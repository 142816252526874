import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import Widget from '../../../components/Widget/WidgetContainer';
import Select from 'react-select';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';

import ErrorIcon from '@material-ui/icons/Error';
import SavedAudienceMessage from './savedAudienceMessage';
import trans from './../../../i18n/tran';
const CustomAudiences = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <Widget
      footnote={
        props.savedAudienceSelected
          ? ''
          : trans('NEW_CAMPAIGN_MESSAGE9', props.lang)
      }
      overflow={true}
      focusable={
        <Focusable
          id={props.componentInfo.focusId}
          refList={props.refList}
        ></Focusable>
      }
      title={trans('NEW_CAMPAIGN_CUSTOM_AUDIENCE', props.lang)}
      title2={
        <Grid container>
          {props.componentInfo.getError(props) && props.showAdErrors ? (
            <Grid item xs={12} lg={12} md={12}>
              <div style={{ color: 'red' }}>
                <div className={classes.errorIcon}>
                  <ErrorIcon></ErrorIcon>
                </div>
                <div className={classes.warningMessage}>
                  {props.componentInfo.errorMessage}
                </div>
              </div>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={4} lg={4} md={4}></Grid>
        </Grid>
      }
      bodyClass={
        props.savedAudienceSelected ? classes.center : classes.tableWidget
      }
      disableWidgetMenu={true}
    >
      {props.savedAudienceSelected ? (
        <SavedAudienceMessage
        lang={props.lang}
        audiences={props.selectedSavedAudiences}
        />
      ) : (
        <div>
          <Select
            placeholder={trans('NEW_CAMPAIGN_CUSTOM_AUDIENCE_SELECT', props.lang)}
            isMulti={true}
            styles={theme.reactSelect}
            id={'Lookalikes_Selector'}
            onChange={e => {
              props.changeSelectedCustomAudiences(e);
            }}
            value={props.selectedCustomAudiences}
            options={props.selectorCustomAudience}
          ></Select>
        </div>
      )}
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(CustomAudiences);
