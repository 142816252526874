import { compose, withState, withHandlers, lifecycle } from 'recompose';
import View from './homeView';
import {
  GAEventCategoryLogin,
  GAEventActionLoginSuccess,
  sessionStorageUTMValue,
  loginSuccessGA,
} from '../../components/Constants';
import {
  setGASessionItem,
  getGASessionItem,
  sendGAEvent,
  sendGAPageView,
} from '../../utils/ga';


const mycompose = compose(
  withState('filter', 'changeFilter', ''),
  withState('showKPIExtra', 'changeShowKPIExtra', true),
  lifecycle({
    componentDidMount() {
      this.props.changeTenantChangeFunction({
        function: newTenenatValue => {},
      });

      let localUser = JSON.parse(localStorage.getItem('userName'));
      let setGAdata = `${localUser.given_name} ${localUser.family_name}`;

      setGASessionItem(loginSuccessGA, sessionStorageUTMValue, setGAdata);
      let sendGAdataLogin = getGASessionItem();

      sendGAPageView('Home Page');
      // TODO: not 100% acurate, since a user can open the home many times
      // not only after a login success
      sendGAEvent({
        category: GAEventCategoryLogin,
        action: GAEventActionLoginSuccess,
        label: sendGAdataLogin,
      });
      // console.log( window.location.href)
			if (window && window.location) {
				const urlParams = new URLSearchParams(window.location.search);
				let flag = urlParams.get("showKPIExtra");
				if (flag) {
					this.props.changeShowKPIExtra(true);
				}
			}
    },

    componentWillUnmount() {},
    componentDidUpdate() {},
  }),
  withHandlers({}),
);

export default mycompose(View);
