import React from 'react';
import { withStyles, Typography, CardContent, Grid } from '@material-ui/core';
import GridContainer from '../../../components/Grid/GridContainer.js';
import GridItem from '../../../components/Grid/GridItem.js';
import Card from '../../../components/Card/Card.js';
import CardHeader from '../../../components/Card/CardHeader.js';
import CardIcon from '../../../components/Card/CardIcon.js';
import CardFooter from '../../../components/Card/CardFooter.js';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DetailsIcon from '@material-ui/icons/TrendingDown';
import ChangeHistoryIcon from '@material-ui/icons/TrendingUp';
import Widget from '../../../components/Widget/WidgetContainer';
import { green, red, orange } from '@material-ui/core/colors';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Disclaimer from '../../../components/Disclaimer/Disclaimer.js';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import trans from '../../../i18n/tran';
import HelpIcon from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';
import { useIntercom } from 'react-use-intercom';

import {
  productTours
} from './../../../components/Constants';
function trunc(number) {
  if (Number.isNaN(number)) {
    return undefined;
  }
  return Math.trunc(number * 100) / 100;
}

function ProcessAlyIndicators(props) {
  if (props && props.data) {
    let threshold = 4;
    const learning = trans('ALY_INDICATORS_LEARNING', props.lang);
    const nochange = trans('ALY_INDICATORS_NO_CHANGE', props.lang);
    const sample = props.data['SampleDays'];
    // CPC Data
    if (
      'CPCMinMax' in props.data &&
      'CPCMinMaxStdDev' in props.data &&
      props.data['CPCMinMax'] !== null &&
      props.data['CPCMinMaxStdDev'] !== null
    ) {
      let cpc = {};
      cpc.StdDev = props.data.CPCMinMaxStdDev;
      cpc.Avg = props.data.CPCMinMax;
      cpc.Delta = trunc(props.data.DeltaCPC);
      cpc.Max = cpc.Avg + cpc.StdDev;
      cpc.Min = cpc.Avg - cpc.StdDev;

      cpc.RangeTitle = trans('ALY_INDICATORS_CPC_RANGE_TITLE', props.lang);
      cpc.RangeHelp = trans('ALY_INDICATORS_CPC_RANGE_HELP', props.lang);

      cpc.PerfTitle = trans('ALY_INDICATORS_CPC_PERF_TITLE', props.lang);
      cpc.PerfHelp = trans('ALY_INDICATORS_CPC_PERF_HELP', props.lang);

      cpc.RangeText = trans('ALY_INDICATORS_CPC_TEXT', props.lang)
        .replace('$AVG', cpc.Avg.toFixed(2))
        .replace('$MAX', cpc.Max.toFixed(2));
      if (sample <= threshold) {
        cpc.PerfRec = learning;
      } else if (cpc.Delta === 0) {
        cpc.PerfRec = nochange;
      } else if (cpc.Delta > 0) {
        cpc.PerfRec = trans('ALY_INDICATORS_CPC_BAD', props.lang);
      } else if (cpc.Delta < 0) {
        cpc.PerfRec = trans('ALY_INDICATORS_CPC_GOOD', props.lang);
      }
      cpc.Icon = () => {
        return <></>;
      };

      if (cpc.Delta < 0) {
        // graph icon down (down is good=gree)
        cpc.Icon = () => {
          return <DetailsIcon fontSize="large" style={{ color: green[500] }} />;
        };
      } else if (cpc.Delta > 0) {
        // graph icon up (up is bad=red)
        cpc.Icon = () => {
          return (
            <ChangeHistoryIcon fontSize="large" style={{ color: red[500] }} />
          );
        };
      }
      props.cpc = cpc;
    }

    // CTR Data
    if (
      'CTRMinMax' in props.data &&
      'CTRMinMaxStdDev' in props.data &&
      props.data['CTRMinMax'] !== null &&
      props.data['CTRMinMaxStdDev'] !== null
    ) {
      let ctr = {};
      ctr.StdDev = props.data.CTRMinMaxStdDev;
      ctr.Avg = props.data.CTRMinMax;
      ctr.Delta = trunc(props.data.DeltaCTR);
      ctr.Max = ctr.Avg + ctr.StdDev;
      ctr.Min = ctr.Avg - ctr.StdDev;
      ctr.RangeTitle = trans('ALY_INDICATORS_CTR_RANGE_TITLE', props.lang);
      ctr.RangeHelp = trans('ALY_INDICATORS_CTR_RANGE_HELP', props.lang);

      ctr.PerfTitle = trans('ALY_INDICATORS_CTR_PERF_TITLE', props.lang);
      ctr.PerfHelp = trans('ALY_INDICATORS_CTR_PERF_HELP', props.lang);

      ctr.RangeText = trans('ALY_INDICATORS_CTR_TEXT', props.lang)
        .replace('$AVG', ctr.Avg.toFixed(2))
        .replace('$MIN', ctr.Min.toFixed(2));

      ctr.Icon = () => {
        return <></>;
      };
      if (sample <= threshold) {
        ctr.PerfRec = learning;
      } else if (ctr.Delta === 0) {
        ctr.PerfRec = nochange;
      }
      if (ctr.Delta < 0) {
        // graph icon down (down better=green)
        ctr.Icon = () => {
          return <DetailsIcon fontSize="large" style={{ color: red[500] }} />;
        };
      }
      if (ctr.Delta > 0) {
        // graph icon up (up is bad=red)
        ctr.Icon = () => {
          return (
            <ChangeHistoryIcon fontSize="large" style={{ color: green[500] }} />
          );
        };
      }
      props.ctr = ctr;
    }
    // CVR
    if (
      'CVRMinMax' in props.data &&
      'CVRMinMaxStdDev' in props.data &&
      props.data['CVRMinMax'] !== null &&
      props.data['CVRMinMaxStdDev'] !== null
    ) {
      let cvr = {};
      cvr.StdDev = props.data.CVRMinMaxStdDev;
      cvr.Avg = props.data.CVRMinMax;
      cvr.Delta = trunc(props.data.DeltaCVR);
      cvr.Max = cvr.Avg + cvr.StdDev;
      cvr.Min = cvr.Avg - cvr.StdDev;

      cvr.RangeTitle = trans('ALY_INDICATORS_CVR_RANGE_TITLE', props.lang);
      cvr.RangeHelp = trans('ALY_INDICATORS_CVR_RANGE_HELP', props.lang);

      cvr.PerfTitle = trans('ALY_INDICATORS_CVR_PERF_TITLE', props.lang);
      cvr.PerfHelp = trans('ALY_INDICATORS_CVR_PERF_HELP', props.lang);

      cvr.RangeText = trans('ALY_INDICATORS_CVR_TEXT', props.lang)
        .replace('$AVG', cvr.Avg.toFixed(2))
        .replace('$MAX', cvr.Max.toFixed(2));
      if (sample <= threshold) {
        cvr.PerfRec = learning;
      } else if (cvr.Delta === 0) {
        cvr.PerfRec = nochange;
      } else if (cvr.Delta < 0) {
        cvr.PerfRec = trans('ALY_INDICATORS_CVR_BAD', props.lang);
      } else if (cvr.Delta > 0) {
        cvr.PerfRec = trans('ALY_INDICATORS_CVR_GOOD', props.lang);
      }
      cvr.Icon = () => {
        return <></>;
      };

      if (cvr.Delta < 0) {
        // graph icon down (down is bad=red)
        cvr.Icon = () => {
          return <DetailsIcon fontSize="large" style={{ color: red[500] }} />;
        };
      } else if (cvr.Delta > 0) {
        // graph icon up (up is good=green)
        cvr.Icon = () => {
          return (
            <ChangeHistoryIcon fontSize="large" style={{ color: green[500] }} />
          );
        };
      }
      props.cvr = cvr;
    }
    // CPA
    if (
      'CPAMinMax' in props.data &&
      'CPAMinMaxStdDev' in props.data &&
      props.data['CPAMinMax'] !== null &&
      props.data['CPAMinMaxStdDev'] !== null
    ) {
      let cpa = {};
      cpa.StdDev = props.data.CPAMinMaxStdDev;
      cpa.Avg = props.data.CPAMinMax;
      cpa.Delta = trunc(props.data.DeltaCPA);
      cpa.Max = cpa.Avg + cpa.StdDev;
      cpa.Min = cpa.Avg - cpa.StdDev;
      cpa.RangeTitle = trans('ALY_INDICATORS_CPA_RANGE_TITLE', props.lang);
      cpa.RangeHelp = trans('ALY_INDICATORS_CPA_RANGE_HELP', props.lang);

      cpa.PerfTitle = trans('ALY_INDICATORS_CPA_PERF_TITLE', props.lang);
      cpa.PerfHelp = trans('ALY_INDICATORS_CPA_PERF_HELP', props.lang);

      cpa.PerfDesc = trans('ALY_INDICATORS_CPA_TEXT', props.lang);
      cpa.RangeText = trans('ALY_INDICATORS_CPA_TEXT', props.lang)
        .replace('$AVG', cpa.Avg.toFixed(2))
        .replace('$MAX', cpa.Max.toFixed(2));
      if (sample <= threshold) {
        cpa.PerfRec = learning;
      } else if (cpa.Delta === 0) {
        cpa.PerfRec = nochange;
      } else if (cpa.Delta > 0) {
        cpa.PerfRec = trans('ALY_INDICATORS_CPA_BAD', props.lang);
      } else if (cpa.Delta < 0) {
        cpa.PerfRec = trans('ALY_INDICATORS_CPA_GOOD', props.lang);
      }
      cpa.Icon = () => {
        return <></>;
      };
      if (cpa.Delta < 0) {
        // graph icon down (down better=green)
        cpa.Icon = () => {
          return <DetailsIcon fontSize="large" style={{ color: green[500] }} />;
        };
      } else if (cpa.Delta > 0) {
        // graph icon up (up is bad=red)
        cpa.Icon = () => {
          return (
            <ChangeHistoryIcon fontSize="large" style={{ color: red[500] }} />
          );
        };
      }
      props.cpa = cpa;
    }
  }
}

const Dashboard37 = ({ classes, theme, ...props }) => {
  const [expanded1, setExpanded1] = React.useState(false);
  const { startTour } = useIntercom();

  const handleChange = panel => (event, isExpanded) => {
    setExpanded1(isExpanded ? true : false);
  };
  if (props.isLoading && !props.isPrintingPage) {
    return (
      <Widget>
        <Typography>{trans('LOADING', props.lang)}...</Typography>
      </Widget>
    );
  } else if (props.data && props.data.length === 0) {
    return (
      <Widget>
        <Typography>{trans('NO_INFO', props.lang)}</Typography>
      </Widget>
    );
  } else if (props.data && props.data.Counter === 0) {
    return (
      <Widget>
        <Typography>{trans('NO_INFO', props.lang)}</Typography>
      </Widget>
    );
  } else if (props.data && typeof props.data.Actions) {
    if (props.resizeRefresh) {
      return <div></div>;
    } else {
      ProcessAlyIndicators(props);
      return (
        <>
          <Accordion expanded={expanded1} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.accordionSummary}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {trans('ALY_INDICATORS', props.lang)}{' '}
                <FiberNewIcon fontSize="medium" style={{ color: orange[500] }} />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GridContainer>
                {/* CTR */}
                {props.ctr && (
                  <>
                    {/* CTR RANGE */}
                    <GridItem xs={12} sm={6} md={6} lg={3}>
                      <Card className={classes.cardStyle}>
                        <CardHeader color="info" stats icon>
                          <CardIcon color="info">
                            <AssessmentIcon />
                          </CardIcon>
                          <p className={classes.cardCategory}>
                            {props.ctr.RangeTitle}
                          </p>
                        </CardHeader>
                        <CardContent>
                          <Grid
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Typography
                              className={classes.metric}
                              color="primary"
                              component="p"
                              align="center"
                            >
                              {props.ctr.RangeText}
                            </Typography>
                          </Grid>
                        </CardContent>
                        <CardFooter>
                          <Disclaimer message={props.ctr.RangeHelp} />
                        </CardFooter>
                      </Card>
                    </GridItem>
                    {/* CTR PERFORMANCE */}
                    {!Number.isNaN(props.ctr.Delta) && (
                      <GridItem xs={12} sm={6} md={6} lg={3}>
                        <Card className={classes.cardStyle}>
                          <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                              <AssessmentIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>
                              {props.ctr.PerfTitle}
                            </p>
                          </CardHeader>
                          <CardContent>
                            <Grid
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Typography>{props.ctr.Icon()}</Typography>
                              <Typography>
                                {(props.ctr.Delta * 100).toFixed(2)} %
                              </Typography>
                            </Grid>
                            <Typography className={classes.metricText}>
                              {props.ctr.PerfRec}
                            </Typography>
                          </CardContent>
                          <CardFooter>
                            <Disclaimer message={props.ctr.PerfHelp} />
                          </CardFooter>
                        </Card>
                      </GridItem>
                    )}
                  </>
                )}

                {/* CPC RANGE  */}
                {props.cpc && (
                  <>
                    <GridItem xs={12} sm={6} md={6} lg={3}>
                      <Card className={classes.cardStyle}>
                        <CardHeader color="info" stats icon>
                          <CardIcon color="info">
                            <AssessmentIcon />
                          </CardIcon>
                          <p className={classes.cardCategory}>
                            {props.cpc.RangeTitle}
                          </p>
                        </CardHeader>
                        <CardContent>
                          <Typography
                            className={classes.metric}
                            color="primary"
                            component="p"
                            align="center"
                          >
                            {props.cpc.RangeText}
                          </Typography>
                        </CardContent>
                        <CardFooter>
                          <Disclaimer message={props.cpc.RangeHelp} />
                        </CardFooter>
                      </Card>
                    </GridItem>
                    {/* CPC PERFORMANCE */}
                    {!Number.isNaN(props.cpc.Delta) && (
                      <GridItem xs={12} sm={6} md={6} lg={3}>
                        <Card className={classes.cardStyle}>
                          <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                              <AssessmentIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>
                              {props.cpc.PerfTitle}
                            </p>
                          </CardHeader>
                          <CardContent>
                            <Grid
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Typography>{props.cpc.Icon()}</Typography>
                              <Typography>
                                {(props.cpc.Delta * 100).toFixed(2)} %
                              </Typography>
                            </Grid>
                            <Typography className="metricText">
                              {props.cpc.PerfRec}
                            </Typography>
                          </CardContent>
                          <CardFooter>
                            <Disclaimer message="Identifica cuánto cuesta que tu público objetivo haga clic en tus anuncios." />
                          </CardFooter>
                        </Card>
                      </GridItem>
                    )}
                  </>
                )}

                {/* CPA RANGE */}
                {props.cpa && (
                  <>
                    <GridItem xs={12} sm={6} md={6} lg={3}>
                      <Card className={classes.cardStyle}>
                        <CardHeader color="info" stats icon>
                          <CardIcon color="info">
                            <AssessmentIcon />
                          </CardIcon>
                          <p className={classes.cardCategory}>
                            {props.cpa.RangeTitle}
                          </p>
                        </CardHeader>
                        <CardContent>
                          <Grid
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Typography
                              className={classes.metric}
                              color="primary"
                              component="p"
                              align="center"
                            >
                              {props.cpa.RangeText}
                            </Typography>
                          </Grid>
                        </CardContent>
                        <CardFooter>
                          <Disclaimer message={props.cpa.RangeHelp} />
                        </CardFooter>
                      </Card>
                    </GridItem>

                    {/* CPA PERFORMANCE */}
                    {!Number.isNaN(props.cpa.Delta) && (
                      <GridItem xs={12} sm={6} md={6} lg={3}>
                        <Card className={classes.cardStyleLow}>
                          <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                              <AssessmentIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>
                              {props.cpa.PerfTitle}
                            </p>
                          </CardHeader>
                          <CardContent>
                            <Grid
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Typography>{props.cpa.Icon()}</Typography>
                              <Typography>
                                {(props.cpa.Delta * 100).toFixed(2)} %
                              </Typography>
                            </Grid>
                            <Typography className="metricText">
                              {props.cpa.PerfRec}
                            </Typography>
                          </CardContent>
                          <CardFooter>
                            <Disclaimer message={props.cpa.PerfHelp} />
                          </CardFooter>
                        </Card>
                      </GridItem>
                    )}
                  </>
                )}

                {/* CVR RANGE */}
                {props.cvr && (
                  <>
                    <GridItem xs={12} sm={6} md={6} lg={3}>
                      <Card className={classes.cardStyle}>
                        <CardHeader color="info" stats icon>
                          <CardIcon color="info">
                            <AssessmentIcon />
                          </CardIcon>
                          <p className={classes.cardCategory}>
                            {props.cvr.RangeTitle}
                          </p>
                        </CardHeader>
                        <CardContent>
                          <Grid
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Typography
                              className={classes.metric}
                              color="primary"
                              component="p"
                              align="center"
                            >
                              {props.cvr.RangeText}
                            </Typography>
                          </Grid>
                        </CardContent>
                        <CardFooter>
                          <Disclaimer message={props.cvr.RangeHelp} />
                        </CardFooter>
                      </Card>
                    </GridItem>

                    {/* CVR PERFORMANCE */}
                    {!Number.isNaN(props.cvr.Delta) && (
                      <GridItem xs={12} sm={6} md={6} lg={3}>
                        <Card className={classes.cardStyleLow}>
                          <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                              <AssessmentIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>
                              {props.cvr.PerfTitle}
                            </p>
                          </CardHeader>
                          <CardContent>
                            <Grid
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Typography>{props.cvr.Icon()}</Typography>
                              <Typography>
                                {props.cvr.Delta.toFixed(2)} %
                              </Typography>
                            </Grid>
                            <Typography className="metricText">
                              {props.cvr.PerfRec}
                            </Typography>
                          </CardContent>
                          <CardFooter>
                            <Disclaimer message={props.cvr.PerfHelp} />
                          </CardFooter>
                        </Card>
                      </GridItem>
                    )}
                  {/* help button */}
                  <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Button
                    variant="outlined"
                    size="medium"
                    className={classes.HelpButton}
                    onClick={e => {
                      if (startTour) {
                        startTour(productTours.es.alyKPI.id);
                      }
                    }}>
                      <HelpIcon fontSize="medium"  />
                      {props.buttonText ? props.buttonText : trans('HELP', props.lang)}
                  </Button>
                </GridItem>
                  </>
                )}
              </GridContainer>
            </AccordionDetails>
          </Accordion>
        </>
      );
    }
  } else
    return (
      <Widget>
        <Typography>No hay datos para mostrar</Typography>
      </Widget>
    );
};

const styles = theme => ({
  accordionSummary: {
    flexDirection: 'row-reverse'
  },
  heading: {
    fontSize: '1.2rem',
  },
  tableWidget: {
    overflowX: 'auto',
    fontSize: '0.9rem',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  mainChartHeaderLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
  },
  divider: {
    minHeight: '3px',
    margin: '10px',
  },
  mainChartLegentElement: {
    fontSize: '18px !important',
    marginLeft: theme.spacing(1),
  },
  cardStyle: {
    flexDirection: 'column',
    minHeight: '400px',
    flexFlow: 'column wrap',
  },
  cardStyleLow: {
    flexDirection: 'column',
    minHeight: '400px',
    flexFlow: 'column wrap',
  },
  cardCategory: {
    color: theme.palette.text.primary,
    fontSize: '1.1rem',
    paddingTop: '10px',
    marginBottom: '0',
    marginTop: '0',
    margin: '0',
  },
  cardTitle: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    fontWeight: '300',
    marginTop: '30px',
    marginBottom: '25px',
    minHeight: '32px',
    '& small': {
      color: theme.primary,
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  metric: {
    textAlign: 'right',
  },
  metricText: {
    textAlign: 'left',
  },
  HelpButton: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '35%',
    alignText: 'center',
    fontSize: '0.75rem',
    marginTop: theme.spacing(2),
    color: theme.palette.text.hint
  },
});

export default withStyles(styles, { withTheme: true })(Dashboard37);
