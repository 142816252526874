import React from 'react';
import { withStyles } from '@material-ui/core';
import Widget from '../../../components/Widget';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { CustomDateSlider } from '../../../components/Sidebar/CustomDateSlider';
import trans from './../../../i18n/tran';
const DatesSelection = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <Widget
      overflow={true}
      focusable={
        <Focusable
          id={props.componentInfo.focusId}
          refList={props.refList}
        ></Focusable>
      }
      title={trans('NEW_CAMPAIGN_DURATION', props.lang)}
      bodyClass={classes.tableWidget}
      disableWidgetMenu={true}
    >
      <div className={classes.datePicker}>
        <CustomDateSlider
          showErrors
          fontSize={'1.5rem'}
          reset={props.reset}
          lang={props.lang}
          changeReset={props.changeReset}
          ErrorIcon={<ErrorIcon />}
          dateRange={props.selectedCampaignDates}
          changeDateRange={props.changeSelectedCampaignDatesHandler}
          selectedDates={props.selectedCampaignDates}
          changeSelectedDates={props.changeSelectedCampaignDatesHandler}
          id={'CreateAdDatePicker'}
          validateDateEqualOrBiggerThanToday={true}
          hideSlider={true}
          changeError={props.componentInfo.getchangeError(props)}
          Error={props.componentInfo.getError(props)}
        >
          {' '}
        </CustomDateSlider>
      </div>
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(DatesSelection);
