import React from 'react';
import { withStyles } from '@material-ui/core';
import Header from '../Header';
import Sidebar from '../Sidebar';
import classnames from 'classnames';
import { showPageWhileLoading } from '../../utils/functions';
import {
  onBoardingOK,
  newAccountUrl,
  newAccountPageId,
  productTours
} from '../Constants';
import trans from './../../i18n/tran';
import Help from './../../components/Help/help';

function CustomPageView({ classes, theme, ...props }) {
  let sidebar = <div></div>;
  let tenantSelected =
    props.selectedTenants.length > 0 || window.location.href === newAccountUrl;
  if (props.showSideBar && tenantSelected) {
    sidebar = <Sidebar {...props} />;
  }

  let elements = React.Children.toArray(props.children);
  if (elements.length === 1) {
    elements = React.cloneElement(elements[0], {
      //customPageId: props.customPageId,
    });
  }

  if (
    sessionStorage.getItem('customPageValidation') !== null &&
    !showPageWhileLoading()
  ) {
    return (
      <React.Fragment>
        <div></div>
      </React.Fragment>
    );
  } else
    return (
      <React.Fragment>
        <Header
          sidebarTransitionTime={
            props.isSidebarOpened
              ? theme.transitions.duration.leavingScreen
              : theme.transitions.duration.enteringScreen
          }
          showSideBar={props.showSideBar && tenantSelected}
          {...props}
        />

        {sidebar}

        <div
          className={classnames(classes.content, {
            [classes.contentShift]: props.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          {sessionStorage.getItem('showOnBoardingMessage') !== null &&
          props.customPageId !== newAccountPageId &&
          //props.customPageId !== adaccountsPageId &&
          sessionStorage.getItem('errorPage') === null ? (
            <div
              style={{
                backgroundColor: props.hoverOnBoardingMessage
                  ? '#ff7e7a'
                  : '#ff4c47',
              }}
            >
              <div
                style={{ textAlign: 'center', width: '100%', color: 'white' }}
              >
                <div
                  onMouseEnter={() => {
                    
                  }}
                  onMouseLeave={() => {
                   
                  }}
                 
                  onClick={() => {
                   
                  }}
                >
                  <h1>*{sessionStorage.getItem('onBoardingMessage')}*</h1>
                </div>

               
              </div>
            </div>
          ) : (
            ''
          )}
          {props.loadingMessage && props.loadingMessage.length > 0 ? (
            <div
              style={{
                backgroundColor: '#013f8a',
              }}
            >
              <div
                style={{ textAlign: 'center', width: '100%', color: 'white' }}
              >
                <h2>{props.loadingMessage}</h2>
              </div>
            </div>
          ) : (
            ''
          )}
          {sessionStorage.getItem('onBoardingStatus') !==
          onBoardingOK.toString() ? (
            elements
          ) : tenantSelected ? (
            elements
          ) : (
            <div>
              {trans('BUSINESS_SELECT', props.lang)}
              <Help
                className={"HelpButton"} 
                lang={props.lang} 
                productTourId={productTours.es.welcome.id} />
            </div>
          )}
        </div>
      </React.Fragment>
    );
}

const styles = theme => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 320px)`,
    minHeight: '100vh',
  },
  contentShift: {
    width: `calc(100vw - ${320 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
});
export default withStyles(styles, { withTheme: true })(CustomPageView);
