import React from 'react';
import { withStyles, Grid, Button } from '@material-ui/core';
import Widget from '../../../components/Widget/WidgetContainer';
import Select from 'react-select';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import { alertConfirmPopUp } from '../../../utils/functions';
import trans from './../../../i18n/tran';
const SavedAudiences = ({ classes, theme, onChange, value, ...props }) => {
  function cleanSavedAudience() {
    props.changeSelectedSavedAudiences([]);
    props.changeSavedAudienceSelected(false);
  }
  return (
    <Widget
      overflow={true}
      focusable={
        <Focusable
          id={props.componentInfo.focusId}
          refList={props.refList}
        ></Focusable>
      }
      title={trans('NEW_CAMPAIGN_AUDIENCE', props.lang)}
      title2={
        <Grid container>
          {props.componentInfo.getError(props) && props.showAdErrors ? (
            <Grid item xs={12} lg={12} md={12}>
              <div style={{ color: 'red' }}>
                <div className={classes.errorIcon}>
                  <ErrorIcon></ErrorIcon>
                </div>
                <div className={classes.warningMessage}>
                  {props.componentInfo.errorMessage}
                </div>
              </div>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={4} lg={4} md={4}></Grid>
        </Grid>
      }
      bodyClass={classes.tableWidget}
      disableWidgetMenu={true}
    >
      <Grid container>
        <Grid item xs={10} lg={11} md={11}>
          <Select
            id={'savedAudienceSelect'}
            styles={theme.reactSelect}
            onChange={e => {
              props.changeSelectedSavedAudiences([e]);
              if (e.value === -1) {
                cleanSavedAudience();
              } else {
                alertConfirmPopUp(
                  trans('NEW_CAMPAIGN_MESSAGE8', props.lang),
                  props,
                  () => {
                    props.changeSelectedSavedAudiences([e]);
                    props.changeSavedAudienceSelected(true);
                  },
                );
              }
            }}
            value={props.selectedSavedAudiences}
            options={props.selectorSavedAudience}
          ></Select>
        </Grid>
        <Grid item xs={2} lg={1} md={1}>
          <Button
            id={'clearSavedAudienceButton'}
            size="small"
            color="primary"
            onClick={() => {
              cleanSavedAudience();
            }}
          >
            <DeleteIcon className={classes.profileMenuIcon} />
          </Button>
        </Grid>
      </Grid>
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(SavedAudiences);
