import React from 'react';
import { Grid, withStyles, Fab } from '@material-ui/core';
import { changeFocus } from '../Focusable';
import { styles } from '../../../utils/styles';
import { getObjectiveComponentsInfo } from '../../../utils/functions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import trans from './../../../i18n/tran';
import { Track, NewEvent, NewTrackData } from './../../../utils/mixpanel'; 

const ErrorMessages = ({ classes, theme, onChange, value, ...props }) => {
  let objectiveComponents = getObjectiveComponentsInfo(props).mapping;
  return (
    <div style={{ width: '100%' }}>
      {!props.isModify ? (
        <Grid container>
          <Grid item xs={12}>
            <Fab
              id="create-Campaign"
              variant="extended"
              color="primary"
              aria-label="add"
              className={classes.mainButton}
              onClick={e => {
                debugger;
                const eventName =NewEvent('new_campaign', 'create_botton','click');
                const eventData =NewTrackData(props);  
                Track(eventName, eventData);
                if (props.hasError) {
                  props.changeShowAdErrors(true);
                  props.changeRecalculateMessages(!props.recalculateMessages);
                  for (let i = 0; i < objectiveComponents.length; i++) {
                    if (objectiveComponents[i].getError)
                      if (objectiveComponents[i].getError(props)) {
                        changeFocus(
                          objectiveComponents[i].focusId,
                          props.refList,
                        );
                        break;
                      }
                  }
                } else {
                  props.setCreateClicked();
                }
              }}
            >
              {trans('NEW_CAMPAIGN_BUTTON', props.lang)}
            </Fab>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      <div>
        <Dialog
          style={{ width: '100%' }}
          onClose={props.setVisibleSnackBar}
          open={props.visibleSnackBar}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            style={
              props.resultCampaign
                ? {
                    backgroundColor: '#013f8a',
                    width: '100%',
                    color: '#FFFFFF',
                  }
                : {
                    backgroundColor: '#d9534f',
                    width: '100%',
                    color: '#FFFFFF',
                  }
            }
          >
            {props.resultCampaign ? 'Creación de Campaña' : 'Error'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {props.resultCampaign && props.selectedUploadType === '1'
                ? trans('NEW_CAMPAIGN_MESSAGE13', props.lang)
                : props.resultCampaign && props.selectedUploadType === '2'
                ? trans('NEW_CAMPAIGN_MESSAGE14', props.lang)
                : trans('ERROR_MESSAGE_1', props.lang)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                props.setVisibleSnackBar(false);
              }}
              color="primary"
            >
              {trans('ACCEPT', props.lang)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
export default withStyles(styles, { withTheme: true })(ErrorMessages);
