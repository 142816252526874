import React from 'react';
import { Grid, withStyles, Paper } from '@material-ui/core';
import Users from './components/Users';

const View = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <Paper>
      <Grid item xs={12} md={12} lg={12}>
        <Users {...props} />
      </Grid>
    </Paper>
  );
};

export default withStyles({}, { withTheme: true })(View);
