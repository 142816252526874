import { axios, axiosHeaders } from './helper';
import { payWallApplyCoupon } from '../../components/Constants';

export interface ApplyCouponParams {
  account_id: number;
  coupon: string;
}

export interface ApplyCouponResponse {
  code?: number;
  desc?: string;
  errors: string[];
}

export const ApplyCoupon = async (params: ApplyCouponParams) => {
  return await axios.post<ApplyCouponResponse>(
    `${payWallApplyCoupon}`,
    { ...params },
    {
      ...axiosHeaders(),
    },
  );
};
