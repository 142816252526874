import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import Select from 'react-select';
import { styles } from '../../../utils/styles';
import trans from './../../../i18n/tran'

const InstagramAccount = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <div style={{ width: '100%' }}>
      <Typography>{trans('NEW_CAMPAIGN_INSTAGRAM', props.lang)}</Typography>
      <Select
        options={props.selectorAdminInstagramAccount}
        value={props.selectedAdminInstagramAccounts}
        styles={theme.reactSelect}
        onChange={e => {
          props.changeSelectedAdminInstagramAccounts([e]);
        }}
        isDisabled={
          props.selectedAdminFanPages.length === 0 ||
          props.selectorAdminInstagramAccount.length === 0
        }
        placeholder={
          props.selectedAdminFanPages.length === 0
            ? trans('NEW_CAMPAIGN_FANPAGE_SELECT', props.lang)
            : props.selectorAdminInstagramAccount.length === 0
            ? trans('NEW_CAMPAIGN_ERROR4', props.lang)
            : trans('NEW_CAMPAIGN_INSTAGRAM_SELECT', props.lang)
        }
      ></Select>
    </div>
  );
};
export default withStyles(styles, { withTheme: true })(InstagramAccount);
