import styled, { css } from 'styled-components';

export const MontserratMediumBlack11px = css`
  color: var(--black-22);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m2);
  font-weight: 500;
  font-style: normal;
`;

export const MontserratNormalTolopea16px = css`
  color: var(--tolopea);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-weight: 400;
  font-style: normal;
`;

export const Label2 = styled.div`
  ${MontserratMediumBlack11px}
  min-height: 16px;
  margin-left: 4px;
  min-width: 73px;
  letter-spacing: 0.5px;
  line-height: 16px;
  white-space: nowrap;
`;

export const Frame5 = styled.div`
  width: 108px;
  height: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3px 0;
  align-items: center;
  background-color: var(--white);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 1px 3px #00000033, 0px 2px 1px #0000001f,
    0px 1px 1px #00000024;
`;

export const Label = styled.div`
  ${MontserratNormalTolopea16px}
  min-height: 24px;
  margin-top: 12px;
  margin-left: 16px;
  min-width: 72px;
  text-align: right;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

export const CardTitle1 = styled.div`
  height: 18px;
  display: flex;
  padding: 0 1px;
  align-items: center;
  min-width: 96px;
`;

export const Icon = styled.div`
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
`;
