import { compose, withHandlers, lifecycle, withState } from 'recompose';
import View from './AssignOptimizationView';
//import { getOptimizationTypesAdminApi } from "../../../../utils/functions";
//import { saveAssignRequest } from "../../components/ApiClient/Assign";

const mycompose = compose(
  withState('filter', 'changeFilter', ''),
  lifecycle({
    componentDidMount() {
      //getOptimizationTypesAdminApi(this.props);
      //getCategoriesCreateAssign(this.props);
      //getCountriesCreateAssign(this.props);
    },

    componentWillUnmount() {
      //window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
    componentDidUpdate() {},
  }),
  withHandlers({}),
);

export default mycompose(View);
