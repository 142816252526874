import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Grid,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { FormValues, OnBoardingStep } from '../type';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Tab from '@material-ui/core/Tab';
import { palette } from '../../theme';
import { useOnboardingContext } from '../../context';
import { AdAccountCard } from './AdAccountCard';
import { useFormikContext, ErrorMessage } from 'formik';
import { PaginationHelper } from './Pagination';
import {
  AddAdAccountToBusiness,
  RemoveAdAccountToBusiness,
  MakeAlyBusinessPartner,
} from '../../rest';

import i18n from '../../../../../i18n';
import { useTranslation } from 'react-i18next';
import { AdAccountI } from '../../types';
import { GetAdAccounts } from '../../../../rest';
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabsText = [
  i18n.t('Available Ad Accounts'),
  i18n.t('Unavailable Ad Accounts'),
];

const AdAccount = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isSubmitting, setFieldValue, values, validateForm } =
    useFormikContext<FormValues>();
  const [data, setData] = React.useState<AdAccountI[]>([]);
  const [filterData, setFilterData] = React.useState(data);
  const [tabValue, setTabValue] = React.useState(0);

  const loadData = async () => {
    const response = await GetAdAccounts(values.businessId);

    if (response) {
      const data = [...response.payload]; //...AdAccountMock(100)

      setData(data);
    }
  };

  const { setNavBackButtonVisible } = useOnboardingContext();
  useEffect(
    () => {
      setNavBackButtonVisible(true);
      setFieldValue('totalSelectedAccounts', 0, true);
      loadData().then();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [searchText, setSearchText] = useState('');

  const tabFilterData = useMemo(() => {
    if (tabValue === 0) {
      return data.filter(
        item => item.AssignedToCurrent || !item.AssignedToAccount,
      );
    } else if (tabValue === 1) {
      return data.filter(
        item => !(item.AssignedToCurrent || !item.AssignedToAccount),
      );
    }
    return [];
  }, [data, tabValue]);

  useEffect(() => {
    if (searchText) {
      const filtered = tabFilterData.filter(
        value =>
          value.Title.toLowerCase().includes(searchText) ||
          value.ExtId.toString().includes(searchText),
      );
      setFilterData(filtered);
    } else {
      setFilterData(tabFilterData);
    }
  }, [searchText, tabFilterData]);

  const totalSelectedAccounts = useMemo(
    () =>
      data.reduce(
        (total, current) => total + (current.AssignedToCurrent ? 1 : 0),
        0,
      ),
    [data],
  );

  useEffect(() => {
    setFieldValue('totalSelectedAccounts', totalSelectedAccounts, true);
  }, [setFieldValue, totalSelectedAccounts]);

  useEffect(
    () => {
      // This will be called everytime `step` changes:
      validateForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Card className={classes.cardRoot}>
        <Tabs
          variant="fullWidth"
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
          indicatorColor="primary"
          style={{ background: 'white' }}>
          {tabsText.map((text, index) => (
            <Tab
              label={text}
              {...a11yProps(index)}
              className={
                tabValue === index ? classes.selectedText : classes.normalText
              }
              key={index}
            />
          ))}
        </Tabs>
      </Card>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} className={classes.search}>
          <TextField
            fullWidth
            type="search"
            placeholder={t('Search by name or ID')}
            value={searchText}
            onChange={event => setSearchText(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <PaginationHelper
          itemsPerPage={10}
          items={filterData}
          renderItem={account => (
            <Grid item xs={6} sm={6} md={6} key={account.Id.toString()}>
              <AdAccountCard
                account={account}
                actionPress={async () => {
                  if (account.Status === 10) {
                    await MakeAlyBusinessPartner(account.ExtId);
                  }

                  if (account.AssignedToCurrent) {
                    await RemoveAdAccountToBusiness(
                      values.businessId,
                      account.Id,
                    );
                  } else {
                    await AddAdAccountToBusiness(
                      values.businessId,
                      account.Id,
                      values.businessCategory,
                    );
                  }
                  loadData();
                }}
              />
            </Grid>
          )}
        />

        <Grid container justifyContent="center">
          <ErrorMessage
            name="totalSelectedAccounts"
            component={Typography}
            className={classes.error}
          />
        </Grid>
        <Grid container justifyContent="center">
          <Typography className={classes.totalAdAccounts}>
            {t('({{totalSelectedAccounts}}) Ad accounts selected', {
              totalSelectedAccounts,
            })}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Button
            className={classes.nextButton}
            type={'submit'}
            fullWidth
            disabled={isSubmitting}
            style={{ textTransform: 'none' }}
            color="primary"
            variant="contained">
            {t('Choose a Plan')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export const AdAccountStep: OnBoardingStep = {
  component: AdAccount,
  label: i18n.t('Ad Account Selection'),
  initialValues: {
    totalSelectedAccounts: 0,
  },
  validationSchema: Yup.object().shape({
    totalSelectedAccounts: Yup.number()
      .integer()
      .min(0, i18n.t('Please Add an ad account')),
  }),
  welcomeTitle: '',
  welcomeDescription: '',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      marginBottom: 20,
      marginTop: 20,
    },
    nextButton: { marginBottom: 20, marginTop: 20 },
    cardContent: { marginBottom: 20 },
    cardContentTab: { marginBottom: 0 },
    normalText: {
      textTransform: 'none',
      color: 'black',
      fontFamily: 'Montserrat-Medium',
      fontSize: 13,
    },
    selectedText: {
      textTransform: 'none',
      color: palette.purple,
      fontFamily: 'Montserrat-Medium',
      fontSize: 13,
    },
    table: {
      marginBottom: 20,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    search: {
      border: '1px solid #BDBDBD',
      marginTop: 10,
      borderRadius: '4px',
      marginBottom: 10,
      marginLeft: '8px',
      marginRight: '8px',
      backgroundColor: palette.white,
    },
    button: { textTransform: 'none' },
    totalAdAccounts: {
      fontFamily: 'Montserrat-SemiBold',
      fontSize: '14px',
      lineHeight: '20px',
      marginTop: 10,
      color: palette.purpleRain,
    },
    error: {
      fontFamily: 'Montserrat-SemiBold',
      fontSize: '14px',
      lineHeight: '20px',
      marginTop: 10,
      color: 'red',
    },
    title: {
      fontFamily: 'Montserrat-SemiBold',
      fontSize: 15,
      marginBottom: 20,
    },
    indicator: {
      backgroundColor: palette.purple,
      height: '10px',
      top: '45px',
    },
  }),
);
