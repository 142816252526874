import tinycolor from 'tinycolor2';

const primary = '#100047'; //azul
const secondary = '#871BEF'; // morado
const warning = '#E87D04'; //
const success = '#3D4FD4'; // segundo azul
const info = '#9013FE'; // morado
const hover = '#871BEF'; // gris palido
const white = '#FFFFFF';
const loaderSpin = 'spin 0.5s linear infinite';
const contrast = '#88D1D4';
const lightenRate = 7.5;
const extremeLightenRate = 30;
const darkenRate = 15;
const danger = '#d9534f';
const disabled = '#C0C0C0';

const customTheme = {
  reactSelect: {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? tinycolor(secondary).lighten(5).toHexString()
        : white,
      color: state.isSelected ? white : primary,
      ':active': {
        backgroundColor: hover,
      },
      ':hover': {
        backgroundColor: tinycolor(secondary)
          .lighten(extremeLightenRate)
          .toHexString(),
      },
    }),
  },
  loader: {
    border: '16px solid ' + white,
    borderRadius: '50%',
    borderTop: '16px solid ' + primary,
    width: '25%',
    height: '25%',
    WebkitAnimation: loaderSpin,
    animation: loaderSpin,
  },
  loader2: {
    border: '16px solid ' + primary,
    borderRadius: '50%',
    borderTop: '16px solid ' + primary,
    width: '25%',
    height: '25%',
  },
  modal: {
    display: 'block',
    position: ' fixed',
    zIndex: ' 1305',
    paddingTop: ' 100px',
    left: ' 0',
    top: ' 0',
    width: ' 100%',
    height: ' 100%',
    overflow: ' auto',
    backgroundColor: ' rgba(0, 0, 0, 0.4)',
  },
  sideBarmodal: {
    display: 'block',
    position: ' fixed',
    zIndex: ' 1305',
    paddingTop: ' 100px',
    left: ' 0',
    top: ' 0',
    width: ' 320px',
    height: ' 100%',
    overflow: ' auto',
    backgroundColor: ' rgba(0, 0, 0, 0.1)',
  },
  modalgif: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '500%',
  },
  modalimage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '150px',
  },
  modalcontent: {
    width: ' 100px',
    height: ' 100px',
    position: ' absolute',
    left: ' 50%',
    marginLeft: ' -50px',
    top: ' 50%',
    msTransform: ' translateY(-50%)',
    transform: ' translateY(-50%)',
  },
  palette: {
    primary: {
      main: primary,
      hover: tinycolor(hover).lighten(extremeLightenRate).toHexString(),
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: contrast, //
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      lighter: tinycolor(success).lighten(40).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: primary,
      secondary: secondary,
      hint: contrast,
      danger: danger,
      warning: warning,
    },
    background: {
      default: '#F6F7FF',
      light: '#F3F5FF',
    },
    disabled: {
      main: disabled,
      light: tinycolor(disabled).lighten(lightenRate).toHexString(),
      dark: tinycolor(disabled).darken(darkenRate).toHexString(),
    },
  },
  customShadows: {
    widget:
      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark:
      '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide:
      '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    transparent:
      '0px 12px 33px 0px #013f8a, 0 3px 3px -2px #013f8a, 0 1px 8px 0 #013f8a',
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: '#4A4A4A1A',
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      },
    },
    MuiSelect: {
      icon: {
        color: '#B9B9B9',
      },
    },
    MuiListItem: {
      root: {
        color: primary,
        '&$selected': {
          backgroundColor: '#F3F5FF !important',
        },
      },
      button: {
        '&:hover, &:focus': {
          backgroundColor: '#F3F5FF',
        },

        '&$selected': {
          backgroundColor: '#F3F5FF !important',
        },
        '&:focus': {
          backgroundColor: '#F3F5FF',
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: 'white',
      },
    },
    MuiTableRow: {
      root: {
        height: 35,
      },
    },
    MuiTableCell: {
      root: {
        //borderBottom: '1px solid rgba(224, 224, 224, .5)',
        padding:
          '0px 0px 4px 14px' /* leo: this is important otherwise hamburger menu will move when click on the menu item*/,
      },
      head: {
        fontSize:
          '1.05rem' /* iChel: original general size on 0.95rem without bold*/,
        fontWeight: 'bold',
      },
      body: {
        fontSize: '0.90rem' /* iChel: original general size on 0.80rem*/,
      },
    },
  },
};

export default customTheme;
