import React from 'react';
import { withStyles } from '@material-ui/core';
import Widget from '../../../components/Widget/WidgetContainer';
import Select from 'react-select';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import {
  callToActionLinkClicks,
  callToActionConversions,
  callToActionLeadForms,
  callToActionAppInstall,
  leadGenerationObjective,
  linkClicksObjective,
  conversionsObjective,
  appInstallObjective,
  conversationsObjective,
} from '../../../components/Constants';
import ErrorIcon from '@material-ui/icons/Error';
import trans from './../../../i18n/tran';
const Cta = ({ classes, theme, onChange, value, ...props }) => {
  let callToActionLeadFormsTrans = []
  let callToActionLinkClicksTrans = []
  let callToActionConversionsTrans = []
  let callToActionAppInstallTrans = []

  callToActionLeadForms.forEach(ele => {
    callToActionLeadFormsTrans.push({
      value: ele.value,
      label: trans(ele.key, props.lang)
    });
  });

  callToActionLinkClicks.forEach(ele => {
    callToActionLinkClicksTrans.push({
      value: ele.value,
      label: trans(ele.key, props.lang)
    });
  });

  callToActionConversions.forEach(ele => {
    callToActionConversionsTrans.push({
      value: ele.value,
      label: trans(ele.key, props.lang)
    });
  });


  callToActionAppInstall.forEach(ele => {
    callToActionAppInstallTrans.push({
      value: ele.value,
      label: trans(ele.key, props.lang)
    });
  });

  return (
    <Widget
      overflow={true}
      focusable={
        <Focusable
          id={props.componentInfo.focusId}
          refList={props.refList}
        ></Focusable>
      }
      title={trans('NEW_CAMPAIGN_CTA', props.lang)}
      bodyClass={classes.tableWidget}
      disableWidgetMenu={true}
    >
      {props.componentInfo.getError &&
      props.componentInfo.getError(props) &&
      props.showAdErrors ? (
        <div
          style={{
            color: 'red',
            height: '3rem',
          }}
        >
          <div className={classes.errorIcon}>
            <ErrorIcon></ErrorIcon>
          </div>
          <div className={classes.warningMessage}>
            {trans('NEW_CAMPAIGN_CTA_SELECT', props.lang)}
          </div>
        </div>
      ) : (
        ''
      )}
      <div>
        <Select
          id={'Cta_Selector'}
          styles={theme.reactSelect}
          onChange={e => {
            props.changeSelectedCta([e]);
          }}
          placeholder={
            conversationsObjective ===
              props.selectedAdminCampaignTypes[0].value &&
            props.selectedConversations.length === 0
              ? trans('NEW_CAMPAIGN_CTA_DEST', props.lang)
              : trans('NEW_CAMPAIGN_CTA_SELECT', props.lang)
          }
          value={props.selectedCta}
          options={
            props.selectedAdminCampaignTypes.length > 0 &&
            leadGenerationObjective ===
              props.selectedAdminCampaignTypes[0].value
              ? callToActionLeadFormsTrans
              : linkClicksObjective ===
                props.selectedAdminCampaignTypes[0].value
              ? callToActionLinkClicksTrans
              : conversionsObjective ===
                props.selectedAdminCampaignTypes[0].value
              ? callToActionConversionsTrans
              : appInstallObjective ===
                props.selectedAdminCampaignTypes[0].value
              ? callToActionAppInstallTrans
              : conversationsObjective ===
                props.selectedAdminCampaignTypes[0].value
              ? props.selectorCta
              : []
          }
        ></Select>
      </div>
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(Cta);
