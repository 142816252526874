import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Paper, Typography, withStyles, Button } from '@material-ui/core';
import classnames from 'classnames';

const ErrorPage = ({ classes }) => (
  <Grid container>
    <Paper className={classes.container}>
      <Typography className={classes.logotypeText}>404</Typography>
      <Typography color="primary" className={classes.textRowops}>
        Oops
      </Typography>
      <Typography
        color="textSecondary"
        className={classnames(classes.textRow, classes.safetyText)}
      >
        NO PODEMOS ENCONTRAR LA PÁGINA.
      </Typography>
      <div style={{ width: '50%', margin: 'auto' }}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/login"
          size="large"
          className={classes.button}
          style={{ width: '100%' }}
        >
          Volver al Inicio
        </Button>
      </div>
    </Paper>
  </Grid>
);

const styles = theme => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  logotype: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logotypeText: {
    fontWeight: 500,
    fontSize: 400,
    color: theme.palette.primary.yellow,
    marginLeft: theme.spacing(2),
  },
  logotypeIcon: {
    width: 70,
    marginRight: theme.spacing(2),
  },
  paperRoot: {
    boxShadow: theme.customShadows.transparent,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    maxWidth: 650,
    maxHeight: 900,
    backgroundColor: theme.palette.primary.main,
  },
  textRow: {
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    color: theme.palette.primary.yellow,
  },

  textRowops: {
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    color: 'white',
    fontWeight: 600,
    fontSize: 80,
  },
  errorCode: {
    fontSize: 148,
    fontWeight: 600,
  },
  safetyText: {
    fontWeight: 300,
    color: theme.palette.text.secondary,
  },
  backButton: {
    margin: 'auto',
    width: '100%',
    boxShadow: theme.customShadows.widget,
    textTransform: 'none',
    fontSize: 15,
    backgroundColor: theme.palette.primary,
    '&:hover': {
      borderBottomColor: 'white',
      backgroundColor: 'white',
    },
  },
});

export default withStyles(styles, { withTheme: true })(ErrorPage);
