import React from 'react';
import { withStyles } from '@material-ui/core';
import PageTitle from '../../components/PageTitle';
import ReportList from '../Report/Components/ReportList';
import Viewer from './Components/Viewer';
import trans from './../../i18n/tran';
const Report = ({ classes, theme, ...props }) => {
  return (
    <React.Fragment>
      <PageTitle title={trans('REPORT_VIEWER_TITLE', props.lang)} />
      <Viewer
        report={props.report}
        execReport={props.execReport}
        paramChanged={props.paramChanged}
      />
      <ReportList reports={props.reports} />
    </React.Fragment>
  );
};

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(Report);
