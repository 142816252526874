import React from 'react';

import {
  withStyles,
  Typography,
  TableContainer,
  Button,
} from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Parameters from './Parameters';
import trans from '../../../i18n/tran';
import GridContainer from '../../../components/Grid/GridContainer.js';
import GridItem from '../../../components/Grid/GridItem.js';
import { CSVLink } from 'react-csv';

const Viewer = ({ classes, theme, ...props }) => {
  const report = props.report;
  return (
    <>
      {report && (
        <React.Fragment>
          <Typography variant="h2">{props.report.Title}</Typography>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6} lg={4} className={classes.grid}>
               <Parameters report={report} paramChanged={props.paramChanged} />
               <Button
                    color="primary"
                    variant="contained"
                    size="medium"
                    onClick={e => {
                      props.execReport(e);
                    }}
                  >
                  {trans('REPORT_VIEWER_RUN', props.lang)}
                </Button>
                <CSVLink
                    style={{
                      backgroundColor: theme.palette.text.primary,
                      color: 'white',
                      textAlign: 'center',
                      textDecoration: 'none',
                      display: 'inline-block',
                      textTransform: 'uppercase',
                      padding:"10px",
                      marginLeft:"10px"
                    }}
                    filename={
                     report.Title +(new Date().toLocaleDateString('es')) + '.csv'
                    }
                    data={report.Data}
                  >
                    Descargar CSV
                  </CSVLink>

            </GridItem>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table" className={classes.table}>
                <TableHead>
                  <TableRow>
                    {report.Columns.map((col, index) => (
                      <TableCell key={index}>{col.Name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.Data.map((row, rowindex) => (
                    <TableRow key={rowindex} className={classes.tableRow}>
                      {Object.keys(row).map((key, index) => (
                        <TableCell key={index} className={classes.tableCell}>{row[key]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>    
          </GridContainer>          
        </React.Fragment>
      )}
    </>
  );
};

const styles = theme => ({
  grid: {
    padding: '20px 15px !important',
  },
  tableRow: {
  },
  tableCell: {
    maxWidth:"200px",
    wordWrap: "break-word"
  },
  table: {
  },
  tableContainer: {
    maxHeight: "800px",
    backgroundColor: "white"
  }
});

export default withStyles(styles, { withTheme: true })(Viewer);
