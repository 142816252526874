import React from 'react';
import {
  withStyles,
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
} from '@material-ui/core';
import Widget from '../../../components/Widget';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import ErrorIcon from '@material-ui/icons/Error';
import Upload from './DropZone/upload/Upload';
import {
  uploadImageExtensions,
  uploadVideoExtensions,
  // Environment,
  maxUploadImageFiles,
  maxUploadVideoFiles,
  maxUploadImageFilesMessage,
  maxUploadVideoFilesMessage,
} from '../../../components/Constants';
import trans from './../../../i18n/tran';

const Location = ({ classes, theme, onChange, value, ...props }) => {
  // console.log(props.lang)
  return (
    <Widget
      overflow={true}
      focusable={
        <Focusable id={props.focusId} refList={props.refList}></Focusable>
      }
      title={trans('NEW_CAMPAIGN_MULTIMEDIA', props.lang)}
      bodyClass={classes.tableWidget}
    >
      {props.invalidTypeFile ? (
        <div style={{ width: '80%', color: 'red' }}>
          <div className={classes.errorIcon}>
            <ErrorIcon></ErrorIcon>
          </div>
          <div
            style={{
              fontSize: props.ErrorfontSize ? props.ErrorfontSize : '0.8rem',
            }}
          >
            {trans('NEW_CAMPAIGN_MESSAGE10', props.lang)}
          </div>
          <br></br>
        </div>
      ) : (
        ''
      )}
      {props.invalidDimension ? (
        <div style={{ width: '80%', color: 'red' }}>
          <div className={classes.errorIcon}>
            <ErrorIcon></ErrorIcon>
          </div>
          <div
            style={{
              fontSize: props.ErrorfontSize ? props.ErrorfontSize : '0.8rem',
            }}
          >
            {trans('NEW_CAMPAIGN_MESSAGE16', props.lang)}
          </div>
          <br></br>
        </div>
      ) : (
        ''
      )}

      {props.invalidSize ? (
        <div style={{ width: '80%', color: 'red' }}>
          <div className={classes.errorIcon}>
            <ErrorIcon></ErrorIcon>
          </div>
          <div
            style={{
              fontSize: props.ErrorfontSize ? props.ErrorfontSize : '0.8rem',
            }}
          >
            {trans('NEW_CAMPAIGN_MESSAGE11', props.lang)}
          </div>
          <br></br>
        </div>
      ) : (
        ''
      )}
      {props.invalidDuration ? (
        <div style={{ width: '80%', color: 'red' }}>
          <div className={classes.errorIcon}>
            <ErrorIcon></ErrorIcon>
          </div>
          <div
            style={{
              fontSize: props.ErrorfontSize ? props.ErrorfontSize : '0.8rem',
            }}
          >
            {trans('NEW_CAMPAIGN_MESSAGE12', props.lang)}
          </div>
          <br></br>
        </div>
      ) : (
        ''
      )}
      {props.invalidMaxFiles ? (
        <div style={{ width: '80%', color: 'red' }}>
          <div className={classes.errorIcon}>
            <ErrorIcon></ErrorIcon>
          </div>
          <div
            style={{
              fontSize: props.ErrorfontSize ? props.ErrorfontSize : '0.8rem',
            }}
          >
            {props.maxUploadFilesMessage}
          </div>
          <br></br>
        </div>
      ) : (
        ''
      )}
      <Grid container spacing={2}>
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="uploadType"
              name="uploadType"
              value={props.selectedUploadType}
              onChange={(e, value) => {
                props.changeSelectedUploadType(value);
                //console.log(value)
                let validFileExtensions = [];
                let maxUploadFiles = maxUploadImageFiles;
                let maxUploadFilesMessage = maxUploadImageFilesMessage;
                if (value === '1') validFileExtensions = uploadImageExtensions;
                if (value === '2') {
                  maxUploadFiles = maxUploadVideoFiles;
                  maxUploadFilesMessage = maxUploadVideoFilesMessage;
                  validFileExtensions = uploadVideoExtensions;
                }
                props.changeUploadAllowedFiles(validFileExtensions);
                props.changeMaxUploadFiles(maxUploadFiles);
                props.changeMaxUploadFilesMessage(maxUploadFilesMessage);
              }}
            >
              <Grid item xs={4} md={4} lg={4}>
                <FormControlLabel
                  value={'1'}
                  control={<Radio />}
                  label={trans('IMAGE', props.lang)}
                />
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <FormControlLabel
                  value={'2'}
                  control={<Radio />}
                  label={trans('VIDEO', props.lang)}
                />
              </Grid>
            </RadioGroup>
          </FormControl>
        </div>
      </Grid>
      <Upload
        showAdErrors={props.showAdErrors}
        id="dropzone"
        ErrorIconClass={classes.errorIcon}
        ErrorMessageClass={classes.warningMessage}
        maxListSize={props.maxUploadFiles}
        minListSize={1}
        maxListSizeMessage={props.maxUploadFilesMessage}
        minListSizeMessage="Seleccione al menos un archivo para cargar"
        invalidFileTypeMessage="Debe seleccionar archivos de múltimedia válidos"
        placeholder={'Seleccione archivo'}
        ErrorIcon={<ErrorIcon />}
        changeError={props.changeHasErrorUpload}
        Error={props.hasErrorUpload}
        list={props.adFiles}
        changeList={props.changeAdFiles}
        reset={props.reset}
        changeReset={props.changeReset}
        visiblePreview={props.visiblePreview}
        changeVisiblePreview={props.changeVisiblePreview}
        uploadAllowedFiles={props.uploadAllowedFiles}
        invalidTypeFile={props.invalidTypeFile}
        selectedUploadType={props.selectedUploadType}
        changeInvalidTypeFile={props.changeInvalidTypeFile}
        changeInvalidDimension={props.changeInvalidDimension}
        changeInvalidSize={props.changeInvalidSize}
        changeInvalidDuration={props.changeInvalidDuration}
        changeInvalidMaxFiles={props.changeInvalidMaxFiles}
        lang={props.lang}
      />
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(Location);
