import React, { FC } from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { palette } from '../../theme';

import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { AdAccountI } from '../../types';

interface AdAccountCardProps {
  account: AdAccountI;
  actionPress: () => void;
}

export const AdAccountCard: FC<AdAccountCardProps> = ({
  account,
  actionPress,
}) => {
  const classes = useStyles();
  const selectedItem = account.AssignedToCurrent;
  const available = account.AssignedToCurrent || !account.AssignedToAccount;
  const IconComponent = selectedItem ? RemoveIcon : AddIcon;
  return (
    <Card
      className={`${classes.cardRoot} ${
        selectedItem ? classes.cardRootSelected : ''
      }`}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title}>{account.Title}</Typography>
        <Typography className={classes.detail}>{account.ExtId}</Typography>

        <Grid container justifyContent="flex-end">
          {available ? (
            <IconButton
              className={
                selectedItem
                  ? classes.roundButtonRemove
                  : classes.roundButtonAdd
              }
              style={{
                background: selectedItem
                  ? palette.purpleDeep
                  : palette.purpleRain,
              }}
              onClick={actionPress}>
              <IconComponent />
            </IconButton>
          ) : (
            <div style={{ height: 24 }} />
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      marginBottom: 0,
      marginTop: 0,
      paddingBottom: 0,
    },
    cardRootSelected: {
      boxShadow:
        '0px 2px 2px  #8036e9, 0px 3px 1px #8036e9, 0px 1px 5px #8036e9',
      borderRadius: '5px',
      border: '1.5px solid #8036E9',
    },
    cardContent: { marginBottom: 0, paddingBottom: 0 },
    roundButtonAdd: {
      borderRadius: '24px',
      backgroundColor: palette.purpleAly,
      color: palette.white,
      height: '24px',
      width: '24px',
    },
    roundButtonRemove: {
      borderRadius: '24px',
      backgroundColor: palette.deepPurple,
      color: palette.white,
      height: '24px',
      width: '24px',
    },
    normalText: {
      textTransform: 'none',
      color: 'black',
      fontFamily: 'Montserrat-Medium',
      fontSize: 13,
    },
    title: {
      fontFamily: 'Montserrat',
      fontSize: '12px',
      lineHeight: '16px',
    },

    detail: {
      fontFamily: 'Montserrat-Medium',
      fontSize: '10px',
      lineHeight: '16px',
      marginBottom: 20,
      color: '#9898AB',
    },
    indicator: {
      backgroundColor: palette.purple,
      height: '10px',
      top: '45px',
    },
  }),
);
