export function toUrlParams(list, prePend = '?') {
  let url = '';
  if (list && list.length) {
    let strs = [];
    list.forEach(ele => {
      for (const prop in ele) {
        if (Array.isArray(ele[prop])) {
          strs.push(`${prop}=${ele[prop].join(',')}`);
        } else {
          strs.push(`${prop}=${ele[prop]}`);
        }
      }
    });
    url = `${prePend}${strs.join('&')}`;
  }
  return url;
}

// parse this structure:
// utm_source=PruebasUTM&utm_medium=LocalHost&utm_campaign=PruebasDeUTMenGA
// and returns value from key=value expression
// utm_campaign
// example: findNeedleFromURL("utm_source=PruebasUTM&utm_medium=LocalHost&utm_campaign=PruebasDeUTMenGA","utm_campaign")
// returns: PruebasDeUTMenGA
export function findNeedleFromURL(url, needle) {
  let res;
  if (url && url.length && url.length > 0) {
    let params = url.split('&');
    params.forEach(param => {
      let vals = param.split('=');
      if (vals.length > 1 && vals[0] === needle) {
        res = vals[1];
      }
    });
  }
  return res;
}

export function findNeedleInURL(url, needle) {
  let res;
  if (url && url.length && url.length > 0) {
    let params = url.split('?');
    params.forEach(param => {
      let vals = param.split('=');
      if (vals.length > 1 && vals[0] === needle) {
        res = vals[1];
      }
    });
  }
  return res;
}
