import { compose, withHandlers, lifecycle } from 'recompose';
import View from './EnhancedTable';
//import { saveAssignRequest } from "../../components/ApiClient/Assign";

const mycompose = compose(
  lifecycle({
    componentDidMount() {},

    componentWillUnmount() {},
    componentDidUpdate() {
      if (this.props.List && this.props.List.length > 0) {
        for (let i = 0; i < this.props.List.length; i++) {
          let element = document.getElementById(
            this.props.List[i].id.toString(),
          );

          if (element) {
            element.value = this.props.List[i].value;
          }
        }
      }
    },
  }),
  withHandlers({}),
);

export default mycompose(View);
