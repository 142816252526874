/** CONVERSION */
import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import GridContainer from '../../../components/Grid/GridContainer.js';
import GridItem from '../../../components/Grid/GridItem.js';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
} from '@material-ui/core';

import Widget from '../../../components/Widget/WidgetContainer';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import trans, {NO_TRANS_AVAIL} from '../../../i18n/tran';
import { orange } from '@material-ui/core/colors';
import FiberNewIcon from '@material-ui/icons/FiberNew';

import { FunnelChart } from 'react-funnel-pipeline'
import 'react-funnel-pipeline/dist/index.css';

const removePrefix = (value, prefix) =>
   value.startsWith(prefix) ? value.slice(prefix.length) : value;

const conv_prefix = "offsite_conversion.";

const funnelPallette = [
  "#4E2FCC",
  "#5B35E5",
  "#652BE2",
  "#6D45DB",
  "#804AF5",
  "#755DF0",
  "#846CEA",
  "#887CF2",
  "#9D74F5",
  "#AC73F9",
  "#BA8AFC",
  "#B190F9",
  "#CCA9FD",
  "#D4BBFD"
]

function newCol(name, sum) {
  return {
    name: name,
    sum: sum,
    label: undefined,
    toolTip: undefined,
  }
}

function translateCols(cols, lang="es") {
  // console.log(cols)
  for (let index = 0; index < cols.length; index++) {
    const ele = cols[index];
    const nameKey = removePrefix(ele.name, conv_prefix);
    const toolTipKey = `${nameKey}_tool_tip`;
    ele.label = trans(nameKey, lang)===NO_TRANS_AVAIL? ele.name: trans(nameKey, lang);
    ele.toolTip = trans(toolTipKey, lang)===NO_TRANS_AVAIL? ele.name: trans(toolTipKey, lang);
  }
}

/*
Creates an object with two arrays: rows and cols
Cols are same data agregated (sum)
A col has a name and its sum
Rows are a detail value by date
*/
function ProcessFacebookData(params) {
  let rows = [];
  let cols = [];
  if (!params || params.length===0) {
    return { cols:cols, rows:rows};
  }
  const data = params[0];
  cols.push(newCol("DATE", Number.POSITIVE_INFINITY));
  if (data) {
    for (const item of data) {
      let Obj = {"DATE":item.date_start};
      if(item.actions) {
        for (const action of item.actions) {
          // take into account only stats that starts with conv_prefix
          if (action.action_type.startsWith(conv_prefix)) {
            // add column if doesn't exist
            let col = cols.find( (c)=> c.name===action.action_type);
            if (!col) {
                col = newCol(action.action_type, 0);
                cols.push(col);
            }
            Obj[col.name] = action.value;
            // update col sum
            const val = parseInt(action.value, 10);
            col.sum += val;
          }        
        }
        rows.push(Obj);
      }        
    }
  }
  return { cols:cols, rows:rows};
}

const NoInfo = (props) => {
  return (
    <Widget>
        <Typography>{trans('LOADING', props.lang)}...</Typography>
      </Widget>
  )
}

const Details = (props) => {
  return (
    <TableContainer>
      <Table className="mb-0">
        <TableHead><TableRow>
        { props.cols.sort((a,b)=> a.sum<b.sum? 1: -1 ).map ( (h, index) => 
            <TableCell key={index} align='center'> 
                { h.label }
            </TableCell> 
            )
          }
        </TableRow></TableHead>
        <TableBody>
          { props.rows.map ( (row, rowindex) =>
            <TableRow key={rowindex}>
              { props.cols.map ( (h, index) => <TableCell align='center' key={index}> {row[h.short] || row[h.name]}</TableCell> )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const Summary = (props) => {
  return (
    <TableContainer>
      <Table className="mb-0">
        <TableHead><TableRow>
          { props.cols.filter( (col) => col.name!=="DATE").sort((a,b)=> a.sum<b.sum? 1: -1 ).map ( (h, index) => 
            <TableCell key={index} align='center'> 
                { h.label }
            </TableCell> 
            )
          }
        </TableRow></TableHead>
        <TableBody>
        <TableRow>
        { props.cols.filter( (col) => col.name!=="DATE").sort((a,b)=> a.sum<b.sum ? 1: -1).map ( (h, index) => 
           <TableCell key={index} align='center'> 
            {h.sum} 
            </TableCell>
          )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}


const Dashboard36 = ({ classes, theme, ...props }) => {
  const [expanded1, setExpanded1] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [expanded3, setExpanded3] = React.useState(true);

  const handleChange = panel => (event, newSate) => {
    if (panel === 'panel1') {
      setExpanded1(newSate);
    }
    if (panel === 'panel2') {
      setExpanded2(newSate);
    }
    if (panel === 'panel3') {
      setExpanded3(newSate);
    }    
  };

  if (props.isLoading && !props.isPrintingPage) {
    return (
      <NoInfo lang={props.lang} />
    );
  } 
  else if (props.data && props.data.length === 0) {
    return (
      <NoInfo lang={props.lang} />
    );
  } 
  else if (props.data && props.data.Counter === 0) {
    return (
      <NoInfo lang={props.lang} />
    );
  } 
  else if (props.data && typeof props.data.Actions) {
    if (props.resizeRefresh) {
      return <div></div>;
    } else {
      let conv = ProcessFacebookData(props.data);
      translateCols(conv.cols, props.lang);
      let funnel = [
        { 
          name: trans('impressions', props.lang), 
          value:  props.data[1].Impressions,
          toolTip: trans('impressions_tool_tip', props.lang)
        },
        { 
          name: trans('clicks', props.lang), 
          value:  props.data[1].LinkClicks,
          toolTip: trans('clicks_tool_tip', props.lang)
        },
      ];

      conv.cols.filter( (col) => col.name!=="DATE").sort((a,b)=> a.sum<b.sum ? 1: -1).forEach(ele => {
        funnel.push({
          name:ele.label,
          value: ele.sum,
          toolTip: ele.toolTip
        })
      });

      return (
        <>
          <Accordion id="panel1" expanded={expanded1} onChange={handleChange('panel1')}>
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1b-content"
              id="panel1b-header"
            >
              <Typography className={classes.heading}>
                {trans('CONVERSION_INDICATORS', props.lang)}{' '}
                <FiberNewIcon fontSize="medium" style={{ color: orange[500] }} />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <GridContainer justifyContent="center">
                    <GridItem xs={6} sm={6} md={6} lg={6}>
                      <FunnelChart 
                          getToolTip={(row)=> `${row.toolTip}`}
                          data={funnel}
                          pallette={funnelPallette}
                        />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  {/* Summary Accordeon */}
                  <Accordion id="panel3" expanded={expanded3} onChange={handleChange('panel3')}>
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                    >
                      <Typography className={classes.heading}>
                        {trans('CONVERSION_INDICATORS_SUM', props.lang)}{' '}
                      </Typography>
                      </AccordionSummary>
                    <AccordionDetails>
                      <Summary cols={conv.cols} lang={props.lang} />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion id="panel2" expanded={expanded2} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography className={classes.heading}>
                        {trans('CONVERSION_INDICATORS_SUB', props.lang)}{' '}
                      </Typography>
                      </AccordionSummary>
                    <AccordionDetails>
                      <Details cols = {conv.cols} rows={conv.rows} lang={props.lang} />
                    </AccordionDetails>
                  </Accordion>
                </GridItem>
              </GridContainer>
            </AccordionDetails>
          </Accordion>
        </>
      );
    }
  } else
    return (
      <Widget>
        <Typography>No hay datos para mostrar</Typography>
      </Widget>
    );
};

const styles = theme => ({
  accordionSummary: {
    flexDirection: 'row-reverse'
  },
  heading: {
    fontSize: '1.2rem',
  },
  tableWidget: {
    overflowX: 'auto',
    fontSize: '0.9rem',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  mainChartHeaderLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
  },
  divider: {
    minHeight: '3px',
    margin: '10px',
  },
  mainChartHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mainChartHeaderLabels: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      order: 3,
      width: '100%',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartLegentElement: {
    fontSize: '18px !important',
    marginLeft: theme.spacing(1),
  },
  cardStyle: {
    flexDirection: 'column',
    minHeight: '400px',
    flexFlow: 'column wrap',
  },
  cardStyleLow: {
    flexDirection: 'column',
    minHeight: '400px',
    flexFlow: 'column wrap',
  },
  cardCategory: {
    color: theme.palette.text.primary,
    fontSize: '1.1rem',
    paddingTop: '10px',
    marginBottom: '0',
    marginTop: '0',
    margin: '0',
  },
  cardTitle: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    fontWeight: '300',
    marginTop: '30px',
    marginBottom: '25px',
    minHeight: '32px',
    '& small': {
      color: theme.primary,
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  metric: {
    textAlign: 'right',
  },
  metricText: {
    textAlign: 'left',
  },
});

export default withStyles(styles, { withTheme: true })(Dashboard36);
