import React, { Component } from 'react';
import 'date-fns';
import { es } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Slider, Grid } from '@material-ui/core';
// import { fakeLayoutLoading } from "../../utils/functions"
import { calculateRemainingDays } from '../../utils/functions';

export default class DatePickerWithSlider extends Component {
  constructor(props) {
    super(props);
    // validates all inputs as props necesarily to work
    if (typeof props.selectedDates === 'undefined') {
      console.log('WARNING:selectedDates array is undefined');
    }
    if (typeof props.dateRange === 'undefined') {
      console.log('WARNING:selectedDates array is undefined');
    }
    if (typeof props.sliderValues === 'undefined') {
      console.log('WARNING:sliderValues array is undefined');
    } else {
      console.log('props.sliderValues is ok');
    }
    //console.log(this.props.sliderValues)
    // console.log(props.selectedDates)
    let state = {
      initDateInvalid: false,
      endDateInvalid: false,
      /*sliderValue: [
				((props.selectedDates[0] - props.dateRange[0]) /
					86400000 /
					((props.dateRange[1] - props.dateRange[0]) / 86400000)) *
				100,
				100 -
				((props.dateRange[1] - props.selectedDates[1]) /
					86400000 /
					((props.dateRange[1] - props.dateRange[0]) / 86400000)) *
				100,
			],
			// dates: [props.selectedDates[0], props.selectedDates[1]],
			*/
      dates: props.selectedDates,
      dateRange: props.selectedDates,
      dateEqualOrBiggerThanToday: true,
    };

    if (!props.showErrors) {
      if (props.changeError) {
      }
    } else if (props.changeError) {
    }

    if (props.monthSelector) {
    
    }
    this.state = state;
  }
 
  render() {
    return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={6} md={6} style={{ margin: 'auto' }}>
              {this.state.initDateInvalid ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div>{this.props.ErrorIcon}</div>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    Fecha Inicio Inválida{' '}
                  </div>
                </div>
              ) : (
                ''
              )}
              {this.state.endDateInvalid && !this.state.initDateInvalid ? (
                <div
                  style={{ width: '80%', color: 'red', visibility: 'hidden' }}
                >
                  <div>{this.props.ErrorIcon}</div>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    Fecha Fin Inválida{' '}
                  </div>
                </div>
              ) : (
                ''
              )}

              {this.props.showErrors &&
              !this.state.endDateInvalid &&
              !this.state.initDateInvalid &&
              !this.state.dateEqualOrBiggerThanToday ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div>{this.props.ErrorIcon}</div>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    Fecha inicio no puede ser menor al día actual
                  </div>
                </div>
              ) : (
                ''
              )}

              {this.props.showErrors &&
              !this.state.endDateInvalid &&
              !this.state.initDateInvalid &&
              this.state.dates[0].getTime() > this.state.dates[1].getTime() ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    {this.props.ErrorIcon}
                  </div>
                  Fecha inicio no puede ser mayor a fecha fin{' '}
                </div>
              ) : this.props.showErrors &&
                calculateRemainingDays(this.state.dates) < 2 ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    {this.props.ErrorIcon}
                  </div>
                  Fecha inicio debe de ser menor a 2 dias de fecha fin{' '}
                </div>
              ) : (
                ''
              )}

              <KeyboardDatePicker
                style={{ width: '100%' }}
                inputProps={{
                  style: {
                    fontSize: '0.9rem',
                    textAlign: 'center',
                  },
                  readOnly: false,
                }}
                KeyboardButtonProps={{
                  disabled: this.props.disableStartDateButton,
                  size: 'small',
                }}
                autoOk
                maxDateMessage=""
                minDateMessage=""
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                id={this.props.id + '-startDate'}
                label="Fecha de Inicio"
                value={this.props.selectedDates[0]}
                onChange={(e, value) => {
                  // if (e) {
                  if (e && this.props.changeSelectedDates) {
                    this.props.changeSelectedDates([
                      e,
                      this.props.selectedDates[1],
                    ]);
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} style={{ margin: 'auto' }}>
              {this.state.endDateInvalid ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div>{this.props.ErrorIcon}</div>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    Fecha Fin Inválida{' '}
                  </div>
                </div>
              ) : (
                ''
              )}

              {this.state.initDateInvalid && !this.state.endDateInvalid ? (
                <div
                  style={{ width: '80%', color: 'red', visibility: 'hidden' }}
                >
                  <div>{this.props.ErrorIcon}</div>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    Fecha Inicio Inválida{' '}
                  </div>
                </div>
              ) : (
                ''
              )}

              {this.props.showErrors &&
              !this.state.endDateInvalid &&
              !this.state.initDateInvalid &&
              !this.state.dateEqualOrBiggerThanToday ? (
                <div
                  style={{ width: '80%', color: 'red', visibility: 'hidden' }}
                >
                  <div>{this.props.ErrorIcon}</div>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    Fecha inicio no puede ser menor al día actual
                  </div>
                </div>
              ) : (
                ''
              )}
              {this.props.showErrors &&
              !this.state.endDateInvalid &&
              !this.state.initDateInvalid &&
              this.state.dates[0].getTime() > this.state.dates[1].getTime() ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    {this.props.ErrorIcon}
                  </div>
                  Fecha fin no puede ser menor a fecha inicio{' '}
                </div>
              ) : this.props.showErrors &&
                calculateRemainingDays(this.state.dates) < 2 ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    {this.props.ErrorIcon}
                  </div>
                  Fecha fin debe de ser mayor a 2 dias de fecha inicio{' '}
                </div>
              ) : (
                ''
              )}
              <KeyboardDatePicker
                style={{ width: '100%' }}
                inputProps={{
                  style: {
                    fontSize: '0.9rem',
                    textAlign: 'center',
                  },
                  readOnly: false,
                }}
                KeyboardButtonProps={{
                  size: 'small',
                  disable: this.props.disableEndDateButton,
                }}
                autoOk
                maxDateMessage=""
                minDateMessage=""
                disableToolbar
                variant="inline"
                id={this.props.id + '-endDate'}
                label="Fecha Fin"
                format="dd/MM/yyyy"
                value={this.props.selectedDates[1]}
                onChange={(e, value) => {
                  if (e && this.props.changeSelectedDates) {
                    this.props.changeSelectedDates([
                      this.props.selectedDates[0],
                      e,
                    ]);
                  }
                  /*
									if (e) {
										if (e.getTime) {
											if (e.getTime()) {
												changeDate(
													this.state.dates[0],
													e,
													this,
													!this.state.endDateInvalid
												);
												let newState = this.state;
												newState.endDateInvalid = false;
												this.setState(newState);
												if (this.props.changeError) {
													this.props.changeError(false);
													validateError(this, newState);
												}
											} else {
												let newState = this.state;
												newState.endDateInvalid = true;
												this.setState(newState);
												if (this.props.changeError) {
													this.props.changeError(true);
												}
											}
										}
									}
									*/
                }}
              />
            </Grid>

            <div style={{ width: '5%', float: 'right' }}></div>
          </Grid>
        </MuiPickersUtilsProvider>

        {this.props.hideSlider ? (
          <div></div>
        ) : (
          <div style={{ width: '90%', paddingTop: '10px', margin: ' 0 auto' }}>
            <Slider
              id={'DateSlider'}
              step={1}
              value={[1, 100]}
              min={1}
              max={100}
              onChange={(e, value) => {
                if (e && this.props.changeSelectedDates) {
                  this.props.changeSelectedDates([
                    e,
                    this.props.selectedDates[1],
                  ]);
                }
               
              }}
              onChangeCommitted={(e, value) => {
                
              }}
              aria-labelledby="range-slider"
            />
          </div>
        )}
      </div>
    );
  }
}
