import React from 'react';

import { Grid, withStyles, Tooltip } from '@material-ui/core';
import PageTitle from '../../../../components/PageTitle';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Widget from '../../../../components/Widget/WidgetContainer';
import { styles } from '../../../../utils/styles';
import ModifyCampaign from './ModifyCampaign/Container';
const View = ({ classes, theme, onChange, value, ...props }) => {
  if (props.showModify && props.selectedAdminCampaigns.length > 0) {
    return (
      <React.Fragment>
        <Grid item xs={12} md={12} lg={12}>
          <Widget
            focusable={props.focusable}
            title={''}
            title2={
              <Grid item xs={12}>
                <div style={{ float: 'right' }}>
                  <Tooltip
                    open={props.showPermisionTooltip}
                    placement="left"
                    arrow={true}
                    title={
                      <div>
                        <h2>
                          Favor revisar que tenga permisos a la cuenta
                          publicitaria y a la fanpage de la campaña que desea
                          modificar
                        </h2>
                      </div>
                    }
                  >
                    <PriorityHighIcon
                      onClick={() => {
                        props.changeShowPermisionTooltip(
                          !props.showPermisionTooltip,
                        );
                      }}
                    />
                  </Tooltip>
                </div>
              </Grid>
            }
            bodyClass={classes.tableWidget}
            disableWidgetMenu={true}
          >
            <PageTitle title="Modificar Campaña" />
            <ModifyCampaign
              isModify={true}
              isLoadingLayout={props.isLoadingLayout}
              changeIsLoadingLayout={props.changeIsLoadingLayout}
              {...props}
            />
          </Widget>
        </Grid>
      </React.Fragment>
    );
  } else return '';
};

export default withStyles(styles, { withTheme: true })(View);
