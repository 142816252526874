import React, { FC } from 'react';
import { NavBarWrapper, useNavBarContext } from '../../components/NavBar';
import { useTranslation } from 'react-i18next';
import { Formik, Form, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import i18n from '../../../i18n';
import { ApplyCoupon, CouponValidate } from '../../rest';
import { useAccountIdContext } from '../../context/accountId';

export interface FormValues {
  coupon: string;
}
const initialValues = {
  coupon: '',
};
const validationSchema = Yup.object().shape({
  coupon: Yup.string()
    .trim(i18n.t('Value cannot include leading and trailing spaces'))
    .required(i18n.t('Please enter coupon')),
});

const PageContent: FC = () => {
  const { t } = useTranslation();

  const { currentAccount } = useAccountIdContext();

  const { showSnackbar } = useNavBarContext();
  const onSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>,
  ) => {
    if (!currentAccount) {
      showSnackbar('Please select an account id', 'warning');
      return;
    }
    const resultValidateCoupon = await CouponValidate(
      values.coupon.trim().toUpperCase(),
    );

    if (resultValidateCoupon.status !== 200) {
      formikHelpers.setErrors({ coupon: t('Invalid Coupon') });
      return;
    }

    if (
      Array.isArray(resultValidateCoupon.data.errors) &&
      resultValidateCoupon.data.errors?.length > 0
    ) {
      showSnackbar(
        resultValidateCoupon.data.errors.join(',') as string,
        'error',
      );
      return;
    }

    const applyResult = await ApplyCoupon({
      coupon: values.coupon,
      account_id: currentAccount?.Id,
    });

    if (applyResult.status !== 200) {
      showSnackbar(t('Error with coupon'), 'error');
      return;
    }
    if (applyResult.data.code !== 200) {
      showSnackbar(applyResult.data.desc as string, 'error');
      return;
    }

    if (applyResult.data.errors.length > 0) {
      showSnackbar(applyResult.data.errors.join(','), 'error');
      return;
    }

    showSnackbar(t('Coupon applied successful'), 'success');
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({ handleSubmit, getFieldProps, isValid }) => (
        <Form>
          <div className="flex items-center justify-center  pt-6">
            <div className="w-full max-w-xs ">
              <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="coupon">
                    {t('Apply coupon to account')}
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="coupon"
                    type="text"
                    placeholder={t('Coupon')}
                    {...getFieldProps('coupon')}
                  />
                  <ErrorMessage
                    name="coupon"
                    render={msg => (
                      <p className="text-red-500 text-xs italic">{msg}</p>
                    )}
                  />
                </div>

                <div className="flex items-center justify-between">
                  <button
                    disabled={!isValid}
                    onClick={() => handleSubmit()}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-25"
                    type="button">
                    {t('Apply')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const Page = () => {
  return (
    <NavBarWrapper>
      <PageContent />
    </NavBarWrapper>
  );
};
export default Page;
