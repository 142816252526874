import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Helmet } from 'react-helmet';
import { ListSubheader, Paper, Radio } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Color } from '@material-ui/lab/Alert/Alert';
import {
  AccountIdContextWrapper,
  useAccountIdContext,
} from '../context/accountId';
import i18n from 'i18next';
import Auth from '../../authentication/Auth';
import { Drawer, drawerBleeding, DrawerProps } from './Drawer';

interface NavBarContextProps {
  documentTitle: string;
  setDocumentTitle: (value: string) => void;
  menuDrawerState: boolean;
  profileDrawerState: boolean;
  configDrawerState: boolean;
  languageDrawerState: boolean;
  setLanguageDrawerState: React.Dispatch<React.SetStateAction<boolean>>;
  setConfigDrawerState: React.Dispatch<React.SetStateAction<boolean>>;
  setMenuDrawerState: React.Dispatch<React.SetStateAction<boolean>>;
  setProfileDrawerState: React.Dispatch<React.SetStateAction<boolean>>;
  showSnackbar: (text: string, severityValue: Color) => void;
}

const NavBarContext = createContext<NavBarContextProps>({
  documentTitle: '',
  setDocumentTitle: () => {},
  menuDrawerState: false,
  profileDrawerState: false,
  configDrawerState: false,
  languageDrawerState: false,
  setLanguageDrawerState: () => {},
  setConfigDrawerState: () => {},
  setMenuDrawerState: () => {},
  setProfileDrawerState: () => {},
  showSnackbar: () => {},
});

export const NavBarWrapper: FC = ({ children }) => {
  const classes = useStyles();

  const [documentTitle, setTitle] = useState('Aly');

  const [menuDrawerState, setMenuDrawerState] = useState(false);
  const [profileDrawerState, setProfileDrawerState] = useState(false);
  const [configDrawerState, setConfigDrawerState] = useState(false);
  const [languageDrawerState, setLanguageDrawerState] = useState(false);

  const setLanguageDrawerStateComplete = (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => {
    setProfileDrawerState(false);
    setLanguageDrawerState(value);
  };
  const setDocumentTitle = useCallback(
    (value: string) => setTitle(value),
    [setTitle],
  );

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [severity, setSeverity] = useState<Color>('success');
  const [snackbarText, setSnackBarText] = useState('');

  const handleSnackbarClose = useCallback(() => setSnackBarOpen(false), []);

  const showSnackbar = useCallback((text: string, severityValue: Color) => {
    setSnackBarText(text);
    setSeverity(severityValue);
    setSnackBarOpen(true);
  }, []);

  return (
    <NavBarContext.Provider
      value={{
        documentTitle,
        setDocumentTitle,
        menuDrawerState,
        setMenuDrawerState,
        setProfileDrawerState,
        profileDrawerState,
        showSnackbar,
        configDrawerState,
        setConfigDrawerState,
        languageDrawerState,
        setLanguageDrawerState: setLanguageDrawerStateComplete,
      }}>
      <div className={classes.root}>
        <Snackbar open={snackBarOpen} onClose={handleSnackbarClose}>
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            elevation={6}
            variant="filled">
            {snackbarText}
          </Alert>
        </Snackbar>

        <Helmet>
          <meta charSet="utf-8" />
          <title>{documentTitle}</title>
        </Helmet>
        <AccountIdContextWrapper>
          <NavBar />
          {children}
        </AccountIdContextWrapper>
      </div>
    </NavBarContext.Provider>
  );
};

export const useNavBarContext = () => {
  const context = useContext(NavBarContext);

  if (context === undefined) {
    throw new Error('cant be use out of provider');
  }
  return context;
};

export const NavBar: FC = () => {
  const classes = useStyles();

  const {
    documentTitle,
    menuDrawerState,
    setMenuDrawerState,
    profileDrawerState,
    setProfileDrawerState,
    configDrawerState,
    setConfigDrawerState,
    languageDrawerState,
    setLanguageDrawerState,
  } = useNavBarContext();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <IconButton
          onClick={() => setMenuDrawerState(prev => !prev)}
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu">
          <MenuIcon />
        </IconButton>

        <Typography variant="h6" className={classes.title}>
          {documentTitle}
        </Typography>
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => setConfigDrawerState(true)}
            color="inherit">
            <AccountCircle />
          </IconButton>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => setProfileDrawerState(true)}
            color="inherit">
            <AccountCircle />
          </IconButton>
        </div>
      </Toolbar>
      <MenuDrawer
        drawerState={menuDrawerState}
        setDrawerState={setMenuDrawerState}
      />
      <ConfigDrawer
        drawerState={configDrawerState}
        setDrawerState={setConfigDrawerState}
      />
      <ProfileDrawer
        drawerState={profileDrawerState}
        setDrawerState={setProfileDrawerState}
      />
      <LanguageDrawer
        drawerState={languageDrawerState}
        setDrawerState={setLanguageDrawerState}
      />
    </AppBar>
  );
};

const MenuDrawer: FC<DrawerProps> = ({ drawerState, setDrawerState }) => {
  return (
    <Drawer
      drawerState={drawerState}
      setDrawerState={setDrawerState}
      anchorPosition="bottom">
      <List>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Ads" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="New Campaign" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="New Product" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Modify Product" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Campaign manager" />
        </ListItem>
      </List>
    </Drawer>
  );
};

interface LanguageType {
  language: string;
  languageType: string;
}
const languages: LanguageType[] = [
  { languageType: 'en', language: 'English' },
  { languageType: 'es', language: 'Spanish' },
];

const savedLang = localStorage.getItem('lang');
const LanguageDrawer: FC<DrawerProps> = ({ drawerState, setDrawerState }) => {
  const [currentLanguage, setCurrentLanguage] = useState(savedLang ?? 'es');

  useEffect(() => {
    i18n.changeLanguage(currentLanguage ?? savedLang);
  }, [currentLanguage]);
  return (
    <Drawer
      drawerState={drawerState}
      setDrawerState={setDrawerState}
      anchorPosition="bottom">
      <List
        subheader={
          <ListSubheader style={{ background: 'white' }}>
            Language
          </ListSubheader>
        }>
        {languages?.map(item => (
          <ListItem key={item.languageType}>
            <ListItemText primary={item.language} />
            <ListItemSecondaryAction>
              <Radio
                edge="end"
                onChange={value => setCurrentLanguage(item.languageType)}
                checked={item.languageType === currentLanguage}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

const ConfigDrawer: FC<DrawerProps> = ({ drawerState, setDrawerState }) => {
  const { setLanguageDrawerState } = useNavBarContext();

  const logout = () => {
    const auth = new Auth();
    auth.logout();
  };
  return (
    <Drawer
      drawerState={drawerState}
      setDrawerState={setDrawerState}
      anchorPosition="bottom">
      <List>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="CSV Download" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="New Business" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="User Administration" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Business admin" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Billing" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText
            primary="Language"
            onClick={() => setLanguageDrawerState(true)}
          />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" onClick={() => logout()} />
        </ListItem>
      </List>
    </Drawer>
  );
};

const ProfileDrawer: FC<DrawerProps> = ({ drawerState, setDrawerState }) => {
  const { accounts, updateCurrentAccount, currentAccount } =
    useAccountIdContext();

  return (
    <Drawer
      drawerState={drawerState}
      setDrawerState={setDrawerState}
      anchorPosition="bottom">
      <Paper
        style={{
          maxHeight: `calc(50% - ${drawerBleeding}px)`,
          overflow: 'auto',
        }}>
        <List
          subheader={
            <ListSubheader style={{ background: 'white' }}>
              Switch Ad Profiles
            </ListSubheader>
          }>
          {accounts?.map(item => (
            <ListItem key={item.UniqueKey}>
              <ListItemText primary={item.Title} />
              <ListItemSecondaryAction>
                <Radio
                  edge="end"
                  onChange={value => updateCurrentAccount(item)}
                  checked={item === currentAccount}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Drawer>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      overflow: 'hidden',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },

    appBar: { boxShadow: 'none' },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    drawer: { zIndex: 2147483699 },
  }),
);
