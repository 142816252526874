import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  AdAccountStep,
  BusinessProfileStep,
  CheckoutStep,
  OnBoardingStep,
  PlanSelectionStep,
} from './steps';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { FormValues } from './steps/type';
import {
  CreateNewAccountWithPaymentParams,
  useCreateNewAccountWithPayment,
} from './rest';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { CouponValidate } from '../../rest';

interface OnboardingContextProps {
  isNavBackButtonVisible: boolean;
  showProcessComplete: () => void;
  isProcessComplete: boolean;
  setNavBackButtonVisible: (value: boolean) => void;
  activeStep: number;
  steps: OnBoardingStep[];
  isLastStep: () => boolean;
  showNextStep: () => void;
  showPreviousStep: () => void;
  tiers: TiersI | undefined;
  setTiers: (value: TiersI) => void;
}

const OnboardingContext = createContext<OnboardingContextProps>({
  isNavBackButtonVisible: false,
  isProcessComplete: false,
  showProcessComplete: () => {},
  setNavBackButtonVisible: () => {},
  activeStep: 0,
  steps: [],
  isLastStep: () => false,
  showNextStep: () => {},
  showPreviousStep: () => {},
  tiers: undefined,
  setTiers: () => {},
});

export interface TierBasic {
  id: number;
  name: string;
  features: string[];
  percentage: number;
  priceMonthly: string;
  support: string[];
}
export interface TiersI {
  data: TierBasic[];
  technical: string[];
}

const defaultSteps = [
  BusinessProfileStep,
  AdAccountStep,
  PlanSelectionStep,
  CheckoutStep,
];

const initialValuesFromSteps = defaultSteps.reduce(
  (values, { initialValues }) => ({
    ...values,
    // @ts-ignore
    ...initialValues,
  }),
  {},
) as FormValues;

const LOCAL_STORAGE_KEY = 'onboarding';

export const OnboardingWrapper: FC = ({ children }) => {
  const [steps] = useState(defaultSteps);
  const [activeStep, setActiveStep] = useState(0);
  const [tiers, setTiers] = useState<TiersI>();
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [errorSnackbarText, setErrorSnackbarText] = React.useState('');

  const handleCloseError = useCallback(() => setErrorSnackbar(false), []);

  const showError = useCallback((error: string) => {
    setErrorSnackbarText(error);
    setErrorSnackbar(true);
  }, []);

  const [initialValues, handleUpdateForm] = useLocalStorageState(
    LOCAL_STORAGE_KEY,
    initialValuesFromSteps,
  );

  const [isProcessComplete, setIsProcessComplete] = useState(false);

  const showProcessComplete = useCallback(
    () => {
      setIsProcessComplete(true);
      handleUpdateForm({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const isLastStep = useCallback(() => {
    return activeStep === steps.length - 1;
  }, [activeStep, steps]);

  const showNextStep = useCallback(() => {
    setActiveStep(Math.min(activeStep + 1, steps.length - 1));
  }, [activeStep, steps]);

  const showPreviousStep = useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep, setActiveStep]);

  const [isNavBackButtonVisible, setNavBackButtonVisible] = useState(false);

  const ActiveStep = steps[activeStep];
  const validationSchema = ActiveStep.validationSchema;

  const { createNewAccountWithPayment } = useCreateNewAccountWithPayment();
  const onSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>,
  ) => {
    const { setSubmitting } = formikHelpers;

    if (!isLastStep()) {
      setSubmitting(false);
      showNextStep();
      return;
    } else {
      if (
        values.billingCoupon &&
        values.billingCoupon.trim().toUpperCase().length > 0
      ) {
        const couponResult = await CouponValidate(values.billingCoupon);

        console.log('couponResult', couponResult);
        if (couponResult.data.errors && couponResult.data.errors?.length > 0) {
          showError(`${couponResult.data.errors}`);

          return;
        }
      }

      const params: CreateNewAccountWithPaymentParams = {
        accountId: values.businessId,
        email: values.email,
        accountName: values.businessName,
        taxId: values.taxId,
        country: values.billingCountry,
        address: values.billingAddress,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phoneNumber,
        tierId: values.planType,
        cardNumber: values.billingCardNumber,
        cardExpDate: values.billingCardExpiration.replace('/', ''),
        cardCVV: values.billingCardCVV,
        cardHolderName: values.billingCardName,
        billingCoupon: values.billingCoupon,
      };
      if (params.billingCoupon?.length === 0) {
        delete params.billingCoupon;
      }
      const response = await createNewAccountWithPayment(params);
      if (response.code === 200) {
        showProcessComplete();
      } else {
        if (response.more_info) {
          showError(`${response.more_info}`);
        } else {
          showError(`Error al procesar pago`);
        }
      }
    }
  };

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit,
  });

  const { values } = formik;

  useEffect(() => {
    handleUpdateForm(values);
  }, [handleUpdateForm, values]);

  return (
    <OnboardingContext.Provider
      value={{
        isProcessComplete,
        showProcessComplete,
        isNavBackButtonVisible,
        setNavBackButtonVisible,
        activeStep,
        steps,
        isLastStep,
        showNextStep,
        showPreviousStep,
        tiers,
        setTiers,
      }}>
      <Snackbar open={errorSnackbar} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          elevation={6}
          variant="filled">
          {errorSnackbarText}
        </Alert>
      </Snackbar>
      <FormikProvider value={formik}>{children}</FormikProvider>
    </OnboardingContext.Provider>
  );
};

export const useLocalStorageState = (key: string, value: unknown) => {
  const parsedLocalStorage = JSON.parse(localStorage.getItem(key) || '{}');
  const initialValue =
    Object.keys(parsedLocalStorage).length > 0 ? parsedLocalStorage : value;
  const [localStorageState, setLocalStorageState] =
    React.useState(initialValue);
  const handleUpdateLocalStorageState = React.useCallback(
    x => {
      setLocalStorageState(x);
      const {
        billingCardName,
        billingCardNumber,
        billingCardExpiration,
        billingCardCVV,
        ...rest
      } = x;
      localStorage.setItem(key, JSON.stringify(rest));
    },
    [key],
  );
  return [localStorageState, handleUpdateLocalStorageState];
};
export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext);

  if (context === undefined) {
    throw new Error('cant be use out of provider');
  }
  return context;
};
