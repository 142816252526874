import React from 'react';

import { Done, Error, HourglassEmpty } from '@material-ui/icons';

export default function DraftStatus(props) {
  return (
    <>
      {props.Status === 10 && <HourglassEmpty />}
      {props.Status === 20 && <Done style={{ color: 'green' }} />}
      {props.Status === 30 && <Error style={{ color: 'red' }} />}
    </>
  );
}
