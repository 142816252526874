import React, { Component } from 'react';
import './Dropzone.css';
import UploadImage from '../../../../../images/UploadImage.svg';
import trans from './../../../../../i18n/tran';

class Dropzone extends Component {
  constructor(props) {
    super(props);
    // console.log(props.lang)
    this.state = { hightlight: false };
    this.fileInputRef = React.createRef();
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.value = '';
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  resetRef() {
    let element = document.getElementById('inputTest');
    element.value = '';
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  removeFileByIndex(index) {
    if (this.fileInputRef.current) {
      if (this.fileInputRef.current.value) {
        this.fileInputRef.current.value = '';
      }
    }
  }

  onDragOver(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    this.setState({ hightlight: true });
  }

  onDragLeave(event) {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
    return (
      <div
        className={`Dropzone ${this.state.hightlight ? 'Highlight' : ''}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
      >
        <input
          id="inputTest"
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          accept="image/*, video/*"
          multiple
          onChange={this.onFilesAdded}
          onClick={(this.value = null)}
        />
        <img alt="upload" className="Icon" src={UploadImage} />
        <span className="labelDropzone">{trans('UPLOAD_MESSAGE1', this.props.lang)}</span>
        <span variant="caption" className="labelDropzoneOp">
          {trans('UPLOAD_MESSAGE2', this.props.lang)}
        </span>
      </div>
    );
  }
}

export default Dropzone;
