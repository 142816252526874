import { compose, withState, withHandlers, lifecycle } from 'recompose';
import AdAccountsView from './View';
import {
  getAdAccounts,
  inviteBlackbox,
  deletePermissions,
} from './fbAdAccounts';
import {
  alertPopUp,
  validateOnBoardingEnd,
  addAdAccountToAccount,
  deleteAdAccount,
  getFbAdAccounts,
} from '../../utils/functions';
import {
  sendGAEventAsociateAdAccount,
  sendGAEventRemoveAdAccount,
} from '../../utils/ga';
import { Track, NewEvent, NewTrackData } from 'src/utils/mixpanel';

// const event_prefix = "ad_accounts_";
const event_section = "ad_accounts";

function deleteFunction(id, props) {

  const eData = NewTrackData(props, {"ad_account_id": id})
  /*
  const eData = {
    "account_id": props.selectedTenants[0].value,
    "account_name": props.selectedTenants[0].label,
    "unique_key": props.selectedTenants[0].UniqueKey,
  }
  */
  Track(NewEvent(event_section, "delete", "click"), eData );

  deleteAdAccount(id, props, () => {
    // enviar evento GA de remove
    sendGAEventRemoveAdAccount(
      props.selectedTenants[0].label,
      props.selectedTenants[0].UniqueKey,
    );

    Track(NewEvent(event_section, "delete", "success"), eData );

    getAdAccounts((err, res) => {
      getAdAccountsResult(props, res, err);
    });
  });
}

export function bindData(props, callback) {
  getAdAccounts((err, res) => {
    getAdAccountsResult(props, res, err, false);
  });
}

export async function getAdAccountsResult(
  props,
  res,
  err,
  validateOnBoarding = true,
) {
  if (!err) {
    props.changePagePagination2(0);
    props.changePagePagination(0);
    if (res) {
      props.setAdAccounts(res);
    }
    if (validateOnBoarding) await validateOnBoardingEnd(props, true);
  } 
  props.changeIsLoadingLayout(false);
}
let mycompose = compose(
  withState('adAccounts', 'setAdAccounts', null),
  withState('link', 'setShowConfirmWindow'),
  withState('resetPermissions', 'setResetPermissions'),
  withState('isOpen', 'setIsOpen', false),
  withState('message', 'setMessage'),
  withState('pagePagination', 'changePagePagination', 0),
  withState('rowsPerPage', 'changeRowsPerPage', 5),
  withState('pagePagination2', 'changePagePagination2', 0),
  withState('rowsPerPage2', 'changeRowsPerPage2', 5),
  withState('delete', 'deleteAdAcount', null),
  withHandlers({
    deleteAdAcount: props => async e => {
      deleteFunction(e, props);
    },
    setShowConfirmWindow: props => async e => {

      const eData = NewTrackData(props, {"ad_account_id": e.alyId, "ad_account_ext_id": e.extId})
      /*
      const eventData = {
        "account_id": props.selectedTenants[0].value,
        "account_name": props.selectedTenants[0].label,
        "ad_account_id": e.alyId,
        "ad_account_extid": e.extId
      }*/
      Track(NewEvent(event_section, "assoc_with_aly", "click"), eData);
      /* AddAdAccountToAccount 
      * It does a POST to ${ApiAdminEndPoint}/v3.0/account/adAccount
      * It relates an ad account with account
      */
      await addAdAccountToAccount(
        props,
        props.selectedTenants[0].value,
        e,
        async (isError, alyMessage) => {
          if (e.status === 10 && !isError) {
            // add as agency in Facebook
            inviteBlackbox(
              e.id,
              async (err, message) => {
                if (props.changeIsLoadingLayout) {
                  props.changeIsLoadingLayout(false);
                }
                if (err) {
                  eData.error = alyMessage
                  // Track(event_prefix + 'assoc_with_aly_error', eventData );
                  Track(NewEvent(event_section, "assoc_with_aly","error", eData));
                } else {
                  // Track(event_prefix + 'assoc_with_aly_success', eventData );
                  Track(NewEvent(event_section, "assoc_with_aly","success", eData));
                }
                // it shows a popup
                alertPopUp(message, props, err, false, async () => {
                  if (err) {
                    deleteAdAccount(
                      e.alyId,
                      props,
                      () => {

                        getAdAccounts(
                          async (error, res) => {
                            await getAdAccountsResult(props, res, error);
                          },
                          props,
                          err,
                        );
                      },
                      true,
                    );
                  } else {
                    //Asociate a new account from fb
                    sendGAEventAsociateAdAccount(
                      props.selectedTenants[0].label,
                      props.selectedTenants[0].UniqueKey,
                    );

                    getFbAdAccounts(props, () => {
                      bindData(props);
                    });
                  }
                });
              },
              props,
            );
          } else {
            if (props.changeIsLoadingLayout) {
              props.changeIsLoadingLayout(false);
            }
            if (isError) {
              eData.error = alyMessage
              // Track(event_prefix +'assoc_with_aly_existing_error', eventData );
              Track(NewEvent(event_section, "assoc_with_aly","fail", eData));
            }else {
              // Track(event_prefix +'assoc_with_aly_existing_success', eventData );
              Track(NewEvent(event_section, "assoc_with_aly","success", eData));
            }

            // error when addAdAccountToAccount was called
            alertPopUp(alyMessage, props, isError, false, async () => {
              //Asociate an exist account in Aly
              sendGAEventAsociateAdAccount(
                props.selectedTenants[0].label,
                props.selectedTenants[0].UniqueKey,
              );
              getAdAccounts(async (err, res) => {
                await getAdAccountsResult(props, res, err, true);
              });
            });
          }
        },
      );
    },
    setResetPermissions: props => e => {
      console.log('reset permissions');
      deletePermissions((err, message) => {
        console.log(message);
      });
    },
  }),
  lifecycle({
    componentWillMount() {
      //bindData(this.props);
    },
    componentDidMount() {
      // gets ad accounts from fb through user api /adAccounts
      getFbAdAccounts(this.props, () => {
        bindData(this.props);
      });

      this.props.changeTenantChangeFunction({
        function: newTenenatValue => {
          // gets ad accounts from fb through user api /adAccounts
          getFbAdAccounts(this.props, () => {
            bindData(this.props);
          });
        },
      });
    },
    componentDidUpdate() {
      if (this.props.adAccounts === null) {
        if (!this.props.isLoadingLayout) {
          this.props.changeIsLoadingLayout(true);
        }        
      }
    },
    componentWillUnmount() {
      // //////console.log('compoent Will Unmount')
      // window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
  }),
);

export default mycompose(AdAccountsView);
