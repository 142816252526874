import { compose, withState, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import HeaderView from './HeaderView';
import { signOut } from '../../pages/login/LoginState';
import { toggleSidebar, toggleResizeRefresh } from '../Layout/LayoutState';
import { deletePermissions } from '../../pages/adaccountsV2/fbAdAccounts';
import { getLocalStorageTenant } from '../../utils/functions';
export default compose(
  connect(
    state => ({
      isSidebarOpened: state.layout.isSidebarOpened,
    }),
    { signOut, toggleSidebar, toggleResizeRefresh },
  ),
  withState('mailMenu', 'setMailMenu', null),
  withState('langMenu', 'setLangMenu', null),
  withState('notificationsMenu', 'setNotificationsMenu', null),
  withState('profileMenu', 'setProfileMenu', null),
  withState('resetPermissions', 'setResetPermissions'),

  withState('reportsMenu', 'setReportsMenu', null),

  lifecycle({
    componentDidUpdate() {
      if (
        this.props.selectedTenants &&
        this.props.selectedTenants.length === 0 &&
        getLocalStorageTenant()
      ) {
        this.props.changeSelectedTenants([getLocalStorageTenant()]);
      }
    },
  }),
  withHandlers({
    openProfileMenu: props => event => {
      props.setProfileMenu(event.currentTarget);
    },
    openMailMenu: props => event => {
      props.setMailMenu(event.currentTarget);
    },
    openLangMenu: props => event => {
      props.setLangMenu(event.currentTarget);
    },


    openReportMenu: props => event => {
      props.setReportsMenu(event.currentTarget);
    },



    closeDashboardsMenu: props => () => {
      props.setMailMenu(null);
    },
    closeProfileMenu: props => () => {
      props.setProfileMenu(null);
    },
    closeLangMenu: props => () => {
      props.setMailMenu(null);
    },


    closeReportMenu: props => () => {
      props.setLangMenu(null);
    },


    setResetPermissions: props => e => {
      deletePermissions((err, message) => {
        console.log('deletePermissions');
      });
    },
  }),
)(HeaderView);
