import Auth from '../../authentication/Auth';
import { saveAsset, getAdPreviewUrlAdminApi } from '../Constants';
import { alertPopUp } from '../../utils/functions';

export const saveAssetRequest = (externalId, file, callback) => {
  //let url = `${saveAsset}/${adAccountId}`
  let url = `${saveAsset}/${externalId ? externalId : ''}`;

  let myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('access_token')}`,
  );
  myHeaders.append('nonce', localStorage.getItem('nonce'));

  const formData = new FormData();
  formData.append('file', file, file.name);

  fetch(url, {
    method: 'POST',
    headers: myHeaders,
    body: formData,
  })
    .then(response => {
      if (response.status === 401) {
        let auth = new Auth();

        auth.sessionExpired();
      } else {
        return response.json();
      }
    })
    .then(resultString => {
      ////console.log(resultString)
      // ////console.log(`data set 0 ${resultString}`)
      if (resultString.success) {
        callback(undefined, resultString.payload);
      } else {
        if (resultString.message !== undefined)
          callback(resultString.message, undefined);
        else callback('Unknown error', undefined);
      }
    })
    .catch(err => {
      //console.log("error"+err)
      callback(err, undefined);
    });
};

export const getPreviewApiAdmin = (props, creativeSpecParameters, callback) => {
  let url = getAdPreviewUrlAdminApi;
  url +=
    '?ctaType=' +
    creativeSpecParameters.ctaType +
    '&ctaLink=' +
    creativeSpecParameters.ctaLink +
    '&description=' +
    creativeSpecParameters.description +
    '&message=' +
    creativeSpecParameters.message +
    '&name=' +
    creativeSpecParameters.name +
    '&image=' +
    creativeSpecParameters.image +
    '&pageId=' +
    creativeSpecParameters.pageId +
    '&adFormat=' +
    creativeSpecParameters.adFormat +
    '&adAccountExtId=' +
    creativeSpecParameters.adAccountExtId;

  let myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('access_token')}`,
  );
  myHeaders.append('nonce', localStorage.getItem('nonce'));

  fetch(url, {
    method: 'GET',
    headers: myHeaders,
  })
    .then(response => {
      if (response.status === 401) {
        let auth = new Auth();

        auth.sessionExpired();
      } else if (response.status === 404) {
        alertPopUp(
          'No se logró cargar la vista previa, favor revisar permisos de cuenta publicitaria',
          props,
          true,
        );
      } else {
        return response.json();
      }
    })
    .then(resultString => {
      ////console.log(resultString)
      // ////console.log(`data set 0 ${resultString}`)
      if (resultString.success) {
        callback(undefined, resultString.payload);
      } else {
        if (resultString.message !== undefined)
          callback(resultString.message, undefined);
        else callback('Unknown error', undefined);
      }
    })
    .catch(err => {
      //console.log("error"+err)
      callback(err, undefined);
    });
};
