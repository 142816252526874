import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  withStyles,
  Button,
  Grid,
} from '@material-ui/core';
import {
  homePagePagePath,
  release1AdsPagePath,
  createadPagePath,
  modifyProductPagePath,
  assignPagePath,
  exportPagePagePath,
  productPagePath,
  adaccountsPagePath,
  downloadstatsPagePath,
  newAccountPagePath,
  userPagePath,
  userPageId,
  billingPagePath,
  reportsPagePath,
  homePagePageId,
  release1AdsPageId,
  adaccountsPageId,
  exportPagePageId,
  downloadstatsPageId,
  createadPageId,
  productPageId,
  modifyProductPageId,
  assignPageId,
  billingPageId,
  newAccountPageId,
  hideTenantSelectorPageIds,
  onBoardingOK,
  subscriptionsPageId,
  subscriptionsPagePath,
  draftsPageId,
  draftsPagePath,
  reportsPageId,
  couponsPagePath,
  couponsPageId,


  salesReportPath, 
  salesReportPageId, 
  automaticReportPageId, 
  automaticReportPath
} from '../Constants';

import { Link } from 'react-router-dom';
import {
  Menu as MenuIcon,
  Wallpaper, Payment, ArrowBackIos,
  ArrowForwardIos, Drafts, Receipt,
  Assessment, CloudDownload,
  Dashboard, Group, Business,
  ControlPointRounded,
  AssignmentTurnedIn, Language,
  Home, Person, Build, Create, Replay,
  Policy, LocalOffer, BarChart, AccessTime
} from '@material-ui/icons';


import classNames from 'classnames';
import { Typography } from '../Wrappers';

import CardMembershipIcon from '@material-ui/icons/CardMembership';
import {
  refreshCustomPage,
  tenantValidation,
  addComponent,
} from '../../utils/functions';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  getHeaderButtonStyle,
  getHeaderButtonImageStyle,
} from '../../utils/styles';
import Select from 'react-select';
import { getWidthCode } from '../../utils/generalFunctions';

import logo from '../../images/ALY2.svg';
import { Spain, Usa } from '../Flags/Flags';
import trans from '../../i18n/tran';

const Header = ({
  classes,
  theme,
  isSidebarOpened,
  toggleSidebar,
  ...props
}) => {
  let UserCompleteName;
  let userName = '';
  let userPicture;
  if (localStorage.getItem('userName'))
    userName = JSON.parse(localStorage.getItem('userName'));
  if (localStorage.getItem('picture')) {
    userPicture = JSON.parse(localStorage.getItem('picture')).picture;
  }

  if (userName && userName.given_name && userName.family_name)
    UserCompleteName = userName.given_name + ',' + userName.family_name;
  else UserCompleteName = 'Usuario';
  if (sessionStorage.getItem('userPages') === undefined) {
    return <div></div>;
  } else
    return (
      <AppBar
        position="fixed"
        className={classes.appBar}
        onClick={() => {
          if (props.mailMenu) props.setMailMenu(null);
          if (props.profileMenu) props.setProfileMenu(null);
          if (props.reportsMenu) props.setReportsMenu(null);
        }}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start" component={Link} to={homePagePagePath}>
            <img
              src={logo}
              alt="aly-ai logo"
              className={classes.userIcon}
              height="50"
            ></img>
          </IconButton>
          {props.showSideBar ? (
            <IconButton
              onClick={() => {
                toggleSidebar();
                refreshCustomPage(
                  props.toggleResizeRefresh,
                  100,
                  props.sidebarTransitionTime
                    ? props.sidebarTransitionTime
                    : 250,
                  window.scrollX,
                  window.scrollY,
                );
              }}
              className={classNames(
                classes.headerMenuButton,
                classes.headerMenuButtonCollapse,
              )}
            >
              {isSidebarOpened ? (
                <ArrowBackIos
                  classes={{
                    root: classNames(
                      classes.headerIcon,
                      classes.headerIconCollapse,
                    ),
                  }}
                />
              ) : (
                <ArrowForwardIos
                  classes={{
                    root: classNames(
                      classes.headerIcon,
                      classes.headerIconCollapse,
                    ),
                  }}
                />
              )}
            </IconButton>
          ) : (
            ''
          )}

          <div className={classes.grow} />
          {!hideTenantSelectorPageIds.includes(props.customPageId) ||
            sessionStorage.getItem('onBoardingStatus') !==
            onBoardingOK.toString() ? (
            <Grid container>
              <Grid
                item
                xs={2}
                sm={6}
                style={
                  getWidthCode() === 'xs'
                    ? {
                      margin: 'auto',
                      paddingLeft: '5px',
                      paddingBottom: '10px',
                    }
                    : { margin: 'auto' }
                }
              >
                <Typography className={classes.typo} variant="h1" weight="bold">
                  {trans('NAV_BUSINESS', props.lang)}
                </Typography>
              </Grid>

              <Grid
                item
                xs={10}
                sm={6}
                style={
                  getWidthCode() === 'xs'
                    ? {
                      margin: 'auto',
                      paddingLeft: '5px',
                      paddingBottom: '10px',
                    }
                    : { margin: 'auto' }
                }
              >
                <Select
                  styles={theme.reactSelect}
                  value={props.selectedTenants}
                  options={props.selectorTenant}
                  placeholder={trans('SELECT_PLACEHOLDER', props.lang)}
                  noOptionsMessage={() => trans('SELECT_NO_OPS', props.lang)}
                  onChange={e => {
                    tenantValidation(props, e, props.customPageId, true, true);
                    window.location.reload();
                  }}
                ></Select>
              </Grid>
            </Grid>
          ) : (
            ''
          )}

          {/* Reports - Icon */}


          {addComponent(
            props,
            reportsPageId,
            <Button
            color="secondary"
            onClick={e => {
              props.openReportMenu(e);
              props.setProfileMenu(null);
              props.setLangMenu(null);
              props.setMailMenu(null);              
            }}
            onClose={props.closeReportMenu}
            style={getHeaderButtonStyle()}
          >
            <Assessment style={getHeaderButtonImageStyle('white')} />
          </Button>,
            ['DEV', 'QA', 'PROD'],
            true,
          )}

          
          

          {/* Hamburger - Icon */}

          <Button
            color="secondary"
            onClick={e => {
              props.openMailMenu(e);
              props.setProfileMenu(null);
              props.setLangMenu(null);
              props.setReportsMenu(null);
            }}
            onClose={props.closeMailMenu}
            style={getHeaderButtonStyle()}
          >
            <MenuIcon style={getHeaderButtonImageStyle('white')} />
          </Button>

          {/* Profile - Icono*/}

          <Button
            color="secondary"
            onClick={e => {
              props.openProfileMenu(e);
              props.setMailMenu(null);
              props.setLangMenu(null);
              props.setReportsMenu(null);
            }}
            style={getHeaderButtonStyle(true)}
          >
            {userPicture ? (
              <img
                alt=""
                style={getHeaderButtonImageStyle('white', true)}
                src={userPicture}
              ></img>
            ) : (
              <Person />
            )}
          </Button>

          {/* World - icon */}
          <Button
            color="secondary"
            onClick={e => {
              props.openLangMenu(e);
              props.setMailMenu(null);
              props.setProfileMenu(null);
              props.setReportsMenu(null);
            }
            }
            style={getHeaderButtonStyle()}
          >
            <Language style={getHeaderButtonImageStyle('white')} />
          </Button>

          {/* Reports - Menu */}
          <Menu
            onClick={() => {props.setReportsMenu(null);}}
            id="reports-menu"
            open={Boolean(props.reportsMenu)}
            anchorEl={props.reportsMenu}
            onClose={props.closeReportMenu}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            {addComponent(
              props,
              reportsPageId,
              <MenuItem
                onClick={() => props.setReportsMenu(null)}
                component={Link}
                to={reportsPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                id="reportsPageId"
              >
                <BarChart className={classes.profileMenuIcon} />
                {trans('NAV_REPORTS', props.lang)}
              </MenuItem>,
              ['DEV', 'QA', 'PROD'],
              true,
            )}

            {addComponent(
              props,
              release1AdsPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                component={Link}
                to={automaticReportPath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                id="adsMenuItem"
              >
                <AccessTime className={classes.profileMenuIcon} />
                {trans('NAV_REPORTE_AUTO', props.lang)}
              </MenuItem>,
            )}

          </Menu>


          {/* Dashboards - Menu*/}
          <Menu
            onClick={() => props.setMailMenu(null)}
            id="mail-menu"
            open={Boolean(props.mailMenu)}
            anchorEl={props.mailMenu}
            onClose={props.closeDashboardsMenu}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            {addComponent(
              props,
              homePagePageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                component={Link}
                to={homePagePagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                id="homePageId"
              >
                <Home className={classes.profileMenuIcon} />
                {trans('NAV_HOME', props.lang)}
              </MenuItem>,
              ['DEV', 'QA', 'PROD'],
              true,
            )}

            {addComponent(
              props,
              release1AdsPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                component={Link}
                to={release1AdsPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                id="adsMenuItem"
              >
                <Dashboard className={classes.profileMenuIcon} />
                {trans('NAV_ADS', props.lang)}
              </MenuItem>,
            )}
            {addComponent(
              props,
              createadPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                id={'createAdMenuItem'}
                component={Link}
                to={createadPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <Wallpaper className={classes.profileMenuIcon} />
                {trans('NAV_CREATE_CAMPAIGN', props.lang)}
              </MenuItem>,
            )}
            {addComponent(
              props,
              draftsPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                id={'adsCreatedMenuItem'}
                component={Link}
                to={draftsPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <Drafts className={classes.profileMenuIcon} />
                {trans('NAV_DRAFTS', props.lang)}
              </MenuItem>,
              ['DEV', 'QA', 'PROD'],
              true,
            )}
            {addComponent(
              props,
              productPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                id={'createProductMenuItem'}
                component={Link}
                to={productPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <ControlPointRounded className={classes.profileMenuIcon} />
                {trans('NAV_CREATE_PRODUCT', props.lang)}
              </MenuItem>,
            )}
            {addComponent(
              props,
              modifyProductPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                id={'modifyProductMenuItem'}
                component={Link}
                to={modifyProductPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <Create className={classes.profileMenuIcon} />
                {trans('NAV_MOD_PRODUCT', props.lang)}
              </MenuItem>,
            )}
            {addComponent(
              props,
              assignPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                id={'assignProductMenuItem'}
                component={Link}
                to={assignPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <AssignmentTurnedIn className={classes.profileMenuIcon} />
                {trans('NAV_ADMIN_CAMPAIGNS', props.lang)}
              </MenuItem>,
            )}

            {addComponent(
              props,
              assignPageId, // esto es lo que tengo que cambiar para que se mire -> salesReportPageId
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                id={'viewReportMenuItem'}
                component={Link}
                to={salesReportPath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <BarChart className={classes.profileMenuIcon} />
                {trans('RP_SALES_REPORT', props.lang)}
              </MenuItem>,
            )}

          </Menu>

          {/* Profile - Menu*/}
          <Menu
            id="profile-menu"
            open={Boolean(props.profileMenu)}
            anchorEl={props.profileMenu}
            onClose={props.closeProfileMenu}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            <div className={classes.profileMenuUser}>
              <Typography variant="h4" weight="medium">
                {UserCompleteName}
              </Typography>
            </div>

            {addComponent(
              props,
              downloadstatsPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                id={'createAdMenuItem'}
                component={Link}
                to={downloadstatsPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <CloudDownload className={classes.profileMenuIcon} />
                {trans('NAV_DOWNLOAD_CSV', props.lang)}
              </MenuItem>,
            )}
            {addComponent(
              props,
              newAccountPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                component={Link}
                to={newAccountPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                id={newAccountPageId}
              >
                <Business className={classes.profileMenuIcon} />
                {trans('NAV_NEW_BUSINESS', props.lang)}
              </MenuItem>,
            )}

            {addComponent(
              props,
              userPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                component={Link}
                to={userPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                id={userPageId}
              >
                <Group className={classes.profileMenuIcon} />
                {trans('NAV_USERS_ADMIN', props.lang)}
              </MenuItem>,
            )}
            {addComponent(
              props,
              adaccountsPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                component={Link}
                to={adaccountsPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                id={adaccountsPageId}
              >
                <Build className={classes.profileMenuIcon} />
                {trans('NAV_BUSINESS_MANAGEMENT', props.lang)}
              </MenuItem>,
            )}
            {addComponent(
              props,
              exportPagePageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                component={Link}
                to={exportPagePagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                id="exportPageId"
              >
                <Assessment className={classes.profileMenuIcon} />
                {trans('NAV_EXPORT_REPORT', props.lang)}
              </MenuItem>,
            )}

            {addComponent(
              props,
              couponsPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                component={Link}
                to={couponsPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                id="couponsPageId"
              >
                <LocalOffer className={classes.profileMenuIcon} />
                {trans('NAV_COUPONS', props.lang)}
              </MenuItem>,
            )}

            {addComponent(
              props,
              billingPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                component={Link}
                to={billingPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                id="billingPageId"
              >
                <Payment className={classes.profileMenuIcon} />
                {trans('NAV_BILLING', props.lang)}
              </MenuItem>,
            )}
            {addComponent(
              props,
              subscriptionsPageId,
              <MenuItem
                onClick={() => props.setProfileMenu(null)}
                component={Link}
                to={subscriptionsPagePath}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                id="subscriptionsPageId"
              >
                <CardMembershipIcon className={classes.profileMenuIcon} />
                {trans('NAV_SUBSCRIPTIONS', props.lang)}
              </MenuItem>,
            )}

            <MenuItem
              onClick={e => props.setResetPermissions()}
              id={'resetPermissionsMenuItem'}
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
            >
              <Replay className={classes.profileMenuIcon} />
              {trans('NAV_ASSETS_UPDATE', props.lang)}
            </MenuItem>
            <a target="_blank" href="https://aly-ai.com/politicas/" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <Policy className={classes.profileMenuIcon} />
                {trans('NAV_PRIVACY_POLICY', props.lang)}
              </MenuItem>
            </a>
            <MenuItem
              onClick={props.signOut}
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
            >
              <ExitToAppIcon className={classes.profileMenuIcon} />
              {trans('NAV_LOGOUT', props.lang)}
            </MenuItem>
            {localStorage.showBuild ? (
              <div style={{ float: 'right' }}>Build: {Build}</div>
            ) : (
              ''
            )}
          </Menu>

          {/* Language - Menu */}
          <Menu
            id="language-menu"
            open={Boolean(props.langMenu)}
            anchorEl={props.langMenu}
            onClose={props.closeLangMenu}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            {/* change to spanish */}
            <MenuItem
              id={'language-menu-es'}
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
              onClick={() => {
                props.closeLangMenu();
                props.setLang('es');
              }
              }
            >
              <Spain isCurrent={props.lang === "es"} />
            </MenuItem>
            <MenuItem
              id={'language-menu-en'}
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
              onClick={() => {
                props.closeLangMenu();
                props.setLang('en');
              }
              }
            >
              <Usa isCurrent={props.lang === "en"} />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
};

const styles = theme => ({
  separator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider,
  },
  createCampaignButton: {
    color: theme.palette.text.secondary,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.text.primary,
    },
  },
  appBar: {
    width: '100vw',
    zIndex: theme.zIndex.drawer + 101,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  grow: {
    flexGrow: 1,
  },

  headerMenu: {
    marginTop: theme.spacing(7),
  },
  headerMenuList: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerMenuItem: {
    color: theme.palette.primary.primary,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.text.hint,
      color: theme.palette.primary.main,
    },
  },
  headerMenuButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },
  headerIcon: {
    fontSize: 28,
  },
  headerIconCollapse: {
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.text.secondary,
    },
  },
  profileMenu: {
    minWidth: 265,
  },
  profileMenuUser: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  profileMenuItem: {
    color: theme.palette.text.secondary,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.secondary,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerIcons: {
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '2rem',
    flexShrink: '0',
    userSelect: 'none',
  },
  createAdButton: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: 'none',
  },
  sendButtonIcon: {
    marginLeft: theme.spacing(2),
  },
});

export default withStyles(styles, { withTheme: true })(Header);
