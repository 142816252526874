import React from 'react';

import { ReactComponent as Aly } from '../../assets/svg/aly.svg';
import { ReactComponent as StorePlus } from '../../assets/svg/storePlus.svg';
import { ReactComponent as User } from '../../assets/svg/user.svg';
import { ReactComponent as Spa } from '../../assets/svg/spa.svg';
import { ReactComponent as DoubleArrows } from '../../assets/svg/doubleArrows.svg';
import { ReactComponent as CreditCard } from '../../assets/svg/creditCard.svg';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

//import Spa from '@material-ui/icons/Spa';

export const CreditCardIcon = () => {
  const classes = useStyles();
  return <CreditCard className={classes.imageIcon} />;
};
export const DoubleArrowsIcon = () => {
  const classes = useStyles();
  return <DoubleArrows className={classes.imageIcon} />;
};
export const SpaIcon = () => {
  const classes = useStyles();
  return <Spa className={classes.imageIcon} />;
};
export const AlyIcon = () => {
  const classes = useStyles();
  return <Aly className={classes.imageIcon} />;
};

export const StorePlusIcon = () => {
  const classes = useStyles();
  return <StorePlus className={classes.imageIcon} />;
};

export const UserIcon = () => {
  const classes = useStyles();
  return <User className={classes.imageIcon} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageIcon: {
      height: '1.5em',
      width: '1.5em',
      top: '.5em',
      position: 'relative',
    },
  }),
);
