import { compose, withState, withHandlers, lifecycle } from 'recompose';
import View from './UpdateProductView';

const mycompose = compose(
  withState('filter', 'changeFilter', ''),
  withState('refresh', 'changeRefresh', false),
  lifecycle({
    componentDidMount() {

    },

    componentWillUnmount() {
      //window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
   
  }),
  withHandlers({}),
);

export default mycompose(View);
