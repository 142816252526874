import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import { Grid } from '@material-ui/core';
import {
  facebookProvider,
  instagramProvider,
  multipleProvider,
  locationComponentId,
  leadGenerationObjective,
  linkClicksObjective,
  titlesComponentId,
  descriptionComponentId,
  ctaComponentId,
  bodiesComponentId,
  attachmentsComponentId,
  adPreviewComponentId,
  budgetComponentId,
  leadGenerationFormComponentId,
  pixelComponentId,
  applicationComponentId,
  conversationComponentId,
  instagramAccountComponentId,
  linkComponentId,
  UTMComponentId,
  fanPageFocusId,
  fechaCampanaFocusId,
  productoFocusId,
  presupuestoFocusId,
  linkFocusId,
  tituloAnuncioFocusId,
  cuerpoAnuncioFocusId,
  uploadFocusId,
  biddingAmountFocusId,
  productComponentId,
  fanPageComponentId,
  biddingAmountComponentId,
  ctaFocusId,
  dateComponentId,
  conversationsObjective,
  conversionsObjective,
  appInstallObjective,
  campaignNameComponentId,
  campaignNameFocusId,
  ubicacionFocusId,
  campaignStatusComponentId,
  campaignStatusFocusId,
  genderAgeComponentId,
  genderFocusId,
  LookAlikeComponentId,
  LookAlikeFocusId,
  interestsComponentId,
  interestsFocusId,
  pixelFocusId,
  applicationFocusId,
  lowestCostWithouCapBidStrategy,
} from '../components/Constants';
import {
  validateUrl,
  calculateRemainingDays,
  isFacebookeUrl,
  changeLinkPreview,
} from '../utils/functions';

import trans from './../i18n/tran';

function getErrorGrid(errorMessage) {
  return {
    isError: true,
    message: (
      <Grid style={{ color: 'red' }} container spacing={0}>
        <Grid>
          <ErrorIcon />
        </Grid>
        <Grid style={{ height: '1.5rem', marginTop: 'auto' }}>
          {errorMessage}
        </Grid>{' '}
      </Grid>
    ),
  };
}
function getWarningGrid(errorMessage, height = '1.5rem', showWarning = true) {
  return {
    isError: false,
    message: (
      <Grid container spacing={0}>
        <Grid style={{ height: height, marginTop: 'auto' }}>
          {' '}
          {showWarning && <WarningIcon />}
          {errorMessage}
        </Grid>{' '}
      </Grid>
    ),
  };
}

/*
function InboundLenFilter(ele, min, max) {
  return ele.length<min || ele.length >= max;
}



function getTextFieldListLengthError(list, minSize, maxSize) {
  let errorArray = [];
  for (let i = 0; i < list.length; i++) {
    if (minSize) {
      if (list[i].length < minSize) {
        errorArray.push(i);
      }
    } else if (maxSize) {
      if (list[i].length >= maxSize) {
        errorArray.push(i);
      }
    }
  }
  return errorArray;
}

function includesIndex(list, index) {
  let found = false;
  list.forEach(element => {
    if (element === index) found = true;
  });
  return found;
}
*/
/*
function getTextFieldListLengthWarning(list, maxSize, index) {
  //for (let i = 0; i < list.length; i++) {
  if (maxSize) {
    if (list[index].length >= maxSize) {
      //warningArray.push(i);
      return true;
    }
  }
  //}
  //return warningArray;
  return false;
}
*/
function validateDecimal(decimalValue) {
  return !isNaN(decimalValue - parseFloat(decimalValue));
}
/*
function validateInteger(decimalValue) {
  return Number.isInteger(Number(decimalValue));
}*/
function validateMinDecimal(
  decimalValue,
  minDecimalValue,
  excludeMinDecimalValue,
) {
  if (minDecimalValue || minDecimalValue === 0) {
    if (!isNaN(parseFloat(decimalValue))) {
      if (parseFloat(decimalValue) < minDecimalValue && !excludeMinDecimalValue)
        return false;
      if (parseFloat(decimalValue) <= minDecimalValue && excludeMinDecimalValue)
        return false;
      return true;
    }
  } //if there is no minvalue to compare it means it should skip the validation
  else return true;
  return false;
}

function validateMaxDecimal(decimalValue, maxDecimalValue) {
  if (maxDecimalValue)
    if (!isNaN(decimalValue - parseFloat(decimalValue))) {
      if (parseFloat(decimalValue) >= maxDecimalValue) return false;
      return true;
    }
    //if there is no max to compare it means it should skip the validation
    else return true;
  return false;
}
function validateNumber(
  decimalValue,
  minDecimalValue,
  maxDecimalValue,
  validateDailyValue,
  campaignDates,
  minDecimalDailyValue,
  excludeMinDecimalValue,
  error,
  changeErrorFunction,
  optional,
  props
) {
  let minDecimalTotalValue = 0;
  if (validateDailyValue) {
    let days = calculateRemainingDays(campaignDates);
    minDecimalTotalValue = minDecimalDailyValue * days;
  }
  if (decimalValue.length > 0) {
    if (validateDecimal(decimalValue)) {
      if (validateDailyValue && minDecimalTotalValue > minDecimalValue)
        minDecimalValue = minDecimalTotalValue;
      if (
        validateMinDecimal(
          decimalValue,
          minDecimalValue,
          excludeMinDecimalValue,
        )
      ) {
        if (validateMaxDecimal(decimalValue, maxDecimalValue)) {
          if (error) changeErrorFunction(false);
          return '';
        } else {
          if (!error) changeErrorFunction(true);
          return getErrorGrid(
            trans('NEW_CAMPAIGN_MESSAGE21', props.lang).replace("$MAX", maxDecimalValue)
          );
        }
      } 
      else {
        if (!error) changeErrorFunction(true);
        let message = trans('NEW_CAMPAIGN_MESSAGE22',props.lang).replace("$VAL", minDecimalValue)
        const part2 = trans('NEW_CAMPAIGN_MESSAGE23', props.lang).replace("$MIN", minDecimalDailyValue)
        if (validateDailyValue) {
          message = message + part2
        }
        return getErrorGrid( message );
      }
    }
  } else if (optional) {
    if (error) changeErrorFunction(false);
    return <div></div>;
  }
  if (!error) changeErrorFunction(true);
  return getErrorGrid(trans('NEW_CAMPAIGN_MESSAGE4', props.lang));
}

function getLengthMessage(
  index,
  list,
  error,
  changeError,
  changeList,
  props,
) {

  for (let idx = 0; idx < list.length; idx++) {
      const ele = list[idx];
      if (ele.length < props.minTextSize && idx === index) {
        return getErrorGrid(trans('NEW_CAMPAIGN_MESSAGE19', props.lang).replaceAll('$MIN', props.minTextSize));
      }

      if (ele.length >= props.maxTextSize && idx === index) {
        list[index] = list[index].slice(0, props.maxTextSize);
        changeList(list);
        return getWarningGrid(
            trans('NEW_CAMPAIGN_WARN_TRIM', props.lang).replaceAll("$MAX",props.maxTextSize)
        );
      }
      changeError(false);
  }
}

let campaignNameComponentInfo = {
  cleanFunction: props => {
    if (props.changeCampaignName) {
      props.changeCampaignName(['']);
    }
    props.changeCampaignNameHasError(false);
  },
  getMessage: props => {
    return (index, list, componentProps) => {
      if (!props.isModify)
        return getWarningGrid(
          trans('NEW_CAMPAIGN_MESSAGE1', props.lang),
          '',
        );
      else return '';
    };
  },
  componentId: campaignNameComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: props => {
    return props.campaignNameHasError;
  },
  getchangeError: props => {
    return () => { };
  },
  errorMessage: 'Favor Revisar Nombre de Campaña',
  focusId: campaignNameFocusId,
};

let modifyCampaignNameComponentInfo = {
  cleanFunction: props => { },
  getMessage: props => {
    return (index, list, componentProps) => {
      if (list[index].length === 0) {
        props.changeModifyCampaignNameHasError(true);
        return getErrorGrid('Debe Ingresar Nombre');
      } else return '';
    };
  },
  componentId: campaignNameComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: props => {
    return props.modifyCampaignNameHasError;
  },
  getchangeError: props => {
    return props.changeModifyCampaignNameHasError;
  },
  errorMessage: 'Favor Revisar Nombre de Campaña',
  focusId: campaignNameFocusId,
};

let titleComponentInfo = {
  cleanFunction: props => {
    props.changeAdTitles(['']);
    props.changeHasErrorTitles(true);
  },
  componentId: titlesComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },

  // could be an error or Warning Message
  getMessage: props => {
    return (index, list, componentProps) => {
      return getLengthMessage(
        index,
        list,
        props.hasErrorTitles,
        props.changeHasErrorTitles,
        props.changeAdLinks,
        componentProps,
      );
    };
  },

  getError: props => {
    return props.hasErrorTitles;
  },
  getchangeError: props => {
    return () => { }; //props.changeHasErrorTitles;
  },
  errorMessage: 'Favor Revisar Título',
  focusId: tituloAnuncioFocusId,
};
let modifyCampaignBiddingAmountComponentInfo = {
  cleanFunction: props => { },
  componentId: biddingAmountComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getMessage: props => {
    return (index, list, componentProps) => {
      let isExcludeMinDecimal =
        props.modifyCampaignSelectedBidStrategies.value[0].value ===
          lowestCostWithouCapBidStrategy
          ? false
          : true;

      let validate = validateNumber(
        list[index],      // decimal Value
        0,                // minDecimal Value
        1000000,          // maxDecimal Value
        false,            // validate Daily Value
        0,                // campaingDates
        0,                // minDecimalDailyValue
        isExcludeMinDecimal,  // excludeMinDecimalVallue
        props.modifyCampaignHasErrorBiddingAmount,  // error
        props.changeModifyCampaignHasErrorBiddingAmount,  //  changeErrorFunction
        true,   // optional
        props   // props
      );
      if (validate.message) return validate;
    };
  },
  getError: props => {
    //console.log(props.modifyCampaignBidValue)
    //console.log(props.modifyCampaignSelectedBidStrategies)
    if (
      props.modifyCampaignBidValue.value[0] === '0' &&
      props.modifyCampaignSelectedBidStrategies.value[0].value !==
      lowestCostWithouCapBidStrategy
    ) {
      //console.log("true")
      return true;
    } else return props.modifyCampaignHasErrorBiddingAmount;
  },
  getchangeError: props => {
    return props.changeModifyCampaignHasErrorBiddingAmount;
  },
  errorMessage: '',
  focusId: biddingAmountFocusId,
};
let biddingAmontComponentInfo = {
  cleanFunction: props => {
    props.changeAdBiddingAmount([]);
    props.changeBiddingAmountHasError(false);
  },
  getMessage: props => {
    return (index, list, componentProps) => {
      return validateNumber(
        list[index],    // decimal Value
        0,              // minDecimal Value
        1000000,        // maxDecimal Value
        false,          // validate Daily Value
        0,              // campaingDates
        0,              // minDecimalDailyValue
        true,           // excludeMinDecimalValue
        props.biddingAmountHasError,  // error
        props.changeBiddingAmountHasError,  //  changeErrorFunction
        true,           // optional
        props           // props
      );
    };
  },
  componentId: biddingAmountComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: props => {
    return props.biddingAmountHasError;
  },
  getchangeError: props => {
    return () => { }; //props.changeBiddingAmountHasError;
  },
  errorMessage: 'Favor Revisar Bidding Amount',
  focusId: biddingAmountFocusId,
};

let fanPageComponentInfo = {
  cleanFunction: props => {
    props.changeSelectorAdminFanPage([]);
    props.changeSelectedAdminFanPages([]);
    props.changeSelectedAdminAdAccounts([]);
    props.changeSelectorAdminAdAccount([]);
    props.changeHasErrorFanPage(true);
    props.changeSelectedAdminFanPages([]);
    props.changeSelectedLeadGenerationForms([]);
    props.changeSelectedPixels([]);
    props.changeSelectorPixel([]);
    props.changeSelectorAdminInstagramAccount([]);
    props.changeSelectedAdminInstagramAccounts([]);
    props.changeSelectedPixelEvents([]);
    props.changeSelectorPixelEvent([]);
    props.changeSelectedUsedPixelEvents([]);
    props.changeSelectorUsedPixelEvent([]);
  },
  componentId: fanPageComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: props => {
    if (props.selectedAdminCampaignTypes.length > 0)
      if (leadGenerationObjective === props.selectedAdminCampaignTypes[0].value)
        return (
          props.hasErrorFanPage ||
          props.selectedLeadGenerationForms.length === 0
        );
      else return props.hasErrorFanPage;
  },
  getchangeError: props => {
    return props.changeHasErrorFanPage;
  },
  errorMessage: 'Favor Revisar Fan Page',
  focusId: fanPageFocusId,
};

let productComponentInfo = {
  cleanFunction: props => {
    props.changeHasErrorProduct(true);
    props.changeSelectedAdminProducts([]);
    props.changeSelectedAdminBrands([]);
    props.changeSelectedAdminCompanies([]);
  },
  componentId: productComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: props => {
    return props.hasErrorProduct;
  },
  getchangeError: props => {
    return props.changeHasErrorProduct;
  },
  errorMessage: 'Seleccione un Producto',
  focusId: productoFocusId,
};
let uploadComponentInfo = {
  cleanFunction: props => {
    props.changeHasErrorUpload(true);
    props.changeAdFiles([]);
  },
  componentId: attachmentsComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: props => {
    return props.hasErrorUpload;
  },
  getchangeError: props => {
    return props.changeHasErrorUpload;
  },
  errorMessage: 'Favor Revisar Archivos Adjuntos',
  focusId: uploadFocusId,
};

let LookAlikeInfo = {
  cleanFunction: props => {
    props.changeSelectedCustomAudiences([]);
    props.changeSelectedSavedAudiences([]);
    props.changeSavedAudienceSelected(false);
  },
  componentId: LookAlikeComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: props => { },
  getchangeError: props => {
    return () => { };
  },
  errorMessage: '',
  focusId: LookAlikeFocusId,
};
let genderAgeComponentInfo = {
  cleanFunction: (props, onlyAge) => {
    let ageList = [];
    for (let i = 18; i <= 65; i++) {
      if (i === 65) ageList.push({ label: '65+', value: i });
      else ageList.push({ label: i, value: i });
    }
    props.changeSelectorAgeStart(ageList);
    props.changeSelectedAgesStart([]);
    props.changeSelectorAgeEnd(ageList);
    props.changeSelectedAgesEnd([]);
    if (!onlyAge) props.changeSelectedGender('3');
  },
  componentId: genderAgeComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: props => {
    if (props.selectedAgesStart.length > 0 && props.selectedAgesEnd.length > 0)
      return false;
    else if (
      props.selectedAgesStart.length === 0 &&
      props.selectedAgesEnd.length === 0
    )
      return false;
    else if (props.savedAudienceSelected) return false;
    else return true;
  },
  getchangeError: props => {
    return () => { };
  },
  errorMessage: '',
  focusId: genderFocusId,
};
let modifyCampaignBudgetComponentInfo = {
  cleanFunction: props => { },
  componentId: budgetComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },

  getMessage: props => {
    return (index, list, componentProps) => {
      let validate = validateNumber(
        list[index],            // decimal Value
        1,                      // minDecimal Value
        1000000,                // maxDecimal Value
        true,                   // validate Daily Value
        props.selectedCampaignDates,  // campaingDates
        1,                       // minDecimalDailyValue
        false,                  // excludeMinDecimalVallue
        props.modifyCampaignHasErrorBudget,     // error
        props.changeModifyCampaignHasErrorBudget,   //  changeErrorFunction
        undefined,
        props
      );
      if (validate.message) {
        return validate;
      } else {
        return getWarningGrid('XXXXXXXXXXXXX', '', false);
      }
    };
  },
  getError: props => {
    return props.modifyCampaignHasErrorBudget;
  },
  getchangeError: props => {
    return props.changeModifyCampaignHasErrorBudget;
  },
  errorMessage: '',
  focusId: presupuestoFocusId,
};

let budgetComponentInfo = {
  cleanFunction: props => {
    props.changeHasErrorBudget(true);
    if (props.changeAdBudget) props.changeAdBudget(['']);
  },
  componentId: budgetComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },

  getMessage: props => {
    return (index, list, componentProps) => {
      let validate = validateNumber(
        list[index],        // decimal Value
        0,                  // minDecimal Value
        1000000,            // maxDecimal Value
        true,               // validate Daily Value
        props.selectedCampaignDates,  // campaingDates
        1,                  // minDecimalDailyValue
        false,              // excludeMinDecimalValue
        props.hasErrorBudget,   // error
        props.changeHasErrorBudget,  //  changeErrorFunction
        undefined,
        props
      );
      if (validate.message && props.showAdErrors) {
        return validate;
      } else {
        return getWarningGrid('', '', false);
      }
    };
  },
  getError: props => {
    return props.hasErrorBudget;
  },
  getchangeError: props => {
    return props.changeHasErrorBudget;
  },
  errorMessage: 'Favor Revisar Presupuesto',
  focusId: presupuestoFocusId,
};
let bodiesComponentInfo = {
  cleanFunction: props => {
    props.changeHasErrorBodies(true);
    props.changeAdBodies(['']);
  },
  componentId: bodiesComponentId,
  getMessage: props => {
    return (index, list, componentProps) => {
      return getLengthMessage(
        index,
        list,
        props.hasErrorBodies,
        props.changeHasErrorBodies,
        props.changeAdBodies,
        componentProps,
      );
    };
  },
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: props => {
    return props.hasErrorBodies;
  },
  getchangeError: props => {
    return props.changeHasErrorBodies;
  },
  errorMessage: 'Favor Revisar Cuerpo de Anuncio',
  focusId: cuerpoAnuncioFocusId,
};
let ctaComponentInfo = {
  cleanFunction: props => {
    props.changeSelectedCta([]);
  },
  componentId: ctaComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: props => {
    return props.selectedCta.length === 0;
  },
  getchangeError: props => {
    return () => { };
  },
  errorMessage: 'Favor Revisar CTA',
  focusId: ctaFocusId,
};

let modifyCampaignStatusInfo = {
  cleanFunction: props => { },
  componentId: campaignStatusComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: () => {
    return false;
  },
  getMessage: () => {
    return '';
  },
  getchangeError: props => {
    return () => { };
  },

  focusId: campaignStatusFocusId,
};
let modifyCampaignDateComponentInfo = {
  cleanFunction: props => { },
  componentId: dateComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: props => {
    let dates = props.modifySelectedDates.value;
    if (
      dates &&
      dates.length &&
      dates.length > 1 &&
      dates[1] < new Date().setHours(0, 0, 0, 0)
    )
      return true;
    else return false;
  },
  getMessage: props => {
    let dates = props.modifySelectedDates.value;
    if (
      dates &&
      dates.length &&
      dates.length > 1 &&
      dates[1] < new Date().setHours(0, 0, 0, 0)
    )
      return getErrorGrid('Fecha Fin debe de ser mayor igual a hoy');
    else return '';
  },
  getchangeError: props => {
    return () => { };
  },
  errorMessage: 'Fecha debe de ser mayor igual a hoy',
  focusId: fechaCampanaFocusId,
};

let dateComponentInfo = {
  cleanFunction: props => { },
  componentId: dateComponentId,
  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: props => {
    return props.hasErrorDate;
  },
  getchangeError: props => {
    return props.changeHasErrorDate;
  },
  errorMessage: 'Favor Revisar Fechas',
  focusId: fechaCampanaFocusId,
};
function linkValidation(props, isGenForm) {
  return (index, list, componentProps) => {
    changeLinkPreview(
      componentProps,
      componentProps.adLinkParameters,
      list[index],
    );

    if (!validateUrl(list[index])) {
      if (!props.hasErrorLink) {
        props.changeHasErrorLink(true);
      }
      return getErrorGrid(trans('NEW_CAMPAIGN_MESSAGE2', props.lang));
    } else if (isGenForm && isFacebookeUrl(list[index])) {
      if (!props.hasErrorLink) {
        props.changeHasErrorLink(true);
      }
      return getErrorGrid(trans('NEW_CAMPAIGN_MESSAGE20', props.lang));
    } else if (props.hasErrorLink) {
      props.changeHasErrorLink(false);
    }
  };
}

function linkComponentInfo(isGenForm = false) {
  return {
    cleanFunction: props => {
      props.changeHasErrorLink(true);
      props.changeAdLinks(['']);
      props.changeAdLinkPreview('');
    },
    getMessage: props => {
      return linkValidation(props, isGenForm);
    },

    componentId: linkComponentId,
    childFunction: () => { },
    initFunction: () => { },
    getError: props => {
      return props.hasErrorLink;
    },
    getchangeError: props => {
      return props.changeHasErrorLink;
    },
    focusId: linkFocusId,
  };
}

let locationComponentInfo = {
  cleanFunction: props => {
    if (props.setLocationId) {
      props.setLocationId(0);
    }
    if (props.locations) {
      props.locations.forEach(element => {
        if (element.fbType === 'position') {
          if (element.marker) element.marker.setMap(null);
          if (element.circle) element.circle.setMap(null);
        }
      });
    }      
    if (props.setLocations) {
      props.setLocations([]);
    }
  },
  componentId: locationComponentId,
  childFunction: () => { },
  initFunction: () => { },
  getchangeError: () => { },
  getError: props => {
    return props.locations.length === 0 && !props.savedAudienceSelected;
  },
  errorMessage: 'NEW_CAMPAIGN_MESSAGE24',
  errorMessageKey: 'NEW_CAMPAIGN_MESSAGE24',
  focusId: ubicacionFocusId,
};

let interestsComponentInfo = {
  cleanFunction: props => {
    props.changeInterests([]);
  },
  componentId: interestsComponentId,
  childFunction: () => { },
  initFunction: () => { },
  getchangeError: () => { },
  getError: props => {
    return false;
  },
  errorMessage: '',
  focusId: interestsFocusId,
};
let descriptionComponentInfo = {
  cleanFunction: props => {
    props.changeDescription([]);
  },
  componentId: descriptionComponentId,
  childFunction: () => { },
  initFunction: () => { },
  getchangeError: props => {
    return () => { };
  },
};
let adPreviewComponentInfo = {
  cleanFunction: props => {
    props.changeVisiblePreview(false);
    props.changePreview([]);
  },
  componentId: 'Removed:' + adPreviewComponentId,
  childFunction: () => { },
  initFunction: () => { },
  getchangeError: props => {
    return () => { };
  },
};
let leadGenerationFormComponentInfo = {
  cleanFunction: props => { },
  componentId: leadGenerationFormComponentId,
  childFunction: () => { },
  initFunction: () => { },
  getchangeError: props => {
    return () => { };
  },
};
let pixelComponentInfo = {
  cleanFunction: props => {
    props.changeSelectedPixels([]);
    props.changeSelectorPixel([]);
    props.changeSelectorAdminInstagramAccount([]);
    props.changeSelectedAdminInstagramAccounts([]);
    props.changeSelectedPixelEvents([]);
    props.changeSelectorPixelEvent([]);
    props.changeSelectedUsedPixelEvents([]);
    props.changeSelectorUsedPixelEvent([]);
  },
  componentId: pixelComponentId,

  childFunction: () => { },
  initFunction: () => { },
  validateValue: props => { },
  getError: props => {
    if (props.selectedAdminCampaignTypes.length > 0)
      if (conversionsObjective === props.selectedAdminCampaignTypes[0].value)
        return (
          props.hasErrorPixel ||
          props.selectedPixels.length === 0 ||
          (props.selectedPixelEvents.length === 0 &&
            props.selectedUsedPixelEvents.length === 0)
        );
      else return props.hasErrorPixel;
  },
  getchangeError: props => {
    return props.changeHasErrorPixel;
  },
  errorMessage: 'NEW_CAMPAIGN_MESSAGE25',
  errorMessageKey: 'NEW_CAMPAIGN_MESSAGE25',
  focusId: pixelFocusId,
};
let applicationComponentInfo = {
  cleanFunction: props => {
    props.changeSelectedApplications([]);
    props.changeSelectorApplication([]);
    props.changeSelectorAppStore([]);
    props.changeSelectorPixelEvent([]);
  },
  componentId: applicationComponentId,
  childFunction: () => { },
  initFunction: () => { },
  getError: props => {
    if (props.selectedAdminCampaignTypes.length > 0)
      if (appInstallObjective === props.selectedAdminCampaignTypes[0].value)
        return (
          props.hasErrorApplication ||
          props.selectedApplications.length === 0 ||
          props.selectedAppStores.length === 0
        );
      else return props.hasErrorApplication;
  },
  getchangeError: props => {
    return props.changeHasErrorApplication;
  },
  errorMessage: 'NEW_CAMPAIGN_MESSAGE26',
  errorMessageKey: 'NEW_CAMPAIGN_MESSAGE26',
  focusId: applicationFocusId,
};
let conversationComponentInfo = {
  cleanFunction: props => {
    props.changeSelectedConversations([]);
  },
  componentId: conversationComponentId,
  childFunction: () => { },
  initFunction: () => { },
  getError: props => {
    if (props.selectedAdminCampaignTypes.length > 0)
      if (conversationsObjective === props.selectedAdminCampaignTypes[0].value)
        return (
          props.hasErrorConversations ||
          props.selectedConversations.length === 0
        );
      else return props.hasErrorConversations;
  },
  getchangeError: props => {
    return props.changeHasErrorConversations;
  },
};
let instagramAccountComponentInfo = {
  cleanFunction: props => { },
  componentId: instagramAccountComponentId,
  childFunction: () => { },
  initFunction: () => { },
  getchangeError: props => {
    return () => { };
  },
};
let UTMComponentInfo = {
  cleanFunction: props => {
    props.changeAdLinkPreview(props.adLinks[0]);
    props.changeAdLinkParameters([]);
    props.changeHasErrorLink(true);
  },
  componentId: UTMComponentId,
  childFunction: () => { },
  initFunction: () => { },
  getchangeError: props => {
    return () => { };
  },
};

//
/*conversationsObjective },
  { label: "CONVERSIONS", value: conversionsObjective } */
export let createAddMapping = [
  {
    objective: leadGenerationObjective,
    provider: facebookProvider,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      leadGenerationFormComponentInfo,
      campaignNameComponentInfo,
      bodiesComponentInfo,
      titleComponentInfo,
      descriptionComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      linkComponentInfo(true),
      budgetComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      locationComponentInfo,
      interestsComponentInfo,
      uploadComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: leadGenerationObjective,
    provider: instagramProvider,

    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      leadGenerationFormComponentInfo,
      instagramAccountComponentInfo,
      campaignNameComponentInfo,
      bodiesComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      linkComponentInfo(true),
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      locationComponentInfo,
      interestsComponentInfo,
      uploadComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: leadGenerationObjective,
    provider: multipleProvider,

    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      leadGenerationFormComponentInfo,
      instagramAccountComponentInfo,
      campaignNameComponentInfo,
      bodiesComponentInfo,
      titleComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      linkComponentInfo(true),
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      locationComponentInfo,
      interestsComponentInfo,
      uploadComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: linkClicksObjective,
    provider: facebookProvider,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      campaignNameComponentInfo,
      descriptionComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      linkComponentInfo(),
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      UTMComponentInfo,
      uploadComponentInfo,
      titleComponentInfo,
      bodiesComponentInfo,
      locationComponentInfo,
      interestsComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: linkClicksObjective,
    provider: instagramProvider,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      instagramAccountComponentInfo,
      campaignNameComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      linkComponentInfo(),
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      UTMComponentInfo,
      uploadComponentInfo,
      bodiesComponentInfo,
      locationComponentInfo,
      interestsComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: linkClicksObjective,
    provider: multipleProvider,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      instagramAccountComponentInfo,
      campaignNameComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      linkComponentInfo(),
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      UTMComponentInfo,
      uploadComponentInfo,
      titleComponentInfo,
      bodiesComponentInfo,
      locationComponentInfo,
      interestsComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: conversationsObjective,
    provider: facebookProvider,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      campaignNameComponentInfo,
      descriptionComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      conversationComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      uploadComponentInfo,
      titleComponentInfo,
      bodiesComponentInfo,
      locationComponentInfo,
      interestsComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: conversationsObjective,
    provider: instagramProvider,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      instagramAccountComponentInfo,
      campaignNameComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      conversationComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      uploadComponentInfo,
      bodiesComponentInfo,
      locationComponentInfo,
      interestsComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: conversationsObjective,
    provider: multipleProvider,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      instagramAccountComponentInfo,
      campaignNameComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      conversationComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      uploadComponentInfo,
      titleComponentInfo,
      bodiesComponentInfo,
      locationComponentInfo,
      interestsComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: conversionsObjective,
    provider: facebookProvider,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      campaignNameComponentInfo,
      pixelComponentInfo,
      bodiesComponentInfo,
      titleComponentInfo,
      descriptionComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      linkComponentInfo(),
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      UTMComponentInfo,
      locationComponentInfo,
      interestsComponentInfo,
      uploadComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: conversionsObjective,
    provider: instagramProvider,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      campaignNameComponentInfo,
      pixelComponentInfo,
      instagramAccountComponentInfo,
      bodiesComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      linkComponentInfo(),
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      UTMComponentInfo,
      locationComponentInfo,
      interestsComponentInfo,
      uploadComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: conversionsObjective,
    provider: multipleProvider,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      campaignNameComponentInfo,
      pixelComponentInfo,
      instagramAccountComponentInfo,
      bodiesComponentInfo,
      titleComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      linkComponentInfo(),
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      UTMComponentInfo,
      locationComponentInfo,
      interestsComponentInfo,
      uploadComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: appInstallObjective,
    provider: facebookProvider,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      campaignNameComponentInfo,
      applicationComponentInfo,
      bodiesComponentInfo,
      titleComponentInfo,
      descriptionComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      locationComponentInfo,
      interestsComponentInfo,
      uploadComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: appInstallObjective,
    provider: instagramProvider,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      campaignNameComponentInfo,
      applicationComponentInfo,
      instagramAccountComponentInfo,
      bodiesComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      locationComponentInfo,
      interestsComponentInfo,
      uploadComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  {
    objective: appInstallObjective,
    provider: multipleProvider,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      productComponentInfo,
      fanPageComponentInfo,
      campaignNameComponentInfo,
      applicationComponentInfo,
      instagramAccountComponentInfo,
      bodiesComponentInfo,
      titleComponentInfo,
      ctaComponentInfo,
      dateComponentInfo,
      budgetComponentInfo,
      //biddingAmontComponentInfo,
      genderAgeComponentInfo,
      LookAlikeInfo,
      locationComponentInfo,
      interestsComponentInfo,
      uploadComponentInfo,
      adPreviewComponentInfo,
    ],
  },
  //assign page
  {
    isModify: true,
    initFunction: () => { },
    finalFunction: () => { },
    mapping: [
      modifyCampaignNameComponentInfo,
      modifyCampaignDateComponentInfo,
      modifyCampaignBudgetComponentInfo,
      modifyCampaignStatusInfo,
      modifyCampaignBiddingAmountComponentInfo,
    ],
  },
];

export let fullAdComponentsList = [
  productComponentInfo,
  campaignNameComponentInfo,
  fanPageComponentInfo,
  leadGenerationFormComponentInfo,
  pixelComponentInfo,
  instagramAccountComponentInfo,
  applicationComponentInfo,
  conversationComponentInfo,
  descriptionComponentInfo,
  ctaComponentInfo,
  dateComponentInfo,
  budgetComponentInfo,
  biddingAmontComponentInfo,
  titleComponentInfo,
  bodiesComponentInfo,
  locationComponentInfo,
  interestsComponentInfo,
  uploadComponentInfo,
  adPreviewComponentInfo,
  UTMComponentInfo,
  linkComponentInfo(),
  genderAgeComponentInfo,
  LookAlikeInfo,
];
