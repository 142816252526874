import React from 'react';
import { Grid, withStyles, Button, TextField } from '@material-ui/core';
import Widget from '../../components/Widget/WidgetContainer';
import PageTitle from '../../components/PageTitle/PageTitle';
import { createAccount, linkAccountWithUser } from '../../utils/functions';
import { styles, resizeCenterh2 } from '../../utils/styles';
import './../../i18n/tran';
import trans from './../../i18n/tran';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import Helper from './components/accounts/Helper';
import SaveIcon from '@material-ui/icons/Save';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

function TabPanel(props) {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`nav-tabpanel-${props.index}`}
      aria-labelledby={`nav-tab-${props.index}`}
    >
      {props.value === props.index && props.children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const View = ({ classes, theme, onChange, value, ...props }) => {
  const [tabState, setTabState] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabState(newValue);
  };

  return (
    <React.Fragment>
      <PageTitle title={trans('NEW_ACCOUNT_TITLE', props.lang)} />
      <Paper>
        <Tabs
          value={tabState}
          indicatorColor="primary"
          onChange={handleChange}
        >
          <Tab label={trans('NEW_ACCOUNT_TAB1', props.lang)} value={0} />
          <Tab label={trans('NEW_ACCOUNT_TAB2', props.lang)} value={1} />
        </Tabs>
      </Paper>
      <TabPanel value={tabState} index={0}>
        <Widget
          overflow={true}
          title={
            <div style={resizeCenterh2()}>
              {trans('NEW_ACCOUNT_SUB_TITLE', props.lang)}
            </div>
          }
          bodyClass={classes.tabWidget}
          disableWidgetMenu={true}
        >
          <Grid
            container
            spacing={4}
            direction="row"
            alignContent="space-between"
            style={{ minHeight: '250px' }}
          >
            <Grid item xs={8} md={8} lg={8}>
              <TextField
                variant="outlined"
                style={{ width: '100%' }}
                placeholder={trans(
                  'NEW_ACCOUNT_ACCOUNT_NAME_PLACEHOLDER',
                  props.lang,
                )}
                value={props.accountName}
                onChange={e => {
                  props.changeAccountName(e.target.value);
                }}
              ></TextField>
              <Disclaimer
                message={trans('NEW_ACCOUNT_FORM_HELPER_2', props.lang)}
              />
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Button
                disabled={props.accountName.length === 0}
                variant="contained"
                startIcon={<SaveIcon />}
                size="large"
                color="primary"
                onClick={async () => {
                  await createAccount(props, props.accountName, true);
                }}
              >
                {trans('NEW_ACCOUNT_FORM_CALLTOACTION', props.lang)}
              </Button>
            </Grid>
          </Grid>
        </Widget>
      </TabPanel>
      <TabPanel value={tabState} index={1}>
        <Widget
          overflow={true}
          title={
            <div style={resizeCenterh2()}>
              {trans('NEW_ACCOUNT_FORM_ASOC', props.lang)}
            </div>
          }
          bodyClass={classes.tabWidget}
          disableWidgetMenu={true}
        >
          <Grid
            container
            spacing={4}
            direction="row"
            alignContent="space-between"
            style={{ minHeight: '250px' }}
          >
            <Grid item xs={8} md={8} lg={8}>
              <TextField
                variant="outlined"
                style={{ width: '100%' }}
                placeholder={trans(
                  'NEW_ACCOUNT_FORM_ASOC_PLACEHOLDER',
                  props.lang,
                )}
                value={props.uniqueAccountKey}
                onChange={e => {
                  props.changeUniqueAccountKey(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Button
                variant="contained"
                size="large"
                startIcon={<SaveIcon />}
                color="primary"
                onClick={async () => {
                  await linkAccountWithUser(props, props.uniqueAccountKey);
                }}
              >
                {trans('NEW_ACCOUNT_FORM_CALLTOACTION_2', props.lang)}
              </Button>
            </Grid>
          </Grid>
        </Widget>
      </TabPanel>

      <Grid
        container
        spacing={4}
        direction="column"
        alignItems="center"
        style={{ marginTop: '20px' }}
      >
        <Grid item xs={12} md={12} lg={12} style={{ minWidth: '100%' }}>
          <Widget
            title={trans('NEW_ACCOUNT_HELP', props.lang)}
            bodyClass={classes.tableWidget}
            disableWidgetMenu={true}
            
          >
            <Helper lang={props.lang} />
          </Widget>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(View);
