import React from 'react';
// import { Link } from "react-router-dom";
import { Grid, withStyles, Button } from '@material-ui/core';
// import classnames from "classnames";
// import { resizeError, resizeDiv } from "../../utils/styles";
// import { homePagePagePath } from "../../components/Constants";

const ErrorPage = ({ classes, ...props }) => {
  return (
    <Grid container className={classes.container}>
      {sessionStorage.getItem('inactive') !== null ? (
        <div>
          <div
            style={{ fontSize: '30vmin', textAlign: 'center', width: '100%' }}
          >
            401
          </div>
          <div
            style={{ fontSize: '10vmin', textAlign: 'center', width: '100%' }}
          >
            Oops
          </div>
          <div
            style={{ fontSize: '5vmin', textAlign: 'center', width: '100%' }}
          >
            <div>USUARIO INACTIVO PARA ESTE NEGOCIO ADMINISTRADOR </div>
            SOLICITAR ACTIVACIÓN AL ADMINISTRADOR DEL NEGOCIO
          </div>
          <div style={{ width: '50%', margin: 'auto' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.location.reload();
              }}
              size="large"
              className={classes.button}
              style={{ width: '100%' }}
            >
              Volver al Inicio
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{ fontSize: '30vmin', textAlign: 'center', width: '100%' }}
          >
            403
          </div>
          <div
            style={{ fontSize: '10vmin', textAlign: 'center', width: '100%' }}
          >
            Oops
          </div>
          <div
            style={{ fontSize: '5vmin', textAlign: 'center', width: '100%' }}
          >
            NO TIENE SUFICIENTES PERMISOS EN EL NEGOCIO SELECCIONADO PARA
            ACCESSAR ESTA PÁGINA.
          </div>
          <div style={{ width: '50%', margin: 'auto' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.location.reload();
              }}
              size="large"
              className={classes.button}
              style={{ width: '100%' }}
            >
              Volver al Inicio
            </Button>
          </div>
        </div>
      )}
    </Grid>
  );
};
const styles = theme => ({
  container: {
    height: '100%',
    width: '100%',

    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logotype: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logotypeText: {
    fontWeight: 500,
    fontSize: 400,
    color: theme.palette.primary.yellow,
    marginLeft: theme.spacing(2),
  },
  logotypeIcon: {
    width: 70,
    marginRight: theme.spacing(2),
  },
  paperRoot: {
    boxShadow: theme.customShadows.transparent,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    maxWidth: 650,
    maxHeight: 900,
    backgroundColor: theme.palette.primary.main,
  },
  textRow: {
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    color: theme.palette.primary.yellow,
  },

  textRowops: {
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.primary.yellow,
  },
  errorCode: {
    fontSize: 148,
    fontWeight: 600,
  },
  safetyText: {
    fontWeight: 300,
    color: theme.palette.text.hint,
  },
  backButton: {
    margin: 'auto',
    width: '100%',
    boxShadow: theme.customShadows.widget,
    textTransform: 'none',
    fontSize: 15,
    backgroundColor: theme.palette.primary,
    '&:hover': {
      borderBottomColor: 'white',
      backgroundColor: 'white',
    },
  },
});

export default withStyles(styles, { withTheme: true })(ErrorPage);
