import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import SelectorView from './SelectorView';
//import { setCampaigns, setSelectedCampaign } from "./SelectorState";

export default compose(
  connect(
    state => ({
      //selectedCampaign: state.campaign.selectedCampaign
      //campaigns: state.campaign.campaigns
    }),
    {
      //setCampaigns, setSelectedCampaign
    },
  ),
  lifecycle({
    componentDidMount() {},
    componentWillUnmount() {},
  }),
)(SelectorView);
