import React from 'react';
import trans from '../../../i18n/tran';
import { Button } from 'src/components/Wrappers/Wrappers';

import {
    withStyles,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Box,
    Paper,
    Accordion,
    AccordionDetails,
    Typography,
    AccordionSummary
  } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GridContainer from '../../../components/Grid/GridContainer.js';
import GridItem from '../../../components/Grid/GridItem.js';  

const IAAgeGender = ({ classes, theme, ...props }) => {
    const [expanded1, setExpanded1] = React.useState(true);
    const handleChange = panel => (event, isExpanded) => {
      setExpanded1(isExpanded ? true : false);
    };

    if (props.campaigns.length===0) {
      return (
        <div>
          <span>{trans('NO_INFO', props.lang)}</span>
        </div>
      );
    }
    
      return (
        <div>
          <GridContainer className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={12} className={classes.action}>
              <Button
                variant="contained"
                color="primary"
                className={classes.wide}
                onClick={(e) => {
                  props.callLambdaAgeGender(e);
                  // Track(NewEvent("ads", "aly_log_botton", "click"));
                }}
              >
                {trans('ALGO_CORR_NAME', props.lang)}
              </Button>
            </GridItem>      
            {props.ageGender && props.ageGender.length > 0 &&
            <GridItem xs={12} sm={12} md={12} lg={12} className={classes.summary}>
              <Accordion expanded={expanded1} onChange={handleChange('panel1')} style={{ borderShadow: 'none' }} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.accordionSummary}
                  aria-controls="panel1b-content"
                  id="panel1b-header"
                >
                  <Typography className={classes.heading}>
                    {trans('AGE_GENDER_DETAIL_SUB', props.lang)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* OPTIMIZATION DETAILS */}
                  <GridItem xs={12} sm={12} md={12} lg={12} className={classes.summary}>
                    <Box>
                      <Paper>
                        <Table className={classes.summaryTable} size="medium" padding="normal">
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.detHead}>{trans('FEMALE_LBG', props.lang)}</TableCell>
                              <TableCell className={classes.detHeadCenter}>{trans('FEMALE_UBG', props.lang)}</TableCell>                          
                              <TableCell className={classes.detHeadCenter}>{trans('INV_FEMALE', props.lang)}</TableCell>
                              <TableCell className={classes.detHeadCenter}>{trans('MALE_LBG', props.lang)}</TableCell>
                              <TableCell className={classes.detHeadCenter}>{trans('MALE_UBG', props.lang)}</TableCell>
                              <TableCell className={classes.detHeadCenter}>{trans('INV_MALE', props.lang)}</TableCell>                          
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {props.ageGender.map(({ id, FemaleLbg, FemaleUbg, InvFemale, InvMale, MaleLbg,MaleUbg }) => (
                              <TableRow key={id}>
                                <TableCell className={classes.detCell}>{FemaleLbg}</TableCell>
                                <TableCell className={classes.detCell}>{FemaleUbg}</TableCell>
                                <TableCell className={classes.detCell}>{InvFemale} %</TableCell>
                                <TableCell className={classes.detCell}>{MaleLbg}</TableCell>
                                <TableCell className={classes.detCell}>{MaleUbg}</TableCell>  
                                <TableCell className={classes.detCell}>{InvMale} %</TableCell>                                                      
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Paper>
                    </Box>
                  </GridItem>
                </AccordionDetails>
              </Accordion>
            </GridItem>
            }
          </GridContainer>
        </div>
      )
    
}


const styles = theme => ({
    accordionSummary: {
      flexDirection: 'column',
      display:'flex'
    },
    wide: {
      minWidth: '300px',
      borderRadius: '12px',
      textTransform: 'none',
      fontWeight: '900'
    },
    action: {
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'center'
    },
    summary: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px'
    },
    summaryTable: {
      minWidth: "780px",
      maxWidth: "1024px"
    },
    detCell: {
      textAlign: 'center',
      minWidth: '130px',
    },
    detHeadCenter: {
      textAlign: 'center',
      minWidth: '130px',
      backgroundColor: theme.palette.text.primary,
      color: 'white'
    },
    detHead: {
      minWidth: '130px',
      backgroundColor: theme.palette.text.primary,
      color: 'white'
    },
    detContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
      minWidth: '720px'
  
    },
    container: {
      padding: '20px'
    }
  })

export default withStyles(styles, { withTheme: true })(IAAgeGender);
