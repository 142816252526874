import { t } from 'i18next';
import { MenuItem, TextFieldProps } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { FieldProps } from 'formik';
import React from 'react';

export const BusinessCategoriesField = (
  props: JSX.IntrinsicAttributes & TextFieldProps & FieldProps,
) => {
  return (
    <TextField select {...props} {...props.field}>
      {categories.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {t(`businessCategories.${option.description}`)}
        </MenuItem>
      ))}
    </TextField>
  );
};

const categories = [
  {
    id: 1,
    description: 'Arts and Entertainment',
  },
  {
    id: 2,
    description: 'Cars and Vehicles',
  },
  {
    id: 3,
    description: 'Beauty (Physical State)',
  },
  {
    id: 4,
    description: 'Books and Literature',
  },
  {
    id: 5,
    description: 'Business and Industry',
  },
  {
    id: 6,
    description: 'Computers and Electronics',
  },
  {
    id: 7,
    description: 'Finance',
  },
  {
    id: 8,
    description: 'Food and drinks',
  },
  {
    id: 9,
    description: 'Games',
  },
  {
    id: 10,
    description: 'Health',
  },
  {
    id: 11,
    description: 'Hobbies and Leisure',
  },
  {
    id: 12,
    description: 'House and garden',
  },
  {
    id: 13,
    description: 'Internet and Telecommunications',
  },
  {
    id: 14,
    description: 'Work and Education',
  },
  {
    id: 15,
    description: 'Law and Government',
  },
  {
    id: 16,
    description: 'News',
  },
  {
    id: 17,
    description: 'Online Communities',
  },
  {
    id: 18,
    description: 'People and Society',
  },
  {
    id: 19,
    description: 'Pets and Animals',
  },
  {
    id: 20,
    description: 'Real estate',
  },
  {
    id: 21,
    description: 'References',
  },
  {
    id: 22,
    description: 'Science',
  },
  {
    id: 23,
    description: 'Shopping',
  },
  {
    id: 24,
    description: 'Sport',
  },
  {
    id: 25,
    description: 'Travels',
  },
  {
    id: 26,
    description: 'Locations',
  },
];
