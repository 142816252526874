import './../assets/loader.css';
import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import themes, { overrides } from '../themes';
const theme = createTheme({ ...themes.default, ...overrides });

export const isLoadingModal = (
  <div id="myModal" style={theme.modal}>
    <div style={theme.modalcontent}>
      <div style={theme.modalcontent}>
        <div className="loadingio-spinner-pulse-opn7nv6o23j">
          <div className="ldio-opn7nv6o23j">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export const sideBarLoadingModal = (
  <div id="myModal" style={theme.sideBarmodal}>
    <div style={theme.modalcontent}>
      <div style={theme.modalcontent}>
        <div className="loadingio-spinner-pulse-opn7nv6o23j">
          <div className="ldio-opn7nv6o23j">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export const LockModal = (
  <div id="myModal" style={theme.modal}>
    <div style={theme.modalcontent}>
      <div style={theme.modalcontent}></div>
    </div>
  </div>
);

export const isLoadingLoginModal2 = (
  <div id="myModal" style={theme.modal}>
    <div style={theme.modalcontent}>
      <div style={theme.modalcontent}>
        <div className="loadingio-spinner-pulse-opn7nv6o23j">
          <div className="ldio-opn7nv6o23j">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export const isLoadingLoginModal = (
  <div id="myModal" style={theme.modal}>
    <div style={theme.modalcontent}>
      <div style={theme.modalcontent}>
        <div className="loadingio-spinner-pulse-opn7nv6o23j">
          <div className="ldio-opn7nv6o23j">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
