import { getDataset10UrlChartsTotal } from '../../components/Constants';
import { requestHeaders, request } from './helper';

interface Dataset12SummedResponse {
  code?: string;
  comments?: string;
  counter?: number;
  errors?: string | string[];
  payload?: any[];
}
export interface Dataset12SummedParams {
  fDate: string;
  lDate: string;
  accountId: number;
  companyValue: number;
  brandValue: number;
  product: number;
  campaignTypeId: number;
  adAccountId: number;
  campaignId: number;
  genderId: number[];
  ageId: number[];
}

/*http://alyai-alb-382630953.us-east-2.elb.amazonaws.com/stat/api/v4.0/dataset12/summed

?fDate=2021-05-01&lDate=2022-05-27&accountId=89&companyValue=391&brandValue=392&product=230
&campaignTypeId=3&adAccountId=186&campaignId=1712&genderId=1,2,3&ageId=1,2,4,5,8
*/

export const Dataset12Summed = async (params: Dataset12SummedParams) => {
  try {
    if (!params) {
      const error: Dataset12SummedResponse = {
        errors: 'Coupon invalid',
      };
      return error;
    }
    const headers = requestHeaders();

    const url = new URL(getDataset10UrlChartsTotal);
    url.search = new URLSearchParams({
      fDate: params.fDate,
      lDate: params.lDate,
      accountId: params.accountId.toString(),
      companyValue: params.companyValue.toString(),
      brandValue: params.brandValue.toString(),
      product: params.product.toString(),
      campaignTypeId: params.campaignTypeId.toString(),
      adAccountId: params.adAccountId.toString(),
      campaignId: params.campaignId.toString(),
      genderId: params.genderId.join(','),
      ageId: params.ageId.join(','),
    }).toString();

    return await request<Dataset12SummedResponse>(
      url.toString().replace(/%2C/g, ','),
      {
        method: 'GET',
        headers: headers,
      },
    );
  } catch (e) {
    const error: Dataset12SummedResponse = {
      errors: 'Coupon error',
    };
    return error;
  }
};
