import { compose, withState, lifecycle } from 'recompose';
import View from './AssignView';
// import { getAccountsApiAdmin } from "../../utils/functions";

//import { saveAssignRequest } from "../../components/ApiClient/Assign";

const mycompose = compose(
  withState('budgetList', 'changeBudgetList', []),
  withState('refresh', 'changeRefresh', false),
  withState(
    'campaignsWithoutOptimizationFiltered',
    'changeCampaignsWithoutOptimizationFiltered',
    [],
  ),
  withState(
    'campaignsWithoutOptimization',
    'changeCampaignsWithoutOptimization',
    [],
  ),
  withState(
    'campaignsWithoutProductFiltered',
    'changeCampaignsWithoutProductFiltered',
    [],
  ),
  withState('campaignsWithFilters', 'changeCampaignsWithFilters', []),
  withState(
    'campaignsWithFiltersFiltered',
    'changeCampaignsWithFiltersFiltered',
    [],
  ),
  withState('campaignsManager', 'changeCampaignsManager', []),
  withState('campaignsManagerFiltered', 'changeCampaignsManagerFiltered', []),

  withState('campaignsWithoutProduct', 'changeCampaignsWithoutProduct', []),

  withState('assignProduct', 'changeAssignProduct', true),
  withState('updateProduct', 'changeUpdateProduct', false),
  withState('manageBudget', 'changeManageBudget', true),
  withState('assignOptimization', 'changeOptimizationType', false),

  withState('selectorAdminCampaign', 'changeSelectorAdminCampaign', []),
  withState('selectedAdminCampaigns', 'changeSelectedAdminCampaigns', []),

  withState('showCreateProduct', 'changeShowCreateProduct', false),
  withState('updateProducts', 'changeUpdateProducts', false),

  withState('pagePagination', 'changePagePagination', 0),
  lifecycle({
    componentDidMount() {
      /*if (this.props.selectedAdminAdAccounts.length === 0) {
				getAccountsApiAdmin(this.props);
				this.props.changeSelectedAdminAccounts([]);
			}*/
      this.props.changeTenantChangeFunction({
        function: newTenant => {
          this.props.changeSelectedAdminProductsFiltered([]);
          this.props.changeSelectedCountriesFiltered([]);
          this.props.changeSelectedAdminProducts([]);
          this.props.changeSelectorAdminProduct([]);
          this.props.changeSelectedAdminAdAccounts([]);
          this.props.changeSelectorFacebookCampaign([]);
          this.props.changeSelectedFacebookCampaigns([]);
          this.props.changeSelectedCountriesFiltered([]);
          this.props.changeSelectorCountryFiltered([]);
          this.props.changeSelectedCategoriesFiltered([]);
          this.props.changeSelectorCategoryFiltered([]);
          this.props.changeSelectedAdminProductsFiltered([]);
          this.props.changeSelectorAdminProductFiltered([]);

          this.props.changeCampaignsWithoutOptimization([]);
          this.props.changeCampaignsWithoutOptimizationFiltered([]);
          this.props.changeCampaignsWithoutProduct([]);
          this.props.changeCampaignsWithoutProductFiltered([]);
          this.props.changeCampaignsWithFilters([]);
          this.props.changeCampaignsWithFiltersFiltered([]);
          this.props.changeSelectedAdminCampaigns([]);
          this.props.changeSelectorAdminCampaign([]);
          this.props.changeUpdateTable(true);

          this.props.changePagePagination(0);
        },
      });
    },

    componentWillUnmount() {
      //window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
    componentDidUpdate() {},
  }),
);

export default mycompose(View);
