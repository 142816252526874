import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { GetAccounts } from '../rest';
import { GetAccountNonce } from '../rest/GetAccountNonce';
import { useNavBarContext } from '../components/NavBar';
import { t } from 'i18next';

export interface AccountI {
  Id: number;
  Status: number;
  Title: string;
  UniqueKey: string;
}

interface AccountIdContextProps {
  accounts?: AccountI[];
  currentAccount?: AccountI;
  reloadAccounts: () => void;
  updateCurrentAccount: (value: AccountI) => void;
}

const AccountIdContext = createContext<AccountIdContextProps>({
  accounts: undefined,
  currentAccount: undefined,
  reloadAccounts: () => {},
  updateCurrentAccount: () => {},
});

export const AccountIdContextWrapper: FC = ({ children }) => {
  const [accounts, setAccounts] = useState<AccountI[]>();

  const [currentAccountID, setCurrentAccountId] = useState<number>();
  const currentAccount = accounts?.find(value => value.Id === currentAccountID);

  const updateCurrentAccount = async (account: AccountI) => {
    const GetAccountNonceResult = await GetAccountNonce(account.Id);

    if (
      GetAccountNonceResult.payload?.Nonce &&
      GetAccountNonceResult.payload.Nonce.length > 0
    ) {
      localStorage.setItem('nonce', GetAccountNonceResult.payload.Nonce);

      localStorage.setItem(
        'selectedTenants',
        JSON.stringify({ ...account, value: account.Id }),
      );
      setCurrentAccountId(account.Id);
    }
  };

  const { showSnackbar, setProfileDrawerState } = useNavBarContext();
  const reloadAccounts = useCallback(async () => {
    const json = localStorage.getItem('selectedTenants');

    const response = await GetAccounts();
    setAccounts(response.data.payload);
    if (json && json.length > 0) {
      const jsonObject = JSON.parse(json);
      const accountParsed: AccountI = {
        Status: 20,
        Id: jsonObject.value,
        UniqueKey: jsonObject.UniqueKey,
        Title: jsonObject.label,
      };
      setCurrentAccountId(accountParsed.Id);
      return;
    }

    const currentTenant = JSON.parse(json ?? '{}');

    if (response.data.payload && response.data.payload.length > 0) {
      if (response.data.payload.length === 1) {
        updateCurrentAccount(response.data.payload[0]);
        return;
      }
      if (!!currentTenant.value) {
        const filterResult = response.data.payload.find(
          value => value.Id === currentTenant.value,
        );
        if (filterResult) {
          updateCurrentAccount(filterResult);
          return;
        }
      }
    }

    setProfileDrawerState(true);
    showSnackbar(t('Please select account'), 'warning');
  }, []);

  useEffect(() => {
    reloadAccounts();
  }, []);

  return (
    <AccountIdContext.Provider
      value={{
        reloadAccounts,
        accounts,
        currentAccount,
        updateCurrentAccount,
      }}>
      {children}
    </AccountIdContext.Provider>
  );
};

export const useAccountIdContext = () => {
  const context = useContext(AccountIdContext);

  if (context === undefined) {
    throw new Error('cant be use out of provider');
  }
  return context;
};
