import { compose, withState, lifecycle } from 'recompose';
import View from './UserAdminView';
import { getAccountUsers } from '../../utils/functions';

const mycompose = compose(
  withState('pagePagination', 'changePagePagination', 0),
  withState('users', 'changeUsers', []),

  lifecycle({
    componentDidMount() {
      getAccountUsers(this.props);
      this.props.changeTenantChangeFunction({
        function: newTenenatValue => {
          getAccountUsers(this.props);
        },
      });
    },

    componentWillUnmount() {
      //window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
    componentDidUpdate() {},
  }),
);

export default mycompose(View);
