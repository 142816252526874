import { compose, withState, lifecycle } from 'recompose';
import { sendGAPageView } from '../../utils/ga';
import View from './createNewAccount';
//import { getInitAdAccounts, getAccountsApiAdmin } from "../../utils/functions";

const mycompose = compose(
  withState('accountName', 'changeAccountName', ''),
  withState('uniqueAccountKey', 'changeUniqueAccountKey', ''),
  lifecycle({
    componentDidMount() {
      sendGAPageView('New Account Page');
      this.props.changeTenantChangeFunction({
        function: newTenenatValue => {},
      });
    },

    componentWillUnmount() {
      //window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
    componentDidUpdate() {},
  }),
);

export default mycompose(View);
