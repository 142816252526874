import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import Pagination from '@material-ui/lab/Pagination';
import { Grid } from '@material-ui/core';
interface PaginationHelperProps<T> {
  itemsPerPage: number;
  items: T[];
  renderItem: (item: T) => React.ReactElement;
}

export function PaginationHelper<T>(
  props: React.PropsWithChildren<PaginationHelperProps<T>>,
) {
  const { items, renderItem, itemsPerPage } = props;

  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [items]);
  const data = useMemo(
    () => _.chunk(items, itemsPerPage),
    [items, itemsPerPage],
  );
  const pages = data.length;

  const handleChange = useCallback((event: object, value: number) => {
    setPage(value);
  }, []);

  return (
    <>
      {pages >= 1 && page <= pages && data[page - 1].map(renderItem)}
      {pages !== 1 && (
        <Grid container justifyContent="flex-end">
          <Pagination count={pages} page={page} onChange={handleChange} />
        </Grid>
      )}
    </>
  );
}
