import React from 'react';

import { Button } from '@material-ui/core';
import trans from '../../../i18n/tran';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// import {reportsPagePath} from '../../../components/Constants'

export default function ReportList(props, classes) {
  if (props.reports && props.reports.length > 0) {
    let rows = props.reports;
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{trans('ID', props.lang)}</TableCell>
              <TableCell>{trans('NAME', props.lang)}</TableCell>
              <TableCell>{trans('ACTION', props.lang)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.Id}>
                <TableCell component="th" scope="row">
                  {row.Id}
                </TableCell>
                <TableCell>{row.Title}</TableCell>
                <TableCell>
                  <Button href={`?id=${row.Id}`}>
                    {trans('WATCH', props.lang)}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return <></>;
  }
}
