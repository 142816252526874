import Auth from '../../authentication/Auth';
import { saveProduct, deleteProductsUrlAdminApi } from '../Constants';

export const saveProductRequest = (bodyProduct, requestType, callback) => {
  let url = `${saveProduct}`;

  let myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('access_token')}`,
  );
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('nonce', localStorage.getItem('nonce'));
  let bodyForm = JSON.stringify(bodyProduct);
  //console.log("bodyForm:"+bodyForm)
  fetch(url, {
    method: requestType ? 'PUT' : 'POST',
    headers: myHeaders,
    body: bodyForm,
  })
    .then(response => {
      if (response.status === 401) {
        let auth = new Auth();

        auth.sessionExpired();
      } else if (response.status === 409) {
        return (response.message = '409');
      } else {
        return response.json();
      }
    })
    .then(resultString => {
      //console.log(resultString)
      // //console.log(`data set 0 ${resultString}`)
      if (resultString.success) {
        callback(undefined, resultString.payload);
        //return resultString.payload
      } else {
        if (resultString.message !== undefined)
          callback(resultString.message, undefined);
        else {
          if (resultString === '409') callback(resultString, undefined);
          else callback('Unknown error', undefined);
        }
      }
    })
    .catch(err => {
      callback(err, undefined);
      ////console.log(err)
    });
};

export const deleteProductRequest = (productId, callback) => {
  let url = `${deleteProductsUrlAdminApi}/${productId}`;

  let myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('access_token')}`,
  );
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('nonce', localStorage.getItem('nonce'));
  //let bodyForm = JSON.stringify(bodyProduct)
  //console.log("bodyForm:"+bodyForm)
  fetch(url, {
    method: 'DELETE',
    headers: myHeaders,
    //body: bodyForm
  })
    .then(response => {
      if (response.status === 401) {
        let auth = new Auth();

        auth.sessionExpired();
      } else if (response.status === 409) {
        return (response.message = '409');
      } else {
        return response.json();
      }
    })
    .then(resultString => {
      //console.log(resultString)
      // //console.log(`data set 0 ${resultString}`)
      if (resultString.success) {
        callback(undefined, resultString.payload);
        //return resultString.payload
      } else {
        if (resultString.message !== undefined)
          callback(resultString.message, undefined);
        else {
          if (resultString === '409') callback(resultString, undefined);
          else callback('Unknown error', undefined);
        }
      }
    })
    .catch(err => {
      callback(err, undefined);
      ////console.log(err)
    });
};

/*export const getProductRequest = (props, callback) => {
	let url = `${saveProduct}?accountId=${props.selectedAdminAccounts[0].value}`;

	let myHeaders = new Headers();
	myHeaders.append(
		"Authorization",
		`Bearer ${localStorage.getItem("access_token")}`
	);
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("nonce", localStorage.getItem("nonce"));
	//let bodyForm = JSON.stringify(bodyProduct)
	//console.log("bodyForm:"+bodyForm)
	fetch(url, {
		method: "GET",
		headers: myHeaders,
	})
		.then((response) => {
			if (response.status === 401) {
				let auth = new Auth();

			  auth.sessionExpired(); 
			} else if (response.status === 409) {
				return (response.message = "409");
			} else {
				return response.json();
			}
		})
		.then((resultString) => {
			if (resultString.success) {
				callback(undefined, resultString.payload);
			} else {
				if (resultString.message !== undefined)
					callback(resultString.message, undefined);
				else {
					if (resultString === "409") callback(resultString, undefined);
					else callback("Unknown error", undefined);
				}
			}
		})
		.catch((err) => {
			callback(err, undefined);
		});
};*/
