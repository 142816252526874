import { compose, withState, withHandlers, lifecycle } from 'recompose';
import {
  updateCampaignsAlyUrl,
  successHttpCode,
  badParametersHttpError,
  lowestCostWithouCapBidStrategy,
} from '../../../../../components/Constants';
import { changeAccount, isBudget } from '../../../AssignView';
import View from './ModifyCampaign';
import {
  getPageHasError,
  createHeaders,
  calculateRemainingBudget,
  customFetch,
  toFbDate,
  toISODate,
  alertPopUp,
} from '../../../../../utils/functions';

let today = new Date();
today.setHours(0, 0, 0, 0);
let endDay = new Date(Date.now() + 2 * 24 * 60 * 60 * 1000);
endDay.setHours(0, 0, 0, 0);

const mycompose = compose(
  withState('recalculateMessages', 'changeRecalculateMessages', false),
  withState(
    'modifyCampaignNameHasError',
    'changeModifyCampaignNameHasError',
    false,
  ),
  withState(
    'modifyCampaignHasErrorBudget',
    'changeModifyCampaignHasErrorBudget',
    false,
  ),
  withState(
    'modifyCampaignHasErrorBiddingAmount',
    'changeModifyCampaignHasErrorBiddingAmount',
    false,
  ),
  withState('hasError', 'changeHasError', true),
  withState('showAdErrors', 'changeShowAdErrors', false),
  withState('refList', 'changeRefList', []),
  withState('visibleSnackBar', 'setVisibleSnackBar', false),
  withState('resultCampaign', 'setResultCampaign', false),
  withState('resultCampaignMessage', 'changeResultCampaignMessage', ''),

  lifecycle({
    componentDidMount() {},

    componentWillUnmount() {
      //window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
    componentDidUpdate() {
      let newHasError = getPageHasError(this.props);

      if (newHasError !== this.props.hasError)
        this.props.changeHasError(newHasError);
    },
  }),
  withHandlers({
    changeSelectedCampaignDatesHandler: props => dates => {
      props.changeRecalculateMessages(!props.recalculateMessages);

      calculateRemainingBudget(
        props.modifyCampaignDailyBudget.value[0],
        dates,
        props,
      );

      props.changeModifySelectedDates({
        modified: true,
        value: dates,
      });
    },
    setModifyClicked: props => event => {
      var myHeaders = createHeaders();
      //
      let bodyForm = { campaignExtId: props.modifyCampaignid };

      if (
        props.selectedAdminAdAccounts !== undefined &&
        props.selectedAdminAdAccounts.length > 0
      ) {
        bodyForm.adAccountId = props.selectedAdminAdAccounts[0].value;
      }

      if (props.modifySelectedDates.modified) {
        bodyForm.endDate = encodeURIComponent(
          toISODate(props.modifySelectedDates.value[1]),
        );
        bodyForm.endDateUnix = toFbDate(
          props.modifySelectedDates.value[1],
          true,
        );
      }
      if (props.modifyCampaignStatus.modified) {
        bodyForm.status = props.modifyCampaignStatus.value;
      }
      if (props.modifyCampaignName.modified) {
        bodyForm.name = props.modifyCampaignName.value[0];
      }
      if (props.modifyCampaignDailyBudget.modified) {
        bodyForm.dailyBudget = props.modifyCampaignDailyBudget.value;
      }
      if (props.modifyCampaignSelectedBidStrategies.modified) {
        bodyForm.bidStrategy =
          props.modifyCampaignSelectedBidStrategies.value[0].value;
      }
      if (
        props.modifyCampaignBidValue.modified &&
        props.modifyCampaignSelectedBidStrategies.value[0].value !==
          lowestCostWithouCapBidStrategy
      ) {
        bodyForm.biddingAmount = props.modifyCampaignBidValue.value[0];
      }
      //console.log(props.modifyCampaignSelectedBidStrategies)
      //console.log(props.modifyCampaignBidValue)
      //console.log(bodyForm)
      //console.log(url);
      customFetch(
        updateCampaignsAlyUrl,
        {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify(bodyForm),
        },
        updateCampaignResult,
        [],
        props,
        false,
        true,
      );
    },
    setVisibleSnackBar: props => visible => {
      changeAccount(isBudget, true, props.selectedAdminAdAccounts, props, true);
      props.changeShowModify(false);

      props.setVisibleSnackBar(visible);
    },
  }),
);

function updateCampaignResult(result, params, props, updateSelected, status) {
  if (result.success && status === successHttpCode) {
    alertPopUp('Modificación Exitosa.', props);
    props.changeIsLoadingLayout(false);
  } else {
    props.setResultCampaign(false);
    let errorMessage = 'Ha ocurrido un error, porfavor intente de nuevo.';
    if (status === badParametersHttpError) {
      if (result.message && result.message.length > 0)
        errorMessage = result.message;
    }
    alertPopUp(errorMessage, props, true);
    props.changeIsLoadingLayout(false);
  }
  props.setVisibleSnackBar(true);
  if (props.changeIsLoadingSideBar) props.changeIsLoadingSideBar(false);
  if (props.changeIsLoadingLayout) props.changeIsLoadingLayout(false);
}
export default mycompose(View);
