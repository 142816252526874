import React from 'react';
import {
  withStyles,
  Checkbox,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import Widget from '../../../components/Widget/WidgetContainer';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import {
  facebookPlacementParameters,
  facebookProvider,
} from '../../../components/Constants';
import { clearAdPage } from '../../../utils/functions';
import trans from './../../../i18n/tran';
const FacebookPlacement = ({ classes, theme, onChange, value, ...props }) => {

  if (props.reset) { 
    return <div></div>
  }
  else {
    for (const group in facebookPlacementParameters) {
      facebookPlacementParameters[group].forEach(ele => {
        ele.label = trans(ele.key, props.lang)
      });
    }
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Widget
              overflow={true}
              focusable={
                <Focusable
                  id={props.focusId}
                  refList={props.refList}
                ></Focusable>
              }
              title={trans('NEW_CAMPAIGN_FB_PLACEMENTS_SUB', props.lang)}
              bodyClass={classes.tableWidget}
              disableWidgetMenu={true}
            >
              <Grid container>
                {facebookPlacementParameters[
                  `${props.selectedAdminCampaignTypes[0].value}`
                ].map((parameter, index) => (
                  <Grid item xs={12} sm={4} md={2} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.selectedFacebookPositions.includes(
                            parameter.value,
                          )}
                          onChange={(event, checked) => {
                            let newFacebookPositions = [];
                            if (checked) {
                              newFacebookPositions =
                                props.selectedFacebookPositions;
                              newFacebookPositions.push(parameter.value);
                              props.changeFacebookPositions(
                                newFacebookPositions,
                              );
                              props.changeVisiblePreview(false);
                              props.changePreview([]);
                              props.changeSelectedAppStores([]);
                              props.changeSelectedApplications([]);
                              props.changeSelectedConversations([]);
                              if (props.selectedAdminCampaignTypes.length > 0)
                                clearAdPage(
                                  props,
                                  props.selectedAdminCampaignTypes[0].value,
                                  [facebookProvider],
                                  false,
                                );
                              let providers = props.providers;
                              if (!props.providers.includes('facebook')) {
                                providers.push('facebook');
                              }

                              props.changeProviders(providers);
                            } else {
                              newFacebookPositions = [];
                              props.selectedFacebookPositions.forEach(
                                element => {
                                  if (element !== parameter.value)
                                    newFacebookPositions.push(element);
                                },
                              );
                              props.changeFacebookPositions(
                                newFacebookPositions,
                              );
                              if (
                                props.selectedAdminCampaignTypes.length > 0 &&
                                props.selectedInstagramPositions.length === 0 &&
                                props.selectedFacebookPositions.length === 0
                              ) {
                                clearAdPage(
                                  props,
                                  props.selectedAdminCampaignTypes[0].value,
                                  [facebookProvider],
                                  true,
                                );
                              } else {
                                clearAdPage(
                                  props,
                                  props.selectedAdminCampaignTypes[0].value,
                                  [facebookProvider],
                                  false,
                                );
                              }
                              if (newFacebookPositions.length === 0) {
                                let providers = props.providers;
                                let indexProvider =
                                  providers.indexOf('facebook');
                                if (indexProvider > -1) {
                                  providers.splice(indexProvider, 1);
                                  props.changeProviders(providers);
                                }
                              }
                            }
                          }}
                        ></Checkbox>
                      }
                      label={parameter.label}
                    />
                  </Grid>
                ))}
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
};
export default withStyles(styles, { withTheme: true })(FacebookPlacement);
