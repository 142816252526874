import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import Widget from '../../../../components/Widget/WidgetContainer';
//"../../components/Widget/WidgetContainer";
import EnhancedTable from '../../../../components/EnhancedTable';
import TextField from '@material-ui/core/TextField';
import Product from '../../../createad/Components/Product';
import Button from '@material-ui/core/Button';
import { addProductToAssets } from '../../../../utils/functions';
import { styles, getItem12Button } from '../../../../utils/styles';

const AssignProduct = ({ classes, theme, onChange, value, ...props }) => {
  /*function getSelectedCampaigns() {
		let list = "";
		props.selectedFacebookCampaigns.forEach(element => {
			if (list === "") list = element.label;
			else list += ", " + element.label;
		});
		return list;
	}*/
  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        {' '}
        <Widget bodyClass={classes.tableWidget} disableWidgetMenu={true}>
          <h2 style={{ textAlign: 'center' }}>
            Asignación de Productos a Campañas sin Producto Asignado
          </h2>
        </Widget>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Widget bodyClass={classes.tableWidget} disableWidgetMenu={true}>
          <div>
            <div style={{ paddingBottom: '25px' }}>
              <TextField
                value={props.filter}
                placeholder={'Filtrar Campañas'}
                onChange={e => {
                  props.changeFilter(e.target.value);
                  let filtered = [];
                  props.campaignsWithoutProduct.forEach(element => {
                    if (
                      element.campaign.label
                        .toUpperCase()
                        .includes(e.target.value.toUpperCase()) ||
                      element.campaignType
                        .toUpperCase()
                        .includes(e.target.value.toUpperCase())
                    )
                      filtered.push(element);
                  });
                  props.changeUpdateProducts(true);
                  props.changeCampaignsWithoutProductFiltered(filtered);
                }}
              ></TextField>
            </div>
          </div>
          <div style={{ overflow: 'hidden' }}>
            <EnhancedTable
              updateTable={props.updateTable}
              mainId="campaign"
              changeRefresh={props.changeRefresh}
              refresh={props.refresh}
              onChecked={props.changeSelectedFacebookCampaigns}
              selectAll={true}
              ignoreTableClass={true}
              resizeWidthCodes={['xs', 'xs-sm', 'sm']}
              headCells={[
                {
                  id: 'campaign',
                  numeric: false,
                  disablePadding: true,
                  label: 'Campaña',
                },
                {
                  id: 'campaignType',
                  numeric: true,
                  disablePadding: false,
                  label: 'Tipo de Campaña',
                },
                {
                  id: 'account',
                  numeric: true,
                  disablePadding: false,
                  label: 'Negocio Administrador',
                },
                {
                  id: 'adAccount',
                  numeric: true,
                  disablePadding: false,
                  label: 'Cuenta Publicitaria',
                },
              ]}
              rows={props.campaignsWithoutProductFiltered}
              page={props.pagePagination}
              setPage={props.changePagePagination}
            ></EnhancedTable>
          </div>
          <div style={{ height: '50px' }}></div>
        </Widget>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <div>
          <Product isCampaign={true} {...props}></Product>
          <div>
            <div style={{ width: '120%', maxWidth: '700px', float: 'right' }}>
              <Button
                style={getItem12Button()}
                id="assign-Product"
                disabled={
                  props.selectedFacebookCampaigns.length === 0 ||
                  props.selectedAdminProducts.length === 0
                }
                variant="contained"
                size="small"
                color="primary"
                className={classes.mainButton}
                onClick={e => {
                  addProductToAssets(
                    {
                      selectedFacebookCampaigns:
                        props.selectedFacebookCampaigns,
                      selectedProducts: props.selectedAdminProducts,
                      selectedAdAccounts: props.selectedAdminAdAccounts,
                      selectedAdminAccounts: props.selectedTenants,
                      api: 'Admin',
                      withoutProduct: true,
                    },
                    props,
                  );
                }}
              >
                Asignar Productos
              </Button>
            </div>
          </div>
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(AssignProduct);
