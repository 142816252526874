import { compose, withState, withHandlers, lifecycle } from 'recompose';
import View from './ManageBudget';
//import { saveAssignRequest } from "../../components/ApiClient/Assign";

const mycompose = compose(
  withState('filter', 'changeFilter', ''),
  withState('refresh', 'changeRefresh', false),
  withState('showPermisionTooltip', 'changeShowPermisionTooltip', false),
  withState('showValuesTooltip', 'changeShowValuesTooltip', false),
  withState('refresh', 'changeRefresh', false),

  lifecycle({
    componentDidMount() {
      this.props.changeSelectedCategoriesFiltered([]);
      this.props.changeSelectedAdminProductsFiltered([]);
      this.props.changeSelectedCountriesFiltered([]);
    },

    componentWillUnmount() {},
  }),
  withHandlers({}),
);

export default mycompose(View);
