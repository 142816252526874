import React from 'react';
import { withStyles, Grid } from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/Error';
import Widget from '../../components/Widget/WidgetContainer';

import DatePickerWithSlider from '../../components/DatePickerWithSlider';
import PageTitle from '../../components/PageTitle';
import DraftTable from './Components/Grid';

const Drafts = ({ classes, theme, ...props }) => {
  return (
    <React.Fragment>
      <PageTitle title="Campanas Creadas" />

      <DatePickerWithSlider
        readOnly
        showErrors={false}
        fontSize={'0.8rem'}
        reset={props.reset}
        changeReset={props.changeReset}
        ErrorIcon={<ErrorIcon />}
        dateRange={[new Date(), new Date()]}
        id={'date_picker'}
        validateDateEqualOrBiggerThanToday={false}
        hideSlider={true}
        changeError={() => {}}
        Error={false}
        switchDates={true}
        selectedDates={props.selectedDates}
        changeDateRange={() => {}}
        changeSelectedDates={props.updateSelectedDates}
      >
        {' '}
      </DatePickerWithSlider>

      <Grid
        container
        spacing={4}
        style={{
          paddingTop: 14,
        }}
      >
        <Grid item xs={12} md={12} lg={12}>
          <Widget disableWidgetMenu={true}>
            <DraftTable {...props} />
          </Widget>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(Drafts);
