import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PageTitle from '../../components/PageTitle';
import Widget from '../../components/Widget';
import { Button } from '@material-ui/core';
import PopUp from '../../components/PopUp';
import { onBoardingOK } from '../../components/Constants';
import { getFbAdAccounts } from '../../utils/functions';
import { bindData } from './Container';
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable';
import {
  getWidgetTitleStyle,
  getEnhancedTableButton,
  resizeDiv,
} from '../../utils/styles';
import trans from './../../i18n/tran';

const AdAccounts = ({ classes, theme, ...props }) => {
  let dataCurrent = [];
  let dataMine = [];
  let data = props.adAccounts;

  if (data) {
    // console.log(data)
    data.forEach(element => {
      /*element.addToAlyAi = Status(
        element.Status,
        element.ExtId,
        element.Id,
        element.AssignedToCurrent === 1,
        element.AssignedToAccount === 1,
      );
      */
      element.addToAlyAi = CreateStatusAction(element);
      element.removeAdAccount = (
        <Button
          onClick={() => {
            props.deleteAdAcount(element.Id);
          }}
          style={getEnhancedTableButton({
            width: '50%',
            background: theme.palette.text.danger,
            borderRadius: 6,
            border: 0,
            color: 'white',
            height: '10%',
            padding: '5px 30px',
            '&:hover, &:focus': {
              backgroundColor: 'orange',
              color: 'white',
            },
          })}>
          X
        </Button>
      );
      element.label = element.ExtId;

      if (element.AssignedToCurrent === 0) {
        dataMine.push(element);
      } else {
        dataCurrent.push(element);
      }
    });

    return (
      <React.Fragment>
        <PageTitle title={trans('AD_ACCOUNTS', props.lang)} variant="h1" />
        <Grid container spacing={4}>
          {/* Unique Key Widget */}
          <Grid item xs={12} md={12} lg={12}>
            <Widget disableWidgetMenu={true}>
              {props.selectedTenants && props.selectedTenants.length > 0 ? (
                <div>
                  <div style={resizeDiv(4, 4, 4, 4)}>
                    <div style={{ paddingTop: '25px' }}>
                      <span style={getWidgetTitleStyle()}>
                        {props.selectedTenants[0].label}
                      </span>
                    </div>
                  </div>
                  <div style={resizeDiv(3, 4)}>
                    <div style={{ paddingTop: '25px' }}>
                      {trans('UNIQUE_KEY', props.lang) + ' :'}
                      <span>{props.selectedTenants[0].UniqueKey}</span>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </Widget>
          </Grid>
          {/* Ad Accounts */}
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={4}>
              {/* Ad Accounts associated to ALY */}
              <Grid item xs={12} md={12} lg={12}>
                <Widget
                  noBodyPadding
                  disableWidgetMenu={true}
                  bodyClass={classes.tableWidget}
                  title={
                    <div style={getWidgetTitleStyle()}>
                      {trans('AD_ACCOUNTS_PAGE_SUB1', props.lang) +
                        props.selectedTenants[0].label}
                    </div>
                  }>
                  {dataCurrent && dataCurrent.length > 0 ? (
                    <EnhancedTable
                      lang={props.lang}
                      noBoxShadow={true}
                      classes={classes}
                      changeRowsPerPage={props.changeRowsPerPage}
                      updateTableProducts={props.updateTableProducts}
                      mainId="Id"
                      changeRefresh={props.changeRefresh}
                      refresh={props.refresh}
                      selectAll={false}
                      rows={dataCurrent}
                      ignoreTableClass={true}
                      resizeWidthCodes={['xs', 'xs-sm', 'sm']}
                      headCells={[
                        {
                          id: 'label',
                          label: 'Id',
                          center: true,
                        },
                        {
                          id: 'Title',
                          label: trans('AD_ACCOUNTS_PAGE_COL_NAME', props.lang),
                          center: true,
                        },
                        {
                          id: 'addToAlyAi',
                          label:
                            trans('AD_ACCOUNTS_PAGE_COL_ASSOC', props.lang) +
                            props.selectedTenants[0].label,
                          center: true,
                          isButton: true,
                        },
                        {
                          id: 'removeAdAccount',
                          label: trans(
                            'AD_ACCOUNTS_PAGE_COL_REMOVE',
                            props.lang,
                          ),
                          center: true,
                          isButton: true,
                          isDeleteButton: true,
                        },
                      ]}
                      deleteIcon={
                        <div style={{ color: 'red' }}>
                          <CloseIcon />
                        </div>
                      }
                      deleteFunction={props.deleteAccount}
                      page={props.pagePagination}
                      setPage={props.changePagePagination}></EnhancedTable>
                  ) : (
                    <div>{trans('AD_ACCOUNTS_PAGE_ERROR1', props.lang)}</div>
                  )}
                </Widget>
              </Grid>
              {/* My Ad Accounts */}
              <Grid item xs={12} md={12} lg={12}>
                <Widget
                  noBodyPadding
                  disableWidgetMenu={true}
                  bodyClass={classes.tableWidget}
                  title={
                    <div style={getWidgetTitleStyle()}>
                      {trans('AD_ACCOUNTS_PAGE_MY', props.lang)}
                    </div>
                  }>
                  {dataMine && dataMine.length > 0 ? (
                    <EnhancedTable
                      lang={props.lang}
                      noBoxShadow={true}
                      classes={classes}
                      changeRowsPerPage={props.changeRowsPerPage}
                      updateTableProducts={props.updateTableProducts}
                      mainId="Id"
                      changeRefresh={props.changeRefresh}
                      refresh={props.refresh}
                      selectAll={false}
                      rows={dataMine}
                      ignoreTableClass={true}
                      resizeWidthCodes={['xs', 'xs-sm', 'sm']}
                      headCells={[
                        {
                          id: 'label',
                          label: 'Id',
                          center: true,
                        },

                        {
                          id: 'Title',
                          label: trans('AD_ACCOUNTS_PAGE_COL_NAME', props.lang),
                          center: true,
                        },
                        {
                          id: 'addToAlyAi',
                          label:
                            trans('AD_ACCOUNTS_PAGE_COL_ASSOC', props.lang) +
                            props.selectedTenants[0].label,
                          center: true,
                          isButton: true,
                        },
                      ]}
                      page={props.pagePagination2}
                      setPage={props.changePagePagination2}></EnhancedTable>
                  ) : (
                    <div>{trans('AD_ACCOUNTS_PAGE_MESSAGE4', props.lang)}</div>
                  )}
                </Widget>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PopUp
          isOpen={props.isOpen}
          message={props.message}
          handleClose={async e => {
            if (
              sessionStorage.getItem('onBoardingStatus') !==
              onBoardingOK.toString()
            ) {
              getFbAdAccounts(this.props, () => {
                bindData(props);
              });
            } else props.setIsOpen(false);
          }}
        />
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }

  //10 created en la tabla
  //20 asociada a la cuenta

  //  function Status(status, extId, alyId, assignedToCurrent, assignedToAccount) {
  function CreateStatusAction(ele) {
    const assignedToCurrent = ele.AssignedToCurrent === 1;
    const assignedToAccount = ele.AssignedToAccount === 1;

    /**
     * Si el estatus es 10 y no esta asociada a la cuenta actual(negocio)
     * Si el estatus es 10 y no esta asignado a ninguna otra cuenta
     * Si el estatus es 20 y no esta asociada a esta cuenta y ningua otra
     * cuenta
     *
     * segundo if esta asignado a la cuenta actual
     *
     */
    if (
      (ele.Status === 10 && assignedToCurrent) ||
      (ele.Status === 10 && !assignedToAccount) ||
      (ele.Status === 20 && !assignedToAccount && !assignedToCurrent)
    ) {
      return (
        <Button
          style={getEnhancedTableButton({
            background: theme.palette.text.danger,
            borderRadius: 6,
            border: 0,
            color: 'white',
            height: '10%',
            padding: '5px 30px',
            '&:hover, &:focus': {
              backgroundColor: 'orange',
              color: 'white',
            },
          })}
          value={ele.extId}
          onClick={e => props.setShowConfirmWindow(ele)}>
          {trans('AD_ACCOUNTS_PAGE_MESSAGE3', props.lang)}
        </Button>
      );
    } else if (assignedToCurrent) {
      return (
        <Button
          disabled={true}
          style={getEnhancedTableButton({
            background: theme.palette.disabled.light,
            borderRadius: 6,
            border: 0,
            color: theme.palette.text.success,
            height: '10%',
            padding: '5px 30px',
          })}>
          {trans('AD_ACCOUNTS_PAGE_MESSAGE2', props.lang)}
        </Button>
      );
    } else {
      return (
        <Button
          disabled={true}
          style={getEnhancedTableButton({
            background: theme.palette.disabled.light,
            borderRadius: 6,
            border: 0,
            color: theme.palette.text.success,
            height: '10%',
            padding: '5px 30px',
          })}>
          {trans('AD_ACCOUNTS_PAGE_MESSAGE1', props.lang)}
        </Button>
      );
    }
  }
};

const styles = theme => ({
  Widget: {
    overflowX: 'auto',
    padding: 60,
    paddingRight: 60,
    paddingleft: 60,
    paddingBottom: 25,
    width: '100%',
  },
  tableWidget: {
    overflowX: 'auto',
    padding: 10,
  },
  buttonstatus10: {
    background: '#ff5722',
    borderRadius: 6,
    border: 0,
    color: 'white',
    height: '10%',
    padding: '5px 30px',
    '&:hover, &:focus': {
      backgroundColor: 'orange',
      color: 'white',
    },
  },
  buttonstatus11: {
    background: '#00897b',
    padding: '5px 30px',
  },
  Button1: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignText: 'center',
    fontSize: '0.6rem',
    marginTop: theme.spacing(2),
  },
  linked: {
    color: '#1a237e',
  },
  notLinked: {
    color: '#ff5722',
  },
});

export default withStyles(styles, { withTheme: true })(AdAccounts);
