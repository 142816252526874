/// returns date difference in minutes
/// returns undefined if date1 or date2 are not valid dates
export function DateDiffMinutes(date1, date2) {
  if (date1 && date2) {
    /// https://stackoverflow.com/questions/643782/how-to-check-whether-an-object-is-a-date
    if (
      typeof date1.getMonth === 'function' &&
      typeof date2.getMonth === 'function'
    ) {
      let diff = Math.floor((date1 - date2) / 1000 / 60);
      return diff;
    }
  }
  return undefined;
}