import React, { Component } from 'react';
import Auth from '../../authentication/Auth';

export default class Callback extends Component {
  componentDidMount() {
    const auth = new Auth();
    auth.handleAuthentication();
  }
  render() {
    return <div>Loading...</div>;
  }
}
