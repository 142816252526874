import { request, requestHeaders } from './helper';
import { TierJson } from '../pages/onboarding/rest';

export const GetTiers = async () => {
  try {
    const headers = requestHeaders();
    return await request<TierJson[]>(
      `${process.env.REACT_APP_API_PAYWALL}/tiers`,
      {
        method: 'GET',
        headers: headers,
      },
    );
  } catch (e) {
    console.log('ValidateBusiness exception', e);
    const error: TierJson[] = [];
    return error;
  }
};
