import { compose, withState, withHandlers, lifecycle } from 'recompose';
import View from './BillingView';
import {
  getPayAlyProducts,
  getPayAlyCountries,
  getPayAlyCompanyInfo,
  getPayAlyCurrentSubscription,
} from '../../utils/functions';
//import { saveAssignRequest } from "../../components/ApiClient/Assign";
import { sendGAPageView } from '../../utils/ga';

const mycompose = compose(
  withState('filter', 'changeFilter', ''),
  withState('products', 'changeProducts', []),
  withState('selectedProduct', 'changeSelectedProduct', -1),
  withState(
    'selectedProductName',
    'changeSelectedProductName',
    'No Seleccionado',
  ),
  withState('companyName', 'changeCompanyName', ''),
  withState('nit', 'changeNit', ''),
  withState('selectedCountryCode', 'changeSelectedCountryCode', []),
  withState('selectorCountryCode', 'changeSelectorCountryCode', []),
  withState('companyAddress', 'changeCompanyAddress', ''),
  withState('email', 'changeEmail', ''),
  withState('phone', 'changePhone', ''),
  lifecycle({
    componentDidMount() {
      // to avoid calling it twice

      if (sessionStorage.getItem('errorPage') === null) {
        getPayAlyCurrentSubscription(
          this.props,
          () => {
            getPayAlyProducts(this.props);
            getPayAlyCountries(this.props);
            getPayAlyCompanyInfo(this.props);
          },
          true,
        );

        this.props.changeTenantChangeFunction({
          function: newTenant => {
            getPayAlyCurrentSubscription(
              this.props,
              () => {
                getPayAlyProducts(this.props);
                getPayAlyCountries(this.props);
                getPayAlyCompanyInfo(this.props);
              },
              true,
            );
          },
        });
      }
      //getCategoriesCreateAssign(this.props);
      //getCountriesCreateAssign(this.props);
      sendGAPageView('Billing Page');
    },

    componentWillUnmount() {
      //window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
    componentDidUpdate() {},
  }),
  withHandlers({}),
);

export default mycompose(View);
