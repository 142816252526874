import React from 'react';
import Select from 'react-select';
import { Grid, withStyles } from '@material-ui/core';
import { CustomDateSlider } from '../../components/Sidebar/CustomDateSlider';
import { CSVLink } from 'react-csv';
import PageTitle from '../../components/PageTitle';
import Widget from '../../components/Widget/WidgetContainer';
import { Typography, TextField, Button } from '@material-ui/core';
import {
  // accountSelectorId,
  sideBarDatePicker,
  //emptySelectorText,
  //productSelectorId,
  todasSelectorValue,
} from '../../components/Constants';
import {
  // getProductAdminApiFiltersBrandandCompany,
  getDataset10and11,
  updateSelector,
  updateProductAdminApiFiltersCreateAd,
  //getAdAccountsByProductApiAdmin
} from '../../utils/functions';
import { styles } from '../../utils/styles';
const DownloadStats = ({
  classes,
  theme,
  toggleSidebar,
  isSidebarOpened,
  isPermanent,
  location,
  ...props
}) => {
  function clean() {}
  return (
    <React.Fragment>
      <PageTitle title="Descarga de CSV" />
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={6}>
          <Widget
            overflow={true}
            title={'Fechas'}
            bodyClass={classes.WidgetCenter}
            disableWidgetMenu={true}
          >
            <CustomDateSlider
              hideSlider={true}
              changeError={() => {}}
              changeIsLoading={() => {}}
              fontSize={'1.2rem'}
              switchDates={true}
              monthSelector={false}
              onChange={() => {}}
              {...props}
              id={sideBarDatePicker}
            ></CustomDateSlider>
          </Widget>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Widget
            overflow={true}
            title={'Filtros'}
            bodyClass={classes.tableWidget}
            disableWidgetMenu={true}
          >
            {' '}
            <Typography>Empresa</Typography>
            <Select
              id={'CompanySelector'}
              placeholder={'Seleccione Empresa'}
              styles={theme.reactSelect}
              isDisabled={props.selectorAdminCompany.length === 0}
              isMulti={true}
              options={props.selectorAdminCompany}
              value={props.selectedAdminCompanies}
              onChange={e => {
                props.changeSelectorAdminAdAccount([]);
                props.changeSelectedAdminAdAccounts([]);
                //props.changeSelectorAdminProduct([]);
                props.changeSelectedAdminProducts([]);
                let params = {};
                if (e && e.length > 0) {
                  updateSelector(
                    true,
                    props.changeSelectedAdminCompanies,
                    props.selectedAdminCompanies,
                    e,
                    props.selectorAdminCompany,
                    'AdminCompaniesSelectorId',
                    props,
                    params,
                    'selectedAdminCompanies',
                    true,
                  );
                  //this means there are only two options (company and All)
                  if (!params.selectedAdminCompanies) {
                    params.selectedAdminCompanies =
                      props.selectorAdminCompany.map(value => value);
                    //remove Todos
                    if (params.selectedAdminCompanies.length > 0)
                      params.selectedAdminCompanies.pop();
                  }

                  updateProductAdminApiFiltersCreateAd(
                    props,
                    params.selectedAdminCompanies,
                    [],
                    true,
                    true,
                  );
                } else {
                  props.changeSelectedAdminCompanies([todasSelectorValue]);
                  updateProductAdminApiFiltersCreateAd(
                    props,
                    [todasSelectorValue],
                    [],
                    true,
                    true,
                  );
                }
                clean();
              }}
            ></Select>
            {props.selectedAdminCompanies.length ? (
              <div style={{ paddingTop: '10px' }}>
                <Button
                  style={{ width: '25%', float: 'right' }}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    getDataset10and11(props);
                    props.changeFileName('');
                  }}
                >
                  {' '}
                  Enviar
                </Button>
              </div>
            ) : (
              ''
            )}
          </Widget>
        </Grid>
        {props.dataSet10Json.length > 0 ? (
          <Grid item xs={12} md={12} lg={12}>
            <Widget
              overflow={true}
              title={'Archivo'}
              bodyClass={classes.tableWidget}
              disableWidgetMenu={true}
            >
              <TextField
                variant="outlined"
                placeholder={'Nombre del Archivo'}
                style={{ width: '100%' }}
                value={props.fileName}
                onChange={e => {
                  props.changeFileName(e.target.value);
                }}
              ></TextField>
              <div style={{ paddingTop: '10px' }}>
                <div
                  style={{
                    marginRight: '5px',
                    marginTop: '10px',
                    float: 'right',
                  }}
                >
                  <CSVLink
                    style={{
                      height: '4rem',
                      width: '10rem',
                      backgroundColor: theme.palette.text.primary,
                      color: 'white',
                      padding: '20px 25px',
                      textAlign: 'center',
                      textDecoration: 'none',
                      display: 'inline-block',
                      textTransform: 'uppercase',
                    }}
                    filename={
                      props.fileName.length > 0
                        ? props.fileName + '.csv'
                        : new Date().toLocaleDateString('es') + '.csv'
                    }
                    data={props.dataSet10Json}
                  >
                    Descargar CSV
                  </CSVLink>
                </div>
                {props.dataSet11Json &&
                props.dataSet11Json.length &&
                props.dataSet11Json.length > 0 ? (
                  <div
                    style={{
                      marginRight: '5px',
                      marginTop: '10px',
                      float: 'right',
                    }}
                  >
                    <CSVLink
                      style={{
                        height: '4rem',
                        width: '10rem',
                        backgroundColor: theme.palette.text.primary,
                        color: 'white',
                        padding: '14px 25px',
                        textAlign: 'center',
                        textDecoration: 'none',
                        display: 'inline-block',
                        textTransform: 'uppercase',
                      }}
                      filename={
                        props.fileName.length > 0
                          ? props.fileName + 'imagenes.csv'
                          : new Date().toLocaleDateString('es') +
                            '_imagenes.csv'
                      }
                      data={props.dataSet11Json}
                    >
                      Descargar CSV de Imágenes
                    </CSVLink>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Widget>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(DownloadStats);
