import React from 'react';
import { withStyles } from '@material-ui/core';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import TextFieldList from '../../../components/TextFieldList/TextFieldListView';

import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import trans from './../../../i18n/tran';

const Description = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <TextFieldList
      lang={props.lang}
      recalculateMessages={props.recalculateMessages}
      showAdErrors={props.showAdErrors}
      ErrorIconClass={classes.errorIcon}
      ErrorMessageClass={classes.warningMessage}
      reset={props.reset}
      changeReset={props.changeReset}
      focusable={
        <Focusable id={props.focusId} refList={props.refList}></Focusable>
      }
      id="description"
      widgetTitle={trans('NEW_CAMPAIGN_DESC', props.lang)}
      maxTextSize={255}
      maxListSize={1}
      WarningIcon={<WarningIcon />}
      ErrorIcon={<ErrorIcon />}
      maxTextSizeMessage={trans('NEW_CAMPAIGN_DESC_WARN1', props.lang)}
      warningTextSize={31}
      warningTextSizeMessage={trans('NEW_CAMPAIGN_DESC_WARN2', props.lang)}
      invalidUrlMessage={''}
      placeholder={trans('NEW_CAMPAIGN_DESC_ENTER', props.lang)}
      multiline={false}
      variant="outlined"
      changeError={props.changeHasErrorLink}
      Error={props.hasErrorLink}
      list={props.description}
      changeList={props.changeDescription}
    ></TextFieldList>
  );
};
export default withStyles(styles, { withTheme: true })(Description);
