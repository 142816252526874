import defaultTheme from './default';

const theme = {
  default: defaultTheme,
};
export default theme;

export const overrides = {
  typography: {
    fontFamily: 'Josefin Sans, Arial',
    useNextVariants: true,
    h1: {
      fontSize: '1.2rem',
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.64rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.285rem',
    },
    h6: {
      fontSize: '1.142rem',
    },
    subtitle1: {
      fontSize: '3rem',
    },
    subtitle2: {
      fontSize: '1rem',
    },
    body1: {
      fontSize: '1rem', // todo lo que no tiene un typography agarra este tag automaticamente
    },
    body2: {
      fontSize: '0.80rem',
    },
    caption: {
      fontSize: '1rem',
    },
    button: {
      fontSize: '1rem',
    },
    overline: {
      fontSize: '1rem',
    },
  },
};
