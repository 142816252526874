import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAccountIdContext } from './accountId';
import dayjs from 'dayjs';
import { GetAccountCompanies } from '../rest';
import { Drawer, drawerBleeding, DrawerProps } from '../components/Drawer';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ListSubheader, Paper, Radio } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { es } from 'date-fns/locale';
import { Company } from '../rest/GetAccountCompanies';

dayjs.extend(customParseFormat);
interface HomeFiltersContextProps {
  startDate: string;
  endDate: string;
  setStartDate: (value: string) => void;
  setEndDate: (value: string) => void;
  openFilterDrawer: () => void;
}
const HomeFiltersContext = createContext<HomeFiltersContextProps>({
  startDate: '',
  endDate: '',
  setStartDate: () => {},
  setEndDate: () => {},
  openFilterDrawer: () => {},
});

const YYYY_MM_DD = 'YYYY-MM-DD';
const DD__MM__YYYY = 'dd/MM/yyyy';
const DD__MM__YYYY2 = 'DD/MM/YYYY';
export const HomeFiltersContextWrapper: FC = ({ children }) => {
  const [startDate, setStartDate] = useState(
    dayjs().add(-12, 'month').format(YYYY_MM_DD),
  );

  const validateSetStartDate = (date: string) => {
    const valueFormated = dayjs(date, DD__MM__YYYY2).format(YYYY_MM_DD);
    setStartDate(valueFormated);
  };

  const [endDate, setEndDate] = useState(dayjs().format(YYYY_MM_DD));
  const validateSetEndDate = (date: string) => {
    const valueFormated = dayjs(date, DD__MM__YYYY2).format(YYYY_MM_DD);
    setEndDate(valueFormated);
  };

  const [companies, setCompanies] = useState<Company[]>([]);
  const [companiesSelected, setCompaniesSelected] = useState<number[]>([]);

  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  const { currentAccount } = useAccountIdContext();

  console.log('startDate', startDate);
  console.log('endDate', endDate);

  const getCompanies = useCallback(async () => {
    if (currentAccount) {
      const response = await GetAccountCompanies(
        currentAccount.Id,
        startDate,
        endDate,
      );
      setCompanies(response.data.payload);
    }
  }, [currentAccount, startDate, endDate]);
  useEffect(() => {
    if (currentAccount) {
      console.log('hola');
      getCompanies();
    }
  }, [currentAccount]);

  const openFilterDrawer = useCallback(() => {
    setShowFilterDrawer(true);
  }, [setShowFilterDrawer]);
  return (
    <HomeFiltersContext.Provider
      value={{
        startDate,
        endDate,
        openFilterDrawer,
        setStartDate: validateSetStartDate,
        setEndDate: validateSetEndDate,
      }}>
      <FilterDrawer
        drawerState={showFilterDrawer}
        setDrawerState={setShowFilterDrawer}
      />
      {children}
    </HomeFiltersContext.Provider>
  );
};

const FilterDrawer: FC<DrawerProps> = ({ drawerState, setDrawerState }) => {
  const { accounts, updateCurrentAccount, currentAccount } =
    useAccountIdContext();

  const { startDate, setStartDate, endDate, setEndDate } =
    useHomeFiltersContext();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <Drawer
        autoClose={false}
        drawerState={drawerState}
        setDrawerState={setDrawerState}
        anchorPosition="bottom">
        <Paper>
          <List
            subheader={
              <ListSubheader style={{ background: 'white' }}>
                Filters
              </ListSubheader>
            }>
            <ListItem>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <KeyboardDatePicker
                style={{ width: '100%' }}
                inputProps={{
                  style: {
                    fontSize: '0.9rem',
                    textAlign: 'center',
                  },
                  readOnly: false,
                }}
                KeyboardButtonProps={{
                  size: 'small',
                }}
                autoOk
                maxDateMessage=""
                minDateMessage=""
                disableToolbar
                variant="inline"
                format={DD__MM__YYYY}
                label="Fecha de Inicio"
                value={startDate}
                onChange={(e, value) => {
                  if (value) {
                    setStartDate(value);
                  }
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <KeyboardDatePicker
                style={{ width: '100%' }}
                inputProps={{
                  style: {
                    fontSize: '0.9rem',
                    textAlign: 'center',
                  },
                  readOnly: false,
                }}
                KeyboardButtonProps={{
                  size: 'small',
                }}
                autoOk
                maxDateMessage=""
                minDateMessage=""
                disableToolbar
                variant="inline"
                format={DD__MM__YYYY}
                label="Fecha de Fin"
                value={endDate}
                onChange={(e, value) => {
                  if (value) {
                    setEndDate(value);
                  }
                }}
              />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="New Business" />
            </ListItem>
          </List>
        </Paper>
      </Drawer>
    </MuiPickersUtilsProvider>
  );
};

export const useHomeFiltersContext = () => {
  const context = useContext(HomeFiltersContext);

  if (context === undefined) {
    throw new Error('cant be use out of provider');
  }
  return context;
};
