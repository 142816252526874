import { compose, withState, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { getdateRange, getTenants } from '../../utils/functions';

import { toggleSidebar, toggleResizeRefresh } from './LayoutState';
import { refreshCustomPage } from '../../utils/functions';
import LayoutView from './LayoutView';
//import { homePagePageId, newAccountPageId } from "../Constants";
let dateRange = getdateRange();

function resize() {
  refreshCustomPage(
    this.props.toggleResizeRefresh,
    0,
    100,
    window.scrollX,
    window.scrollY,
  );
}

export default compose(
  connect(
    state => ({
      sideBarOpened: state.layout.isSidebarOpened,
      resizeRefresh: state.layout.resizeRefresh,
    }),
    { toggleSidebar, toggleResizeRefresh },
  ),

  withState(
    'modifyCampaignOriginalBudget',
    'changeModifyCampaignOriginalBudget',
    { modified: false, value: [''] },
  ),
  withState('modifyCampaignSpent', 'changeModifyCampaignSpent', {
    modified: false,
    value: [''],
  }),
  withState(
    'modifyCampaignRemainingSpent',
    'changeModifyCampaignRemainingSpent',
    { modified: false, value: [''] },
  ),
  withState('modifyCampaignAdjusted', 'changeModifyCampaignAdjusted', {
    modified: false,
    value: [''],
  }),
  withState('modifyCampaignDailyBudget', 'changeModifyCampaignDailyBudget', {
    modified: false,
    value: [''],
  }),
  withState('modifyCampaignBidValue', 'changeModifyCampaignBidValue', {
    modified: false,
    value: [''],
  }),
  withState(
    'modifyCampaignSelectorBidStrategy',
    'changeModifyCampaignSelectorBidStrategy',
    [],
  ),
  withState(
    'modifyCampaignSelectedBidStrategies',
    'changeModifyCampaignSelectedBidStrategies',
    {
      modified: false,
      value: [],
    },
  ),
  withState('modifyCampaignRemaining', 'changeModifyCampaignRemaining', {
    modified: false,
    value: [''],
  }),
  withState('modifyCampaignDuration', 'changeModifyCampaignDuration', {
    modified: false,
    value: [''],
  }),
  withState('modifyCampaignStatus', 'changeModifyCampaignStatus', {
    modified: false,
    value: '',
  }),
  withState('modifyObjective', 'changeModifyObjective', ''),
  withState('modifySelectedDates', 'changeModifySelectedDates', []),
  withState('modifyCampaignid', 'changeModifyCampaignid', ''),
  withState('modifyCampaignName', 'changeModifyCampaignName', {
    modified: false,
    value: [''],
  }),
  /***/
  withState('loadingMessage', 'changeLoadingMessage', ''),
  withState('hoverOnBoardingMessage', 'changeHoverOnBoardingMessage', false),
  withState('onBoardingMessage', 'changeOnBoardingMessage', ''),
  withState('onBoardingStatus', 'changeOnBoardingStatus', 0),
  withState('showOnBoardingMessage', 'changeShowOnBoardingMessage', false),
  // Optimization
  withState('selectorOptimization', 'changeSelectorOptimization', []),
  withState('selectedOptimizations', 'changeSelectedOptimizations', []),
  // Account
  withState('selectorAccount', 'changeSelectorAccount', []),
  withState('selectedAccounts', 'changeSelectedAccounts', []),

  // Company
  withState('selectorCompany', 'changeSelectorCompany', []),
  withState('selectedCompanies', 'changeSelectedCompanies', []),
  //SelectorCampaignName
  withState('selectorCampaignName', 'changeSelectorCampaignName', []),
  withState('selectedCampaignNames', 'changeSelectedCampaignNames', []),
  // Brand
  withState('selectorBrand', 'changeSelectorBrand', []),
  withState('selectedBrands', 'changeSelectedBrands', []),
  // Ad Account
  withState('selectorAdAccount', 'changeSelectorAdAccount', []),
  withState('selectedAdAccounts', 'changeSelectedAdAccounts', []),
  // Campaign Type
  withState('selectorCampaignType', 'changeSelectorCampaignType', []),
  withState('selectedCampaignTypes', 'changeSelectedCampaignTypes', []),
  // Category
  withState('selectorCategory', 'changeSelectorCategory', []),
  withState('selectedCategories', 'changeSelectedCategories', []),
  withState('selectorCategoryFiltered', 'changeSelectorCategoryFiltered', []),
  withState(
    'selectedCategoriesFiltered',
    'changeSelectedCategoriesFiltered',
    [],
  ),
  // Country
  withState('selectorCountryFiltered', 'changeSelectorCountryFiltered', []),
  withState('selectedCountriesFiltered', 'changeSelectedCountriesFiltered', []),
  withState('selectorCountry', 'changeSelectorCountry', []),
  withState('selectedCountries', 'changeSelectedCountries', []),
  // Product
  withState('selectorProduct', 'changeSelectorProduct', []),
  withState('selectedProducts', 'changeSelectedProducts', []),
  //
  withState(
    'companyCurrentSubscription',
    'changeCompanyCurrentSubscription',
    [],
  ),

  withState('selectorAdminAccount', 'changeSelectorAdminAccount', []),
  withState('selectedAdminAccounts', 'changeSelectedAdminAccounts', []),
  withState('selectorAdminAdAccount', 'changeSelectorAdminAdAccount', []),
  withState('selectedAdminAdAccounts', 'changeSelectedAdminAdAccounts', []),
  withState('selectorAdminCompany', 'changeSelectorAdminCompany', []),
  withState('selectedAdminCompanies', 'changeSelectedAdminCompanies', []),

  withState('selectorAdminBrand', 'changeSelectorAdminBrand', []),
  withState('selectedAdminBrands', 'changeSelectedAdminBrands', []),

  //
  withState('selectorAge', 'changeSelectorAge', []),
  withState('selectedAges', 'changeSelectedAges', []),
  //
  withState('selectorGender', 'changeSelectorGender', []),
  withState('selectedGenders', 'changeSelectedGenders', []),
  //
  withState('dateRange', 'changeDateRange', dateRange),
  withState('selectedDates', 'changeSelectedDates', dateRange),
  //

  withState('selectorFacebookCampaign', 'changeSelectorFacebookCampaign', []),
  withState('selectedFacebookCampaigns', 'changeSelectedFacebookCampaigns', []),
  //
  withState('isLoadingSideBar', 'changeIsLoadingSideBar', false),
  withState('isLoadingLayout', 'changeIsLoadingLayout', false),
  //used for onbaoarding
  withState(
    'customPageValidationEnded',
    'changeCustomPageValidationEnded',
    true,
  ),
  //used for permissions
  withState('pageIsValidated', 'changePageIsValidated', false),
  withState('goHome', 'changeGoHome', false),

  withState('isPrintingPage', 'changeIsPrintingPage', false),
  //
  withState('isFirstLoad', 'changeIsFirstLoad', true),
  //

	withState("dashboard31Data", "changeDashboard31Data", []),
	withState("dashboard32Data", "changeDashboard32Data", []),
	withState("dashboard33Data", "changeDashboard33Data", []),
	withState("dashboard34Data", "changeDashboard34Data", []),
	withState("dashboard35Data", "changeDashboard35Data", []),
	withState("dashboard36Data", "changeDashboard36Data", []),

  withState('dashboard11', 'setDashboard11'),
  withState('dashboard12', 'setDashboard12'),
  withState('dashboard13', 'setDashboard13'),
  withState('dashboard14', 'setDashboard14'),
  withState('dashboard15', 'setDashboard15'),

  withState(
    'selectorAdminProductFiltered',
    'changeSelectorAdminProductFiltered',
    [],
  ),
  withState(
    'selectedAdminProductsFiltered',
    'changeSelectedAdminProductsFiltered',
    [],
  ),
  withState('isFilterProducts', 'changeIsFilterProducts', false),
  withState('facebookNoGas', 'changeFacebookNoGas', false),
  withState('showModify', 'changeShowModify', false),
  withState('showConfirmPopUp', 'changeShowConfirmPopUp', false),
  withState('confirmPopUpCancelFunction', 'changeConfirmPopUpCancelFunction', {
    function: () => {},
  }),
  withState('confirmPopUpAcceptFunction', 'changeConfirmPopUpAcceptFunction', {
    function: () => {},
  }),
  withState('showSuccessAlert', 'changeShowSuccessAlert', false),
  withState('popUpAlertMessage', 'changePopUpAlertMessage', ''),
  withState('showErrorAlert', 'changeShowErrorAlert', false),
  withState('showWarningAlert', 'changeShowWarningAlert', false),
  withState('popUpAcceptLabel', 'changePopUpAcceptLabel', 'Aceptar'),
  withState('fileName', 'changeFileName', ''),
  withState('selectorAdminProduct', 'changeSelectorAdminProduct', []),
  withState('selectedAdminProducts', 'changeSelectedAdminProducts', []),
  withState('updateTable', 'changeUpdateTable', false),
  withState(
    'selectorCreateProductCompany',
    'changeSelectorCreateProductCompany',
    [],
  ),
  withState(
    'selectorCreateProductBrand',
    'changeSelectorCreateProductBrand',
    [],
  ),
  withState('tenantChangeFunction', 'changeTenantChangeFunction', {
    function: () => {},
  }),
  //withState("lastCustomPageId", "changeLastCustomPageId", -1),
  lifecycle({
    componentDidMount() {
      window.isReloading = false;
      window.onbeforeunload = function (e) {
        window.isReloading = true;
      };
      window.removeEventListener('resize', () => {});
      window.addEventListener('resize', resize.bind(this));

      getTenants(this.props);
    },
  }),
)(LayoutView);
