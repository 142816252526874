import React, { FC } from 'react';
import { StepIconProps } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import { makeStyles } from '@material-ui/core/styles';
import { OnBoardingStep } from '../pages/onboarding/steps';
import { palette } from '../pages/onboarding/theme';

interface Props {
  activeStep: number;
  steps: OnBoardingStep[];
}
export const TopStepper: FC<Props> = ({ activeStep, steps }) => {
  const classes = styles();
  return (
    <Stepper alternativeLabel activeStep={activeStep} className={classes.root}>
      {steps.map((step, index) => (
        <Step key={index} className={classes.radio}>
          <StepLabel StepIconComponent={CustomStepIconComponent}>
            <span
              className={
                index === activeStep || index <= activeStep
                  ? classes.labelActive
                  : classes.label
              }>
              {steps[index].label}
            </span>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const CustomStepIconComponent = (props: StepIconProps) => {
  const { active, completed } = props;
  let Component = RadioButtonUncheckedIcon;
  if (completed) {
    Component = CheckCircleIcon;
  }
  if (active) {
    Component = RadioButtonCheckedIcon;
  }
  return (
    <Component
      fontSize="medium"
      color={active || completed ? 'primary' : 'action'}
    />
  );
};

const styles = makeStyles({
  radio: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: 0,
  },
  root: {
    paddingTop: '40px',
    background: 'transparent',
    paddingLeft: 0,
    paddingRight: 0,
  },

  label: {
    fontFamily: 'Montserrat-Medium',
    fontSize: '9px',
    lineHeight: '16px',
    color: palette.slate,
  },
  labelActive: {
    fontFamily: 'Montserrat-Semibold',
    fontWeight: 'bold',
    fontSize: '9px',
    lineHeight: '16px',
    color: palette.purpleRain,
  },
});
