import axiosBase from 'axios';
import Auth from '../../authentication/Auth';

export interface GenericGetAccountResponse<T> {
  success: boolean;
  payload: T[];
}
export const axiosHeaders = (): any => {
  const nonce = localStorage.getItem('nonce');
  return {
    headers: {
      nonce,
    },
  };
};

export const requestHeaders = () => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  /*headers.append(
      'Authorization',
      `Bearer ${localStorage.getItem('access_token')}`,
    );*/
  headers.append('nonce', localStorage.getItem('nonce') || '');

  return headers;
};
// Make the `request` function generic
// to specify the return data type:
export function request<TResponse>(
  url: string,
  // `RequestInit` is a type for configuring
  // a `fetch` request. By default, an empty object.
  config: RequestInit = {},

  // This function is async, it will return a Promise:
): Promise<TResponse> {
  // Inside, we call the `fetch` function with
  // a URL and config given:
  return (
    fetch(url, config)
      // When got a response call a `json` method on it
      .then(response => response.json())
      // and return the result data.
      .then(data => data as TResponse)
  );

  // We also can use some post-response
  // data-transformations in the last `then` clause.
}

export const axios = axiosBase.create({});

axios.interceptors.response.use(
  response => {
    if (response.status === 401) {
      alert('You are not authorized');
    }
    return response;
  },
  error => {
    if (error.response.status === 401) {
      const auth = new Auth();
      auth.sessionExpired();
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  },
);

//https://lightrains.com/blogs/axios-intercepetors-react/
/*
* axios.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    const originalRequest = error.config

    if (
      error.response.status === 401 &&
      originalRequest.url === 'http://127.0.0.1:3000/v1/auth/token'
    ) {
      router.push('/login')
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const refreshToken = localStorageService.getRefreshToken()
      return axios
        .post('/auth/token', {
          refresh_token: refreshToken
        })
        .then(res => {
          if (res.status === 201) {
            localStorageService.setToken(res.data)
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + localStorageService.getAccessToken()
            return axios(originalRequest)
          }
        })
    }
    return Promise.reject(error)
  }
)
* */
