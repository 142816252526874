import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { Typography } from 'src/components/Wrappers/Wrappers';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Paper
} from '@material-ui/core';
import trans from './../../../i18n/tran';

const StandardLoc = ({classes, ...props}) => {

  const CountryGroups = ({classes,...props}) => {
    let counter = 0;
    console.log('CountryGroup Props:', props)
    return ( <>
      {props.country_groups && props.country_groups.map && props.country_groups.map(cg => {
        return(
          <>
            <TableRow key={counter++}>
              <TableCell>
                {trans('COUNTRY_GROUP', props.lang)}
              </TableCell>
              <TableCell  align="right">
                {cg}
              </TableCell>
            </TableRow>
          </>
        )
      })}
      </>
    )
  }
  const Regions = ({classes,...props}) => {
    let counter = 0;
    console.log('Regions Props:', props)
    return (<>
        { props.regions && props.regions.map && props.regions.map(reg => {
          return (
            <>
             <TableRow key={counter++}>
              <TableCell>
                {trans('REGION', props.lang)}
              </TableCell>
              <TableCell  align="right">
              {reg.name} ( {reg.country}) )
              </TableCell>
            </TableRow>
            </>
          )
        })}
        </>
    )
  }

  const Countries = ({classes,...props}) => {
    let counter = 0;
    console.log('countries props:', props);
    return(
      <>
        { props.countries && props.countries.map && props.countries.map(country => {
          return (
            <>
              <TableRow key={counter++}>
                <TableCell>
                  {trans('COUNTRY', props.lang)}
                </TableCell>
                <TableCell  align="right">
                {country}  
                </TableCell>
              </TableRow>              
            </>
          )
        })
      }
      </>
    )
  }
  return (
    <>  
        <Countries countries={props.locs.countries} lang={props.lang}  />
        <Regions regions={props.locs.regions} lang={props.lang} />
        <CountryGroups country_groups={props.locs.country_groups} lang={props.lang} />
    </>
    )   
}

const CustomLoc = ({classes, ...props}) => {
  console.log('CustomLoc props', props)
  let counter = 0;
  const locs = props.locs
    return (
      <>
        {locs && locs.map(point => {
          return (
            <>
              <TableRow key={counter++}>
                <TableCell>
                  <span>
                    { trans('CARDINAL_POINT', props.lang)}
                  </span>
                </TableCell>
                <TableCell align="right">
                  <span className={classes.bold}>{trans('LATITUDE', props.lang)} </span>
                  {point.latitude}
                  <span className={classes.bold}> {trans('LONGITUDE', props.lang)} </span>
                  {point.longitude}
                  <span className={classes.bold}> {trans('RADIUS', props.lang)} </span>
                  {point.radius}
                  {point.distance_unit === 'kilometer'
                    ? ' KM'
                    : point.distance_unit === 'mile'
                    ? ' Mi'
                    : ' ' + point.distance_unit
                  }
                  <span className={classes.bold}> {trans('COUNTRY', props.lang)} </span>
                  {point.country}
                </TableCell>
              </TableRow>
            </>
          )
        })
        }
      </>
    )  
}

const LocsFromSavedAudiences = ({ classes, ...props }) => {
  console.log('LocsFromSavedAudiences props:',props)
  if (
    props.audiences &&
    props.audiences.length > 0 &&
    props.audiences[0].targeting &&
    props.audiences[0].targeting.geo_locations
  ) {
    let locs = props.audiences[0].targeting.geo_locations;
      return (
        <Grid item xs={12}>
          <div
            className={classes.locStyle}>
            <div className={classes.locStyleChild}>
            <TableContainer component={Paper}  size="small" aria-label="a dense table">
              <Table className="mb-0">
                <TableHead>
                  <TableCell colSpan={2} align="center">
                    {trans('PLACES', props.lang)}  - {trans('INCLUDED', props.lang)}
                  </TableCell>
                </TableHead>
                <TableBody>
                  <CustomLoc locs={locs.custom_locations} lang={props.lang} classes={classes} />
                  {/* send locs (countries, regions, cities, zips) */}
                  <StandardLoc locs={locs} lang={props.lang} classes={classes} />
                </TableBody>
              </Table>
              </TableContainer>
            </div>
          </div>
        </Grid>
      )
  }    
  return (<></>)
}

const AgeGenderFromSavedAudiences = ({ classes, ...props }) => {
  console.log('AgeGenderFromSavedAudiences props:',props);
  const aus = props.audiences;
  return (
      <>
      { aus 
        && aus.length 
        && aus.length > 0  
        && aus[0].targeting &&
        <>
         <div
            className={classes.locStyle}>
            <div className={classes.locStyleChild}>
              <TableContainer component={Paper}>
              <Table className="mb-0">
                <TableBody>
                    <TableRow>
                      <TableCell>
                        {trans('GENDERS', props.lang)}
                      </TableCell>
                      { aus[0].targeting.genders && 
                          aus[0].targeting.genders.length &&
                          aus[0].targeting.genders.length> 0
                          ? <>

                            <TableCell align="right">
                              {aus[0].targeting.genders[0]===1 && <span>{trans('MALE', props.lang)}</span> }
                              {aus[0].targeting.genders[0]===2 && <span>{trans('FEMALE', props.lang)}</span> }
                            </TableCell>
                        </>
                        : <>
                          <TableCell align="right">
                            <span>{trans('BOTH',props.lang)}</span>
                          </TableCell>
                        </>
                      } 
                    </TableRow>
                    <TableRow>
                    <TableCell>
                      {trans('AGES', props.lang)}
                    </TableCell>
                    <TableCell align="right">
                    {aus[0].targeting.age_min &&
                      aus[0].targeting.age_min 
                    } - 
                    {aus[0].targeting.age_max===65 
                      ? <span>65+</span>
                      : <span>{aus[0].targeting.age_max}</span>
                    }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              </TableContainer>
          </div>
          </div>
        </>
      }
      </>
  )
} 

const BehAndInterests = ({classes, ...props}) => {
  console.log('BehAndInterests props:',props);
  const aus = props.audiences;
  let interests;
  let behaviors;
  let one=false;

  if (aus 
      && aus.length 
      && aus.length > 0  
      && aus[0].targeting
      && aus[0].targeting.flexible_spec 
      && aus[0].targeting.flexible_spec.length 
      && aus[0].targeting.flexible_spec.length >0){
        aus[0].targeting.flexible_spec.forEach(ele => {
          if ("behaviors" in ele) {
            behaviors = ele.behaviors;
            one = true;
          }
          if ("interests" in ele) {
            interests = ele.interests;
            one = true;
          }
        });
      }
  let beCounter = 0;
  let inCounter = 0;
  return (
    <>
    { one &&
      <div  className={classes.locStyle}>
        <div className={classes.locStyleChild}>
          <TableContainer>
            <Table className="mb-0">
              <TableBody>
              {   behaviors &&
                  behaviors.map &&
                  behaviors.map(be => {
                    return (
                            <>
                              {beCounter===0 &&
                                <TableRow key={beCounter++}>
                                  <TableCell align="center">
                                    {trans('BEHAVIORS', props.lang)}  
                                  </TableCell>
                                </TableRow>
                              }
                              <TableRow key={beCounter++}>
                                <TableCell>
                                   {be.name}  
                                  </TableCell>
                              </TableRow>              
                            </>
                          )
                    })
              }    
              {   interests &&
                  interests.map &&
                  interests.map(be => {
                    return (
                            <>
                            { inCounter===0 &&
                                <TableRow key={inCounter++}>
                                  <TableCell align="center">
                                    {trans('INTERESTS', props.lang)}  
                                  </TableCell>
                                </TableRow>
                              }
                              <TableRow key={inCounter++}>
                                <TableCell>
                                {be.name}  
                                </TableCell>
                              </TableRow>              
                            </>
                          )
                    })
              }    
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    }
    </>
  )
}

const SavedAudienceMessage = ({ classes, ...props }) => {
  console.log("SavedAudienceMessage props:", props)
  let audienceName;
  if (
    props &&
    props.audiences &&
    props.audiences.length &&
    props.audiences.length > 0
  ) {
    audienceName = props.audiences[0].label;
  }
  if(audienceName) {
    return  (
      <React.Fragment>
          <Typography>
            {trans('INTEREST_MESSAGE1', props.lang)} <bold>{audienceName}</bold>
          </Typography>
          {props.children}
      </React.Fragment>
    );
  }
  return (<></>);
};

const styles = theme => ({
  locStyle: {
    width: '100%',
    margin: 'auto',
  },
  bold: {
    fontWeight:"bold",
  },
  locStyleChild: {
    width: '50%',
    margin: 'auto',
    paddingTop: '15px',
    paddingBottom: '15px'
  }
});

const ComSavedAudienceMessage = withStyles(styles, { withTheme: true })(SavedAudienceMessage); 

export default ComSavedAudienceMessage;
export const ComLocsFromSavedAudiences = withStyles(styles, { withTheme: true })(LocsFromSavedAudiences);
export const ComAgeGenderFromSavedAudiences = withStyles(styles, { withTheme: true })(AgeGenderFromSavedAudiences); 
export const ComBehAndInterests = withStyles(styles, { withTheme: true })(BehAndInterests); 

