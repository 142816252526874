import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import trans from '../../i18n/tran';
import { useIntercom } from 'react-use-intercom';

const Help = ({ classes, theme, ...props }) => {
  const { startTour } = useIntercom();
  let cName = classes.HelpButton; 
  return (
    <Button
      variant="outlined"
      size="medium"
      className={cName}
      onClick={e => {
      if (startTour) {
        startTour(props.productTourId);
      }
    }}>
      <HelpIcon fontSize="medium"  />
      {props.buttonText ? props.buttonText : trans('HELP', props.lang)}
    </Button>
  )
}

const styles = theme => ({
  HelpButton: {
    display: 'flex',
    flexWrap: 'wrap',
    alignText: 'center',
    fontSize: '0.75rem',
    marginTop: theme.spacing(2),
    color: theme.palette.text.hint
  },
})

export default withStyles(styles, { withTheme: true })(Help);
