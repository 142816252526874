import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PageTitle from '../../components/PageTitle';
import Widget from '../../components/Widget';
import Selector from '../../components/Selectors';
import { CustomDateSlider } from '../../components/Sidebar//CustomDateSlider';

import ErrorIcon from '@material-ui/icons/Error';

import Dashboard12Component from './Components/Dashboard12';
import Dashboard13Component from './Components/Dashboard13';
import Dashboard14Component from './Components/Dashboard14';
import Dashboard15Component from './Components/Dashboard15';
import IAComponent from './Components/IA';
import IAAgeGender from './Components/AgeGender';

import { getComponentMapping } from './../../utils/functions';

import { sideBarDatePicker, productTours } from './../../components/Constants';
import trans  from './../../i18n/tran';
import Help from './../../components/Help/help';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import { orange } from '@material-ui/core/colors';
const Ads = ({ classes, theme, ...props }) => {

  return (
    <React.Fragment>
      <PageTitle title={trans('ADS', props.lang)} variant="h1" />

      <Grid container spacing={4} justifyContent="flex-end">
        <Grid item xs={12} md={12} lg={12}>
          <div style={{ width: '100%', margin: '20px auto' }}>
            <CustomDateSlider
              fontSize={props.dateFontSize ? props.dateFontSize : undefined}
              changeError={props.changeDateHasErrors}
              changeIsLoading={
                props.layoutLoading
                  ? props.changeIsLoadingLayout
                  : props.changeIsLoadingSideBar
              }
              ErrorfontSize={'0.7rem'}
              ErrorIcon={<ErrorIcon />}
              switchDates={true}
              monthSelector={props.customPageId === 3}
              onChange={
                getComponentMapping(props.customPageId, sideBarDatePicker).childFunction
              }
              {...props}
              id={sideBarDatePicker}
            ></CustomDateSlider>
            <Help
                className={"HelpButton"} 
                lang={props.lang} 
                productTourId={productTours.es.adsSelection.id} /> 
          </div>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Selector {...props} />
        </Grid>
        {/* Resultados por imagen */}
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <Widget
            title={trans('ADS_WIDGET1_HEAD', props.lang)}
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
          >
            <Dashboard12Component data={props.dashboard12} lang={props.lang} />
          </Widget>
        </Grid>
        {/* Text Combinations */}
        <Grid item xs={12} md={12} lg={7}>
          <Widget
            title={trans('ADS_WIDGET2_HEAD', props.lang)}
            upperTitle
            disableWidgetMenu={true}
            noBodyPadding
            bodyClass={classes.tableWidget}
          >
            <Dashboard13Component data={props.dashboard13}  lang={props.lang} />
            
          </Widget>
        </Grid>
        {/* Age Results */}
        <Grid item xs={12} md={6} lg={6}>
          <Widget
            title={trans('ADS_WIDGET3_HEAD', props.lang)}
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
          >
            <Dashboard14Component data={props.dashboard14} lang={props.lang} />
          </Widget>
        </Grid>
        {/* Gender Results */}
        <Grid item xs={12} md={6} lg={6}>
          <Widget
            title={trans('ADS_WIDGET4_HEAD', props.lang)}
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
          >
            <Dashboard15Component data={props.dashboard15} lang={props.lang} />
          </Widget>
        </Grid>
        {/* ALY AI */}
        <Grid item xs={12} md={12} lg={12}>
          <Widget
            title={<div>{trans('ADS_WIGET5_HEAD', props.lang)}{" "}
            <FiberNewIcon fontSize="medium" style={{ color: orange[500], marginLeft:'4px' }}  /></div>}
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}>
              <IAComponent 
                lang={props.lang} 
                campaigns={props.selectedFacebookCampaigns} 
                details={props.details}
                summary={props.summary}
                error={props.error}
                callLambdaCorrelation={props.callLambdaCorrelation} 
                lambdaData={props.lambdaData} 
                />
          </Widget>
        </Grid>
        {/* ALY AI - AGE GENDER */}
        { props.showKPIExtra && 
          <Grid item xs={12} md={12} lg={12}>
              <Widget
                title={<div>{trans('ADS_WIGET6_HEAD', props.lang)}{" "}
                <FiberNewIcon fontSize="medium" style={{ color: orange[500], marginLeft:'4px' }}  /></div>}
                upperTitle
                noBodyPadding
                disableWidgetMenu={true}
                bodyClass={classes.tableWidget}>
                  <IAAgeGender 
                    lang={props.lang} 
                    campaigns={props.selectedFacebookCampaigns} 
                    error={props.error}
                    callLambdaAgeGender={props.callLambdaAgeGender} 
                    ageGender={props.ageGender}

                  />

              </Widget>
          </Grid>
        }
      </Grid>
    </React.Fragment>
  );
};

const styles = theme => ({
  progressBar: {
    backgroundColor: theme.palette.secondary,
    width: '25%',
    weight: '25%',
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + '80 !important',
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  tableWidget: {
    overflowX: 'auto',
    fontSize: '0.9rem',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  HelpButton: {
    display: 'flex',
    flexWrap: 'wrap',
    alignText: 'center',
    fontSize: '0.75rem',
    marginTop: theme.spacing(2),
    color: theme.palette.text.hint
  },
  HelpButton2: {
    display: 'flex',
    flexWrap: 'wrap',
    alignText: 'center',
    fontSize: '0.75rem',
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary
  },
});

export default withStyles(styles, { withTheme: true })(Ads);
