import React from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import Widget from '../../../../components/Widget/WidgetContainer';
import EnhancedTable from '../../../../components/EnhancedTable';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';

const AssignOptimization = ({ classes, theme, onChange, value, ...props }) => {

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <div>
          <div>
            <Typography>
              Seleccione Tipo de Optimización que desea Asignar
            </Typography>
            <Select
              id={'TipodeOptimizacionSelectorId'}
              isMulti={false}
              styles={theme.reactSelect}
              isDisabled={props.selectorOptimization.length === 0}
              placeholder={'Seleccione Tipo de Optimización'}
              value={props.selectedOptimizations}
              options={props.selectorOptimization}
              onChange={e => {
                props.changeSelectedOptimizations([e]);
              }}
            ></Select>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Widget bodyClass={classes.tableWidget} disableWidgetMenu={true}>
          <div>
            <div style={{ paddingBottom: '10px' }}>
              <TextField
                value={props.filter}
                placeholder={'Filtrar Campañas'}
                onChange={e => {
                  props.changeFilter(e.target.value);
                  let filtered = [];
                  props.campaignsWithoutOptimization.forEach(element => {
                    if (
                      element.campaign.label
                        .toUpperCase()
                        .includes(e.target.value.toUpperCase())
                    )
                      filtered.push(element);
                  });
                  props.changeUpdateProducts(true);
                  props.changeCampaignsWithoutOptimizationFiltered(filtered);
                }}
              ></TextField>
            </div>
          </div>
          <div style={{ overflow: 'hidden' }}>
            <EnhancedTable
              mainId="campaign"
              changeRefresh={props.changeRefresh}
              refresh={props.refresh}
              onChecked={props.changeSelectedFacebookCampaigns}
              selectAll={true}
              headCells={[
                {
                  id: 'campaign',
                  numeric: false,
                  disablePadding: true,
                  label: 'Campaña',
                },
                {
                  id: 'campaignType',
                  numeric: true,
                  disablePadding: false,
                  label: 'Tipo de Campaña',
                },
                {
                  id: 'account',
                  numeric: true,
                  disablePadding: false,
                  label: 'Negocio Administrador',
                },
                {
                  id: 'addAccount',
                  numeric: true,
                  disablePadding: false,
                  label: 'Campaña',
                },
              ]}
              rows={props.campaignsWithoutOptimizationFiltered}
            ></EnhancedTable>
          </div>
          <div style={{ height: '50px' }}></div>
        </Widget>
      </Grid>
    </React.Fragment>
  );
};

const styles = theme => ({
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  infoMessage: {
    color: '#9F6000',
    backgroundColor: '#FEEFB3',
    margin: theme.spacing(1),
  },
  textField: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: 500,
    borderColor: '#013f8a',
  },
  margin: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
    width: 400,
  },
  fanButton: {
    float: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(1),
    alignText: 'center',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(0),
  },
  tableWidget: {
    overflowX: 'visible',
  },
  datePicker: {
    overflowX: 'visible',
    padding: theme.spacing(2),
    margin: 'auto',
  },
  errorIcon: {
    fontSize: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    float: 'left',
  },
  warningMessage: {
    fontSize: '0.9rem',
    height: '1.5rem',
    paddingTop: '0.3rem',
    float: 'left',
  },
  Button: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignText: 'center',
    fontSize: '0.75rem',
    marginTop: theme.spacing(2),
  },
});

export default withStyles(styles, { withTheme: true })(AssignOptimization);
