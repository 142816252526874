import React from 'react';

import trans from '../../../i18n/tran';
import { Button } from 'src/components/Wrappers/Wrappers';

import {
  withStyles,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Box,
  Paper,
  Accordion,
  AccordionDetails,
  Typography,
  AccordionSummary
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GridContainer from '../../../components/Grid/GridContainer.js';
import GridItem from '../../../components/Grid/GridItem.js';
import { Track, NewEvent } from '../../../utils/mixpanel';

const IAComponent = ({ classes, theme, ...props }) => {
  const [expanded1, setExpanded1] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded1(isExpanded ? true : false);
  };
  let currency;
  if (props.campaigns && props.campaigns.length && props.campaigns.length > 0) {
    currency = props.campaigns[0].Currency;
    return (
      <div>        
        <GridContainer className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={12} className={classes.action}>
            <Button
              variant="contained"
              color="primary"
              className={classes.wide}
              onClick={(e) => {
                props.callLambdaCorrelation(e);
                Track(NewEvent("ads", "aly_log_botton", "click"));
              }}
            >
              {trans('ALGO_CORR_NAME', props.lang)}
            </Button>
          </GridItem>
          {props.summary && props.summary.length > 0 &&
            <GridItem xs={12} sm={12} md={12} lg={12} className={classes.summary}>
              <Box>
                <Paper>
                  <Table className={classes.summaryTable} size="medium" padding="normal">
                    <TableHead>
                      <TableRow>
                        <TableCell>{trans('ALY_LOG_CHANGES_TYPE', props.lang)}</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>{trans('ALY_LOG_CHANGES', props.lang)}</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>{trans('ALY_LOG_TIME_SAVED', props.lang)}</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>{trans('ALY_LOG_ADDITIONAL_ACTIONS', props.lang)}</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>{trans('ALY_LOG_IMPROVEMENT', props.lang)}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.summary.map(({ id, col1, col2, col3, col4 }) => (
                        <TableRow key={id}>
                          <TableCell>{trans('BUDGET', props.lang)}</TableCell>
                          {/* Modificaciones Automaticas */}
                          <TableCell style={{ textAlign: 'center' }}>{col1}</TableCell>
                          {/* Tiempo Ahorrado */}
                          <TableCell style={{ textAlign: 'center' }}>{col3} hrs</TableCell>
                          {/* Acciones Adicionales */}
                          <TableCell style={{ textAlign: 'center' }}>{col2}</TableCell>
                          {/* Mejora En Costo */}
                          <TableCell style={{ textAlign: 'center' }}>{col4} %</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
            </GridItem>
          }
          {props.details && props.details.length > 0 &&
            <GridItem xs={12} sm={12} md={12} lg={12} className={classes.detContainer}>
              <Accordion expanded={expanded1} onChange={handleChange('panel1')} style={{ borderShadow: 'none' }} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.accordionSummary}
                  aria-controls="panel1b-content"
                  id="panel1b-header"
                >
                  <Typography className={classes.heading}>
                    {trans('ALGO_CORR_MESSAGE1', props.lang)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* OPTIMIZATION DETAILS */}
                  <GridItem>
                    <Table >
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.detHead}>{trans('DATE', props.lang)}</TableCell>
                          <TableCell className={classes.detHeadCenter}>{trans('AGE', props.lang)}</TableCell>
                          <TableCell className={classes.detHeadCenter}>{trans('GENDER', props.lang)}</TableCell>
                          <TableCell className={classes.detHeadCenter}>{trans('ACTIONS', props.lang)}</TableCell>
                          <TableCell className={classes.detHeadCenter}>{trans('ACTIONS_PERCENTAGE', props.lang)}</TableCell>
                          <TableCell className={classes.detHeadCenter}>{trans('ALY_LOG_BUDGET', props.lang)}</TableCell>
                          <TableCell className={classes.detHeadCenter}>{trans('ALY_LOG_MOD_BUDGET', props.lang)}</TableCell>
                          <TableCell className={classes.detHeadCenter}>{trans('ALY_LOG_SAVINGS', props.lang)}</TableCell>
                          <TableCell className={classes.detHeadCenter}>{trans('ALY_LOG_CPA', props.lang)}</TableCell>
                          <TableCell className={classes.detHeadCenter}>{trans('ALY_LOG_CPA_MOD', props.lang)}</TableCell>
                          <TableCell className={classes.detHeadCenter}>{trans('ALY_LOG_CPA_SAVINGS', props.lang)}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.details.map(({ id, run_date, age, gender, actions, delta_actions, percent_actions, cpa, delta_cpa, percent_cpa, cost, delta_cost, percent_cost }) => (
                          <TableRow key={id}>
                            <TableCell>{run_date}</TableCell>
                            <TableCell className={classes.detCell}>{age}</TableCell>
                            <TableCell className={classes.detCell}>{gender}</TableCell>
                            <TableCell className={classes.detCell}>{delta_actions}</TableCell>
                            <TableCell className={classes.detCell}>{percent_actions} %</TableCell>
                            <TableCell className={classes.detCell}>{cost} {currency}</TableCell>
                            <TableCell className={classes.detCell}>{delta_cost} {currency}</TableCell>
                            <TableCell className={classes.detCell}>{percent_cost} %</TableCell>
                            <TableCell className={classes.detCell}>{cpa} {currency}</TableCell>
                            <TableCell className={classes.detCell}>{delta_cpa} {currency}</TableCell>
                            <TableCell className={classes.detCell}>{percent_cpa} %</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </GridItem>
                </AccordionDetails>
              </Accordion>
            </GridItem>
          }
          {props.error &&
            <span>{trans('ERROR_1', props.lang)}</span>
          }
        </GridContainer>
      </div>
    );
  } else {
    return (
      <div>
        <span>{trans('NO_INFO', props.lang)}</span>
      </div>
    );
  }
};

const styles = theme => ({
  accordionSummary: {
    flexDirection: 'column',
    display:'flex'
  },
  wide: {
    minWidth: '300px',
    borderRadius: '12px',
    textTransform: 'none',
    fontWeight: '900'
  },
  action: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center'
  },
  summary: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  summaryTable: {
    minWidth: "780px",
    maxWidth: "1024px"
  },
  detCell: {
    textAlign: 'center',
    minWidth: '130px',
  },
  detHeadCenter: {
    textAlign: 'center',
    minWidth: '130px',
    backgroundColor: theme.palette.text.primary,
    color: 'white'
  },
  detHead: {
    minWidth: '130px',
    backgroundColor: theme.palette.text.primary,
    color: 'white'
  },
  detContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    minWidth: '720px'

  },
  container: {
    padding: '20px'
  }
})

export default withStyles(styles, { withTheme: true })(IAComponent);
