import {mixPanelProjectToken, Environment} from './../components/Constants'

const mixpanel = require('mixpanel-browser');

let IsLoaded = false;
let Events = [];

const sections = [ 
    {
        name:"ad_accounts", 
        components:[
            { 
                name:"delete", 
                events: [
                    { name: "click" },
                    { name: "success" },
                    { name: "fail" }
                    ],
            },
            {
                name:"assoc_with_aly",
                events: [
                    { name: "click" },
                    { name: "success" },
                    { name: "fail" }
                ]
            }
        ]
    },
    {
        name:"login",
        components: [
            {
                name:"page",
                events: [
                    { name:"load" },
                    { name: "signup" }
                ]
            },
            {
                name:"facebook_login",
                events: [
                    { name: "click" },
                    { name: "success" },
                    { name: "fail" }
                ]
            }
        ]
    },
    {
        name:"new_campaign",
        components: [
            {
                name:"create_botton",
                events: [
                    { name:"click" },
                    { name: "success" },
                    { name: "fail"}
                ]
            },
            {
                name:"page",
                events: [
                    { name: "load"}
                ]
            }
        ]
    },
    {
        name:"ads",
        components: [
            { 
                name:"aly_log_botton",
                events: [
                    { name: "click" },
                    { name: "sucess"},
                    { name: "fail"}
                ]
            },
            {
                name:"image_results",
                events: [
                    { name: "success"}
                ]
            }
        ]
    },
    {
        name:"menu",
        components: [
            {
                name:"business_dropdown",
                events: [
                    { name:"click"},
                    { name:"changed"}
                ]
            }
        ]
    }
];

export const NewTrackData = (props, append={}) => {
    try {
        return {
            ...append,
            "account_id": props.selectedTenants[0].value,
            "account_name": props.selectedTenants[0].label,
            "unique_key": props.selectedTenants[0].UniqueKey
        }
    }catch(e) {warn(e)}
    return {...append}
}

export const ExportEvents = () => {
    let events = [];
    sections.forEach(sec => {
        sec.components.forEach(com => {
            com.events.forEach(e => {
                events.push(`${sec.name},${com.name},${e.name}`)
            });
        });
    });
    return events;
}

export const NewEvent = (secName, comName, eName) => {
    const section = sections.find( item => item.name === secName);
    if (section) {
        const com = section.components.find ( item => item.name === comName);
        if (com) {
            const e = com.events.find( item => item.name === eName)
            if (e) {
                return `${section.name}_${com.name}_${e.name}`
            }
        }
    }
    return undefined;
}
  
function mixPanelLoaded() {
    IsLoaded = true;
    log("init completed");
    flushEvents();
}

function flushEvents() {
    while (Events.length>0) {
        let event = Events.shift();
        mixpanel.track(event.name, event.data);
    }
    log("events flushed");
}

function log(...args) {
    if (Environment==='DEV') {
        console.log("mixpanel", args);
    }
}

function warn(...args) {
    if (Environment==='DEV') {
        console.warn("mixpanel", args)
    }
}

export function Init() {
    let ops = {
        loaded:mixPanelLoaded
    };
    if (Environment==='DEV') {
        ops.debug = true;
    }
    mixpanel.init(mixPanelProjectToken, ops);
    log("init started");
}

export function Track(eventName, eventData={}) {
    // check eventName
    if (!eventName) {
        warn("warning !eventName");
        return
    }
    // append environment
    eventData.env = Environment;
    log("track called", eventName)
    if (!IsLoaded) {
        Events.push({
            name:eventName,
            data:eventData
        })
        return;
    }
    mixpanel.track(eventName, eventData);
}

export function Identify(userid, props) {
    mixpanel.identify(userid);
    // map user properties
    if (props) {
        props["$email"] = props.email;
        // props["$avatar"] = props.picture;  <- did not work
        props["$first_name"] = props.given_name;
        props["$last_name"] = props.family_name;
    }
   
    mixpanel.people.set(props);
}

export function Alias(email) {
    mixpanel.alias(email);
}

export function Reset() {
    mixpanel.reset();
    log("reset called");
}