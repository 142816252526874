import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import Widget from '../../../../components/Widget/WidgetContainer';

import EnhancedTable from '../../../../components/EnhancedTable';

const SelectProduct = ({ classes, theme, onChange, value, ...props }) => {
  //  console.log(props.selectedAdminProducts);
  //  data is displed from here:props.selectorAdminProduct
  //  console.log(props.selectorAdminProduct);  
  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <Widget bodyClass={classes.tableWidget} disableWidgetMenu={true}>
          <div style={{ overflow: 'hidden' }}>
            <EnhancedTable
              changeRowsPerPage={props.changeRowsPerPage}
              updateTable={props.updateTable}
              mainId="product"
              changeRefresh={props.changeRefresh}
              refresh={props.refresh}
              selectAll={false}
              changeShowCreateProduct={props.changeShowCreateProduct}
              showCreateProduct={props.showCreateProduct}
              modifyProductRow={props.modifyProductRow}
              changemodifyProductRow={props.changemodifyProductRow}
              visibleSnackBar={props.visibleSnackBar}
              setVisibleSnackBar={props.setVisibleSnackBar}
              dialogMessage={props.dialogMessage}
              changeDialogMessage={props.changeDialogMessage}
              resultProduct={props.resultProduct}
              setResultProduct={props.setResultProduct}
              responseError={props.responseError}
              deleteClicked={props.deleteClicked}
              setDeleteClicked={props.setDeleteClicked}
              headCells={[
                {
                  id: 'label',
                  numeric: false,
                  disablePadding: true,
                  label: 'Producto',
                },
                {
                  id: 'CategoryTitle',
                  numeric: true,
                  disablePadding: false,
                  label: 'Categoría',
                },
                {
                  id: 'Company',
                  numeric: false,
                  disablePadding: false,
                  label: 'Empresa',
                },
                {
                  id: 'CompanyTagId',
                  numeric: false,
                  disablePadding: false,
                  label: 'Id Empresa',
                },
                {
                  id: 'Brand',
                  numeric: false,
                  disablePadding: false,
                  label: 'Marca',
                },
                {
                  id: 'BrandTagId',
                  numeric: false,
                  disablePadding: false,
                  label: 'Id Marca',
                },                
                {
                  id: 'modifyProduct',
                  numeric: false,
                  disablePadding: false,
                  label: 'Modificar',
                  center: true,
                },
                {
                  id: 'deleteProduct',
                  numeric: false,
                  disablePadding: false,
                  label: 'Eliminar',
                  center: true,
                },
              ]}
              rows={
                props.selectedAdminProducts.length > 0
                  ? props.selectedAdminProducts
                  : props.selectorAdminProduct
              }
              page={props.pagePagination}
              setPage={props.changePagePagination}
            ></EnhancedTable>
          </div>
        </Widget>
      </Grid>
    </React.Fragment>
  );
};

const styles = theme => ({ 
  tableWidget: {
    overflowX: 'visible',
  },
});

export default withStyles(styles, { withTheme: true })(SelectProduct);
