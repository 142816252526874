import React, { Component } from 'react';
import 'date-fns';
import { es } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Slider, Grid } from '@material-ui/core';
import { fakeLayoutLoading } from '../../utils/functions';
import { calculateRemainingDays } from '../../utils/functions';
import trans from './../../i18n/tran'
//registerLocale("es", es);
export class CustomDateSlider extends Component {
  constructor(props) {
    super(props);
    let state = {
      initDateInvalid: false,
      endDateInvalid: false,
      sliderValue: [
        ((props.selectedDates[0] - props.dateRange[0]) /
          86400000 /
          ((props.dateRange[1] - props.dateRange[0]) / 86400000)) *
          100,
        100 -
          ((props.dateRange[1] - props.selectedDates[1]) /
            86400000 /
            ((props.dateRange[1] - props.dateRange[0]) / 86400000)) *
            100,
      ],
      dates: [props.selectedDates[0], props.selectedDates[1]],
      dateRange: props.dateRange,
      dateEqualOrBiggerThanToday: true,
    };
    ////console.log(props.dateRange[0])
    ////console.log(props.dateRange[1])
    //only validate if dates are valid
    if (!props.showErrors) {
      if (props.changeError) {
        if (!state.dates[0].getTime() || !state.dates[1].getTime())
          props.changeError(true);
        else props.changeError(false);
      }
    } else if (props.changeError) {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (
        state.dates[0].getTime() < today.getTime() &&
        state.validateDateEqualOrBiggerThanToday
      ) {
        state.dateEqualOrBiggerThanToday = false;
        props.changeError(true);
      }
      if (state.dates[0].getTime() > state.dates[1].getTime()) {
        props.changeError(true);
      }
      if (calculateRemainingDays(state.dates) < 2) {
        props.changeError(true);
      }
    }

    if (props.monthSelector) {
      state.dates[0] = new Date(
        state.dates[0].getFullYear(),
        state.dates[0].getMonth(),
        1,
      );
      state.dates[1] = new Date(
        state.dates[1].getFullYear(),
        state.dates[1].getMonth() + 1,
        0,
      );
      //so they are not pointers
      state.dateRange = state.dates.map(val => val);
    }
    this.state = state;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.reset) {
      ////console.log("reset:"+this.props.reset)
      this.setState({
        dates: [new Date(), new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)],
      });
      this.props.changeReset(false);
    } else {
      if (prevProps.monthSelector !== this.props.monthSelector) {
        // //console.log(prevProps, this.props);
        if (this.props.monthSelector) {
          let newState = this.state;
          newState.dates[0] = new Date(
            newState.dates[0].getFullYear(),
            newState.dates[0].getMonth(),
            1,
          );
          newState.dates[1] = new Date(
            newState.dates[1].getFullYear(),
            newState.dates[1].getMonth() + 1,
            0,
          );
          //so they are not pointers
          newState.dateRange = newState.dates.map(val => val);
          this.setState(newState);
        }
      }
    }
  }
  render() {
    let inputProps = {
      style: {
        fontSize: this.props.fontSize ? this.props.fontSize : '0.9rem',
        textAlign: 'center',
      },
      readOnly: this.props.readOnly,
    };
    function validateError(currentThis, newState) {
      if (currentThis.props.showErrors) {
        if (currentThis.props.changeError) {
          if (
            newState.dates[0].getTime() > newState.dates[1].getTime() ||
            !newState.dateEqualOrBiggerThanToday ||
            calculateRemainingDays(newState.dates) < 2
          ) {
            currentThis.props.changeError(true);
          } else if (currentThis.props.Error)
            currentThis.props.changeError(false);
        }
      }
    }

    //  there are soooo many wrong things with this code
    function changeDate(
      newInitDate,
      newDateEnd,
      currentThis,
      skipIfNoChange = true,
    ) {
      newInitDate.setHours(0, 0, 0, 0);
      newDateEnd.setHours(0, 0, 0, 0);
      // console.log('change date called');
      let newState = currentThis.state;
      if (newInitDate > newDateEnd && currentThis.props.switchDates) {
        let dateAux = newInitDate;
        newInitDate = newDateEnd;
        newDateEnd = dateAux;
      }
      if (currentThis.props.monthSelector) {
        newInitDate = new Date(
          newInitDate.getFullYear(),
          newInitDate.getMonth(),
          1,
        );
        newDateEnd = new Date(
          newDateEnd.getFullYear(),
          newDateEnd.getMonth() + 1,
          0,
        );
      }
      if (newInitDate < newState.dateRange[0]) {
        newState.dateRange = [newInitDate, newState.dateRange[1]];
        currentThis.props.changeDateRange(newState.dateRange);
      }
      if (newDateEnd > newState.dateRange[1]) {
        newState.dateRange = [newState.dateRange[0], newDateEnd];
        currentThis.props.changeDateRange(newState.dateRange);
      }

      let dateRangeDifference =
        (newState.dateRange[1] - newState.dateRange[0]) / 86400000;

      //no change
      if (
        !skipIfNoChange ||
        newDateEnd.getTime() !== newState.dates[1].getTime() ||
        newInitDate.getTime() !== newState.dates[0].getTime()
      ) {
        if (newInitDate.getTime() !== newState.dates[0].getTime()) {
          let sliderMin =
            ((newInitDate - newState.dateRange[0]) /
              86400000 /
              dateRangeDifference) *
            100;
          newState.dates[0] = newInitDate;
          newState.sliderValue[0] = sliderMin;
        }
        if (newDateEnd.getTime() !== newState.dates[1].getTime()) {
          let sliderMax =
            //100 - ((dateRange[1] - dateEnd) / 86400000 / dateRangeDifference) * 100;

            100 -
            ((newState.dateRange[1] - newDateEnd) /
              86400000 /
              dateRangeDifference) *
              100;
          newState.dates[1] = newDateEnd;
          newState.sliderValue[1] = sliderMax;
        }
        if (currentThis.props.validateDateEqualOrBiggerThanToday) {
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          if (currentThis.state.dates[0].getTime() < today.getTime()) {
            newState.dateEqualOrBiggerThanToday = false;
          } else newState.dateEqualOrBiggerThanToday = true;
        }
        validateError(currentThis, newState);
        if (!skipIfNoChange) {
          //to force the visual change
          newState.dates[0] = new Date(newState.dates[0].getTime() + 1000);
          newState.dates[1] = new Date(newState.dates[1].getTime() + 1000);
          currentThis.setState(newState);
          newState.dates[0] = new Date(newState.dates[0].getTime() - 1000);
          newState.dates[1] = new Date(newState.dates[1].getTime() - 1000);
        }
        currentThis.setState(newState);
        currentThis.props.changeSelectedDates(newState.dates.map(val => val));

        if (currentThis.props.onChange) {
          // console.log("date picker new selectedDates:",{ selectedDates: newState.dates });
          // console.log(currentThis.props.onChange);
          currentThis.props.onChange(
            { selectedDates: newState.dates },
            currentThis.props,
            true,
          );
        }
      } else fakeLayoutLoading(currentThis.props.changeIsLoadingLayout);
    }
    let locale
    if (this.props.lang) {
      if(this.props.lang==="es") {
        locale=es
      }
    }

    return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={6} md={6} style={{ margin: 'auto' }}>
              {this.state.initDateInvalid ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div>{this.props.ErrorIcon}</div>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    {trans('START_DATE_INVALID', this.props.lang)}{' '}
                  </div>
                </div>
              ) : (
                ''
              )}
              {this.state.endDateInvalid && !this.state.initDateInvalid ? (
                <div
                  style={{ width: '80%', color: 'red', visibility: 'hidden' }}
                >
                  <div>{this.props.ErrorIcon}</div>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    Fecha Fin Inválida{' '}
                  </div>
                </div>
              ) : (
                ''
              )}

              {this.props.showErrors &&
              !this.state.endDateInvalid &&
              !this.state.initDateInvalid &&
              !this.state.dateEqualOrBiggerThanToday ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div>{this.props.ErrorIcon}</div>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    Fecha inicio no puede ser menor al día actual
                  </div>
                </div>
              ) : (
                ''
              )}

              {this.props.showErrors &&
              !this.state.endDateInvalid &&
              !this.state.initDateInvalid &&
              this.state.dates[0].getTime() > this.state.dates[1].getTime() ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    {this.props.ErrorIcon}
                  </div>
                  Fecha inicio no puede ser mayor a fecha fin{' '}
                </div>
              ) : this.props.showErrors &&
                calculateRemainingDays(this.state.dates) < 2 ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    {this.props.ErrorIcon}
                  </div>
                  Fecha inicio debe de ser menor a 2 dias de fecha fin{' '}
                </div>
              ) : (
                ''
              )}

              <KeyboardDatePicker
                style={{ width: '100%' }}
                inputProps={
                  this.props.inputPropsStartDate
                    ? this.props.inputPropsStartDate
                    : inputProps
                }
                KeyboardButtonProps={{
                  disabled: this.props.disableStartDateButton,
                  size: 'small',
                }}
                autoOk
                maxDateMessage=""
                minDateMessage=""
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                id={this.props.id + '-startDate'}
                label={trans('START_DATE', this.props.lang)}
                value={this.state.dates[0]}
                onChange={(e, value) => {
                  if (this.props.changeReset) this.props.changeReset(false);
                  if (e) {
                    if (e.getTime) {
                      if (e.getTime()) {
                        changeDate(
                          e,
                          this.state.dates[1],
                          this,
                          !this.state.endDateInvalid,
                        );
                        let newState = this.state;
                        newState.initDateInvalid = false;
                        this.setState(newState);
                        if (this.props.changeError) {
                          this.props.changeError(false);
                          validateError(this, newState);
                        }
                      } else {
                        let newState = this.state;
                        newState.initDateInvalid = true;
                        this.setState(newState);
                        if (this.props.changeError) {
                          this.props.changeError(true);
                        }
                      }
                    }
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} style={{ margin: 'auto' }}>
              {this.state.endDateInvalid ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div>{this.props.ErrorIcon}</div>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    {trans('END_DATE_INVALID', this.props.lang)}{' '}
                  </div>
                </div>
              ) : (
                ''
              )}

              {this.state.initDateInvalid && !this.state.endDateInvalid ? (
                <div
                  style={{ width: '80%', color: 'red', visibility: 'hidden' }}
                >
                  <div>{this.props.ErrorIcon}</div>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    Fecha Inicio Inválida{' '}
                  </div>
                </div>
              ) : (
                ''
              )}

              {this.props.showErrors &&
              !this.state.endDateInvalid &&
              !this.state.initDateInvalid &&
              !this.state.dateEqualOrBiggerThanToday ? (
                <div
                  style={{ width: '80%', color: 'red', visibility: 'hidden' }}
                >
                  <div>{this.props.ErrorIcon}</div>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    Fecha inicio no puede ser menor al día actual
                  </div>
                </div>
              ) : (
                ''
              )}
              {this.props.showErrors &&
              !this.state.endDateInvalid &&
              !this.state.initDateInvalid &&
              this.state.dates[0].getTime() > this.state.dates[1].getTime() ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    {this.props.ErrorIcon}
                  </div>
                  Fecha fin no puede ser menor a fecha inicio{' '}
                </div>
              ) : this.props.showErrors &&
                calculateRemainingDays(this.state.dates) < 2 ? (
                <div style={{ width: '80%', color: 'red' }}>
                  <div
                    style={{
                      fontSize: this.props.ErrorfontSize
                        ? this.props.ErrorfontSize
                        : '0.8rem',
                    }}
                  >
                    {this.props.ErrorIcon}
                  </div>
                  Fecha fin debe de ser mayor a 2 dias de fecha inicio{' '}
                </div>
              ) : (
                ''
              )}
              <KeyboardDatePicker
                style={{ width: '100%' }}
                inputProps={
                  this.props.inputPropsEndDate
                    ? this.props.inputPropsEndDate
                    : inputProps
                }
                KeyboardButtonProps={{
                  size: 'small',
                  disable: this.props.disableEndDateButton,
                }}
                autoOk
                maxDateMessage=""
                minDateMessage=""
                disableToolbar
                variant="inline"
                id={this.props.id + '-endDate'}
                label={trans('END_DATE', this.props.lang)}
                format="dd/MM/yyyy"
                value={this.state.dates[1]}
                onChange={(e, value) => {
                  if (e) {
                    if (e.getTime) {
                      if (e.getTime()) {
                        changeDate(
                          this.state.dates[0],
                          e,
                          this,
                          !this.state.endDateInvalid,
                        );
                        let newState = this.state;
                        newState.endDateInvalid = false;
                        this.setState(newState);
                        if (this.props.changeError) {
                          this.props.changeError(false);
                          validateError(this, newState);
                        }
                      } else {
                        let newState = this.state;
                        newState.endDateInvalid = true;
                        this.setState(newState);
                        if (this.props.changeError) {
                          this.props.changeError(true);
                        }
                      }
                    }
                  }
                }}
              />
            </Grid>

            <div style={{ width: '5%', float: 'right' }}></div>
          </Grid>
        </MuiPickersUtilsProvider>

        {this.props.hideSlider ? (
          <div></div>
        ) : (
          <div style={{ width: '90%', paddingTop: '10px', margin: ' 0 auto' }}>
            <Slider
              id={'DateSlider'}
              value={this.state.sliderValue}
              onChange={(e, value) => {
                let newState = this.state;
                newState.sliderValue = value;

                let dateRangeDifference =
                  this.state.dateRange[1] - this.state.dateRange[0];
                newState.dates[0] = new Date(
                  (newState.sliderValue[0] / 100) * dateRangeDifference +
                    this.state.dateRange[0].getTime(),
                );
                newState.dates[1] = new Date(
                  (newState.sliderValue[1] / 100) * dateRangeDifference +
                    this.state.dateRange[0].getTime(),
                );
                newState.dates[0].setHours(0, 0, 0, 0);
                newState.dates[1].setHours(0, 0, 0, 0);
                if (this.props.validateDateEqualOrBiggerThanToday) {
                  let today = new Date();
                  today.setHours(0, 0, 0, 0);
                  if (this.state.dates[0].getTime() < today.getTime()) {
                    newState.dateEqualOrBiggerThanToday = false;
                  } else newState.dateEqualOrBiggerThanToday = true;
                }

                this.setState(newState);
              }}
              onChangeCommitted={(e, value) => {
                let newState = this.state;
                newState.endDateInvalid = false;
                newState.initDateInvalid = false;
                this.setState(newState);
                if (this.props.changeError) this.props.changeError(false);
                if (
                  this.state.dates[0].getTime() !==
                    this.props.selectedDates[0].getTime() ||
                  this.state.dates[1].getTime() !==
                    this.props.selectedDates[1].getTime()
                ) {
                  if (!this.props.monthSelector) {
                    changeDate(
                      this.state.dates[0],
                      this.state.dates[1],
                      this,
                      false,
                    );
                  } else {
                    this.props.changeSelectedDates(
                      this.state.dates.map(val => val),
                    );

                    if (this.props.onChange) {
                      validateError(this, this.state);
                      // //console.log("log");
                      this.props.onChange(
                        { selectedDates: this.state.dates },
                        this.props,
                        true,
                      );
                    }
                  }
                } else fakeLayoutLoading(this.props.changeIsLoading);
              }}
              aria-labelledby="range-slider"
            />
          </div>
        )}
      </div>
    );
  }
}
