import { compose, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';
import {
  tenantValidation,
  getLocalStorageTenant,
} from '../../utils/functions';
import CustomPage from './CustomPageView';
import { newAccountPageId, adaccountsPageId } from '../Constants';

async function validateCustomPageChange(props) {
  if (
    props.lastCustomPageId !== props.customPageId &&
    sessionStorage.getItem('customPageValidation') === null
  ) {
    sessionStorage.setItem('customPageValidation', 'true');

    props.changeCustomPageValidationEnded(false);
    let tenant = getLocalStorageTenant();
    let refresh = true;
    if (tenant === undefined) {
      refresh = false;
    }
    if (
      props.customPageId !== newAccountPageId &&
      props.customPageId !== adaccountsPageId
    ) {
      let onBoarding = await tenantValidation(
        props,
        tenant,
        props.customPageId,
        refresh,
      );
      if (onBoarding.continue) props.changeLastCustomPageId(onBoarding.pageId);
      else {
        props.changeLastCustomPageId(props.customPageId);
      }
    } else props.changeLastCustomPageId(props.customPageId);
    sessionStorage.removeItem('customPageValidation');
    //used for tracking of custom page validation and to force a refresh after the validation
    props.changeCustomPageValidationEnded(true);
  }
}
export default compose(
  connect(state => ({
    SmartComponentReducer: state.SmartComponentReducer,
  })),

  withState('lastCustomPageId', 'changeLastCustomPageId', -1),

  lifecycle({
    componentDidMount() {
    },

    componentDidUpdate() {
      validateCustomPageChange(this.props);
    },
  }),
)(CustomPage);
