import React from 'react';
import { withStyles } from '@material-ui/core';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import ErrorIcon from '@material-ui/icons/Error';
import TextFieldList from '../../../components/TextFieldList/TextFieldListView';
import { getCurrency } from '../../../utils/functions';
import trans from './../../../i18n/tran';

const Budget = ({ classes, theme, onChange, value, ...props }) => {
  let currency = getCurrency(props);
  if (currency) currency =  trans('CURRENCY', props.lang) + ': ' + currency;
  return (
    <TextFieldList
      recalculateMessages={props.recalculateMessages}
      showAdErrors={props.showAdErrors}
      ErrorIconClass={classes.errorIcon}
      ErrorMessageClass={classes.warningMessage}
      reset={props.reset}
      changeReset={props.changeReset}
      focusable={
        <Focusable
          id={props.componentInfo.focusId}
          refList={props.refList}
        ></Focusable>
      }
      id="Presupuesto"
      widgetTitle={trans('NEW_CAMPAIGN_BUDGET', props.lang)}
      widgetTitle2={
        <div style={{ float: 'right' }}>
          <h3>{currency}</h3>
        </div>
      }
      maxTextSize={500}
      maxListSize={1}
      ErrorIcon={<ErrorIcon />}
      invalidDecimalMessage={trans('NEW_CAMPAIGN_MESSAGE4', props.lang)}
      minDecimalMessage={trans('NEW_CAMPAIGN_MESSAGE5', props.lang)}
      minDailyMessage={trans('NEW_CAMPAIGN_MESSAGE6', props.lang)}
      minDecimalvalue={300}
      maxDecimalMessage={trans('NEW_CAMPAIGN_MESSAGE7', props.lang)}
      maxDecimalvalue={1000000}
      placeholder={trans('NEW_CAMPAIGN_BUDGET_ENTER', props.lang)}
      multiline={false}
      changeError={props.componentInfo.getchangeError(props)}
      Error={props.componentInfo.getError(props)}
      list={props.adBudget}
      initListValue={props.adBudget}
      hasInitValue={true}
      changeList={props.changeAdBudget}
      textFieldVariant="budget"
      fieldType="number"
      variant="outlined"
      getMessage={props.componentInfo.getMessage(props)}
      selectedDates={props.selectedCampaignDates}
    ></TextFieldList>
  );
};
export default withStyles(styles, { withTheme: true })(Budget);
