import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import Select from 'react-select';
import { styles } from '../../../utils/styles';
import trans from './../../../i18n/tran';

const LeadGenerationForms = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <div style={{ width: '100%' }}>
      <Typography>{trans('NEW_CAMPAIGN_INST_FORM', props.lang)}</Typography>
      <Select
        options={props.selectorLeadGenerationForm}
        styles={theme.reactSelect}
        value={props.selectedLeadGenerationForms}
        onChange={e => {
          props.changeHasErrorFanPage(false);
          props.changeSelectedLeadGenerationForms([e]);
        }}
        isDisabled={props.selectedAdminFanPages.length === 0}
        placeholder={
          props.selectedAdminFanPages.length === 0
            ? trans('NEW_CAMPAIGN_FORM_SELECT', props.lang)
            : trans('NEW_CAMPAIGN_FORM_SELECT1', props.lang)
        }
      ></Select>
    </div>
  );
};
export default withStyles(styles, { withTheme: true })(LeadGenerationForms);
