import { compose, withState, withHandlers, lifecycle } from 'recompose';
import DashboardAdsView from './View';
import { doFetch, createHeaders } from './../../utils/fetch';
import { ageGenderUrl, ageGenderKey, Environment } from './../../components/Constants';
import { Track, NewEvent, NewTrackData } from '../../utils/mixpanel';

//  dont do this ;)
let firstTime = false;
let controller;
let signal;

function toObject(target, obj, name, isDate = false, isPercentage = false) {
  let counter = 0;
  Object.entries(obj).forEach(([key, value]) => {
    let obj = {};
    if (isDate) {
      value = new Date(value).toLocaleDateString();
    } else if (isPercentage) {
      value = (value * 10).toFixed(2);
    }
    if (target[counter]) {
      obj = target[counter]; // copy
      obj[name] = value;
      target[counter] = obj;
    } else {
      obj[name] = value;
      obj['id'] = key;
      target[counter] = obj;
    }
    counter++;
  });
}

// converts an array of javascript dates objects to a dictionary prior to send to mixpanel
let convSelectedDates = sel => {
  try {
    return {
      start_date: sel[0].toLocaleDateString(),
      end_date: sel[1].toLocaleDateString(),
    };
  } catch (e) {
    console.warn(e);
  }
  return undefined;
};

let convSelectedCampaings = sel => {
  try {
    return {
      campaign_name: sel[0].label,
      campaing_ext_id: sel[0].ExtId,
    };
  } catch (e) {
    console.warn(e);
  }
  return undefined;
};

let convSelectedAdAccounts = sel => {
  try {
    return {
      ad_accounts: sel,
    };
  } catch (e) {
    console.warn(e);
  }
  return undefined;
};

let mycompose = compose(
  withState('details', 'setDetails', []),
  withState('summary', 'setSummary', []),
  withState('showKPIExtra', 'changeShowKPIExtra', false),
  withState('error', 'setError', undefined),
  withState('ageGender', 'setAgeGender', []),
  withHandlers({
    callLambdaAgeGender: props => async e => {
      if (props && props.selectedFacebookCampaigns) {
        const campaigns = props.selectedFacebookCampaigns;

        if (campaigns.length > 0) {
          const payload = {            
            CampaignExtId: campaigns[0].ExtId
          };

          controller = new AbortController();
          signal = controller.signal;
          props.changeIsLoadingLayout(true);
          
          let APIheaders = new Headers();
          APIheaders.append("Content-Type", "application/json");
          APIheaders.append("x-api-key", ageGenderKey);          

          await doFetch(
            ageGenderUrl,
            {
              method: 'post',
              headers: APIheaders,
              signal,
              body: JSON.stringify(payload),
            },
            res => {
              props.changeIsLoadingLayout(false);              
              try {
                const pl = res;                
                if ('statusCode' in pl && 'body' in pl) {
                  const details = JSON.parse(pl.body);

                  // some conversions
                  const invFemale = parseFloat(details.InvFemale);
                  const invMale = parseFloat(details.InvMale);
                  // props.age
                  details.InvFemale = Math.round(invFemale * 100);
                  details.InvMale = Math.round(invMale * 100);
                  details.id = 1;
                  props.setAgeGender([details]);
                }
              } catch (e) {
                console.log(e);
                console.log(res);
                props.setError(e);
                // Track(NewEvent("ads", "aly_log_botton", "fail"));
              }
            },
          );
        }
      }
    },
    callLambdaCorrelation: props => async e => {
      if (props && props.selectedDates && props.selectedFacebookCampaigns) {
        const dates = props.selectedDates;
        const campaigns = props.selectedFacebookCampaigns;
        if (dates.length > 1 && campaigns.length > 0) {
          let payload = {
            StartDate: '2021-12-01',
            EndDate: '2021-12-17',
            CampaignId: 13972,
          };
          if (Environment === 'PROD') {
            payload = {
              StartDate: dates[0],
              EndDate: dates[1],
              CampaignId: campaigns[0].value,
            };
          }

          controller = new AbortController();
          signal = controller.signal;
          props.changeIsLoadingLayout(true);
          // TODO: remove hard code API call and use constants instead
          await doFetch(
            'https://apis.aly-ai.com/stat/api/v3.0/algo/1',
            {
              method: 'post',
              headers: createHeaders(),
              signal,
              body: JSON.stringify(payload),
            },
            res => {
              props.changeIsLoadingLayout(false);
              if ('success' in res && 'payload' in res && res.success) {
                if (res.payload) {
                  try {
                    const pl = JSON.parse(res.payload);
                    if ('statusCode' in pl && 'body' in pl) {
                      const details = JSON.parse(pl.body.details);
                      const summary = JSON.parse(pl.body.summary);
                      let det = [],
                        sum = [];

                      toObject(det, details.Acciones, 'actions');
                      toObject(det, details.Age, 'age');
                      toObject(det, details.CPA, 'cpa');
                      toObject(det, details.Cost, 'cost');
                      toObject(det, details.DeltaAcciones, 'delta_actions');
                      toObject(det, details.DeltaCPA, 'delta_cpa');
                      toObject(det, details.DeltaCosto, 'delta_cost');
                      toObject(det, details.Gender, 'gender');
                      toObject(
                        det,
                        details.PorcentajeAcciones,
                        'percent_actions',
                        false,
                        true,
                      );
                      toObject(
                        det,
                        details.PorcentajeCPA,
                        'percent_cpa',
                        false,
                        true,
                      );
                      toObject(
                        det,
                        details.PorcentajeCosto,
                        'percent_cost',
                        false,
                        true,
                      );
                      toObject(det, details.RunDate, 'run_date', true);

                      toObject(
                        sum,
                        summary['ModificacionesAutomaticas'],
                        'col1',
                      );
                      toObject(sum, summary['AccionesAdicionales'], 'col2');
                      toObject(sum, summary['TiempoAhorrado'], 'col3');
                      toObject(
                        sum,
                        summary['MejoraEnCosto'],
                        'col4',
                        false,
                        true,
                      );
                      props.setDetails(det);
                      props.setSummary(sum);

                      Track(NewEvent('ads', 'aly_log_botton', 'sucess'));
                    }
                  } catch (e) {
                    console.log(e);
                    console.log(res);
                    props.setError(e);
                    Track(NewEvent('ads', 'aly_log_botton', 'fail'));
                  }
                } else {
                  // print something like, something went wrong
                  props.setError('error');
                }
              }
            },
          );
        }
      } else {
        console.log('WRN cannot find parameters for lambda');
      }
    },
    closeDialog: props => e => {
      props.setShowTablePopup(false);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps, prevState) {
      if (
        firstTime &&
        this.props.selectedFacebookCampaigns &&
        this.props.selectedFacebookCampaigns.length > 0
      ) {
        firstTime = false;
        this.props.changeSelectedFacebookCampaigns([]);
      }
      if (
        JSON.stringify(this.props.selectedFacebookCampaigns) !==
        JSON.stringify(prevProps.selectedFacebookCampaigns)
      ) {
        this.props.setDetails([]);
        this.props.setSummary([]);
        this.props.setError(undefined);
      }
      if (
        JSON.stringify(this.props.dashboard12) !==
        JSON.stringify(prevProps.dashboard12)
      ) {
        if (
          this.props.dashboard12 &&
          this.props.dashboard12.length &&
          this.props.dashboard12.length > 0
        ) {
          try {
            let dat = NewTrackData(this.props, {
              selected_dates: convSelectedDates(this.props.selectedDates),
              selected_campaign: convSelectedCampaings(
                this.props.selectedFacebookCampaigns,
              ),
              selected_ad_accounts: convSelectedAdAccounts(
                this.props.selectedAdAccounts,
              ),
            });

            Track(NewEvent('ads', 'image_results', 'success'), dat);
          } catch (e) {
            console.warn('error', e);
          }
        }
      }
    },
    componentDidMount() {
      this.props.changeTenantChangeFunction({
        function: newTenenatValue => {},
      });

      firstTime = true;

      if (window && window.location && window.location) {
        const urlParams = new URLSearchParams(window.location.search);
        let flag = urlParams.get('showKPIExtra');
        if (flag) {
          this.props.changeShowKPIExtra(true);
        }
      }
    },

    componentWillUnmount() {},
  }),
);

export default mycompose(DashboardAdsView);
