import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core';
import trans from './../../i18n/tran';

const Popup = ({ classes, theme, ...props }) => {
  if (props.isOpen)
    return (
      <div style={{ width: '100%' }}>
        <Dialog
          style={{ width: '100%', zIndex: '1302' }}
          open={props.isOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={
              props.error
                ? {
                    backgroundColor: theme.palette.text.danger,
                    width: '100%',
                    color: '#FFFFFF',
                  }
                : props.confirmPopUp || props.warning
                ? {
                    backgroundColor: theme.palette.text.warning,
                    width: '100%',
                    color: '#FFFFFF',
                  }
                : {
                    backgroundColor: '#013f8a',
                    width: '100%',
                    color: '#FFFFFF',
                  }
            }
            id="alert-dialog-title"
          >
            {'ALY'}
          </DialogTitle>
          <DialogContent>
            <Grid
              id="alert-dialog-description"
              style={{
                fontSize: '1rem',
                fontFamily: 'Kollektif, Arial',
                fontWeight: '400',
                lineHeight: '1.5',
              }}
            >
              {props.message}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                props.handleClose(props);
              }}
              style={{
                backgroundColor: theme.palette.text.primary,
                color: 'white',
              }}
            >
              {props.popUpAcceptLabel}
            </Button>
            {props.confirmPopUp ? (
              <Button
                variant="contained"
                onClick={() => {
                  props.cancelFunction(props);
                }}
                style={{ backgroundColor: '#E8E8E8', color: '#808080' }}
              >
                { trans('CANCELL', props.lang) }
              </Button>
            ) : (
              ''
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  else
    return (
      <Dialog
        style={{ width: '100%' }}
        open={props.isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle></DialogTitle>
      </Dialog>
    );
};

export default withStyles({}, { withTheme: true })(Popup);
