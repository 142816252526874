import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import Dot from '../../../components/Sidebar/components/Dot';
import trans from './../../../i18n/tran';

import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from 'recharts';
import Widget from '../../../components/Widget/WidgetContainer';
const Dashboard31 = ({ classes, theme, ...props }) => {
  function formatLabel(e1, e2, e3) {
    return e1.toFixed(0);
  }
  function formatXAxis(tickItem) {
    return new Date(tickItem).toLocaleDateString(props.lang, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
  function formatToolTipDate(tickItem) {
    return (
      new Date(tickItem).toLocaleDateString(props.lang, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    );
  }
  if (props.isLoading && !props.isPrintingPage) {
    return (
      <Widget>
        <Typography>{trans('LOADING', props.lang)}...</Typography>
      </Widget>
    );
  } else if (props.data.length > 0) {
    //console.log(props.data);
    return (
      <div id="div32">
        <Widget
          noBodyClass
          header={
            <div className={classes.mainChartHeader}>
              <Typography variant="h2" color="primary">
                Clicks
              </Typography>
              <div className={classes.mainChartHeaderLabels}>
                <div className={classes.mainChartHeaderLabel}>
                  <Dot color={theme.palette.primary.main} />
                  <Typography className={classes.mainChartLegentElement}>
                    Clicks
                  </Typography>
                </div>
                <div className={classes.mainChartHeaderLabel}>
                  <Dot color={theme.palette.text.black} />
                  <Typography className={classes.mainChartLegentElement}>
                    {trans('TREND', props.lang)}
                  </Typography>
                </div>
                <div className={classes.mainChartHeaderLabel}>
                  <Dot color={theme.palette.secondary.contrastText} />
                  <Typography className={classes.mainChartLegentElement}>
                    {trans('ACTIONS', props.lang)}
                  </Typography>
                </div>
              </div>
            </div>
          }
        >
          <React.Fragment>
            <ResponsiveContainer
              id="ResponsiveContainer32"
              width="100%"
              height="25%"
              minWidth={props.width}
              minHeight={props.height}
              debounce={3}
            >
              {props.resizeRefresh ? (
                <div></div>
              ) : (
                <ComposedChart
                  width={props.width}
                  height={props.height}
                  data={props.data}
                  margin={{
                    top: 20,
                    right: 80,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis
                    dataKey="date"
                    tickFormatter={formatXAxis}
                    interval={
                      props.data.length >
                      Math.ceil(document.documentElement.clientWidth / 350)
                        ? Math.ceil(
                            props.data.length /
                              (document.documentElement.clientWidth / 350),
                          )
                        : 0
                    }
                  />
                  <YAxis
                    yAxisId="actions"
                    orientation="right"
                    stroke={theme.palette.secondary.contrastText}
                  />
                  <YAxis
                    tickFormatter={e => e.toFixed(2)}
                    stroke={theme.palette.primary.main}
                  />
                  <Tooltip
                    labelFormatter={formatToolTipDate}
                    formatter={formatLabel}
                  />
                  <Line
                    strokeDasharray="4 4"
                    dot={false}
                    type="linear"
                    dataKey="trend"
                    stroke={theme.palette.text.black}
                    name={trans('TREND', props.lang)}
                    strokeWidth={2}
                  />
                  <Area
                    type="monotone"
                    dataKey="Clicks"
                    fill={theme.palette.success.main}
                    stroke={theme.palette.success.main}
                    name="Clicks"
                    fillOpacity={0.6}
                  />
                  <Area
                    yAxisId="actions"
                    type="monotone"
                    dataKey="Acciones"
                    fill={theme.palette.secondary.contrastText}
                    stroke={theme.palette.secondary.contrastText}
                    name={trans('ACTIONS', props.lang)}
                    fillOpacity={0.3}
                  />
                </ComposedChart>
              )}
            </ResponsiveContainer>
          </React.Fragment>
        </Widget>
      </div>
    );
  } else
    return (
      <Widget>
        <Typography>{trans('NO_INFO', props.lang)}</Typography>
      </Widget>
    );
};

const styles = theme => ({
  tableWidget: {
    overflowX: 'auto',
    fontSize: '0.9rem',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  mainChartHeaderLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
  },
  mainChartHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mainChartHeaderLabels: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      order: 3,
      width: '100%',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartLegentElement: {
    fontSize: '18px !important',
    marginLeft: theme.spacing(1),
  },
});

export default withStyles(styles, { withTheme: true })(Dashboard31);
