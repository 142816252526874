import React from 'react';
import { Typography } from '../Wrappers';
import esFlag from '../../images/es.svg';
import enFlag from '../../images/en.svg';

import trans from '../../i18n/tran';

export function Spain(props) {
  return ( 
      <Typography>
        <img
          src={esFlag}
          alt="spain flag"
          height="10"
        /> 
        <span> </span>
        {trans('NAV_LANG_ES', "es")}
        { (props.isCurrent) &&
          <span> * </span>
        }
      </Typography>
  )
}

export function Usa(props) {
  return ( 
      <Typography>
        <img
          src={enFlag}
          alt="usa flag"
          height="10"
        /> 
        <span> </span>
        {trans('NAV_LANG_EN', "en")}
        { (props.isCurrent) &&
          <span> * </span>
        }
      </Typography>
  )
}