import React, { useState, useEffect } from 'react'
import './Reports.css';
import Campania from './components/campania';

import '../../assets/fonts/Inter/Inter-Regular.ttf'
import '../../assets/fonts/Inter/Inter-Light.ttf'
import '../../assets/fonts/Inter/Inter-SemiBold.ttf'

import trans from './../../i18n/tran';
import { doFetch, createHeaders } from './../../utils/fetch';
import { sellsCapture, getSales } from '../../components/Constants'

export default function SalesReports(props) {

  const [mainprops, setMainProps] = useState(props);


  const meses = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
  const [mes, setMes] = useState(0);
  const siguienteMes = () => {
    setMes(mes === 11 ? 0 : mes + 1)
  }
  const anteriorMes = () => {
    setMes(mes === 0 ? 11 : mes - 1)
  }
  useEffect(() => {
    getReports();
  }, [mes]);
  const [anio, setAnio] = useState(new Date().getFullYear());
  const siguienteAnio = () => {
    setAnio(anio + 1)
  }
  const anteriorAnio = () => {
    setAnio(anio - 1)
  }
  useEffect(() => {
    getReports();
  }, [anio]);

  // guardar tickets locales
  const [guardarTrigger, setguardarTrigger] = useState(0);
  const saveLocalTickets = () => {
    setguardarTrigger(guardarTrigger + 1);
    setSubArray(cropSubArray());
  }



  // paginador
  const [pagina, setPagina] = useState(1);
  const siguientePagina = () => {
    pagina == maxPaginas ? <></> : setPagina(pagina + 1);
  }
  const anteriorPagina = () => {
    pagina == 1 ? <></> : setPagina(pagina - 1);
  }
  const campaignsPerPage = 10;
  const [maxPaginas, setMaxPagina] = useState(1);
  const [subArray, setSubArray] = useState(<></>);
  const cropSubArray = () => {

    // Getting single unique campaigns
    const uniqueObjects = campanias.filter(isUnique);
    setMaxPagina(Math.ceil(uniqueObjects.length / campaignsPerPage) || 1)

    // console.log('FILTRADOS ->', uniqueObjects);

    // Getting ROAS
    uniqueObjects.forEach((initialObject, i) => {

      const filteredObjects = campanias.filter(obj =>
        obj.AdAccountID === initialObject.AdAccountID &&
        obj.CampaignId === initialObject.CampaignId &&
        obj.ProductId === initialObject.ProductId &&
        obj.TicketCount === initialObject.TicketCount
      );

      // console.log('fileredObjects', filteredObjects)

      if (filteredObjects.length > 0) {
        const sum = filteredObjects.reduce((acc, obj) => acc + obj.ROAS, 0);
        const average = sum / filteredObjects.length;
        const roundedNumber = parseFloat(average.toFixed(4));
        uniqueObjects[i].ROAS = roundedNumber;
      }

    });


    return uniqueObjects.slice((pagina * campaignsPerPage) - campaignsPerPage, (pagina * campaignsPerPage) > uniqueObjects.length ? uniqueObjects.length : (pagina * campaignsPerPage)).map(
      (campania, i) =>
        < Campania key={i} guardar={guardarTrigger} campania={campania} reloadCampaigns={reloadCampaigns}></Campania>
    )
  }

  function isUnique(obj, index, self) {
    return (
      self.findIndex(
        (item) => (item.AdAccountID === obj.AdAccountID) && (item.CampaignId === obj.CampaignId) && (item.ProductId === obj.ProductId) && (item.TicketCount === obj.TicketCount)
      ) === index
    );
  }

  useEffect(() => {
    setSubArray(cropSubArray());
  }, [pagina])


  // On init
  const [campanias, setListaCampanias] = useState([]);
  let controller = new AbortController();
  let signal = controller.signal;
  useEffect(() => {
    getReports(props);
  }, [])

  // Campanias handler
  useEffect(() => {
    setSubArray(cropSubArray());
  }, [campanias])
  const reloadCampaigns = () => {
    // console.log('reloading Campaign.')
    getReports(props);
  }
  const getReports = async (props) => {

    controller = new AbortController();
    signal = controller.signal;

    let url = `${getSales}/`;

    await doFetch(
      url,
      {
        method: 'post',
        headers: createHeaders(),
        body: JSON.stringify({
          AccountId: mainprops.selectedTenants[0].value,
          Year: anio,
          Month: mes + 1
        }),
        signal,
      },
      res => {
        if ('success' in res && 'payload' in res && res.success) {

          let raw = res.payload.sales
          setListaCampanias(raw)
          // console.log('getReports: ', raw)

        }
      },
    );
  }


  return (
    <div className="ReportsApp">
      <div className="col-principal">
        <div className="col-titulo">
          <p>
            {trans('RP_SALES_REPORT', props.lang)}
          </p>
        </div>

        <div className="col-filtros">
          <p className="filter-title">
            {trans('RP_FILTER', props.lang)}
          </p>

          <div className='filtros'>
            <button onClick={anteriorAnio} >&lt;</button>
            <p>
              {anio}
            </p>
            <button onClick={siguienteAnio} >&gt;</button>
          </div>

          <div className='filtros'>
            <button onClick={anteriorMes} >&lt;</button>
            <p>
              {trans(meses[mes], props.lang)}
            </p>
            <button onClick={siguienteMes} >&gt;</button>
          </div>
        </div>

        <div className="col-tabla">
          <table>
            <thead>
              <tr>
                <th className='first'>
                  {trans('RP_CAMPAIGN', props.lang)}
                </th>
                <th >
                  {trans('RP_OBJECTIVE', props.lang)}
                </th>
                <th >
                  {trans('RP_BUDGET', props.lang)}
                </th>
                <th >
                  {trans('RP_DAYS_ACTIVE', props.lang)}
                </th>
                <th >
                  {/* TODO */}
                  ROAS
                </th>
                <th className='last'>
                  {trans('RP_CAMPAIGN_TOTAL', props.lang)}
                </th>
              </tr>
            </thead>
            <tbody>
              {
                subArray
              }
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={'100%'} className='pagination'>
                  <button onClick={anteriorPagina} >&lt;</button>
                  <button className="text">
                    {pagina} - {maxPaginas}
                  </button>
                  <button onClick={siguientePagina} >&gt;</button>
                </th>
              </tr>
            </tfoot>

          </table>
        </div>

        <div className='col-botones'>
          {/* <button>
            {trans('RP_EDIT', props.lang)}
          </button> */}
          <button onClick={saveLocalTickets} >
            {trans('RP_SAVE', props.lang)}
          </button>
        </div>
      </div>
    </div>
  );

}
