import React from 'react';
import DowloadStats from '../../pages/downloadStats/DownloadStatsContainer';
import { withStyles, CssBaseline } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';

// pages
import AdAccountsv2 from '../../pages/adaccountsV2/Container';
import Ads from '../../pages/ads/Container';

import CreateAds from '../../pages/createad/Container';
import CreateProduct from '../../pages/product';
import CustomPage from '../CustomPage';
import { isLoadingModal, sideBarLoadingModal } from '../../utils/global';
import HomePage from '../../pages/home';
import ExportPage from '../../pages/exportReports';
import Assign from '../../pages/assign';
import ModifyProduct from '../../pages/modifyProduct';
import CreateNewAccount from '../../pages/init/createNewAccountContainer';
import ClientApproval from '../../pages/ClientApproval';
import Approval from '../../pages/ClientApproval';
import ErrorPage from '../../pages/error/403Error';
import Users from '../../pages/UserAdmin';
import Billing from '../../pages/Billing';
import Subscriptions from '../../pages/Subscriptions';
import Drafts from '../../pages/Drafts';
import Reports from '../../pages/Report';

import SalesReports from 'src/pages/reports/Reports';
import ReportsAuto from 'src/pages/reportsAuto/ReportsAuto';

import {
  homePagePageId,
  release1AdsPageId,
  adaccountsPageId,
  exportPagePageId,
  clientApprovalPageId,
  downloadstatsPageId,
  createadPageId,
  productPageId,
  modifyProductPageId,
  assignPageId,
  initPageId,
  approvalPageId,
  billingPageId,
  newAccountPageId,
  release1AdsPagePath,
  adaccountsPagePath,
  createadPagePath,
  productPagePath,
  assignPagePath,
  modifyProductPagePath,
  exportPagePagePath,
  approvalPagePagePath,
  clientApprovalPagePath,
  homePagePagePath,
  downloadstatsPagePath,
  newAccountPagePath,
  userPagePath,
  userPageId,
  billingPagePath,
  subscriptionsPageId,
  subscriptionsPagePath,
  draftsPageId,
  draftsPagePath,
  reportsPageId,
  reportsPagePath,

  salesReportPath,
  salesReportPageId,
  automaticReportPageId,
  automaticReportPath

} from '../Constants';
import PopUp from '../PopUp/PopUp';
import { addComponent } from '../../utils/functions';

const Layout = ({ classes, isSidebarOpened, toggleSidebar, ...props }) => {
  if (sessionStorage.getItem('userPages') === null) {
    return <div />;
  }

  return (
    <div>
      {props.isLoadingSideBar ? sideBarLoadingModal : <div />}
      {props.isLoadingLayout ||
        sessionStorage.getItem('customPageValidation') !== null ? (
        isLoadingModal
      ) : (
        <div />
      )}
      <div className={classes.root} id="LayoutDiv">
        <CssBaseline />

        <Switch>
          {addComponent(
            props,
            draftsPageId,
            <Route
              exact
              path={draftsPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={draftsPageId}>
                    <Drafts {...props} customPageId={draftsPageId} />
                  </CustomPage>
                );
              }}
            />,
          )}
          ,
          {addComponent(
            props,
            reportsPageId,
            <Route
              exact
              path={reportsPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={reportsPageId}>
                    <Reports {...props} customPageId={reportsPageId} />
                  </CustomPage>
                );
              }}
            />,
          )}
          {addComponent(
            props,
            release1AdsPageId,
            <Route
              exact
              path={release1AdsPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={release1AdsPageId}>
                    <Ads {...props} customPageId={release1AdsPageId} />
                  </CustomPage>
                );
              }}
            />,
          )}
          {addComponent(
            props,
            billingPageId,
            <Route
              exact
              path={billingPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={billingPageId}>
                    <Billing {...props} customPageId={billingPageId} />
                  </CustomPage>
                );
              }}
            />,
          )}
          {addComponent(
            props,
            subscriptionsPageId,
            <Route
              exact
              path={subscriptionsPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={subscriptionsPageId}>
                    <Subscriptions
                      {...props}
                      customPageId={subscriptionsPageId}
                    />
                  </CustomPage>
                );
              }}
            />,
          )}
          {addComponent(
            props,
            adaccountsPageId,
            <Route
              exact
              path={adaccountsPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={adaccountsPageId}>
                    <AdAccountsv2
                      changeIsLoadingLayout={props.changeIsLoadingLayout}
                      isLoadingLayout={props.isLoadingLayout}
                      customPageId={adaccountsPageId}
                      {...props}
                    />
                  </CustomPage>
                );
              }}
            />,
          )}
          {addComponent(
            props,
            createadPageId,
            <Route
              exact
              path={createadPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={createadPageId}>
                    <CreateAds
                      isLoadingLayout={props.isLoadingLayout}
                      changeIsLoadingLayout={props.changeIsLoadingLayout}
                      customPageId={createadPageId}
                      {...props}
                    />
                  </CustomPage>
                );
              }}
            />,
          )}
          {addComponent(
            props,
            productPageId,
            <Route
              exact
              path={productPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={productPageId}>
                    <CreateProduct
                      isLoadingLayout={props.isLoadingLayout}
                      changeIsLoadingLayout={props.changeIsLoadingLayout}
                      modifyProduct={false}
                      customPageId={productPageId}
                      {...props}
                    />
                  </CustomPage>
                );
              }}
            />,
          )}
          {addComponent(
            props,
            assignPageId,
            <Route
              exact
              path={assignPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={assignPageId}>
                    <Assign
                      {...props}
                      isLoadingLayout={props.isLoadingLayout}
                      customPageId={assignPageId}
                      changeIsLoadingLayout={props.changeIsLoadingLayout}
                    />
                  </CustomPage>
                );
              }}
            />,
          )}
          {addComponent(
            props,
            modifyProductPageId,
            <Route
              exact
              path={modifyProductPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={modifyProductPageId}>
                    <ModifyProduct
                      {...props}
                      customPageId={modifyProductPageId}
                      isLoadingLayout={props.isLoadingLayout}
                      changeIsLoadingLayout={props.changeIsLoadingLayout}
                    />
                  </CustomPage>
                );
              }}
            />,
          )}
          {addComponent(
            props,
            exportPagePageId,
            <Route
              exact
              path={exportPagePagePath}
              render={() => {
                return (
                  <CustomPage
                    singleProduct={true}
                    {...props}
                    showSideBar={false}
                    customPageId={exportPagePageId}
                    layoutLoading={true}>
                    <ExportPage
                      customPageId={exportPagePageId}
                      layoutLoading={true}
                      singleProduct={true}
                      {...props}
                    />
                  </CustomPage>
                );
              }}
            />,
          )}
          <Route
            exact
            path={approvalPagePagePath}
            render={() => {
              return (
                <CustomPage
                  {...props}
                  showSideBar={false}
                  customPageId={approvalPageId}
                  layoutLoading={true}>
                  <Approval
                    customPageId={approvalPageId}
                    isApproval={true}
                    layoutLoading={true}
                    {...props}
                  />
                </CustomPage>
              );
            }}
          />
          {addComponent(
            props,
            clientApprovalPageId,
            <Route
              exact
              path={clientApprovalPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={clientApprovalPageId}
                    layoutLoading={true}>
                    <ClientApproval
                      layoutLoading={true}
                      customPageId={clientApprovalPageId}
                      {...props}
                    />
                  </CustomPage>
                );
              }}
            />,
          )}
          {addComponent(
            props,
            homePagePageId,
            <Route
              exact
              path={homePagePagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar
                    customPageId={homePagePageId}>
                    <HomePage customPageId={homePagePageId} {...props} />
                  </CustomPage>
                );
              }}
            />,
          )}
          {addComponent(
            props,
            downloadstatsPageId,
            <Route
              exact
              path={downloadstatsPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={downloadstatsPageId}>
                    <DowloadStats
                      {...props}
                      customPageId={downloadstatsPageId}
                    />
                  </CustomPage>
                );
              }}
            />,
          )}
          {addComponent(
            props,
            newAccountPageId,
            <Route
              exact
              path={newAccountPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={newAccountPageId}>
                    <CreateNewAccount
                      {...props}
                      customPageId={newAccountPageId}
                    />
                  </CustomPage>
                );
              }}
            />,
          )}
          {addComponent(
            props,
            initPageId,
            <Route
              path={userPagePath}
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={userPageId}>
                    <Users customPageId={userPageId} {...props} />
                  </CustomPage>
                );
              }}
            />,
          )}

          {addComponent(
            props,
            exportPagePageId,
            <Route
              exact
              path={salesReportPath}
              render={() => {
                return (
                  <CustomPage
                    singleProduct={true}
                    {...props}
                    showSideBar={false}
                    customPageId={exportPagePageId}
                    layoutLoading={true}>
                    <SalesReports
                      customPageId={exportPagePageId}
                      layoutLoading={true}
                      singleProduct={true}
                      {...props}
                    />
                  </CustomPage>
                );
              }}
            />,
          )}

          {addComponent(
            props,
            exportPagePageId,
            <Route
              exact
              path={automaticReportPath}
              render={() => {
                return (
                  <CustomPage
                    singleProduct={true}
                    {...props}
                    showSideBar={false}
                    customPageId={exportPagePageId}
                    layoutLoading={true}>
                    <ReportsAuto
                      customPageId={exportPagePageId}
                      layoutLoading={true}
                      singleProduct={true}
                      {...props}
                    />
                  </CustomPage>
                );
              }}
            />,
          )}





          {sessionStorage.getItem('errorPage') !== null ? (
            <Route
              render={() => {
                //props.history.push("/app/home");
                return (
                  <CustomPage {...props} showSideBar={false} customPageId={'-'}>
                    <ErrorPage customPageId={'-'} {...props} />
                  </CustomPage>
                );
              }}
            />
          ) : (
            <Route
              render={() => {
                return (
                  <CustomPage
                    {...props}
                    showSideBar={false}
                    customPageId={-2}
                  />
                );
              }}
            />
          )}
        </Switch>
      </div>
      <PopUp
        popUpAcceptLabel={props.popUpAcceptLabel}
        isOpen={
          props.showSuccessAlert ||
          props.showErrorAlert ||
          props.showConfirmPopUp
        }
        error={props.showErrorAlert}
        confirmPopUp={props.showConfirmPopUp}
        warning={props.showWarningAlert}
        message={props.popUpAlertMessage}
        handleClose={props => {
          props.changePopUpAlertMessage('');
          props.changeShowSuccessAlert(false);
          props.changeShowErrorAlert(false);
          props.changeShowConfirmPopUp(false);
          props.changeShowWarningAlert(false);
          if (
            props.confirmPopUpAcceptFunction &&
            props.confirmPopUpAcceptFunction.function
          ) {
            props.confirmPopUpAcceptFunction.function(props);
          }
          props.changeConfirmPopUpAcceptFunction({ function: () => { } });
          props.changeConfirmPopUpCancelFunction({ function: () => { } });
        }}
        cancelFunction={props => {
          props.changePopUpAlertMessage('');
          props.changeShowSuccessAlert(false);
          props.changeShowErrorAlert(false);
          props.changeShowConfirmPopUp(false);
          props.changeShowWarningAlert(false);
          if (
            props.confirmPopUpCancelFunction &&
            props.confirmPopUpCancelFunction.function
          ) {
            props.confirmPopUpCancelFunction.function(props);
          }
          props.changeConfirmPopUpAcceptFunction({ function: () => { } });
          props.changeConfirmPopUpCancelFunction({ function: () => { } });
        }}
        {...props}
      />
    </div>
  );
};

const styles = theme => ({
  loader: {
    border: '16px solid  #FFFFFF',
    borderRadius: '50%',
    borderTop: '16px solid ' + theme.primary,
    width: '100%',
    height: '100%',
    WebkitAnimation: 'spin 0.7s linear infinite',
    animation: 'spin 0.7s linear infinite',
  },
  modal: {
    display: 'block',
    position: ' fixed',
    /*1201 cubre todo menos el header bar*/
    /*1301 en adelante cubre todo */
    zIndex: ' 1202',
    paddingTop: ' 100px',
    left: ' 0',
    top: ' 0',
    width: ' 100%',
    height: ' 100%',
    overflow: ' auto',
    backgroundColor: ' rgba(0, 0, 0, 0.4)',
  },
  modalcontent: {
    width: ' 100px',
    height: ' 100px',
    position: ' absolute',
    left: ' 50%',
    marginLeft: ' -50px',
    top: ' 50%',
    msTransform: ' translateY(-50%)',
    transform: ' translateY(-50%)',
  },
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 320px)`,
    minHeight: '100vh',
  },
  contentShift: {
    width: `calc(100vw - ${320 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
});

export default withStyles(styles)(Layout);
