import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, withStyles, Button } from '@material-ui/core';
import mantainanceLogo from '../../images/mantainanceLogo.png';
//import Login from '../login';

const Mantainance = ({ classes }) => (
  <Grid container className={classes.container}>
    <img src={mantainanceLogo} alt="aly-ai logo"></img>
    <Typography
      color="textSecondary"
      component="h2"
      variant="h5"
      className={classes.safetyText}
    >
      Página en Mantenimiento
    </Typography>
    <Button
      variant="contained"
      color="primary"
      component={Link}
      to="/login"
      size="large"
      className={classes.button}
    >
      Volver al Inicio
    </Button>
  </Grid>
);

const styles = theme => ({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  safetyText: {
    paddingBottom: theme.spacing(2),
  },
});

export default withStyles(styles, { withTheme: true })(Mantainance);
