import { compose, withState, withHandlers, lifecycle } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Auth from '../../authentication/Auth';

import LoginView from './LoginView';
import { loginUser, resetError } from './LoginState';
import {
  insertUserUrl,
  //sessionValidationInterval,
  GAEventCategoryLogin,
  GAEventActionLoginFacebook,
  sessionStorageUTMValue,
  loginLoginGA,
} from './../../components/Constants';
import {
  loginWithFacebookValidation,
  updateNonce,
} from './../../utils/functions';

import { findNeedleFromURL } from '../../utils/urlUtils';
import { setGASessionItem, sendGAEvent, sendGAPageView } from '../../utils/ga';
import { Track, Identify, Alias, NewEvent, NewTrackData } from './../../utils/mixpanel';

/*
authenticate and create if needed a user into aly platform (using the login method of the UserApi)
then do set/unset more variables in the local storage
then calls loginWithFacebookValidation

*/
async function loginfunction(props) {
  props.changeIsLoadingLogin(true);
  var url = insertUserUrl;

  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('access_token')}`,
  );
  let UserInfo = JSON.parse(localStorage.getItem('userInfo'));
  
  /*
   console.log(UserInfo);
    email: "leonidasmenendez@gmail.com"
    email_verified: true
    family_name: "Aly"
    given_name: "Leo"
    name: "Leo Aly"
    nickname: "leonidasmenendez"
    picture: "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=1324591131251339&height=50&width=50&ext=1638459828&hash=AeQjxyRMq4iE-pu-YTU"
    sub: "facebook|1324591131251339"
    updated_at: "2021-11-02T15:43:48.589Z"
  */
  await fetch(url, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(UserInfo),
  })
    .then(response => {
      return response.json();
    })
    .then(userLoginResponse => {
      let payload = userLoginResponse.payload;
      if (payload && payload.is_new && payload.is_new==='YES') {
        //////// signup event 
        // Track('signup',UserInfo);
        Track(NewTrackData("login", "page", "signup", UserInfo));
        Alias(UserInfo.email);
      }
      Identify(UserInfo.email, UserInfo);
      updateNonce(payload.nonce);
      sessionStorage.removeItem('logOut');
      localStorage.removeItem('logOut');

      localStorage.setItem('dictionary', JSON.stringify(payload.dictionary));
      localStorage.setItem(
        'userName',
        JSON.stringify({
          given_name: UserInfo.given_name,
          family_name: UserInfo.family_name,
        }),
      );

      localStorage.setItem(
        'picture',
        JSON.stringify({
          picture: UserInfo.picture,
        }),
      );

      localStorage.removeItem('userInfo');
      // this.props.loginName(UserInfo.name| UserInfo.given_name | UserInfo.family_name)
      //;
      // NOT SURE WHY DOES THIS:
      loginWithFacebookValidation(props, UserInfo);
    });

  }
export default compose(
  connect(
    state => ({
      isLoading: state.login.isLoading,
      isAuthenticated: state.login.isAuthenticated,
      error: state.login.error,
    }),
    { loginUser, resetError },
  ),
  withRouter,
  withState('activeTabId', 'setActiveTabId', 0),
  withState('nameValue', 'setNameValue', ''),
  withState('loginValue', 'setLoginValue', ''),
  withState('passwordValue', 'setPasswordValue', ''),
  withState('isLoadingLogin', 'changeIsLoadingLogin', true),
  withHandlers({
    handleLoginButtonClick: props => () => {
      props.changeIsLoadingLogin(true);
      // Track("login_page_login_clicked");
      Track(NewEvent("login", "facebook_login", "click"));
      // store utm before clear session, local storage
      const utm = sessionStorage.getItem(sessionStorageUTMValue);
      const lang = localStorage.getItem("lang");
      sessionStorage.clear();
      localStorage.clear();
      // restore utm after clear session and local storage
      setGASessionItem(loginLoginGA, sessionStorageUTMValue, utm);
      localStorage.setItem('lang', lang);
      const auth = new Auth();
      auth.login();

      sendGAEvent({
        category: GAEventCategoryLogin,
        action: GAEventActionLoginFacebook,
        label: `${utm} User start session`,
      });
    },
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      if (!this.props.error && nextProps.error) {
        //this.props.setPasswordValue("");
      }
    },
    componentDidMount() {
      let hasCampaign = false;
      let id_token = localStorage.getItem('id_token');
      sendGAPageView('Login Page');
      // https://mixpanel.com/blog/the-death-of-the-pageview/
      // Track("login_page_view");
      Track(NewEvent("login", "page", "load"))

      if (this.props.location && this.props.location.search) {
        let campaignName = findNeedleFromURL(
          this.props.location.search,
          'utm_campaign',
        );
        if (campaignName) {
          setGASessionItem(loginLoginGA, sessionStorageUTMValue, campaignName);
          hasCampaign = true;
        }
      }
      if (!hasCampaign) {
        setGASessionItem(loginLoginGA, sessionStorageUTMValue);
      }
      if (!id_token) {
        const auth = new Auth();
        auth.setupSession(err => {
          if (err) {
            this.props.changeIsLoadingLogin(false);  // 
            this.props.loginUser(undefined); // set login user with undefined=no authenticated
          } else {
            // after auth is made with auth0, then we need to setup a user inside aly platform
            // loginfunction does that (BTW: not a self explinatory function name) 
            loginfunction(this.props);
          }
        });
      } else {
        // if user is already is authenticated and hit the login page we just set the user 
        // PROBABLY THIS IS STEP IS NOT LONGER NEEDED
        this.props.loginUser(localStorage.getItem('nonce')); // state de redux
      }
    },
  }),
)(LoginView);
