import React from 'react';
import { withStyles } from '@material-ui/core';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import InterestsPicker from '../../../components/InterestsPicker';

const Interests = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <InterestsPicker
      id={'map_picker'}
      {...props}
      showLocationWiget={true}
      focusable={
        <Focusable id={props.focusId} refList={props.refList}></Focusable>
      }
    />
  );
};
export default withStyles(styles, { withTheme: true })(Interests);
