import React, { Component } from 'react';
import {
  TextField,
  withStyles,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Widget from '../../../components/Widget/WidgetContainer';

class TextFieldList extends Component {
  constructor(props, classes) {
    super(props);
    this.state = {
      maxLengthExceeded: [false],
      list: props.hasInitValue ? props.initListValue : [''],
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.reset) {
      if (this.state.list.length > 1 || this.state.list[0].length > 0) {
        this.setState({ maxLengthExceeded: [false], list: [''] });
      }
    }
    if (this.props.hasInitValue && this.props.initListValue.length > 0) {
      this.props.changeList(this.props.initListValue);
      this.setState({ list: this.props.initListValue });
      this.props.changeInitList([]);
    }

  }

  render() {
    function validateError(newList, CurrentThis) {
      for (let i = 0; i < newList.length; i++) {
        if (CurrentThis.props.minTextSize) {
          if (newList[i].length < CurrentThis.props.minTextSize) {
            if (!CurrentThis.props.Error) {
              CurrentThis.props.changeError(true);
            }
            return;
          }
        }
      }
      if (CurrentThis.props.Error) {
        CurrentThis.props.changeError(false);
      }
    }

    function validateDecimal(decimalValue) {
      return !isNaN(decimalValue - parseFloat(decimalValue));
    }
    function validateMinDecimal(decimalValue, minDecimalValue) {
      if (!isNaN(decimalValue - parseFloat(decimalValue))) {
        if (parseFloat(decimalValue) < minDecimalValue) return false;
        return true;
      }
      return false;
    }
    return (
      <Widget
        focusable={this.props.focusable}
        disableWidgetMenu={true}
        title={<div>{this.props.widgetTitle}</div>}
        sidebutton={
          this.props.maxListSize > 1 &&
          this.state.list.length < this.props.maxListSize ? (
            <div style={{ float: 'right' }}>
              <AddIcon
                id={this.props.id + 'addButton'}
                className={this.props.classes.buttons}
                onClick={() => {
                  let newState = this.state;
                  newState.list.push('');
                  validateError(newState.list, this);
                  this.setState(newState);
                  this.props.changeList(newState.list);
                }}
              />
            </div>
          ) : (
            ''
          )
        }
      >
        <React.Fragment>
          {this.state.list.map((title, index) => (
            <span key={index}>
              {this.state.maxLengthExceeded[index] ? (
                <div style={{ color: 'yellow', backgroundColor: 'blue' }}>
                  <div>{this.props.WarningIcon}</div>
                  {this.props.maxTextSizeMessage}
                </div>
              ) : (
                ''
              )}
              {title.length < this.props.minTextSize &&
              (this.props.showAdErrors ||
                this.props.showAdErrors === undefined) ? (
                <div style={{ color: 'red' }}>
                  <div className={this.props.ErrorIconClass}>
                    {this.props.ErrorIcon}
                  </div>
                  <div className={this.props.ErrorMessageClass}>
                    {this.props.minTextSizeMessage}
                  </div>
                </div>
              ) : (
                ''
              )}
              {
                title.trim().length === 0 && title[0] === " " ?(
                  <div style={{ color: 'red' }}>
                    <div className={this.props.ErrorIconClass}>
                      {this.props.ErrorIcon}
                    </div>
                    <div className={this.props.ErrorMessageClass}>
                      {this.props.minTextSizeMessage}
                    </div>
                  </div>
                ):(
                  ''
                )
              }

              {this.props.textFieldVariant === 'budget' ? (
                !validateDecimal(title) &&
                (this.props.showAdErrors ||
                  this.props.showAdErrors === undefined) ? (
                  <div style={{ color: 'red' }}>
                    <div className={this.props.ErrorIconClass}>
                      {this.props.ErrorIcon}
                    </div>
                    <div className={this.props.ErrorMessageClass}>
                      {this.props.invalidDecimalMessage}
                    </div>{' '}
                  </div>
                ) : !validateMinDecimal(title, this.props.minDecimalvalue) &&
                  (this.props.showAdErrors ||
                    this.props.showAdErrors === undefined) ? (
                  <div style={{ color: 'red' }}>
                    <div className={this.props.ErrorIconClass}>
                      {this.props.ErrorIcon}
                    </div>
                    <div className={this.props.ErrorMessageClass}>
                      {this.props.minDecimalMessage}
                    </div>
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}

              <TextField
                id={this.props.id + 'textfield' + index}
                rows={this.props.rows}
                className={this.props.classes.textField}
                variant={this.props.variant}
                multiline={this.props.multiline}
                margin={this.props.margin}
                type="text"
                fullWidth={true}
                onKeyUp={e => {
                  //if (this.props.maxListSize > 1)
                  this.props.changeList(this.state.list);
                  if (this.props.textFieldVariant === 'link') {
                    let parameters = this.props.adLinkParameters;
                    let urlParameters = '';
                    parameters.forEach(element => {
                      if (urlParameters === '') urlParameters = '?';
                      else urlParameters += '&';
                      urlParameters += element;
                    });
                    this.props.changeAdLinkPreview(
                      this.state.list[0] + '' + urlParameters,
                    );
                  }
                }}
                onChange={e => {
                  if (this.props.changeReset) this.props.changeReset(false);
                  //verifyVariant(this.props.textFieldVariant);
                  if (this.props.removeNewLineChar) {
                    e.target.value = e.target.value.replace(/\n/g, ' ');
                  }
                  let newList = this.state.list.map(value => value);
                  newList[index] = e.target.value;
                  let newState = this.state;

                  if (newList[index].length > this.props.maxTextSize) {
                    newState.maxLengthExceeded[index] = true;
                    newList[index] = newList[index].slice(
                      0,
                      this.props.maxTextSize,
                    );
                  } else {
                    newState.maxLengthExceeded[index] = false;
                  }

                  newState.list = newList;
                  if (this.props.changeError) {
                    validateError(newList, this);
                  }
                  if (this.props.textFieldVariant === 'budget') {
                    if (!validateDecimal(e.target.value))
                      this.props.changeError(true);
                    else {
                      if (
                        !validateMinDecimal(
                          e.target.value,
                          this.props.minDecimalvalue,
                        )
                      )
                        this.props.changeError(true);
                      else this.props.changeError(false);
                    }
                  }
                  this.setState(newState);
                }}
                placeholder={this.props.placeholder}
                value={title}
                style={{
                  width: '100%',
                  fontSize: '1rem',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DeleteIcon
                        id={this.props.id + 'deleteButton' + index}
                        className={this.props.classes.buttons}
                        onClick={() => {
                          let newList = this.state.list.map(value => value);
                          let newState = this.state;
                          newList.splice(index, 1);
                          newState.maxLengthExceeded.splice(index, 1);
                          if (newList.length === 0) newList.push('');
                          newState.list = newList;
                          validateError(newList, this);
                          this.setState(newState);
                          this.props.changeList(newState.list);
                          if (this.props.textFieldVariant === 'link')
                            this.props.changeAdLinkPreview('');
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              {this.props.textFieldVariant === 'link' ? (
                <div>
                  <h3>Vista Previa</h3>
                  <Tooltip title={this.props.adLinkPreview}>
                    <TextField
                      disabled={true}
                      id={'Link_Preview'}
                      rows={this.props.rows}
                      className={this.props.classes.textField}
                      variant={this.props.variant}
                      multiline={this.props.multiline}
                      margin={this.props.margin}
                      type="text"
                      fullWidth={true}
                      value={this.props.adLinkPreview}
                    ></TextField>
                  </Tooltip>
                </div>
              ) : (
                ''
              )}
            </span>
          ))}
        </React.Fragment>
      </Widget>
    );
  }
}

const styles = theme => ({
  textField: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: '100%',
    borderColor: '#013f8a',
    paddingTop: '0.3rem',
  },
});

export default withStyles(styles, { withTheme: true })(TextFieldList);
