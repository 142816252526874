import React from 'react';
import Select from 'react-select';
import {
  Typography,
  Grid,
  TextField,
  Button,
  withStyles,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import EnhancedTable from '../EnhancedTable/EnhancedTable';

import Widget from '../Widget/WidgetContainer';
import { styles } from '../../utils/styles';
import {
  getInterests,
  getAdvancedTargeting,
  filterAdvanceTargeting,
} from '../../utils/functions';
import { getWidthCode } from '../../utils/generalFunctions';
import trans from './../../i18n/tran';
import SavedAudienceMessage, {ComBehAndInterests} from '../../pages/createad/Components/savedAudienceMessage';
let currentId = 0;
const View = ({ classes, theme, onChange, value, ...props }) => {
  function findInterest(interest) {
    let found = false;
    props.interests.forEach(element => {
      if (
        element.value === interest.value &&
        interest.fbType === element.fbType
      )
        found = true;
    });
    return found;
  }

  function addInterests() {
    let newInterestsList = props.interests;
    props.interestsSelected.forEach(element => {
      element.fbType = props.advanceTargetingSearch;
      element.tipo = getSearchSelected();
      if (!findInterest(element)) {
        currentId = currentId + 1;
        element.id = currentId;
        newInterestsList.push(element);
      }
    });
    props.changePagePagination(
      Math.ceil(newInterestsList.length / props.rowsPerPage) - 1,
    );
    filterAdvanceTargeting(props, props.interestsSelector, newInterestsList);
    props.changeInterests(newInterestsList);
    props.changeInterestsSelected([]);
  }

  function removeInterest(id) {
    let newInterestsList = [];
    let removedInterestsList = [];
    props.interests.forEach(element => {
      if (element.id !== id) newInterestsList.push(element);
      else removedInterestsList.push(element);
    });
    props.interests = newInterestsList;
    if (newInterestsList.length === 0) props.changePagePagination(0);
    else if (
      props.pagePagination >
      Math.ceil(newInterestsList.length / props.rowsPerPage) - 1
    )
      props.changePagePagination(
        Math.ceil(newInterestsList.length / props.rowsPerPage) - 1,
      );
    filterAdvanceTargeting(
      props,
      props.interestsSelector,
      newInterestsList,
      removedInterestsList,
    );
    props.changeInterests(newInterestsList);
  }
  function searchInitFunction(value) {
    if (value !== 'interests') getAdvancedTargeting(value, props);
  }
  function getSearchSelected() {
    let key
    if (props.advanceTargetingSearch) {
      key = props.advanceTargetingSearch.toUpperCase()
      return trans(key, props.lang)
    }
    return
  }

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <Widget
          focusable={props.focusable}
          title={trans('INTEREST_TITLE', props.lang)}
          bodyClass={classes.tableWidget}
          disableWidgetMenu={true}
        >
          {props.savedAudienceSelected ? (
            <SavedAudienceMessage 
            lang={props.lang}
            audiences={props.selectedSavedAudiences}
            >
              <ComBehAndInterests   lang={props.lang}
            audiences={props.selectedSavedAudiences} />

            </SavedAudienceMessage>
          ) : (
            <div style={{ paddingBottom: '25px' }}>
              <div
                style={
                  getWidthCode() === 'xs' ? { borderBottomStyle: 'groove' } : {}
                }
              >
                <FormControl component="fieldset" style={{ width: '100%' }}>
                  <RadioGroup
                    row={true}
                    aria-label="advanceTargeting"
                    name="advanceTargeting"
                    value={props.advanceTargetingSearch}
                    onChange={(e, value) => {
                      props.changeInterestsSelector([]);
                      props.changeInterestsSelected([]);
                      props.setAdvanceTargetingSearch(value);
                      searchInitFunction(value);
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={4} md={3}>
                        <FormControlLabel
                          value={'interests'}
                          control={<Radio />}
                          label={trans('INTERESTS', props.lang)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <FormControlLabel
                          value={'behaviors'}
                          control={<Radio />}
                          label={trans('BEHAVIORS', props.lang)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <FormControlLabel
                          value={'life_events'}
                          control={<Radio />}
                          label={trans('LIFE_EVENTS', props.lang)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <FormControlLabel
                          value={'industries'}
                          control={<Radio />}
                          label={trans('INDUSTRIES', props.lang)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <FormControlLabel
                          value={'family_statuses'}
                          control={<Radio />}
                          label={trans('FAMILY_STATUSES', props.lang)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <FormControlLabel
                          value={'relationship_statuses'}
                          control={<Radio />}
                          label={trans('RELATIONSHIP_STATUSES', props.lang)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <FormControlLabel
                          value={'education_statuses'}
                          control={<Radio />}
                          label={trans('EDUCATION_STATUSES', props.lang)}
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </div>
              <div style={{ paddingTop: '25px' }}>
                {props.advanceTargetingSearch === 'interests' ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={9} md={9} lg={10}>
                      <TextField
                        value={props.interestsSearchText}
                        placeholder={trans('SEARCH', props.lang) + '...'}
                        style={{ width: '100%' }}
                        onChange={e => {
                          props.changeInterestsSearchText(e.target.value);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      lg={2}
                      style={{ float: 'right' }}
                    >
                      <Button
                        disabled={
                          (props.interestsSearchText &&
                            props.interestsSearchText.length === '') ||
                          !props.interestsSearchText
                        }
                        fullWidth={true}
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={() => {
                          getInterests(props);
                        }}
                      >
                        {trans('SEARCH', props.lang)}
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </div>
              <Typography>{getSearchSelected()}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9} md={9} lg={10}>
                  <Select
                    maxMenuHeight={200}
                    id={'fbInterests'}
                    styles={theme.reactSelect}
                    isMulti={true}
                    value={props.interestsSelected}
                    options={props.interestsSelector}
                    onChange={e => {
                      if (e) props.changeInterestsSelected(e);
                      else props.changeInterestsSelected([]);
                    }}
                  ></Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={2}
                  style={{ float: 'right' }}
                >
                  <Button
                    fullWidth={true}
                    disabled={props.interestsSelected.length === 0}
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() => {
                      addInterests();
                    }}
                  >
                    {trans('ADD', props.lang)}
                  </Button>
                </Grid>
              </Grid>
            </div>
          )}
          {props.savedAudienceSelected ? (
            ''
          ) : (
            <div style={{ overflow: 'hidden' }}>
              <EnhancedTable
                classes={classes}
                lang={props.lang}
                changeRowsPerPage={props.changeRowsPerPage}
                deleteFunction={removeInterest}
                updateTableProducts={props.updateTableProducts}
                mainId="id"
                changeRefresh={props.changeRefresh}
                refresh={props.refresh}
                selectAll={false}
                rows={props.interests}
                ignoreTableClass={true}
                resizeWidthCodes={['xs', 'xs-sm', 'sm']}
                headCells={[
                  {
                    id: 'label',
                    label: trans('NAME', props.lang),
                    center: true,
                  },

                  {
                    id: 'tipo',
                    label: trans('TYPE', props.lang),
                    center: true,
                  },
                  {
                    id: 'eliminarLocation',
                    label: '',
                    center: true,
                  },
                ]}
                page={props.pagePagination}
                setPage={props.changePagePagination}
              ></EnhancedTable>
            </div>
          )}
          <div style={{ height: '50px' }}></div>
        </Widget>
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(View);
