import React from 'react';
import { withStyles } from '@material-ui/core';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import ErrorIcon from '@material-ui/icons/Error';
import TextFieldList from '../../../components/TextFieldList/TextFieldListView';
import trans from './../../../i18n/tran';
const Link = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <TextFieldList
      lang={props.lang}
      recalculateMessages={props.recalculateMessages}
      showAdErrors={props.showAdErrors}
      ErrorIconClass={classes.errorIcon}
      ErrorMessageClass={classes.warningMessage}
      reset={props.reset}
      changeReset={props.changeReset}
      focusable={
        <Focusable
          id={props.componentInfo.focusId}
          refList={props.refList}
        ></Focusable>
      }
      id="Links"
      widgetTitle={trans('NEW_CAMPAIGN_LINK', props.lang)}
      maxTextSize={500}
      maxListSize={1}
      ErrorIcon={<ErrorIcon />}
      invalidUrlMessage={trans('NEW_CAMPAIGN_MESSAGE2', props.lang)}
      placeholder={trans('NEW_CAMPAIGN_MESSAGE3', props.lang)}
      multiline={false}
      variant="outlined"
      Error={props.componentInfo.getError(props)}
      changeError={props.componentInfo.getchangeError(props)}
      list={props.adLinks}
      changeList={props.changeAdLinks}
      textFieldVariant="link"
      adLinkPreview={props.adLinkPreview}
      changeAdLinkPreview={props.changeAdLinkPreview}
      adLinkParameters={props.adLinkParameters}
      getMessage={props.componentInfo.getMessage(props)}
    ></TextFieldList>
  );
};
export default withStyles(styles, { withTheme: true })(Link);
