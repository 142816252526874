export const initialState = {
  selectedCampaign: -1,
  campaigns: [{ id: -1, name: 'TODAS LAS CAMPAÑAS' }],
  error: null,
};

export const SELECTED_CAMPAIGN = 'campaign/SELECTED_CAMPAIGN';
export const CAMPAIGNS = 'campaign/SELECT_CAMPAIGN';

export const selectedCampaign = () => ({
  type: SELECTED_CAMPAIGN,
});

export const campaigns = () => ({
  type: CAMPAIGNS,
});

export const setSelectedCampaign = campaign => dispatch => {
  dispatch({ type: SELECTED_CAMPAIGN, payload: campaign });
};

export const setCampaigns = campaigns => dispatch => {
  dispatch({ type: CAMPAIGNS, payload: campaigns });
};

export default function CampaignReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case CAMPAIGNS:
      // ////console.log(`${JSON.stringify(payload)}`)
      return {
        ...state,
        campaigns: payload,
      };
    case SELECTED_CAMPAIGN:
      return {
        ...state,
        selectedCampaign: payload,
      };
    default:
      return state;
  }
}
