import React from 'react';
import { withStyles, Grid, TextField, Tooltip } from '@material-ui/core';

import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Focusable from '../../../../createad/Focusable';
import { styles } from '../../../../../utils/styles';

import ErrorIcon from '@material-ui/icons/Error';
import TextFieldList from '../../../../../components/TextFieldList/TextFieldListView';

import {
  calculateRemainingBudget,
  isNumeric,
  getCurrency,
} from '../../../../../utils/functions';
import TransparentWidget from '../../../../../components/TransparentWidget';
import Widget from '../../../../../components/Widget';
const BudgetDetail = ({ classes, theme, onChange, value, ...props }) => {
  let currency = getCurrency(props);
  if (currency) currency = 'Moneda: ' + currency;
  if (props.showModify) {
    return (
      <TransparentWidget
        focusable={props.focusable}
        title={''}
        title2={
          <Grid container>
            <Grid item xs={12} md={4} lg={4}></Grid>
            <Grid item xs={12}>
              <div style={{ float: 'right' }}>
                <Tooltip
                  open={props.showValuesTooltip}
                  placement="left"
                  arrow={true}
                  title={
                    <div>
                      <h2>Los datos mostrados son aproximados</h2>
                    </div>
                  }
                >
                  <PriorityHighIcon
                    onClick={() => {
                      props.changeShowValuesTooltip(!props.showValuesTooltip);
                    }}
                  />
                </Tooltip>
              </div>
            </Grid>
            <div style={{ height: '50px' }}></div>
          </Grid>
        }
        bodyClass={classes.tableWidget}
        disableWidgetMenu={true}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} lg={4}>
            <Widget
              titleFullWith={true}
              disableWidgetMenu={true}
              title={
                <div style={{ textAlign: 'center' }}>{'Duración (Días)'}</div>
              }
              focusable={
                <Focusable
                  //id={props.componentInfo.focusId}
                  refList={props.refList}
                ></Focusable>
              }
            >
              <React.Fragment>
                {' '}
                <div style={{ height: '2rem' }}> </div>
                <TextField
                  disabled={true}
                  className={classes.textFieldList}
                  id="modifyCampaignDuration"
                  placeholder={''}
                  multiline={false}
                  variant="outlined"
                  value={
                    isNumeric(props.modifyCampaignDuration.value[0])
                      ? parseFloat(
                          props.modifyCampaignDuration.value[0],
                        ).toFixed(0)
                      : props.modifyCampaignDuration.value[0]
                  }
                  style={{
                    width: '100%',
                    fontSize: '1rem',
                  }}
                  inputProps={{ style: { textAlign: 'center' } }}
                ></TextField>
              </React.Fragment>
            </Widget>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Widget
              titleFullWith={true}
              disableWidgetMenu={true}
              title={
                <div style={{ textAlign: 'center' }}>{'Días Restantes'}</div>
              }
              focusable={
                <Focusable
                  //id={props.componentInfo.focusId}
                  refList={props.refList}
                ></Focusable>
              }
            >
              <React.Fragment>
                {' '}
                <div style={{ height: '2rem' }}> </div>
                <TextField
                  disabled={true}
                  className={classes.textFieldList}
                  id="modifyCampaignRemaining"
                  placeholder={''}
                  multiline={false}
                  variant="outlined"
                  value={
                    isNumeric(props.modifyCampaignRemaining.value[0])
                      ? parseFloat(
                          props.modifyCampaignRemaining.value[0],
                        ).toFixed(0)
                      : props.modifyCampaignRemaining.value[0]
                  }
                  style={{
                    width: '100%',
                    fontSize: '1rem',
                  }}
                  inputProps={{ style: { textAlign: 'center' } }}
                ></TextField>
              </React.Fragment>
            </Widget>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Widget
              titleFullWith={true}
              disableWidgetMenu={true}
              title={<div style={{ textAlign: 'center' }}>{'Gastado'}</div>}
              focusable={
                <Focusable
                  //id={props.componentInfo.focusId}
                  refList={props.refList}
                ></Focusable>
              }
            >
              <React.Fragment>
                {' '}
                <div style={{ height: '2rem' }}> </div>
                <TextField
                  disabled={true}
                  className={classes.textFieldList}
                  id="modifyCampaignSpent"
                  placeholder={''}
                  multiline={false}
                  variant="outlined"
                  value={
                    isNumeric(props.modifyCampaignSpent.value[0])
                      ? parseFloat(props.modifyCampaignSpent.value[0]).toFixed(
                          2,
                        )
                      : props.modifyCampaignSpent.value[0]
                  }
                  style={{
                    width: '100%',
                    fontSize: '1rem',
                  }}
                  inputProps={{ style: { textAlign: 'center' } }}
                ></TextField>
              </React.Fragment>
            </Widget>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Widget
              titleFullWith={true}
              disableWidgetMenu={true}
              title={
                <div style={{ textAlign: 'center' }}>
                  {'Presupuesto Total (Original)'}
                </div>
              }
              focusable={
                <Focusable
                  //id={props.componentInfo.focusId}
                  refList={props.refList}
                ></Focusable>
              }
            >
              <React.Fragment>
                <div style={{ height: '2rem' }}> </div>
                <TextField
                  disabled={true}
                  className={classes.textFieldList}
                  id="modifyCampaignOriginalBudget"
                  placeholder={''}
                  multiline={false}
                  variant="outlined"
                  value={
                    isNumeric(props.modifyCampaignOriginalBudget.value[0])
                      ? parseFloat(
                          props.modifyCampaignOriginalBudget.value[0],
                        ).toFixed(2)
                      : props.modifyCampaignOriginalBudget.value[0]
                  }
                  style={{
                    width: '100%',
                    fontSize: '1rem',
                  }}
                  inputProps={{ style: { textAlign: 'center' } }}
                ></TextField>
              </React.Fragment>
            </Widget>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Widget
              titleFullWith={true}
              disableWidgetMenu={true}
              title={
                <div style={{ textAlign: 'center' }}>
                  {'Presupuesto Ajustado'}
                </div>
              }
              focusable={
                <Focusable
                  //id={props.componentInfo.focusId}
                  refList={props.refList}
                ></Focusable>
              }
            >
              <React.Fragment>
                {' '}
                <div style={{ height: '2rem' }}> </div>
                <TextField
                  disabled={true}
                  className={classes.textFieldList}
                  id="modifyCampaignAdjusted"
                  placeholder={''}
                  multiline={false}
                  variant="outlined"
                  value={
                    isNumeric(props.modifyCampaignAdjusted.value[0])
                      ? parseFloat(
                          props.modifyCampaignAdjusted.value[0],
                        ).toFixed(2)
                      : props.modifyCampaignAdjusted.value[0]
                  }
                  style={{
                    width: '100%',
                    fontSize: '1rem',
                  }}
                  inputProps={{ style: { textAlign: 'center' } }}
                ></TextField>
              </React.Fragment>
            </Widget>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Widget
              titleFullWith={true}
              disableWidgetMenu={true}
              title={
                <div style={{ textAlign: 'center' }}>
                  {'Presupuesto Restante'}
                </div>
              }
              focusable={
                <Focusable
                  //id={props.componentInfo.focusId}
                  refList={props.refList}
                ></Focusable>
              }
            >
              <React.Fragment>
                <div style={{ height: '2rem' }}> </div>
                <TextField
                  disabled={true}
                  className={classes.textFieldList}
                  id="modifyCampaignRemainingSpent"
                  placeholder={''}
                  multiline={false}
                  variant="outlined"
                  value={
                    isNumeric(props.modifyCampaignRemainingSpent.value[0])
                      ? parseFloat(
                          props.modifyCampaignRemainingSpent.value[0],
                        ).toFixed(2)
                      : props.modifyCampaignRemainingSpent.value[0]
                  }
                  style={{
                    width: '100%',
                    fontSize: '1rem',
                  }}
                  inputProps={{ style: { textAlign: 'center' } }}
                ></TextField>
              </React.Fragment>
            </Widget>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <TextFieldList
              textAlign={'center'}
              recalculateMessages={props.recalculateMessages}
              showAdErrors={true}
              ErrorIconClass={classes.errorIcon}
              ErrorMessageClass={classes.warningMessage}
              reset={props.reset}
              changeReset={props.changeReset}
              getMessage={props.componentInfo.getMessage(props)}
              changeError={props.componentInfo.getchangeError(props)}
              Error={props.componentInfo.getError(props)}
              focusable={
                <Focusable
                  id={props.componentInfo.focusId}
                  refList={props.refList}
                ></Focusable>
              }
              id="modifyCampaignDailyBudget"
              widgetTitle={'Presupuesto Diario'}
              widgetTitle2={
                <div style={{ float: 'right' }}>
                  <h3>{currency}</h3>
                </div>
              }
              ErrorIcon={<ErrorIcon />}
              placeholder={''}
              multiline={false}
              variant="outlined"
              list={props.modifyCampaignDailyBudget.value}
              initListValue={props.modifyCampaignDailyBudget.value}
              hasInitValue={true}
              changeList={e => {
                if (e.length && e.length > 0) {
                  calculateRemainingBudget(
                    e[0],
                    props.modifySelectedDates.value,
                    props,
                  );
                }
                props.changeModifyCampaignDailyBudget({
                  modified: true,
                  value: e,
                });
              }}
              maxListSize={1}
              disabled={false}
            ></TextFieldList>
          </Grid>
        </Grid>
      </TransparentWidget>
    );
  } else return '';
};
export default withStyles(styles, { withTheme: true })(BudgetDetail);
