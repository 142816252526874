import { axios, axiosHeaders } from './helper';
import { getAccountsUrlAdminApiWithoutStatusValidation } from '../../components/Constants';
import { AccountI } from '../context/accountId';

interface GetAccountsResponse {
  success: boolean;
  payload?: AccountI[];
  errors?: string | string[];
}

export const GetAccounts = async () => {
  return await axios.get<GetAccountsResponse>(
    `${getAccountsUrlAdminApiWithoutStatusValidation}`,

    {
      ...axiosHeaders(),
    },
  );
};
