import { axios, axiosHeaders } from './helper';
import { couponValidate } from '../../components/Constants';

interface CouponValidateResponse {
  code: number;
  desc: string;
  errors: string[];
  payload: unknown;
}

export const CouponValidate = async (coupon: string) => {
  return await axios.post<CouponValidateResponse>(
    `${couponValidate}`,
    { coupon },
    {
      ...axiosHeaders(),
    },
  );
};
