import React from 'react';
import { withStyles, FormHelperText } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const Disclaimer = ({ classes, theme, ...props }) => {
  return (
    <>
      <Box component="span" m={1}>
        <FormHelperText>
          <ErrorOutlineIcon /> {props.message}
        </FormHelperText>
      </Box>
    </>
  );
};

export default withStyles({}, { withTheme: true })(Disclaimer);
