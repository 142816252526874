import React from 'react';
import { withStyles, Grid, Tooltip } from '@material-ui/core';

import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Focusable from '../../../../createad/Focusable';
import { styles } from '../../../../../utils/styles';
import Select from 'react-select';
import ErrorIcon from '@material-ui/icons/Error';
import TextFieldList from '../../../../../components/TextFieldList/TextFieldListView';
import { lowestCostWithouCapBidStrategy } from '../../../../../components/Constants';
import {
  // calculateRemainingBudget,
  // isNumeric,
  getCurrency,
} from '../../../../../utils/functions';
import TransparentWidget from '../../../../../components/TransparentWidget';
import Widget from '../../../../../components/Widget';
const BiddingAmount = ({ classes, theme, onChange, value, ...props }) => {
  let currency = getCurrency(props);
  if (currency) currency = 'Moneda: ' + currency;
  if (props.showModify) {
    return (
      <TransparentWidget
        focusable={props.focusable}
        title={''}
        title2={
          <Grid container>
            <Grid item xs={12} md={4} lg={4}></Grid>
            <Grid item xs={12}>
              <div style={{ float: 'right' }}>
                <Tooltip
                  open={props.showValuesTooltip}
                  placement="left"
                  arrow={true}
                  title={
                    <div>
                      <h2>Los datos mostrados son aproximados</h2>
                    </div>
                  }
                >
                  <PriorityHighIcon
                    onClick={() => {
                      props.changeShowValuesTooltip(!props.showValuesTooltip);
                    }}
                  />
                </Tooltip>
              </div>
            </Grid>
            <div style={{ height: '50px' }}></div>
          </Grid>
        }
        bodyClass={classes.tableWidget}
        disableWidgetMenu={true}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={6}>
            <Widget
              titleFullWith={true}
              disableWidgetMenu={true}
              overflow={true}
              title={
                <div style={{ textAlign: 'left' }}>{'Estrategía de Puja'}</div>
              }
              focusable={
                <Focusable
                  //id={props.componentInfo.focusId}
                  refList={props.refList}
                ></Focusable>
              }
            >
              <div style={{ height: '2rem' }}> </div>
              <Select
                id={'BidStrategy'}
                styles={theme.reactSelect}
                options={props.modifyCampaignSelectorBidStrategy}
                value={props.modifyCampaignSelectedBidStrategies.value}
                onChange={e => {
                  props.changeModifyCampaignSelectedBidStrategies({
                    modified: true,
                    value: [e],
                  });
                  //e.value === lowestCostWithouCapBidStrategy ?
                  //console.log(e)
                  let bidValue =
                    e.value === lowestCostWithouCapBidStrategy
                      ? ['0']
                      : props.modifyCampaignBidValue.value;
                  //console.log(bidValue)
                  props.changeModifyCampaignBidValue({
                    modified: true,
                    value: bidValue,
                  });
                }}
                placeholder={'Seleccione una Estrategía de Puja'}
              ></Select>
            </Widget>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextFieldList
              textAlign={'center'}
              recalculateMessages={props.recalculateMessages}
              showAdErrors={true}
              ErrorIconClass={classes.errorIcon}
              ErrorMessageClass={classes.warningMessage}
              reset={props.reset}
              changeReset={props.changeReset}
              getMessage={props.componentInfo.getMessage(props)}
              changeError={props.componentInfo.getchangeError(props)}
              Error={props.componentInfo.getError(props)}
              focusable={
                <Focusable
                  id={props.componentInfo.focusId}
                  refList={props.refList}
                ></Focusable>
              }
              id="modifyCampaignBiddingAmount"
              widgetTitle={'Bidding Amount'}
              widgetTitle2={
                <div style={{ float: 'right' }}>
                  <h3>{currency}</h3>
                </div>
              }
              ErrorIcon={<ErrorIcon />}
              placeholder={''}
              multiline={false}
              variant="outlined"
              textFieldVariant="biddingAmount"
              bidStrategy={props.modifyCampaignSelectedBidStrategies}
              list={props.modifyCampaignBidValue.value}
              initListValue={props.modifyCampaignBidValue.value}
              hasInitValue={true}
              changeList={e => {
                props.changeModifyCampaignBidValue({
                  modified: true,
                  value: e,
                });
              }}
              maxListSize={1}
              disabled={
                props.modifyCampaignSelectedBidStrategies.value.length !== 0 &&
                props.modifyCampaignSelectedBidStrategies.value[0].value ===
                  lowestCostWithouCapBidStrategy
              }
            ></TextFieldList>
          </Grid>
        </Grid>
      </TransparentWidget>
    );
  } else return '';
};
export default withStyles(styles, { withTheme: true })(BiddingAmount);
