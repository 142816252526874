import { compose, withState, withHandlers, lifecycle } from 'recompose';
import View from './SubscriptionsView';
import {  
  getPayAlySubscriptions,
  getPayAlyCurrentSubscription,
  getPayAlyLinks,
} from '../../utils/functions';

const mycompose = compose(
  withState('companyLinks', 'changeCompanyLinks', []),
  withState('companySubscriptions', 'changeCompanySubscriptions', []),
  withState('pagePagination', 'changePagePagination', 0),
  withState('rowsPerPage', 'changeRowsPerPage', 5),
  withState('pagePagination2', 'changePagePagination2', 0),
  withState('rowsPerPage2', 'changeRowsPerPage2', 5),
  lifecycle({
    componentDidMount() {
      // to avoid calling it twice
      if (sessionStorage.getItem('errorPage') === null) {
        getPayAlySubscriptions(this.props); // the name is misleading, it should be get all payments
        getPayAlyCurrentSubscription(this.props);
        getPayAlyLinks(this.props);
        this.props.changeTenantChangeFunction({
          function: newTenant => {
            getPayAlySubscriptions(this.props);
            getPayAlyCurrentSubscription(this.props);
            getPayAlyLinks(this.props);
          },
        });
      }
    },

    componentWillUnmount() {
      //window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
    componentDidUpdate() {},
  }),
  withHandlers({}),
);

export default mycompose(View);
