import React, { FC } from 'react';
import { Global } from '@emotion/react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const drawerBleeding = 56;

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export interface DrawerProps {
  drawerState: boolean;
  autoClose?: boolean;
  setDrawerState: (value: boolean) => void;
  anchorPosition?: Anchor;
}

export const Drawer: FC<DrawerProps> = ({
  drawerState,
  setDrawerState,
  anchorPosition = 'bottom',
  autoClose = true,
  children,
}) => {
  const classes = useStyles();

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawerState(open);
    };

  const handleClose = autoClose ? toggleDrawer(false) : () => {};
  return (
    <>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
      <SwipeableDrawer
        className={classes.drawer}
        anchor={anchorPosition}
        open={drawerState}
        onClose={() => setDrawerState(false)}
        onOpen={() => setDrawerState(true)}>
        <div
          className={clsx(classes.list, {
            [classes.fullList]:
              anchorPosition === 'top' || anchorPosition === 'bottom',
          })}
          role="presentation"
          onClick={handleClose}
          onKeyDown={toggleDrawer(false)}>
          {children}
        </div>
      </SwipeableDrawer>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: { zIndex: 2147483699 },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
  }),
);
