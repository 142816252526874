import React, { FC, useCallback } from 'react';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { useStyles } from './styles';
import { palette } from '../../theme';
import { t } from 'i18next';
import { TierBasic } from '../../context';

interface PaymentPlanItemProps {
  data: TierBasic;
  changePlan: (value: number | undefined) => void;
  selected: boolean;
  technical: string[];
}
export const PaymentPlanItem: FC<PaymentPlanItemProps> = ({
  data,
  changePlan,
  selected,
  technical,
}) => {
  const classes = useStyles();

  const cta = useCallback(() => {
    changePlan(selected ? undefined : data.id);
  }, [changePlan, data.id, selected]);
  return (
    <Card
      className={`${classes.cardRoot}  ${selected && classes.cardSelected} `}>
      <CardContent className={classes.cardContent}>
        <PaymentDetail data={data} technical={technical} />
        <Button
          className={` ${selected && classes.buttonInverse}`}
          onClick={cta}
          fullWidth
          style={{ textTransform: 'none' }}
          color="primary"
          variant="contained">
          {selected ? t('Remove plan') : t('Get this plan')}
        </Button>
      </CardContent>
    </Card>
  );
};

interface PaymentDetailProps {
  data: TierBasic;
  technical: string[];
}

export const PaymentDetail: FC<PaymentDetailProps> = ({ data, technical }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.cardTitle}>{data.name}</Typography>

      {data.id === 1 ? (
        <Typography className={classes.cardSubTitle}>
          {t('+{{percentage}}% Based on monthly ad spend', {
            percentage: data.percentage,
          })}
        </Typography>
      ) : (
        <Typography className={classes.cardSubTitle}>
          <span style={{ textDecoration: 'line-through' }}>
            +{data.percentage}%
          </span>
          {t('+0% Based on monthly ad spend', {
            percentage: data.percentage,
          })}
        </Typography>
      )}
      <div style={{ paddingLeft: '24px' }}>
        {data?.features.map((feature: string) => {
          return (
            <Typography
              key={`${data.id}${data.name}${feature}`}
              className={classes.featureEnable}>
              <CheckCircleIcon
                fontSize="small"
                style={{ color: palette.mindAly }}
              />

              {feature}
            </Typography>
          );
        })}
      </div>

      <div style={{ paddingLeft: '24px' }}>
        <Typography
          className={classes.cardSection}
          style={{ marginLeft: '20px' }}>
          {t('Technical Support')}
        </Typography>
        {technical.map((support: string) => {
          const valueEnable = data.support.includes(support);
          const IconComponent = valueEnable
            ? CheckCircleIcon
            : RemoveCircleOutlineIcon;
          return (
            <Typography
              key={`${data.id}${data.name}${support}`}
              className={`
              ${valueEnable ? classes.featureEnable : classes.featureDisable} 
            `}>
              <IconComponent
                fontSize="small"
                style={{ color: valueEnable ? palette.mindAly : palette.slate }}
              />
              {support}
            </Typography>
          );
        })}
      </div>
      <Typography className={classes.priceText}>
        US$ {data.priceMonthly}
        <span className={classes.pricePerTimeText}> {t('/Monthly')}</span>
        <br />
        <span className={classes.cardSubTitle}>{t('*Taxes may apply.')} </span>
      </Typography>
    </>
  );
};
