import ReactGA from 'react-ga';
import {
  loginLoginGA,
  loginSuccessGA,
  accountNewAccountGA,
  accountAdAccountGA,
  accountRemoveAccountGA,
  billingSaleGA,
  billingCompanyNameGA,
  billingCompanyCountryGA,
  billingLinkPayalyGA,
  productProductGA,
  reactAppGATrackingCode,
  sessionStorageUTMValue,
  GAEventCategoryAsociate,
  GAEventActionAsociate,
  GAEventCategoryRemove,
  GAEventActionRemove,
  GAEventCategorySale,
  GAEventActionSale,
} from '../components/Constants';

let google = {
  gaTrackingCode: reactAppGATrackingCode,
};

export default google;

export function getGASessionItem() {
  return sessionStorage.getItem('GAData');
}

function setGAData(keys, concat) {
  let existsAll = true;
  let vals = [];
  for (let i = 0; i < keys.length; i++) {
    if (!existsSessionItem(keys[i])) {
      existsAll = false;
      break;
    } else {
      let val = sessionStorage.getItem(keys[i]);
      vals.push(val);
    }
  }
  if (existsAll) {
    let stringGAData = concat(...vals);
    sessionStorage.setItem('GAData', stringGAData);
  }
}

export function addOrUpdateSessionItem(key, value) {
  let set = true;
  if (existsSessionItem(key)) {
    let oldVal = sessionStorage.getItem(key);
    if (oldVal === value) {
      set = false; // dont update if it is the same value
    }
  }
  if (set) {
    sessionStorage.setItem(key, value); // add if doesnt exist or exist but it is different
  }
}

export function existsSessionItem(key) {
  return !(sessionStorage.getItem(key) === null);
}

export function setupKey(key, val = undefined) {
  if (typeof val == 'undefined') {
    val = 'Organic';
  }
  let currentUtm;
  currentUtm = sessionStorage.getItem(key);
  if (
    !(
      currentUtm &&
      currentUtm.length &&
      currentUtm.length > 0 &&
      currentUtm !== 'Organic'
    )
  ) {
    sessionStorage.setItem(key, val);
  }
}

export function setGASessionItem(pageAction, key, value = undefined) {
  let stringGAData;

  switch (pageAction) {
    case loginLoginGA:
      setupKey(key, value);
      break;
    case loginSuccessGA:
      addOrUpdateSessionItem('GAUserName', value);
      setGAData(['utm'], utmVal => {
        return `${utmVal} - ${value}`;
      });
      break;
    case accountNewAccountGA:
      addOrUpdateSessionItem('GAAccountName', value);
      setGAData(['utm', 'GAUserName'], (utmVal, GAUserNameVal) => {
        return `${utmVal} - ${GAUserNameVal} - ${value}`;
      });
      break;
    case accountAdAccountGA:
      addOrUpdateSessionItem('GAAccountKey', value);
      setGAData(['utm', 'GAUserName'], (utmVal, GAUserNameVal) => {
        return `${utmVal} - ${GAUserNameVal} - ${value}_add`;
      });
      break;
    case accountRemoveAccountGA:
      addOrUpdateSessionItem('GAAccountKey', value);
      setGAData(['utm', 'GAUserName'], (utmVal, GAUserNameVal) => {
        return `${utmVal} - ${GAUserNameVal} - ${value}_Remove`;
      });
      break;
    case billingSaleGA:
      addOrUpdateSessionItem('GAPlan', value);
      setGAData(
        ['GAUserName', 'GAAccountName'],
        (GAUserNameVal, GAAccountNameVal) => {
          return `${GAUserNameVal} - ${GAAccountNameVal} - ${value}`;
        },
      );
      break;
    case billingCompanyNameGA:
      addOrUpdateSessionItem('GACompanyName', value);
      setGAData(
        ['GAUserName', 'GAAccountName', 'GAPlan'],
        (GAUserNameVal, GAAccountNameVal, GAPlanVal) => {
          return `${GAUserNameVal} - ${GAAccountNameVal} - ${GAPlanVal} - ${value}`;
        },
      );
      break;
    case billingCompanyCountryGA:
      addOrUpdateSessionItem('GACompanyCountry', value);
      setGAData(
        ['GAUserName', 'GAAccountName', 'GAPlan', 'GACompanyName'],
        (GAUserNameVal, GAAccountNameVal, GAPlanVal, GACompanyName) => {
          return `${GAUserNameVal} - ${GAAccountNameVal} - ${GAPlanVal} - ${GACompanyName} - ${value}`;
        },
      );
      break;
    case billingLinkPayalyGA:
      addOrUpdateSessionItem('GALinkToPay', value);
      setGAData(['GAUserName'], GAUserNameVal => {
        return `${GAUserNameVal} - ${value}`;
      });
      break;
    case productProductGA:
      addOrUpdateSessionItem('GAProduct', value);
      setGAData(
        [
          'GAUserName',
          'GAAccountName',
          'GAPlan',
          'GACompanyName',
          'GACompanyCountry',
          'GALinkToPay',
          'GAProduct',
        ],
        (
          GAUserNameVal,
          GAAccountNameVal,
          GAPlanVal,
          GACompanyNameVal,
          GACompanyCountryVal,
          GALinkToPayVal,
          GAProductVal,
        ) => {
          return `${GAUserNameVal} - ${GAAccountNameVal} - ${GAPlanVal} - ${GACompanyNameVal} - ${GACompanyCountryVal} - ${GALinkToPayVal} - ${GAProductVal} - ${value}`;
        },
      );
      break;
    default:
      break;
  }
  return stringGAData;
}

//this is the only place where we call GA event
export function sendGAEvent(payload) {
  ReactGA.event(payload);
}

export function sendGAPageView(page) {
  ReactGA.pageview(page);
}

// function where we need to send and an specific event
export function sendGAEventAsociateAdAccount(AccountName, AccountAlyKey) {
  let setGAdataAdAccount = `${AccountName} - ${AccountAlyKey}`;
  setGASessionItem(
    accountAdAccountGA,
    sessionStorageUTMValue,
    setGAdataAdAccount,
  );
  let sendGAdataAdAccount = getGASessionItem();

  sendGAEvent({
    category: GAEventCategoryAsociate,
    action: GAEventActionAsociate,
    label: sendGAdataAdAccount,
  });
}

export function sendGAEventRemoveAdAccount(AccountName, AccountAlyKey) {
  let setGAdataRemoveAccount = `${AccountName} - ${AccountAlyKey}`;
  setGASessionItem(
    accountRemoveAccountGA,
    sessionStorageUTMValue,
    setGAdataRemoveAccount,
  );
  let sendGAdataRemoveAccount = getGASessionItem();

  sendGAEvent({
    category: GAEventCategoryRemove,
    action: GAEventActionRemove,
    label: sendGAdataRemoveAccount,
  });
}

export function sendGAEventBillingSale(productPrice) {
  let sendGAdataSale = getGASessionItem();

  sendGAEvent({
    category: GAEventCategorySale,
    action: GAEventActionSale,
    label: sendGAdataSale,
    value: productPrice,
  });
}
