import React from 'react';
import { Grid, withStyles, Button} from '@material-ui/core';
import Widget from '../../components/Widget/WidgetContainer';
import { cancelPayAly } from '../../utils/functions';
import { billingPagePath } from '../../components/Constants';
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable';
import trans from '../../i18n/tran';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import PageTitle from '../../components/PageTitle';
import { Typography } from 'src/components/Wrappers/Wrappers';

const FullStringDate = (props) => {
  if(!props.date) {
    return (<></>)
  }
  return (
    <Typography> 
      {trans('BILLING_SUBS_NEXT_COLLECT', props.lang)} {" : "}
      {new Date(props.date).toLocaleDateString(props.lang, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      })}
    </Typography>
  )
}

const WarningPaymentDue = (props) => {
    const today = new Date();    
    const dateDate = Date.parse(props.date);       
    if (props.date && dateDate<today) {
      return <Typography>Fecha proximo cobro ha caducado!</Typography>
    }
    return <></>
}

const PageMain = (props) => {
  if (!props.sub) {
    return <></>
  }
  
  if (props.sub.length===0) {  
    return (
      <Typography>{trans('BILLING_SUBS_NO_SUBS', props.lang)}</Typography>
    )
  }
  // WHY????
  if (!props.sub[0]) {
    return (<></>)
  }  

  return (
    <>
      <FullStringDate lang={props.lang} date={props.sub[0].NextPayment} />
      <WarningPaymentDue lang={props.lang} date={props.sub[0].NextPayment} />
    </>
  )    
}

const SubscriptionView = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <React.Fragment>
      <PageTitle title={trans('NAV_SUBSCRIPTIONS', props.lang)} variant="h1" />
      <Grid item xs={12} md={12} lg={12}>
        <Widget 
          bodyClass={classes.tableWidget}
          overflow
          disableWidgetMenu={true}>
            <PageMain lang={props.lang} sub={props.companyCurrentSubscription} />             
        </Widget>
        { /* PAYMENTS */}
        <Widget
          title={
            <div>
              <h2 style={{ textAlign: 'center' }}>
                {trans('BILLING_PAYMENTS', props.lang)}
              </h2>
            </div>
          }
          bodyClass={classes.tableWidget}
          overflow
          disableWidgetMenu={true}
        >
          <div style={{ overflow: 'hidden' }}>
            <EnhancedTable
              classes={classes}
              changeRowsPerPage={props.changeRowsPerPage}
              updateTableProducts={props.updateTableProducts}
              mainId="id"
              changeRefresh={props.changeRefresh}
              refresh={props.refresh}
              selectAll={false}
              rows={props.companySubscriptions}
              ignoreTableClass={true}
              resizeWidthCodes={['xs', 'xs-sm', 'sm']}
              headCells={[
                {
                  id: 'ProductName',
                  label: trans('PRODUCT', props.lang),
                  center: true,
                },
                {
                  id: 'Date',
                  label: trans('DATE', props.lang),
                  center: true,
                },
                {
                  id: 'Status',
                  label: trans('PAYMENT_STATUS', props.lang),
                  center: true,
                },
              ]}
              page={props.pagePagination}
              setPage={props.changePagePagination}
            ></EnhancedTable>

            {props.companyCurrentSubscription.length > 0 && (
              <Disclaimer
                message={trans('BILLING_SUBS_DISCLAIMER', props.lang)}
              />
            )}

            {props.companyCurrentSubscription.length > 0 ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => {
                  cancelPayAly(props);
                }}
                style={{ float: 'right', color: 'red' }}
              >
                {trans('BILLING_CANCEL', props.lang)}
              </Button>
            ) : (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => {
                  if (props.history) {
                    props.history.push(billingPagePath);
                  }
                }}
                style={{ float: 'right' }}
              >
                {trans('BILLING_NEW_SUBSCRIPTION', props.lang)}
              </Button>
            )}
          </div>
        </Widget>
        { /* LINKS DE PAGO? WTF */}
        { /* 
        <Widget
            title={
              <div>
                <h2 style={{ textAlign: 'center' }}>Links</h2>
              </div>
            }
            bodyClass={classes.tableWidget}
            overflow
            disableWidgetMenu={true}
          >
            <div style={{ overflow: 'hidden' }}>
              <EnhancedTable
                classes={classes}
                changeRowsPerPage={props.changeRowsPerPage2}
                updateTableProducts={props.updateTableProducts}
                mainId="id"
                changeRefresh={props.changeRefresh}
                refresh={props.refresh}
                selectAll={false}
                rows={props.companyLinks}
                ignoreTableClass={true}
                resizeWidthCodes={['xs', 'xs-sm', 'sm']}
                headCells={[
                  {
                    id: 'ProductName',
                    label: trans('PRODUCT', props.lang),
                    center: true,
                  },

                  {
                    id: 'MinMonths',
                    label: 'Meses Mínimos',
                    center: true,
                  },
                  {
                    id: 'Price',
                    label: 'Precio',
                    center: true,
                  },
                  {
                    id: 'LinkButton',
                    label: 'Link',
                    center: true,
                    isButton: true,
                  },
                ]}
                page={props.pagePagination2}
                setPage={props.changePagePagination2}
              ></EnhancedTable>
            </div>{' '}
        </Widget>
        */}
      </Grid>
    </React.Fragment>
  );
};

const styles = theme => ({
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  infoMessage: {
    color: '#9F6000',
    backgroundColor: '#FEEFB3',
    margin: theme.spacing(1),
  },
  textField: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: 500,
    borderColor: '#013f8a',
  },
  margin: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
    width: 400,
  },
  fanButton: {
    float: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(1),
    alignText: 'center',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(0),
  },
  tableWidget: {
    overflowX: 'visible',
  },
  datePicker: {
    overflowX: 'visible',
    padding: theme.spacing(2),
    margin: 'auto',
  },
  errorIcon: {
    fontSize: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    float: 'left',
  },
  warningMessage: {
    fontSize: '0.9rem',
    height: '1.5rem',
    paddingTop: '0.3rem',
    float: 'left',
  },
  Button: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignText: 'center',
    fontSize: '0.75rem',
    marginTop: theme.spacing(2),
  },
  modal: {
    width: '90%',
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflow: 'scroll',
    height: '100%',
    display: 'block',
  },
});

export default withStyles(styles, { withTheme: true })(SubscriptionView);
