/*
leo notes:This screen is called when creating products and when modifing products
to know if modifing a product check the prop:modifyProduct
*/
import { compose, withState, withHandlers, lifecycle } from 'recompose';
import View from './ProductView';
import {
  getCategoriesCreateProduct,
  getProductAdminApiFilters,
  getAdAccountsByProductApiAdmin,
  getCompanyApiAdmin,
  getBrandApiAdmin,
  getProductAdminApiFiltersBrandandCompany,
  validateOnBoardingEnd,
  alertPopUp,
  getProductAdminApi,
} from '../../utils/functions';
import { saveProductRequest } from '../../components/ApiClient/Product';
import { onBoardingOK } from '../../components/Constants';
import { sendGAPageView } from '../../utils/ga';
import trans from 'src/i18n/tran';

export function returnToModifyPage(props) {
  props.changeShowCreateProduct(false);
  getCompanyApiAdmin(props, props.selectedTenants);
  getProductAdminApi(props, props.selectedTenants);
  props.changeSelectedAdminBrands([]);
}
function reloadProperties(props) {
  props.changeAdTitle(['']);
  props.changeAdDescription(['']);
  props.changeSelectorCategory([]);
  props.changeSelectedCategories([]);
  props.changeSelectedAdminAdAccounts([]);
  props.changeSelectedCreateProductCompanies([]);
  props.changeSelectedCreateProductBrands([]);
  props.changeSelectorCreateProductBrand([]);
  props.changeHasErrorTitle(true);
  props.changeHasErrorDescription(false);
  props.changeHasErrorCategory(true);
  props.changeShowErrors(false);
  props.changeHasError(true);
  props.changeRefList([]);
  props.changeReset(true);
  getCategoriesCreateProduct(props);
}
const mycompose = compose(
  withState('adTitle', 'changeAdTitle', ['']),
  withState('initTitle', 'changeInitTitle', ['']),
  withState('adDescription', 'changeAdDescription', ['']),
  withState('initDescription', 'changeInitDescription', ['']),
  withState('adProductId', 'changeAdProductId', 0),
  withState('showErrors', 'changeShowErrors', false),
  withState('hasErrorTitle', 'changeHasErrorTitle', true),
  withState('hasErrorDescription', 'changeHasErrorDescription', false),
  withState('hasErrorAdAccount', 'changeHasErrorAdAccount', true),
  withState('hasErrorCategory', 'changeHasErrorCategory', true),
  withState('hasErrorCompany', 'changeHasErrorCompany', true),
  withState('hasErrorBrand', 'changeHasErrorBrand', true),

  withState('hasError', 'changeHasError', true),
  withState('createClicked', 'setCreateClicked', false),
  withState('refList', 'changeRefList', []),
  withState(
    'selectedCreateProductCompanies',
    'changeSelectedCreateProductCompanies',
    [],
  ),

  withState(
    'selectedCreateProductBrands',
    'changeSelectedCreateProductBrands',
    [],
  ),
  withState('reset', 'changeReset', false),
  withState('tempCompany', 'changeTempCompany', ''),
  withState('tempBrand', 'changeTempBrand', ''),
  withState('initModifyAdAccount', 'changeInitModifyAdAccount', false),
  lifecycle({
    componentDidMount() {
      if (!this.props.isDialog) {
        this.props.changeTenantChangeFunction({
          function: newTenenatValue => {
            if (
              sessionStorage.getItem('onBoardingStatus') !==
              onBoardingOK.toString()
            ) {
              this.props.changeHasErrorAdAccount(true);
              this.props.changeSelectedAdminAdAccounts([]);
              this.props.changeSelectedCreateProductCompanies([]);
            }
          },
        });
        this.props.changeSelectedAdminAdAccounts([]);
      } 
      else {
        let previousTenantChangeFunction = this.props.tenantChangeFunction;
        this.props.changeTenantChangeFunction({
          function: newTenenatValue => {
            this.props.changeShowCreateProduct(false);
            if (  previousTenantChangeFunction 
                  && previousTenantChangeFunction.function) {
                    previousTenantChangeFunction.function(newTenenatValue);
                  }
          },
        });
        
        if (this.props.selectedTenants.length > 0) {
          getCompanyApiAdmin(this.props, this.props.selectedTenants);
        }
        if (this.props.selectedAdminAdAccounts.length > 0) {
          this.props.changeHasErrorAdAccount(false);
        }
      }
      this.props.changeSelectedCategories([]);
      getCategoriesCreateProduct(this.props);
      
      if (this.props.modifyProduct && this.props.modifyProductRow.length > 0) {
        this.props.changeAdProductId(this.props.modifyProductRow[0].value);
        this.props.changeInitTitle([this.props.modifyProductRow[0].label]);
        this.props.changeAdTitle([this.props.modifyProductRow[0].label]);
        this.props.changeHasErrorTitle(false);
        this.props.changeInitDescription([
          this.props.modifyProductRow[0].Description != null
            ? this.props.modifyProductRow[0].Description
            : '',
        ]);
        this.props.changeAdDescription([
          this.props.modifyProductRow[0].Description != null
            ? this.props.modifyProductRow[0].Description
            : '',
        ]);
  
        if (this.props.modifyProductRow[0].CategoryId != null) {
          this.props.changeSelectedCategories([
            {
              value: this.props.modifyProductRow[0].CategoryId,
              label: this.props.modifyProductRow[0].CategoryTitle,
            },
          ]);
          this.props.changeHasErrorCategory(false);
        }
        //
        getAdAccountsByProductApiAdmin(
          this.props,
          this.props.modifyProductRow[0].value,
        );
        getCompanyApiAdmin(this.props, this.props.selectedTenants);
        if (this.props.modifyProductRow[0].Company !== '') {
          this.props.changeSelectedCreateProductCompanies([
            {
              value: this.props.modifyProductRow[0].CompanyTagId,
              label: this.props.modifyProductRow[0].Company,
            },
          ]);
          this.props.changeHasErrorCompany(false);
          
          getBrandApiAdmin(this.props, this.props.selectedTenants, [
            {
              value: this.props.modifyProductRow[0].CompanyTagId,
              label: this.props.modifyProductRow[0].Company,
            },
          ]);
          if (this.props.modifyProductRow[0].Brand !== '') {
            this.props.changeHasErrorBrand(false);
            this.props.changeSelectedCreateProductBrands([
              {
                value: this.props.modifyProductRow[0].BrandTagId,
                label: this.props.modifyProductRow[0].Brand,
              },
            ]);
          }
        }
      } else {
        if (this.props.selectedTenants 
          &&  this.props.selectedTenants.length
          &&  this.props.selectedTenants.length>0) {
            if (sessionStorage.getItem('onBoardingStatus')==="4") {
              let preset = this.props.selectedTenants[0].label;
              this.props.changeInitTitle([preset]);
              this.props.changeInitDescription([preset]);
              this.props.changeHasErrorTitle(false);
              this.props.changeHasErrorDescription(false);
              getProductAdminApi(this.props, this.props.selectedTenants);
              //getBrandApiAdmin(this.props, "");
            }
        }       
      }
      this.props.changeHasError(
        this.props.hasErrorTitle ||
          this.props.hasErrorDescription ||
          this.props.hasErrorCategory ||
          this.props.hasErrorAdAccount,
      );
      sendGAPageView('Product page');
    },

    componentWillUnmount() {
      //window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
    componentDidUpdate(prevProps, prevState) {
      let newHasError =
        this.props.hasErrorTitle ||
        this.props.hasErrorDescription ||
        this.props.hasErrorCategory ||
        this.props.hasErrorAdAccount;
      if (newHasError !== this.props.hasError) {
        this.props.changeHasError(newHasError);
      }
      // react to update properties
      // 1:selectorCreateProductCompany
      if (JSON.stringify(this.props.selectorCreateProductCompany)!==JSON.stringify(prevProps.selectorCreateProductCompany)) {       
       //  debugger; 
        if (this.props.selectorCreateProductCompany.length
          && this.props.selectorCreateProductCompany.length>0
          && sessionStorage.getItem('onBoardingStatus')==="4") {
          // set it as default
          let newComSelected = [
            {
              value: this.props.selectorCreateProductCompany[0].value,
              label: this.props.selectorCreateProductCompany[0].label,
            }
          ]; 
          this.props.changeSelectedCreateProductCompanies();
          this.props.changeHasErrorCompany(false);
          getBrandApiAdmin(this.props, this.props.selectedTenants, newComSelected);
        }
      }
      if (JSON.stringify(this.props.selectorCreateProductBrand)!==JSON.stringify(prevProps.selectorCreateProductBrand)) {
        if (this.props.selectorCreateProductBrand.length 
          && this.props.selectorCreateProductBrand.length>0
          && sessionStorage.getItem('onBoardingStatus')==='4') {
            let brandSelected = [
              {
                value: this.props.selectorCreateProductBrand[0].value,
                label: this.props.selectorCreateProductBrand[0].label,
              }
            ];
            this.props.changeSelectedCreateProductBrands(brandSelected);
            this.props.changeHasErrorBrand(false);
          }
      }
    },
  }),
  withHandlers({
    setCreateClicked: props => event => {
      let accountId = props.selectedTenants[0].value;
      let categoryId = props.selectedCategories[0].value;
      let company = props.selectedCreateProductCompanies[0].value;
      let brand = props.selectedCreateProductBrands[0].value;
      let adAccounts = '';
      for (let i = 0; i < props.selectedAdminAdAccounts.length; i++) {
        if (i > 0 && props.selectedAdminAdAccounts.length > 1)
          adAccounts += ',';
        adAccounts += props.selectedAdminAdAccounts[i].value + '';
      }

      props.changeIsLoadingLayout(true);
      saveProductRequest(
        props.modifyProduct
          ? {
              productId: props.adProductId,
              categoryId: categoryId,
              title: props.adTitle[0],
              description: props.adDescription[0],
              adAccount: adAccounts,
              company: company,
              brand: brand,
            }
          : {
              accountId: accountId,
              categoryId: categoryId,
              title: props.adTitle[0],
              description: props.adDescription[0],
              adAccount: adAccounts,
              company: company,
              brand: brand,
            },
        props.modifyProduct,
        async (error, result) => {
          if (result !== undefined) {
            if (props.isDialog && props.showCreateProduct) {
              if (props.modifyProduct)
                alertPopUp(
                  trans('PRODUCT_MODIFIED_SUCCESS', props.lang),
                  props,
                  false,
                  false,
                  () => {
                    returnToModifyPage(props);
                  },
                );
              else if (props.isDialog) {
                let Product = [];
                if (result.length && result.length > 0) {
                  Product = result.map(({ Id, Title }) => ({
                    value: Id,
                    label: Title,
                  }))[0];
                } else Product = { value: result.Id, label: result.Title };

                getProductAdminApiFiltersBrandandCompany(
                  props,
                  props.selectedTenants,
                  [Product],
                  false,
                  true,
                  props.selectedAdminAdAccounts,
                );

                props.changeShowCreateProduct(false);
              } else {
                getProductAdminApiFilters(props, props.selectedTenants, [
                  {
                    value: result.Id,
                    label: result.Title,
                  },
                ]);
                props.changeShowCreateProduct(false);
              }
            } else {
              let msg = trans('PRODUCT_CREATED_SUCCESS', props.lang);
              msg = msg.replace("$NAME", props.adTitle[0]);
              alertPopUp(
                msg,
                props,
                false,
                false,
                async () => {
                  if (
                    sessionStorage.getItem('onBoardingStatus') !==
                    onBoardingOK.toString()
                  )
                    await validateOnBoardingEnd(props);
                  else reloadProperties(props);
                },
              );
            }
          } else if (error) {
            if (error === '409')
              alertPopUp(
                trans('ERROR_MESSAGE_2', props.lang),
                props,
                true,
              );
            else
              alertPopUp(
                trans('ERROR_MESSAGE_1', props.lang),
                props,
                true,
              );
          }
          props.changeIsLoadingLayout(false);
        },
      );
    },
  }),
);

export default mycompose(View);
