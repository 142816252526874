import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import Widget from '../../../components/Widget';
import Select from 'react-select';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { getPixelStatsAdminApi } from '../../../utils/functions';
import trans from './../../../i18n/tran';

const Pixels = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <Widget
      overflow={true}
      focusable={
        <Focusable id={props.focusId} refList={props.refList}></Focusable>
      }
      title={
        <div>
          <div style={{ height: '1.5rem' }}>
            <div> Conversion</div>
            {props.hasErrorPixel && props.showAdErrors ? (
              <div
                style={{
                  color: 'red',
                }}
              >
                <div className={classes.errorIcon}>
                  <ErrorIcon></ErrorIcon>
                </div>
                <div className={classes.warningMessage}>
                  {props.selectedPixels.length === 0
                    ? trans('NEW_CAMPAIGN_PIXEL_SELECT', props.lang)
                    : trans('NEW_CAMPAING_EVENT_SELECT', props.lang)}
                </div>
              </div>
            ) : (
              ''
            )}
            <br />
          </div>
        </div>
      }
      bodyClass={classes.tableWidget}
      disableWidgetMenu={true}
    >
      <div style={{ padding: '50px 0px' }}>
        <div style={{ width: '100%' }}>
          {' '}
          <div>
            <Typography>Pixels</Typography>
            <Select
              id={'Pixel'}
              styles={theme.reactSelect}
              options={props.selectorPixel}
              value={props.selectedPixels}
              onChange={e => {
                props.changeHasErrorPixel(true);
                props.changeSelectedPixels([e]);
                props.changeSelectedPixelEvents([]);
                props.changeSelectedUsedPixelEvents([]);
                getPixelStatsAdminApi(props, [e]);
              }}
              isDisabled={
                props.selectedAdminAdAccounts.length === 0 ||
                props.selectorPixel.length === 0
              }
              placeholder={
                props.selectedAdminAdAccounts.length === 0
                  ? trans('SELECT_AD_ACCOUNT', props.lang)
                  : props.selectorPixel.length === 0
                  ? trans('NEW_CAMPAIGN_MESSAGE17', props.lang)
                  : trans('NEW_CAMPAIGN_PIXEL_SELECT', props.lang)
              }
            ></Select>
          </div>
        </div>

        <div style={{ width: '100%' }}>
          {' '}
          <div>
            <Typography>{trans('NEW_CAMPAIGN_PIXEL_ACTIVE', props.lang)}</Typography>
            <Select
              id={'PixelEvent'}
              options={props.selectorUsedPixelEvent}
              value={props.selectedUsedPixelEvents}
              styles={theme.reactSelect}
              onChange={e => {
                props.changeHasErrorPixel(false);
                props.changeSelectedUsedPixelEvents([e]);
                props.changeSelectedPixelEvents([]);
              }}
              isDisabled={props.selectedPixels.length === 0}
              placeholder={
                props.selectedPixels.length === 0
                  ? trans('NEW_CAMPAIGN_PIXEL_SELECT', props.lang)
                  : trans('NEW_CAMPAING_EVENT_SELECT', props.lang)
              }
            ></Select>
          </div>
        </div>

        <div style={{ width: '100%' }}>
          {' '}
          <div>
            <Typography>{trans('NEW_CAMPAIGN_PIXEL_INACTIVE', props.lang)}</Typography>
            <Select
              id={'PixelEvent'}
              options={props.selectorPixelEvent}
              value={props.selectedPixelEvents}
              styles={theme.reactSelect}
              onChange={e => {
                props.changeHasErrorPixel(false);
                props.changeSelectedPixelEvents([e]);
                props.changeSelectedUsedPixelEvents([]);
              }}
              isDisabled={props.selectedPixels.length === 0}
              placeholder={
                props.selectedPixels.length === 0
                  ? trans('NEW_CAMPAIGN_PIXEL_SELECT', props.lang)
                  : trans('NEW_CAMPAING_EVENT_SELECT', props.lang)
              }
            ></Select>
          </div>
        </div>
      </div>
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(Pixels);
