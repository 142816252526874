export default {
  LOGIN: { en: 'Login', es: 'Ingresar' },
  HELP: {en:'Help', es:'Ayuda'},
  APP: {en:'App', es:'Aplicación'},
  LOGIN_WITH_FACEBOOK: {es: 'Ingresa con Facebook', en: 'Login with Facebook'},
  BUSINESS: { en: 'Business', es: 'Cuenta Administradora' },
  TITLE: { es: 'Título', en: 'Title' },
  DESCRIPTION: { es: 'Descripción', en:"Description" },
  PRODUCT: { es: 'Producto', en:"Product" },
  DATE: { es: 'Fecha', en:"Date" },
  STATUS: { es: 'Estado', en:"Status" },
  ID: { es: 'ID', en:"ID" },
  NAME: { es: 'Nombre', en: 'Name' },
  ACTION: { es: 'Acción', en: 'Action' },
  ACTIONS: {es:'Acciones', en:'Actions'},
  WATCH: { es: 'Ver', en: 'Watch' },
  IMAGE: {es:'Imagen', en:'Image'},
  IMAGES: {es:'IMÁGENES', en:'Images'},
  IMPRESSIONS: {es:'Impresiones', en:'Impressions'},
  ADS: {es:'Anuncios', en:'Ads'},
  AD_ACCOUNT: {es:'Cuenta Publicitaria', en:'Ad Account'},
  AD_ACCOUNTS: {es:'Cuentas Publicitarias', en:'Ad Accounts'},
  CLOSE: {es:"Cerrar", en:"Close"},
  AGE: {es:"Edad", en:"Age"},
  GENDER: {es:'Género', en:'Gender'},
  GENDERS: {es:'Géneros', en:'Genders'},
  TREND: {es:'Tendencia', en:'Trend'},
  LOAD: {es:'Cargar', en:'Load'},
  AUTO_LOAD: {es:'Auto Cargar', en:'Auto Load'},
  FEMALE: {es:"Femenino", en:"Female"},
  MALE: {es:"Masculino", en:"Male"},
  UNKNOWN: {es:"Otro", en: "Other"},
  OTHERS: {es:"Otros", en:"Others"},
  GLOSSARY: {es:'GLOSARIO', en:'Glossary'},
  DELTA_ACTIONS: {es:'Optimizaciones', en:'Optimizations'},
  ACTIONS_PERCENTAGE: {es:'Acciones %', en:'Actions %'},
  DELTA_CPA: {es:'Opt. CPA', en:'CPA Opt.'},
  COST: {es:'Costo', en:'Cost'},
  CLEAN: {es:'Limpiar', en:'Clean up'},
  MEN: {es:'Hombres', en:'Men'},
  WOMEN: {es:'Mujeres', en:'Women '},
  BOTH: {es:'Ambos', en:'Both'},
  WITHOUT_BUTTON: {es:'Sin Botón', en:'Without Button'},
  PREVIEW: {es:'Vista Previa', en:'Preview'},
  ACCEPT: {es:'Aceptar', en:'Accept'},
  CURRENCY: {es:'Moneda', en:'Currency'},
  PLACES:{es:'Lugares', en:'Places'},
  MAP:{es:'Mapa', en:'Map'},
  VALUE:{es:'Valor', en:'Value'},
  TYPE: {es:'Tipo', en:'Type'},
  FREQUENCY: {es: 'Frecuencia', en:'Frequency '},
  DELETE: {es:'Eliminar', en:'Delete'},
  CANCELL: {es:'Cancelar', en:'Cancel'},
  APP_STORE: {es:'Tienda de Aplicaciones', en:'App Store'},
  CAMPAIGN_OBJECTIVE: {es:'Objetivo de campaña', en:'Campaign objective'},
  BUDGET: {es:'Presupuesto', en:'Budget'},
  // NUMBERS
  THOUSAND: {es:'Mil', en:'Thousand'},
  MILLIONS: {es:'Millones', en: 'Millions'},
  BILLION: {es:'Mil Millones', en:'Billion'},
  TRILLION: {es:'Billones', en:'Trillion'},
  TRILLIONS: {es:'Trillones', en:'Trillions'},
  TIME: {es:'Tiempo', en:'Time'},
  // CTA
  BUY: {es:'Comprar', en:'Shop Now'},
  SIGN_UP: {es:'Registrarte', en:'Sign up'},
  SUBSCRIBE: {es:'Suscribirse', en:'Subscribe'},
  SEE_MORE: {es:'Ver Más', en:'Watch more'},
  LISTEN: {es:'Escuchar', en:'Listen Now'},
  APPLY: {es:'Postularte', en:'Apply Now'},
  CONTACT_US: {es:'Contactarnos', en:'Contact us'},
  DOWNLOAD: {es:'Descargar', en:'Download'},
  GET_OFFER: {es:'Obtener Oferta', en:'Get Offer'},
  QUOTE: {es:'Cotizar', en:'Get Quote'},
  SEE_FUNCTIONS: {es:'Ver Funciones', en:'Get Showtimes'},
  MORE_INFO: {es:'Más Información', en:'Learn More'},
  ASK_DATE: {es:'Solicitar Cita', en:'Request Time'},
  PLAY: {es:'Jugar', en:'Play Game'},
  SEE_MENU: {es:'Ver Menú', en:'See Menu'},
  SEND_MESSAGE: {es:'Enviar Mensaje', en:'Send Message'},
  NO_BUTTON: {es:'Sin Botón', en:'No Button'},
  INSTALL: {es:'Instalar', en:'Install Now'},
  LEARN_MORE: {es:'Learn more', en:'Más Información'},
  VIDEO: {es:'Video', en:'Video'},
  COUNTRY:  {es:'País', en:'Country'},
  COUNTRY_GROUP: {es:'Grupo de Paises', en:'Country Group'},
  REGION:  {es:'Región', en:'Region'},
  ZIP:  {es:'Código Postal', en:'Zip'},
  ALL:  {es:'Todos', en:'All'},
  CITY:  {es:'Ciudad', en:'City'},
  SEARCH: {es:"Buscar", en:'Search'},
  ADD: {es:'Agregar',  en:'Add'},
  START: {es:'Inicio',  en:'Start'},
  END: {es:'Fin',  en:'End'},
  INCLUDED: {es:"Incluido", en:"Included"},
  EXCLUDED: {es:'Excluido', en:'Excluded'},
  CARDINAL_POINT: {es:'Punto Cardinal', en:'Cardinal point'},
  LATITUDE: {es:'Latitud', en: 'Latitude'},
  LONGITUDE: {es:'Longitud', en: 'Longitude'},
  RADIUS: {es:'Radio', en: 'Radius'},
  CAMPAIGN: {es:'Campaña', en:'Campaign'},
  OBJECTIVE: {es:'Objetivo', en:'Objective'},
  AUDIENCE: {es:"AUDIENCIA", en:'Audience'},
  
  /* RELATION SHIP STATUS */
  REL_1: {es:'Soltero (a)', en:'Single'},
  REL_2: {es:'Tiene una relación', en:'In a relationship'},
  REL_3: {es:'Casado (a)', en:'Married'},
  REL_4: {es:'Comprometido (a)', en:'Engaged'},
  REL_5: {es:'No especificado', en:'No specified'},
  REL_6: {es:'Mantiene una unión civil', en:'Maintains a civil union '},
  REL_7: {es:'Tiene una pareja de hecho', en:'Has a domestic partner '},  
  REL_8: {es:'Tiene una relación abierta', en:'Has an open relationship '},
  REL_9: {es:'Es complicado', en:'It\'s complicated'},
  REL_10: {es:'Separado (a)', en:'Separated'},
  REL_11: {es:'Divorciado (a)', en:'Divorced'},
  REL_12: {es:'Viudo (a)', en:'Widow (er)'},
  /* EDUCACION STATUS */
  EDU_1: {es:'Secundario en curso', en:'Secondary in progress'},
  EDU_2: {es:'Estudios universitarios en curso', en:'Ongoing university studies'},
  EDU_3: {es:'Estudios universitarios completos', en:'Complete university studies'},
  EDU_4: {es:'Secundario completo', en:'Complete secondary'},
  EDU_5: {es:'Estudios universitarios incompletos', en:'Incomplete university studies'},
  EDU_6: {es:'Título intermedio', en:'Intermediate title'},
  EDU_7: {es:'Posgrado en curso', en:'Ongoing postgraduate'},
  EDU_8: {es:'Posgrado incompleto', en:'Incomplete graduate'},
  EDU_9: {es:'Maestría', en:'Master\'s degree'},
  EDU_10: {es:'Título profesional', en:'Job title'},
  EDU_11: {es:'Doctorado', en:'Doctorate'},
  EDU_12: {es:'Sin especificar', en:'Unspecified'},
  EDU_13: {es:'Secundario incompleto', en:'Incomplete secondary'},

  // PLACEMENTS
  PLACEMENT1: {es:'Sección de Noticias de Facebook', en:'Facebook News Feed'},
  PLACEMENT2: {es:'En artículos', en:'In articles'},
  PLACEMENT3: {es:'Facebook Marketplace', en:'Facebook Marketplace'},
  PLACEMENT4: {es:'Secciones de videos de Facebook', en:'Facebook video sections'},
  PLACEMENT5: {es:'Historias de Facebook', en:'Facebook Stories '},
  PLACEMENT6: {es:'Resultados de la búsqueda de Facebook', en:'Facebook search results'},
  PLACEMENT7: {es:'Columna derecha de Facebook', en:'Right column of Facebook '},
  PLACEMENT8: {es:'Noticias de Instagram', en:'Instagram news'},
  PLACEMENT9: {es:'Historias de Instagram', en:'Instagram Stories '},
  PLACEMENT10: {es:'Sección “Explorar” de Instagram', en:'"Explore" section of Instagram'},

  // FB OBJECTIVES
  LEAD_GENERATION: {es:'Generación de clientes potenciales', en:'Lead Generation'},
  LINK_CLICKS: {es:'Tráfico', en:'Traffic'},
  CONVERSIONS: {es:'Conversiones', en:'Conversions'},
  APP_INSTALLS: {es:'Descargas de aplicaciones', en:'App Installs'},
  MESSAGES: {es:'Mensajes', en:'Messages'},

  // FB SEGEMENTATION
  INTERESTS: {es:'Intereses', en:'Interests'},
  BEHAVIORS: {es:'Comportamientos', en:'Behaviors'},
  LIFE_EVENTS: {es:'Eventos de Vida', en:'Life Events'},
  INDUSTRIES: {es:'Industrias', en:'Industries'},
  FAMILY_STATUSES: {es:'Estatus Familiar', en:'Family Status'},
  RELATIONSHIP_STATUSES: {es:'Situaciones Sentimentales', en:'Sentimental situations'},
  EDUCATION_STATUSES: {es:'Educación', en:'Education'},
  

  COST_LIMIT: {es:'Límite de Coste', en:'Cost Limit'},
  BID_LIMIT: {es:'Límite de Puja', en:'Bid Limit'},
  
  // PLACE PICKER
  PLACE_PICKER_LABEL1: {es:'Segmentación por Lugar', en:'Segmentation by Place '},

  // INTERESTS
  INTEREST_TITLE: {es:'Segmentación detallada', en:'Detailed segmentation'},
  INTEREST_MESSAGE1: {es:'Se Utilizarán los valores de la Audiencia Grabada', en:'Recorded Audience values will be used'},
  // GENDER AGE PICKER
  AGE_GENDER_TITLE: {es:'Edades y Género', en:'Ages and Gender '},
  AGE_GENDER_TITLE_RANGE: {es:'Seleccione Rango', en:'Select Range '},
  AGES: {es:'Edades', en:'Ages '},
  // ASSETS
  UPLOAD_MESSAGE1: {es:'Arrastra y suelta archivos aquí', en:'Drag and drop files here'},
  UPLOAD_MESSAGE2: {es:'o selecciona desde tu computadora', en:'or select from your computer'},

  DELTA_COST: {es:'Opt. Cost', en:'Opt. Cost'},
  AD_ACCOUNT_SELECT: {es:"Seleccione Cuenta Publicitaria", en:"Select an Ad Account"},
  BUSINESS_SELECT: {es:'Favor Seleccione Negocio Administrador', en:'Please Select Business Administrator'},
  UNIQUE_KEY: {es:'Llave única', en:'Unique Key'},

  AD_ACCOUNTS_PAGE_SUB1: {
    es:'Cuentas Asociadas a ',
    en:'Ad Accounts Associated to '
  },
  AD_ACCOUNTS_PAGE_COL_NAME: {
    es:'Nombre',
    en:'Name'
  },
  AD_ACCOUNTS_PAGE_COL_ASSOC: {
    es:'Asociar con ALY ',
    en:'Associate with '
  },
  AD_ACCOUNTS_PAGE_COL_REMOVE: {
    es:'Eliminar',
    en:'Remove'
  },
  AD_ACCOUNTS_PAGE_ERROR1: {
    es:'No hay Cuentas Publicitarias Asociadas a Negocio',
    en:'No Ad Accounts associated yet'
  },
  AD_ACCOUNTS_PAGE_ERROR2: {
    es:'No se ha podido realizar la operación. Para agregar a ALY como un socio comercial el ad account que desea agregar debe estar manejado por un negocio (Business Manager). Así mismo verifique los permisos del usuario de Facebook ya que este debe tener un rol de administrador.',
    en:'The operation could not be performed. To add ALY as a business partner the ad account must be managed by a business (Business Manager). Also check the permissions of the facebook user since he must have an administrator role.'
  },
  AD_ACCOUNTS_PAGE_MY: {
    es:'Mis Cuentas',
    en:'My Accounts'
  },
  AD_ACCOUNTS_PAGE_MESSAGE1: {
    es:'ASOCIADA A OTRO NEGOCIO ADMINISTRADOR',
    en:'ASSOCIATED WITH ANOTHER ADMINISTRATOR ACCOUNT'
  },
  AD_ACCOUNTS_PAGE_MESSAGE2: {
    es:'ASOCIADA',
    en:'ASSOCIATED'
  },
  AD_ACCOUNTS_PAGE_MESSAGE3: {
    es:'Asociar con ALY',
    en:'Partner with ALY'
  },
  AD_ACCOUNTS_PAGE_MESSAGE4: {
    es:'No se encontraron cuentas Adicionales',
    en:'No Additional Accounts Found '
  },
  AD_ACCOUNTS_PAGE_MESSAGE5: {
    es:'Se ha agregado exitosamente el ad account para administración de ALY',
    en:'The ad account for ALY administration has been successfully added. '
  },
  AD_ACCOUNTS_PAGE_MESSAGE6: {
    es: '¿Desea Eliminar Cuenta?',
    en:'Do you want to delete this account?'
  },
  AD_ACCOUNTS_PAGE_MESSAGE7: {
    es: 'Cuenta Eliminada',
    en: 'Account Deleted'
  },
  AD_ACCOUNTS_PAGE_MESSAGE8: {
    es: 'Asociación de Cuenta Publicitaria exitosa',
    en: 'Successful Ad Account Association'
  },

  AD_ACCOUNTS_PAGE_MESSAGE9: {
    es: 'No tiene permisos para realizar esta acción',
    en: 'You do not have permissions to perform this action'
  },

  AD_ACCOUNTS_PAGE_MESSAGE10: {
    es: 'Cuenta Publicitaria ya Asociada a Negocio Administrador',
    en: 'Ad Account Already Associated with Business Administrator'
  },

  AD_ACCOUNTS_PAGE_MESSAGE11: {
    es: '',
    en: ''
  },


  CAMPAIGNS: {es:'Campañas', en:'Campaigns'},
  CAMPAIGNS_SELECT: {es:"Seleccione Campaña", en:"Select Campaign"},
  NO_INFO:{es:"No hay información para mostrar", en:"No information to display"},
  NO_INFO_DROPDOWN: {
    en:'There is no information for the above filters',
    es:'No hay información para los filtros anteriores'
  },
  ELEMENTS_PER_PAGE: {es:'Elementos por página', en:'Rows per page'},
  COMPANY: {es:'Empresa', en:'Company'},
  BRAND: {es: 'Marca', en:'Brand'},
  CAMPAIGN_TYPE: {es:"Tipo de Campaña",en:'Campaign Type'},
  LOADING: {es:'Cargando', en:'Loading'},
  CAMPAIGN_NAME: {es:'Nombre de Campaña', en:'Campaign Name'},
  SELECT_OPTIMIZATION_TYPE: {es:'Seleccione Tipo de Optimización', en:'Select Optimization Type'},
  SELECT_CAMPAIGN_TYPE: {es:'Seleccione Tipo de Campaña', en:'Select Campaign Type'},
  SELECT_COMPANY: {es:'Seleccione Empresa', en:'Select Company'},
  SELECT_BRAND: {es:'Seleccione Marca', en:'Select Brand'},
  SELECT_AD_ACCOUNT: {es:'Seleccione Cuenta Publicitaria', en:'Select Ad Account'},
  AGE_RANGE: {es:'Rango de Edades', en:'Age Range'},
  ADS_WIDGET1_HEAD: {es:'Resultados por Imagen', en:'Results by Image'},  
  ADS_WIDGET_FOOT: {es:'Total de Acciones', en:'Total'},
  ADS_WIDGET2_HEAD: {es:'Combinaciones de textos de anuncios con mayor probabilidad de éxito', en:'Copywriting most likely to succeed'},
  ADS_WIDGET2_TITLE1: {es:'Título Creativo', en:'Head Line'},
  ADS_WIDGET2_TITLE2: {es:'Cuerpo del Anuncio	', en:'Primary Text'},
  ADS_WIDGET3_HEAD: {es:'Resultados por Edad', en:'Results by Age'},
  ADS_WIDGET3_TITLE1: {es:'Rango de Edades', en:'Age Range'},
  ADS_WIDGET4_HEAD: {es:'Género con mayor probabilidad de conversión', en:'Gender most likely to convert'},
  ADS_WIDGET4_TITLE1: {es:'Género con Potencial', en:'Gender with Potential'},
  ADS_WIGET5_HEAD: {es:"Optimizaciones en plataforma", en:"Platform optimizations"},
  ADS_WIGET6_HEAD: {es:"ALY AI - DETECTOR DE MEJOR EDAD Y GÉNERO", en:"ALY AI - BEST AGE AND GENDER DETECTOR"},
  ADS_WIDGET5_TITLE1: {es:"Campaña", en:"Campaign"},
  ADS_WIDGET5_TITLE2: {es:"Id Campaña", en:"Campaign Id"},
  ADS_WIDGET5_TITLE3: {es:"Acciones", en:"Actions"},
  CAMPAIGN_EXT_ID: { es: 'Id Campaña', en:"Campaign Id" },
  ADSET_EXT_ID: { es: 'Id AdSet', en:"Ad Set Id" },
  ERROR: { es: 'Error', en:"Error" },
  ERROR_1: {es:'Se ha producido un error', en:'An error has occurred'},
  ALGO_CORR_NAME: {es:"Ver", en:"See"},
  ALGO_CORR_MESSAGE1: {es:'Detalle de optimizaciones', en:'Detail of optimizations'},
  LAST_TRY: { es: 'Ultimo Intento', en:"Last Try" },
  REPORT_VIEWER_RUN: { es: 'Ver Reporte', en: 'Run Report' },
  REPORT_VIEWER_PARAMETERS: { es: 'Parámetros', en: 'Parameters' },
  REPORT_VIEWER_TITLE: {es:"Reportes", en:"Reports"},
  CREATED_AT: { es: 'Creación', en:"Created" },
  CHOOSE_A_BUSINESS: {
    en: 'Please select a Business',
    es: 'Favor Seleccione Cuenta Administradora',
  },
  SELECT_PLACEHOLDER: { en: 'Select...', es: 'Seleccionar...' },
  SELECT_NO_OPS: { en: 'No Options', es: 'No hay coincidencias' },
  SELECT_COUNTRY_PLACEHOLDER: { en: 'Select a Country', es: 'Seleccione País' },
  START_DATE: { en: 'Start Date', es: 'Fecha de Inicio' },
  START_DATE_INVALID: {es:'Fecha Inicio Inválida', en:'Start Date Invalid'},
  END_DATE: { en: 'End Date', es: 'Fecha de Fin' },
  END_DATE_INVALID: {es:'Fecha Fin Inválida', en:'Start Date Invalid'},
  ERROR_MESSAGE_1: {
    es:'Ha ocurrido un error, porfavor intente de nuevo.',
    en:'An error has occurred. Please try again.'
  },
  ERROR_MESSAGE_2: {
    es:'Producto ya existente para el Negocio Administrador seleccionado, porfavor intente de nuevo.',
    en:'Existing product for the selected Business Administrator, please try again.'
  },
  ERROR_MESSAGE_3: {
    es:'Error de comunicación 500, pruebe nuevamente o contacte al administrador',
    en:'Communication error 500, try again or contact the administrator'
  },
  ERROR_MESSAGE_4: {
    es:'Error 400, pruebe nuevamente o contacte al administrador',
    en:'Error 400, try again or contact administrator'
  },
  ERROR_MESSAGE_5: {
    es:'No se encotro información para los filtros seleccionados',
    en:'No information was found for the selected filters'
  },
  STANDARD_INDICATORS: {
    en: 'Standard Indicators',
    es: 'Indicadores Estándard',
  },
  ALY_INDICATORS: {
    en: "ALY Indicators",
    es: "Indicadores ALY"   
  },
  CONVERSION_INDICATORS: {
    en: "Conversion Indicators",
    es: "Indicadores de Conversión"
  },
  ALY_INDICATORS_LEARNING: {
    en: 'Do not make modifications, campaign in learning period ',
    es: 'No realizar modificaciones, campaña en periodo de aprendizaje',
  },
  ALY_INDICATORS_NO_CHANGE: {
    en: 'No modifications, stable campaign.',
    es: 'No realizar modificaciones, campaña estable.',
  },
  ALY_INDICATORS_CPC_RANGE_TITLE: { en: 'CPC Range', es: 'Rango de CPC' },
  ALY_INDICATORS_CPC_PERF_TITLE: {
    en: 'CPC Performance',
    es: 'Rendimiento de CPC',
  },
  ALY_INDICATORS_CPC_TEXT: {
    en: 'Your cost per click will be between $AVG and $MAX',
    es: 'Tu costo por clic estará entre $AVG y $MAX',
  },
  ALY_INDICATORS_CPC_RANGE_HELP: {
    en: 'Your CPC will be within this range. This is what it will cost for each user to click on your ad.',
    es: 'Tu CPC estará entre este rango. Esto es lo que costará que cada usuario haga clic en tu anuncio.',
  },
  ALY_INDICATORS_CPC_PERF_HELP: {
    en: 'On a weekly basis, this is the performance of users who see and click on the ad.',
    es: 'ℹ En base a la semana este es el rendimiento de los usuarios que ven el anuncio y hacen clic en él.',
  },
  ALY_INDICATORS_CPC_GOOD: {
    en: 'The cost per click has been improving',
    es: 'El costo por cada clic ha ido mejorando',
  },
  ALY_INDICATORS_CPC_BAD: {
    en: 'The cost per click has become more expensive',
    es: 'El costo por cada clic se ha encarecido',
  },

  ALY_INDICATORS_CPA_RANGE_TITLE: { en: 'CPC Range', es: 'Rango de CPA' },
  ALY_INDICATORS_CPA_PERF_TITLE: {
    en: 'CPA Performance',
    es: 'Rendimiento de CPA',
  },
  ALY_INDICATORS_CPA_TEXT: {
    en: 'Your cost per share will be between $AVG and $MAX',
    es: 'Tu costo por acción estará entre $AVG y $MAX',
  },
  ALY_INDICATORS_CPA_DESC: {
    en: 'Your CPA will be in the following range. This is what it will cost for each person to meet the campaign objective (lead generation, traffic, conversions, messages, app downloads)',
    es: 'Tu CPA estará en el siguiente rango. Esto es lo que costará que cada persona cumpla con el objetivo de la campaña (generación de clientes potenciales, tráfico, conversiones, mensajes, descargas de aplicaciones)',
  },
  ALY_INDICATORS_CPA_GOOD: {
    en: 'The cost per action has been improving ',
    es: 'El costo por cada acción ha ido mejorando',
  },
  ALY_INDICATORS_CPA_BAD: {
    en: 'The cost per action has increased',
    es: 'El costo por cada acción se ha encarecido',
  },
  ALY_INDICATORS_CPA_RANGE_HELP: {
    en: 'Your CPA will be in the following range. This is what it will cost for each person to meet the campaign objective (lead generation, traffic, conversions, messages, app downloads)',
    es: 'Tu CPA estará en el siguiente rango. Esto es lo que costará que cada persona cumpla con el objetivo de la campaña (generación de clientes potenciales, tráfico, conversiones, mensajes, descargas de aplicaciones)',
  },
  ALY_INDICATORS_CPA_PERF_HELP: {
    en: 'Based on the week, this is the performance of the users who see the ad and take an action on it.',
    es: 'En base a la semana este es el rendimiento de los usuarios que ven el anuncio y hacen una acción en él.',
  },

  ALY_INDICATORS_CTR_RANGE_TITLE: { en: 'CTR Range', es: 'Rango de CTR' },
  ALY_INDICATORS_CTR_PERF_TITLE: {
    en: 'CTR Performance',
    es: 'Rendimiento de CTR',
  },
  ALY_INDICATORS_CTR_TEXT: {
    en: 'Your click through rate will be between $MIN% and $AVG% ',
    es: 'Tu click through rate estará entre $MIN % y $AVG %',
  },
  ALY_INDICATORS_CTR_RANGE_HELP: {
    en: 'Your CTR will be in the following range. This is what it will cost for each user to click on your ads in relation to ad impressions.',
    es: 'Tu CTR estará en el siguiente rango. Esto es lo que costará que cada usuario le de clic a tus anuncios en relación a las impresiones del anuncio.',
  },
  ALY_INDICATORS_CTR_PERF_HELP: {
    en: 'On a weekly basis, this is the performance of users who see and click on the ad.',
    es: 'En base a la semana este es el rendimiento de los usuarios que ven el anuncio y hacen clic en él.',
  },
  ALY_INDICATORS_CTR_GOOD: {
    en: 'Every day more users click on your ads!',
    es: '¡ Cada día más usuarios hacen clic en tus anuncios !',
  },
  ALY_INDICATORS_CTR_BAD: {
    en: 'The number of users who clicked on your ads has dropped. Check your ads are attractive (images, videos, texts, titles).',
    es: 'Ha bajado la cantidad de usuarios que dieron clic en tus anuncios.  Revisa tus anuncios sean atractivos (imágenes, videos, textos, títulos).',
  },

  ALY_INDICATORS_CVR_RANGE_TITLE: { en: 'CVR Range', es: 'Rango de CVR' },
  ALY_INDICATORS_CVR_PERF_TITLE: {
    en: 'CVR Performance',
    es: 'Rendimiento de CVR',
  },
  ALY_INDICATORS_CVR_TEXT: {
    en: 'Your conversion rate will be between $AVG % and $MAX %',
    es: 'Tu tasa de conversión estará entre $AVG % y $MAX %',
  },
  ALY_INDICATORS_CVR_RANGE_HELP: {
    en: 'Your CVR will be in the following range. This is the number of users who, after clicking on the ad, completed the advertising action.',
    es: 'Tu CVR estará en el siguiente rango. Esta es la cantidad de usuarios que después de hacer clic en el anuncio completaron la acción publicitaria.',
  },
  ALY_INDICATORS_CVR_PERF_HELP: {
    en: 'This is the number of users who, after clicking on the ad, completed the advertising action. ',
    es: 'Esta es la cantidad de usuarios que después de hacer clic en el anuncio completaron la acción publicitaria.',
  },
  ALY_INDICATORS_CVR_GOOD: {
    en: 'CVR conversion rate has improved!',
    es: '¡La tasa de conversión CVR ha mejorado !',
  },
  ALY_INDICATORS_CVR_BAD: {
    en: 'The conversion rate has dropped. Check that the size of the audience entered to ALY is not too small.',
    es: 'La tasa de conversión ha bajado. Revisar que el tamaño de la audiencia ingresada a ALY no sea muy pequeña.',
  },
  NEW_ACCOUNT_TITLE : {
    es:'Cuenta Administradora',
    en:'Administrator Account'
  },
  NEW_ACCOUNT_TAB1: {
    en:'New',
    es:'Nueva'
  },
  NEW_ACCOUNT_TAB2: {
    en:'Associate',
    es:'Asociar'
  },
  NEW_ACCOUNT_SUB_TITLE: { es: 'Creación de Cuenta Administradora', en:'Administrator Account Creation' },
  NEW_ACCOUNT_ACCOUNT_NAME_PLACEHOLDER: {
    es: 'Ingrese Nombre de la Cuenta Administradora',
    en: 'Enter Name of the Administrator Account'
  },
  NEW_ACCOUNT_FORM_HELPER_2: {
    es: 'Ingresar el nombre de la empresa con la que se trabajarán las campañas. De ser agencia tomar en cuenta el nombre de la agencia.',
    en: 'Enter the name of the company with which the campaigns will be worked. If it is an agency, take into account the name of the agency.'
  },
  NEW_ACCOUNT_FORM_CALLTOACTION: { es: 'Crear Nueva Cuenta Administradora', en:'Create New Administrator Account' },
  NEW_ACCOUNT_FORM_ASOC: {
    es: 'Asociación de Cuenta Administradora Por Llave Única',
    en:'Unique Key Management Account Association '
  },
  NEW_ACCOUNT_FORM_ASOC_PLACEHOLDER: {
    es: 'Ingrese Llave Única de la Cuenta Administradora',
    en: 'Enter Unique Key of the Administrator Account'
  },
  NEW_ACCOUNT_FORM_CALLTOACTION_2: { 
    es: 'Asociar A Cuenta Administradora',
    en: 'Associate To Administrator Account'
  },
  NEW_ACCOUNT_HELP: { 
    es: 'Requisitos recomendados para lanzar una campaña',
    en: 'Recommended requirements to launch a campaign' 
  },
  NEW_ACCOUNT_HELP_REFERENCE: { 
    es: 'Referencia',
    en: 'Reference' 
  },
  NEW_ACCOUNT_HELP_1: { 
    es: 'Creación',
    en: 'Creation'
  },
  NEW_ACCOUNT_HELP_2: {
    es: 'Administración de permisos en Fan Page existente',
    en: 'Permission management on existing Fan Page'
  },
  NEW_ACCOUNT_HELP_3: { 
    es: 'Administración de permisos en Cuenta Existente',
    en: 'Management of permissions in Existing Account'
  },
  NEW_ACCOUNT_HELP_4: { 
    es: 'Asignación de roles y permisos',
    en: 'Assignment of roles and permissions' 
  },
  NEW_ACCOUNT_HELP_5: { 
    es: 'Verificar metodo de pago',
    en: 'Check payment method'
  },
  NEW_ACCOUNT_HELP_6: { 
    es: 'Asignación de roles y permisos',
    en: 'Assignment of roles and permissions'
  },
  NEW_ACCOUNT_HELP_7: { 
    es: 'Crear un Administrador Comercial',
    en: 'Create a Commercial Administrator'
  },
  NEW_ACCOUNT_HELP_8: { 
    es: 'Crear o añadir una cuenta publicitaria',
    en: 'Create or add an ad account'
  },
  NEW_ACCOUNT_HELP_LG_HEAD_1: { 
    es: 'Fan Page en Facebook',
    en: 'Facebook Fan Page'
  },
  NEW_ACCOUNT_HELP_LG_HEAD_2: { 
    es: 'Cuenta de Instagram (opcional)',
    en: 'Instagram account (optional)'
  },
  NEW_ACCOUNT_HELP_LG_HEAD_3: {
    es: 'Cuenta publicitaria de Facebook / Business Manager',
    en: 'Facebook Ad Account / Business Manager'
  },
  NEW_ACCOUNT_HELP_LG_HEAD_4: {
    es: 'Método de pago activo dentro de la cuenta publicitaria en Facebook',
    en: 'Active payment method within the ad account on Facebook'
  },
  NEW_ACCOUNT_HELP_LG_HEAD_5: {
    es: 'Cuenta administrador de anuncios / Ad Account',
    en: 'Ads manager account / Ad Account'
  },
  NEW_ACCOUNT_HELP_LG_HEAD_6: { 
    es: 'Página Web (recomendado)',
    en: 'Website (recommended)' 
  },
  NEW_ACCOUNT_SUCCESS: {
    es:'Negocio Administrador creado correctamente',
    en: 'Business Administrator successfully created'
  },
  NEW_ACCOUNT_ERROR1: {
    es: 'Ya existe un Negocio Administrador con ese nombre',
    en:'There is already a Business Administrator with that name'
  },
  NEW_ACCOUNT_ERROR2: {
    es: 'Esta Cuenta Publicitaria ya fue asignada anteriormente',
    en:'This Ad Account has already been assigned previously'
  },
  NEW_ACCOUNT_ERROR3: {
    es: 'Llave única no válida',
    en:'Invalid unique key'
  },
  NEW_ACCOUNT_ASSOC_SUCCESS: {
    es: 'Asociación de Negocio Administrador exitosa',
    en:'Successful Administrator Business Association'
  },

  PAGE_BILLING_TITLE: {
    es:"Billing",
    en:"Billing"
  },
  PRODUCT_CREATE: { 
    es: 'Creación de Productos',
    en: 'Product Creation' 
  },
  PRODUCT_CREATE_ACTION: { 
    es: 'Modificar Producto',
    en: 'Modify Product'
  },
  PRODUCT_MODIFY: { 
    es: 'Modificación de Producto',
    en: 'Modify Product'
  },

  NEW_CAMPAIGN_TITLE: {es:'Creación de Campañas', en:'New Campaign'},
  NEW_CAMPAIGN_SUB_TITLE: {es:'Crear nueva Campaña', en:'Create New Campaign'},
  NEW_CAMPAIGN_OBJECTIVE_SELECT: {es:'Elige un objetivo de la campaña', en:'Choose a campaign objective'},
  NEW_CAMPAIGN_OBJECTIVE_PLACEHOLDER: {es:'Objetivos de campaña disponibles', en:'Available campaign objectives'},
  NEW_CAMPAIGN_FB_PLACEMENTS_SUB: {es:'Ubicaciones en plataforma Facebook', en:'Facebook platform placements'},
  NEW_CAMPAIGN_INST_PLACEMENTS_SUB: {es:'Ubicaciones en plataforma Instagram', en:'Instagram platform placements'},
  NEW_CAMPAIGN_PRODUCT_FILTER: {es:' Filtrar Productos', en:'Products Filter'},
  NEW_CAMPAIGN_PRODUCT_SELECT: {es:'Seleccione un Producto', en:'Select a Product'},
  NEW_CAMPAIGN_PRODUCT_CREATE: {es:'Crear Producto', en:'Create Product'},
  NEW_CAMPAIGN_PRODUCT: {es:'Producto', en:'Product'},
  NEW_CAMPAIGN_FANPAGE: {es:'Página de Facebook', en:'Facebook Fan Page'},
  NEW_CAMPAIGN_FANPAGE_SELECT: {es:'Seleccione una Página de Facebook', en:'Select a Facebook Page'},
  NEW_CAMPAIGN_INST_FORM: {es:'Formulario Instantáneo', en:'Instant Form'},  
  NEW_CAMPAIGN_FORM_SELECT: {es:'Seleccione un Formulario', en:'Select a Form'},
  NEW_CAMPAIGN_FORM_SELECT1: {es:'Seleccione Formulario Instantáneo', en:'Select Instant Form'},
  NEW_CAMPAIGN_INSTAGRAM_SELECT: {es:'Seleccione una Cuenta de Instagram', en:'Select an Instagram Account'},
  NEW_CAMPAIGN_INSTAGRAM: {es:'Cuenta de Instagram', en:'Instagram account'},
  NEW_CAMPAIGN_NAME: {es:'Nombre de Campaña', en:'Campaign Name'},
  NEW_CAMPAIGN_NAME2: {es:'Nombre de Campaña (Opcional)', en:'Campaign Name (Optional)'},
  NEW_CAMPAIGN_BODY: {es:"Texto Principal", en:"Body"},
  NEW_CAMPAIGN_BODY_ENTER: {es:"Ingrese Texto Principal", en:"Enter Body"},

  NEW_CAMPAIGN_TITLE_TITLE: {es:"Título del Anuncio", en:"Ad Title"},
  NEW_CAMPAIGN_TITLE_TITLE_ENTER: {es:"Ingrese Título del Anuncio", en:"Enter Ad Title"},
  NEW_CAMPAIGN_TITLE_TITLE_MIN: {es:"10 Caracteres Mínimo", en:"10 Characters Minimum"},

  NEW_CAMPAIGN_DESC: {es:"Descripción", en:"Description"},
  NEW_CAMPAIGN_DESC_ENTER: {es:"Ingrese Descripción", en:"Enter Description"},
  NEW_CAMPAIGN_DESC_WARN1: {es:"Ha Sobrepasado los 250 caracteres, se ha recortado el texto a 250 caracteres", en:"Exceeded 250 characters, text has been trimmed to 250 characters"},
  NEW_CAMPAIGN_DESC_WARN2: {es:"Ha Sobrepasado los 31 caracteres, es posible que no se muestre el resto en el anuncio", en:"You have Exceeded 31 characters, the rest may not be displayed in the ad"},

  NEW_CAMPAIGN_WARN_TRIM:{es:"Ha Sobrepasado los $MAX caracteres, se ha cortado el texto a $MAX caracteres",en:"Exceeded $MAX characters, text has been cut to $MAX characters"},
 
  NEW_CAMPAIGN_BUDGET: {es:'Presupuesto Total para la Campaña', en:'Total Budget for the Campaign '},
  NEW_CAMPAIGN_BUDGET_ENTER: {es:'Ingrese el Presupuesto', en:'Enter the Budget '},

  NEW_CAMPAIGN_DURATION: {es:'Programar duración de la campaña', en:'Schedule campaign duration'},

  NEW_CAMPAIGN_CTA: {es:'Llamada a la acción', en:'Call to Action'},
  NEW_CAMPAIGN_CTA_SELECT: {es:'Seleccione una Llamada a la acción', en:'Select a Call to Action '},
  NEW_CAMPAIGN_CTA_DEST: {es:'Seleccione un Destino de Mensaje', en:'Select a Message Destination '},

  NEW_CAMPAIGN_LINK: {es:'Enlace a sitio web', en:'Link to website '},
  NEW_CAMPAIGN_LINK_PARAM: {es:'Parámetros para Enlace', en:'Parameters for Link '},
  NEW_CAMPAIGN_CUSTOM_AUDIENCE: {es:'Audiencias Personalizadas', en:'Custom Audiences '},
  NEW_CAMPAIGN_CUSTOM_AUDIENCE_SELECT: {es:'Seleccione Audiencias Personalizadas', en:'Select Custom Audiences'},

  NEW_CAMPAIGN_AUDIENCE: {es:'Audiencias Grabadas', en:'Recorded Audiences '},
  NEW_CAMPAIGN_MULTIMEDIA: {es:'Contenido Multimedia', en:'Multimedia content '},

  NEW_CAMPAIGN_PIXEL_SELECT: {es:'Seleccione un Pixel', en:'Select a Pixel'},
  NEW_CAMPAING_EVENT_SELECT: {es:'Seleccione un Evento', en:'Select an Event '},
  NEW_CAMPAIGN_PIXEL_ACTIVE: {es:'Eventos Activos del Pixel', en:'Active Pixel Events'},
  NEW_CAMPAIGN_PIXEL_INACTIVE: {es:'Eventos Inactivos del Pixel', en:'Pixel Inactive Events'},
  
  NEW_CAMPAIGN_APP_SELECT: {es:'Seleccione una Aplicación', en:'Select an App'},
  NEW_CAMPAIGN_APP_STORE_SELECT: {es:'Seleccione una Tienda de Aplicaciones', en:'Select an App Store'},
  
  NEW_CAMPAIGN_MESSAGES: {es:'Mensajes', en:'Posts'},
  NEW_CAMPAIGN_MESSAGES_DEST: {es:'Destino del Mensaje', en:'Destination of the Message'},
  NEW_CAMPAIGN_MESSAGES_DEST_SELECT: {es:'Seleccione un Destino de Mensaje', en:'Select a Message Destination'},
  

  NEW_CAMPAIGN_PRODUCT_ERROR1: {es:'No se Encontraron Productos Para Negocio Seleccionado', en:'No Products Found For Selected Business'},
  NEW_CAMPAIGN_SUB_TITLE_2: {es:' Activos comerciales a utilizar', en:' Business assets to use'},
  NEW_CAMPAIGN_ERROR1: {es:'No se Encontraron Cuentas Publicitarias Para Producto Seleccionado', en:'No Ad Accounts Found For Selected Product'},
  NEW_CAMPAIGN_ERROR2: {es:'No se encontraron Fan Pages', en:'No Fan Pages found'},
  NEW_CAMPAIGN_ERROR3: {es:'No se Encontraron Páginas de Facebook para la Cuenta Publicitaria Seleccionada', en:'No Facebook Pages Found for Selected Ad Account'},
  NEW_CAMPAIGN_ERROR4: {es:'No se Encontraron Cuentas de Instagram para la Fan Page seleccionada', en:'No Instagram Accounts Found for the selected Fan Page'},
  NEW_CAMPAIGN_MESSAGE1: {es:'Si no ingresa Nombre, el Nombre será Nombre de Producto_Tipo de Campaña_Fecha Inicio',en:'If you do not enter Name, the Name will be Product Name_Campaign Type_ Start Date'},
  NEW_CAMPAIGN_MESSAGE2: {es:'Debe de ingresar una URL válida', en:'You must enter a valid URL'},
  NEW_CAMPAIGN_MESSAGE3: {es:'Ingrese Enlace a sitio web', en:'Enter Website Link '},
  NEW_CAMPAIGN_MESSAGE4: {es:'Debe de ingresar un valor numérico válido', en:'You must enter a valid numeric value '},
  NEW_CAMPAIGN_MESSAGE5: {es:'Debe de ingresar un valor mayor o igual a 300', en:'You must enter a value greater than or equal to 300'},
  NEW_CAMPAIGN_MESSAGE6: {es:'Debe de ingresar un valor diario mayor o igual a 5', en:'You must enter a daily value greater than or equal to 5 '},
  NEW_CAMPAIGN_MESSAGE7: {es:'Debe de ingresar un valor menor a  $1,000,000 (USD)', en:'You must enter a value less than $ 1,000,000 (USD) '},
  NEW_CAMPAIGN_MESSAGE8: {es:'Si selecciona una Audiencia Grabada no podrá colocar targeting adicional, ¿Desea Continuar?', en:'If you select a Recorded Audience you will not be able to place additional targeting, do you want to continue? '},
  NEW_CAMPAIGN_MESSAGE9: {es:'* Es posible que esta audiencia no cumpla con todos los requisitos de Facebook', en:'* This audience may not meet all of Facebook\'s requirements'},
  NEW_CAMPAIGN_MESSAGE10: {es:'Debe seleccionar archivo(s) de múltimedia válido(s)', en:'You must select valid media files'},
  NEW_CAMPAIGN_MESSAGE11: {es:'El tamaño mínimo de video es de 1 MB y máximo de 100 MB', en:'The minimum video size is 1MB and maximum 100MB'},
  NEW_CAMPAIGN_MESSAGE12: {es:'La duración mínima de video es de 1 segundo y máxima de 241 minutos', en:'The minimum video duration is 1 second and maximum 241 minutes'},
  NEW_CAMPAIGN_MESSAGE13: {es:'Campaña en Proceso de Creación.', en:'Campaign in process of creation.'},
  NEW_CAMPAIGN_MESSAGE14: {es:'Campaña en Proceso de Creación, la Campaña estará lista cuando Facebook haya cargado el video.', en:'Campaign in Creation Process, the Campaign will be ready when Facebook has uploaded the video. '},
  NEW_CAMPAIGN_MESSAGE15: {es:'Debe seleccionar archivos de multimedia válidos', en:'You must select valid media files '},
  NEW_CAMPAIGN_MESSAGE16: {es:'La dimensión minima de video es 120 x 120', en:'The minimum video dimension is 120 x 120'},
  NEW_CAMPAIGN_MESSAGE17: {es:'No se encontraron Pixels para la Cuenta Publicitaria Seleccionada', en:'No Pixels Found for the Selected Ad Account'},
  NEW_CAMPAIGN_MESSAGE18: {es:'Actualmente Facebook ha removido la opción de selección de targeting específico para Whatsapp por lo que no estará habilitado en el Business Manager.', en:'Currently Facebook has removed the option to select specific targeting for WhatsApp so it will not be enabled in the Business Manager.'},
  NEW_CAMPAIGN_MESSAGE19: {es:"$MIN Caracteres Mínimo", en:"$MIN Characters Minimum"},
  NEW_CAMPAIGN_MESSAGE20: {es:"La URL debe de ser externa a Facebook", en:"The URL must be external to Facebook"},
  NEW_CAMPAIGN_MESSAGE21: {es:"Debe de ingresar un valor menor a $MAX", en:"Debe de ingresar un valor menor a $MAX"},
  NEW_CAMPAIGN_MESSAGE22: {es:"Debe de ingresar un valor mayor a $VAL", en:"You must enter a value greater than $VAL"},
  NEW_CAMPAIGN_MESSAGE23: {es:"(el mínimo es $MIN diario)", en:"(the minimum is $MIN daily)"},
  NEW_CAMPAIGN_MESSAGE24: {es:'Favor Ingrese una Locación', en:'Please Enter a Location'},
  NEW_CAMPAIGN_MESSAGE25: {es:'Favor Revisar Pixel', en:'Please Check Pixel'},
  NEW_CAMPAIGN_MESSAGE26: {es:'Favor Revisar Application', en:'Please Review Application'},
  NEW_CAMPAIGN_SUCESS: {es:'Campaña en Proceso de Creación.', en:'Campaign in Process of Creation.'},
  NEW_CAMPAIGN_MESSAGE27: {es:'No se agregará ya que se tiene seleccionado el país completo', en:'It will not be added since the full country is selected'},
  NEW_CAMPAIGN_MESSAGE28: {es:'No se agregará ya que se tiene seleccionada la región que lo incluye', en:'It will not be added since the region that includes it is selected'},
  NEW_CAMPAIGN_MESSAGE29: {es:'No se agregará ya que se tiene seleccionada la ciudad que lo incluye', en:'It will not be added since the city that includes it has been selected'},
  NEW_CAMPAIGN_BUTTON: {es:'Crear Campaña', en:'Create Campaign'},
  NEW_CAMPAIGN_BID_STRAT_NONE: {es:'Sin Estrategia de Puja', en:'No Bidding Strategy '},
  NEW_CAMPAIGN_BID_START_TARGET_COST: {es:'Coste Objetivo', en:'Target Cost '},
  PRODUCT_MODIFY_ACTION: { es: 'Crear Producto', en:'Create Product' },
  PRODUCT_FORM_LABEL1: { es: 'Información General', en:'General Information' },
  PRODUCT_FORM_LABEL2: { es: 'Datos Generales', en:'Industry Type' },
  PRODUCT_FORM_CAT: { es: 'Categoría', en:'Category' },
  PRODUCT_FORM_CAT_PLACEHOLDER: { es: 'Seleccione una Categoría', en:'Select Category' },
  PRODUCT_FORM_CAT_NOT_FOUND: {
    es: 'No se Encontraron Categorías Para Usuario Actual',
  },
  PRODUCT_FORM_AD_ACCOUNT_PLACEHOLDER: {
    es: 'Seleccione una Cuenta Publicitaria',
    en: 'Select Ad Account'
  },
  PRODUCT_FORM_AD_ACCOUNT: { es: 'Cuenta Publicitaria', en:'Ad Account' },
  PRODUCT_FORM_AD_ACCOUNT_NOTFOUND: {
    es: 'No se Encontraron Cuentas Publicitarias para la Cuenta Administradora',
  },
  PRODUCT_FORM_COM_PLACEHOLDER: { es: 'Seleccione una Empresa', en:'Select Company' },
  PRODUCT_FORM_COM: { es: 'Empresa', en:'Company' },
  PRODUCT_FORM_BRAND: { es: 'Marca', en:'Brand' },
  PRODUCT_FORM_BRAND_PLACEHOLDER: { es: 'Seleccione una Marca', en:'Select Brand' },
  PRODUCT_FORM_BRAND_NOTFOUND: {
    es: 'No se Encontraron Empresas para la Cuenta Administradora seleccionada',
    en: 'No Companies Found for the selected Administrator Account'
  },
  PRODUCT_FORM_BRAND_NOTFOUND_2: {
    es: 'No se Encontraron Marcas para la Empresa seleccionada',
    en: 'No Brands Found for the selected Company'
  },
  PRODUCT_FORM_ACCOUNT_PLACEHOLDER: {
    es: 'Seleccione una Cuenta Administradora',
    en: 'Select an Administrator Account'
  },
  PRODUCT_FORM_TITLE_VALIDATION1: {
    es: 'Ha Sobrepasado los 64 caracteres, se ha recortado el texto a 64 caracteres',
    en: 'Exceeded 64 characters, text has been trimmed to 64 characters'
  },
  PRODUCT_FORM_TITLE_VALIDATION2: { es: 'Debe de Ingresar un Título', en:'Must enter title' },
  PRODUCT_FORM_TITLE_PLACEHOLDER: { es: 'Ingrese Título', en:'Enter Title' },

  PRODUCT_FORM_DESC_VALIDATION1: {
    es: 'Ha Sobrepasado los 250 caracteres, se ha recortado el texto a 250 caracteres',
    en: 'Exceeded 250 characters, text has been trimmed to 250 characters'
  },
  PRODUCT_CREATED_SUCCESS: {
    es: 'El producto $NAME ha sido creado con éxito',
    en: 'The product $NAME has been created successfully'
  },
  PRODUCT_MODIFIED_SUCCESS: {
    es: 'El producto ha sido modificado con éxito',
    en: 'The product has been modified successfully'
  },
  PRODUCT_SELECT: {
    es: 'Seleccione Producto',
    en: 'Select Product'
  },


  PRODUCT_FORM_DESC_PLACEHOLDER: { es: 'Ingrese Descripción', en:"Enter description" },
  BILLING_PAYMENTS: { es: 'Pagos realizados' },
  BILLING_CANCEL: { es: 'Cancelar Suscripción' },
  BILLING_NEW_SUBSCRIPTION: { es: 'Generar Nueva Suscripción' },
  BILLING_FORM_HEADER: { es: 'Datos de facturación' },
  BILLING_SUBS_ACTIVE: { es: 'Suscripción Activa' },
  BILLING_SUBS_NEXT_COLLECT: { es: 'Siguiente Cobro' },
  BILLING_SUBS_LAST_COLLECT : { es: 'Ultimo Cobro' },
  BILLING_SUBS_NO_SUBS: { es: 'No Tiene Suscripción Activa' },
  BILLING_SUBS_DISCLAIMER: {
    es: 'Al cancelar la suscripción, se estará eliminando los datos ingresados de la tarjeta de crédito. Se estará realizando un último cobro con el porcentaje (%) de pauta del mes vencido. Ya no se realizarán más cobros automáticos.',
  },
  PAYMENT_STATUS: { es: 'Estado de Pago' },
  TAX_ID: { en: 'Tax ID', es: 'ID Tributario' },
  BILLING_FORM_CALLTOACTION: {
    en: 'Place your order now',
    es: 'Proceder a pago',
  },

  NAV_HOME: { en: 'Home', es: 'Inicio' },
  NAV_ADS: { en: 'Ads', es: 'Anuncios' },
  NAV_CREATE_CAMPAIGN: { en: 'New Campaign', es: 'Crear Campañas' },
  NAV_DRAFTS: { en: 'Drafts', es: 'Campañas Creadas' },
  NAV_CREATE_PRODUCT: { en: 'New Product', es: 'Crear Productos' },
  NAV_MOD_PRODUCT: { en: 'Modify Products', es: 'Modificar Productos' },
  NAV_ADMIN_CAMPAIGNS: { en: 'Campaign Manager', es: 'Manejo de Campañas' },
  NAV_DOWNLOAD_CSV: { en: 'CSV Download', es: 'Descargar CSV' },
  NAV_NEW_BUSINESS: { en: 'New Business', es: 'Nueva Cuenta Administradora' },
  NAV_USERS_ADMIN: {
    en: 'Users Administration',
    es: 'Administración de Usuarios',
  },
  NAV_BUSINESS_MANAGEMENT: {
    en: 'Business Admin',
    es: 'Administración de Negocio',
  },
  NAV_BUSINESS: {
    es: "Negocio Administrador",
    en: "Business"
  },
  NAV_EXPORT_REPORT: { en: 'Export Report', es: 'Exportar Reporte' },
  NAV_BILLING: { en: 'Billing', es: 'Billing' },
  NAV_SUBSCRIPTIONS: { en: 'Subscriptions', es: 'Suscripciones' },
  NAV_ASSETS_UPDATE: { en: 'Update Assets', es: 'Actualizar Activos' },
  NAV_PRIVACY_POLICY: { en:'Privacy Policy', es:'Política de privacidad'},
  NAV_LOGOUT: { en: 'Logout', es: 'Salir' },
  NAV_LANG_ES: {en:'Spanish', es:'Español'},
  NAV_LANG_EN: {en:'English', es:'Inglés'},
  NAV_COUPONS: { en: 'Coupons', es: 'Cupones'},
  ONBOARDING_ERR_MESSAGE1: {
    es:'Favor Realizar Pago',
    en:'Please Make Payment'
  },
  ONBOARDING_ERR_MESSAGE2: {
    es:'Suscripción no pagada, si cree que esto es un error contactar a Soporte ALY-AI <soporte@aly-ai.com>',
    en:'Unpaid subscription, if you think this is an error contact ALY-AI Support <support@aly-ai.com>'
  },
  ONBOARDING_ERR_MESSAGE3: {
    en: 'Please Create Business Administrator',
    es:'Favor Crear Negocio Administrador'
  },
  ONBOARDING_ERR_MESSAGE4: {
    en: 'Please associate Ad Accounts to Business Administrator',
    es:'Favor Asociar Cuentas Publicitarias a Negocio Administrador'
  },
  ONBOARDING_ERR_MESSAGE5: {
    en: 'Please Associate Ad Accounts with ALY',
    es:'Favor Asociar Cuentas Publicitarias con ALY'
  },
  ONBOARDING_ERR_MESSAGE6: {
    en: 'Please Create a Product',
    es:'Favor Crear un Producto'
  },

  ONBOARDING_MESSAGE1: {
    es:'Gracias por llenar los requerimientos mínimos para ALY',
    en:'Thank you for meeting the minimum requirements for ALY'
  },

  ONBOARDING_MESSAGE2: {
    es:'Ir a Página de Inicio',
    en:'Go to Home Page '
  },
  // PRODUCT CATEGORIES
  PRODUCT_CAT_1: {es:'Arte y Entretenimiento', en:'Arts and Entertainment'},
  PRODUCT_CAT_2: {es:'Autos y Vehículos', en:'Cars and Vehicles'},
  PRODUCT_CAT_3: {es:'Belleza (Estado Físico)', en:'Beauty (Physical State)'},
  PRODUCT_CAT_4: {es:'Libros y Literatura', en:'Books and Literature'},
  PRODUCT_CAT_5: {es:'Negocios e Industria', en:'Business and Industry'},
  PRODUCT_CAT_6: {es:'Computadoras y Electrónicos', en:'Computers and Electronics'},
  PRODUCT_CAT_7: {es:'Finanzas', en:'Finance'},
  PRODUCT_CAT_8: {es:'Comida y Bebidas', en:'Food and drinks'},
  PRODUCT_CAT_9: {es:'Juegos', en:'Games'},
  PRODUCT_CAT_10: {es:'Salud', en:'Health'},
  PRODUCT_CAT_11: {es:'Hobbies y Ocio', en:'Hobbies and Leisure'},
  PRODUCT_CAT_12: {es:'Casa y Jardín', en:'House and garden'},
  PRODUCT_CAT_13: {es:'Internet y Telecomunicaciones', en:'Internet and Telecommunications'},
  PRODUCT_CAT_14: {es:'Trabajo y Educación', en:'Work and Education'},
  PRODUCT_CAT_15: {es:'Ley y Gobierno', en:'Law and Government'},
  PRODUCT_CAT_16: {es:'Noticias', en:'News'},
  PRODUCT_CAT_17: {es:'Comunidades en Línea', en:'Online Communities'},
  PRODUCT_CAT_18: {es:'Personas y Sociedad', en:'People and Society'},
  PRODUCT_CAT_19: {es:'Mascotas y Animales', en:'Pets and Animals'},
  PRODUCT_CAT_20: {es:'Bienes Raíces', en:'Real estate'},
  PRODUCT_CAT_21: {es:'Referencias', en:'References'},
  PRODUCT_CAT_22: {es:'Ciencia', en:'Science'},
  PRODUCT_CAT_23: {es:'Compras', en:'Shopping'},
  PRODUCT_CAT_24: {es:'Deporte', en:'Sport'},
  PRODUCT_CAT_25: {es:'Viajes', en:'Travels'},
  PRODUCT_CAT_26: {es:'Ubicaciones', en:'Locations'},

  PPTX_PAGE_TITLE: {es:'Generar Reporte', en:'Generate Report'},
  PPTX_PAGE_ACTION: {es:'Generar Reportes', en:'Generate Reports'},
  PPTX_SUB_1: {es:'GRÁFICA DE IMPRESIONES', en:'IMPRESSION CHART'},
  PPTX_SUB_2: {es:'GRÁFICA ACCIONES', en:'ACTIONS GRAPH'},
  PPTX_SUB_3: {es:'GRÁFICA COSTO POR ACCIÓN', en:'COST PER ACTION CHART'},
  PPTX_SUB_4: {es:'GRÁFICA DE FRECUENCIA', en:'FREQUENCY GRAPH'},
  PPTX_DATE_RANGE: {es:'Fechas del reporte', en:'Report dates'},
  PPTX_SUB_5: {es:'Inversión de Medios', en:'Media Investment'},
  PPTX_SUB_6: {es:'RESUMEN DESEMPEÑO', en:'PERFORMANCE SUMMARY '},
  PPTX_SUB_7: {es:'COMBINACIÓN DE TEXTOS', en:'COMBINATION OF TEXTS'},
  PPTX_MESSAGE_1: {es:'*La gráfica mostrada no es proporcional, esto es por motivos ilustrativos', en:'The graph shown is not proportional, this is for illustrative reasons'},
  PPTX_MESSAGE_2: {es:'Reportes Finalizados', en:'Finalized Reports'},
  PPTX_MESSAGE_3: {es:'Generando reporte $REPORT de $LEN', en:'Generating report $REPORT of $LEN'},
  PPTX_MESSAGE_4: {es:'Generando reporte', en:'Generating report'},
  PPTX_MESSAGE_5: {es:'Solicitando Información para los Reportes', en:'Requesting Information for Reports'},
  PPTX_MESSAGE_6: {es:'Título Creativo', en:'Creative Title'},
  PPTX_MESSAGE_7: {es:'Cuerpo del Anuncio', en:'Ad Body'},
  PPTX_CTA_TITLE: {es:'CTA (Call to action)', en:'CTA (llamada a la acción)'},
  PPTX_CTA_DESC: {es:'llama a la acción, es una herramienta o estrategia de publicidad, gráfica o textual, que busca incitar a la audiencia a responder a una acción después de ver un anuncio. Campañas CTA son campañas que llaman a una acción al usuario que vio el anuncio.', en:'calls to action, is an advertising tool or strategy, graphic or textual, that seeks to incite the audience to respond to an action after seeing an ad. CTA campaigns are campaigns that call an action to the user who saw the ad. '},
  PPTX_KPIs_DESC: {es:'se trata de indicadores clave de rendimiento, utilizados para medir el nivel del desempeño de un proceso. Estos indicadores están relacionados a un objetivo.', en:'These are key performance indicators, used to measure the level of performance of a process. These indicators are related to an objective. '},
  PPTX_IMPRESSIONS_DESC: {es:'es la frecuencia con la que se muestra su anuncio. Una impresión se cuenta cada vez que su anuncio aparece en Facebook o Instagram.', en:'is how often your ad is shown. An impression is counted every time your ad appears on Facebook or Instagram.'},
  PPTX_CLICK_DESC: {es:'cuando un usuario pulsa/hace clic en un anuncio.', en:'when a user clicks / clicks on an ad.'},
  PPTX_LEAD_DESC: {es:'(prospecto o posible cliente ) contacto de ventas potencial, individuo u organización que expresa un interés en determinados bienes o servicios.', en:'(prospect or prospect) potential sales contact, individual or organization who expresses an interest in certain goods or services.'},
  PPTX_COST_TITLE: {es:'Costo o Inversión de Medios', en:'Cost or Investment of Media'},
  PPTX_COST_DESC: {es:'total de inversión en medios en una campaña publicitaria, también conocida como pauta digital o Ad Spend en inglés.', en:'Total investment in media in an advertising campaign, also known as a digital campaign.'},
  PPTX_CPC_TITLE:{es:'CPC (costo por clic)', en:'CPC (cost per click)'},
  PPTX_CPC_DESC:{es:'costo por cada clic realizado por los usuarios en una campaña (costo/clicks)', en:'cost for each click made by users in a campaign (cost / clicks)'},
  PPTX_CTR_TITLE: {es:'CTR (click-through-rate)', en:'CTR (click through rate) '},
  PPTX_CTR_DESC: {es:'métrica que muestra la frecuencia en que las personas ven un anuncio y terminan haciendo click o pulsando en él. (click / impresiones)', en:'metric that shows how often people see an ad and end up clicking or clicking on it. (click / impressions) '},
  PPTX_CPL_TITLE: {es:'CPL (costo por lead)', en:'CPL (cost per lead) '},
  PPTX_CPL_DESC: {es:'costo por cada lead generado en una campaña. (costo/leads)', en:'cost for each lead generated in a campaign. (cost / leads)'},
  PPTX_CPA_TITLE: {es:'CPA (costo por acción)', en:'CPA (cost per action) '},
  PPTX_CPA_DESC: {es:'costo por acción ejecutada. (costo/acciones).', en:'cost per action executed. (cost / actions).'},
  PPTX_CPM_TITLE: {es:'CPM (Costo por mil impresiones)', en:'CPM (Cost per thousand impressions) '},
  PPTX_CPM_DESC: {es:'Costo por cada mil impresiones generadas por un anuncio. (Formula: 1000* Inversión de Medios/Impresiones).', en:'Cost per thousand impressions generated by an ad. (Formula: 1000 * Media / Impression Investment).'},
  PPTX_ROAS_TITLE: {es:'ROAS (Retorno en la inversión publicitaria)', en:'ROAS (Return on advertising investment) '},
  PPTX_ROAS_DESC: {es:'Cantidad de dólares que retornan como venta por cada dólar invertido en una campaña digital. (Ingresos de la campaña/Costo).', en:'Amount of dollars that return as a sale for each dollar invested in a digital campaign. (Campaign income / Cost). '},
  PPTX_REACH_TITLE: {es:'Reach o Alcance', en:'Reach'},
  PPTX_REACH_DESC: {es:'número de personas que han visto un contenido.', en:'number of people who have seen content.'},
  PPTX_LINK_CLICKS_TITLE: {es:'Link clicks o clics en el enlace', en:'Link clicks'},
  PPTX_LINK_CLICKS_DESC: {es:'total de clicks/pulsaciones que se dieron en un anuncio. Clicks/pulsaciones que generan acciones concretas.', en:'total clicks that occurred on an ad. Clicks / pulsations that generate concrete actions. '},
  PPTX_NEW_CONVERSATIONS_TITLE: {es:'Conversaciones nuevas', en:'New conversations '},
  PPTX_NEW_CONVERSATIONS_DESC: {es:'número de personas que iniciaron una conversación, por medio de Whatsapp o Facebook Messenger.', en:'number of people who started a conversation, through WhatsApp or Facebook Messenger.'},
  PPTX_LEAD_GENERATION_TITLE: {es:'Generación de leads', en:'Lead generation'},
  PPTX_LEAD_GENERATION_DESC: {es:'objetivo de campaña que permite recopilar información de clientes potenciales (prospectos/ leads), como correo electrónico, número de celular, país, información adicional de personas interesadas en el producto o servicio promocionado.', en:'Campaign objective that allows collecting information from potential customers (prospects / leads), such as email, cell phone number, country, additional information from people interested in the promoted product or service.'},
  PPTX_CONVERTIONS_TITLE: {es:'Conversiones', en:'Conversions '},
  PPTX_CONVERTIONS_DESC: {es:'objetivo de campaña que permite saber cuántas personas realizaron una compra, afiliación, descargas, formularios, etc, desde una campaña en redes sociales. Para medir los resultados será necesario la instalación del Píxel de Facebook (código de seguimiento).', en:'Campaign objective that lets you know how many people made a purchase, affiliation, downloads, forms, etc., from a campaign on social networks. To measure the results, it will be necessary to install the Facebook Pixel (tracking code).'},
  PPTX_ACTIONS_TITLE: {es:'Acciones', en:'Actions'},
  PPTX_ACTIONS_DESC: {es:'hecho específico realizado por un usuario y generado dentro de los objetivos de una campaña. Estos pueden ser pueden ser:', en:'specific event performed by a user and generated within the objectives of a campaign. These can be:'},
  PPTX_LEADS_DESC: {es:'Prospectos o posibles clientes (leads)', en:'Prospects or potential customers (leads)'},
  PPTX_CONVERSATIONS_2_DESC: {es:'Nuevas conversaciones por Facebook messenger y WhatsApp', en:'New conversations on Facebook messenger and WhatsApp'},
  PPTX_SHOP_DESC: {es:'Compras en línea', en:'Online Purchases'},
  PPTX_APP_DOWNLOADS_DESC: {es:'Descarga de aplicación', en:'App Download'},
  PPTX_LINK_CLICKS_2_DESC: {es:'Clicks a Link', en:'Link Clicks'},
  PPTX_TO: {es:'al', en:'to'},
  ALY_LOG_CHANGES: {es:'Modificaciones automáticas', en:'Automatic modifications'},
  ALY_LOG_IMPROVEMENT: {es:'Mejora en el costo por acción en las campañas', en:'Improved cost per action in campaigns'},
  ALY_LOG_TIME_SAVED: {es:'Tiempo Ahorrado (hrs)', en:'Time Saved (hrs)'},
  ALY_LOG_CHANGES_TYPE: {es:'Tipo de Modificaciones', en:'Type of Modifications'},
  ALY_LOG_ADDITIONAL_ACTIONS: {es:'Acciones adicionales generadas', en:'Additional actions generated'},
  ALY_LOG_BUDGET: {es:'Presupuesto de campaña', en:'Campaign budget'},
  ALY_LOG_MOD_BUDGET: {es:'Modificación en presupuesto', en:'Modification in budget'},
  ALY_LOG_SAVINGS: {es:'Ahorro en presupuesto', en:'Budget savings'},
  ALY_LOG_CPA: {es:'Costo por Acción', en:'Cost per Action'},
  ALY_LOG_CPA_MOD: {es:'Modificación en costo por acción', en:'Modification in cost per action'},
  ALY_LOG_CPA_SAVINGS: {es:'Ahorro en Costo por Acción', en:'Savings in Cost per Action'},
  CONVERSION_INDICATORS_SUB: {es:'Detalle de eventos pixel', en:"Pixel event details"},
  CONVERSION_INDICATORS_SUM: {es:'Estadisticas de eventos de pixel', en:"Pixel event statistics"},

  CLICKS: {es:'Clics', en:"Clicks"},

  impressions: {en:"Impressions",es:"Impresiones"},    
  clicks: {es:'Clics', en:"Clicks"},  
  fb_pixel_view_content: {es:'Ver Contenido', en:'View Content'},
  fb_pixel_lead: {es:'Prospectos', en:'Leads'},
  fb_pixel_add_to_cart: {es:'Agregar a Carrito', en:'Add to Cart'},
  fb_pixel_initiate_checkout: {es:'Iniciar Pago', en:'Initiate Checkout'},
  fb_pixel_purchase: {es:'Compra', en:'Purchase'},
  fb_pixel_search: {es:'Busquedas', en:'Search'},
  fb_pixel_complete_registration: {es:'Registro Completo', en:'Complete Registration'},

  impressions_tool_tip: {en:"Total times the campaign was viewed",es:"Total de veces que la campaña fue vista"},  
  clicks_tool_tip: {en:"Total number of ad clicks", es:"Número total de clics en el anuncio"},
  fb_pixel_view_content_tool_tip: {en:"Total number of clicks on the \"View content\" event", es:"Número total de clics en el evento \"Ver contenido\""},
  fb_pixel_lead_tool_tip: {en:"Total number of times the \"Lead\" event occurred", es:"Número total de veces que ocurrió el evento \"Lead\""},  
  fb_pixel_add_to_cart_tool_tip: {en:'Total number of times the "Add To Cart" event occurred', es:'Número total de veces que ocurrió el evento "Agregar a Carrito"'},
  fb_pixel_initiate_checkout_tool_tip: {en:'Total number of times the "Initiate Checkout" event occurred', es:'Número total de veces que ocurrió el evento "Iniciar Pago"'},
  fb_pixel_purchase_tool_tip: {en:'Total number of times the "Purchase" event occurred', es:'Número total de veces que ocurrió el evento "Compra"'},
  fb_pixel_search_tool_tip: {en:'Total number of times the "Search" event occurred', es:'Número total de veces que ocurrió el evento "Busqueda"'},
  fb_pixel_complete_registration_tool_tip: {en:"Total number of times the \"Complete registration\" event occurred", es:'Número total de veces que ocurrió el evento "Complete registration"'},

  FEMALE_UBG: {es:'Edad máxima de mujeres', en:'Maximum age of women'},
  FEMALE_LBG: {es:'Edad mínima de mujeres', en:'Minimum age for women'},  
  INV_FEMALE: {es:'Presupuesto óptimo para mujeres', en:'Optimal budget for women'},  
  MALE_LBG: {es:'Edad mínima de hombres', en:'Minimum age for men'},
  MALE_UBG: {es:'Edad máxima de hombres', en:'Maximum age of men'},
  INV_MALE: {es:'Presupuesto óptimo para hombres', en:'Optimal budget for men'},

  AGE_GENDER_DETAIL_SUB: {es:'Estas son las mejores edades para cada género y el presupuesto ideal para la segmentación de tu campaña.', en:'These are the best ages for each gender and the ideal budget for targeting your campaign.'},


  // REPORTES
  // REPORTE AUTOMATICO

  RP_AUTOMATIC_REPORT: {es:'Reporte Automático', en: 'Automatic Report'},
  RP_SELECT_CLIENT: {es:'Seleccionar cliente', en:'Select client'},
  RP_ALL_CLIENTS:{es:'Todos los clientes', en:'All clients'},
  RP_REPORT_TYPE: {es:'Tipo de reporte', en: 'Report type'}, 
  //select - tipos de reporte
  RP_RECURRENCE: {es:'Recurrencia', en:'Recurrence'}, 
  // select - recurrencias
  RP_MONTHLY: {es: 'Mensual', en:'Monthly'},
  RP_BIWEEKLY: {es: 'Bisemanal', en:'Biweekly'},
  RP_WEEKLY: {es: 'Semanal', en:'Weekly'},

  RP_SHIPPING_DATE: {es: 'Fecha de envío', en: 'Sending date'},
  RP_SCHEDULE: {es:'Horario', en:'Schedule'},
  RP_DELETE: {es:'Borrar', en:'Delete'},  
  RP_SEND: {es:'Enviar', en:'Send'},
  RP_ALL: {es:'Todos', en:'All'},
  RP_ALL_REPORTS: {es:'Todos los Reportes', en:'All Reports'},

  RP_REPORT_OK: {es: 'El envío del reporte se ha programado con éxito.', en: 'The report was scheduled correctly.'},
  RP_REPORT_OPTIONS: {es:'Opciones', en:'Options'},

  RP_DELETE_CONFIRM: {es:'¿Estás seguro que deseas eliminar este reporte?', en:'Are you sure you want to delete this report?'},
  
  
  // REPORTE DE VENTAS
  RP_SALES_REPORT: {es: 'Reporte de Ventas', en:'Sales Report'},
  RP_FILTER: {es: 'Filtrar', en:'Filter'},
  RP_ACTIVE_CAMPAIGN: {es: 'Campaña activa', en:'Active campaign'},
  RP_CAMPAIGN: {es: 'Campaña', en:'Campaign'},
  RP_OBJECTIVE: {es: 'Objetivo', en:'Objective'},
  RP_BUDGET: {es: 'Presupuesto', en:'Budget'},
  RP_DAYS_ACTIVE: {es: 'Días activa', en:'Days active'},
  RP_CAMPAIGN_TOTAL: {es: 'Total campaña', en:'Campaign total'},
  RP_TICKET: {es: 'Ticket', en:'Ticket'},
  RP_TRANSACTIONS_NO: {es: 'No. transacciones', en:'Transactions'},
  RP_SALES: {es: 'Venta', en:'Sales'},
  RP_PRODUCTS: {es: 'Productos', en:'Products'},
  RP_COMMENTS: {es: 'Comentarios', en:'Comments'},
  RP_EDIT: {es:'Editar', en:'Edit'},
  RP_SAVE: {es:'Guardar', en:'Save'},  


  // MESES

  JAN: {es:'Enero', en:'January'}, 
  FEB: {es:'Febrero', en:'February'}, 
  MAR: {es:'Marzo', en:'March'}, 
  APR: {es:'Abril', en:'April'}, 
  MAY: {es:'Mayo', en:'May'}, 
  JUN: {es:'Junio', en:'June'}, 
  JUL: {es:'Julio', en:'July'}, 
  AUG: {es:'Agosto', en:'August'}, 
  SEP: {es:'Septiembre', en:'September'}, 
  OCT: {es:'Octubre', en:'October'}, 
  NOV: {es:'Noviembre', en:'November'}, 
  DEC: {es:'Diciembre', en:'December'}, 

  // NAVBAR

  NAV_REPORTS : {es:'Reports', en:'Reportes'},
  NAV_REPORTE_AUTO: {es:'Auto Report', en:'Reporte Automático'},  

  
};
