import { createTheme } from '@material-ui/core/styles';
import themes, { overrides } from '../../../themes';
// @ts-ignore
import SemiBold from '../../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf';
// @ts-ignore
import Regular from '../../../assets/fonts/Montserrat/Montserrat-Regular.ttf';

// @ts-ignore
import Medium from '../../../assets/fonts/Montserrat/Montserrat-Medium.ttf';
export const palette = {
  purple: '#3E4FD3',
  lightPurple: '#e5e7f9',
  transparentPurple: '#E5E7F7',
  white: '#fff',
  deepPurple: '#100047',
  purpleRain: '#8036E9',
  purpleDeep: '#100047',
  gray: '#00000099',
  slate: '#9898AB',
  purpleAly: '#871BEF',
  mindAly: '#88D1D4',
  emphasis: '#00000099',
};
const semiBold = {
  fontFamily: 'Montserrat-SemiBold',
  fontStyle: 'SemiBold',
  fontDisplay: 'swap',
  fontWeight: '600',
  src: ` 
   local('Montserrat-SemiBold'),
   url(${SemiBold}) format('truetype')
 `,
};

const regular = {
  fontFamily: 'Montserrat-Regular',
  fontStyle: 'Regular',
  fontDisplay: 'swap',
  fontWeight: '600',
  src: ` 
   local('Montserrat-Regular'),
   url(${Regular}) format('truetype')
 `,
};

const montserrat = {
  fontFamily: 'Montserrat',
  fontStyle: 'Regular',
  fontDisplay: 'swap',
  fontWeight: '600',
  src: ` 
   local('Montserrat'),
   url(${Regular}) format('truetype')
 `,
};

const medium = {
  fontFamily: 'Montserrat-Medium',
  fontStyle: 'Medium',
  fontDisplay: 'swap',
  fontWeight: '600',
  src: ` 
   local('Montserrat-Medium'),
   url(${Medium}) format('truetype')
 `,
};

// @ts-ignore
export const theme = createTheme({
  ...themes.default,
  ...overrides,
  typography: {
    fontFamily: ['Montserrat'].join(','),
  },
  palette: {
    primary: {
      main: palette.purpleRain,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #fff inset',
        },
      },
    },
    MuiFormControl: {
      root: {
        height: '38px',
      },
    },
    MuiInputBase: {
      root: {
        height: '38px',
      },
    },

    MuiCssBaseline: {
      '@global': {
        // @ts-ignore
        '@font-face': [semiBold, regular, medium, montserrat],
      },
    },
  },
});
