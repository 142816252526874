import React from 'react';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const Focusable = ({ ...props }) => {
  if (props.refList) {
    let findref = getFocusable(props.refList, props.id);
    if (!findref) {
      let ref = React.createRef();
      props.refList.push({ id: props.id, ref: ref });
      return (
        <div
          id={props.id}
          style={{
            float: 'right',
            opacity: '0',
          }}
          ref={ref}
        >
          <ErrorOutlineIcon style={{ fontSize: 40 }} />
        </div>
      );
    } else
      return (
        <div
          id={props.id}
          style={{
            float: 'right',
            opacity: '0',
          }}
          ref={findref}
        >
          <ErrorOutlineIcon style={{ fontSize: 40 }} />
        </div>
      );
  } else {
    return '';
  }
};

export default Focusable;
export function getFocusable(refList, focusableId) {
  for (let index = 0; index < refList.length; index++) {
    if (refList[index].id === focusableId) return refList[index].ref;
  }
  return undefined;
}
function disolve(element, speed, times, count = 0) {
  count++;
  element.style.transition = 'opacity ' + speed + 'ms linear';
  element.style.opacity = '100';
  setTimeout(function () {
    element.style.opacity = '0';
    setTimeout(function () {
      if (count < times) disolve(element, speed, times, count);
    }, speed);
  }, speed);
}

export function changeFocus(
  focusableId,
  refList,
  disolveTime = 500,
  repetitions = 5,
) {
  let element = document.getElementById(focusableId);

  if (element) {
    if (element.style) {
      let ref = getFocusable(refList, focusableId);

      if (ref)
        if (ref.current)
          if (ref.current.offsetTop !== undefined) {
            window.scrollTo(0, ref.current.offsetTop - 100);
            disolve(element, disolveTime, repetitions);
          }
    }
  }
}

