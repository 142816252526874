import React from 'react';
import { withStyles } from '@material-ui/core';
import Widget from '../../../components/Widget';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';

import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
const Status = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <Widget
      overflow={true}
      focusable={
        <Focusable
          id={props.componentInfo.focusId}
          refList={props.refList}
        ></Focusable>
      }
      title={'Estado de Campaña'}
      bodyClass={classes.tableWidget}
      disableWidgetMenu={true}
    >
      <div>
        <div style={{ width: '35%', margin: 'auto' }}>
          <FormControl component="fieldset">
            <RadioGroup
              row={true}
              aria-label="status"
              name="status"
              value={props.modifyCampaignStatus.value}
              onChange={(e, value) => {
                props.changeModifyCampaignStatus({
                  modified: true,
                  value: value,
                });
              }}
            >
              <FormControlLabel
                value={'ACTIVE'}
                control={<Radio />}
                label="Activa"
              />
              <FormControlLabel
                value={'PAUSED'}
                control={<Radio />}
                label="Pausada"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(Status);
