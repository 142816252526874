import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import Dashboard31 from './Components/Dashboard31';
import Dashboard32 from './Components/Dashboard32';
import Dashboard33 from './Components/Dashboard33';
import Dashboard34 from './Components/Dashboard34';
import Dashboard35 from './Components/Dashboard35';
import Dashboard36 from './Components/Dashboard36';
import Dashboard37 from './Components/Dashboard37';


import Help from './../../components/Help/help';

import {
  productTours
} from '../../components/Constants';
const HomePage = ({ classes, theme, ...props }) => {
  let width = 0;
  let height = document.documentElement.clientHeight / 3;
  if (!props.sideBarOpened) {
    width = document.documentElement.clientWidth - 30;
  } else width = document.documentElement.clientWidth - 350;

  width = Number(width.toFixed(1)) / 2;
  height = Number(height.toFixed(1)) * 1.3;

  return (
    <>
      <Grid
        container
        spacing={4}
        style={{
          paddingRight:
            props.sideBarOpened && document.documentElement.clientWidth >= 1280
              ? 14
              : '',
        }}>
        {/* STANDAR KPI */}
        <Grid item xs={12} md={12} lg={12}>
          <Dashboard35
            isPrintingPage={props.isPrintingPage}
            resizeRefresh={props.resizeRefresh}
            isLoading={props.isLoadingLayout}
            data={props.dashboard35Data}
            lang={props.lang}
            showKPIExtra={props.showKPIExtra}
            startTour={props.startTour}
          />
        </Grid>
        {/* ALY INDICATORS */}
        <Grid item xs={12} md={12} lg={12}>
          <Dashboard37
            isPrintingPage={props.isPrintingPage}
            resizeRefresh={props.resizeRefresh}
            isLoading={props.isLoadingLayout}
            data={props.dashboard35Data}
            lang={props.lang}
            showKPIExtra={props.showKPIExtra}
            startTour={props.startTour}
          />
        </Grid>
        {/* CONVERSION INDICATORS */}
        {props.showKPIExtra && (
          <Grid item xs={12} md={12} lg={12}>
            <Dashboard36
              isPrintingPage={props.isPrintingPage}
              resizeRefresh={props.resizeRefresh}
              isLoading={props.isLoadingLayout}
              data={[props.dashboard36Data, props.dashboard35Data]}
              lang= {props.lang}
              showKPIExtra={props.showKPIExtra}
            />
          </Grid>
        )}
        {/* CPA GRAPH */}
        <Grid item xs={12} md={12} lg={6}>
          <Dashboard31
            isPrintingPage={props.isPrintingPage}
            width={width}
            height={height}
            resizeRefresh={props.resizeRefresh}
            isLoading={props.isLoadingLayout}
            lang={props.lang}
            data={props.dashboard31Data}
          />
        </Grid>
        {/* CPA CLICKS */}
        <Grid item xs={12} md={12} lg={6}>
          <Dashboard32
            isPrintingPage={props.isPrintingPage}
            width={width}
            height={height}
            resizeRefresh={props.resizeRefresh}
            isLoading={props.isLoadingLayout}
            lang={props.lang}
            data={props.dashboard32Data}
          />
        </Grid>

        {/* HELP */}
        <Grid item xs={12} md={12} lg={12}>
        <Help
                className={"HelpButton"} 
                lang={props.lang} 
                productTourId={productTours.es.graphs.id} /> 
          
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <Dashboard33
            isPrintingPage={props.isPrintingPage}
            width={width}
            height={height}
            resizeRefresh={props.resizeRefresh}
            isLoading={props.isLoadingLayout}
            lang={props.lang}
            data={props.dashboard33Data}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Dashboard34
            isPrintingPage={props.isPrintingPage}
            width={width}
            height={height}
            resizeRefresh={props.resizeRefresh}
            isLoading={props.isLoadingLayout}
            lang={props.lang}
            data={props.dashboard34Data}
          />
        </Grid>
      </Grid>
    </>
  );
};

const styles = theme => ({
  tableWidget: {
    overflowX: 'auto',
    fontSize: '18px',
    //background: '#013f8a'
  },
  mainChartHeaderLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
  },
  mainChartHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mainChartHeaderLabels: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      order: 3,
      width: '100%',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartLegentElement: {
    fontSize: '18px !important',
    marginLeft: theme.spacing(1),
  },
});

export default withStyles(styles, { withTheme: true })(HomePage);
