import React, { useState, useEffect } from 'react'
import trans from '../../../i18n/tran';
import {
    Save, Add
} from '@material-ui/icons';
import { sellsCapture, getTickets as listTickets } from '../../../components/Constants'
import { doFetch, createHeaders } from './../../../utils/fetch';

export default function Campania(props) {

    const checkActive = (campania) => {
        const date = new Date(campania);
        const currentDate = new Date();
        return date > currentDate;
    }

    const [abajo, setAbajo] = useState(false)
    // [ {}, {}] lista de objs, vienen desde el post
    const [ticketObjs, setticketObjs] = useState([])
    // lista de componentes creados a partir de ticketObjs
    const [subArray, setSubArray] = useState(<></>);
    const [ticketsGuardados, setticketsGuardados] = useState([]);
    const [localTicketsArray, setlocalTicketsArray] = useState(<></>);
    // nuevo ticket, creado solo una vez
    const [nuevoTicket, setNuevoTicket] = useState(<></>);

    const [listaParaEditarBase, setListaParaEditarBase] = useState([])
    const [realBudget, setRealBudget] = useState(0);
    const [totalVentasLocal, settotalVentasLocal] = useState(0);
    const [totalROAS, settotalROAS] = useState(0);

    let controller = new AbortController();
    let signal = controller.signal;

    useEffect(() => {
        getTickets();
        setNuevoTicket(< Ticket guardarenArray={guardarenArray} campania={props.campania} terminar={terminarTicket}></Ticket>)
        setAbajo(false)
    }, [])
    useEffect(() => {
        setAbajo(false)
        setNuevoTicket(< Ticket guardarenArray={guardarenArray} campania={props.campania} terminar={terminarTicket}></Ticket>)
        getTickets();
    }, [props.campania])
    useEffect(() => {
        setSubArray(cropSubArray());        
        // get Total Sales
        const totalAmount = ticketObjs.reduce((accumulator, currentObject) => {
            return accumulator + currentObject.Amount;
        }, 0);

        // Calculate the average
        const averageAmount = (totalAmount / ticketObjs.length).toFixed(2);
        // console.log('MIS TICKETS:', averageAmount);
        settotalVentasLocal(totalAmount);

    }, [ticketObjs])
    useEffect(() => {
        // guardar en base
        // console.log('guardo en base', props.guardar, ticketsGuardados)
        ticketsGuardados.forEach(element => {
            guardarTicket(element);
        });
        // console.log('editar final');
        listaParaEditarBase.forEach(element => {
            // console.log('editando ', element)
            editarTicketenBase(element);
        });
        setListaParaEditarBase([])
        setticketsGuardados([])
        props.reloadCampaigns();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [props.guardar])
    useEffect(() => {
        setlocalTicketsArray(cropLocalSubArray());
    }, [ticketsGuardados])
    useEffect(() => {
        if (listaParaEditarBase.length == 0) {
            return;
        }
        // console.log('LISTA ACTUALIZADA PARA BASE', listaParaEditarBase)
        const ultimo = listaParaEditarBase[listaParaEditarBase.length - 1];
        let aux = listaParaEditarBase;

        const duplicateIndex = listaParaEditarBase.findIndex(item => item.id === ultimo.id);
        if (duplicateIndex !== -1 && duplicateIndex != (listaParaEditarBase.length - 1)) {
            // console.log('el doble es: ', duplicateIndex)

            aux[duplicateIndex] = ultimo;
            aux.pop();
            setListaParaEditarBase(aux);
            // console.log(aux);
        }

    }, [listaParaEditarBase])
    useEffect(() => {
        console.log('TOTAL DE VENTAS EDITADO', totalVentasLocal, props.campania.Budget)
        setRealBudget(props.campania.Budget)
        if (!props.campania.Budget) {
            setRealBudget(props.campania.AdSetLifeTimeBudget)
            if(!props.campania.AdSetLifeTimeBudget){
                setRealBudget(props.campania.AdSetDailyBudget)
                if(!props.campania.AdSetDailyBudget){
                    // si es null o 0
                    settotalROAS(0);
                    return
                }
                
            }

        }

        const roas = parseFloat((totalVentasLocal / realBudget).toFixed(4))
        settotalROAS(roas);

    }, [totalVentasLocal])


    const getTickets = async () => {

        controller = new AbortController();
        signal = controller.signal;

        let url = `${listTickets}/`;

        await doFetch(
            url,
            {
                method: 'post',
                headers: createHeaders(),
                body: JSON.stringify({
                    CampaignId: props.campania.CampaignId
                }),
                signal,
            },
            res => {
                if ('success' in res && 'payload' in res && res.success) {

                    let raw = res.payload.tickets
                    setticketObjs(raw)
                    // console.log('getTickets: ', raw);
                }
            },
        );
    }
    const cropSubArray = () => {
        return ticketObjs.map(
            (ticketObj, i) =>
                < Ticket key={i} guardarenArray={guardarenArray} campania={props.campania} ticket={ticketObj} terminar={terminarTicket} editarenBase={editarenBase} listaParaEditarBase={listaParaEditarBase}></Ticket>
        )
    }
    const terminarTicket = () => {
        getTickets();
        props.reloadCampaigns()
    }

    const guardarenArray = (ticketObj, esnuevoTicket, index) => {
        if (esnuevoTicket) {
            // console.log('nuevo')
            // let auxTicketsGuardados = ticketsGuardados;
            setticketsGuardados(auxTicketsGuardados => [...auxTicketsGuardados, ticketObj]);
            return;
        }

        // si no es un nuevo ticket local, entonces modifico en el array local
        let auxTicketsGuardados = ticketsGuardados;
        auxTicketsGuardados[index] = ticketObj;
        setticketsGuardados(auxTicketsGuardados);

    }
    const cropLocalSubArray = () => {
        return ticketsGuardados.map(
            (ticketObj, i) =>
                < Ticket key={i} index={i} guardarenArray={guardarenArray} campania={props.campania} ticket2={ticketObj} terminar={terminarTicket}></Ticket>
        )
    }

    const guardarTicket = async (ticket) => {

        controller = new AbortController();
        signal = controller.signal;

        let url = `${sellsCapture}/`;

        await doFetch(
            url,
            {
                method: 'post',
                headers: createHeaders(),
                body: JSON.stringify(ticket),
                signal,
            },
            res => {
                if ('success' in res && 'payload' in res && res.success) {

                    let raw = res.payload
                    if (raw.sale.length > 0) {
                        alert('Un producto que quieres ingresar es incorrecto')
                    } else {
                        // console.log('ticket ingresado con exito', ticket)                        
                    }

                }
            },
        );
    }
    const editarTicketenBase = async (ticket) => {

        controller = new AbortController();
        signal = controller.signal;

        let url = `${sellsCapture}/`;

        const objfinal = {
            pId: ticket.id,
            pTicketCount: ticket.Ticket,
            pTransactionCount: ticket.Transaction,
            pProduct: ticket.ProductId,
            pAmount: ticket.Amount,
            pComment: ticket.Comments
        }

        await doFetch(
            url,
            {
                method: 'put',
                headers: createHeaders(),
                body: JSON.stringify(objfinal),
                signal,
            },
            res => {
                if ('success' in res && 'payload' in res && res.success) {

                    let raw = res.payload
                    if (!('sale' in raw)) {
                        alert('Un producto que quieres editar es incorrecto')
                    }
                }
            },
        );
    }

    // EDITAR TICKETS EN BASE    
    const editarenBase = (obj) => {

        // console.log('MODIFICANDO UN TICKET DE BASE')
        setListaParaEditarBase(listaParaEditarBase => [...listaParaEditarBase, obj]);
    }



    return (
        <>
            <tr key={props.campania.CampaignId} className={`campania ${checkActive(props.campania.EndDate) ? "" : "innactive"}`}>
                <td>
                    <button className='starter' onClick={() => setAbajo(!abajo)} >
                        {
                            props.campania.CampaignValue
                        }
                        {
                            abajo ? <span className='icon'>↓</span> : <span className='icon'>↑</span>
                        }
                    </button>
                </td>
                <td>
                    {
                        props.campania.CampaignType
                    }
                </td>
                <td>
                    {
                        realBudget
                    }
                </td>
                <td>
                    {
                        props.campania.Dias
                    }
                </td>
                <td>
                    {
                        '$' + totalROAS || 0

                    }
                </td>
                <td>
                    <span className='total'>
                        {
                            '$' + totalVentasLocal || 0
                        }
                    </span>
                </td>
            </tr>
            {
                abajo ?
                    <>

                        <tr key={'A' + props.campania.CampaignId.toString()}>
                            <td colSpan={'100%'} className='extra-menu'>
                                <table className='sub-table'>
                                    <thead>
                                        <tr className='headers'>
                                            <th className='first' >
                                                {trans('RP_TICKET', props.lang)}
                                            </th>
                                            <th>
                                                {trans('RP_TRANSACTIONS_NO', props.lang)}
                                            </th>
                                            <th>
                                                {trans('RP_SALES', props.lang)}
                                            </th>
                                            <th>
                                                {trans('RP_PRODUCTS', props.lang)}
                                            </th>
                                            <th className='last'>
                                                {trans('RP_COMMENTS', props.lang)}
                                            </th>
                                            <th className='last'>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            subArray
                                        }
                                        {
                                            localTicketsArray
                                        }
                                        {
                                            nuevoTicket
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                    </>
                    : <></>
            }
        </>
    )
}

function Ticket(props) {

    const [ticketNuevo, setTicketNuevo] = useState(false);
    const [ticketdeBase, setTicketdeBase] = useState(false);

    const [ticket, setTicket] = useState('');
    const [transacciones, setTransacciones] = useState('');
    const [venta, setVenta] = useState('');
    const [productos, setProductos] = useState('');
    const [productosNombre, setProductosNombre] = useState('');
    const [comentarios, setComentarios] = useState('');
    const [arrIndex, setArrIndex] = useState(null);

    const handleTicketChange = (event) => {
        setTicket(event.target.value);
    };
    const handleTransaccionChange = (event) => {
        setTransacciones(event.target.value);
    };
    const handleVentaChange = (event) => {
        setVenta(event.target.value);
    };
    const handleProductosChange = (event) => {
        setProductos(event.target.value);
    };
    const handleProductosNombreChange = (event) => {
        setProductosNombre(event.target.value);
    };
    const handleComentariosChange = (event) => {
        setComentarios(event.target.value);
    };

    let controller = new AbortController();
    let signal = controller.signal;
    const newTicket = async () => {

        // console.log('GUARDANDO COSITO', ticketNuevo, props.campania);

        controller = new AbortController();
        signal = controller.signal;

        let url = `${sellsCapture}/`;

        let ticketId = ticketNuevo ? null : props.ticket.Id;

        await doFetch(
            url,
            {
                method: 'post',
                headers: createHeaders(),
                body: JSON.stringify({
                    CampaignId: props.campania.CampaignId,
                    ProductId: productos,
                    Ticket: ticket,
                    Transaction: transacciones,
                    Amount: venta,
                    Comments: comentarios,
                    SalesId: ticketId
                }),
                signal,
            },
            res => {
                if ('success' in res && 'payload' in res && res.success) {

                    let raw = res.payload
                    if (raw.sale.length > 0) {
                        alert('El producto que quieres ingresar es incorrecto')
                    } else {
                        ticketNuevo ? resetFields() : <></>;
                        props.terminar();
                    }

                }
            },
        );
    }
    const resetFields = () => {
        setTicket('')
        setTransacciones('')
        setVenta('')
        setProductos(props.campania.ProductId)
        setProductosNombre(props.campania.Producto)
        setComentarios('')
    }


    const agregarTicket = () => {

        let ticketId = ticketNuevo ? null : null; //props.ticket.Id
        let ticketObj = {
            CampaignId: props.campania.CampaignId,
            ProductId: productos,
            Producto: productosNombre,
            Ticket: ticket,
            Transaction: transacciones,
            Amount: venta,
            Comments: comentarios,
            SalesId: ticketId
        }

        if (!ticketdeBase) {
            props.guardarenArray(ticketObj, ticketNuevo, arrIndex);

            ticketNuevo ? resetFields() : <></>
            // console.log('MODIFICANDO ALGO X')
            return;
        }
        ticketId = props.ticket.Id;

        ticketObj.id = ticketId;
        props.editarenBase(ticketObj)

        // SI MODIFICO UN TICKET EXISTENTE
        // crear un array con los tickets que hay que modificar y al guardar, hacer una modificación en masa.
    }

    useEffect(() => {
        if (props.ticket2) {
            setTicket(props.ticket2.Ticket)
            setTransacciones(props.ticket2.Transaction)
            setVenta(props.ticket2.Amount)
            setProductos(props.ticket2.ProductId)
            setProductosNombre(props.ticket2.Producto)
            setComentarios(props.ticket2.Comments)
            setArrIndex(props.index)
        } else {
            setTicketNuevo(true)
            setProductos(props.campania.ProductId)
            setProductosNombre(props.campania.Producto)
        }

        if (props.ticket) {
            // si es un ticket ya guardado
            // console.log('TICKET VIEJO')
            setTicketdeBase(true);

            setTicket(props.ticket.TicketCount)
            setTransacciones(props.ticket.TransactionCount)
            setVenta(props.ticket.Amount)
            setProductos(props.ticket.ProductId)
            setProductosNombre(props.ticket.Producto)
            setComentarios(props.ticket.Comments)
            setTicketNuevo(false)
        }
    }, [])

    return (
        <tr>
            <td className='input-cell'>
                <input type="text" className='table-input' value={ticket} onChange={handleTicketChange} />
            </td>
            <td className='input-cell'>
                <input type="text" className='table-input' value={transacciones} onChange={handleTransaccionChange} />
            </td>
            <td className='input-cell'>
                <input type="text" className='table-input' value={venta} onChange={handleVentaChange} />
            </td>
            <td className='input-cell'>
                <input hidden={true} type="text" className='table-input' value={productos} onChange={handleProductosChange} />
                <input type="text" className='table-input' value={productosNombre} disabled onChange={handleProductosNombreChange}/>
            </td>
            <td className='input-cell'>
                <input type="text" className='table-input' value={comentarios} onChange={handleComentariosChange} />
            </td>
            <td className='input-cell finals'>
                <button onClick={agregarTicket}>
                    <Save />
                </button>
            </td>
        </tr>
    )
}