import React from 'react';
import { withStyles } from '@material-ui/core';
import SideBarFilters from '../../components/Sidebar/components/SideBarFilters';

import PageTitle from '../../components/PageTitle';
import trans from './../../i18n/tran';
const exportReports = ({ classes, theme, ...props }) => {
  return (
    <React.Fragment>
      <PageTitle title={trans('PPTX_PAGE_TITLE', props.lang)} />
      <SideBarFilters
        showAutoload={false}
        lang={props.lang}
        buttonText={trans('PPTX_PAGE_ACTION', props.lang)}
        {...props}
        dateFontSize={'1.3rem'}
        isFullScreeen={true}
        singleProduct={props.singleProduct}
        hideHelp={true}
      />
    </React.Fragment>
  );
};

const styles = theme => ({
  tableWidget: {
    overflowX: 'auto',
    fontSize: '18px',
    //background: '#013f8a'
  },
  mainChartHeaderLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
  },
  mainChartHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mainChartHeaderLabels: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      order: 3,
      width: '100%',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartLegentElement: {
    fontSize: '18px !important',
    marginLeft: theme.spacing(1),
  },
});

export default withStyles(styles, { withTheme: true })(exportReports);
