import React from 'react';
import { withStyles } from '@material-ui/core';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import ErrorIcon from '@material-ui/icons/Error';
import TextFieldList from '../../../components/TextFieldList/TextFieldListView';
import trans from './../../../i18n/tran';

const Name = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <TextFieldList
      recalculateMessages={props.recalculateMessages}
      showAdErrors={true}
      ErrorIconClass={classes.errorIcon}
      ErrorMessageClass={classes.warningMessage}
      reset={props.reset}
      changeReset={props.changeReset}
      focusable={
        <Focusable
          id={props.componentInfo.focusId}
          refList={props.refList}
        ></Focusable>
      }
      id="CampaignName"
      widgetTitle={
        props.isModify ? trans('NEW_CAMPAIGN_NAME', props.lang) : trans('NEW_CAMPAIGN_NAME2', props.lang)
      }
      ErrorIcon={<ErrorIcon />}
      placeholder={trans('NEW_CAMPAIGN_NAME', props.lang)}
      multiline={false}
      variant="outlined"
      Error={props.componentInfo.getError(props)}
      changeError={props.componentInfo.getchangeError(props)}
      list={props.list}
      initListValue={props.list}
      hasInitValue={true}
      changeList={props.changeList}
      maxListSize={1}
      adLinkPreview={props.adLinkPreview}
      changeAdLinkPreview={props.changeAdLinkPreview}
      adLinkParameters={props.adLinkParameters}
      getMessage={props.componentInfo.getMessage(props)}
    ></TextFieldList>
  );
};
export default withStyles(styles, { withTheme: true })(Name);
