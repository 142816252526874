import { request, requestHeaders } from './helper';
import { getAdAccountsUrlAdminOnboardingPage } from '../../components/Constants';
import { GetAdAccountsResponse } from '../pages/onboarding/rest';

export const GetAdAccounts = async (businessId: number) => {
  try {
    if (!businessId) {
      const error: GetAdAccountsResponse = { success: false, payload: [] };
      return error;
    }
    const headers = requestHeaders();
    const url = new URL(getAdAccountsUrlAdminOnboardingPage);
    url.search = new URLSearchParams({
      businessId: `${businessId}`,
    }).toString();

    return await request<GetAdAccountsResponse>(url.toString(), {
      method: 'GET',
      headers: headers,
    });
  } catch (e) {
    console.log('getAdAccounts exception', e);
    const error: GetAdAccountsResponse = { success: false, payload: [] };
    return error;
  }
};
