import React, { useMemo } from 'react';
import { TextField } from 'formik-material-ui';
import { MenuItem, TextFieldProps } from '@material-ui/core';
import _ from 'lodash';
import { FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

export const CountryField = (
  props: JSX.IntrinsicAttributes & TextFieldProps & FieldProps,
) => {
  const { t } = useTranslation();
  const orderCountries = useMemo(() => {
    const countries = t(`countries`, { returnObjects: true }) as Country[];
    return _.orderBy(countries, country => country.label, ['asc']);
  }, [t]);

  return (
    <TextField select {...props} {...props.field}>
      {orderCountries.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export interface Country {
  value: string;
  label: string;
  phone: string;
  suggested?: boolean;
}
