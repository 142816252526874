import React from 'react';
import {
  withStyles,
  Checkbox,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import Widget from '../../../components/Widget/WidgetContainer';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import { linkDynamicParameters } from '../../../components/Constants';
import { changeLinkPreview } from '../../../utils/functions';
import trans from './../../../i18n/tran';
const UTM = ({ classes, theme, onChange, value, ...props }) => {
  if (props.reset) return <div></div>;
  else
    return (
      <Widget
        overflow={true}
        focusable={
          <Focusable id={props.focusId} refList={props.refList}></Focusable>
        }
        title={trans('NEW_CAMPAIGN_LINK_PARAM', props.lang)}
        bodyClass={classes.tableWidget}
        disableWidgetMenu={true}
      >
        <Grid container>
          {linkDynamicParameters.map((parameter, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event, checked) => {
                      let newParameters = [];
                      if (checked) {
                        newParameters = props.adLinkParameters;
                        newParameters.push(parameter.value);
                        props.changeAdLinkParameters(newParameters);
                      } else {
                        newParameters = [];
                        props.adLinkParameters.forEach(element => {
                          if (element !== parameter.value)
                            newParameters.push(element);
                        });

                        props.changeAdLinkParameters(newParameters);
                      }

                      changeLinkPreview(props, newParameters, props.adLinks[0]);
                    }}
                  ></Checkbox>
                }
                label={parameter.label}
              />
            </Grid>
          ))}
        </Grid>
      </Widget>
    );
};
export default withStyles(styles, { withTheme: true })(UTM);
