import { compose, withState, lifecycle, withHandlers } from 'recompose';
import View from './ListProductView';
import {
  alertPopUp,
  alertConfirmPopUp,
  getProductAdminApi,
  getOnBoardingv2,
} from '../../utils/functions';
import { deleteProductRequest } from '../../components/ApiClient/Product';
import { onBoardingOK } from '../../components/Constants';
import { returnToModifyPage } from '../product/Container';

const mycompose = compose(
  withState('refresh', 'changeRefresh', false),
  withState(
    'campaignsWithoutOptimizationFiltered',
    'changeCampaignsWithoutOptimizationFiltered',
    [],
  ),
  withState(
    'campaignsWithoutOptimization',
    'changeCampaignsWithoutOptimization',
    [],
  ),
  withState(
    'campaignsWithoutProductFiltered',
    'changeCampaignsWithoutProductFiltered',
    [],
  ),

  withState('campaignsWithoutProduct', 'changeCampaignsWithoutProduct', []),

  withState('assignProduct', 'changeAssignProduct', true),
  withState('assignOptimization', 'changeOptimizationType', false),

  withState('selectorCategory', 'changeSelectorCategory', []),
  withState('selectedCategories', 'changeSelectedCategories', []),

  withState('selectorAdminAdAccount', 'changeSelectorAdminAdAccount', []),
  withState('selectedAdminAdAccounts', 'changeSelectedAdminAdAccounts', []),

  withState('selectorAdminBrand', 'changeSelectorAdminBrand', []),
  withState('selectedAdminBrands', 'changeSelectedAdminBrands', []),

  withState('showCreateProduct', 'changeShowCreateProduct', false),
  withState('modifyProductRow', 'changemodifyProductRow', []),

  withState('responseError', 'setResponseError', ''),
  withState('deleteClicked', 'setDeleteClicked', []),

  withState('pagePagination', 'changePagePagination', 0),
  withState('rowsPerPage', 'changeRowsPerPage', 5),

  withState('initProductList', 'changeProductList', true),
  lifecycle({
    componentDidMount() {
      this.props.changeTenantChangeFunction({
        function: newTenenatValue => {},
      });
    },

    componentWillUnmount() {
      //window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
    componentDidUpdate() {},
  }),
  withHandlers({
    setDeleteClicked: props => row => {
      alertConfirmPopUp(
        '¿Está seguro que desea eliminar el producto ' + row[0].label + '?',
        props,
        () => {
          deleteProductRequest(row[0].value, (error, result) => {
            //
            if (result !== undefined) {
              alertPopUp(
                'El Producto ha sido eliminado con éxito',
                props,
                false,
                false,
                async () => {
                  returnToModifyPage(props);
                  let onBoarding = await getOnBoardingv2(props);
                  if (onBoarding.onBoardingStatus === onBoardingOK)
                    getProductAdminApi(props, props.selectedTenants);
                },
              );
            } else if (error) {
              alertPopUp(
                'Verifique que no tenga Campañas asociadas.',
                props,
                true,
              );
            }
          });
        },
      );
    },
  }),
);

export default mycompose(View);
