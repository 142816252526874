import {
  getAdAccountsByProduct,
  getCampaignTypesUrl,
  getProductsUrl,
  getAgeUrl,
  getGenderUrl,
  todosSelectorValue,
  todasSelectorValue,
  accountSelectorId,
  companySelectorId,
  brandSelectorId,
  adAccountSelectorId,
  campaignSelectorId,
  categorySelectorId,
  productSelectorId,
  ageSelectorId,
  genderSelectorId,
  sideBarDatePicker,
  getAdAccountsByProductUrlAdminApi,
  getAdAccountsUrlAdminApi,
  getCompaniesUrlAdminApi,
  getBrandsUrlAdminApi,
  getAdPreviewUrlAdminApi,
  getFanPagesUrlAdminApi,
  getLeadFormUrlAdminApi,
  getPixelUrlAdminApi,
  getPixelStatsUrlAdminApi,
  getApplicationsUrlAdminApi,
  getInstagramAccountUrlAdminApi,
  getCategoriesUrlAdminApi,
  getDataset10Url,
  getDataset11Url,
  getAdAccountsUrl,
  Environment,
  getProductsUrlAdminApi,
  facebookCampaignSelectorId,
  getfacebookCampaignsUrl,
  getAdAccountsUrlStatsApi,
  getfacebookCampaignsWithoutProductAdminUrl,
  getfacebookCampaignsWithFilters,
  setProductsToAdsets,
  getBrandsUrl,
  getCompaniesUrl,
  campaignNameSelectorId,
  getfacebookCampaignNames,
  getDataset10UrlCharts,
  getDataset10UrlChartsTotal,
  getCampaignStatsUrl,
  getDataset4,
  getDataset5,
  getDataset6,
  getDataset7,
  getDataset8,
  createAccountUrl,
  linkAccountWithUserUrl,
  conflictHttpError,
  internalServerErrorHttpError,
  notFoundHttpError,
  successHttpCode,
  serviceUnavailableHttpError,
  unauthorizedHttpError,
  addAdAccountToAccountUrl,
  methodNotAllowedHttpError,
  getadAccountByUserAdminApiUrl,
  getpresentationUrl,
  badParametersHttpError,
  assetBase64Url,
  fileNotSupportedHttpCode,
  getOnBoardingUrl,
  onBoardingNoClient,
  onBoardingNoAdAccounts,
  onBoardingNoBBAdAccounts,
  onBoardingNoProducts,
  onBoardingMantainance,
  onBoardingOK,
  mantainanceUrl,
  homeUrl,
  showWhileloadingpages,
  getGeoLocationUrl,
  onBoardingBilling,
  getAccountsUrlAdminApiWithoutStatusValidation,
  getCampaignsAlyUrl,
  getcampaignManagerUniqueUrl,
  getCustomAudience,
  getSavedAudience,
  noContentHttpCode,
  getAdInterestsUrl,
  standardPixelEvents,
  facebookGasHttpErrorCode,
  getAdTargetingCategoryUrl,
  relationshipStatuses,
  educationStatuses,
  glosarioPowerPoint,
  getCampaignByProductAndDate,
  getAccountToActivateStaff,
  getAvailableAccountsUrlAdminApi,
  activateAccountUrl,
  homePagePageId,
  release1AdsPageId,
  adaccountsPageId,
  exportPagePageId,
  clientApprovalPageId,
  downloadstatsPageId,
  createadPageId,
  productPageId,
  modifyProductPageId,
  assignPageId,
  initPageId,
  approvalPageId,
  billingPageId,
  billingPagePath,
  newAccountPageId,
  getNewNonceUrl,
  defaultPermissions,
  newAccountPagePath,
  adaccountsPagePath,
  productPagePath,
  homePagePagePath,
  getAccountUsersUrl,
  modifyAccountUsersUrl,
  deleteAccountUsersUrl,
  userPageId,
  deleteAdAccountsUrlAdminAdAccountPage,
  objectives,
  leadGenerationObjective,
  bidStrategyLeadForms,
  linkClicksObjective,
  bidStrategyLinkCLicks,
  conversionsObjective,
  bidStrategyConversions,
  appInstallObjective,
  bidStrategyAppInstall,
  conversationsObjective,
  bidStrategyConversations,
  staffRoleId,
  getPayAlyProductsUrl,
  getPayAlyCountriesUrl,
  getPayAlyCompanyInfoUrl,
  payAlysaveUserUrl,
  generatePayAlyLinkUrl,
  getPayAlyCompanyLinks,
  getPayAlyCompanyCurrentSubscription,
  getPayAlyCompanySubscriptions,
  subscriptionsPagePath,
  subscriptionsPageId,
  onBoardingInactive,
  payAlyCancelSubscriptions,
  pptxStyle,
  AppAssetsUrlPre,
  GAEventCategoryLinkPayaly,
  GAEventActionLinkPayaly,
  GAEventCategoryLinkPayalyPaid,
  GAEventActionLinkPayalyPaid,
  GAEventCategoryAccount,
  GAEventActionNewAccount,
  GAEventActionLinkAccount,
  sessionStorageUTMValue,
  billingLinkPayalyGA,
  accountNewAccountGA,
  accountAdAccountGA,
} from '../components/Constants';
import { fullAdComponentsList } from './createAdMapping';
import { createAddMapping } from './createAdMapping';
import validator from 'validator';
import pptxgen from 'pptxgenjs';
import Auth from '../authentication/Auth';
import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { setGASessionItem, getGASessionItem, sendGAEvent } from './ga';
import trans from './../i18n/tran';

let counter = 0;
let customPagesMapping = [
  {
    customPageId: homePagePageId,
    parentComponent: sideBarDatePicker,
    initFunction: getCompanies,
    finalFunction: getDataset10Charts,
    mapping: [
      {
        componentId: sideBarDatePicker,
        childId: accountSelectorId,
        childFunction: getCompanies,
        childNeededParams: ['selectedDates'],
      },
      {
        componentId: companySelectorId,
        childId: brandSelectorId,
        childFunction: getBrands,
        childNeededParams: ['selectedCompanies'],
      },
      {
        componentId: brandSelectorId,
        childId: categorySelectorId,
        childFunction: getProducts,
        childNeededParams: ['selectedBrands', 'selectedCompanies'],
      },
      {
        componentId: productSelectorId,
        childId: campaignSelectorId,
        childFunction: getCampaignTypes,
        childNeededParams: ['selectedProducts'],
      },
      {
        componentId: campaignSelectorId,
        childId: adAccountSelectorId,
        childFunction: getAdAccouts,
        childNeededParams: ['selectedCampaignTypes', 'selectedProducts'],
      },
      {
        componentId: adAccountSelectorId,
        childId: campaignNameSelectorId,
        childFunction: getCampaignNames,

        childNeededParams: [
          'selectedAdAccounts',
          'selectedProducts',
          'selectedCampaignTypes',
        ],
      },
      {
        componentId: campaignNameSelectorId,
        childId: ageSelectorId,
        childFunction: getAges,
        childNeededParams: ['selectedAdAccounts'],
      },
      {
        componentId: ageSelectorId,
        childId: genderSelectorId,
        childFunction: getGenders,
        childNeededParams: ['selectedAges'],
      },
      {
        componentId: genderSelectorId,
        childId: 'dataSet10Id',
        childFunction: getDataset10Charts,
        // it is empty because if the value is not in params, it will go and get it
        //from the props and will be sent in componentNeededParams of Child
        childNeededParams: [],
      },
      {
        componentId: 'dataSet10Id',
        componentNeededParams: [
          'selectedDates',
          'selectedTenants',
          'selectedCompanies',
          'selectedBrands',
          'selectedProducts',
          'selectedCampaignTypes',
          'selectedAdAccounts',
          'selectedCampaignNames',
          'selectedGenders',
          'selectedAges',
        ],
        dashboards: [
          'dashboard31',
          'dashboard32',
          'dashboard33',
          'dashboard34',
          'dashboard35',
          'dashboard36',
        ],
        childId: undefined,
        childFunction: undefined,
      },
      {
        componentId: 'NeededParams',
        componentNeededParams: [
          'selectedDates',
          'selectedTenants',
          'selectedCompanies',
          'selectedBrands',
          'selectedProducts',
          'selectedCampaignTypes',
          'selectedAdAccounts',
          'selectedCampaignNames',
          'selectedGenders',
          'selectedAges',
        ],
      },
    ],
  },
  {
    customPageId: exportPagePageId,
    parentComponent: sideBarDatePicker,
    initFunction: getCompanies,
    finalFunction: generateppt,
    mapping: [
      {
        componentId: sideBarDatePicker,
        childId: accountSelectorId,
        childFunction: getCompanies,
        childNeededParams: ['selectedDates'],
      },
      {
        componentId: companySelectorId,
        childId: brandSelectorId,
        childFunction: getBrands,
        childNeededParams: ['selectedCompanies'],
      },
      {
        componentId: brandSelectorId,
        childId: categorySelectorId,
        childFunction: getProducts,
        childNeededParams: ['selectedBrands', 'selectedCompanies'],
      },
      {
        componentId: productSelectorId,
        childId: campaignSelectorId,
        childFunction: getAdAccouts,
        childNeededParams: ['selectedProducts'],
      },

      {
        componentId: adAccountSelectorId,
        childId: campaignNameSelectorId,
        childFunction: getCampaignNamesWithDate,

        childNeededParams: [
          'selectedDates',
          'selectedAdAccounts',
          'selectedProducts',
        ],
      },
      {
        componentId: campaignNameSelectorId,
        childId: 'reportsPptxId',
        childFunction: generateppt,
        // it is empty because if the value is not in params, it will go and get it
        //from the props and will be sent in componentNeededParams of Child
        childNeededParams: [],
      },

      {
        componentId: 'reportsPptxId',
        componentNeededParams: [
          'selectedDates',
          'selectedTenants',
          'selectedCompanies',
          'selectedBrands',
          'selectedProducts',
          'selectedAdAccounts',
          'selectedCampaignNames',
        ],
        dashboards: [],
        childId: undefined,
        childFunction: undefined,
      },
      {
        componentId: 'NeededParams',
        componentNeededParams: [
          'selectedDates',
          'selectedTenants',
          'selectedCompanies',
          'selectedBrands',
          'selectedProducts',
          'selectedAdAccounts',
          'selectedCampaignNames',
        ],
      },
    ],
  },

  {
    customPageId: release1AdsPageId,
    parentComponent: accountSelectorId,
    initFunction: getAdAccountsWithoutDate,
    finalFunction: dashboardCampaignChanged,
    mapping: [
      {
        componentId: sideBarDatePicker,
        childId: adAccountSelectorId,
        childFunction: getAdAccountsWithoutDate,
        childNeededParams: ['selectedDates'],
      },
      {
        componentId: adAccountSelectorId,
        childId: facebookCampaignSelectorId,
        childFunction: getfacebookCampaigns,
        childNeededParams: ['selectedAdAccounts', 'selectedDates'],
      },
    ],
  },
  {
    customPageId: initPageId,

    initFunction: (params, props) => {
      getInitAdAccounts(props);
      //props.changeIsLoadingLayout(false);
    },
    mapping: [],
  },
  {
    customPageId: clientApprovalPageId,

    initFunction: (params, props) => {
      props.changeIsLoadingLayout(false);
    },
    mapping: [],
  },
  {
    customPageId: newAccountPageId,

    initFunction: (params, props) => {
      props.changeIsLoadingLayout(false);
    },
    mapping: [],
  },
  {
    customPageId: createadPageId,
    initFunction: (params, props) => {
      if (params.selectedTenants) {
        getProductAdminApiFiltersBrandandCompany(props, params.selectedTenants);
        props.changeSelectedAdminAccounts(params.selectedTenants);
      }
    },
    mapping: [],
  },
  {
    customPageId: productPageId,
    initFunction: (params, props) => {
      if (params.selectedTenants) {
        getAdAccountsApiAdmin(props, params.selectedTenants);
        getCompanyApiAdmin(props, params.selectedTenants);
        // we send  [{value:0}] since we dont mind what company is selected, api always returns all
        // debugger;
        getBrandApiAdmin(props, params.selectedTenants, [{ value: 0 }]);
      }
    },
    mapping: [],
  },
  {
    customPageId: modifyProductPageId,
    initFunction: (params, props) => {
      if (params.selectedTenants) {
        getProductAdminApi(props, params.selectedTenants);
        getCompanyApiAdmin(props, params.selectedTenants);
      }
    },
    mapping: [],
  },
  {
    customPageId: assignPageId,
    initFunction: (params, props) => {
      if (params.selectedTenants) {
        getAdAccountsApiAdmin(props, params.selectedTenants);
        refreshCustomPage(props.changeUpdateTable, 100, 100);
      }
    },
    mapping: [],
  },
  {
    customPageId: approvalPageId,
    initFunction: (params, props) => {
      getAccountsApiAdmin(props, false, true);
      props.changeIsLoadingLayout(false);
    },
    mapping: [],
  },

  {
    customPageId: downloadstatsPageId,
    initFunction: (params, props) => {
      if (params.selectedTenants) {
        getProductAdminApiFiltersBrandandCompany(
          props,
          params.selectedTenants,
          [],
          true,
        );

        props.changeSelectedAdminProducts([]);
        props.changeSelectedAdminAdAccounts([]);
        props.changeSelectorAdminAdAccount([]);
        props.changeFileName('');
      }
    },
    mapping: [],
  },
  {
    customPageId: userPageId,
    initFunction: (params, props) => {
      if (params.selectedTenants) {
        getAccountsApiAdmin(
          props,
          true,
          props.isApproval,
          false,
          undefined,
          undefined,
          false,
          true,
        );
      }
    },
    mapping: [],
  },
  {
    customPageId: adaccountsPageId,
    initFunction: (params, props) => {},
    mapping: [],
  },

  {
    customPageId: billingPageId,
    initFunction: (params, props) => {
      props.changeIsLoadingLayout(false);
    },
    mapping: [],
  },
  {
    customPageId: subscriptionsPageId,
    initFunction: (params, props) => {
      props.changeIsLoadingLayout(false);
    },
    mapping: [],
  },
];

export function addComponent(
  props,
  pageId,
  component,
  environmentList = ['DEV', 'QA', 'PROD'],
  showIfInactive = false,
) {
  let userPages = getUserPages();

  if (userPages) {
    if (userPages.userPages.length > 0) {
      if (
        userHasPermision(props, userPages, pageId) ||
        (showIfInactive && sessionStorage.getItem('inactive') !== null)
      ) {
        if (environmentList.includes(Environment)) return component;
        else return '';
      }
    }
  } else return '';
}

// Ad Menu if user has access to it
export function addMenu(props, component) {
  return component;
}

export function runInitFunction(props, newTenant, pageId) {
  if (!pageId) pageId = props.customPageId;
  let PageInfo = getCustomPageInfo(pageId);
  props.changeIsFirstLoad(true);
  let params = [];
  params.selectedDates = props.selectedDates;
  params.selectedTenants = newTenant;

  PageInfo.initFunction(params, props, true);
}

function userHasPermision(props, userPages, pageId) {
  if (!userPages) userPages = getLocalUserPages(props);
  if (!pageId) pageId = props.customPageId;
  return userPages.userPages.includes(pageId);
}
function getUserPages() {
  let userPages = sessionStorage.getItem('userPages');
  if (userPages !== null && userPages !== undefined) {
    userPages = JSON.parse(userPages);
    if (sessionStorage.getItem('inactive') === null) {
      userPages.userPages.push(homePagePageId);
    }
  } else if (sessionStorage.getItem('inactive') === null) {
    userPages = defaultPermissions;
    //adding home page since this is the page used to select tenant
    userPages.userPages.push(homePagePageId);
  } else return defaultPermissions;
  return userPages;
}
export function getLocalUserPages(props) {
  let userPages = sessionStorage.getItem('userPages');
  if (userPages !== null) {
    userPages = JSON.parse(userPages);
  } else return defaultPermissions;
  return userPages;
}

function updatePropsTenants(props, newTenantValue) {
  let newTenant = [];
  if (!newTenantValue) {
    newTenant = [];
    if (props.selectedTenants.length > 0)
      if (props.selectedTenants.length > 0) props.changeSelectedTenants([]);
  } else {
    newTenant = [newTenantValue];
    if (newTenantValue.value === -1) {
      if (props.selectedTenants.length > 0)
        if (props.selectedTenants.length > 0) props.changeSelectedTenants([]);

      //window.location.replace(newAccountUrl);
      return;
    } else if (!equalSelectorArrays(props.selectedTenants, newTenant))
      props.changeSelectedTenants(newTenant);
  }
  return newTenant;
}

export async function getPayAlyCountries(props) {
  var myHeaders = createHeaders();

  return await customFetchAsync(
    getPayAlyCountriesUrl,
    {
      method: 'GET',
      headers: myHeaders,
    },
    (result, params, props, updateSelected, status) => {
      let countries = [];
      if (status === successHttpCode) {
        if (result && result.length > 0) {
          countries = result.map(({ Id, Name }) => ({
            value: Id,
            label: Name,
          }));
        }
      }
      props.changeSelectorCountryCode(countries);
      runChildFunction(
        props.customPageId,
        productSelectorId,
        props,
        params,
        updateSelected,
      );
    },
    [],
    props,
    false,
    true,
  );
}

export async function getPayAlySubscriptions(props) {
  var myHeaders = createHeaders();
  let uniqueKey = undefined;
  if (props.selectedTenants && props.selectedTenants.length > 0)
    uniqueKey = props.selectedTenants[0].UniqueKey;
  return await customFetchAsync(
    getPayAlyCompanySubscriptions + '/' + uniqueKey,
    {
      method: 'GET',
      headers: myHeaders,
    },
    (result, params, props, updateSelected, status) => {
      let subscriptions = [];
      if (status === successHttpCode) {
        if (result && result.length > 0) {
          subscriptions = result;
          subscriptions.forEach(ele => {
            ele.Date = new Date(ele.PaidDate).toLocaleDateString('es-ES', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            });
            ele.Status = ele.Code === '00' ? 'PAGADO' : 'ERROR';
          });
        }
      }
      props.changeCompanySubscriptions(subscriptions);
      runChildFunction(
        props.customPageId,
        productSelectorId,
        props,
        params,
        updateSelected,
      );
    },
    [],
    props,
    false,
    true,
  );
}
//validateActiveSubscriptions if it has active suscriptions it will be redirected to suscriptions page
export async function getPayAlyCurrentSubscription(
  props,
  endFunction,
  validateActiveSubscriptions = false,
) {
  var myHeaders = createHeaders();
  let uniqueKey = undefined;
  if (props.selectedTenants && props.selectedTenants.length > 0)
    uniqueKey = props.selectedTenants[0].UniqueKey;
  return await customFetchAsync(
    getPayAlyCompanyCurrentSubscription + '/' + uniqueKey,
    {
      method: 'GET',
      headers: myHeaders,
    },
    (result, params, props, updateSelected, status) => {
      let subscriptions = [];
      if (status === successHttpCode) {
        if (result && result.length > 0) {
          subscriptions = result;
          if (
            validateActiveSubscriptions &&
            sessionStorage.getItem('onBoardingStatus') !==
              onBoardingInactive.toString() &&
            sessionStorage.getItem('onBoardingStatus') !==
              onBoardingBilling.toString()
          ) {
            if (props.history) {
              props.history.push(subscriptionsPagePath);
            }
            return;
          } else if (
            validateActiveSubscriptions &&
            sessionStorage.getItem('onBoardingStatus') ===
              onBoardingInactive.toString()
          ) {
            sessionStorage.setItem('onBoardingMessage', 'Cuenta deshabilitada');
            sessionStorage.setItem('showOnBoardingMessage', true);
            sessionStorage.setItem('clientApproval', true);
          }
        }
      }
      props.changeCompanyCurrentSubscription(subscriptions);
      if (endFunction) endFunction();
      else
        runChildFunction(
          props.customPageId,
          productSelectorId,
          props,
          params,
          updateSelected,
        );
    },
    [],
    props,
    false,
    true,
  );
}
export async function getPayAlyLinks(props) {
  var myHeaders = createHeaders();
  let uniqueKey = undefined;

  if (props.selectedTenants && props.selectedTenants.length > 0)
    uniqueKey = props.selectedTenants[0].UniqueKey;
  return await customFetchAsync(
    getPayAlyCompanyLinks + '/' + uniqueKey,
    {
      method: 'GET',
      headers: myHeaders,
    },
    (result, params, props, updateSelected, status) => {
      let links = [];
      if (status === successHttpCode) {
        if (result && result.length > 0) {
          result.forEach(element => {
            if (!element.PayStatus)
              element.LinkButton = (
                <Button
                  fullWidth={true}
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    window.location.replace(element.Link + '/1');
                  }}>
                  Abrir
                </Button>
              );
            else
              element.LinkButton = (
                <Button
                  fullWidth={true}
                  variant="contained"
                  size="medium"
                  color="primary"
                  disabled={true}>
                  Pagado
                </Button>
              );
          });
          links = result;
        }
      }
      props.changeCompanyLinks(links);
      runChildFunction(
        props.customPageId,
        productSelectorId,
        props,
        params,
        updateSelected,
      );
    },
    [],
    props,
    false,
    true,
  );
}

export async function generatePayAlyLink(props) {
  var myHeaders = createHeaders();
  let uniqueKey = undefined;

  if (props.selectedTenants && props.selectedTenants.length > 0)
    uniqueKey = props.selectedTenants[0].UniqueKey;
  let body = {
    uniqueKey: uniqueKey,
    productId: props.selectedProduct,
  };
  return await customFetchAsync(
    generatePayAlyLinkUrl,
    {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(body),
    },
    (result, params, props, updateSelected, status) => {
      let link = undefined;
      if (status === successHttpCode) {
        runChildFunction(
          props.customPageId,
          productSelectorId,
          props,
          params,
          updateSelected,
        );

        link = result[0].link;
        if (link && link.length > 0) {
          window.location.replace(link + '/1');

          setGASessionItem(billingLinkPayalyGA, sessionStorageUTMValue, link);
          let sendGAdataBillingLinkPayaly = getGASessionItem();

          sendGAEvent({
            category: GAEventCategoryLinkPayaly,
            action: GAEventActionLinkPayaly,
            label: sendGAdataBillingLinkPayaly,
          });
        } else {
          link = undefined;
          alertPopUp(
            'Hubo un error en la solicitud realizada, revisar conexión a Internet y pruebe nuevamente',
            props,
            true,
          );
        }
      } else if (status === notFoundHttpError) {
        alertPopUp(
          'Hubo un Problema con el Producto seleccionado',
          props,
          true,
        );
      } else {
        alertPopUp(
          'Hubo un error en la solicitud realizada, revisar conexión a Internet y pruebe nuevamente',
          props,
          true,
        );
      }
      //leave the loading screen while it loads pay aly
      if (!link)
        runChildFunction(
          props.customPageId,
          productSelectorId,
          props,
          params,
          updateSelected,
        );
    },
    [],
    props,
    false,
    true,
  );
}
export async function savePayAlyCompanyInfo(props) {
  var myHeaders = createHeaders();
  let uniqueKey = undefined;

  if (props.selectedTenants && props.selectedTenants.length > 0)
    uniqueKey = props.selectedTenants[0].UniqueKey;
  let body = {
    uniqueKey: uniqueKey,
    companyName: props.companyName,
    nit: props.nit,
    countryCode: props.selectedCountryCode[0].value,
    companyAddress: props.companyAddress,
    email: props.email.trim(),
    clientFirstName: props.selectedTenants[0].label,
    clientLastName: 'Aly AI',
    phone: props.phone,
  };
  return await customFetchAsync(
    payAlysaveUserUrl,
    {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(body),
    },
    (result, params, props, updateSelected, status) => {
      if (status === successHttpCode) {
        runChildFunction(
          props.customPageId,
          productSelectorId,
          props,
          params,
          updateSelected,
        );
        return true;
      } else if (status === conflictHttpError) {
        alertPopUp(
          'Favor colocar otro correo electrónico, ingresado ya en uso',
          props,
          true,
        );
      } else {
        alertPopUp(
          'Hubo un error en la solicitud realizada, revisar conexión a Internet y pruebe nuevamente',
          props,
          true,
        );
      }
      runChildFunction(
        props.customPageId,
        productSelectorId,
        props,
        params,
        updateSelected,
      );
      return false;
    },
    [],
    props,
    false,
    true,
  );
}

export async function cancelPayAly(props) {
  alertConfirmPopUp(
    '¿Seguro que desea cancelar la suscripcion?',
    props,
    async () => {
      var myHeaders = createHeaders();
      let uniqueKey = undefined;

      if (props.selectedTenants && props.selectedTenants.length > 0)
        uniqueKey = props.selectedTenants[0].UniqueKey;
      let body = {
        uniqueKey: uniqueKey,
      };
      if (uniqueKey) {
        return await customFetchAsync(
          payAlyCancelSubscriptions,
          {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(body),
          },
          (result, params, props, updateSelected, status) => {
            if (status === successHttpCode) {
              window.location.reload();
              return true;
            } else {
              alertPopUp(
                'Hubo un error en la solicitud realizada, revisar conexión a Internet y pruebe nuevamente',
                props,
                true,
              );
            }
            runChildFunction(
              props.customPageId,
              productSelectorId,
              props,
              params,
              updateSelected,
            );
            return false;
          },
          [],
          props,
          false,
          true,
        );
      } else
        runChildFunction(
          props.customPageId,
          productSelectorId,
          props,
          [],
          false,
        );
    },
    () => {
      runChildFunction(props.customPageId, productSelectorId, props, [], false);
    },
  );
}
export async function getPayAlyCompanyInfo(props) {
  var myHeaders = createHeaders();
  let url = getPayAlyCompanyInfoUrl;
  if (props.selectedTenants && props.selectedTenants.length > 0)
    url += '/' + props.selectedTenants[0].UniqueKey;
  else {
    return;
  }
  return await customFetchAsync(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    (result, params, props, updateSelected, status) => {
      if (status === successHttpCode) {
        if (result && result.length > 0) {
          props.changeCompanyName(result[0].Name);
          props.changeNit(result[0].Nit);
          props.changeSelectedCountryCode([
            { label: result[0].CountryName, value: result[0].CountryCode },
          ]);
          props.changeCompanyAddress(result[0].Address);
          props.changeEmail(result[0].Email);
          props.changePhone(result[0].Phone);
        }
      }

      runChildFunction(
        props.customPageId,
        productSelectorId,
        props,
        params,
        updateSelected,
      );
    },
    [],
    props,
    false,
    true,
  );
}

export async function getPayAlyProducts(props) {
  var myHeaders = createHeaders();
  let url = getPayAlyProductsUrl;

  if (props.selectedTenants && props.selectedTenants.length > 0)
    url += '/' + props.selectedTenants[0].UniqueKey;
  return await customFetchAsync(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    (result, params, props, updateSelected, status) => {
      let products = [];
      if (status === successHttpCode) {
        if (result && result.length > 0) {
          products = result;
        }
      }
      props.changeProducts(products);
      runChildFunction(
        props.customPageId,
        productSelectorId,
        props,
        params,
        updateSelected,
      );
    },
    [],
    props,
    false,
    true,
  );
}
export async function getNewNonceAndUserPages(props) {
  var myHeaders = createHeaders();
  let tenantId = getLocalStorageTenant();
  if (tenantId && tenantId.value) tenantId = tenantId.value;
  else tenantId = '';

  if (getLocalStorageNonce()) {
    let url = getNewNonceUrl + '?accountId=' + tenantId;
    return await customFetchAsync(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      (result, params, props, updateSelected, status) => {
        let userPages = { userPages: [], defaultPage: '' };

        updateNonce(result.Nonce);
        if (status === successHttpCode) {
          if (result.JsonPages) {
            try {
              userPages = JSON.parse(result.JsonPages);
              if (userPages.userPages.length === 0)
                userPages = defaultPermissions;
              else if (
                userPages.userPages.length === 1 &&
                userPages.userPages[0] === 'inactive'
              ) {
                sessionStorage.removeItem('inactive');
                sessionStorage.setItem('inactive', 'true');
              } else sessionStorage.removeItem('inactive');
            } catch {
              userPages = defaultPermissions;
            }
          } else userPages = defaultPermissions;
          /*userPages = [
						homePagePageId,
						release1AdsPageId,
						adaccountsPageId,
						exportPagePageId,
						clientApprovalPageId,
						downloadstatsPageId,
						createadPageId,
						productPageId,
						modifyProductPageId,
						assignPageId,
						initPageId,
						approvalPageId,
						rolesPageId,
						billingPageId,
					];*/
        } else userPages = defaultPermissions;

        userPages.userPages.push(newAccountPageId);

        //props.changeUserPages(userPages);
        sessionStorage.removeItem('userPages');
        sessionStorage.setItem('userPages', JSON.stringify(userPages));
        return userPages;
      },
      [],
      props,
      false,
      true,
    );
  } else {
    sessionStorage.removeItem('userPages');
    sessionStorage.setItem('userPages', JSON.stringify(defaultPermissions));
    return defaultPermissions;
  }
}

export async function tenantValidation(
  props,
  newTenantValue,
  pageId,
  tenantChanged = false,
  tenantSelectorChanged = false,
) {
  // console.debug('tenantValidation');
  let newSessionTenant = false;
  if (sessionStorage.getItem('selectedTenants') === null)
    newSessionTenant = true;
  let newTenant = [{ value: -1, lable: 'noneSelected' }];
  newTenant = updatePropsTenants(props, newTenantValue);

  if (tenantChanged) {
    localStorage.removeItem('selectedTenants');
    sessionStorage.removeItem('selectedTenants');
    if (newTenant.length > 0) {
      localStorage.setItem('selectedTenants', JSON.stringify(newTenant[0]));
      sessionStorage.setItem('selectedTenants', JSON.stringify(newTenant[0]));
    }

    await getNewNonceAndUserPages(props);
  }
  let onBoarding = await getOnBoardingv2(props);

  if (onBoarding && onBoarding.continue) {
    if (
      tenantSelectorChanged &&
      props.tenantChangeFunction &&
      props.tenantChangeFunction.function &&
      !newSessionTenant &&
      pageId === onBoarding.pageId
    )
      props.tenantChangeFunction.function(newTenantValue);
    runInitFunction(props, newTenant, onBoarding.pageId);
  } else {
    props.changeIsLoadingLayout(false);
  }
  return onBoarding;
}

export function alertPopUp(
  message,
  props,
  error = false,
  warning = false,
  acceptFunction = () => {},
  acceptLabel = trans('ACCEPT', props.lang),
) {
  props.changeShowSuccessAlert(!error);
  props.changeShowErrorAlert(error);
  props.changePopUpAlertMessage(message);
  props.changeShowWarningAlert(warning);
  props.changePopUpAcceptLabel(acceptLabel);
  props.changeConfirmPopUpAcceptFunction({ function: acceptFunction });
}
export function alertConfirmPopUp(
  message,
  props,
  acceptFunction = () => {},
  cancelFunction = () => {},
  acceptLabel = trans('ACCEPT', props.lang),
) {
  props.changePopUpAlertMessage(message);
  props.changeShowConfirmPopUp(true);
  props.changeConfirmPopUpAcceptFunction({ function: acceptFunction });
  props.changeConfirmPopUpCancelFunction({ function: cancelFunction });
  props.changePopUpAcceptLabel(acceptLabel);
}
export function calculateRemainingDays(dates, fromToday = false) {
  if (dates && dates.length && dates.length > 1) {
    let startDate = new Date(dates[0].getTime());
    let endDate = new Date(dates[1].getTime());
    if (fromToday) startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59);
    return startDate.getTime() <= endDate.getTime()
      ? Math.ceil(
          (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24),
        )
      : 0;
  } else return 0;
}
export function calculateRemainingBudget(
  dailyBudget,
  dates,
  props,
  originalBudget,
  modified = true,
) {
  if (dates && dates.length > 1) {
    dates[1].setHours(23, 59, 59);
  }
  if (isNumeric(dailyBudget)) dailyBudget = parseFloat(dailyBudget);
  else dailyBudget = 0;
  let remainingDays = calculateRemainingDays(dates, true);

  props.changeModifyCampaignRemaining({
    modified: modified,
    value: [remainingDays],
  });
  let remainingBudget = dailyBudget * remainingDays;
  props.changeModifyCampaignRemainingSpent({
    modified: modified,
    value: [remainingBudget],
  });

  let spent = props.modifyCampaignSpent.value;
  if (spent) {
    if (isNumeric(spent[0])) {
      spent = parseFloat(spent[0]);
    } else spent = 0;
  } else spent = 0;

  if (!originalBudget)
    originalBudget = props.modifyCampaignOriginalBudget.value[0];
  if (originalBudget) {
    if (isNumeric(originalBudget)) {
      originalBudget = parseFloat(originalBudget);
    } else originalBudget = 0;
  } else originalBudget = 0;

  let campaignAdjusted = spent + remainingBudget - originalBudget;
  props.changeModifyCampaignAdjusted({
    modified: modified,
    value: [campaignAdjusted],
  });

  props.changeModifyCampaignDuration({
    modified: modified,
    value: [
      Math.ceil((dates[1].getTime() - dates[0].getTime()) / (1000 * 3600 * 24)),
    ],
  });
}
export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
async function getAssetBase64(url, props, showError = true, isPng = true) {
  let getUrl = assetBase64Url;
  var myHeaders = createHeaders();
  getUrl += '?assetUrl=' + encodeURIComponent(url);
  let status;
  try {
    let result = await fetch(getUrl, {
      method: 'GET',
      headers: myHeaders,
    });
    if (result) {
      status = result.status;
      if (result.status === unauthorizedHttpError) {
        let auth = new Auth();

        auth.sessionExpired();
      } else if (result.status === serviceUnavailableHttpError) {
        return false;
      } else if (result.status === fileNotSupportedHttpCode) {
        return false;
      } else if (status === internalServerErrorHttpError) return false;
      else {
        let resultjson = await result.json();
        if (status === successHttpCode) {
          if (resultjson.payload)
            if (isPng) return resultjson.payload;
            else return resultjson.payload;
          else return false;
        } else return false;
      }
    } else return false;

    /*.then((result) => {

		});*/
  } catch (err) {
    if (!window.isReloading) {
      if (showError) {
        alertPopUp(
          'Hubo un error en la solicitud realizada, revisar conexión a Internet y pruebe nuevamente',
          props,
          true,
        );
        props.changeIsLoadingLayout(false);
      }
      return false;
    }
  }
}

function updateDataset4(result, params, props, updateSelected, status) {
  if (status === successHttpCode) {
    let dataset = [];
    if (result.payload && result.payload.header && result.payload.adSets) {
      let i = 0;

      for (i = 0; i < result.payload.adSets.length; i++) {
        let row = result.payload.adSets[i];
        dataset.push({
          id: i,
          title: row[1],
          imageurl: row[2],
          gender: translateGender(row[3], props.lang),
          leads: row[4],
        });
      }

      props.setDashboard11(dataset);
    }
  }
  runChildFunction(
    props.customPageId,
    productSelectorId,
    props,
    params,
    updateSelected,
  );
}

function updateDataset8(result, params, props, updateSelected, status) {
  if (status === successHttpCode) {
    let dataset = [];

    if (result.payload && result.payload.header && result.payload.gender) {
      let i = 0;

      for (i = 0; i < result.payload.gender.length; i++) {
        let row = result.payload.gender[i];
        dataset.push({
          id: i,
          gender: translateGender(row[0], props.lang),
          leads: row[1],
        });
      }

      props.setDashboard15(dataset);
    }
  }
  runChildFunction(
    props.customPageId,
    productSelectorId,
    props,
    params,
    updateSelected,
  );
}
function updateDataset7(result, params, props, updateSelected, status) {
  if (status === successHttpCode) {
    let dataset = [];

    if (result.payload && result.payload.header && result.payload.ages) {
      let i = 0;

      for (i = 0; i < result.payload.ages.length; i++) {
        let row = result.payload.ages[i];
        dataset.push({
          id: i,
          ages: row[0],
          leads: row[1],
        });
      }

      props.setDashboard14(dataset);
    }
  }
  runChildFunction(
    props.customPageId,
    productSelectorId,
    props,
    params,
    updateSelected,
  );
}
function updateDataset6(result, params, props, updateSelected, status) {
  if (status === successHttpCode) {
    let dataset = [];
    if (result.payload && result.payload.header && result.payload.text) {
      let i = 0;

      for (i = 0; i < result.payload.text.length; i++) {
        let row = result.payload.text[i];
        dataset.push({
          id: i,
          title: row[0],
          body: row[1],
          leads: row[2],
        });
      }
      props.setDashboard13(dataset);
    }
  }
  runChildFunction(
    props.customPageId,
    productSelectorId,
    props,
    params,
    updateSelected,
  );
}
function updateDataset5(result, params, props, updateSelected, status) {
  if (status === successHttpCode) {
    let dataset = [];
    if (result.payload && result.payload.header && result.payload.images) {
      let i = 0;

      for (i = 0; i < result.payload.images.length; i++) {
        let row = result.payload.images[i];
        dataset.push({
          id: i,
          imageurl: row[0],
          leads: row[1],
        });
      }

      props.setDashboard12(dataset);
    }
  }
  runChildFunction(
    props.customPageId,
    productSelectorId,
    props,
    params,
    updateSelected,
  );
}

/**
 * toDateRanQS stands for:To Date Range Query String
 * It takes and array of dates and converts it into a urlstring
 */
function toDateRanQS(
  dates,
  qm = false,
  and = false,
  sn = 'startDate',
  en = 'endDate',
) {
  if (dates && dates.length > 1) {
    let s = `${sn}=${encodeURIComponent(toISODate(dates[0]))}`;
    let e = `${en}=${encodeURIComponent(toISODate(dates[1]))}`;
    let pre = qm ? '?' : '';
    if (and) pre = '&';
    return `${pre}${s}&${e}`;
  }
  return '';
}

/**
 * para dashboard 11
 */
function getDataSet4(CampaignId, props) {
  let url = `${getDataset4}/${CampaignId}`;
  url = `${url}${toDateRanQS(props.selectedDates, true)}`;

  let myHeaders = createHeaders();
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateDataset4,
    [],
    props,
    false,
    true,
  );
}

/**
 * Dashboard 12
 */
function getDataSet5(CampaignId, props) {
  let url = `${getDataset5}/${CampaignId}`;
  url = `${url}${toDateRanQS(props.selectedDates, true)}`;

  let myHeaders = createHeaders();
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateDataset5,
    [],
    props,
    false,
    true,
  );
}

function getDataSet6(CampaignId, props) {
  let url = `${getDataset6}/${CampaignId}`;
  url = `${url}${toDateRanQS(props.selectedDates, true)}`;

  let myHeaders = createHeaders();
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateDataset6,
    [],
    props,
    false,
    true,
  );
}

function getDataSet7(CampaignId, props) {
  let url = `${getDataset7}/${CampaignId}`;
  url = `${url}${toDateRanQS(props.selectedDates, true)}`;

  let myHeaders = createHeaders();
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateDataset7,
    [],
    props,
    false,
    true,
  );
}
function translateGender(gender, lang) {
  if (gender && lang) {
    return trans(gender.toUpperCase(), lang);
  }
}

function getDataSet8(CampaignId, props) {
  let url = `${getDataset8}/${CampaignId}`;
  url = `${url}${toDateRanQS(props.selectedDates, true)}`;

  let myHeaders = createHeaders();

  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateDataset8,
    [],
    props,
    false,
    true,
  );
}

export const getdateRange = callback => {
  let defaultDate = new Date();
  let defaultDate2 = new Date(defaultDate - 1000000000);
  defaultDate.setHours(0, 0, 0, 0);
  defaultDate2.setHours(0, 0, 0, 0);
  return [defaultDate2, defaultDate];
};
export function updateProductAdminApiFiltersCreateAd(
  props,
  selectedCompanies,
  selectedBrands,
  companyChanged,
  downloadstats,
) {
  let Products = props.selectorAdminProduct;

  let Brands = [];
  let Companiesid = [];
  let Brandsid = [];

  let FilteredProducts = [];

  if (companyChanged) {
    props.changeSelectedAdminBrands([]);
    Products.forEach(element => {
      if (
        !Companiesid.includes(element.Company) ||
        !Brandsid.includes(element.Brand)
      ) {
        selectedCompanies.forEach(company => {
          if (company.value === element.Company || company.value === -1) {
            if (!Companiesid.includes(element.Company))
              Companiesid.push(element.Company);
            if (!Brandsid.includes(element.Brand)) {
              Brandsid.push(element.Brand);
            }
          }
        });
      }
    });
    Companiesid.forEach(company => {
      Products.forEach(element => {
        if (company === element.Company) FilteredProducts.push(element);
      });
    });
    Brandsid.forEach(element => {
      Brands.push({ value: element, label: element });
    });
    Brands.push({ value: -1, label: 'Todas' });
    props.changeSelectorAdminBrand(Brands);
  } else {
    Products.forEach(element => {
      if (!Brandsid.includes(element.Brand)) {
        selectedBrands.forEach(brand => {
          if (
            brand.value === element.Brand ||
            (brand.value === -1 &&
              arrayContainsElement({ value: -1 }, selectedCompanies)) ||
            (brand.value === -1 &&
              arrayContainsElement(
                { value: element.Company },
                selectedCompanies,
              ))
          ) {
            Brandsid.push(element.Brand);
            if (!Companiesid.includes(element.Company)) {
              Companiesid.push(element.Company);
            }
          }
        });
      }
    });

    Brandsid.forEach(brand => {
      Products.forEach(element => {
        if (brand === element.Brand) FilteredProducts.push(element);
      });
    });
  }
  if (downloadstats) FilteredProducts.push(todosSelectorValue);
  props.changeSelectorAdminProductFiltered(FilteredProducts);
  props.changeSelectedAdminProducts([]);
}
function updateProductAdminApiWithFiltersBrandandCompany(
  result,
  params,
  props,
  updateSelected,
) {
  if (result.payload && result.payload.length && result.payload.length > 0) {
    let Products = result.payload.map(
      ({ Id, Title, Company, CompanyId, Brand }) => ({
        value: Id,
        label: Title,
        Company: Company ? Company : 'Sin Asignar',
        CompanyId: CompanyId ? CompanyId : 'Sin Asignar',
        Brand: Brand ? Brand : 'Sin Asignar',
      }),
    );
    let Companies = [];
    let Brands = [];
    let Companiesid = [];
    let Brandsid = [];

    props.changeSelectorAdminProduct([]);
    props.changeSelectorAdminProductFiltered([]);
    props.changeSelectorAdminCompany([]);
    props.changeSelectorAdminBrand([]);
    props.changeSelectedAdminProducts(
      params.selectedProducts ? params.selectedProducts : [],
    );
    props.changeSelectedAdminProductsFiltered([]);

    Products.forEach(element => {
      if (!Companiesid.includes(element.Company)) {
        Companiesid.push({ value: element.CompanyId, label: element.Company });
      }
      if (!Brandsid.includes(element.Brand)) {
        Brandsid.push(element.Brand);
      }
    });
    Companiesid.forEach(element => {
      Companies.push({ value: element.value, label: element.label });
    });
    Brandsid.forEach(element => {
      Brands.push({ value: element, label: element });
    });
    Brands.push(todasSelectorValue);
    Companies.push(todasSelectorValue);
    props.changeSelectorAdminProduct(Products);
    props.changeSelectorAdminProductFiltered(Products);
    props.changeSelectorAdminCompany(Companies);
    props.changeSelectorAdminBrand(Brands);
    props.changeSelectedAdminCompanies([todasSelectorValue]);
    props.changeSelectedAdminBrands([todasSelectorValue]);
    if (params.downloadstats) {
      Products.push(todosSelectorValue);
      props.changeSelectorAdminProduct(Products);
      props.changeSelectorAdminProductFiltered(Products);
      props.changeSelectedAdminProducts([todosSelectorValue]);
      let params = {};
      updateSelector(
        true,
        props.changeSelectedAdminProducts,
        [],
        [todosSelectorValue],
        Products,
        'AdminProductSelectorId',
        props,
        params,
        'selectedAdminProducts',
        true,
      );

      getAdAccountsByProductApiAdmin(props, params.selectedAdminProducts, {
        downloadstats: true,
      });
    }
  }

  runChildFunction(
    props.customPageId,
    productSelectorId,
    props,
    params,
    updateSelected,
  );
  //getAdAccouts(params, props, updateSelected);
}

export function getProductAdminApiFilters(
  props,
  selectedAccounts,
  selectedProduct,
) {
  getProductAdminApi(
    props,
    selectedAccounts,
    selectedProduct,
    updateProductAdminApiFilters,
  );
}

/* 
leo: parameter Skip is not used!!
todo: remove Skip paramater!
todo: why having params and props?
*/
function dashboardCampaignChanged(params, props, updateSelected, Skip) {
  props.setDashboard15([]);
  props.setDashboard14([]);
  props.setDashboard13([]);
  props.setDashboard12([]);
  props.setDashboard11([]);
  // debugger;
  let selectedCampaign;
  if (
    params.selectedFacebookCampaigns &&
    params.selectedFacebookCampaigns[0] &&
    params.selectedFacebookCampaigns[0].value
  ) {
    selectedCampaign = params.selectedFacebookCampaigns[0];
  } else if (
    props.selectedFacebookCampaigns &&
    props.selectedFacebookCampaigns[0] &&
    props.selectedFacebookCampaigns[0].value
  ) {
    selectedCampaign = props.selectedFacebookCampaigns[0];
  }

  if (selectedCampaign && props.customPageId === release1AdsPageId) {
    let val = selectedCampaign.value;
    getDataSet4(val, props);
    getDataSet5(val, props);
    getDataSet6(val, props);
    getDataSet7(val, props);
    getDataSet8(val, props);
  }
  runChildFunction(
    props.customPageId,
    'changeCampaign',
    props,
    params,
    updateSelected,
  );
}
export function getProductAdminApiFiltersBrandandCompany(
  props,
  selectedAccounts,
  selectedProduct,
  downloadstats,
  filterByAdAccount,
  selectedAdAccounts,
) {
  getProductAdminApi(
    props,
    selectedAccounts,
    selectedProduct,
    updateProductAdminApiWithFiltersBrandandCompany,
    downloadstats,
    filterByAdAccount,
    selectedAdAccounts,
  );
}

export function updateSelector(
  isMultiple,
  changeFunction,
  currentValue,
  value,
  selectorValue,
  selectorId,
  props,
  params,
  paramsName,
  loadingLayout = false,
) {
  let newValue;
  let all = false;
  if (!isMultiple) {
    newValue = [value];
    let index = newValue.findIndex(function (item, i) {
      return item.value === -1;
    });
    all = index > -1;
  } else {
    if (value) {
      if (value.length === 0) {
        all = true;
        newValue = [todosSelectorValue];
      } else {
        //search if we have "Todos" in current
        let index = currentValue.findIndex(function (item, i) {
          return item.value === -1;
        });
        if (index > -1) {
          //there is more than one option plus "Todos"

          if (value.length > 1) {
            //remove Todos (position 0)
            newValue = [value[1]];
          } else newValue = [todosSelectorValue];
        } else {
          index = value.findIndex(function (item, i) {
            return item.value === -1;
          });
          //Todos was selected
          if (index > -1) {
            all = true;
            newValue = [todosSelectorValue];
          }
          //Todos was not selected
          else {
            newValue = value;
          }
        }
      }
    } else {
      all = true;
      newValue = [todosSelectorValue];
    }
  }
  changeFunction(newValue);

  // since we begin with one selected value and length two means that the posible values are, todos or the value, this will cause a fake loading
  if (
    selectorValue.length === 2 &&
    paramsName !== 'selectedCampaignTypes' &&
    paramsName !== 'selectedAccounts'
  ) {
    if (loadingLayout) fakeLayoutLoading(props.changeIsLoadingLayout);
    else fakeLayoutLoading(props.changeIsLoadingSideBar);
  } else {
    if (equalSelectorArrays(currentValue, newValue)) {
      if (loadingLayout) fakeLayoutLoading(props.changeIsLoadingLayout);
      else fakeLayoutLoading(props.changeIsLoadingSideBar);
    } else {
      if (all) {
        //clone value
        newValue = selectorValue.map(val => val);
        //Removes "Todos"
        newValue.pop();
      }
      if (!equalSelectorArrays(currentValue, newValue)) {
        params.selectedDates = props.selectedDates;
        params[paramsName] = newValue;
        let mapping = getComponentMapping(props.customPageId, selectorId, true);
        mapping.childFunction(params, props, true);
      } else fakeLayoutLoading(props.changeIsLoadingSideBar);
    }
  }
}

export async function getOnBoardingv2(props) {
  var myHeaders = createHeaders();
  let status;
  try {
    let result = await fetch(getOnBoardingUrl, {
      method: 'GET',
      headers: myHeaders,
    });
    if (result) {
      status = result.status;
      if (result.status === unauthorizedHttpError) {
        let auth = new Auth();

        auth.sessionExpired();
      } else if (result.status === serviceUnavailableHttpError) {
        return { continue: false, onBoardingStatus: -1 };
      } else if (result.status === fileNotSupportedHttpCode) {
        return { continue: false, onBoardingStatus: -1 };
      } else {
        let resultjson = await result.json();
        if (status === successHttpCode) {
          if (resultjson.payload) {
            return await updateOnBoardingv2(resultjson.payload, [], props);
          } else return { continue: false, onBoardingStatus: -1 };
        } else return { continue: false, onBoardingStatus: -1 };
      }
    } else return { continue: false, onBoardingStatus: -1 };
  } catch (err) {
    console.log(err);
    if (!window.isReloading) {
      alertPopUp('error when loading onboarding information', props, true);
    }
    return { continue: false, onBoardingStatus: -1 };
  }
}
export function showPageWhileLoading() {
  for (let i = 0; i < showWhileloadingpages.length; i++) {
    if (showWhileloadingpages[i] === window.location.href) return true;
  }
  return false;
}
export async function validateOnBoardingEnd(props, tenantChanged = false) {
  if (
    sessionStorage.getItem('onBoardingStatus') !== onBoardingOK.toString() ||
    tenantChanged
  ) {
    let prevStatus = sessionStorage.getItem('onBoardingStatus');
    let goToHome = await getOnBoardingv2(props);
    if (
      goToHome.onBoardingStatus === onBoardingOK &&
      prevStatus !== goToHome.onBoardingStatus.toString()
    ) {
      alertConfirmPopUp(
        trans('ONBOARDING_MESSAGE1', props.lang),
        props,
        () => {
          props.history.push(homePagePagePath);
        },
        () => {},
        trans('ONBOARDING_MESSAGE2', props.lang),
      );
    }
  }
}

async function runOnBoardingValidationsv2(
  props,
  message,
  newPath,
  onBoardingStatus,
  pageId,
) {
  if (getLocalStorageTenant() || onBoardingStatus === onBoardingNoClient) {
    sessionStorage.setItem('onBoardingMessage', message);
    sessionStorage.setItem('showOnBoardingMessage', true);

    if (props.history) {
      props.history.push(newPath);

      return await validatePermisions(
        props,
        {
          continue: true,
          onBoardingStatus,
          pageId: pageId,
        },
        pageId,
      );
    }
    /*if (
		window.location.href !== url &&
		(window.location.href !== initUrl || alwaysRedirect)
	) {
		window.location.replace(url);
		return { continue: false, onBoardingStatus };
	} else {
		//if (changeprops) props.changeCustomPageValidationEnded(true);
		return { continue: true, onBoardingStatus };
	}*/
  } else {
    sessionStorage.setItem('onBoardingMessage', '');
    sessionStorage.removeItem('showOnBoardingMessage');
    onBoardingStatus = onBoardingOK;
    sessionStorage.setItem('onBoardingStatus', onBoardingStatus);

    let localUser = JSON.parse(localStorage.getItem('userName'));
    let userNameGA = localUser.given_name + ' ' + localUser.family_name;

    sendGAEvent({
      category: GAEventCategoryLinkPayalyPaid,
      action: GAEventActionLinkPayalyPaid,
      label: `${userNameGA} - Payment completed`,
    });

    if (props.history) {
      props.history.push(homePagePagePath);

      return {
        continue: true,
        onBoardingStatus,
        pageId: homePagePageId,
      };
    }
  }
}

async function validatePermisions(props, onBoardingResult, pageId) {
  if (userHasPermision(props, undefined, pageId)) {
    sessionStorage.removeItem('errorPage');
    return onBoardingResult;
  }
  if (!pageId) pageId = props.customPageId;
  //-1 is new page non loaded and -2 is still loading layout
  if (
    pageId &&
    pageId !== -1 &&
    pageId !== -2 &&
    onBoardingResult.continue === true
  ) {
    goToDefaultPage(props);
  }
  sessionStorage.setItem('errorPage', 'true');
  onBoardingResult.continue = false;
  return onBoardingResult;
}
function getPathByPageId(pageId) {
  switch (pageId) {
    case homePagePageId:
      return homePagePagePath;
    case billingPageId:
      return billingPagePath;
  }
}
export function goToDefaultPage(props) {
  let userPages = getLocalUserPages(props);
  let newPath = getPathByPageId(userPages.defaultPage);
  if (props.history) {
    //props.customPageId = userPages.defaultPage;
    props.history.push(newPath);
  }
}
async function updateOnBoardingv2(result, params, props) {
  let onBoardingStatus = result.Status;
  sessionStorage.setItem('onBoardingStatus', onBoardingStatus);
  //if (changeprops) props.changeOnBoardingStatus(onBoardingStatus);
  sessionStorage.removeItem('clientApproval');
  updateNonce(result.Nonce);
  switch (onBoardingStatus) {
    case onBoardingOK:
      sessionStorage.setItem('onBoardingMessage', '');
      sessionStorage.removeItem('showOnBoardingMessage');

      return await validatePermisions(props, {
        continue: true,
        onBoardingStatus,
        pageId: props.customPageId,
      });

    //if account is inactive  it will be sent to billing page, if there is an active subscription in pay aly, it will show that it is inactive
    case onBoardingInactive:
      return runOnBoardingValidationsv2(
        props,
        trans('ONBOARDING_ERR_MESSAGE1', props.lang),
        billingPagePath,
        onBoardingStatus,
        billingPageId,
      );
    case onBoardingBilling:
      return runOnBoardingValidationsv2(
        props,
        trans('ONBOARDING_ERR_MESSAGE2', props.lang),
        billingPagePath,
        onBoardingStatus,
        billingPageId,
      );
    case onBoardingNoClient:
      return runOnBoardingValidationsv2(
        props,
        trans('ONBOARDING_ERR_MESSAGE3', props.lang),
        newAccountPagePath,
        onBoardingStatus,
        newAccountPageId,
      );

    case onBoardingNoAdAccounts:
      return runOnBoardingValidationsv2(
        props,
        trans('ONBOARDING_ERR_MESSAGE4', props.lang),
        adaccountsPagePath,
        onBoardingStatus,
        adaccountsPageId,
      );

    case onBoardingNoBBAdAccounts:
      return runOnBoardingValidationsv2(
        props,
        trans('ONBOARDING_ERR_MESSAGE5', props.lang),
        adaccountsPagePath,
        onBoardingStatus,
        adaccountsPageId,
      );

    case onBoardingNoProducts:
      return runOnBoardingValidationsv2(
        props,
        trans('ONBOARDING_ERR_MESSAGE6', props.lang),
        productPagePath,
        onBoardingStatus,
        productPageId,
      );

    case onBoardingMantainance:
      if (window.location.href !== mantainanceUrl) {
        window.location.replace(mantainanceUrl);
        return { continue: false, onBoardingStatus };
      } else {
        return { continue: true, onBoardingStatus };
      }
    default:
      return { continue: false, onBoardingStatus };
  }
}

export async function customFetchAsync(
  url,
  requestInit,
  funtionForResult,
  paramsFunctionResult,
  props,
  updateSelected,
  layoutLoading,
) {
  if (layoutLoading) {
    if (props.changeIsLoadingSideBar && props.isLoadingSideBar)
      props.changeIsLoadingSideBar(false);
    if (props.changeIsLoadingLayout && !props.isLoadingLayout)
      props.changeIsLoadingLayout(true);
  } else {
    if (props.changeIsLoadingSideBar && !props.isLoadingLayout)
      props.changeIsLoadingSideBar(true);
  }
  let status;
  try {
    let result = await fetch(url, requestInit);

    if (result) {
      status = result.status;
      if (result.status === unauthorizedHttpError) {
        let auth = new Auth();

        auth.sessionExpired();
      } else if (result.status === serviceUnavailableHttpError) {
        return '';
      } else if (result.status === fileNotSupportedHttpCode) {
        return '';
      } else if (result.status === facebookGasHttpErrorCode) {
        alertPopUp(
          'Se ha llegado al limite de las llamadas a Facebook para la Cuenta Publicitaria',
          props,
          true,
        );
        props.changeFacebookNoGas(true);
        return '';
      } else {
        let resultjson = await result.json();

        if (!resultjson.payload) resultjson.payload = [];
        return funtionForResult(
          resultjson.payload,
          paramsFunctionResult,
          props,
          updateSelected,
          status,
        );
      }
    } else return '';

    /*.then((result) => {

		});*/
  } catch (err) {
    if (!window.isReloading)
      alertPopUp(
        'Hubo un error en la solicitud realizada, revisar conexión a Internet y pruebe nuevamente',
        props,
        true,
      );
    if (props.changeIsLoadingLayout) props.changeIsLoadingLayout(false);

    return '';
  }
}

export function convertBigNumbers(
  value,
  decimals = 2,
  thousandDecimals = 2,
  lang,
) {
  const suf = {
    THOUSAND: { fallback: 'Mil' },
    MILLIONS: { fallback: 'Millones' },
    BILLION: { fallback: 'Mil Milones' },
    TRILLION: { fallback: 'Billones' },
    TRILLIONS: { fallback: 'Trillones' },
  };

  let aux = e => {
    if (!e) return '';
    if (e.label) return e.label;
    return e.fallback;
  };

  if (Object.keys && lang) {
    Object.keys(suf).forEach(key => {
      suf[key].label = trans(key, lang);
    });
  }
  if (!value) value = 0;
  if (value < 1000) return value.toFixed(decimals);
  if (value < 1000000)
    return (value / 1000).toFixed(thousandDecimals) + ` ${aux(suf.THOUSAND)}`;
  if (value < 1000000000)
    return (
      (value / 1000000).toFixed(thousandDecimals) + ` ${aux(suf.MILLIONS)}`
    );
  if (value < 1000000000000)
    return (
      (value / 1000000000).toFixed(thousandDecimals) + ` ${aux(suf.BILLION)}`
    );
  if (value < 1000000000000000)
    return (
      (value / 1000000000000).toFixed(thousandDecimals) +
      ` ${aux(suf.TRILLION)}`
    );
  if (value < 1000000000000000000)
    return (
      (value / 1000000000000000).toFixed(thousandDecimals) +
      ` ${aux(suf.TRILLIONS)}`
    );

  return value;
}
function addCircleWithText(
  slide,
  position,
  header,
  innerText,
  fillColor,
  width = 0.9,
  doubleCircle = false,
  innerFontSize = 11,
) {
  let pres = new pptxgen();
  slide.addText(header, {
    x: position.x - 11 + '%',
    y: position.y - 5 + '%',
    w: '30%',
    align: 'center',
    fontFace: pptxStyle.fontFamily,
    fontSize: 12,
    bold: false,
    color: pptxStyle.colors.primary,
  });
  if (doubleCircle)
    slide.addText('', {
      shape: pres.ShapeType.ellipse,
      line: '000000',
      lineSize: 5,
      x: position.x - 1.5 + '%',
      y: position.y - 2.8 + '%',
      w: width + 0.3,
      h: width + 0.3,

      align: 'center',
      fontFace: pptxStyle.fontFamily,
      fontSize: 12,
      bold: true,
      color: 'FFFFFF',
    });
  slide.addText(innerText, {
    shape: pres.ShapeType.ellipse,
    x: position.x + '%',
    y: position.y + '%',
    w: width,
    h: width,
    fill: fillColor,
    align: 'center',
    fontFace: pptxStyle.fontFamily,
    fontSize: innerFontSize,
    bold: true,
    color: 'FFFFFF',
  });
}

function addRectangleWithText(
  slide,
  startPosition,
  percentages,
  label,
  labelWidth = 10,
  width = 50,
  height = 2.5,
  showValue = false,
) {
  width = width / 100;
  let pres = new pptxgen();
  let acumulatedWidth = 0;
  slide.addText(label, {
    x: startPosition.x - labelWidth - 2 + '%',
    y: startPosition.y + '%',
    w: labelWidth + '%',
    h: height + '%',
    margin: 0.5,
    fontFace: pptxStyle.fontFamily,
    fontSize: 11,
    isTextBox: true,
    align: 'right',
    color: pptxStyle.colors.primary,
  });
  for (let i = 0; i < percentages.length; i++) {
    if (percentages[i].percentage > 0) {
      slide.addText(
        showValue
          ? percentages[i].value
          : percentages[i].percentage > 6
          ? percentages[i].percentage.toFixed(2) + '%'
          : percentages[i].percentage.toFixed(1) + '%',
        {
          shape: pres.ShapeType.rect,
          x: startPosition.x + acumulatedWidth + '%',
          y: startPosition.y + '%',
          w: width * percentages[i].percentage + '%',
          h: height + '%',
          fill: percentages[i].fillColor,
          align: 'center',
          fontFace: pptxStyle.fontFamily,
          fontSize:
            percentages[i].percentage >= 75
              ? 14
              : percentages[i].percentage >= 50
              ? 12
              : percentages[i].percentage >= 25
              ? 11
              : percentages[i].percentage >= 10
              ? 10
              : percentages[i].percentage >= 5
              ? 8
              : 6,
          bold: false,
          color: 'FFFFFF',
        },
      );
      acumulatedWidth += width * percentages[i].percentage;
    }
  }
}
function getPositionBasedOnNumber(number, quantity) {
  if (number > quantity) {
    return number - quantity;
  } else return number;
}

function addAudienceTitle(slide, element, title, fullWidth = true) {
  let titleLenght =
    element.title && element.title.length ? element.title.length : 0;
  let fontSize = 24;
  if (fullWidth) {
    fontSize = titleLenght <= 24 ? 24 : titleLenght <= 42 ? 22 : 15;
  } else {
    fontSize = titleLenght <= 16 ? 24 : titleLenght <= 21 ? 20 : 15;
  }
  slide.addText(
    [
      {
        text: title,
        options: {
          color: pptxStyle.colors.primary,
          breakLine: titleLenght > 21,
          bold: true,
        },
      },
      {
        text: element.title,
        options: {
          color: pptxStyle.colors.secondary,
          breakLine: true,
          bold: true,
        },
      },
      {
        text: element['summaryResults']
          ? element['summaryResults'].CampaignType
          : '',
        options: {
          color: '434343',
          breakLine: false,
        },
      },
    ],
    {
      x: '0%',
      y: '10%',
      w: !fullWidth ? '51%' : '100%',

      fontFace: pptxStyle.fontFamily,
      fontSize: fontSize,
      bold: false,
      color: pptxStyle.colors.primary,
    },
  );
}

// find an element in the array by: 1) prop and 2) value(needle)
// returns undefined otherwise
function Find(arr, needle, prop) {
  if (!arr || !needle || !prop) return undefined;
  const idx = arr.findIndex(ele => ele[prop] === needle);
  return idx === -1 ? undefined : arr[idx];
}

// what a stupid function! It doesn't make sense at all!! shame!
export function getValueFromJSONArray(
  array,
  search,
  searchId,
  valueId,
  returnObject = false,
  returnDefault = 0,
) {
  let index = array.findIndex(function (item, i) {
    return item[searchId] === search;
  });
  if (index === -1) return returnDefault;
  else if (returnObject) return array[index];
  else return array[index][valueId];
}
// this is UGLY
// someone did not do the proper work
function getActionsByArray(
  array,
  labelId,
  options,
  separateActionsbyGender = false,
  genderOptions = [],
  lang,
) {
  let chartData = [];
  let chartLabels = [];
  let totals = [];
  let totalActions = 0;
  let circleOptions = [];
  let chartColors = [];
  let separatedActionsbyGender = [];
  if (!separateActionsbyGender)
    array.forEach(element => {
      element.data.forEach(elementData => {
        totalActions += elementData['Actions'];
      });
    });

  for (let i = 0; i < array.length; i++) {
    let currentLabel = array[i][labelId];
    let separatedActions = [];
    let actions = 0;
    array[i].data.forEach(dataElement => {
      if (dataElement['Actions'] !== undefined) {
        if (separateActionsbyGender) {
          let option = Find(genderOptions, dataElement['Gender'], 'arrayLabel');
          if (option) {
            separatedActions.push({
              gender: translateGender(dataElement['Gender'], lang),
              actions: dataElement['Actions'],
              fillColor: option.color,
            });
          }
        }
        actions += dataElement['Actions'];
      }
    });
    let noData = false;
    if (separateActionsbyGender) {
      if (actions === 0) {
        noData = true;
      } else
        separatedActions.forEach(element => {
          element.percentage = (element.actions / actions) * 100;
        });
    }
    let option = Find(options, currentLabel, 'arrayLabel');
    if (option) {
      chartColors.push(option.color);
      circleOptions.push({
        position: option.position,
        label: option.key ? trans(option.key, lang) : option.label,
        color: option.color,
        value: ((actions / totalActions) * 100).toFixed(2) + '%',
      });
      chartData.push(actions);
      chartLabels.push(currentLabel);
      totals.push({ value: actions, label: currentLabel });
      separatedActionsbyGender.push({
        noData: noData,
        label: currentLabel,
        data: separatedActions,
        position: option.position,
      });
    }
  }
  //if there is no data in the array based on options add 0%
  options.forEach(option => {
    let found = false;
    chartLabels.forEach(label => {
      if (option.arrayLabel === label) found = true;
    });
    if (!found) {
      chartColors.push(option.color);
      circleOptions.push({
        position: option.position,
        label: option.label,
        color: option.color,
        value: '0%',
      });
      chartData.push(0);
      chartLabels.push(options.label);
      totals.push({ value: 0, label: options.label });
      separatedActionsbyGender.push({
        label: option.label,
        noData: true,
        position: option.position,
      });
    }
  });
  if (totalActions === 0) {
    chartData.push(100);
    chartLabels.push('nothing');
    chartColors.push('19FCFF');
    return {
      data: {
        values: chartData,
        labels: chartLabels,
        name: 'Actions ' + labelId,
      },
      totalActions: totalActions,
      totals: totals,
      circleOptions: circleOptions,
      chartColors: chartColors,
      separatedActionsbyGender: separatedActionsbyGender,
    };
  }
  return {
    data: {
      values: chartData,
      labels: chartLabels,
      name: 'Actions ' + labelId,
    },
    totals: totals,
    totalActions: totalActions,
    circleOptions: circleOptions,
    chartColors: chartColors,
    separatedActionsbyGender: separatedActionsbyGender,
  };
}
function addCampaignSummary(slide, data, number) {
  let position = getPositionBasedOnNumber(number, 4);
  slide.addText(data, {
    x: position < 3 ? '5%' : '50%',
    y: position === 1 || position === 3 ? '25%' : '55%',
    w: '45%',
    h: '25%',
    isTextBox: true,
    fontSize: 11,
    fontFace: pptxStyle.fontFamily,
    color: pptxStyle.colors.primary,
  });
}
function createAgeGenderBarChart(
  slide,
  array,
  label,
  options,
  genderOptions,
  lang,
) {
  let chartData = getActionsByArray(
    array,
    label,
    options,
    true,
    genderOptions,
    lang,
  );

  chartData.separatedActionsbyGender.forEach(element => {
    if (
      element.noData ||
      (element.data !== undefined && element.data.length === 0)
    ) {
      addRectangleWithText(
        slide,
        element.position,
        [{ percentage: 100, fillColor: 'C4C9B9', value: '' }],
        element.label,
        10,
        50,
        8,
        true,
      );
    } else
      addRectangleWithText(
        slide,
        element.position,
        element.data,
        element.label,
        10,
        50,
        8,
      );
  });
}
function createDoughnutChart(
  slide,
  array,
  label,
  options,
  chartOptions,
  centerTextOptions,
  title,
  lang,
) {
  chartOptions.showLabel = false;
  chartOptions.showValue = false;
  chartOptions.showPercent = false;
  chartOptions.showLegend = false;
  chartOptions.showTitle = false;
  chartOptions.holeSize = 80;
  let pres = new pptxgen();
  let chartData = getActionsByArray(array, label, options, false, [], lang);
  chartOptions.chartColors = chartData.chartColors;

  slide.addChart(pres.charts.DOUGHNUT, [chartData.data], chartOptions);
  slide.addText(
    [
      {
        text: chartData.totalActions,
        options: {
          color: pptxStyle.colors.primary,
          breakLine: false,
          bold: true,
          fontSize: 20,
        },
      },
      {
        text: title,
        options: {
          color: pptxStyle.colors.primary,
          breakLine: false,
          bold: true,
          fontSize: 15,
        },
      },
    ],
    centerTextOptions,
  );
  chartData.circleOptions.forEach(element => {
    addCircleWithText(
      slide,
      element.position,
      element.label,
      element.value,
      element.color,
    );
  });
}

// wtf is arrayLabel?
const genderChartOptions = [
  {
    arrayLabel: 'male',
    position: { x: 25, y: 25 },
    label: 'Masculino',
    key: 'MALE',
    color: pptxStyle.colors.primary,
  },
  {
    arrayLabel: 'female',
    position: { x: 25, y: 45 },
    label: 'Femenino',
    key: 'FEMALE',
    color: pptxStyle.colors.secondary,
  },
  {
    arrayLabel: 'unknown',
    position: { x: 25, y: 65 },
    label: 'Otros',
    key: 'UNKNOWN',
    color: pptxStyle.colors.contrast,
  },
];
function addAgeGenderSeparatedBarChart(slide, array) {
  createAgeGenderBarChart(
    slide,
    array,
    'Age',
    [
      {
        arrayLabel: '18-24',
        position: { x: 45, y: 27 },
        label: '18-24',
        color: pptxStyle.colors.primary,
      },
      {
        arrayLabel: '25-34',
        position: { x: 45, y: 36 },
        label: '25-34',
      },
      {
        arrayLabel: '35-44',
        position: { x: 45, y: 45 },
        label: '35-44',
      },
      {
        arrayLabel: '45-54',
        position: { x: 45, y: 54 },
        label: '45-54',
      },
      {
        arrayLabel: '55-64',
        position: { x: 45, y: 63 },
        label: '55-64',
      },
      {
        arrayLabel: '65+',
        position: { x: 45, y: 72 },
        label: '65+',
      },
    ],
    genderChartOptions,
  );
}
function addAgeDoughnutChart(slide, ageSeparatedResults, title, lang) {
  createDoughnutChart(
    slide,
    ageSeparatedResults,
    'Age',
    [
      {
        arrayLabel: '18-24',
        position: { x: 75, y: 25 },
        label: '18-24',
        color: pptxStyle.colors.primary,
      },
      {
        arrayLabel: '25-34',
        position: { x: 75, y: 45 },
        label: '25-34',
        color: pptxStyle.colors.contrast,
      },
      {
        arrayLabel: '35-44',
        position: { x: 75, y: 65 },
        label: '35-44',
        color: pptxStyle.colors.lightgreen,
      },
      {
        arrayLabel: '45-54',
        position: { x: 85, y: 25 },
        label: '45-54',
        color: pptxStyle.colors.secondary,
      },
      {
        arrayLabel: '55-64',
        position: { x: 85, y: 45 },
        label: '55-64',
        color: pptxStyle.colors.lightblue,
      },
      {
        arrayLabel: '65+',
        position: { x: 85, y: 65 },
        label: '65+',
        color: pptxStyle.colors.darkgreen,
      },
    ],
    {
      x: 4,
      y: 2,
      w: 4,
      h: 2,
    },
    {
      x: '52.6%',
      y: '42.5%',
      w: '15%',
      h: '20%',
      align: 'center',
      fontFace: pptxStyle.fontFamily,

      bold: false,
      color: pptxStyle.colors.primary,
    },
    title,
    lang,
  );
}

function addGenderDoughnutChart(slide, genderSeparatedResults, title, lang) {
  createDoughnutChart(
    slide,
    genderSeparatedResults,
    'Gender',
    genderChartOptions,
    {
      x: -0.8,
      y: 2,
      w: 4,
      h: 2,
    },
    {
      x: '4.6%',
      y: '42.5%',
      w: '15%',
      h: '20%',
      align: 'center',
      fontFace: pptxStyle.fontFamily,
      bold: false,
      color: pptxStyle.colors.primary,
    },
    title,
    lang,
  );
}

async function addImagestoPpt(slide, imageResult, props) {
  let imageCount = imageResult.length;
  for (let i = 0; i < imageCount; i++) {
    let image = imageResult[i];

    let image64 = await getAssetBase64(image['IMG URL'], props);
    if (image64 === false) return false;
    if (image64)
      slide.addImage({
        data: image64,
        x:
          imageCount >= 3
            ? i * 3.3 + 0.35
            : imageCount === 2
            ? i * 4 + 0.8
            : 3.5,
        y: 1.5,
        w: 2.66,
        h: 2.66,
      });

    slide.addText(
      trans('ACTIONS', props.lang) +
        ': ' +
        convertBigNumbers(image['Actions'], 0, undefined, props.lang),
      {
        x: imageCount >= 3 ? i * 3.3 + 0.35 : i * 4 + 0.8,
        y: 4.15,
        w: 8 / imageCount,
        margin: 0.5,
        fontFace: pptxStyle.fontFamily,
        color: pptxStyle.colors.primary,
        fontSize: 16,
        bold: true,
        isTextBox: true,
        align: 'center',
      },
    );
    slide.addText(
      trans('IMPRESSIONS', props.lang) +
        ': ' +
        convertBigNumbers(image['Impressions'], 0, undefined, props.lang),
      {
        x: imageCount >= 3 ? i * 3.3 + 0.35 : i * 4 + 0.8,
        y: 4.35,
        w: 8 / imageCount,
        margin: 0.5,
        fontFace: pptxStyle.fontFamily,
        color: pptxStyle.colors.primary,
        fontSize: 16,
        bold: true,
        isTextBox: true,
        align: 'center',
      },
    );
  }
  return true;
}

async function createPresentationSlides(props) {
  let pres = new pptxgen();
  const assets = {
    cover: { url: `${AppAssetsUrlPre}/report-cover.png` },
    footer: { url: `${AppAssetsUrlPre}/report-footer.png` },
    circles: { url: `${AppAssetsUrlPre}/report-circles.png` },
    cover2: { url: `${AppAssetsUrlPre}/report-cover-2.png` },
  };
  let firstSlied64 = await getAssetBase64(assets.cover.url, props, false);

  if (firstSlied64)
    pres.defineSlideMaster({
      title: 'TITLE_SLIDE',
      bkgd: { data: firstSlied64 },
    });
  else
    pres.defineSlideMaster({
      title: 'TITLE_SLIDE',
    });

  let background = await getAssetBase64(assets.footer.url, props, false);
  if (background)
    pres.defineSlideMaster({
      title: 'GENERAL_SLIDE',
      bkgd: { data: background },
    });
  else
    pres.defineSlideMaster({
      title: 'GENERAL_SLIDE',
    });
  background = await getAssetBase64(assets.circles.url, props, false);
  if (background)
    pres.defineSlideMaster({
      title: 'CIRCLES_SLIDE',
      bkgd: { data: background },
    });
  else
    pres.defineSlideMaster({
      title: 'CIRCLES_SLIDE',
    });
  background = await getAssetBase64(assets.cover2.url, props, false);

  if (background)
    pres.defineSlideMaster({
      title: 'FIRST_SLIDE',
      bkgd: { data: background },
    });
  else
    pres.defineSlideMaster({
      title: 'FIRST_SLIDE',
    });
  return pres;
}

function getTextCombinationTableRows(data, lang) {
  let rows = [];
  rows = getpptxCombinationTableHeader(lang);
  data['bodiesAndTitleResults'].forEach(element => {
    rows.push([
      { text: element.AdTitle },
      { text: element.AdBody },
      { text: convertBigNumbers(element.Actions, 0, 2, lang) },
    ]);
  });
  return rows;
}

function getpptxCombinationTableHeader(lang) {
  return [
    [
      {
        text: trans('PPTX_MESSAGE_6', lang),
        options: {
          fill: pptxStyle.colors.secondary,
          fontSize: 12,
          color: 'FFFFFF',
          align: 'center',
        },
      },
      {
        text: trans('PPTX_MESSAGE_7', lang),
        options: {
          fill: pptxStyle.colors.secondary,
          fontSize: 12,
          color: 'FFFFFF',
          align: 'center',
        },
      },
      {
        text: trans('ACTIONS', lang),
        options: {
          fill: pptxStyle.colors.secondary,
          fontSize: 12,
          color: 'FFFFFF',
          align: 'center',
        },
      },
    ],
  ];
}
function getpptxTableHeader(lang) {
  return [
    [
      {
        text: trans('CAMPAIGN', lang),
        options: {
          fill: pptxStyle.colors.secondary,
          fontSize: 12,
          color: pptxStyle.colors.white,
          align: 'center',
        },
      },
      {
        text: trans('OBJECTIVE', lang),
        options: {
          fill: pptxStyle.colors.secondary,
          fontSize: 12,
          color: pptxStyle.colors.white,
          align: 'center',
        },
      },
      {
        text: trans('PPTX_SUB_5', lang),
        options: {
          fill: pptxStyle.colors.secondary,
          fontSize: 12,
          color: pptxStyle.colors.white,
          align: 'center',
        },
      },
      {
        text: trans('ACTIONS', lang),
        options: {
          fill: pptxStyle.colors.secondary,
          fontSize: 12,
          color: pptxStyle.colors.white,
          align: 'center',
        },
      },
      {
        text: 'Clicks',
        options: {
          fill: pptxStyle.colors.secondary,
          fontSize: 12,
          color: pptxStyle.colors.white,
          align: 'center',
        },
      },
      {
        text: trans('IMPRESSIONS', lang),
        options: {
          fill: pptxStyle.colors.secondary,
          fontSize: 12,
          color: pptxStyle.colors.white,
          align: 'center',
        },
      },
      {
        text: 'CTR',
        options: {
          fill: pptxStyle.colors.secondary,
          fontSize: 12,
          color: pptxStyle.colors.white,
          align: 'center',
        },
      },
      {
        text: 'CVR',
        options: {
          fill: pptxStyle.colors.secondary,
          fontSize: 12,
          color: pptxStyle.colors.white,
          align: 'center',
        },
      },
      {
        text: 'CPA',
        options: {
          fill: pptxStyle.colors.secondary,
          fontSize: 12,
          color: pptxStyle.colors.white,
          align: 'center',
        },
      },
    ],
  ];
}

async function getChartFromDataset(data, props, params) {
  try {
    let AreaChartsList = [
      {
        id: 'Impressions',
        slideTitle: trans('PPTX_SUB_1', props.lang),
        label: trans('IMPRESSIONS', props.lang),
      },

      {
        id: 'Actions',
        slideTitle: trans('PPTX_SUB_2', props.lang),
        label: trans('ACTIONS', props.lang),
      },

      {
        id: 'CPA',
        slideTitle: trans('PPTX_SUB_3', props.lang),
        label: 'CPA',
      },
    ];
    let BarChartsList = [
      {
        id: 'Frequency',
        slideTitle: trans('PPTX_SUB_4', props.lang),
        label: trans('FREQUENCY', props.lang),
      },

      {
        id: 'Actions',
        slideTitle: trans('PPTX_SUB_2', props.lang),
        label: trans('ACTIONS', props.lang),
      },

      {
        id: 'CPA',
        slideTitle: trans('PPTX_SUB_3', props.lang),
        label: 'CPA',
      },
    ];

    if (props.changeIsPrintingPage) props.changeIsPrintingPage(true);
    if (props.changeIsLoadingLayout) props.changeIsLoadingLayout(true);

    let pptNumber = 0;
    for (let productI = 0; productI < data.length; productI++) {
      let productElement = data[productI];
      let productName = productElement.product;

      //	let customBackground = true;
      let pres = await createPresentationSlides(props);
      let slide = pres.addSlide('TITLE_SLIDE');
      slide.addText('www.aly-ai.com', {
        x: '25%',
        y: '90%',
        w: '50%',
        align: 'center',
        fontFace: pptxStyle.fontFamily,
        fontSize: 24,
        bold: false,
        color: pptxStyle.colors.white,
      });
      slide = pres.addSlide('FIRST_SLIDE');
      let i = 5;
      let campaignCount = 0;
      let rows = [];
      let row = {
        page: 1,
        rows: getpptxTableHeader(props.lang),
      };
      productElement.campaigns.forEach(element => {
        if (element['summaryResults']) {
          campaignCount++;
          if (campaignCount > 4) {
            //clone
            let clonedRow = {
              page: row.page,
              rows: row.rows.map(val => val),
            };
            rows.push(clonedRow);
            row.rows = getpptxTableHeader(props.lang);
            row.page++;
          }

          i++;
          if (i > 4) {
            i = 1;
            slide = pres.addSlide('GENERAL_SLIDE');
            slide.addText(
              [
                {
                  text: `${trans('PRODUCT', props.lang)} :`,
                  options: {
                    color: pptxStyle.colors.primary,
                    breakLine: false,
                    bold: true,
                  },
                },
                {
                  text: productName,
                  options: {
                    color: pptxStyle.colors.secondary,
                    breakLine: true,
                    bold: true,
                  },
                },
              ],
              {
                x: '5%',
                y: '10%',
                w: '95%',

                fontFace: pptxStyle.fontFamily,
                fontSize: 24,
                bold: false,
                color: pptxStyle.colors.primary,
              },
            );
            slide.addText(
              trans('PPTX_DATE_RANGE', props.lang) +
                ' :' +
                params.selectedDates[0].toLocaleDateString(props.lang, {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }) +
                ' al ' +
                params.selectedDates[1].toLocaleDateString(props.lang, {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }),
              {
                x: '40%',
                y: '2%',
                w: '60%',
                isTextBox: true,
                fontSize: 8,
                fontFace: pptxStyle.fontFamily,
                color: pptxStyle.colors.primary,
                align: 'right',
              },
            );
          }

          row.rows.push([
            element['summaryResults'].Title,
            element['summaryResults'].CampaignType,
            element['summaryResults'].Currency +
              ' ' +
              convertBigNumbers(
                element['summaryResults'].Cost,
                2,
                2,
                props.lang,
              ),

            convertBigNumbers(
              element['summaryResults'].Actions,
              2,
              2,
              props.lang,
            ),
            convertBigNumbers(
              element['summaryResults'].LinkClicks,
              0,
              2,
              props.lang,
            ),
            convertBigNumbers(
              element['summaryResults'].Impressions,
              0,
              2,
              props.lang,
            ),
            element['summaryResults'].CTR + '%',
            element['summaryResults'].ConvRate + '%',
            element['summaryResults'].Currency +
              ' ' +
              convertBigNumbers(
                element['summaryResults'].CPA,
                2,
                2,
                props.lang,
              ),
          ]);
          addCampaignSummary(
            slide,
            [
              {
                text: element['summaryResults'].Title,
                options: { bullet: true, fontSize: 13 },
              },
              {
                text: '',
                options: { bullet: false, fontSize: 13 },
              },
              {
                text:
                  trans('CAMPAIGN_OBJECTIVE', props.lang) +
                  ': ' +
                  element['summaryResults'].CampaignType,
                options: {
                  bullet: true,
                  indentLevel: 1,
                },
              },
              {
                text: element['summaryResults'].StartDate
                  ? trans('START_DATE', props.lang) +
                    ':' +
                    ToDate(element['summaryResults'].StartDate)
                  : '',
                options: {
                  bullet: true,
                  indentLevel: 1,
                },
              },
              {
                text: element['summaryResults'].EndDate
                  ? trans('END_DATE', props.lang) +
                    ':' +
                    ToDate(element['summaryResults'].EndDate)
                  : '',
                options: {
                  bullet: true,
                  indentLevel: 1,
                },
              },

              {
                text:
                  trans('PPTX_SUB_5', props.lang) +
                  ' :' +
                  element['summaryResults'].Currency +
                  ' ' +
                  convertBigNumbers(
                    element['summaryResults'].Cost,
                    2,
                    2,
                    props.lang,
                  ),
                options: {
                  bullet: true,
                  indentLevel: 1,
                },
              },
              /*{
							text: "Colocación de Medio: Facebook",
							options: {
								bullet: true,
								indentLevel: 1,
							},
						},*/
            ],
            campaignCount,
          );
        }
      });
      let clonedRow = { page: row.page, rows: row.rows.map(val => val) };
      rows.push(clonedRow);

      if (rows)
        rows.forEach(row => {
          let tabOpts = {
            x: 0.1,
            y: '30%',
            w: 9.8,
            fill: 'F7F7F7',
            fontSize: 10,
            color: '363636',

            colW: [2.8, 1.2, 1, 1, 0.6, 1, 0.7, 0.5, 1],
          };
          slide = pres.addSlide('GENERAL_SLIDE');
          slide.addTable(row.rows, tabOpts);
          if (row.page && row.page > 1) {
            slide.addText('Página: ' + row.page, {
              x: '90%',
              y: '90%',
              w: '30%',
              isTextBox: true,
              fontSize: 8,
              fontFace: pptxStyle.fontFamily,
              color: pptxStyle.colors.primary,
            });
          }
          slide.addText(
            [
              {
                text: trans('PPTX_SUB_6', props.lang),
                options: {
                  color: pptxStyle.colors.primary,
                  breakLine: true,
                  bold: true,
                },
              },
              {
                text: productName,
                options: {
                  color: pptxStyle.colors.secondary,
                  breakLine: true,
                  bold: true,
                },
              },
            ],
            {
              x: '5%',
              y: '10%',
              w: '95%',

              fontFace: pptxStyle.fontFamily,
              fontSize: 24,
              bold: false,
              color: pptxStyle.colors.primary,
            },
          );
        });
      for (let i = 0; i < productElement.campaigns.length; i++) {
        let element = productElement.campaigns[i];
        if (props.changeIsPrintingPage) props.changeIsPrintingPage(true);
        if (props.changeIsLoadingLayout) props.changeIsLoadingLayout(true);

        let genderSeparatedResults = [];
        let ageSeparatedResults = [];
        element['ageAndGenderResults'].forEach(datasetElement => {
          let foundGender = false;
          let foundAge = false;
          genderSeparatedResults.forEach(genderAgeElement => {
            if (genderAgeElement.Gender === datasetElement['Gender']) {
              genderAgeElement.data.push(datasetElement);
              foundGender = true;
            }
          });
          ageSeparatedResults.forEach(ageGenderElement => {
            if (ageGenderElement.Age === datasetElement['Age']) {
              ageGenderElement.data.push(datasetElement);
              foundAge = true;
            }
          });
          if (!foundGender) {
            if (datasetElement['Gender']) {
              let genderAgeElement = [];
              genderAgeElement.Gender = datasetElement['Gender'];
              genderAgeElement.data = [];
              genderAgeElement.data.push(datasetElement);
              genderSeparatedResults.push(genderAgeElement);
            }
          }
          if (!foundAge) {
            if (datasetElement['Age']) {
              let ageElement = [];
              ageElement.Age = datasetElement['Age'];
              ageElement.data = [];
              ageElement.data.push(datasetElement);
              ageSeparatedResults.push(ageElement);
            }
          }
        });

        //convert dates to current dates and to a numeric value
        let startDate = '';
        let endDate = '';
        element['totalResults'].forEach(resultElement => {
          resultElement['RunDate'] = toCurrentDate(
            new Date(resultElement['RunDate']),
          );
          if (startDate === '') {
            startDate = resultElement['RunDate'];
            endDate = resultElement['RunDate'];
          } else if (startDate > resultElement['RunDate'])
            startDate = resultElement['RunDate'];
          else if (endDate < resultElement['RunDate'])
            endDate = resultElement['RunDate'];
        });

        slide = pres.addSlide('GENERAL_SLIDE');

        addRectangleWithText(
          slide,
          { x: 15, y: 35 },
          [
            {
              percentage: 100,
              fillColor: '9900ff',
              value: convertBigNumbers(
                element['summaryResults'].Impressions,
                0,
                2,
                props.lang,
              ),
            },
          ],
          trans('IMPRESSIONS', props.lang),
          10,
          30,
          10,
          true,
        );
        addRectangleWithText(
          slide,
          { x: 15, y: 50 },
          [
            {
              percentage: 100,
              fillColor: '9900ff',
              value: convertBigNumbers(
                element['summaryResults'].LinkClicks,
                0,
                2,
                props.lang,
              ),
            },
          ],
          'Clicks',
          10,
          25,
          10,
          true,
        );
        if (
          element['summaryResults'].CampaignType !== 'LinkClicks' &&
          element['summaryResults'].CampaignType !== 'LINK_CLICKS'
        )
          addRectangleWithText(
            slide,
            { x: 15, y: 65 },
            [
              {
                percentage: 100,
                fillColor: '9900ff',
                value: convertBigNumbers(
                  element['summaryResults'].Actions,
                  2,
                  2,
                  props.lang,
                ),
              },
            ],
            trans('ACTIONS', props.lang),
            10,
            15,
            10,
            true,
          );
        slide.addText(trans('PPTX_MESSAGE_1', props.lang), {
          x: '0%',
          y: '80%',
          w: '50%',
          margin: 0.5,
          fontFace: pptxStyle.fontFamily,
          fontSize: 10,
          bold: false,
          italic: true,
          isTextBox: true,
          align: 'center',
          color: pptxStyle.colors.primary,
        });

        addAudienceTitle(slide, element, trans('AUDIENCE', props.lang));
        let text = `${element['summaryResults'].CTR}%\r\nCTR`;
        addCircleWithText(
          slide,
          { x: 78, y: 10 },
          '',
          text,
          '013f8a',
          1.5,
          true,
          13,
        );
        addCircleWithText(
          slide,
          { x: 60, y: 23 },
          '',
          element['summaryResults'].Currency +
            '\r\n' +
            convertBigNumbers(
              element['summaryResults'].Cost,
              2,
              2,
              props.lang,
            ) +
            `\r\n${trans('PPTX_SUB_5', props.lang)}\r\n(${trans(
              'COST',
              props.lang,
            )})`,
          '013f8a',
          1.5,
          true,
          13,
        );

        addCircleWithText(
          slide,
          { x: 78, y: 45 },
          '',
          element['summaryResults'].Currency +
            ' ' +
            convertBigNumbers(element['summaryResults'].CPA, 2, 2, props.lang) +
            '\r\nCPA',
          '013f8a',
          1.5,
          true,
          13,
        );
        if (element['summaryResults'] && element['summaryResults'].ConvRate) {
          text = `${element['summaryResults'].ConvRate}%\r\nCVR`;
        }
        addCircleWithText(
          slide,
          { x: 60, y: 58 },
          '',
          element['summaryResults'].CampaignType === 'LinkClicks' ||
            element['summaryResults'].CampaignType === 'LINK_CLICKS'
            ? convertBigNumbers(
                element['summaryResults'].LinkClicks,
                0,
                undefined,
                props.lang,
              ) + '\r\nClicks'
            : text,
          '013f8a',
          1.5,
          true,
          13,
        );

        slide = pres.addSlide('GENERAL_SLIDE');
        addAudienceTitle(slide, element, trans('AUDIENCE', props.lang));
        addGenderDoughnutChart(
          slide,
          genderSeparatedResults,
          trans('ACTIONS', props.lang),
          props.lang,
        );
        addAgeDoughnutChart(
          slide,
          ageSeparatedResults,
          trans('ACTIONS', props.lang),
          props.lang,
        );

        slide = pres.addSlide('GENERAL_SLIDE');
        addGenderDoughnutChart(
          slide,
          genderSeparatedResults,
          trans('ACTIONS', props.lang),
          props.lang,
        );

        addAudienceTitle(slide, element, trans('AUDIENCE', props.lang));
        for (let idx = 0; idx < genderChartOptions.length; idx++) {
          const ele = genderChartOptions[idx];
          if (ele.key && ele.label) {
            ele.label = trans(ele.key, props.lang);
          }
        }
        addAgeGenderSeparatedBarChart(slide, ageSeparatedResults);
        let imageCount = 0;
        if (element['imageResults'] && element['imageResults'].length)
          imageCount = element['imageResults'].length;
        if (imageCount > 0) {
          slide = pres.addSlide('GENERAL_SLIDE');
          slide.addText(
            imageCount > 1
              ? trans('IMAGES', props.lang)
              : trans('IMAGE', props.lang),
            {
              x: 0,
              y: '15%',
              w: '100%',
              margin: 0.5,
              fontFace: pptxStyle.fontFamily,
              color: pptxStyle.colors.primary,
              fontSize: 25,
              bold: true,
              isTextBox: true,
              align: 'center',
            },
          );
          let finish = await addImagestoPpt(
            slide,
            element['imageResults'],
            props,
          );
          if (finish === false) return;
        }
        let bodiesCount = 0;

        if (
          element['bodiesAndTitleResults'] &&
          element['bodiesAndTitleResults'].length
        )
          bodiesCount = element['bodiesAndTitleResults'].length;
        if (bodiesCount > 0) {
          slide = pres.addSlide('GENERAL_SLIDE');

          slide.addText(trans('PPTX_SUB_7', props.lang), {
            x: 0,
            y: '15%',
            w: '100%',
            margin: 0.5,
            fontFace: pptxStyle.fontFamily,
            color: pptxStyle.colors.primary,
            fontSize: 25,
            bold: true,
            isTextBox: true,
            align: 'center',
          });

          var tabOpts = {
            x: 0.1,
            y: '30%',
            w: 9.8,
            fill: 'F7F7F7',
            fontSize: 10,
            color: '363636',
            colW: [2, 6.8, 1],
          };
          slide.addTable(
            getTextCombinationTableRows(element, props.lang),
            tabOpts,
          );
        }
        let interval = Math.floor(element['totalResults'].length / 5);
        AreaChartsList.forEach(chartElement => {
          slide = pres.addSlide('GENERAL_SLIDE');
          slide.addText(chartElement.slideTitle, {
            x: '0%',
            y: '5%',
            w: '100%',
            margin: 0.5,
            fontFace: pptxStyle.fontFamily,
            color: pptxStyle.colors.primary,
            fontSize: 16,
            bold: true,
            isTextBox: true,
            align: 'center',
          });

          slide.addChart(
            getChartsforPowerpoint(
              element['totalResults'],
              pres,
              chartElement,
              'RunDate',
              true,
              false,
              props.lang,
            ),
            {
              x: 0,
              y: 0.8,
              w: 8.5,
              h: 4.4,
              showLegend: true,
              catAxisLabelFrequency: interval,
            },
          );
        });

        interval = 1;
        BarChartsList.forEach(chartElement => {
          slide = pres.addSlide('GENERAL_SLIDE');

          slide.addText(chartElement.slideTitle, {
            x: '0%',
            y: '5%',
            w: '100%',
            margin: 0.5,
            fontFace: pptxStyle.fontFamily,
            color: pptxStyle.colors.primary,
            fontSize: 16,
            bold: true,
            isTextBox: true,
            align: 'center',
          });
          for (let i = 0; i < genderSeparatedResults.length; i++) {
            let chart = getChartsforPowerpoint(
              genderSeparatedResults[i].data,
              pres,
              chartElement,
              'Age',
              false,
              true,
              props.lang,
            );
            if (chart.length > 0) {
              let gender = genderSeparatedResults[i].Gender.toLowerCase();
              slide.addChart(chart, {
                x:
                  genderSeparatedResults.length >= 3
                    ? i * 3.3 + 0.35
                    : i * 4 + 0.8,
                y: 1.5,
                w: 8 / genderSeparatedResults.length,
                h: 3,
                showLegend: false,
                catAxisLabelFrequency: interval,
                showTitle: true,
                title:
                  gender === 'male'
                    ? trans('MALE', props.lang)
                    : gender === 'female'
                    ? trans('FEMALE', props.lang)
                    : gender === 'unknown'
                    ? trans('OTHERS', props.lang)
                    : '',
              });
            }
          }
        });
      }
      glosarioPowerPoint.forEach(page => {
        slide = pres.addSlide('GENERAL_SLIDE');
        slide.addText(trans('GLOSSARY', props.lang), {
          x: '5%',
          y: '5%',
          w: '100%',
          isTextBox: true,
          fontSize: 24,
          fontFace: pptxStyle.fontFamily,
          color: pptxStyle.colors.primary,
        });
        let texts = [];
        let first = true;
        page.forEach(element => {
          let text = element.def_key
            ? trans(element.def_key, props.lang)
            : element.definition;
          let title = element.title_key
            ? trans(element.title_key, props.lang)
            : element.title;
          if (element.bullet) {
            texts.push({
              text: text,
              options: {
                bold: false,
                bullet: true,
                indentLevel: element.indentLevel,
              },
            });
          } else {
            if (!first)
              texts.push({
                text: '',
                options: { breakLine: true, bold: false },
              });
            texts.push({
              text: title,
              options: { breakLine: false, bold: true },
            });
            texts.push({
              text: ': ' + text,
              options: { breakLine: true, bold: false },
            });
          }
          first = false;
        });
        slide.addText(texts, {
          x: '5%',
          y: '15%',
          w: '90%',
          h: '75%',
          valign: 'top',
          isTextBox: true,
          fontSize: 11,
          fontFace: 'Arial',
        });
      });
      try {
        pres.writeFile(productName + '.pptx').then(filename => {
          pptNumber++;
          let reporte = pptNumber + 1;
          props.changeLoadingMessage(
            trans('PPTX_MESSAGE_3', props.lang)
              .replace('$REPORT', reporte)
              .replace('$LEN', data.length),
          );
          if (data.length === pptNumber) {
            alertPopUp(trans('PPTX_MESSAGE_2', props.lang), props);
            props.changeLoadingMessage('');
            if (props.changeIsLoadingLayout) props.changeIsLoadingLayout(false);
            if (props.changeIsPrintingPage) props.changeIsPrintingPage(false);
          }
        });
      } catch (error) {
        console.log(error);
        if (!window.isReloading) {
          alertPopUp(
            'Hubo un error en la solicitud realizada, revisar conexión a Internet y pruebe nuevamente',
            props,
            true,
          );

          props.changeLoadingMessage('');
          if (props.changeIsLoadingLayout) props.changeIsLoadingLayout(false);
          if (props.changeIsPrintingPage) props.changeIsPrintingPage(false);
        }
      }
    }
  } catch (error) {
    console.log(error);
    if (!window.isReloading) {
      alertPopUp(
        'Hubo un error en la solicitud realizada, revisar conexión a Internet y pruebe nuevamente',
        props,
        true,
      );
      props.changeLoadingMessage('');
      if (props.changeIsLoadingLayout) props.changeIsLoadingLayout(false);
      if (props.changeIsPrintingPage) props.changeIsPrintingPage(false);
    }
  }
}

export function generateppt(
  params,
  props,
  updateSelected,
  skipAutoLoadingValidation = false,
) {
  if (props.autoLoad || skipAutoLoadingValidation) {
    let component = getComponentMapping(
      props.customPageId,
      'NeededParams',
      false,
    );

    let skip = getSkipFetch(params, component.componentNeededParams, props);

    if (!skip) {
      let url = getpresentationUrl;
      let urlParameters = getUrlParametersFromComponentNeededParams(
        params,
        props,
        component.componentNeededParams,
      );
      url += urlParameters;
      url += '&productName=' + props.selectedProducts[0].label;

      params.urlParameters = [];
      var myHeaders = createHeaders();

      window.scrollTo(0, 0); // < lunatics
      props.changeLoadingMessage(trans('PPTX_MESSAGE_5', props.lang));
      customFetch(
        url,
        {
          method: 'GET',
          headers: myHeaders,
        },
        updateppt,
        params,
        props,
        updateSelected,
        true,
      );
    } else {
      runChildFunction(
        props.customPageId,
        'reportsPptxId',
        props,
        params,
        updateSelected,
      );
    }
  } else {
    runChildFunction(
      props.customPageId,
      'reportsPptxId',
      props,
      params,
      updateSelected,
    );
  }
}
function getChartsforPowerpoint(
  data,
  pres,
  chartElement,
  labelId,
  trend = true,
  isgender = false,
  lang,
) {
  let dataWithTrend = data;

  if (trend)
    dataWithTrend = calculateTrend(
      data,
      labelId,
      chartElement.id,
      undefined,
      true,
      0,
    );

  if (
    dataWithTrend.length &&
    dataWithTrend.length > 0 &&
    dataWithTrend[0][chartElement.id] !== undefined &&
    dataWithTrend[0][labelId] !== undefined
  ) {
    let chartData = [];
    let chartLabels = [];
    let chartTrend = [];
    dataWithTrend.forEach(withTendecyElement => {
      chartData.push(withTendecyElement[chartElement.id]);
      if (labelId === 'RunDate')
        chartLabels.push(
          new Date(withTendecyElement['RunDate']).toLocaleDateString('es', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
        );
      else chartLabels.push(withTendecyElement[labelId]);

      chartTrend.push(withTendecyElement['trend']);
    });

    let chart = [
      {
        type: isgender ? pres.ChartType.bar : pres.ChartType.area,
        data: [
          {
            name: chartElement.label,
            labels: chartLabels,
            values: chartData,
          },
        ],
        options: {
          lineDataSymbol: 'none',
          serGridLine: 'solid',
          chartColors: [pptxStyle.colors.secondary],
          opacity: 0.4,
        },
      },
    ];
    if (trend) {
      chart.push({
        type: pres.ChartType.line,
        data: [
          {
            name: trans('TREND', lang),
            labels: chartLabels,
            values: chartTrend,
          },
        ],
        options: {
          lineSize: 2,
          lineDataSymbol: 'none',
          chartColors: ['000000', '000000'],
        },
      });
    }
    return chart;
  }
  return [];
}
function updateppt(result, params, props, updateSelected, status) {
  if (status === badParametersHttpError)
    alertPopUp(trans('ERROR_MESSAGE_4', props.lang), props, true);
  else if (status === internalServerErrorHttpError)
    alertPopUp(trans('ERROR_MESSAGE_3', props.lang), props, true);
  else if (status === badParametersHttpError) {
    alertPopUp(trans('ERROR_MESSAGE_5', props.lang), props, true);
  }

  if (status === successHttpCode) {
    if (result.success) {
      if (result.payload) {
        if (result.payload) {
          props.changeLoadingMessage(trans('PPTX_MESSAGE_4', props.lang));
          getChartFromDataset([result.payload], props, params);
        } else {
          alertPopUp(trans('ERROR_MESSAGE_5', props.lang), props, true);
          props.changeLoadingMessage('');
          runChildFunction(props.customPageId, undefined, props);
        }
      } else {
        alertPopUp(trans('ERROR_MESSAGE_5', props.lang), props, true);

        props.changeLoadingMessage('');
        runChildFunction(props.customPageId, undefined, props);
      }
    }
  } else runChildFunction(props.customPageId, undefined, props);
}
/* gets ad accounts from fb through user api /adAccounts
 *  payload.data [id:"act_xx", name:"name", status:"20"]
 *
 */
export async function getFbAdAccounts(props, callback) {
  var myHeaders = createHeaders();
  customFetch(
    getAdAccountsUrl,
    {
      method: 'GET',
      headers: myHeaders,
    },
    async () => {
      await getOnBoardingv2(props);
      callback();
    },
    [],
    props,
    false,
    true,
  );
}
export function loginWithFacebookValidation(props, UserInfo) {
  if (Environment === 'DEV') {
    props.loginUser(
      getLocalStorageNonce(),
      UserInfo.name || UserInfo.given_name || UserInfo.family_name,
    );
    return;
  }
  var url = `${getAdAccountsUrl}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('access_token')}`,
  );
  myHeaders.append('nonce', getLocalStorageNonce());
  fetch(url, { method: 'GET', headers: myHeaders })
    .then(response => {
      if (response.status === successHttpCode) {
        response.json().then(resultjson => {
          if (resultjson)
            props.loginUser(
              getLocalStorageNonce(),
              UserInfo.name || UserInfo.given_name || UserInfo.family_name,
            );
          else {
            let auth = new Auth();

            auth.sessionExpired();
          }
        });
      } else {
        let auth = new Auth();

        auth.sessionExpired();
      }
    })
    .catch(err => {
      let auth = new Auth();

      auth.sessionExpired();
    });
}
function cleanDasboards(props) {
  props.changeDashboard31Data([]);
  props.changeDashboard32Data([]);
  props.changeDashboard33Data([]);
  props.changeDashboard34Data([]);
  props.changeDashboard35Data([]);
  props.changeDashboard36Data([]);
}

function getAverage(points, xName, yName) {
  let x = 0;
  let y = 0;
  for (let i = 0; i < points.length; i++) {
    x += points[i][xName];
    y += points[i][yName];
  }
  let average = {};
  average[xName] = x / points.length;
  average[yName] = y / points.length;
  return average;
}
function getTrendSlope(points, xName, yName, average) {
  let top = 0;
  let bottom = 0;
  for (let i = 0; i < points.length; i++) {
    top +=
      (points[i][xName] - average[xName]) * (points[i][yName] - average[yName]);

    bottom +=
      (points[i][xName] - average[xName]) * (points[i][xName] - average[xName]);
  }
  return top / bottom;
}

//y = interception + slope*x
function calculateTrend(
  points,
  xName,
  yName,
  zName,
  removeNegatives = true,
  extraTrendPoints = 1,
) {
  if (points) {
    if (points.length > 1) {
      let average = getAverage(points, xName, yName);
      //a=AVERAGE(y)-slope*AVERAGE(x)
      let slope = getTrendSlope(points, xName, yName, average);
      let interception = average[yName] - slope * average[xName];

      let newList = [];

      for (let i = 0; i < points.length; i++) {
        let newValue = {};
        newValue[xName] = points[i][xName];
        newValue[yName] = points[i][yName];
        if (zName) newValue[zName] = points[i][zName];
        newValue.trend = Number(
          (interception + slope * points[i][xName]).toFixed(4),
        );
        if (newValue.trend < 0) if (removeNegatives) newValue.trend = 0;
        newList.push(newValue);
      }

      for (let i = 0; i < extraTrendPoints; i++) {
        let newValue = {};
        if (newList.length > 1) {
          newValue[xName] =
            newList[newList.length - 1][xName] +
            (newList[newList.length - 1][xName] -
              newList[newList.length - 2][xName]);
          newValue[yName] = undefined;
          newValue.trend = Number(
            (interception + slope * newValue[xName]).toFixed(4),
          );
          if (newValue.trend < 0) if (removeNegatives) newValue.trend = 0;
          newList.push(newValue);
        }
      }

      return newList;
    }
  }

  return points;
}

function getSkipFetch(currentParams, neededParams, props) {
  if (neededParams) {
    for (let i = 0; i < neededParams.length; i++) {
      if (currentParams[neededParams[i]]) {
        if (currentParams[neededParams[i]].length === 0) return true;
      } else {
        if (
          props &&
          props[neededParams[i]] &&
          props[neededParams[i]].length &&
          props[neededParams[i]].length > 0
        ) {
          currentParams[neededParams[i]] = props[neededParams[i]];
        } else return true;
      }
    }
  }
  return false;
}

export function getDisabledLoadButton(props) {
  let neededParams = getComponentMapping(
    props.customPageId,
    'NeededParams',
    false,
  ).componentNeededParams;

  if (neededParams) {
    for (let i = 0; i < neededParams.length; i++) {
      if (props[neededParams[i]]) {
        if (props[neededParams[i]].length === 0) return true;
      } else return true;
    }
  }
  return false;
}
function runChildFunction(
  customPageId,
  componentId,
  props,
  params,
  updateSelected,
) {
  let child = getComponentMapping(customPageId, componentId, true);
  // console.log('ran final funcion?', child.childFunction.name)
  // debugger;
  child.childFunction(
    params,
    props,
    updateSelected,
    getSkipFetch(params, child.childNeededParams, props),
  );
}

export function getComponentMapping(customPageId, componentId, validateChild) {
  let pageMapping = getCustomPageInfo(customPageId).mapping;
  let componentIndex = pageMapping.findIndex(function (item, i) {
    return item.componentId === componentId;
  });

  if (componentIndex > -1) {
    if (validateChild) {
      if (pageMapping[componentIndex].childId) {
        return pageMapping[componentIndex];
      } else {
        return {
          componentId: componentId,
          childId: undefined,
          childFunction: (params, props) => {
            if (props.changeIsLoadingLayout) {
              props.changeIsLoadingLayout(false);
            }
            if (props.changeIsLoadingSideBar) {
              props.changeIsLoadingSideBar(false);
            }
            if (props.changeIsFirstLoad) {
              props.changeIsFirstLoad(false);
            }
          },
        };
      }
    } else {
      if (pageMapping[componentIndex].mapping === undefined) {
        pageMapping[componentIndex].mapping = [];
      }
      return pageMapping[componentIndex];
    }
  }

  return {
    componentId: componentId,
    childId: undefined,
    childFunction: (params, props) => {
      if (props.changeIsLoadingLayout) props.changeIsLoadingLayout(false);
      if (props.changeIsLoadingSideBar) props.changeIsLoadingSideBar(false);
      if (props.changeIsFirstLoad) props.changeIsFirstLoad(false);
    },
  };
}
export function clearAdPage(props, objective, provider, resetAll) {
  if (props.changeRecalculateMessages)
    props.changeRecalculateMessages(!props.recalculateMessages);
  if (resetAll) {
    fullAdComponentsList.forEach(element => {
      element.cleanFunction(props);
    });
  } else {
    fullAdComponentsList.forEach(element => {
      let objectiveComponents = getObjectiveComponentsInfoFromObjectiveProvider(
        objective,
        provider,
      ).mapping;
      let found = false;
      objectiveComponents.forEach(element2 => {
        if (element2.componentId === element.componentId) found = true;
      });
      if (!found) {
        if (element.cleanFunction) element.cleanFunction(props);
      }
    });
  }
}

export function getPageHasError(props) {
  let hasError = false;
  let objectiveComponents = getObjectiveComponentsInfo(props).mapping;

  objectiveComponents.forEach(element => {
    if (element.getError) if (element.getError(props)) hasError = true;
  });
  return hasError;
}
function getObjectiveComponentsInfoFromObjectiveProvider(objective, providers) {
  let componentInfo = { mapping: [] };
  createAddMapping.forEach(element => {
    if (
      element.objective === objective &&
      providers.includes(element.provider)
    ) {
      componentInfo = element;
      return componentInfo;
    }
  });
  return componentInfo;
}
function getModifyComponentInfo() {
  let componentInfo = { mapping: [] };
  createAddMapping.forEach(element => {
    if (element.isModify) {
      componentInfo = element;
      return componentInfo;
    }
  });
  return componentInfo;
}
export function getObjectiveComponentsInfo(props) {
  if (props.isModify) {
    return getModifyComponentInfo();
  } else if (
    props.selectedAdminCampaignTypes.length > 0 &&
    (props.selectedFacebookPositions.length > 0 ||
      props.selectedInstagramPositions.length > 0)
  ) {
    let providers = [];
    if (
      props.selectedFacebookPositions.length > 0 &&
      props.selectedInstagramPositions.length > 0
    )
      providers = [3];
    else if (props.selectedFacebookPositions.length > 0) providers = [1];
    else providers = [2];
    return getObjectiveComponentsInfoFromObjectiveProvider(
      props.selectedAdminCampaignTypes[0].value,
      providers,
    );
  } else return { mapping: [] };
}
export function createAdComponentBelongsInPage(
  componentId,
  props,
  pageMapping,
) {
  if (!pageMapping) pageMapping = getObjectiveComponentsInfo(props).mapping;

  if (pageMapping) {
    let index = pageMapping.findIndex(function (item, i) {
      if (item) return item.componentId === componentId;
      else {
        return false;
      }
    });
    if (index > -1) {
      pageMapping[index].initFunction();
      return true;
    }
  }
  return false;
}
export function createAdGetComponentInfo(componentId, props, pageMapping) {
  if (!pageMapping) {
    pageMapping = getObjectiveComponentsInfo(props).mapping;
  }
  if (pageMapping) {
    let index = pageMapping.findIndex(function (item, i) {
      return item.componentId === componentId;
    });
    if (index > -1) {
      if (pageMapping[index] && pageMapping[index].errorMessageKey) {
        pageMapping[index].errorMessage = trans(
          pageMapping[index].errorMessageKey,
          props.lang,
        );
      }
      return pageMapping[index];
    }
  }
  return false;
}

export function componentBelongsInPage(
  componentId,
  customPageId,
  pageMapping,
  childMapping,
) {
  if (!pageMapping) pageMapping = getCustomPageInfo(customPageId).mapping;

  let index = pageMapping.findIndex(function (item, i) {
    return item.componentId === componentId;
  });
  if (index > -1) childMapping = pageMapping[index];
  return childMapping;
}

export function getCustomPageInfo(customPageId) {
  let index = customPagesMapping.findIndex(function (item, i) {
    return item.customPageId === customPageId;
  });

  if (index > -1) {
    return customPagesMapping[index];
  }

  return { initFunction: () => {}, mapping: [] };
}

export async function fakeLayoutLoading(changeIsLoadingFunction, time = 500) {
  if (changeIsLoadingFunction) {
    changeIsLoadingFunction(true);
    await new Promise(function (resolve) {
      setTimeout(() => {
        changeIsLoadingFunction(false, resolve);
      }, time);
    });
  }
}
export async function refreshCustomPage(
  changeRefresh,
  time = 100,
  wait = 500,
  scrollX = -1,
  scrollY = -1,
) {
  if (changeRefresh) {
    await new Promise(function (resolve) {
      setTimeout(() => {
        changeRefresh(true);
        new Promise(function (resolve2) {
          setTimeout(() => {
            changeRefresh(false, resolve2);
            if (scrollX > 0 || scrollY > 0) {
              window.scrollTo(scrollX, scrollY);
            }
          }, time);
        });
      }, wait);
    });
  }
}
function parametersToComaSeparated(parameterList, valueName = 'value') {
  let separated = '';
  if (parameterList && parameterList.length > 0) {
    separated += parameterList[0][valueName];
    if (parameterList.length > 1) {
      for (let i = 1; i < parameterList.length; i++)
        separated += ',' + parameterList[i][valueName] + '';
    }
  }
  return separated;
}
function parameterListToNonQueryUrl(parameterList, valueName = 'value') {
  let url = '';
  if (parameterList.length > 0) {
    url += '/';
    url += parametersToComaSeparated(parameterList, valueName);
  }
  return url;
}
// this ugly function: converts from
function parameterListToUrl(
  parameterList,
  parameterName,
  firstparameter,
  valueName = 'value',
) {
  let url = '';

  if (parameterList.length > 0) {
    if (firstparameter) url += '?';
    else url += '&';
    url +=
      parameterName + '=' + encodeURIComponent(parameterList[0][valueName]);
    if (parameterList.length > 1) {
      for (let i = 1; i < parameterList.length; i++)
        url += ',' + encodeURIComponent(parameterList[i][valueName]);
    }
  }
  return url;
}
export const getSelectedValues = (currentValue, selectorValue) => {
  //search if we have "Todos" in current
  let index = currentValue.findIndex(function (item, i) {
    return item.value === -1;
  });
  //Todos is selected, return all
  if (index > -1) {
    //clone value
    let newValue = selectorValue.map(val => val);
    //Removes "Todos"
    newValue.pop();
    return newValue;
  }
  return currentValue;
};
export function equalSelectorArrays(array1, array2) {
  if (array1.length === array2.length) {
    let found = false;
    array1.forEach(element1 => {
      found = false;
      array2.forEach(element2 => {
        if (element1.value === element2.value) {
          found = true;
          return;
        }
      });
      if (!found) return;
    });

    return found;
  }
  return false;
}

function arrayContainsElement(element, array) {
  let found = false;
  array.forEach(element1 => {
    if (element1.value === element.value) {
      found = true;
      return;
    }
  });
  return found;
}
function mergeSelectorArray(array1, array2) {
  let finalArray = array1;
  array1.forEach(element1 => {
    array2.forEach(element2 => {
      if (element1.value !== element2.value) {
        if (!arrayContainsElement(element2, finalArray))
          finalArray.push(element2);
      }
    });
  });
  return finalArray;
}
function changeSelectedValues(currentValue, returnValue) {
  let newValue = [];
  for (let i = 0; i < currentValue.length; i++) {
    let value = currentValue[i].value;

    let index = returnValue.findIndex(function (item, i) {
      return item.value === value;
    });
    if (index > -1) {
      newValue.push(currentValue[i]);
    }
  }
  return newValue;
}
//returns the selected values
// yet another stupid function YASF
function parseSelectorResult(
  result,
  updateSelected,
  currentValue,
  changeSelectorFunction,
  changeSelectedFunction,
  addAll = true,
  selectAll = false,
  selectFirst = true,
) {
  let selectorResult = [];
  let selected = [];
  if (result.success) {
    if (result.payload && result.payload.length && result.payload.length > 0) {
      // is somepoint UniqueKey had a value or in some cases has a value
      selectorResult = result.payload.map(
        ({ Id, Title, UniqueKey, ExtId, Objective, Currency }) => ({
          value: Id,
          label: Title,
          UniqueKey: UniqueKey,
          ExtId: ExtId,
          Objective: Objective,
          Currency: Currency,
        }),
      );
      if (addAll) {
        selectorResult.push(todosSelectorValue);
      }
      if (updateSelected) {
        selected = changeSelectedValues(currentValue, selectorResult);
        if (selected.length === 0)
          if (selectAll && addAll) selected = [todosSelectorValue];
          else if (selectFirst) selected = [selectorResult[0]];
      }
      //select first
      else if (selectFirst) selected = [selectorResult[0]];
    } else {
      changeSelectorFunction([]);
    }

    changeSelectorFunction(selectorResult);

    changeSelectedFunction(selected);
    if (selected.length === 1) {
      //Todos
      if (selected[0].value === -1) {
        selected = selectorResult.map(value => value);
        //remove Todos added before
        selected.pop();
      }
    }
    return selected;
  }

  return [];
}
export async function customFetch(
  url,
  requestInit,
  funtionForResult,
  paramsFunctionResult,
  props,
  updateSelected,
  layoutLoading,
) {
  if (layoutLoading) {
    if (props.changeIsLoadingSideBar) props.changeIsLoadingSideBar(false);
    if (props.changeIsLoadingLayout) props.changeIsLoadingLayout(true);
  } else {
    if (props.changeIsLoadingSideBar && !props.isFirstLoad)
      props.changeIsLoadingSideBar(true);
  }
  let status = 400;

  try {
    await fetch(url, requestInit).then(result => {
      if (result) {
        status = result.status;
        if (result.status === unauthorizedHttpError) {
          let auth = new Auth();
          result.json().then(resultjson => {
            auth.sessionExpired();
          });
        } else if (result.status === serviceUnavailableHttpError) {
          funtionForResult([], paramsFunctionResult, props, updateSelected, -1);
        } else if (result.status === noContentHttpCode) {
          funtionForResult([], paramsFunctionResult, props, updateSelected, -1);
        } else if (result.status === facebookGasHttpErrorCode) {
          alertPopUp(
            'Se ha llegado al limite de las llamadas a Facebook para la Cuenta Publicitaria',
            props,
            true,
          );
          props.changeFacebookNoGas(true);
          funtionForResult([], paramsFunctionResult, props, updateSelected, -1);
        } else
          result
            .json()
            .then(resultjson => {
              if (funtionForResult) {
                if (!resultjson.payload) resultjson.payload = [];
                funtionForResult(
                  resultjson,
                  paramsFunctionResult,
                  props,
                  updateSelected,
                  status,
                );
              } else if (layoutLoading) {
                if (props.changeIsLoadingLayout)
                  props.changeIsLoadingLayout(true);
              } else if (props.changeIsLoadingSideBar)
                props.changeIsLoadingSideBar(true);
            })
            .catch(error => {
              console.log(error);
              if (!window.isReloading) {
                alertPopUp(
                  'Hubo un error en la solicitud realizada, revisar conexión a Internet y pruebe nuevamente',
                  props,
                  true,
                );
                if (props.changeIsLoadingLayout)
                  props.changeIsLoadingLayout(false);

                if (props.changeLoadingMessage) {
                  props.changeLoadingMessage('');
                }

                funtionForResult(
                  [],
                  paramsFunctionResult,
                  props,
                  updateSelected,
                  -1,
                );
              }
            });
      } else {
        funtionForResult(
          [],
          paramsFunctionResult,
          props,
          updateSelected,
          status,
        );
      }
    });
  } catch (err) {
    console.log(err);
    if (!window.isReloading) {
      alertPopUp(
        'Hubo un error en la solicitud realizada, revisar conexión a Internet y pruebe nuevamente',
        props,
        true,
      );
      if (props.changeLoadingMessage) {
        props.changeLoadingMessage('');
      }
      if (props.changeIsLoadingLayout) props.changeIsLoadingLayout(false);
      funtionForResult([], paramsFunctionResult, props, updateSelected, -1);
    }
  }
}

export function createHeaders() {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('access_token')}`,
  );
  myHeaders.append('nonce', getLocalStorageNonce());
  return myHeaders;
}

function getAllIdsFromParameterId2(id, store) {
  if (store[id] && store[id].length && store[id].length > 0) {
    return store[id].map(value => value);
  }
  return [{ value: '', label: 'notFound' }];
}

function getAllAreSelectedFromParameterId(parameterId, props, currentParams) {
  if (parameterId === 'selectedAccounts') {
    return (
      props.selectorAccount.length === currentParams.selectedAccounts.length
    );
  } else if (parameterId === 'selectedAdAccounts') {
    return (
      props.selectorAdAccount.length - 1 ===
      currentParams.selectedAdAccounts.length
    );
  } else if (parameterId === 'selectedOptimizations') {
    return (
      props.selectorOptimization.length - 1 ===
      currentParams.selectedOptimizations.length
    );
  } else if (parameterId === 'selectedCampaignTypes') {
    return (
      props.selectorCampaignType.length ===
      currentParams.selectedCampaignTypes.length
    );
  } else if (parameterId === 'selectedProducts') {
    return (
      props.selectorProduct.length - 1 === currentParams.selectedProducts.length
    );
  } else if (parameterId === 'selectedGenders') {
    if (props.selectorGender.length > 0)
      return (
        props.selectorGender.length - 1 === currentParams.selectedGenders.length
      );
  } else if (parameterId === 'selectedAges') {
    if (props.selectorAge.length > 0)
      return props.selectorAge.length - 1 === currentParams.selectedAges.length;
  }
}
function getUrlParameterName(parameterName) {
  switch (parameterName) {
    case 'selectedTenants':
      return 'accountId';
    case 'selectedCategories':
      return 'categoryId';
    case 'selectedAccounts':
      return 'accountId';
    case 'selectedBrands':
      return 'brandValue';
    case 'selectedCampaignNames':
      return 'campaignId';
    case 'selectedAdAccounts':
      return 'adAccountId';
    case 'selectedCompanies':
      return 'companyValue';

    case 'selectedOptimizations':
      return 'optimizationType';

    case 'selectedCampaignTypes':
      return 'campaignTypeId';

    case 'selectedProducts':
      return 'product';

    case 'selectedGenders':
      return 'genderId';

    case 'selectedAges':
      return 'ageId';

    default:
      return '_';
  }
}

export function toISODate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}
export function changeLinkPreview(props, newParameters, url) {
  let urlParameters = '';
  newParameters.forEach(element => {
    if (urlParameters === '' && !url.includes('?')) urlParameters = '?';
    else urlParameters += '&';
    urlParameters += element;
  });
  props.changeAdLinkPreview(url + '' + urlParameters);
}
function getUrlParametersFromComponentNeededParams(
  currentParams,
  props,
  componentNeededParams,
) {
  let urlParameters = '';
  if (componentNeededParams) {
    for (let i = 0; i < componentNeededParams.length; i++) {
      let parameterList = [];
      if (currentParams[componentNeededParams[i]]) {
        if (componentNeededParams[i] === 'selectedDates') {
          if (currentParams.selectedDates) {
            if (currentParams.selectedDates.length > 1) {
              urlParameters += urlParameters === '' ? '?' : '&';
              //if (urlParameters === '') urlParameters += '?';
              //else urlParameters += '&';
              urlParameters +=
                'fDate=' +
                encodeURIComponent(toISODate(currentParams.selectedDates[0]));
              urlParameters +=
                '&lDate=' +
                encodeURIComponent(toISODate(currentParams.selectedDates[1]));
            }
          }
        } else {
          if (
            currentParams[componentNeededParams[i]][0].value === -1 ||
            getAllAreSelectedFromParameterId(
              componentNeededParams[i],
              props,
              currentParams,
            )
          ) {
            parameterList = getAllIdsFromParameterId2(
              componentNeededParams[i],
              currentParams,
            );
            //remove -1 Todos
            if (parameterList[parameterList.length - 1].value === -1)
              parameterList.pop();
          } else {
            parameterList = currentParams[componentNeededParams[i]];
          }

          if (urlParameters === '') {
            urlParameters += parameterListToUrl(
              parameterList,
              getUrlParameterName(componentNeededParams[i]),
              true,
            );
          } else
            urlParameters += parameterListToUrl(
              parameterList,
              getUrlParameterName(componentNeededParams[i]),
              false,
            );
        }
      }
    }
  }
  return urlParameters;
}
//getAdInterestsUrl

export function getInterests(props, params) {
  var myHeaders = createHeaders();
  let url = getAdInterestsUrl;
  url += '?qParam=' + props.interestsSearchText;
  props.changeInterestsSelector([]);
  props.changeInterestsSelected([]);
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateInterests,
    [],
    props,
    false,
    true,
  );
}
export function isFacebookeUrl(url) {
  return /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i.test(url);
}
function updateInterests(result, params, props, updateSelected, status) {
  props.changeInterestsSelector([]);
  props.changeInterestsSelected([]);
  props.changeInterestsSearchText('');
  if (status === successHttpCode) {
    if (result.payload) {
      let interests = result.payload.map(
        ({ id, name, audience_size, path, description, topic }) => ({
          label: name,
          value: id,
          audience_size,
          description,
          topic,
          path: path ? (path.join ? path.join(', ') : '') : '',
        }),
      );
      filterAdvanceTargeting(props, interests, props.interests);
      props.changeInterestsSelected([]);
    }
  }
  runChildFunction(props.customPageId, '', props, params, updateSelected);
}

export function getAdvancedTargeting(value, props) {
  if (value === 'relationship_statuses') {
    updateAdvancedTargeting(
      { payload: relationshipStatuses },
      [],
      props,
      false,
      successHttpCode,
    );
    fakeLayoutLoading(props.changeIsLoadingLayout);
  } else if (value === 'education_statuses') {
    updateAdvancedTargeting(
      { payload: educationStatuses },
      [],
      props,
      false,
      successHttpCode,
    );
    fakeLayoutLoading(props.changeIsLoadingLayout);
  } else {
    var myHeaders = createHeaders();
    let url = `${getAdTargetingCategoryUrl}?targetingCategory=${value}&lang=${props.lang}`;
    props.changeInterestsSelector([]);
    props.changeInterestsSelected([]);
    customFetch(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      updateAdvancedTargeting,
      [],
      props,
      false,
      true,
    );
  }
}
function updateAdvancedTargeting(
  result,
  params,
  props,
  updateSelected,
  status,
) {
  props.changeInterestsSelector([]);
  props.changeInterestsSelected([]);
  if (status === successHttpCode) {
    if (result.payload) {
      let interests = result.payload.map(
        ({ id, name, audience_size, path, description, topic, key }) => ({
          label: key ? trans(key, props.lang) : name,
          value: id,
          audience_size,
          description,
          topic,
          path: path ? (path.join ? path.join(', ') : '') : '',
        }),
      );
      filterAdvanceTargeting(props, interests, props.interests);
      props.changeInterestsSelected([]);
    }
  }
  runChildFunction(props.customPageId, '', props, params, updateSelected);
}

export function filterAdvanceTargeting(
  props,
  selector,
  targeting,
  removedTargeting = [],
) {
  let filterTargeting = [];

  removedTargeting.forEach(element => {
    if (element.fbType === props.advanceTargetingSearch)
      filterTargeting.push(element);
  });
  selector.forEach(element => {
    let ignore = false;

    targeting.forEach(element2 => {
      if (element.value === element2.value) {
        ignore = true;
      }
    });

    if (!ignore) {
      filterTargeting.push(element);
    }
  });
  props.changeInterestsSelector(filterTargeting);
}
export function getGeoLocation(props, params) {
  var myHeaders = createHeaders();
  let url = getGeoLocationUrl;
  url += '?geoTargetId=' + props.geoTargetingSearch;
  url += '&qParam=' + props.geoTargetingSearchText;
  props.changeGeoTargetingSelector([]);
  props.changeGeoTargetingSelected([]);
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateGeoLocations,
    [],
    props,
    false,
    true,
  );
}

export function filterGeolocations(
  props,
  geoLocations,
  locations,
  removedLocations = [],
) {
  let filteredLocations = [];

  removedLocations.forEach(element => {
    if (
      element.geoTargetingSearch === props.geoTargetingSearch ||
      props.geoTargetingSearch === 'all'
    )
      filteredLocations.push(element.selected);
  });
  geoLocations.forEach(element => {
    let ignore = false;
    if (['country', 'region', 'city', 'zip'].includes(element.type)) {
      locations.forEach(element2 => {
        if (element.key === element2.key) {
          ignore = true;
        }
      });
    } else {
      ignore = true;
    }
    if (!ignore) {
      filteredLocations.push(element);
    }
  });
  props.changeGeoTargetingSelector(filteredLocations);
}
function getGeoLocationName(type, name, country_name, region) {
  return type === 'city'
    ? name + ' (' + country_name + ', ' + region + ')'
    : type === 'region'
    ? name + ' (' + country_name + ')'
    : type === 'zip'
    ? name + ' (' + country_name + ', ' + region + ')'
    : name;
}
function updateGeoLocations(result, params, props, updateSelected, status) {
  //status
  if (status === successHttpCode) {
    if (result.payload) {
      let geoLocations = result.payload.map(
        ({
          name,
          country_code,
          type,
          key,
          region_id,
          country_name,
          region,
          primary_city_id,
        }) => ({
          label: (
            <Grid container>
              <Grid item xs={6} lg={6} md={6}>
                {getGeoLocationName(type, name, country_name, region)}
              </Grid>
              <Grid item xs={6} lg={6} md={6}>
                <div style={{ float: 'right' }}>
                  {' | ' + trans(type.toUpperCase(), props.lang)}
                </div>
              </Grid>
            </Grid>
          ),
          region_id: region_id ? region_id.toString() : '',
          country_code: country_code ? country_code.toString() : '',
          type: type,
          value:
            getGeoLocationName(type, name, country_name, region) +
            '|' +
            getLocationTypeNameByType(type),
          key: key,
          name: getGeoLocationName(type, name, country_name, region),
          primary_city_id: primary_city_id ? primary_city_id.toString() : '',
        }),
      );
      filterGeolocations(props, geoLocations, props.locations);
    }
  }
  runChildFunction(
    props.customPageId,
    'dataSet10Id',
    props,
    params,
    updateSelected,
  );
}
function getDataset11(props, params) {
  props.changedataSet11Json([]);
  var myHeaders = createHeaders();
  let url = getDataset11Url;
  url += params.urlParameters;
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateDataset11,
    [],
    props,
    false,
    true,
  );
}
export function recalculateMessages(currentThis) {
  let messages = [];
  let newState = currentThis.state;
  for (let i = 0; i < currentThis.state.list.length; i++) {
    messages.push(
      currentThis.props.getMessage
        ? currentThis.props.getMessage(
            i,
            currentThis.state.list,
            currentThis.props,
          )
        : '',
    );
  }
  newState.message = messages;
  currentThis.setState(newState);
}
function updateDataset11(result, params, props, updateSelected) {
  props.changedataSet11Json([]);
  if (result.success) {
    if (result.payload) {
      if (
        result.payload &&
        result.payload.length &&
        result.payload.length > 0
      ) {
        result.payload.forEach(element => {
          if (element['Fecha'])
            if (element['Fecha'].length)
              if (element['Fecha'].length >= 10)
                element['Fecha'] = element['Fecha'].substring(0, 10);
        });
        props.changedataSet11Json(result.payload);

        props.changeIsLoadingLayout(false);
      } else {
        alertPopUp(
          'No se encontro información para Dataset 11 para los filtros seleccionados',
          props,
          true,
        );

        props.changeIsLoadingLayout(false);
      }
    } else {
      alertPopUp(
        'No se encontro información para Dataset 11 para los filtros seleccionados',
        props,
        true,
      );
      props.changeIsLoadingLayout(false);
    }
  } else {
    alertPopUp(
      'No se encontro información para los filtros seleccionados',
      props,
      true,
    );
    props.changeIsLoadingLayout(false);
  }
}
export function getDataset10and11(props) {
  props.changedataSet10Json([]);
  props.changedataSet11Json([]);

  var myHeaders = createHeaders();

  let url = getDataset10Url;
  let urlParameters =
    '?fDate=' + encodeURIComponent(toISODate(props.selectedDates[0]));
  urlParameters +=
    '&lDate=' + encodeURIComponent(toISODate(props.selectedDates[1]));
  urlParameters += '&accountId=' + getFunctionTenant([], props)[0].value;
  if (props.selectedAdminCompanies[0].value !== -1)
    urlParameters += parameterListToUrl(
      props.selectedAdminCompanies,
      'company',
      false,
    );
  if (
    props.selectedAdminProducts &&
    props.selectedAdminProducts.length &&
    props.selectedAdminProducts.length > 0 &&
    props.selectedAdminProducts[0].value !== -1
  )
    urlParameters += parameterListToUrl(
      props.selectedAdminProducts,
      'product',
      false,
    );
  if (
    props.selectedAdminAdAccounts &&
    props.selectedAdminAdAccounts.length &&
    props.selectedAdminAdAccounts.length > 0 &&
    props.selectedAdminAdAccounts[0].value !== -1
  )
    urlParameters += parameterListToUrl(
      props.selectedAdminAdAccounts,
      'adAccount',
      false,
    );

  url += urlParameters;

  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateDataset10,
    { urlParameters: urlParameters },
    props,
    false,
    true,
  );
}
function updateDataset10(result, params, props, updateSelected) {
  props.changedataSet10Json([]);
  if (result.success) {
    if (result.payload) {
      if (
        result.payload &&
        result.payload.length &&
        result.payload.length > 0
      ) {
        result.payload.forEach(element => {
          if (element['Fecha'])
            if (element['Fecha'].length)
              if (element['Fecha'].length >= 10)
                element['Fecha'] = element['Fecha'].substring(0, 10);
        });
        props.changedataSet10Json(result.payload);
        getDataset11(props, params);
      } else {
        alertPopUp(
          'No se encontro información para los filtros seleccionados',
          props,
          true,
        );

        props.changeIsLoadingLayout(false);
      }
    } else {
      alertPopUp(
        'No se encontro información para los filtros seleccionados',
        props,
        true,
      );

      props.changeIsLoadingLayout(false);
    }
  } else {
    alertPopUp(
      'No se encontro información para los filtros seleccionados',
      props,
      true,
    );
    props.changeIsLoadingLayout(false);
  }
}

export function toFbDate(date, isEndDate = false) {
  if (isEndDate) {
    date.setHours(23, 59, 59, 59);
    return Math.round(date.getTime() / 1000);
  } else return Math.round(date.getTime() / 1000);
}

function toCurrentDate(date) {
  //offset is in minutes
  return date.getTime() + date.getTimezoneOffset() * 60000;
}

//Needed values will be added if they are not in the payload with default value depending if it is date new Date() text "" or number 0
function cleanNulls(payload, dateNames, textNames, needeValues) {
  if (!textNames) textNames = [];
  if (!dateNames) dateNames = [];
  payload.forEach(element => {
    let keys = Object.keys(element);
    for (let i = 0; i < keys.length; i++) {
      if (dateNames.includes(keys[i])) {
        if (!element[keys[i]]) {
          element[keys[i]] = new Date(0);
        }
      } else if (textNames.includes(keys[i])) {
        if (!element[keys[i]]) {
          element[keys[i]] = '';
        }
      } else {
        if (!element[keys[i]]) {
          element[keys[i]] = 0;
        }
      }
    }
    for (let i = 0; i < needeValues.length; i++) {
      if (!keys.includes(needeValues[i])) {
        if (dateNames.includes(keys[i])) {
          if (!element[keys[i]]) {
            element[needeValues[i]] = new Date(0);
          }
        } else if (textNames.includes(keys[i])) {
          if (!element[keys[i]]) {
            element[needeValues[i]] = '';
          }
        } else {
          if (!element[keys[i]]) {
            element[needeValues[i]] = 0;
          }
        }
      }
    }
  });

  return payload;
}

export function getDataset10Charts(
  params,
  props,
  updateSelected,
  skipAutoLoadingValidation = false,
) {
  cleanDasboards(props);

  if (
    props.autoLoad ||
    props.autoLoad === undefined ||
    skipAutoLoadingValidation
  ) {
    let component = getComponentMapping(
      props.customPageId,
      'NeededParams',
      false,
    );

    let skip = getSkipFetch(params, component.componentNeededParams, props);

    if (!skip) {
      let url = getDataset10UrlCharts;
      let urlParameters = getUrlParametersFromComponentNeededParams(
        params,
        props,
        component.componentNeededParams,
      );

      url += urlParameters;
      var myHeaders = createHeaders();
      params.urlParameters = urlParameters;
      // console.log(params);
      customFetch(
        url,
        {
          method: 'GET',
          headers: myHeaders,
        },
        updateDataset10Charts,
        params,
        props,
        updateSelected,
        true,
      );
    } else {
      runChildFunction(
        props.customPageId,
        'dataSet10Id',
        props,
        params,
        updateSelected,
      );
    }
  } else {
    runChildFunction(
      props.customPageId,
      'dataSet10Id',
      props,
      params,
      updateSelected,
    );
  }
}
function updateDataset10Charts(result, params, props, updateSelected) {
  cleanDasboards(props);
  if (result.success) {
    if (result.payload) {
      if (
        result.payload &&
        result.payload.length &&
        result.payload.length > 0
      ) {
        let component = getComponentMapping(
          props.customPageId,
          'dataSet10Id',
          false,
        );

        let payload = cleanNulls(
          result.payload,
          ['RunDate'],
          [],
          [
            'RunDate',
            'CTR',
            'LinkClicks',
            'Leads',
            'CPC',
            'Acciones',
            'Cost',
            'Impressions',
            'CPA',
            'ConvRate',
          ],
        );

        if (component.dashboards.includes('dashboard31'))
          props.changeDashboard31Data(
            calculateTrend(
              payload.map(({ CPA, RunDate, Actions }) => ({
                CPA: Number(CPA),
                date: toCurrentDate(new Date(RunDate)),
                Acciones: Number(Actions),
              })),
              'date',
              'CPA',
              'Acciones',
            ),
          );
        if (component.dashboards.includes('dashboard32'))
          props.changeDashboard32Data(
            calculateTrend(
              payload.map(({ LinkClicks, RunDate, Actions }) => ({
                Clicks: Number(LinkClicks),
                date: toCurrentDate(new Date(RunDate)),
                Acciones: Number(Actions),
              })),
              'date',
              'Clicks',
              'Acciones',
            ),
          );
        if (component.dashboards.includes('dashboard33'))
          props.changeDashboard33Data(
            calculateTrend(
              payload.map(({ Cost, RunDate, Actions }) => ({
                Cost: Number(Cost),
                date: toCurrentDate(new Date(RunDate)),
                Acciones: Number(Actions),
              })),
              'date',
              'Cost',
              'Acciones',
            ),
          );

        if (component.dashboards.includes('dashboard34'))
          props.changeDashboard34Data(
            calculateTrend(
              payload.map(({ ConvRate, RunDate, CTR }) => ({
                convertionRate: ConvRate,
                date: toCurrentDate(new Date(RunDate)),
                CTR,
              })),
              'date',
              'convertionRate',
              'CTR',
            ),
          );
      }
    }
  }
  getDataset10ChartsTotal(params, props, updateSelected, true);
}

export function getDataset10ChartsTotal(
  params,
  props,
  updateSelected,
  skipAutoLoadingValidation = false,
) {
  if (
    props.autoLoad ||
    props.autoLoad === undefined ||
    skipAutoLoadingValidation
  ) {
    let url = getDataset10UrlChartsTotal;

    url += params.urlParameters;
    var myHeaders = createHeaders();

    customFetch(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      updateDataset10ChartsTotal,
      params,
      props,
      updateSelected,
      true,
    );
  } else {
    runChildFunction(
      props.customPageId,
      'dataSet10Id',
      props,
      params,
      updateSelected,
    );
  }
}
function updateDataset10ChartsTotal(result, params, props, updateSelected) {
  if (result.success) {
    if (result.payload) {
      if (
        result.payload &&
        result.payload.length &&
        result.payload.length > 0
      ) {
        let component = getComponentMapping(
          props.customPageId,
          'dataSet10Id',
          false,
        );

        if (component.dashboards.includes('dashboard35')) {
          props.changeDashboard35Data(result.payload[0]);
        }
      }
    }
  }
  runChildFunction(
    props.customPageId,
    'dataSet10Id',
    props,
    params,
    updateSelected,
  );
  getCampaignStatsChart(params, props, updateSelected, true);
}

export function getCampaignStatsChart(
  params,
  props,
  updateSelected,
  skipAutoLoadingValidation = false,
) {
  if (
    props.autoLoad ||
    props.autoLoad === undefined ||
    skipAutoLoadingValidation
  ) {
    let url = getCampaignStatsUrl;

    url += params.urlParameters;
    var myHeaders = createHeaders();

    customFetch(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      updateCampaignStatsChart,
      params,
      props,
      updateSelected,
      true,
    );
  } else {
    runChildFunction(
      props.customPageId,
      'dataSet10Id',
      props,
      params,
      updateSelected,
    );
  }
}
function updateCampaignStatsChart(result, params, props, updateSelected) {
  if (result.success) {
    if (result.payload) {
      if (
        result.payload &&
        result.payload.length &&
        result.payload.length > 0
      ) {
        let component = getComponentMapping(
          props.customPageId,
          'dataSet10Id',
          false,
        );

        if (component.dashboards.includes('dashboard36')) {
          props.changeDashboard36Data(result.payload);
        }
      }
    }
  }
  runChildFunction(
    props.customPageId,
    'dataSet10Id',
    props,
    params,
    updateSelected,
  );
}

export function getProductAdminApi(
  props,
  selectedAccount,
  selectedProducts,
  updateFunction = updateProductAdminApi,
  downloadstats = false,
  filterByAdAccount = false,
  selectedAdAccounts = [],
) {
  if (props.changePagePagination) props.changePagePagination(0);
  var myHeaders = createHeaders();
  let url = getProductsUrlAdminApi;
  if (downloadstats) url = getProductsUrlAdminApi;
  url += parameterListToUrl(selectedAccount, 'accountId', true);
  if (filterByAdAccount)
    url += parameterListToUrl(selectedAdAccounts, 'adAccountId', false);
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateFunction,
    { selectedProducts: selectedProducts, downloadstats: downloadstats },
    props,
    false,
    true,
  );
}
export function changeFilteredCategoriesProducts(
  props,
  country,
  category,
  products,
) {
  let Products = [];
  let Categories = [];
  let CategorieIds = [];
  if (!products) products = props.selectorAdminProduct;
  products.forEach(element => {
    if (
      !CategorieIds.includes(element.CategoryId) &&
      (element.CountryId === country || country === -1 || !country) &&
      (element.CategoryId === category || category === -1 || !category)
    ) {
      CategorieIds.push(element.CategoryId);
      Categories.push({
        value: element.CategoryId,
        label: element.CategoryTitle,
      });
    }
    if (
      (element.CountryId === country || country === -1 || !country) &&
      (element.CategoryId === category || category === -1 || !category)
    ) {
      Products.push(element);
    }
  });
  Categories.push({ value: -1, label: 'Todas' });
  if (!category) props.changeSelectorCategoryFiltered(Categories);
  props.changeSelectorAdminProductFiltered(Products);
  props.changeUpdateProducts(true);
}
function updateProductAdminApiFilters(result, params, props, updateSelected) {
  if (result.payload && result.payload.length && result.payload.length > 0) {
    let Products = result.payload.map(
      ({ Id, Title, CountryId, CountryTitle, CategoryId, CategoryTitle }) => ({
        value: Id,
        label: Title,
        CountryId: CountryId ? CountryId : '-1',
        CountryTitle: CountryTitle ? CountryTitle : 'Sin Asignar',
        CategoryId: CategoryId ? CategoryId : '-1',
        CategoryTitle: CategoryTitle ? CategoryTitle : 'Sin Asignar',
      }),
    );
    let Countries = [];
    let Categories = [];
    let CountrieIds = [];
    let CategorieIds = [];
    Products.forEach(element => {
      if (!CountrieIds.includes(element.CountryId)) {
        CountrieIds.push(element.CountryId);
        Countries.push({
          value: element.CountryId,
          label: element.CountryTitle,
        });
      }
      if (!CategorieIds.includes(element.CategoryId)) {
        CategorieIds.push(element.CategoryId);
        Categories.push({
          value: element.CategoryId,
          label: element.CategoryTitle,
        });
      }
    });
    Countries.push({ value: -1, label: 'Todos' });
    Categories.push({ value: -1, label: 'Todas' });
    props.changeSelectedCategoriesFiltered({ value: -1, label: 'Todas' });
    props.changeSelectedCountriesFiltered({ value: -1, label: 'Todos' });
    props.changeSelectorCategory(Categories);
    props.changeSelectorCategoryFiltered(Categories);
    props.changeSelectorCountry(Countries);
    props.changeSelectorCountryFiltered(Countries);
    props.changeSelectorAdminProduct(Products);
    props.changeSelectorAdminProductFiltered(Products);

    if (params.selectedProducts) {
      changeFilteredCategoriesProducts(
        props,
        props.selectedCountries.value,
        props.selectedCategories[0].value,
        Products,
      );
      props.changeSelectedAdminProductsFiltered(params.selectedProducts);
      props.changeSelectedCountriesFiltered(props.selectedCountries);
      props.changeSelectedCategoriesFiltered(props.selectedCategories);
    }
  }

  runChildFunction(
    props.customPageId,
    productSelectorId,
    props,
    params,
    updateSelected,
  );
  //getAdAccouts(params, props, updateSelected);
}

// function si called for:
// 1 app/modify/listproduct
// DEVELOPERS: add more url from where this code is callied from
function updateProductAdminApi(result, params, props, updateSelected) {
  let Products = [];
  if (result.payload && result.payload.length && result.payload.length > 0) {
    /* leo: I tried to remove the map, can't !!
     */
    Products = result.payload.map(
      ({
        Id,
        Title,
        CategoryId,
        CategoryTitle,
        Description,
        Company,
        CompanyTagId,
        Brand,
        BrandTagId,
      }) => ({
        value: Id,
        label: Title,
        CategoryId: CategoryId,
        CategoryTitle: CategoryTitle,
        Description: Description,
        product: { id: Id, label: Title },
        Company: Company,
        CompanyTagId: CompanyTagId,
        Brand: Brand,
        BrandTagId: BrandTagId,
      }),
    );
  }
  props.changeSelectorAdminProduct(Products);
  if (params.selectedProducts) {
    props.changeSelectedAdminProducts(params.selectedProducts);
  }
  if (props.initProductList) {
    props.changeSelectedAdminProducts(Products);
  }

  runChildFunction(
    props.customPageId,
    productSelectorId,
    props,
    params,
    updateSelected,
  );
}

export function getAdAccountsApiAdmin(props, selectedAccount) {
  var myHeaders = createHeaders();
  let url = getAdAccountsUrlAdminApi;
  url += parameterListToUrl(selectedAccount, 'accountId', true);

  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateAdAccountsAdminApi,
    [],
    props,
    false,
    true,
  );
}
export function getInitAdAccounts(props) {
  var myHeaders = createHeaders();
  var url = getadAccountByUserAdminApiUrl;
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateAdAccountsAdminInitPage,
    [],
    props,
    false,
    true,
  );
}
function updateAdAccountsAdminInitPage(result, params, props, updateSelected) {
  if (result.payload && result.payload.length && result.payload.length > 0) {
    let Accounts = result.payload.map(
      ({ Id, ExtId, IsAssigned, Title }, position) => ({
        value: Id,
        label: IsAssigned
          ? Title + ' (Cuenta Publicitaria ya asociada a Negocio Administrador)'
          : Title,
        externalId: ExtId,
        disabled: IsAssigned ? true : false,
      }),
    );
    props.changeSelectorAdminAdAccount(Accounts);
  }

  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
}
function updateAdAccountsAdminApi(result, params, props, updateSelected) {
  props.changeSelectorAdminAdAccount([]);
  if (result.payload && result.payload.length && result.payload.length > 0) {
    let Accounts = result.payload.map(({ Id, Title, ExtId, Currency }) => ({
      value: Id,
      label: Title,
      externalId: ExtId,
      id: { id: Id, label: Title },
      currency: Currency,
      addRemove: Id % 2 === 0 ? false : true,
    }));
    props.changeSelectorAdminAdAccount(Accounts);
  }

  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
}

export function getAdAccountsByProductApiAdmin(props, productId, params) {
  var myHeaders = createHeaders();
  let url = getAdAccountsByProductUrlAdminApi;
  if (params && params.downloadstats)
    url += '/' + parametersToComaSeparated(productId);
  else url += '/' + productId;
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateAdAccountsByProductAdminApi,
    params,
    props,
    false,
    true,
  );
}
function updateAdAccountsByProductAdminApi(
  result,
  params,
  props,
  updateSelected,
) {
  if (result.payload && result.payload.length && result.payload.length > 0) {
    let Accounts = result.payload.map(({ Id, Title, ExtId, Currency }) => ({
      value: Id,
      label: Title,
      externalId: ExtId,
      currency: Currency,
    }));
    //addes this validaton so this function can be reused
    if (params && (params.createAd || params.downloadstats))
      props.changeSelectorAdminAdAccount(Accounts);
    else {
      props.changeSelectedAdminAdAccounts(Accounts);
      props.changeHasErrorAdAccount(false);
    }
  }
  if (props.modifyProduct && result.payload && result.payload.length === 0) {
    props.changeInitModifyAdAccount(true);
  }

  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
}

export function getCompanyApiAdmin(props, selectedAccount) {
  var myHeaders = createHeaders();
  let url = getCompaniesUrlAdminApi;
  url += '/' + selectedAccount[0].value;
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateCompaniesAdminApi,
    [],
    props,
    false,
    true,
  );
}
function updateCompaniesAdminApi(result, params, props, updateSelected) {
  let Companies = [];
  props.changeSelectedAdminCompanies(Companies);
  if (result.payload && result.payload.length && result.payload.length > 0) {
    /* leo comments:
    for some reason payload contains in the Id and Title the name of the company, instead of the actual id
    {"success":true,"payload":[{"Id":"demo 2022-01-19 10","Title":"demo 2022-01-19 10"}]}
    */
    Companies = result.payload.map(({ Id, Title }) => ({
      value: Id,
      label: Title,
    }));
  }
  if (props.selectorAdminCompany) {
    props.changeSelectorAdminCompany(Companies);
  }
  if (props.selectorCreateProductCompany) {
    props.changeSelectorCreateProductCompany(Companies);
  }

  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
}

export function getBrandApiAdmin(props, selectedAccount, selectedCompany) {
  if (props.changePagePagination) props.changePagePagination(0);
  var myHeaders = createHeaders();
  let url = `${getBrandsUrlAdminApi}/${selectedAccount[0].value}/${selectedCompany[0].value}`;
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateBrandsAdminApi,
    [],
    props,
    false,
    true,
  );
}
function updateBrandsAdminApi(result, params, props, updateSelected) {
  if (result.payload && result.payload.length && result.payload.length > 0) {
    let Brands = result.payload.map(({ Id, Title }) => ({
      value: Id,
      label: Title,
    }));
    if (props.selectorAdminBrand) {
      props.changeSelectorAdminBrand(Brands);
    }
    if (props.selectorCreateProductBrand && Brands.length > 0) {
      props.changeSelectorCreateProductBrand(Brands);
    }
  }

  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
}

export function getPreviewApiAdmin(props, creativeSpecParameters) {
  var myHeaders = createHeaders();
  let url = getAdPreviewUrlAdminApi;
  url +=
    '?ctaType=' +
    creativeSpecParameters.ctaType +
    '&ctaLink=' +
    creativeSpecParameters.ctaLink +
    '&description=' +
    creativeSpecParameters.description +
    '&message=' +
    creativeSpecParameters.message +
    '&name=' +
    creativeSpecParameters.name +
    '&image=' +
    creativeSpecParameters.image +
    '&pageId=' +
    creativeSpecParameters.pageId +
    '&adFormat=' +
    creativeSpecParameters.adFormat +
    '&adAccountExtId=' +
    creativeSpecParameters.adAccountExtId;
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updatePreviewsAdminApi,
    [],
    props,
    false,
    true,
  );
}
function updatePreviewsAdminApi(result, params, props, updateSelected) {
  if (result.payload && result.payload.length && result.payload.length > 0) {
    let AdPreview = result.payload.map(({ body }) => ({
      iframe: body,
    }));
    props.changePreview(AdPreview);
  }

  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
  //getAdAccouts(params, props, updateSelected);
}

export function getPixelAdminApi(props, selectedAdAccount, params = []) {
  var myHeaders = createHeaders();
  let url = getPixelUrlAdminApi;
  url += '/' + selectedAdAccount[0].externalId;
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updatePixelAdminApi,
    params,
    props,
    false,
    true,
  );
}
function updatePixelAdminApi(result, params, props, updateSelected) {
  let pixel = [];
  if (result.payload && result.payload.length && result.payload.length > 0) {
    pixel = result.payload.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }
  props.changeSelectorPixel(pixel);
  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
}
export function getPixelStatsAdminApi(props, selectedPixel, params = []) {
  var myHeaders = createHeaders();
  let url = getPixelStatsUrlAdminApi;
  url += '/' + selectedPixel[0].value;
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updatePixelStatsAdminApi,
    params,
    props,
    false,
    true,
  );
}
function updatePixelStatsAdminApi(result, params, props, updateSelected) {
  let eventStats = [];
  let usedEvents = [];
  let unUsedEvents = [];

  if (result.payload && result.payload.length && result.payload.length > 0) {
    result.payload.map(function (stats) {
      stats.data.map(stat => {
        if (!eventStats.includes(stat.value) && stat.value !== 'PageView')
          eventStats.push(stat.value);
      });
    });
    if (eventStats.length > 0) {
      eventStats.map(id => {
        standardPixelEvents.forEach(element => {
          if (element.event === id) usedEvents.push(element);
        });
      });
      if (eventStats.length < standardPixelEvents.length) {
        standardPixelEvents.map(event => {
          if (!usedEvents.find(e => e.value === event.value))
            unUsedEvents.push(event);
        });
      }
    } else {
      unUsedEvents = standardPixelEvents;
    }
  }
  props.changeSelectorUsedPixelEvent(usedEvents);
  props.changeSelectorPixelEvent(unUsedEvents);
  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
}
export function getApplicationAdminApi(props, selectedAdAccount, params = []) {
  var myHeaders = createHeaders();
  let url = getApplicationsUrlAdminApi;
  url += '/' + selectedAdAccount[0].externalId;
  //url +="/act_2093026644323252"
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateApplicationAdminApi,
    params,
    props,
    false,
    true,
  );
}
function updateApplicationAdminApi(result, params, props, updateSelected) {
  let application = [];
  if (result.payload && result.payload.length && result.payload.length > 0) {
    application = result.payload.map(({ id, name, object_store_urls }) => ({
      value: id,
      label: name,
      store: object_store_urls,
    }));
  }
  props.changeSelectorApplication(application);
  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
}
export function getInstagramAccountAdminApi(
  props,
  selectedFanPage,
  params = [],
) {
  var myHeaders = createHeaders();
  let url = getInstagramAccountUrlAdminApi;
  url += '/' + selectedFanPage[0].value;
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateInstagramAccountAdminApi,
    params,
    props,
    false,
    true,
  );
}
function updateInstagramAccountAdminApi(result, params, props, updateSelected) {
  let igAccount = [];
  if (result.payload && result.payload.length && result.payload.length > 0) {
    igAccount = result.payload.map(({ id, username }) => ({
      value: id,
      label: username,
    }));
  }

  props.changeSelectorAdminInstagramAccount(igAccount);
  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
}

export function getLeadFormsAdminApi(props, selectedFanPage, params = []) {
  var myHeaders = createHeaders();
  let url = getLeadFormUrlAdminApi;
  url += parameterListToNonQueryUrl(selectedFanPage);
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateLeadFormsAdminApi,
    params,
    props,
    false,
    true,
  );
}
function updateLeadFormsAdminApi(result, params, props, updateSelected) {
  let leadGenerationForms = [];

  if (result.payload && result.payload.length && result.payload.length > 0) {
    leadGenerationForms = result.payload.map(({ name, id, business }) => ({
      value: id,
      label: business ? business + ' | ' + name : name,
    }));
  } else if (Environment === 'DEV') {
    leadGenerationForms = [
      { label: 'Form1 (DEV)', value: 'FORM1 DEV' },
      { label: 'Form2 (DEV)', value: 'FORM2 DEV' },
      { label: 'Form3 (DEV)', value: 'FORM3 DEV' },
    ];
  } else if (params.validateEmpty) {
    alertPopUp(params.validateEmptyMessage, props);
  }
  props.changeSelectorLeadGenerationForm(leadGenerationForms);
  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
}

export function getFanPagesAdminApi(props, selectedAdAccount, params = []) {
  var myHeaders = createHeaders();
  customFetch(
    getFanPagesUrlAdminApi,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateFanPageAdminApi,
    params,
    props,
    false,
    true,
  );
}
function updateFanPageAdminApi(result, params, props, updateSelected) {
  let FanPages = [];
  if (result.payload && result.payload.length && result.payload.length > 0) {
    FanPages = result.payload.map(
      ({ name, id, business, has_whatsapp_number }) => ({
        value: id,
        hasWhatsAppNumber: has_whatsapp_number,
        label: business
          ? business + ' | ' + name
          : params.selectedAdAccount[0].label + ' | ' + name,
      }),
    );
  } else if (params.validateEmpty) {
    alertPopUp(params.validateEmptyMessage, props);
  }
  if (props.selectorAdminFanPage.length > 0 && !params.AdAccountChanged)
    FanPages = mergeSelectorArray(props.selectorAdminFanPage, FanPages);
  props.changeSelectorAdminFanPage(FanPages);
  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
  //getAdAccouts(params, props, updateSelected);
}

export async function addAdAccountToAccount(
  props,
  accountId,
  adaccount,
  endFunction,
) {
  if (adaccount.assignedToAccount) {
    endFunction();
  } else {
    var myHeaders = createHeaders();
    let body = { accountId: accountId, adAccountId: adaccount.Id };
    await customFetchAsync(
      addAdAccountToAccountUrl,
      {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(body),
      },
      updateAdAccountToAccount,
      { endFunction: endFunction },
      props,
      false,
      true,
    );
  }
}
export async function createAccount(props, accountName, isOnboarding) {
  var myHeaders = createHeaders();
  let body = { accountName: accountName };
  await customFetchAsync(
    createAccountUrl,
    {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(body),
    },
    updateCreateAccount,
    { isOnboarding: isOnboarding },
    props,
    false,
    true,
  );

  setGASessionItem(accountNewAccountGA, sessionStorageUTMValue, accountName);
  let sendGAdataNewAccount = getGASessionItem();
  sendGAEvent({
    category: GAEventCategoryAccount,
    action: GAEventActionNewAccount,
    label: sendGAdataNewAccount,
  });
}
export async function linkAccountWithUser(props, UniqueKey) {
  var myHeaders = createHeaders();
  let body = { uniqueCode: UniqueKey };
  await customFetchAsync(
    linkAccountWithUserUrl,
    {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(body),
    },
    updateLinkAccount,
    [],
    props,
    false,
    true,
  );
  setGASessionItem(accountAdAccountGA, sessionStorageUTMValue, UniqueKey);
  let sendGAdataLinkAccount = getGASessionItem();
  sendGAEvent({
    category: GAEventCategoryAccount,
    action: GAEventActionLinkAccount,
    label: sendGAdataLinkAccount,
  });
}

function updateCreateAccount(result, params, props, updateSelected, status) {
  let newTenantId = undefined;
  if (status === conflictHttpError)
    alertPopUp(trans('NEW_ACCOUNT_ERROR1', props.lang), props, true);
  else if (status === internalServerErrorHttpError)
    alertPopUp(trans('ERROR_MESSAGE_3', props.lang), props, true);
  else if (status === successHttpCode) {
    props.changeAccountName('');
    //props.changeSelectedTenants()
    if (result && result.length && result.length > 0)
      newTenantId = result[0].iAccountId;
    alertPopUp(
      trans('NEW_ACCOUNT_SUCCESS', props.lang),
      props,
      false,
      false,
      async () => {
        await validateOnBoardingEnd(props, true);
      },
    );
  }
  //getAccountsApiAdmin(props, params.isOnboarding,false,true);
  getTenants(props, newTenantId, true);
}
function updateLinkAccount(result, params, props, updateSelected, status) {
  /**conflictHttpError,
	internalServerErrorHttpError,
	notFoundHttpError,
	serviceUnavailableHttpError, */

  let newTenantId = undefined;

  if (status === conflictHttpError)
    alertPopUp(trans('NEW_ACCOUNT_ERROR2', props.lang), props, true);
  else if (status === internalServerErrorHttpError)
    alertPopUp(trans('ERROR_MESSAGE_3', props.lang), props, true);
  else if (status === notFoundHttpError) {
    alertPopUp(trans('NEW_ACCOUNT_ERROR3', props.lang), props, true);
  } else if (status === successHttpCode) {
    if (result && result.length && result.length > 0)
      newTenantId = result[0].AccountId;
    alertPopUp(
      trans('NEW_ACCOUNT_ASSOC_SUCCESS', props.lang),
      props,
      false,
      false,
      async () => {
        await getNewNonceAndUserPages(props);
        window.location.replace(homeUrl);
      },
    );
    props.changeUniqueAccountKey('');
  }

  getTenants(props, newTenantId, true);
}
function updateAdAccountToAccount(
  result,
  params,
  props,
  updateSelected,
  status,
) {
  if (status === methodNotAllowedHttpError)
    params.endFunction(true, trans('AD_ACCOUNTS_PAGE_MESSAGE9', props.lang));
  else if (status === internalServerErrorHttpError)
    params.endFunction(true, trans('ERROR_MESSAGE_3', props.lang));
  else if (status === conflictHttpError) {
    params.endFunction(true, trans('AD_ACCOUNTS_PAGE_MESSAGE10', props.lang));
  } else if (status === successHttpCode) {
    params.endFunction(false, trans('AD_ACCOUNTS_PAGE_MESSAGE8', props.lang));
  } else {
    params.endFunction(true, trans('ERROR_MESSAGE_3', props.lang));
  }
}
function activateAccount(props, uniqueKey, accountId, accountName) {
  //activateAccountUrl
  var myHeaders = createHeaders();
  let url = activateAccountUrl;
  url += parameterListToUrl([{ value: uniqueKey }], 'uniqueKey', true);
  url += parameterListToUrl([{ value: 20 }], 'status', false);
  url += parameterListToUrl([{ value: accountId }], 'accountId', false);
  customFetch(
    url,
    {
      method: 'PUT',
      headers: myHeaders,
    },
    (result, params, props, updateSelected, status) => {
      if (status === successHttpCode) {
        alertPopUp(accountName + ' Activada', props, false, false, () => {
          getAccountsApiAdmin(props, true, true);
        });
      } else
        alertPopUp(
          'La activación no se pudo realizar, intente nuevamente',
          props,
          true,
          false,
          () => {
            getAccountsApiAdmin(props, true, true);
          },
        );
    },
    null,
    props,
    false,
    true,
  );

  runChildFunction(props.customPageId, '', props, {});
}
export function getAccountUsers(props) {
  customFetch(
    getAccountUsersUrl,
    {
      method: 'GET',
      headers: createHeaders(),
    },
    updateAccountUsers,
    {},
    props,
    false,
    true,
  );
}

function updateAccountUsers(result, params, props, updateSelected, status) {
  if (status === successHttpCode) {
    let userIsStaff = false;
    if (result && result.payload && result.payload.length > 0) {
      let blockedUsers = [];
      let users = [];
      result.payload.forEach(element => {
        if (element.Blocked === 'False') {
          users.push({
            email: element.Email,
            canModify: true,
            name: element.FirstName + ' ' + element.LastName,
            role: element.RoleId,
            userId: element.UserId,
          });
        } else {
          userIsStaff = element.RoleId === staffRoleId;
          blockedUsers.push({
            email: element.Email,
            canModify: false,
            name: element.FirstName + ' ' + element.LastName,
            role: element.RoleId,
            userId: element.UserId,
          });
        }
      });
      let orderedUsers = [];
      blockedUsers.forEach(element => {
        element.currentUserIsStaff = userIsStaff;
        element.showUser = userIsStaff || element.role !== staffRoleId;
        orderedUsers.push(element);
      });
      users.forEach(element => {
        element.currentUserIsStaff = userIsStaff;
        element.showUser = userIsStaff || element.role !== staffRoleId;
        orderedUsers.push(element);
      });
      props.changeUsers(orderedUsers);
    }
  }

  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
}
export async function getAccountsApiAdmin(
  props,
  isOnboarding = false,
  isApproval = false,
  isTenant = false,
  updateFunction = undefined,
  selectedTenantId = undefined,
  runChildFunction = false,
  isClientApproval = false,
) {
  var myHeaders = createHeaders();
  if (updateFunction === undefined) updateFunction = updateAccountsAdminApi;

  customFetch(
    isApproval
      ? getAccountToActivateStaff 
      : getAvailableAccountsUrlAdminApi,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateFunction,
    {
      isApproval: isApproval,
      selectedTenantId: selectedTenantId,
      runChildFunction: runChildFunction,
      isClientApproval: isClientApproval,
    },
    props,
    false,
    true,
  );
}
function updateAccountsAdminApi(result, params, props, updateSelected) {
  let Accounts = [];

  if (result.payload && result.payload.length && result.payload.length > 0) {
    result.payload.forEach(element => {
      if (element.Status === 10 && params.isApproval) {
        Accounts.push({
          value: element.Id,
          label: element.Title,
          UniqueKey: element.UniqueKey,
          status: (
            <Button
              fullWidth={true}
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => {
                activateAccount(
                  props,
                  element.UniqueKey,
                  element.Id,
                  element.Title,
                );
              }}>
              Activar
            </Button>
          ),
        });
      } else if (
        params.isClientApproval &&
        element.Id === getLocalStorageTenant().value
      ) {
        Accounts.push({
          value: element.Id,
          label: element.Title,
          UniqueKey: element.UniqueKey,
          status:
            element.Status === 10
              ? 'Inactivo'
              : element.Status === 20
              ? 'Activo'
              : '-',
        });
      }
    });
    /* else {
			Accounts = result.payload.map(({ Id, Title, UniqueKey, Status }) => ({
				value: Id,
				label: Title,
				UniqueKey: UniqueKey,
				status: Status === 10 ? "Inactivo" : Status === 20 ? "Activo" : "-",
			}));
		}*/
    props.changeSelectorAdminAccount(Accounts);
  }
  props.changeSelectorAdminAccount(Accounts);
  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
  //getAdAccouts(params, props, updateSelected);
}
export function getCategoriesCreateProduct(props) {
  var myHeaders = createHeaders();

  customFetch(
    getCategoriesUrlAdminApi,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateCategoriesCreateProduct,
    [],
    props,
    false,
    true,
  );
}
function updateCategoriesCreateProduct(result, params, props, updateSelected) {
  if (result.payload && result.payload.length && result.payload.length > 0) {
    let Categories = result.payload.map(({ Id, Title }) => ({
      value: Id,
      label: trans(`PRODUCT_CAT_${Id}`, props.lang),
    }));
    props.changeSelectorCategory(Categories);
  }

  runChildFunction(null, null, props, params, updateSelected);
}

export function getTenants(props, selectedTenantId, runChildFunction = false) {
  getAccountsApiAdmin(
    props,
    false,
    false,
    true,
    updateTenants,
    selectedTenantId,
    runChildFunction,
  );
}
async function updateTenants(result, params, props, updateSelected) {
  if (!result.payload) result.payload = [];
  let selectedTenant = undefined;

  params.selectedTenants = parseSelectorResult(
    result,
    updateSelected,
    props.selectedTenants,
    props.changeSelectorTenant,
    props.changeSelectedTenants,
    false,
    false,
    false, //result.payload && result.payload.length === 1
  );
  if (params.selectedTenantId) {
    if (result && result.payload && result.payload.length > 0) {
      result.payload.forEach(element => {
        if (element.Id === params.selectedTenantId) selectedTenant = element;
      });
      if (selectedTenant) {
        selectedTenant.value = selectedTenant.Id;
        selectedTenant.label = selectedTenant.Title;
        tenantValidation(props, selectedTenant, props.customPageId, true, true);
      }
    }
  }
  if (params.runChildFunction && selectedTenant === undefined)
    runChildFunction(
      'tenants',
      accountSelectorId,
      props,
      params,
      updateSelected,
    );

  updatePropsTenants(props, getLocalStorageTenant());
  //getAdAccouts(params, props, updateSelected);

  runChildFunction(
    props.customPageId,
    accountSelectorId,
    props,
    params,
    updateSelected,
  );
}
//if current tab(session) doesn't have nonce , returns last generated nonce in localStorage
export function getLocalStorageNonce() {
  let nonce = undefined;
  if (sessionStorage.nonce !== undefined) {
    nonce = sessionStorage.nonce;
  }
  if (nonce === undefined && localStorage.nonce !== undefined) {
    nonce = localStorage.nonce;
  }

  return nonce;
}
export function updateNonce(nonce) {
  localStorage.setItem('nonce', nonce);
  sessionStorage.setItem('nonce', nonce);
  let alyUserAuthNonceMax = 80; // <----- MUST be equal to the project param
  // convert it from minutes and adds now
  let now = new Date().getTime();
  let alyExpiresAt = JSON.stringify(alyUserAuthNonceMax * 60 * 1000 + now);
  // we need to update aly_expire
  localStorage.setItem('aly_expires_At', alyExpiresAt);
}
export function getLocalStorageTenant() {
  let tenant = undefined;
  if (sessionStorage.selectedTenants !== undefined) {
    tenant = JSON.parse(sessionStorage.selectedTenants);
  }
  if (tenant === undefined && localStorage.selectedTenants !== undefined) {
    tenant = JSON.parse(localStorage.selectedTenants);
  }

  return tenant;
}
function getFunctionTenant(params, props) {
  return params.selectedTenants
    ? params.selectedTenants
    : props.selectedTenants;
}
export function getAdAccountsWithoutDate(params, props, updateSelected, Skip) {
  if (!Skip) {
    var myHeaders = createHeaders();
    let url = getAdAccountsUrlStatsApi;
    url += parameterListToUrl(
      getFunctionTenant(params, props),
      'accountId',
      true,
    );
    customFetch(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      updateAdAccounts,
      params,
      props,
      updateSelected,
      true,
    );
  } else updateAdAccounts({ success: true, payload: [] }, [], props, true);
}

// for stats only?
export function getAdAccouts(params, props, updateSelected, Skip) {
  if (
    params.selectedProducts &&
    params.selectedProducts.length &&
    params.selectedProducts[0].value === -1
  ) {
    params.selectedProducts = props.selectorProduct.map(val => val);
    params.selectedProducts.pop();
  }
  if (!Skip) {
    if (!params.selectedCampaignTypes) {
      params.selectedCampaignTypes = [{ value: -1 }];
    }
    var myHeaders = createHeaders();

    let url = getAdAccountsByProduct;
    url += parameterListToUrl(
      getFunctionTenant(params, props),
      'accountId',
      true,
    );
    url += parameterListToUrl(params.selectedProducts, 'productId', false);
    url += parameterListToUrl(
      params.selectedCampaignTypes,
      'campaignTypeId',
      false,
    );
    url += `&startDate=${toISODate(
      params.selectedDates[0],
    )}&endDate=${toISODate(params.selectedDates[1])}`;

    customFetch(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      updateAdAccounts,
      params,
      props,
      updateSelected,
      props.layoutLoading !== undefined ? props.layoutLoading : false,
    );
  } else updateAdAccounts({ success: true, payload: [] }, [], props, true);
}
function updateAdAccounts(result, params, props, updateSelected) {
  params.selectedAdAccounts = parseSelectorResult(
    result,
    updateSelected,
    props.selectedAdAccounts,
    props.changeSelectorAdAccount,
    props.changeSelectedAdAccounts,
  );

  runChildFunction(
    props.customPageId,
    adAccountSelectorId,
    props,
    params,
    updateSelected,
  );
  //getCampaignTypes(params, props, updateSelected);
}

export function getAudience(props, params) {
  props.changeSelectorCustomAudience([]);
  props.changeSelectorSavedAudience([]);
  props.changeSelectedCustomAudiences([]);
  props.changeSelectedSavedAudiences([]);
  props.changeSavedAudienceSelected(false);
  let myHeaders = createHeaders();

  customFetch(
    getCustomAudience + '/' + params.selectedAdAccount.externalId,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updategetAudience,
    { isCustomAudience: true },
    props,
    true,
    true,
  );

  customFetch(
    getSavedAudience + '/' + params.selectedAdAccount.externalId,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updategetAudience,
    { isCustomAudience: false },
    props,
    false,
    true,
  );
}
function updategetAudience(
  result,
  params,
  props,
  updateSelected,
  resultStatus,
) {
  let selectorResult = [];
  // let selected = [];
  if (result.success) {
    if (result.payload && result.payload.length && result.payload.length > 0) {
      if (params.isCustomAudience) {
        selectorResult = result.payload.map(
          ({ id, name, potentialReach, status }) => ({
            value: id,
            label: status ? (status.code !== 200 ? '*' + name : name) : name,
            potentialReach,
            status,
          }),
        );
        props.changeSelectorCustomAudience(selectorResult);
      } else {
        selectorResult = result.payload.map(({ id, name, targeting }) => ({
          value: id,
          label: name,
          targeting,
        }));

        props.changeSelectorSavedAudience(selectorResult);
      }
    }
  }

  if (!params.isCustomAudience)
    runChildFunction(
      props.customPageId,
      'Campaigns',
      props,
      params,
      updateSelected,
    );
}
export function getSavedAudienceCustomAudinces(props) {
  // let gridSavedCustomAudience = <Grid></Grid>;
  let savedCustomAudiencesInclude = <div></div>;
  let savedCustomAudiencesExclude = <div></div>;
  if (
    props.selectedSavedAudiences &&
    props.selectedSavedAudiences.length > 0 &&
    props.selectedSavedAudiences[0].targeting &&
    props.selectedSavedAudiences[0].targeting.custom_audiences &&
    props.selectedSavedAudiences[0].targeting.custom_audiences.length > 0
  ) {
    savedCustomAudiencesInclude = (
      <Grid item xs={12}>
        <h3>Incluido:</h3>
        <div
          style={{
            width: '90%',
            margin: 'auto',
            border: 'thick solid #013f8a',
          }}>
          <div
            style={{
              width: '90%',
              margin: 'auto',
            }}>
            {props.selectedSavedAudiences[0].targeting.custom_audiences.map(
              (value, index) => (
                <span key={counter++}>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      {value.name}
                    </Grid>
                  </Grid>
                </span>
              ),
            )}
          </div>
        </div>
      </Grid>
    );
  }
  if (
    props.selectedSavedAudiences &&
    props.selectedSavedAudiences.length > 0 &&
    props.selectedSavedAudiences[0].targeting &&
    props.selectedSavedAudiences[0].targeting.excluded_custom_audiences &&
    props.selectedSavedAudiences[0].targeting.excluded_custom_audiences.length >
      0
  ) {
    savedCustomAudiencesExclude = (
      <Grid item xs={12}>
        <h3 style={{ color: 'red' }}>Excluido:</h3>
        <div
          style={{
            width: '90%',
            margin: 'auto',
            border: 'thick solid #ff2727',
          }}>
          <div
            style={{
              width: '90%',
              margin: 'auto',
            }}>
            {props.selectedSavedAudiences[0].targeting.excluded_custom_audiences.map(
              (value, index) => (
                <span key={counter++}>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      {value.name}
                    </Grid>
                  </Grid>
                </span>
              ),
            )}
          </div>
        </div>
      </Grid>
    );
  }
  return (
    <Grid container>
      {savedCustomAudiencesInclude}
      {savedCustomAudiencesExclude}
    </Grid>
  );
}

export function getSavedAudienceGenderandAge(props) {
  let gridSavedGendersAge = <Grid></Grid>;
  if (
    props.selectedSavedAudiences &&
    props.selectedSavedAudiences.length > 0 &&
    props.selectedSavedAudiences[0].targeting
  ) {
    let genders = '';
    let ageMax = '';
    let ageMin = '';

    if (
      props.selectedSavedAudiences[0].targeting.genders &&
      props.selectedSavedAudiences[0].targeting.genders.length === 1 &&
      props.selectedSavedAudiences[0].targeting.genders[0] === 1
    ) {
      genders = 'Masculino';
    } else if (
      props.selectedSavedAudiences[0].targeting.genders &&
      props.selectedSavedAudiences[0].targeting.genders.length === 1 &&
      props.selectedSavedAudiences[0].targeting.genders[0] === 2
    ) {
      genders = 'Femenino';
    } else genders = 'Todos';
    if (props.selectedSavedAudiences[0].targeting.age_max)
      ageMax = props.selectedSavedAudiences[0].targeting.age_max;
    if (props.selectedSavedAudiences[0].targeting.age_min)
      ageMin = props.selectedSavedAudiences[0].targeting.age_min;

    gridSavedGendersAge = (
      <Grid container spacing={4}>
        <div
          style={{
            width: '90%',
            margin: 'auto',
            border: 'thick solid #013f8a',
          }}>
          <div
            style={{
              width: '90%',
              margin: 'auto',
            }}>
            <Grid container style={{ fontSize: '1rem' }}>
              <Grid item xs={6} md={6} lg={6}>
                <span style={{ fontWeight: 'bold' }}> {'Edades: '}</span>
                {ageMin + '-' + (ageMax === 65 ? '65+' : ageMax).toString()}
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <span style={{ fontWeight: 'bold' }}> {'Generos: '}</span>{' '}
                {genders}
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    );
  }
  return gridSavedGendersAge;
}
export function getAdvancedTargetingNameByType(targetingType) {
  switch (targetingType) {
    case 'interests':
      return 'Intereses';
    case 'behaviors':
      return 'Comportamientos';
    case 'life_events':
      return 'Eventos de Vida';
    case 'industries':
      return 'Industrias';
    case 'family_statuses':
      return 'Estatus Familiar';
    case 'relationship_statuses':
      return 'Situaciones Sentimentales';
    case 'education_statuses':
      return 'Educación';
    default:
      return '';
  }
}
export function getLocationTypeNameByType(locationType) {
  switch (locationType) {
    case 'city':
      return 'Ciudad';
    case 'country':
      return 'País';
    case 'region':
      return 'Región';
    case 'zip':
      return 'Código Postal';
    case 'all':
      return 'Todos';
    default:
      return '';
  }
}
function getRelationship_statusName(id) {
  let name = undefined;
  relationshipStatuses.forEach(element => {
    if (element.id === id) name = element.name;
  });
  return name;
}
function getEducation_statusName(id) {
  let name = undefined;
  educationStatuses.forEach(element => {
    if (element.id === id) name = element.name;
  });
  return name;
}
function getInterestsFromFbElement(element, interests, include = true) {
  if (element.interests && element.interests.length > 0) {
    interests.push({ label: getAdvancedTargetingNameByType('interests') });
    element.interests.forEach(interestsElement => {
      interests.push({
        name: interestsElement,
        type: getAdvancedTargetingNameByType('interests'),
        include,
      });
    });
  }
  if (element.behaviors && element.behaviors.length > 0) {
    interests.push({ label: getAdvancedTargetingNameByType('behaviors') });
    element.behaviors.forEach(behaviorsElement => {
      interests.push({
        name: behaviorsElement,
        type: getAdvancedTargetingNameByType('behaviors'),
        include,
      });
    });
  }
  if (element.life_events && element.life_events.length > 0) {
    interests.push({ label: getAdvancedTargetingNameByType('life_events') });
    element.life_events.forEach(life_eventsElement => {
      interests.push({
        name: life_eventsElement,
        type: getAdvancedTargetingNameByType('life_events'),
        include,
      });
    });
  }
  if (element.industries && element.industries.length > 0) {
    interests.push({ label: getAdvancedTargetingNameByType('industries') });
    element.industries.forEach(industriesElement => {
      interests.push({
        name: industriesElement,
        type: getAdvancedTargetingNameByType('industries'),
        include,
      });
    });
  }
  if (element.family_statuses && element.family_statuses.length > 0) {
    interests.push({
      label: getAdvancedTargetingNameByType('family_statuses'),
    });
    element.family_statuses.forEach(family_statusesElement => {
      interests.push({
        name: family_statusesElement,
        type: getAdvancedTargetingNameByType('family_statuses'),
        include,
      });
    });
  }
  if (
    element.relationship_statuses &&
    element.relationship_statuses.length > 0
  ) {
    interests.push({
      label: getAdvancedTargetingNameByType('relationship_statuses'),
    });
    element.relationship_statuses.forEach(relationship_statusesElement => {
      let name = getRelationship_statusName(relationship_statusesElement);
      if (name) {
        interests.push({
          name: { name: name },
          type: getAdvancedTargetingNameByType('relationship_statuses'),
          include,
        });
      }
    });
  }
  if (element.education_statuses && element.education_statuses.length > 0) {
    interests.push({
      label: getAdvancedTargetingNameByType('education_statuses'),
    });
    element.education_statuses.forEach(education_statusesElement => {
      let name = getEducation_statusName(education_statusesElement);
      if (name) {
        interests.push({
          name: { name: name },
          type: getAdvancedTargetingNameByType('education_statuses'),
          include,
        });
      }
    });
  }
}
export function getSavedInterests(props) {
  let gridSavedInterests = <Grid></Grid>;
  let interests = [];
  let excludeInterests = [];
  if (
    props.selectedSavedAudiences &&
    props.selectedSavedAudiences.length > 0 &&
    props.selectedSavedAudiences[0].targeting &&
    props.selectedSavedAudiences[0].targeting.flexible_spec &&
    props.selectedSavedAudiences[0].targeting.flexible_spec.length > 0
  ) {
    props.selectedSavedAudiences[0].targeting.flexible_spec.forEach(element => {
      getInterestsFromFbElement(element, interests, true);
    });
  }
  if (
    props.selectedSavedAudiences &&
    props.selectedSavedAudiences.length > 0 &&
    props.selectedSavedAudiences[0].targeting &&
    props.selectedSavedAudiences[0].targeting.exclusions
  ) {
    getInterestsFromFbElement(
      props.selectedSavedAudiences[0].targeting.exclusions,
      excludeInterests,
      false,
    );
  }

  let savedInterests =
    interests.length > 0 ? (
      <Grid>
        {interests.map(({ name, label }, index) =>
          label ? (
            <span style={{ fontWeight: 'bold' }} key={counter++}>
              {label}:
            </span>
          ) : (
            <span key={counter++}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  *{name.name}
                </Grid>
              </Grid>
            </span>
          ),
        )}
      </Grid>
    ) : undefined;
  let savedInterestsExclude =
    excludeInterests.length > 0 ? (
      <Grid>
        {excludeInterests.map(({ name, label }, index) =>
          label ? (
            <span style={{ fontWeight: 'bold' }} key={counter++}>
              {label}:
            </span>
          ) : (
            <span key={counter++}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  *{name.name}
                </Grid>
              </Grid>
            </span>
          ),
        )}
      </Grid>
    ) : undefined;

  gridSavedInterests = (
    <Grid container spacing={4}>
      {savedInterests ? (
        <Grid item xs={12}>
          <h3>Incluido:</h3>
          <div
            style={{
              width: '90%',
              margin: 'auto',
              border: 'thick solid #013f8a',
            }}>
            <div
              style={{
                width: '90%',
                margin: 'auto',
              }}>
              {savedInterests}
            </div>
          </div>
        </Grid>
      ) : (
        ''
      )}
      {savedInterestsExclude ? (
        <Grid item xs={12}>
          <h3 style={{ color: 'red' }}>Excluido:</h3>
          <div
            style={{
              width: '90%',
              margin: 'auto',
              border: 'thick solid #ff2727',
            }}>
            <div
              style={{
                width: '90%',
                margin: 'auto',
              }}>
              {savedInterestsExclude}
            </div>
          </div>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );

  return gridSavedInterests;
}

function getGeoTargetingFromFbElement(element, list) {
  if (element.custom_locations) {
    list.push(<Grid container></Grid>);

    list.push(
      element.custom_locations.map(element => {
        return (
          <span key={counter++}>
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <span style={{ fontWeight: 'bold' }}>
                  {'Punto Cardinal: lat: '}
                </span>
                {element.latitude}
                <span style={{ fontWeight: 'bold' }}>{', lng: '}</span>
                {element.longitude}
                <span style={{ fontWeight: 'bold' }}>{' Radio: '} </span>
                {element.radius}

                {element.distance_unit === 'kilometer'
                  ? ' KM'
                  : element.distance_unit === 'mile'
                  ? ' Mi'
                  : ' ' + element.distance_unit}
              </Grid>
            </Grid>
          </span>
        );
      }),
    );
  }
  if (element.countries || element.regions || element.cities || element.zips) {
    list.push(<Grid container></Grid>);

    if (element.countries)
      list.push(
        element.countries.map(element => {
          return (
            <span key={counter++}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <span style={{ fontWeight: 'bold' }}>
                    {getLocationTypeNameByType('country')} :
                  </span>{' '}
                  {element}
                </Grid>
              </Grid>
            </span>
          );
        }),
      );
    if (element.regions)
      list.push(
        element.regions.map(element => {
          return (
            <span key={counter++}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <span style={{ fontWeight: 'bold' }}>
                    {getLocationTypeNameByType('region')} :{' '}
                  </span>{' '}
                  {element.name + ' (' + element.country + ')'}
                </Grid>
              </Grid>
            </span>
          );
        }),
      );
    if (element.cities)
      list.push(
        element.cities.map(element => {
          return (
            <span key={counter++}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <span style={{ fontWeight: 'bold' }}>
                    {getLocationTypeNameByType('city')}:{' '}
                  </span>{' '}
                  {element.name +
                    ' (' +
                    element.region +
                    ', ' +
                    element.country +
                    ')'}
                </Grid>
              </Grid>
            </span>
          );
        }),
      );
    if (element.zips)
      list.push(
        element.zips.map(element => {
          return (
            <span key={counter++}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <span style={{ fontWeight: 'bold' }}>
                    {getLocationTypeNameByType('zip')}:{' '}
                  </span>{' '}
                  {element.name + ' (' + element.country + ')'}
                </Grid>
              </Grid>
            </span>
          );
        }),
      );
  }
}
export function getSavedAudienceGeoLocation(props) {
  let gridSavedGeoLocations = <Grid></Grid>;

  let savedGeoLocationsInclude = [];
  let savedGeoLocationsExclude = [];
  if (
    props.selectedSavedAudiences &&
    props.selectedSavedAudiences.length > 0 &&
    props.selectedSavedAudiences[0].targeting &&
    props.selectedSavedAudiences[0].targeting.geo_locations
  ) {
    getGeoTargetingFromFbElement(
      props.selectedSavedAudiences[0].targeting.geo_locations,
      savedGeoLocationsInclude,
    );
  }
  if (
    props.selectedSavedAudiences &&
    props.selectedSavedAudiences.length > 0 &&
    props.selectedSavedAudiences[0].targeting &&
    props.selectedSavedAudiences[0].targeting.excluded_geo_locations
  ) {
    getGeoTargetingFromFbElement(
      props.selectedSavedAudiences[0].targeting.excluded_geo_locations,
      savedGeoLocationsExclude,
    );
  }

  gridSavedGeoLocations = (
    <Grid container spacing={4}>
      {savedGeoLocationsInclude.length > 0 ? (
        <Grid item xs={12}>
          <h3>Incluido:</h3>
          <div
            style={{
              width: '90%',
              margin: 'auto',
              border: 'thick solid #013f8a',
            }}>
            <div
              style={{
                width: '90%',
                margin: 'auto',
              }}>
              {savedGeoLocationsInclude.map(value => {
                return <span key={counter++}>{value}</span>;
              })}
            </div>
          </div>
        </Grid>
      ) : (
        ''
      )}
      {savedGeoLocationsExclude.length > 0 ? (
        <Grid item xs={12}>
          <h3 style={{ color: 'red' }}>Excluido:</h3>
          <div
            style={{
              width: '90%',
              margin: 'auto',
              border: 'thick solid #ff2727',
            }}>
            <div
              style={{
                width: '90%',
                margin: 'auto',
              }}>
              {savedGeoLocationsExclude.map(value => {
                return <span key={counter++}>{value}</span>;
              })}
            </div>
          </div>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
  return gridSavedGeoLocations;
}
export function getCurrency(props) {
  return props.selectedAdminAdAccounts.length > 0 &&
    props.selectedAdminAdAccounts[0].currency
    ? props.selectedAdminAdAccounts[0].currency
    : undefined;
}
export function getAlyCampaigns(params, props) {
  let myHeaders = createHeaders();

  let url =
    getCampaignsAlyUrl +
    parameterListToUrl(params.selectedAdAccounts, 'adAccountId', true);
  customFetch(
    url,
    {
      method: 'GET',
      headers: myHeaders,
    },
    updateAlyCampaigns,
    params,
    props,
    false,
    true,
  );
}
function updateAlyCampaigns(result, params, props, updateSelected, status) {
  params.selectedAdminCampaigns = [];

  let selectorResult = [];
  let selected = [];
  if (result.success) {
    if (result.payload && result.payload.length && result.payload.length > 0) {
      selectorResult = result.payload.map(
        ({ ExtId, Title, Budget, CreatedAt }) => ({
          value: ExtId,
          label: Title,
          Budget,
          CreatedAt,
        }),
      );

      props.changeSelectorAdminCampaign(selectorResult);

      if (params.refreshCampaigns) {
        let selectedExtId =
          props.selectedAdminCampaigns &&
          props.selectedAdminCampaigns.length &&
          props.selectedAdminCampaigns.length > 0
            ? props.selectedAdminCampaigns[0].value
            : 0;
        selectorResult.forEach(element => {
          if (element.value === selectedExtId) selected = [element];
        });
        getfacebookCampaigns(
          {
            selectedAdminCampaigns: props.selectedAdminCampaigns,
            api: 'Admin',
            isBudget: true,
          },
          props,
        );
      }
      props.changeSelectedAdminCampaigns(selected);
    }
  }
  if (!params.refreshCampaigns)
    runChildFunction(
      props.customPageId,
      'Campaigns',
      props,
      params,
      updateSelected,
    );
}
//getfacebookCampaigns
export function getfacebookCampaigns(params, props, updateSelected, Skip) {
  if (!params.selectedAdAccounts)
    params.selectedAdAccounts = props.selectedAdminAdAccounts;
  if (!Skip) {
    if (props.changePagePagination) props.changePagePagination(0);
    var myHeaders = createHeaders();
    let url = '';
    if (!params.api) params.api = '';
    if (params.api === 'Admin') {
      //withoutFilter withoutProduct
      if (params.withoutProduct) {
        url =
          getfacebookCampaignsWithoutProductAdminUrl +
          parameterListToUrl(params.selectedAdAccounts, 'adAccountId', true) +
          parameterListToUrl(
            params.selectedAdAccounts,
            'adAccountExtId',
            false,
            'externalId',
          );
      } else if (params.isUpdate) {
        url =
          getfacebookCampaignsWithFilters +
          parameterListToUrl(params.selectedAdAccounts, 'adAccountId', true);
      } else if (params.isBudget || params.isManager) {
        props.changeShowModify(false);
        url =
          getcampaignManagerUniqueUrl +
          parameterListToUrl(
            params.selectedAdminCampaigns,
            'campaignId',
            true,
          ) +
          parameterListToUrl(
            [{ value: params.selectedAdminCampaigns[0].CreatedAt }],
            'startDate',
            false,
          ) +
          parameterListToUrl(
            [{ value: params.selectedAdminCampaigns[0].Budget }],
            'budget',
            false,
          ) +
          parameterListToUrl(params.selectedAdAccounts, 'adAccountId', false);
      }
    } else {
      url = `${getfacebookCampaignsUrl}${parameterListToUrl(
        params.selectedAdAccounts,
        'adAccountId',
        true,
      )}${parameterListToUrl(
        [{ value: props.customPageId }],
        'pageId',
        false,
      )}`;
      if (params.selectedDates && params.selectedDates.length > 1) {
        url = `${url}${toDateRanQS(params.selectedDates, false, true)}`;
      }
    }
    customFetch(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      updatefacebookCampaigns,
      params,
      props,
      updateSelected,
      true,
    );
  } else {
    updatefacebookCampaigns({ success: true, payload: [] }, [], props, true);
  }
}
/*
What a stupid function is this, very long, hard to read, difficult to understand what it does
*/
function updatefacebookCampaigns(
  result,
  params,
  props,
  updateSelected,
  resultStatus,
) {
  if (
    params.withoutFilter ||
    params.withoutProduct ||
    params.isUpdate ||
    params.isBudget ||
    params.isManager
  ) {
    props.changeCampaignsWithoutOptimization([]);
    props.changeCampaignsWithoutOptimizationFiltered([]);
    props.changeCampaignsWithoutProduct([]);
    props.changeCampaignsWithoutProductFiltered([]);
    props.changeCampaignsWithFilters([]);
    props.changeCampaignsWithFiltersFiltered([]);
    props.changeCampaignsManager([]);
    props.changeCampaignsManagerFiltered([]);
    props.changeModifyCampaignOriginalBudget({ modified: false, value: [''] });
    props.changeModifyCampaignSpent({ modified: false, value: [''] });
    props.changeModifyCampaignRemainingSpent({ modified: false, value: [''] });
    props.changeModifyCampaignAdjusted({ modified: false, value: [''] });
    props.changeModifyCampaignDailyBudget({ modified: false, value: [''] });
    props.changeModifyCampaignBidValue({ modified: false, value: [''] });
    props.changeModifyCampaignRemaining({ modified: false, value: [''] });
    props.changeModifyCampaignDuration({ modified: false, value: [''] });
    props.changeModifyCampaignStatus({ modified: false, value: [''] });
    props.changeModifySelectedDates({ modified: false, value: [''] });
    props.changeModifyCampaignid('');
    props.changeModifyCampaignName({ modified: false, value: [''] });
  }
  if (params.withoutFilter) {
    if (result.payload && result.payload.length && result.payload.length > 0) {
      let campaignsWithoutOptimization = result.payload.map(
        ({ Id, Title, Objective }) => ({
          campaign: { id: Id, label: Title },
          campaignType: Objective,
          account: params.selectedAdminAccounts[0].label,
          adAccount: params.selectedAdAccounts[0].label,
        }),
      );
      props.changeCampaignsWithoutOptimization(campaignsWithoutOptimization);
      props.changeCampaignsWithoutOptimizationFiltered(
        campaignsWithoutOptimization,
      );
    }
  } else if (params.withoutProduct) {
    let campaignsWithoutProduct = [];
    if (result.payload && result.payload.length && result.payload.length > 0) {
      campaignsWithoutProduct = result.payload.map(
        ({ Id, Title, Objective }) => ({
          campaign: { id: Id, label: Title },
          campaignType: Objective,
          account: params.selectedAdminAccounts[0].label,
          adAccount: params.selectedAdAccounts[0].label,
        }),
      );
    }
    props.changeCampaignsWithoutProduct(campaignsWithoutProduct);
    props.changeCampaignsWithoutProductFiltered(campaignsWithoutProduct);
    props.changeUpdateTable(true);
    refreshCustomPage(props.changeUpdateTable, 100, 100);
  } else if (params.isUpdate) {
    if (result.payload && result.payload.length && result.payload.length > 0) {
      let campaignsWithProducts = result.payload.map(
        ({ Id, Title, Objective, ProductId, ProductTitle }) => ({
          campaign: { id: Id, label: Title },
          campaignType: Objective,
          account: params.selectedAdminAccounts[0].label,
          adAccount: params.selectedAdAccounts[0].label,
          products: [],
          selectedProduct: { id: ProductId, label: ProductTitle },
          selectedProductLabel: ProductTitle, //{ ProductId: ProductId, ProductTitle: ProductTitle }
        }),
      );
      props.changeCampaignsWithFilters(campaignsWithProducts);
      props.changeCampaignsWithFiltersFiltered(campaignsWithProducts);
      props.changeUpdateTable(true);
      refreshCustomPage(props.changeUpdateTable, 100, 100);
    }
  } else if (params.isBudget || params.isManager) {
    if (result.payload && resultStatus === successHttpCode) {
      result.payload = [result.payload];
      let campaignsManager = result.payload.map(
        ({
          name,
          id,
          objective,
          startDate,
          endDate,
          status,
          //duration,
          //remaining,
          dailyBudget,
          originalBudget,
          spend,
          bidStrategy,
          biddingAmount,
          //remainingSpend,
          //adjusted,
        }) => ({
          campaign: { id: id, label: name },
          objective: objective,
          startDate,
          campaignDate:
            validator.toDate(startDate) && validator.toDate(endDate)
              ? [new Date(startDate), new Date(endDate)]
              : [],
          endDate,
          status,
          bidStrategy: bidStrategy,
          biddingAmount: biddingAmount,
          dailyBudget: dailyBudget ? dailyBudget.toString() : '0',
          originalBudget: originalBudget ? originalBudget : 0,
          spend,
        }),
      );
      if (params.isBudget) {
        campaignsManager = campaignsManager[0];
        if (
          campaignsManager.status === 'ACTIVE' ||
          campaignsManager.status === 'PAUSED'
        ) {
          props.changeModifyCampaignOriginalBudget({
            modified: false,
            value: [campaignsManager.originalBudget],
          });
          props.changeModifyCampaignSpent({
            modified: false,
            value: [campaignsManager.spend ? campaignsManager.spend : 0],
          });

          props.changeModifyCampaignDailyBudget({
            modified: false,
            value: [campaignsManager.dailyBudget.toString()],
          });
          props.changeModifyObjective(campaignsManager.objective.toString());
          let campaignObjective = objectives.find(
            e => e.label === campaignsManager.objective.toString(),
          );
          let bidStrategySelector = [];
          if (campaignObjective.value === leadGenerationObjective)
            bidStrategySelector = bidStrategyLeadForms;
          if (campaignObjective.value === linkClicksObjective)
            bidStrategySelector = bidStrategyLinkCLicks;
          if (campaignObjective.value === conversionsObjective)
            bidStrategySelector = bidStrategyConversions;
          if (campaignObjective.value === appInstallObjective)
            bidStrategySelector = bidStrategyAppInstall;
          if (campaignObjective.value === conversationsObjective)
            bidStrategySelector = bidStrategyConversations;
          props.changeModifyCampaignSelectorBidStrategy(bidStrategySelector);
          props.changeModifyCampaignSelectedBidStrategies({
            modified: false,
            value: [
              bidStrategySelector.find(
                e => e.value === campaignsManager.bidStrategy.toString(),
              ),
            ],
          });
          props.changeModifyCampaignBidValue({
            modified: false,
            value: [campaignsManager.biddingAmount.toString()],
          });

          props.changeModifyCampaignStatus({
            modified: false,
            value: campaignsManager.status,
          });
          props.changeModifySelectedDates({
            modified: false,
            value: campaignsManager.campaignDate,
          });
          props.changeModifyCampaignid(campaignsManager.campaign.id);
          props.changeModifyCampaignName({
            modified: false,
            value: [campaignsManager.campaign.label],
          });
          calculateRemainingBudget(
            campaignsManager.dailyBudget.toString(),
            campaignsManager.campaignDate,
            props,
            campaignsManager.originalBudget,
            false,
          );
          props.changeShowModify(true);
        } else {
          alertPopUp('Campaña no encontrada en Facebook', props, true);
        }
      }
    }
  } else {
    params.selectedFacebookCampaigns = parseSelectorResult(
      result,
      updateSelected,
      props.selectedFacebookCampaigns,
      props.changeSelectorFacebookCampaign,
      props.changeSelectedFacebookCampaigns,
      false,
    );
  }
  runChildFunction(
    props.customPageId,
    facebookCampaignSelectorId,
    props,
    params,
    updateSelected,
  );
}

export function getCampaignNamesWithDate(params, props, updateSelected, Skip) {
  if (
    params.selectedProducts &&
    params.selectedProducts.length &&
    params.selectedProducts[0].value === -1
  ) {
    params.selectedProducts = props.selectorProduct.map(val => val);
    params.selectedProducts.pop();
  }

  let component = getComponentMapping(
    props.customPageId,
    adAccountSelectorId,
    false,
  );

  let skip = true;
  if (!Skip) skip = getSkipFetch(params, component.childNeededParams, props);
  if (!skip) {
    var myHeaders = createHeaders();
    if (
      params.selectedProducts &&
      params.selectedProducts.length &&
      params.selectedProducts[0].value === -1
    ) {
      params.selectedProducts = props.selectorProduct.map(val => val);
      params.selectedProducts.pop();
    }
    let url =
      getCampaignByProductAndDate +
      '?fDate=' +
      encodeURIComponent(toISODate(params.selectedDates[0])) +
      '&lDate=' +
      encodeURIComponent(toISODate(params.selectedDates[1])) +
      parameterListToUrl(params.selectedProducts, 'productId', false) +
      parameterListToUrl(getFunctionTenant(params, props), 'accountId', false) +
      parameterListToUrl(params.selectedAdAccounts, 'adAccountId', false);

    customFetch(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      updateCampaignNames,
      params,
      props,
      updateSelected,
      props.layoutLoading !== undefined ? props.layoutLoading : false,
    );
  } else updateCampaignNames({ success: true, payload: [] }, [], props, true);
}

// for stats only?
export function getCampaignNames(params, props, updateSelected, Skip) {
  if (
    params.selectedProducts &&
    params.selectedProducts.length &&
    params.selectedProducts[0].value === -1
  ) {
    params.selectedProducts = props.selectorProduct.map(val => val);
    params.selectedProducts.pop();
  }

  let component = getComponentMapping(
    props.customPageId,
    adAccountSelectorId,
    false,
  );

  let skip = true;
  if (!Skip) skip = getSkipFetch(params, component.childNeededParams, props);
  if (!skip) {
    //http://localhost:3002/stat/api/v2.0/campaignByProduct?accountId=35&productId=7&adAccountId=10&campaignTypeId=1
    var myHeaders = createHeaders();
    if (
      params.selectedProducts &&
      params.selectedProducts.length &&
      params.selectedProducts[0].value === -1
    ) {
      params.selectedProducts = props.selectorProduct.map(val => val);
      params.selectedProducts.pop();
    }
    // TODO: get rid of that ugly function parameterListToUrl
    let url =
      getfacebookCampaignNames +
      parameterListToUrl(params.selectedProducts, 'productId', true) +
      parameterListToUrl(getFunctionTenant(params, props), 'accountId', false) +
      parameterListToUrl(params.selectedAdAccounts, 'adAccountId', false) +
      parameterListToUrl(
        params.selectedCampaignTypes,
        'campaignTypeId',
        false,
      ) +
      '&startDate=' +
      encodeURIComponent(toISODate(params.selectedDates[0])) +
      '&endDate=' +
      encodeURIComponent(toISODate(params.selectedDates[1]));

    customFetch(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      updateCampaignNames,
      params,
      props,
      updateSelected,
      props.layoutLoading !== undefined ? props.layoutLoading : false,
    );
  } else updateCampaignNames({ success: true, payload: [] }, [], props, true);
}

function updateCampaignNames(result, params, props, updateSelected) {
  params.selectedCampaignNames = parseSelectorResult(
    result,
    updateSelected,
    props.selectedCampaignNames,
    props.changeSelectorCampaignName,
    props.changeSelectedCampaignNames,
    true,
  );

  runChildFunction(
    props.customPageId,
    campaignNameSelectorId,
    props,
    params,
    updateSelected,
  );
}

// for stats only?
export function getCampaignTypes(params, props, updateSelected, Skip) {
  if (
    params.selectedProducts &&
    params.selectedProducts.length &&
    params.selectedProducts[0].value === -1
  ) {
    params.selectedProducts = props.selectorProduct.map(val => val);
    params.selectedProducts.pop();
  }

  if (!Skip) {
    var myHeaders = createHeaders();
    let url =
      getCampaignTypesUrl +
      parameterListToUrl(params.selectedProducts, 'productId', true);
    url += `&startDate=${toISODate(
      params.selectedDates[0],
    )}&endDate=${toISODate(params.selectedDates[1])}`;
    customFetch(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      updateCampaignTypes,
      params,
      props,
      updateSelected,
      props.layoutLoading !== undefined ? props.layoutLoading : false,
    );
  } else updateCampaignTypes({ success: true, payload: [] }, [], props, true);
}

function updateCampaignTypes(result, params, props, updateSelected) {
  params.selectedCampaignTypes = parseSelectorResult(
    result,
    updateSelected,
    props.selectedCampaignTypes,
    props.changeSelectorCampaignType,
    props.changeSelectedCampaignTypes,
    false,
  );

  runChildFunction(
    props.customPageId,
    campaignSelectorId,
    props,
    params,
    updateSelected,
  );
  //getCategories(params, props, updateSelected);
}

// for stats
export function getProducts(params, props, updateSelected, Skip) {
  if (!Skip) {
    var myHeaders = createHeaders();
    let url =
      getProductsUrl +
      parameterListToUrl(getFunctionTenant(params, props), 'accountId', true);
    url += parameterListToUrl(params.selectedCompanies, 'companyValue', false);
    url += parameterListToUrl(
      params.selectedBrands ? params.selectedBrands : [],
      'brandValue',
      false,
    );
    url += parameterListToUrl(
      params.selectedCategories ? params.selectedCategories : [],
      'categoryId',
      false,
    );
    url += `&startDate=${toISODate(
      params.selectedDates[0],
    )}&endDate=${toISODate(params.selectedDates[1])}`;
    customFetch(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      updateProducts,
      params,
      props,
      updateSelected,
      props.layoutLoading !== undefined ? props.layoutLoading : false,
    );
  } else updateProducts({ success: true, payload: [] }, [], props, true);
}

function updateProducts(result, params, props, updateSelected) {
  params.selectedProducts = parseSelectorResult(
    result,
    updateSelected,
    props.selectedProducts,
    props.changeSelectorProduct,
    props.changeSelectedProducts,
    !props.singleProduct,
  );
  runChildFunction(
    props.customPageId,
    productSelectorId,
    props,
    params,
    updateSelected,
  );

  //getAges(params, props, updateSelected);
}

// stats api
export function getCompanies(params, props, updateSelected, Skip) {
  if (!Skip && getFunctionTenant(params, props).length > 0) {
    var myHeaders = createHeaders();
    let url = `${getCompaniesUrl}${parameterListToUrl(
      getFunctionTenant(params, props),
      'accountId',
      true,
    )}&startDate=${toISODate(params.selectedDates[0])}&endDate=${toISODate(
      params.selectedDates[1],
    )}`;

    customFetch(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      updateCompanies,
      params,
      props,
      updateSelected,
      props.layoutLoading !== undefined ? props.layoutLoading : false,
    );
  } else updateCompanies({ success: true, payload: [] }, [], props, true);
}

function updateCompanies(result, params, props, updateSelected) {
  params.selectedCompanies = parseSelectorResult(
    result,
    updateSelected,
    props.selectedCompanies,
    props.changeSelectorCompany,
    props.changeSelectedCompanies,
  );
  runChildFunction(
    props.customPageId,
    companySelectorId,
    props,
    params,
    updateSelected,
  );
}

// for stats
export function getBrands(params, props, updateSelected, Skip) {
  if (!Skip) {
    var myHeaders = createHeaders();
    let url =
      getBrandsUrl +
      parameterListToUrl(getFunctionTenant(params, props), 'accountId', true);
    url += parameterListToUrl(params.selectedCompanies, 'companyId', false);
    url += `&startDate=${encodeURIComponent(
      toISODate(params.selectedDates[0]),
    )}&endDate=${encodeURIComponent(toISODate(params.selectedDates[1]))}`;
    customFetch(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      updateBrands,
      params,
      props,
      updateSelected,
      props.layoutLoading !== undefined ? props.layoutLoading : false,
    );
  } else updateBrands({ success: true, payload: [] }, [], props, true);
}

function updateBrands(result, params, props, updateSelected) {
  params.selectedBrands = parseSelectorResult(
    result,
    updateSelected,
    props.selectedBrands,
    props.changeSelectorBrand,
    props.changeSelectedBrands,
  );
  runChildFunction(
    props.customPageId,
    brandSelectorId,
    props,
    params,
    updateSelected,
  );
}

export function getAges(params, props, updateSelected, Skip) {
  //
  if (!Skip) {
    try {
      var myHeaders = createHeaders();
      let url = getAgeUrl;
      url += `?startDate=${toISODate(
        params.selectedDates[0],
      )}&endDate=${toISODate(params.selectedDates[1])}`;
      url +=
        `&campaigns=` +
        params.selectedCampaignNames.map(ele => ele.value).join(',');
      customFetch(
        url,
        {
          method: 'GET',
          headers: myHeaders,
        },
        updateAges,
        params,
        props,
        updateSelected,
        props.layoutLoading !== undefined ? props.layoutLoading : false,
      );
      return;
    } catch (e) {
      console.warn(e);
      console.warn(params.selectedDates);
    }
    updateAges({ success: true, payload: [] }, [], props, true);
    return;
  } else updateAges({ success: true, payload: [] }, [], props, true);
}

function updateAges(result, params, props, updateSelected) {
  params.selectedAges = parseSelectorResult(
    result,
    updateSelected,
    props.selectedAges,
    props.changeSelectorAge,
    props.changeSelectedAges,
    true,
    true,
  );
  runChildFunction(
    props.customPageId,
    ageSelectorId,
    props,
    params,
    updateSelected,
  );
}

export function getGenders(params, props, updateSelected, Skip) {
  if (!Skip) {
    var myHeaders = createHeaders();
    let url = getGenderUrl;

    url += `?startDate=${toISODate(
      params.selectedDates[0],
    )}&endDate=${toISODate(params.selectedDates[1])}`;
    url +=
      `&campaigns=` +
      params.selectedCampaignNames.map(ele => ele.value).join(',');

    customFetch(
      url,
      {
        method: 'GET',
        headers: myHeaders,
      },
      updateGenders,
      params,
      props,
      updateSelected,
      props.layoutLoading !== undefined ? props.layoutLoading : false,
    );
  } else updateGenders({ success: true, payload: [] }, [], props, true);
}

function updateGenders(result, params, props, updateSelected) {
  params.selectedGenders = parseSelectorResult(
    result,
    updateSelected,
    props.selectedGenders,
    props.changeSelectorGender,
    props.changeSelectedGenders,
    true,
    true,
  );
  runChildFunction(
    props.customPageId,
    genderSelectorId,
    props,
    params,
    updateSelected,
  );
}

export function updateProductToAssets(params, props) {
  if (
    params.selectedFacebookCampaigns &&
    params.selectedFacebookCampaigns.length &&
    params.selectedFacebookCampaigns.length > 0
  ) {
    let myHeaders = new Headers();
    myHeaders.append(
      'Authorization',
      `Bearer ${localStorage.getItem('access_token')}`,
    );
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('nonce', getLocalStorageNonce());
    let bodyForm = {};

    bodyForm.campaignId = parametersToComaSeparated(
      params.selectedFacebookCampaigns,
    );

    bodyForm.productId = params.selectedProducts[0].value;
    bodyForm.adAccountId = params.selectedAdAccounts[0].value;
    customFetch(
      setProductsToAdsets,
      {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(bodyForm),
      },
      result => {
        if (result.success) {
          props.changeSelectedFacebookCampaigns([]);
          props.changeSelectedOptimizations([]);
          getfacebookCampaigns(params, props);
          props.changeFilter('');
          alertPopUp('Modificación Realizada', props);
        } else
          alertPopUp(
            'La modificación no se pudo realizar, intente nuevamente',
            props,
            true,
          );
        props.changeUpdateProducts(true);
        props.changeRefresh(true);
      },
      null,
      props,
      false,
      true,
    );
  }
  runChildFunction(props.customPageId, '', props, params);
}

export function updatUserRole(userToAssign, positionId, props, endFunction) {
  alertConfirmPopUp('¿Desea Cambiar Rol al Usuario?', props, () => {
    let myHeaders = createHeaders();

    let bodyForm = {};

    bodyForm.userToAssign = userToAssign;
    bodyForm.positionId = positionId;

    customFetch(
      modifyAccountUsersUrl,
      {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(bodyForm),
      },
      (result, params, props, updateSelected, status) => {
        if (status === successHttpCode) {
          alertPopUp(
            'Modificación Realizada',
            props,
            false,
            false,
            endFunction,
          );
        } else
          alertPopUp(
            'La modificación no se pudo realizar, intente nuevamente',
            props,
            true,
          );
      },
      null,
      props,
      false,
      true,
    );

    runChildFunction(props.customPageId, '', props, []);
  });
}
function deleteAdAccountFunction(adAccountId, props, endFunction, skipAlert) {
  let myHeaders = createHeaders();
  let body = { adAccountId: adAccountId };
  customFetch(
    deleteAdAccountsUrlAdminAdAccountPage,
    {
      method: 'DELETE',
      headers: myHeaders,
      body: JSON.stringify(body),
    },
    (result, params, props, updateSelected, status) => {
      if (status === successHttpCode) {
        if (!skipAlert) {
          alertPopUp(
            trans('AD_ACCOUNTS_PAGE_MESSAGE7', props.lang),
            props,
            false,
            false,
            endFunction,
          );
        }
      } else if (!skipAlert)
        alertPopUp(
          'La modificación no se pudo realizar, intente nuevamente',
          props,
          true,
        );
    },
    null,
    props,
    false,
    true,
  );

  runChildFunction(props.customPageId, '', props, []);
}
export function deleteAdAccount(
  adAccountId,
  props,
  endFunction,
  skipAlert = false,
) {
  if (!skipAlert)
    alertConfirmPopUp(
      trans('AD_ACCOUNTS_PAGE_MESSAGE6', props.lang),
      props,
      () => {
        deleteAdAccountFunction(adAccountId, props, endFunction, skipAlert);
      },
    );
  else deleteAdAccountFunction(adAccountId, props, endFunction, skipAlert);
}
export function deleteUserRole(userId, props, endFunction) {
  alertConfirmPopUp('¿Desea Eliminar Usuario?', props, () => {
    let myHeaders = createHeaders();

    customFetch(
      deleteAccountUsersUrl + '/' + userId,
      {
        method: 'DELETE',
        headers: myHeaders,
      },
      (result, params, props, updateSelected, status) => {
        if (status === successHttpCode) {
          alertPopUp('Usuario Eliminado', props, false, false, endFunction);
        } else
          alertPopUp(
            'La modificación no se pudo realizar, intente nuevamente',
            props,
            true,
          );
      },
      null,
      props,
      false,
      true,
    );

    runChildFunction(props.customPageId, '', props, []);
  });
}

export function addProductToAssets(params, props) {
  if (
    params.selectedFacebookCampaigns &&
    params.selectedFacebookCampaigns.length &&
    params.selectedFacebookCampaigns.length > 0
  ) {
    let myHeaders = new Headers();
    myHeaders.append(
      'Authorization',
      `Bearer ${localStorage.getItem('access_token')}`,
    );
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('nonce', getLocalStorageNonce());
    let bodyForm = {};

    bodyForm.campaignId = parametersToComaSeparated(
      params.selectedFacebookCampaigns,
    );

    bodyForm.productId = params.selectedProducts[0].value;
    bodyForm.adAccountId = params.selectedAdAccounts[0].value;
    customFetch(
      setProductsToAdsets,
      {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(bodyForm),
      },
      result => {
        if (result.success) {
          props.changeSelectedFacebookCampaigns([]);
          props.changeSelectedOptimizations([]);
          getfacebookCampaigns(params, props);
          props.changeFilter('');
          alertPopUp('Asignación Realizada', props);
        } else
          alertPopUp(
            'La asignación no se pudo realizar, intente nuevamente',
            props,
            true,
          );
        props.changeRefresh(true);
      },
      null,
      props,
      false,
      true,
    );
  }
  runChildFunction(props.customPageId, '', props, params);
}

export function validateUrl(url) {
  return validator.isURL(url);
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseInt
function filterInt(value) {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value);
  } else {
    return NaN;
  }
}

// https://tc39.es/ecma262/#sec-date-time-string-format
// convert the date without any
export function ToDate(ecmadatestring) {
  // "2021-06-16T00:00:00.000Z".split('T')
  let ret;
  if (ecmadatestring) {
    let components = ecmadatestring.split('T');
    if (components.length && components.length > 0) {
      let datestr = components[0];
      components = datestr.split('-');
      if (components && components.length && components.length > 2) {
        let yearStr = components[0];
        let monthStr = components[1];
        let dayStr = components[2];
        const year = filterInt(yearStr);
        const month = filterInt(monthStr);
        const day = filterInt(dayStr);
        if (!(Number.isNaN(year) && Number.isNaN(month) && Number.isNaN(day))) {
          let dateObj = new Date(year, month - 1, day);
          return dateObj.toLocaleDateString('es-ES', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
        }
      }
    }
  }

  return ret;
}
