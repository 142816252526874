import React from 'react';
import { withStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';

import ErrorIcon from '@material-ui/icons/Error';
import TextFieldList from '../../../components/TextFieldList/TextFieldListView';
import trans from './../../../i18n/tran';

const Titles = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <TextFieldList
      lang={props.lang}
      recalculateMessages={props.recalculateMessages}
      showAdErrors={props.showAdErrors}
      ErrorIconClass={classes.errorIcon}
      ErrorMessageClass={classes.warningMessage}
      reset={props.reset}
      changeReset={props.changeReset}
      focusable={
        <Focusable
          id={props.componentInfo.focusId}
          refList={props.refList}
        ></Focusable>
      }
      multiline={false}
      removeNewLineChar={true}
      Error={props.componentInfo.getError(props)}
      id="Títulos"
      widgetTitle={trans('NEW_CAMPAIGN_TITLE_TITLE', props.lang)}
      maxTextSize={255}
      maxListSize={3}
      WarningIcon={<WarningIcon />}
      ErrorIcon={<ErrorIcon />}
      getMessage={props.componentInfo.getMessage(props)}
      minTextSize={10}
      placeholder={trans('NEW_CAMPAIGN_TITLE_TITLE_ENTER', props.lang)}
      list={props.adTitles}
      changeList={props.changeAdTitles}
      label={trans('NEW_CAMPAIGN_TITLE_ENTER', props.lang)}
      textFieldVariant="title"
      variant="outlined"
      selectedAdminCampaignTypes={props.selectedAdminCampaignTypes}
      provider={props.provider}
    ></TextFieldList>
  );
};
export default withStyles(styles, { withTheme: true })(Titles);
