import dic from './dic';

let defaultLang = 'es';

export const NO_TRANS_AVAIL = 'NO_TRANS_AVAIL';

function trans(key, lang = defaultLang) {
  if (dic[key] && dic[key][lang]) {
    return dic[key][lang];
  } else if (dic[key] && !lang) {
    return dic[key][defaultLang];
  } else {
    return 'NO_TRANS_AVAIL';
  }
}

export default trans;
