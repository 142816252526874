import { buildStamp } from '../utils/buildStamp';

const ApiUserEndPoint = process.env.REACT_APP_API_USER;
const StatsEndPoint = process.env.REACT_APP_API_STATS;
const ApiAdminEndPoint = process.env.REACT_APP_API_ADMIN;
const AssetsEndPoint = process.env.REACT_APP_API_ASSET;
const RedirectEndPoint = process.env.REACT_APP_API_REDIRECT;
const LogoutEndPoint = process.env.REACT_APP_API_LOGOUT;
const ExpiredEndPoint = process.env.REACT_APP_API_EXPIRED;
const Environment = process.env.REACT_APP_ENVIRONMENT;
const mixPanelProjectToken = process.env.REACT_APP_TOKEN_MIXPANEL;
const ApiPaywallEndPoint = process.env.REACT_APP_API_PAYWALL;
const awsLambda01 = process.env.REACT_APP_API_AWS_LAMBDA_01;
const awsLambda01Key = process.env.REACT_APP_API_AWS_LAMBDA_01_KEY;

const Build = buildStamp;

const ageGenderUrl = `${awsLambda01}`;
const ageGenderKey = `${awsLambda01Key}`

let insertUserUrl = `${ApiUserEndPoint}/v4.0/login`;
let assingAdAccountUrl = `${ApiUserEndPoint}/v4.0/adAccount`;

let getAdAccountsUrl = `${ApiUserEndPoint}/v4.0/adAccounts`;
let getAdAccountsUrlAdminAdAccountPage = `${ApiAdminEndPoint}/v3.0/user/account/adAccounts`;
const getAdAccountsUrlAdminOnboardingPage = `${ApiAdminEndPoint}/v3.0/onboarding/adAccounts`;
const couponValidate = `${ApiPaywallEndPoint}/coupon-validate`;
const payWallCreateAccount = `${ApiPaywallEndPoint}/accountv2`;
const payWallApplyCoupon = `${ApiPaywallEndPoint}/coupon-claim-by-account-id`;
const makeAlyBusinessPartner = `${ApiUserEndPoint}/v4.0/adAccount`;
const associateBusinessWithAdAccount = `${ApiAdminEndPoint}/v3.0/onboarding/account/adAccount`;
const deAssociateBusinessWithAdAccount = `${ApiAdminEndPoint}/v3.0/onboarding/account/adAccount`;
let deleteAdAccountsUrlAdminAdAccountPage = `${ApiAdminEndPoint}/v3.0/adAccount`;
let getOnBoardingUrl = `${ApiUserEndPoint}/v4.0/onboarding`;
let deletePermissionsUrl = `${ApiUserEndPoint}/v4.0/permission`;
let getNewNonceUrl = `${ApiUserEndPoint}/v4.0/accountNonce`;
let saveAsset = `${AssetsEndPoint}/v2.0/asset`;
let assetBase64Url = `${AssetsEndPoint}/v2.0/imageBase64`;
let saveDraft = `${ApiAdminEndPoint}/v3.0/draft`;
let saveProduct = `${ApiAdminEndPoint}/v3.0/product`;
let getDataset4 = `${StatsEndPoint}/v4.0/dataset4`;
let getDataset5 = `${StatsEndPoint}/v4.0/dataset5`;
let getDataset6 = `${StatsEndPoint}/v4.0/dataset6`;
let getDataset7 = `${StatsEndPoint}/v4.0/dataset7`;
let getDataset8 = `${StatsEndPoint}/v4.0/dataset8`;

let getDataset10UrlCharts = `${StatsEndPoint}/v4.0/dataset12/groupByDate`;
let getDataset10UrlChartsTotal = `${StatsEndPoint}/v4.0/dataset12/summed`;
let getCampaignStatsUrl = `${StatsEndPoint}/v4.0/campaign/stats`;
let getDataset10Url = `${StatsEndPoint}/v4.0/dataset12`;
let getDataset11Url = `${StatsEndPoint}/v4.0/dataset14`;
let getCampaignsAlyUrl = `${ApiAdminEndPoint}/v3.0/adAccount/campaigns`;
let updateCampaignsAlyUrl = `${ApiAdminEndPoint}/v3.0/campaign`;
let getcampaignManagerUniqueUrl = `${ApiAdminEndPoint}/v3.0/fb/campaign`;
let getfacebookCampaignNames = `${StatsEndPoint}/v4.0/product/campaigns`;
let getfacebookCampaignsWithoutProductAdminUrl = `${ApiAdminEndPoint}/v3.0/campaign/withoutProduct`;
let getfacebookCampaignsWithFilters = `${ApiAdminEndPoint}/v3.0/campaign/withProduct`;
let setProductsToAdsets = `${ApiAdminEndPoint}/v3.0/campaign/product`;
let getCampaignByProductAndDate = `${StatsEndPoint}/v4.0/product/campaigns/withDate`;
let getpresentationUrl = `${StatsEndPoint}/v4.0/report`;
let getAdAccountsByProduct = `${StatsEndPoint}/v4.0/product/adAccounts`;
let getCampaignTypesUrl = `${StatsEndPoint}/v4.0/product/campaignType`;
let getProductsUrl = `${StatsEndPoint}/v4.0/account/products`;
let getBrandsUrl = `${StatsEndPoint}/v4.0/account/brand`;
let getCompaniesUrl = `${StatsEndPoint}/v4.0/account/company`;
let getAgeUrl = `${StatsEndPoint}/v4.0/ages`;
let getGenderUrl = `${StatsEndPoint}/v4.0/genders`;
let getGeoLocationUrl = `${ApiAdminEndPoint}/v3.0/geoLocation`;
let getAdInterestsUrl = `${ApiAdminEndPoint}/v3.0/interest`;
let getAdTargetingCategoryUrl = `${ApiAdminEndPoint}/v3.0/targetingCategory`;
let getAccountsUrlAdminApiWithoutStatusValidation = `${ApiAdminEndPoint}/v3.0/accounts`;
let getAvailableAccountsUrlAdminApi = `${ApiAdminEndPoint}/v3.0/accountsAvailable`;
let getAdAccountsUrlAdminApi = `${ApiAdminEndPoint}/v3.0/account/adAccount`;
let getAdAccountsByProductUrlAdminApi = `${ApiAdminEndPoint}/v3.0/product/adAccounts`;
let getCompaniesUrlAdminApi = `${ApiAdminEndPoint}/v3.0/account/company`;
let getBrandsUrlAdminApi = `${ApiAdminEndPoint}/v3.0/company/brand`;
let getAdPreviewUrlAdminApi = `${ApiAdminEndPoint}/v1/adPreview`;
let getadAccountByUserAdminApiUrl = `${ApiAdminEndPoint}/v3.0/adAccounts`;
let getCustomAudience = `${ApiAdminEndPoint}/v3.0/customaudience`;
let getSavedAudience = `${ApiAdminEndPoint}/v3.0/savedaudience`;
let getProductsUrlAdminApi = `${ApiAdminEndPoint}/v3.0/account/product`;
let deleteProductsUrlAdminApi = `${ApiAdminEndPoint}/v3.0/product`;
let getAdAccountsUrlStatsApi = `${StatsEndPoint}/v4.0/account/adAccounts`;
let getfacebookCampaignsUrl = `${StatsEndPoint}/v4.0/adAccount/campaigns`;
let getFanPagesUrlAdminApi = `${ApiAdminEndPoint}/v3.0/pages`;
let getLeadFormUrlAdminApi = `${ApiAdminEndPoint}/v3.0/leadForm`;
let getPixelUrlAdminApi = `${ApiAdminEndPoint}/v3.0/pixel`;
let getPixelStatsUrlAdminApi = `${ApiAdminEndPoint}/v3.0/pixelStats`;
let getApplicationsUrlAdminApi = `${ApiAdminEndPoint}/v3.0/applications`;
let getInstagramAccountUrlAdminApi = `${ApiAdminEndPoint}/v3.0/instagramAccount`;
let getCategoriesUrlAdminApi = `${ApiAdminEndPoint}/v3.0/category`;
let createAccountUrl = `${ApiAdminEndPoint}/v3.0/account`;
const verifyAccountUrl = `${ApiAdminEndPoint}/v3.0/account`;
let getAccountToActivateStaff = `${ApiAdminEndPoint}/v3.0/staff/accounts`;
let activateAccountUrl = `${ApiAdminEndPoint}/v3.0/staff/account`;
let linkAccountWithUserUrl = `${ApiAdminEndPoint}/v3.0/user/account`;
let addAdAccountToAccountUrl = `${ApiAdminEndPoint}/v3.0/account/adAccount`;
const getAccountUsersUrl = `${ApiAdminEndPoint}/v3.0/account/users`;
const modifyAccountUsersUrl = `${ApiAdminEndPoint}/v3.0/account/user`;
const deleteAccountUsersUrl = `${ApiAdminEndPoint}/v3.0/account/user`;

const getPayAlyProductsUrl = `${ApiAdminEndPoint}/v2.0/payAly/products`;
const getPayAlyCountriesUrl = `${ApiAdminEndPoint}/v2.0/payAly/countries`;
const getPayAlyCompanyInfoUrl = `${ApiAdminEndPoint}/v2.0/payAly/company`;
const payAlysaveUserUrl = `${ApiAdminEndPoint}/v2.0/payAly/saveUser`;
const generatePayAlyLinkUrl = `${ApiAdminEndPoint}/v2.0/payAly/link`;
const getPayAlyCompanyLinks = `${ApiAdminEndPoint}/v2.0/payAly/company/links`;
const getPayAlyCompanyCurrentSubscription = `${ApiAdminEndPoint}/v2.0/payAly/company/subscription`;
const getPayAlyCompanySubscriptions = `${ApiAdminEndPoint}/v2.0/payAly/company/subscriptions`;
const payAlyCancelSubscriptions = `${ApiAdminEndPoint}/v2.0/payAly/subscriptions/cancel`;

const getDrafts = `${ApiAdminEndPoint}/v2.0/drafts`;
const getReports = `${ApiAdminEndPoint}/v2.0/reports`;

const automaticReports = `${ApiAdminEndPoint}/v3.0/report`;
const sendAutomaticReports = `${ApiAdminEndPoint}/v3.0/report-now`;
const automaticReportsClients = `${ApiAdminEndPoint}/v3.0/report-clients`;
const automaticReportsCSA = `${ApiAdminEndPoint}/v3.0/report-csa`;
const sellsCapture = `${ApiAdminEndPoint}/v3.0/sells`;
const getSales = `${ApiAdminEndPoint}/v3.0/get-sells`;
const getTickets = `${ApiAdminEndPoint}/v3.0/get-tickets`;

let sideBarDatePicker = 'sideBarDatePicker';
let optimizationSelectorId = 'OptimizationType';
let accountSelectorId = 'Account';
let adAccountSelectorId = 'AdAccount';
let companySelectorId = 'Company';
let brandSelectorId = 'Brand';
let campaignSelectorId = 'CampaignType';
let categorySelectorId = 'Category';
let productSelectorId = 'Product';
let ageSelectorId = 'Age';
let genderSelectorId = 'Gender';
let facebookCampaignSelectorId = 'facebookCampaign';
let dataSet9Id = 'dataSet9';
let todosSelectorValue = { value: -1, label: 'All/Todos' };
let todasSelectorValue = { value: -1, label: 'All/Todas' };

let facebookProvider = 1;
let instagramProvider = 2;
let multipleProvider = 3;
let leadGenerationObjective = 1;
let linkClicksObjective = 2;
let conversationsObjective = 3;
let conversionsObjective = 4;
let appInstallObjective = 5;
let titlesComponentId = 'titlesComponentId';
let descriptionComponentId = 'descriptionComponentId';
let ctaComponentId = 'ctaComponentId';
let bodiesComponentId = 'bodiesComponentId';
let attachmentsComponentId = 'attachmentsComponentId';
let genderAgeComponentId = 'genderAgeComponentId';
let LookAlikeComponentId = 'LookAlikeComponentId';
let adPreviewComponentId = 'adPreviewComponentId';
let budgetComponentId = 'budgetComponentId';
let leadGenerationFormComponentId = 'leadGenerationFormComponentId';
let pixelComponentId = 'pixelComponentId';
let applicationComponentId = 'applicationComponentId';
let conversationComponentId = 'conversationComponentId';
let instagramAccountComponentId = 'instagramAccountComponentId';
let linkComponentId = 'linkComponentId';
let locationComponentId = 'locationComponentId';
let UTMComponentId = 'UTMComponentId';
let productComponentId = 'productComponentId';
let fanPageComponentId = 'fanPageComponentId';
let dateComponentId = 'dateComponentId';
let campaignStatusComponentId = 'campaignStatusComponentId';
let fanPageFocusId = 'fanPageFocusId';
let pixelFocusId = 'pixelFocusId';
let applicationFocusId = 'applicationFocusId';
let conversationFocusId = 'conversationFocusId';
let campaignNameComponentId = 'campaignNameComponentId';
let campaignNameFocusId = 'campaignNameFocusId';
let biddingAmountFocusId = 'biddingAmountFocusId';
let fechaCampanaFocusId = 'fechaCampanaFocusId';
let campaignStatusFocusId = 'campaignStatusFocusId';
let productoFocusId = 'productoFocusId';
let presupuestoFocusId = 'presupuestoFocusId';
let linkFocusId = 'linkFocusId';
let LookAlikeFocusId = 'LookAlikeFocusId';
let interestsComponentId = 'interestsComponentId';
let interestsFocusId = 'interestsFocusId';
let tituloAnuncioFocusId = 'tituloAnuncioFocusId';
let cuerpoAnuncioFocusId = 'cuerpoAnuncioFocusId';
let ubicacionFocusId = 'ubicacionFocusId';
let uploadFocusId = 'uploadFocusId';
let genderFocusId = 'genderFocusId';
let ctaFocusId = 'ctaFocusId';
let biddingAmountComponentId = 'biddingAmountComponentId';
let campaignNameSelectorId = 'campaignNameSelectorId';
let googlePlayApplicationId = 'google_play';
let amazonAppStoreApplicationId = 'amazon_app_store';
let fbCanvasApplicationId = 'fb_canvas';
let instantGameApplicationId = 'instant_game';
let itunesApplicationId = 'itunes';
let itunesIpadApplicationId = 'itunes_ipad';
let windowsStoreApplicationId = 'windows_10_store';
let facebookMessengerLinkConversation = 'https://fb.com/messenger_doc/';
let instagramDirectLinkConversation = 'http://www.instagram.com/';
let whatsAppLinkConversation = 'https://api.whatsapp.com/send';
let facebookMessengerDestinationTypeId = 'MESSENGER';
let whatsAppDestinationTypeId = 'WHATSAPP';
let instagramDirectDestinationTypeId = 'INSTAGRAM_DIRECT';
let lowestCostWithouCapBidStrategy = 'LOWEST_COST_WITHOUT_CAP';
let uploadImageExtensions = ['.jpg', '.jpeg', '.bmp', '.gif', '.png', '.jfif'];
let uploadVideoExtensions = [
  '.avi',
  '.mp4',
  '.mpeg-4',
  '.mkv',
  '.flv',
  '.mov',
  '.wmv',
  'mp4',
];

const loginPageId = 'loginPage';
const homePagePageId = 'homePage';
const release1AdsPageId = 'release1Ads';
const adaccountsPageId = 'adaccounts';
const exportPagePageId = 'exportPage';
const clientApprovalPageId = 'clientApproval';
const downloadstatsPageId = 'downloadstats';
const createadPageId = 'createad';
const draftsPageId = 'drafts';
const reportsPageId = 'reports';
const productPageId = 'product';
const modifyProductPageId = 'modifyProductPage';
const assignPageId = 'assign';
const initPageId = 'init';
const newAccountPageId = 'newAccount';
const approvalPageId = 'approval';
const userPageId = 'userPageId';
const rolesPageId = 'rolesPageId';
const billingPageId = 'billingPageId';
const subscriptionsPageId = 'subscriptionsPageId';
const couponsPageId = 'couponsPageId';

const salesReportPageId = 'sells-client'
const automaticReportPageId = 'reports-admin'

const hideTenantSelectorPageIds = [newAccountPageId];
const defaultPermissions = {
  userPages: [newAccountPageId],
  defaultPage: newAccountPageId,
};
const sessionValidationInterval = 5000;
const maxUploadImageFiles = 3;
const maxUploadVideoFiles = 1;
const maxUploadImageFilesMessage = 'Como máximo puede agregar 3 archivos';
const maxUploadVideoFilesMessage = 'Como máximo puede agregar 1 archivo';
const ctaAdPreview = 'SHOP_NOW';

const DraftStatus = {
  Created: 10,
  Success: 20,
  Error: 30,
};

const release1AdsPagePath = '/app/dashboard/ads';
const adaccountsPagePath = '/app/adaccounts';
const createadPagePath = '/app/createad';
const productPagePath = '/app/product';
const assignPagePath = '/app/campaigns';
const modifyProductPagePath = '/app/modify/listproduct';
const exportPagePagePath = '/app/export';
const approvalPagePagePath = '/app/approval';
const clientApprovalPagePath = '/app/clientapproval';
const homePagePagePath = '/app/home';
const downloadstatsPagePath = '/app/downloadstats';
const newAccountPagePath = '/app/newAccount';
const initPagePath = '/app/init';
const userPagePath = '/app/users';
const billingPagePath = '/app/billing';
const subscriptionsPagePath = '/app/subscriptions';
const draftsPagePath = '/app/drafts';
const reportsPagePath = '/app/reports';
const couponsPagePath = '/app/coupons';

const salesReportPath = '/app/reportsadmin';
const automaticReportPath = '/app/auto-reportsadmin';

let objectives = [
  {
    label: 'Generación de clientes potenciales',
    value: leadGenerationObjective,
    facebook_name: 'OUTCOME_LEADS',
  },
  {
    label: 'Tráfico',
    value: linkClicksObjective,
    facebook_name: 'OUTCOME_TRAFFIC',
  },
  {
    label: 'Conversiones',
    value: conversionsObjective,
    facebook_name: 'OUTCOME_SALES',
  },
  {
    label: 'Descargas de aplicaciones',
    value: appInstallObjective,
    facebook_name: 'OUTCOME_APP_PROMOTION',
  },
  {
    label: 'Mensajes',
    value: conversationsObjective,
    facebook_name: 'OUTCOME_ENGAGEMENT',
  },
];
let placements = [
  {
    value: {
      provider: 'facebook',
      placement: 'facebook',
      placementId: 1,
      position: { facebook_positions: 'feed' },
    },
    label: 'Facebook-Feed',
  },
  {
    value: {
      provider: 'facebook',
      placement: 'facebook',
      placementId: 1,
      position: { facebook_positions: 'story' },
    },
    label: 'Facebook-Stories',
  },

  {
    value: {
      provider: 'facebook',
      placement: 'instagram',
      placementId: 2,
      position: { instagram_positions: 'stream' },
    },
    label: 'Instagram-Stream',
  },
  {
    value: {
      provider: 'facebook',
      placement: 'instagram',
      placementId: 2,
      position: { instagram_positions: 'story' },
    },
    label: 'Instagram-Stories',
  } /*
	{
		value: {
			provider: "facebook",
			placement: "instagram",
			placementId: 2,
			position: { instagram_positions: "feed" }
		},
		label: "Instagram-Feed"
	}*/,
];

let linkDynamicParameters = [
  { label: 'ad_id', value: 'ad_id={{ad.id}}' },
  { label: 'adset_id', value: 'adset_id={{adset.id}}' },
  { label: 'campaign_id', value: 'campaign_id={{campaign.id}}' },
  { label: 'ad_name', value: 'ad_name={{ad.name}}' },
  { label: 'adset_name', value: 'adset_name={{adset.name}}' },
  { label: 'campaign_name', value: 'campaign_name={{campaign.name}}' },
  { label: 'placement', value: 'placement={{placement}}' },
  { label: 'site_source_name', value: 'site_source_name={{site_source_name}}' },
];

let facebookPlacementParameters = {
  1: [
    {
      label: 'Sección de Noticias de Facebook',
      value: 'feed',
      key: 'PLACEMENT1',
    },
    { label: 'En artículos', value: 'instant_article', key: 'PLACEMENT2' },
    { label: 'Facebook Marketplace', value: 'marketplace', key: 'PLACEMENT3' },
    {
      label: 'Secciones de videos de Facebook',
      value: 'video_feeds',
      key: 'PLACEMENT4',
    },
    { label: 'Historias de Facebook	', value: 'story', key: 'PLACEMENT5' },
    {
      label: 'Resultados de la búsqueda de Facebook',
      value: 'search',
      key: 'PLACEMENT6',
    },
  ],
  2: [
    {
      label: 'Sección de Noticias de Facebook',
      value: 'feed',
      key: 'PLACEMENT1',
    },
    {
      label: 'Columna derecha de Facebook',
      value: 'right_hand_column',
      key: 'PLACEMENT7',
    },
    { label: 'En artículos', value: 'instant_article', key: 'PLACEMENT2' },
    { label: 'Facebook Marketplace', value: 'marketplace', key: 'PLACEMENT3' },
    {
      label: 'Secciones de videos de Facebook',
      value: 'video_feeds',
      key: 'PLACEMENT4',
    },
    { label: 'Historias de Facebook', value: 'story', key: 'PLACEMENT5' },
    {
      label: 'Resultados de la búsqueda de Facebook',
      value: 'search',
      key: 'PLACEMENT6',
    },
  ],
  3: [
    {
      label: 'Sección de Noticias de Facebook',
      value: 'feed',
      key: 'PLACEMENT1',
    },
    {
      label: 'Columna derecha de Facebook',
      value: 'right_hand_column',
      key: 'PLACEMENT7',
    },
    { label: 'En artículos', value: 'instant_article', key: 'PLACEMENT2' },
    { label: 'Facebook Marketplace', value: 'marketplace', key: 'PLACEMENT3' },
    {
      label: 'Secciones de videos de Facebook',
      value: 'video_feeds',
      key: 'PLACEMENT4',
    },
    { label: 'Historias de Facebook', value: 'story', key: 'PLACEMENT5' },
    {
      label: 'Resultados de la búsqueda de Facebook',
      value: 'search',
      key: 'PLACEMENT6',
    },
  ],
  4: [
    {
      label: 'Sección de Noticias de Facebook',
      value: 'feed',
      key: 'PLACEMENT1',
    },
    { label: 'En artículos', value: 'instant_article', key: 'PLACEMENT2' },
    {
      label: 'Secciones de videos de Facebook',
      value: 'video_feeds',
      key: 'PLACEMENT4',
    },
    { label: 'Historias de Facebook', value: 'story', key: 'PLACEMENT5' },
    {
      label: 'Resultados de la búsqueda de Facebook',
      value: 'search',
      key: 'PLACEMENT6',
    },
  ],
  5: [
    {
      label: 'Sección de Noticias de Facebook',
      value: 'feed',
      key: 'PLACEMENT1',
    },
    { label: 'En artículos', value: 'instant_article', key: 'PLACEMENT2' },
    { label: 'Facebook Marketplace', value: 'marketplace', key: 'PLACEMENT3' },
    {
      label: 'Secciones de videos de Facebook',
      value: 'video_feeds',
      key: 'PLACEMENT4',
    },
    { label: 'Historias de Facebook', value: 'story', key: 'PLACEMENT5' },
    {
      label: 'Resultados de la búsqueda de Facebook',
      value: 'search',
      key: 'PLACEMENT6',
    },
  ],
};

let instagramPlacementParameters = {
  1: [
    { label: 'Noticias de Instagram', value: 'stream', key: 'PLACEMENT8' },
    { label: 'Historias de Instagram', value: 'story', key: 'PLACEMENT9' },
    {
      label: 'Sección “Explorar” de Instagram',
      value: 'explore',
      key: 'PLACEMENT10',
    },
  ],
  2: [
    { label: 'Noticias de Instagram', value: 'stream', key: 'PLACEMENT8' },
    { label: 'Historias de Instagram', value: 'story', key: 'PLACEMENT9' },
    {
      label: 'Sección “Explorar” de Instagram',
      value: 'explore',
      key: 'PLACEMENT10',
    },
  ],
  3: [
    { label: 'Noticias de Instagram', value: 'stream', key: 'PLACEMENT8' },
    { label: 'Historias de Instagram', value: 'story', key: 'PLACEMENT9' },
    {
      label: 'Sección “Explorar” de Instagram',
      value: 'explore',
      key: 'PLACEMENT10',
    },
  ],
  4: [
    { label: 'Noticias de Instagram', value: 'stream', key: 'PLACEMENT8' },
    { label: 'Historias de Instagram', value: 'story', key: 'PLACEMENT9' },
    {
      label: 'Sección “Explorar” de Instagram',
      value: 'explore',
      key: 'PLACEMENT10',
    },
  ],
  5: [
    { label: 'Noticias de Instagram', value: 'stream', key: 'PLACEMENT8' },
    { label: 'Historias de Instagram ', value: 'story', key: 'PLACEMENT9' },
    {
      label: 'Sección “Explorar” de Instagram',
      value: 'explore',
      key: 'PLACEMENT10',
    },
  ],
};

let callToActionLinkClicks = [
  { value: 'NO_BUTTON', label: 'Sin Botón', key: 'NO_BUTTON' },
  { value: 'BUY_NOW', label: 'Comprar', key: 'BUY' },
  { value: 'SIGN_UP', label: 'Registrarte', key: 'SIGN_UP' },
  { value: 'SUBSCRIBE', label: 'Suscribirte', key: 'SUBSCRIBE' },
  { value: 'WATCH_MORE', label: 'Ver Más', key: 'SEE_MORE' },
  { value: 'LISTEN_MUSIC', label: 'Escuchar', key: 'LISTEN' },
  //enviar solicitud
  { value: 'APPLY_NOW', label: 'Postularte', key: 'APPLY' },
  //Reservar
  //{ value: "BOOK_NOW", label: "Reservar*" },
  { value: 'CONTACT_US', label: 'Contactarnos', key: 'CONTACT_US' },
  { value: 'DOWNLOAD', label: 'Descargar', key: 'DOWNLOAD' },
  { value: 'GET_OFFER', label: 'Obtener Oferta', key: 'GET_OFFER' },
  { value: 'GET_QUOTE', label: 'Cotizar', key: 'QUOTE' },
  { value: 'GET_SHOWTIMES', label: 'Ver Funciones', key: 'SEE_FUNCTIONS' },
  { value: 'LEARN_MORE', label: 'Más Información', key: 'LEARN_MORE' },
  { value: 'REQUEST_TIME', label: 'Solicitar Cita', key: 'ASK_DATE' },
  { value: 'PLAY_GAME', label: 'Jugar', key: 'PLAY' },
  { value: 'SEE_MENU', label: 'Ver Menú', key: 'SEE_MENU' },
];
let conversationsWhatsAppDestinationType = [
  {
    value: facebookMessengerDestinationTypeId,
    label: 'Messenger',
    link: facebookMessengerLinkConversation,
  },
  {
    value: whatsAppDestinationTypeId,
    label: 'WhatsApp Business',
    link: whatsAppLinkConversation,
  },
];

let conversationsIGStoriesDestinationType = [
  {
    value: facebookMessengerDestinationTypeId,
    label: 'Messenger',
    link: facebookMessengerLinkConversation,
  },
];
let callToActionConversions = [
  { value: 'NO_BUTTON', label: 'Sin Botón', key: 'NO_BUTTON' },
  { value: 'BUY_NOW', label: 'Comprar', key: 'BUY' },
  { value: 'SIGN_UP', label: 'Registrarte', key: 'SIGN_UP' },
  { value: 'SUBSCRIBE', label: 'Suscribirte', key: 'SUBSCRIBE' },
  { value: 'WATCH_MORE', label: 'Ver Más', key: 'SEE_MORE' },
  { value: 'LISTEN_MUSIC', label: 'Escuchar', key: 'LISTEN' },
  { value: 'APPLY_NOW', label: 'Postularte', key: 'APPLY' },
  { value: 'CONTACT_US', label: 'Contactarnos', key: 'CONTACT_US' },
  { value: 'DOWNLOAD', label: 'Descargar', key: 'DOWNLOAD' },
  { value: 'GET_OFFER', label: 'Obtener Oferta', key: 'GET_OFFER' },
  { value: 'GET_QUOTE', label: 'Cotizar', key: 'QUOTE' },
  { value: 'GET_SHOWTIMES', label: 'Ver Funciones', key: 'SEE_FUNCTIONS' },
  { value: 'LEARN_MORE', label: 'Más Información', key: 'MORE_INFO' },
  { value: 'REQUEST_TIME', label: 'Solicitar Cita', key: 'ASK_DATE' },
  { value: 'PLAY_GAME', label: 'Jugar', key: 'PLAY' },
];
let callToActionLeadForms = [
  //{ value: "NO_BUTTON", label: "Sin Botón" },
  { value: 'APPLY_NOW', label: 'Postularte', key: 'APPLY' },
  { value: 'DOWNLOAD', label: 'Descargar', key: 'DOWNLOAD' },
  { value: 'GET_OFFER', label: 'Obtener Oferta', key: 'GET_OFFER' },
  { value: 'GET_QUOTE', label: 'Cotizar', key: 'QUOTE' },
  { value: 'LEARN_MORE', label: 'Más Información', key: 'MORE_INFO' },
  { value: 'SIGN_UP', label: 'Registrarte', key: 'SIGN_UP' },
  { value: 'SUBSCRIBE', label: 'Suscribirte', key: 'SUBSCRIBE' },
  //{ value: "BOOK_NOW", label: "Reservar*" }
];
let callToActionAppInstall = [
  { value: 'INSTALL_APP', label: 'Instalar', key: 'INSTALL' },
];
let callToActionConversationsMessenger = [
  { value: 'MESSAGE_PAGE', label: 'Enviar Mensaje', key: 'SEND_MESSAGE' },
];
let callToActionConversationsDirect = [
  { value: 'INSTAGRAM_MESSAGE', label: 'Enviar Mensaje', key: 'SEND_MESSAGE' },
];
let callToActionConversationsWhatsapp = [
  { value: 'WHATSAPP_MESSAGE', label: 'Enviar Mensaje', key: 'SEND_MESSAGE' },
];
let bidStrategyLinkCLicks = [
  { value: 'COST_CAP', label: 'Limite de Coste' },
  { value: 'LOWEST_COST_WITH_BID_CAP', label: 'Limite de Puja' },
  { value: 'LOWEST_COST_WITHOUT_CAP', label: 'Sin Estrategía de Puja' },
];
let bidStrategyAppInstall = [
  { value: 'COST_CAP', label: 'Limite de Coste' },
  { value: 'LOWEST_COST_WITH_BID_CAP', label: 'Limite de Puja' },
  { value: 'LOWEST_COST_WITHOUT_CAP', label: 'Sin Estrategía de Puja' },
];
let bidStrategyLeadForms = [
  { value: 'COST_CAP', label: 'Limite de Coste' },
  { value: 'LOWEST_COST_WITH_BID_CAP', label: 'Limite de Puja' },
  { value: 'TARGET_COST', label: 'Coste Objetivo' },
  { value: 'LOWEST_COST_WITHOUT_CAP', label: 'Sin Estrategía de Puja' },
];
let bidStrategyConversions = [
  { value: 'COST_CAP', label: 'Limite de Coste' },
  { value: 'LOWEST_COST_WITH_BID_CAP', label: 'Limite de Puja' },
  { value: 'TARGET_COST', label: 'Coste Objetivo' },
  { value: 'LOWEST_COST_WITHOUT_CAP', label: 'Sin Estrategía de Puja' },
];
let bidStrategyConversations = [
  { value: 'LOWEST_COST_WITHOUT_CAP', label: 'Sin Estrategía de Puja' },
];

let userRoles = [
  { label: 'Admin', value: 2 },
  { label: 'Inactivo', value: 3 },
  { label: 'View', value: 5 },
  { label: 'Creator', value: 6 },
  { label: 'Billing', value: 4 },
];
let userRolesWithStaff = [
  { label: 'Staff', value: 1 },
  { label: 'Admin', value: 2 },
  { label: 'Inactivo', value: 3 },
  { label: 'View', value: 5 },
  { label: 'Creator', value: 6 },
  { label: 'Billing', value: 4 },
];
const staffRoleId = 1;
let conversationsDestinationType = [
  {
    value: facebookMessengerDestinationTypeId,
    label: 'Messenger',
    link: facebookMessengerLinkConversation,
  },
  {
    value: whatsAppDestinationTypeId,
    label: 'WhatsApp Business',
    link: whatsAppLinkConversation,
  },

  {
    value: instagramDirectDestinationTypeId,
    label: 'Instagram Direct',
    link: instagramDirectLinkConversation,
  },
];
let standardPixelEvents = [
  { value: 'LEAD', label: 'Cliente Potencial', event: 'Lead' },
  { value: 'SEARCH', label: 'Buscar', event: 'Search' },
  {
    value: 'SUBMIT_APPLICATION',
    label: 'Enviar Solicitud',
    event: 'SubmitApplication',
  },
  { value: 'CONTENT_VIEW', label: 'Ver Contenido', event: 'ViewContent' },
  {
    value: 'INITIATED_CHECKOUT',
    label: 'Iniciar Pago',
    event: 'InitiateCheckout',
  },
  {
    value: 'CUSTOMIZE_PRODUCT',
    label: 'Personalizar Producto',
    event: 'CustomizeProduct',
  },
  { value: 'START_TRIAL', label: 'Iniciar Prueba', event: 'StartTrial' },
  { value: 'PURCHASE', label: 'Comprar', event: 'Purchase' },
  { value: 'FIND_LOCATION', label: 'Buscar Ubicación', event: 'FindLocation' },
  {
    value: 'ADD_PAYMENT_INFO',
    label: 'Añadir Información de Pago',
    event: 'AddPaymentInfo',
  },
  {
    value: 'COMPLETE_REGISTRATION',
    label: 'Completar Registro',
    event: 'CompleteRegistration',
  },
  { value: 'CONTACT', label: 'Contactar', event: 'Contact' },
  { value: 'ADD_TO_CART', label: 'Añadir al Carrito', event: 'AddToCart' },
  { value: 'DONATE', label: 'Hacer Donación', event: 'Donate' },
  { value: 'SUBSCRIBE', label: 'Suscribirte', event: 'Subscribe' },
  {
    value: 'ADD_TO_WISHLIST',
    label: 'Añadir a lista de Deseos',
    event: 'AddToWishlist',
  },
  { value: 'SCHEDULE', label: 'Programación', event: 'Schedule' },
];
let appInstall = [
  { value: 'amazon_app_store', label: 'Amazon Appstore' },
  { value: 'fb_canvas', label: 'Página principal de Facebook' },
  //{ value: "fb_gameroom", label:  "" },
  { value: 'google_play', label: 'Google Play' },
  { value: 'instant_game', label: 'Juegos Instantáneos' },
  { value: 'itunes', label: 'iTunes' },
  { value: 'itunes_ipad', label: 'iTunes para iPad' },
  { value: 'windows_10_store', label: 'Tienda de Windows' },
];
let relationshipStatuses = [
  { id: 1, name: 'Soltero (a)', key: 'REL_1' },
  { id: 2, name: 'Tiene una relación', key: 'REL_2' },
  { id: 3, name: 'Casado (a)', key: 'REL_3' },
  { id: 4, name: 'Comprometido (a)', key: 'REL_4' },
  { id: 6, name: 'No especificado', key: 'REL_5' },
  { id: 7, name: 'Mantiene una unión civil', key: 'REL_6' },
  { id: 8, name: 'Tiene una pareja de hecho', key: 'REL_7' },
  { id: 9, name: 'Tiene una relación abierta', key: 'REL_8' },
  { id: 10, name: 'Es complicado', key: 'REL_9' },
  { id: 11, name: 'Separado (a)', key: 'REL_10' },
  { id: 12, name: 'Divorciado (a)', key: 'REL_11' },
  { id: 13, name: 'Viudo (a)', key: 'REL_12' },
];
let educationStatuses = [
  { id: 1, name: 'Secundario en curso', key: 'EDU_1' },
  { id: 2, name: 'Estudios universitarios en curso', key: 'EDU_2' },
  { id: 3, name: 'Estudios universitarios completos', key: 'EDU_3' },
  { id: 4, name: 'Secundario completo', key: 'EDU_4' },
  { id: 5, name: 'Estudios universitarios incompletos', key: 'EDU_5' },
  { id: 6, name: 'Título intermedio', key: 'EDU_6' },
  { id: 7, name: 'Posgrado en curso', key: 'EDU_7' },
  { id: 8, name: 'Posgrado incompleto', key: 'EDU_8' },
  { id: 9, name: 'Maestría', key: 'EDU_9' },
  { id: 10, name: 'Título profesional', key: 'EDU_10' },
  { id: 11, name: 'Doctorado', key: 'EDU_11' },
  { id: 12, name: 'Sin especificar', key: 'EDU_12' },
  { id: 13, name: 'Secundario incompleto', key: 'EDU_3' },
];
let glosarioPowerPoint = [
  [
    {
      title: 'CTA (Call to action)',
      title_key: 'PPTX_CTA_TITLE',
      definition:
        'llama a la acción, es una herramienta o estrategia de publicidad, gráfica o textual, que busca incitar a la audiencia a responder a una acción después de ver un anuncio. Campañas CTA son campañas que llaman a una acción al usuario que vio el anuncio.',
      def_key: 'PPTX_CTA_DESC',
    },
    {
      title: 'KPIS',
      definition:
        'se trata de indicadores clave de rendimiento, utilizados para medir el nivel del desempeño de un proceso. Estos indicadores están relacionados a un objetivo.',
      def_key: 'PPTX_KPIs_DESC',
    },
    {
      title: 'Impresiones',
      title_key: 'IMPRESSIONS',
      definition:
        'es la frecuencia con la que se muestra su anuncio. Una impresión se cuenta cada vez que su anuncio aparece en Facebook o Instagram.',
      def_key: 'PPTX_IMPRESSIONS_DESC',
    },
    {
      title: 'Click /Clic',
      definition: 'cuando un usuario pulsa/hace clic en un anuncio.',
      def_key: 'PPTX_CLICK_DESC',
    },

    {
      title: 'Lead',
      definition:
        '(prospecto o posible cliente ) contacto de ventas potencial, individuo u organización que expresa un interés en determinados bienes o servicios.',
      def_key: 'PPTX_LEAD_DESC',
    },
    {
      title: 'Costo o Inversión de Medios',
      title_key: 'PPTX_COST_TITLE',
      definition:
        'total de inversión en medios en una campaña publicitaria, también conocida como pauta digital o Ad Spend en inglés. ',
      def_key: 'PPTX_COST_DESC',
    },
    {
      title: 'CPC (costo por clic)',
      title_key: 'PPTX_CPC_TITLE',
      definition:
        'costo por cada clic realizado por los usuarios en una campaña (costo/clicks)',
      def_key: 'PPTX_CPC_DESC',
    },
  ],
  [
    {
      title: 'CTR (click through rate)',
      title_key: 'PPTX_CTR_TITLE',
      definition:
        'métrica que muestra la frecuencia en que las personas ven un anuncio y terminan haciendo click o pulsando en él. (click / impresiones)',
      def_key: 'PPTX_CTR_DESC',
    },
    {
      title: 'CPL (costo por lead)',
      title_key: 'PPTX_CPL_TITLE',
      definition: 'costo por cada lead generado en una campaña. (costo/leads)',
      def_key: 'PPTX_CPL_DESC',
    },
    {
      title: 'CPA (costo por acción)',
      title_key: 'PPTX_CPA_TITLE',
      definition: 'costo por acción ejecutada. (costo/acciones).',
      def_key: 'PPTX_CPA_DESC',
    },
    {
      title: 'CPM (Costo por mil impresiones)',
      title_key: 'PPTX_CPM_TITLE',
      definition:
        'Costo por cada mil impresiones generadas por un anuncio. (Formula: 1000* Inversión de Medios/Impresiones).',
      def_key: 'PPTX_CPM_DESC',
    },
    {
      title: 'ROAS (Retorno en la inversión publicitaria)',
      title_key: 'PPTX_ROAS_TITLE',
      definition:
        'Cantidad de dólares que retornan como venta por cada dólar invertido en una campaña digital. (Ingresos de la campaña/Costo).',
      def_key: 'PPTX_ROAS_DESC',
    },
    {
      title: 'Reach o Alcance',
      title_key: 'PPTX_REACH_TITLE',
      definition: 'número de personas que han visto un contenido.',
      def_key: 'PPTX_REACH_DESC',
    },
    {
      title: 'Link clicks o clics en el enlace',
      title_key: 'PPTX_LINK_CLICKS_TITLE',
      definition:
        'total de clicks/pulsaciones que se dieron en un anuncio. Clicks/pulsaciones que generan acciones concretas.',
      def_key: 'PPTX_LINK_CLICKS_DESC',
    },
    {
      title: 'Conversaciones nuevas',
      title_key: 'PPTX_NEW_CONVERSATIONS_TITLE',
      definition:
        'número de personas que iniciaron una conversación, por medio de Whatsapp o Facebook Messenger.',
      def_key: 'PPTX_NEW_CONVERSATIONS_DESC',
    },
  ],
  [
    {
      title: 'Generación de leads',
      title_key: 'PPTX_LEAD_GENERATION_TITLE',
      definition:
        'objetivo de campaña que permite recopilar información de clientes potenciales (prospectos/ leads), como correo electrónico, número de celular, país, información adicional de personas interesadas en el producto o servicio promocionado.',
      def_key: 'PPTX_LEAD_GENERATION_DESC',
    },
    {
      title: 'Conversiones',
      title_key: 'PPTX_CONVERTIONS_TITLE',
      definition:
        'objetivo de campaña que permite saber cuántas personas realizaron una compra, afiliación, descargas, formularios, etc, desde una campaña en redes sociales. Para medir los resultados será necesario la instalación del Píxel de Facebook (código de seguimiento).',
      def_key: 'PPTX_CONVERTIONS_DESC',
    },
    {
      title: 'Acciones',
      title_key: 'PPTX_ACTIONS_TITLE',
      definition:
        'hecho específico realizado por un usuario y generado dentro de los objetivos de una campaña. Estos pueden ser pueden ser:',
      def_key: 'PPTX_ACTIONS_DESC',
    },
    {
      title: '',
      definition: 'Prospectos o posibles clientes (leads)',
      def_key: 'PPTX_LEADS_DESC',
      bullet: true,
      indentLevel: 2,
    },
    {
      title: '',
      definition: 'Nuevas conversaciones por Facebook messenger y WhatsApp',
      def_key: 'PPTX_CONVERSATIONS_2_DESC',
      bullet: true,
      indentLevel: 2,
    },
    {
      title: '',
      definition: 'Compras en línea',
      def_key: 'PPTX_SHOP_DESC',
      bullet: true,
      indentLevel: 2,
    },
    {
      title: '',
      definition: 'Descarga de aplicación',
      def_key: 'PPTX_APP_DOWNLOADS_DESC',
      bullet: true,
      indentLevel: 2,
    },
    {
      title: '',
      definition: 'Clicks a Link',
      def_key: 'PPTX_LINK_CLICKS_2_DESC',
      bullet: true,
      indentLevel: 2,
    },
  ],
];
/*
Http Errors
*/
const internalServerErrorHttpError = 500;
const serviceUnavailableHttpError = 503;
const conflictHttpError = 409;
const methodNotAllowedHttpError = 405;
const forbiddenHttpError = 403;
const notFoundHttpError = 404;
const unauthorizedHttpError = 401;
const badParametersHttpError = 400;
const successHttpCode = 200;
const noContentHttpCode = 204;
const fileNotSupportedHttpCode = 415;
const facebookGasHttpErrorCode = 509;
const onBoardingNoClient = 1;
const onBoardingNoAdAccounts = 2;
const onBoardingNoBBAdAccounts = 3;
const onBoardingNoProducts = 4;
const onBoardingBilling = 5;
const onBoardingMantainance = 7;
const onBoardingOK = 6;
const onBoardingClientApproval = -5;
const onBoardingInactive = 8;

const initUrl = LogoutEndPoint + 'app/init';
const newAccountUrl = LogoutEndPoint + 'app/newAccount';
const clientApprovalUrl = LogoutEndPoint + 'app/clientapproval';
const adAccountsUrl = LogoutEndPoint + 'app/adaccounts';
const productsUrl = LogoutEndPoint + 'app/product';
const homeUrl = LogoutEndPoint + 'app/home';
const mantainanceUrl = LogoutEndPoint + 'mantainance';

const showWhileloadingpages = [initUrl, productsUrl];

const showSessionWillExpireBeforeMinutes = 30;
const reactAppGATrackingCode = '#{reactAppGATrackingCode}#';
const sessionStorageUTMValue = 'utm';
const GAEventCategoryLogin = 'Login';
const GAEventActionLoginFacebook = 'Login with facebook';
const GAEventActionLoginSuccess = 'Login success';
const GAEventCategoryLinkPayaly = 'Link payAly';
const GAEventActionLinkPayaly = 'The account has a payment';
const GAEventCategoryLinkPayalyPaid = 'Link paid';
const GAEventActionLinkPayalyPaid = 'A payment was done';
const GAEventCategoryAccount = 'Account';
const GAEventActionNewAccount = 'Add a new account';
const GAEventActionLinkAccount = 'Link to account key';
const GAEventCategoryAsociate = 'Asociate';
const GAEventActionAsociate = 'The account was asociate';
const GAEventCategoryRemove = 'Remove';
const GAEventActionRemove = 'The account was remove';
const GAEventCategorySale = 'Sale';
const GAEventActionSale = 'Product selected';
const GAEventCategoryCompany = 'Company';
const GAEventActionCompanyName = 'Company name';
const GAEventActionCompanyCountry = 'Company country';
const GAEventCategoryProduct = 'Product';
const GAEventActionProduct = 'A new product was created';
const loginLoginGA = 'Login';
const loginSuccessGA = 'LoginSuccess';
const accountNewAccountGA = 'NewAccount';
const accountAdAccountGA = 'AdAccount';
const accountRemoveAccountGA = 'RemoveAccount';
const billingSaleGA = 'BillingSale';
const billingCompanyNameGA = 'CompanyName';
const billingCompanyCountryGA = 'CompanyCountry';
const billingLinkPayalyGA = 'LinkPayaly';
const productProductGA = 'Product';

const pptxStyle = {
  fontFamily: 'Arial Rounded MT Bold',
  colors: {
    primary: '100047',
    secondary: '871BEF',
    white: 'ffffff',
    black: '000000',
    contrast: '88D1D4',
    lightgreen: '40bf80',
    darkgreen: '008000',
    lightblue: '00bfff',
  },
};

const AppAssetsUrlPre = 'https://aly-ai-prod-assets.s3.amazonaws.com/app';

const productTours = {
  es: {
    home: { id: 304168, pageId: homePagePageId, des: 'Barra Lateral' },
    welcome: { id: 295810, pageId: homePagePageId, des: 'Home:Bienvenida ' },
    standardKPI: {
      id: 305167,
      pageId: homePagePageId,
      des: 'Standard Indicators',
    },
    alyKPI: {
      id: 305168,
      pageId: homePagePageId,
      des: 'ALY Indicators ',
    },
    adsSelection: {
      id: 305936,
      pageId: release1AdsPageId,
      desc: 'Anuncios: Selección',
    },
    graphs: {
      id: 305930,
      pageId: homePagePageId,
      desc: 'home graphs',
    },
    /*
    adsImage: {
      id: 295927,
      pageId: release1AdsPageId,
      desc: 'Anuncios: Resultados imagen',
    },
    adsText: {
      id: 295928,
      pageId: release1AdsPageId,
      desc: 'Anuncios: Resultados texto',
    },
    adsAgeGender: {
      id: 295929,
      pageId: release1AdsPageId,
      desc: 'Anuncios: Resultados Edad',
    },
    adsGender: {
      id: 295930,
      pageId: release1AdsPageId,
      desc: 'Anuncios: Resultados Género',
    },*/
    newCampaignObjective: {
      id: 305942,
      pageId: createadPageId,
      desc: 'Objetivo',
    },
    /*
    newCampaignPlacements: {
      id: 296611,
      pageId: createadPageId,
      desc: 'Ubicaciones de negocio',
    },
    newCampaignSetup: {
      id: 296615,
      pageId: createadPageId,
      desc: 'Set up general',
    },
    */
  },
};

export {
  objectives,
  callToActionLinkClicks,
  callToActionConversions,
  callToActionLeadForms,
  callToActionAppInstall,
  callToActionConversationsMessenger,
  callToActionConversationsDirect,
  callToActionConversationsWhatsapp,
  bidStrategyLinkCLicks,
  bidStrategyAppInstall,
  bidStrategyConversations,
  bidStrategyConversions,
  bidStrategyLeadForms,
  lowestCostWithouCapBidStrategy,
  uploadVideoExtensions,
  uploadImageExtensions,
  maxUploadImageFiles,
  maxUploadVideoFiles,
  maxUploadImageFilesMessage,
  maxUploadVideoFilesMessage,
  conversationsDestinationType,
  standardPixelEvents,
  appInstall,
  conversationsObjective,
  conversionsObjective,
  appInstallObjective,
  fanPageComponentId,
  productComponentId,
  fanPageFocusId,
  pixelFocusId,
  applicationFocusId,
  conversationFocusId,
  dateComponentId,
  campaignStatusComponentId,
  fechaCampanaFocusId,
  campaignStatusFocusId,
  productoFocusId,
  presupuestoFocusId,
  linkFocusId,
  tituloAnuncioFocusId,
  cuerpoAnuncioFocusId,
  ubicacionFocusId,
  uploadFocusId,
  genderFocusId,
  ctaFocusId,
  UTMComponentId,
  linkDynamicParameters,
  titlesComponentId,
  locationComponentId,
  ctaAdPreview,
  descriptionComponentId,
  ctaComponentId,
  bodiesComponentId,
  attachmentsComponentId,
  genderAgeComponentId,
  adPreviewComponentId,
  budgetComponentId,
  leadGenerationFormComponentId,
  pixelComponentId,
  applicationComponentId,
  conversationComponentId,
  instagramAccountComponentId,
  facebookProvider,
  instagramProvider,
  leadGenerationObjective,
  linkClicksObjective,
  linkComponentId,
  getAccountsUrlAdminApiWithoutStatusValidation,
  getAvailableAccountsUrlAdminApi,
  getAdAccountsByProductUrlAdminApi,
  getAdAccountsUrlAdminApi,
  getCompaniesUrlAdminApi,
  getBrandsUrlAdminApi,
  getAdPreviewUrlAdminApi,
  getProductsUrlAdminApi,
  deleteProductsUrlAdminApi,
  getFanPagesUrlAdminApi,
  getLeadFormUrlAdminApi,
  getPixelUrlAdminApi,
  getPixelStatsUrlAdminApi,
  getApplicationsUrlAdminApi,
  getInstagramAccountUrlAdminApi,
  getCategoriesUrlAdminApi,
  //getCountriesUrlAdminApi,
  //getCampaignTypesAdminUrl,
  campaignNameSelectorId,
  googlePlayApplicationId,
  amazonAppStoreApplicationId,
  fbCanvasApplicationId,
  instantGameApplicationId,
  itunesApplicationId,
  itunesIpadApplicationId,
  windowsStoreApplicationId,
  facebookMessengerDestinationTypeId,
  instagramDirectDestinationTypeId,
  whatsAppDestinationTypeId,
  insertUserUrl,
  assingAdAccountUrl,
  getAdAccountsUrl,
  getDataset4,
  getDataset5,
  getDataset6,
  getDataset7,
  getDataset8,
  getDataset10Url,
  getDataset11Url,
  getDataset10UrlCharts,
  getDataset10UrlChartsTotal,
  RedirectEndPoint,
  LogoutEndPoint,
  saveAsset,
  saveDraft,
  getfacebookCampaignsUrl,
  getfacebookCampaignsWithoutProductAdminUrl,
  getfacebookCampaignsWithFilters,
  setProductsToAdsets,
  getAdAccountsUrlStatsApi,
  saveProduct,
  getAdAccountsByProduct,
  todosSelectorValue,
  todasSelectorValue,
  getCampaignTypesUrl,
  getProductsUrl,
  getAgeUrl,
  getGenderUrl,
  optimizationSelectorId,
  accountSelectorId,
  adAccountSelectorId,
  companySelectorId,
  brandSelectorId,
  campaignSelectorId,
  categorySelectorId,
  facebookCampaignSelectorId,
  productSelectorId,
  ageSelectorId,
  genderSelectorId,
  dataSet9Id,
  sideBarDatePicker,
  ExpiredEndPoint,
  Environment,
  getBrandsUrl,
  getCompaniesUrl,
  biddingAmountFocusId,
  biddingAmountComponentId,
  campaignNameComponentId,
  campaignNameFocusId,
  getfacebookCampaignNames,
  createAccountUrl,
  verifyAccountUrl,
  linkAccountWithUserUrl,
  addAdAccountToAccountUrl,
  Build,
  methodNotAllowedHttpError,
  conflictHttpError,
  internalServerErrorHttpError,
  notFoundHttpError,
  serviceUnavailableHttpError,
  unauthorizedHttpError,
  successHttpCode,
  getadAccountByUserAdminApiUrl,
  getpresentationUrl,
  badParametersHttpError,
  assetBase64Url,
  fileNotSupportedHttpCode,
  getOnBoardingUrl,
  deletePermissionsUrl,
  onBoardingNoClient,
  onBoardingNoAdAccounts,
  onBoardingNoBBAdAccounts,
  onBoardingNoProducts,
  onBoardingMantainance,
  onBoardingBilling,
  onBoardingOK,
  onBoardingInactive,
  initUrl,
  adAccountsUrl,
  productsUrl,
  mantainanceUrl,
  homeUrl,
  showWhileloadingpages,
  getGeoLocationUrl,
  clientApprovalUrl,
  getCampaignsAlyUrl,
  getcampaignManagerUniqueUrl,
  LookAlikeFocusId,
  LookAlikeComponentId,
  getCustomAudience,
  getSavedAudience,
  noContentHttpCode,
  getAdInterestsUrl,
  interestsComponentId,
  interestsFocusId,
  facebookGasHttpErrorCode,
  getAdTargetingCategoryUrl,
  relationshipStatuses,
  educationStatuses,
  getCampaignByProductAndDate,
  glosarioPowerPoint,
  getAccountToActivateStaff,
  activateAccountUrl,
  loginPageId,
  homePagePageId,
  release1AdsPageId,
  adaccountsPageId,
  exportPagePageId,
  clientApprovalPageId,
  downloadstatsPageId,
  createadPageId,
  productPageId,
  modifyProductPageId,
  assignPageId,
  initPageId,
  approvalPageId,
  rolesPageId,
  billingPageId,
  getNewNonceUrl,
  newAccountPageId,
  newAccountUrl,
  defaultPermissions,
  sessionValidationInterval,
  hideTenantSelectorPageIds,
  release1AdsPagePath,
  adaccountsPagePath,
  createadPagePath,
  productPagePath,
  assignPagePath,
  modifyProductPagePath,
  exportPagePagePath,
  approvalPagePagePath,
  clientApprovalPagePath,
  homePagePagePath,
  downloadstatsPagePath,
  newAccountPagePath,
  initPagePath,
  billingPagePath,
  forbiddenHttpError,
  updateCampaignsAlyUrl,
  userPagePath,
  userPageId,
  userRoles,
  userRolesWithStaff,
  getAccountUsersUrl,
  modifyAccountUsersUrl,
  deleteAccountUsersUrl,
  staffRoleId,
  getAdAccountsUrlAdminAdAccountPage,
  deleteAdAccountsUrlAdminAdAccountPage,
  conversationsIGStoriesDestinationType,
  conversationsWhatsAppDestinationType,
  placements,
  getPayAlyProductsUrl,
  getPayAlyCountriesUrl,
  getPayAlyCompanyInfoUrl,
  payAlysaveUserUrl,
  generatePayAlyLinkUrl,
  getPayAlyCompanyLinks,
  getPayAlyCompanyCurrentSubscription,
  getPayAlyCompanySubscriptions,
  subscriptionsPagePath,
  subscriptionsPageId,
  onBoardingClientApproval,
  facebookPlacementParameters,
  instagramPlacementParameters,
  multipleProvider,
  payAlyCancelSubscriptions,
  reactAppGATrackingCode,
  pptxStyle,
  AppAssetsUrlPre,
  showSessionWillExpireBeforeMinutes,
  sessionStorageUTMValue,
  GAEventCategoryLogin,
  GAEventActionLoginFacebook,
  GAEventActionLoginSuccess,
  GAEventCategoryLinkPayaly,
  GAEventActionLinkPayaly,
  GAEventCategoryLinkPayalyPaid,
  GAEventActionLinkPayalyPaid,
  GAEventCategoryAccount,
  GAEventActionNewAccount,
  GAEventActionLinkAccount,
  GAEventCategoryAsociate,
  GAEventActionAsociate,
  GAEventCategoryRemove,
  GAEventActionRemove,
  GAEventCategorySale,
  GAEventActionSale,
  GAEventCategoryCompany,
  GAEventActionCompanyName,
  GAEventActionCompanyCountry,
  GAEventCategoryProduct,
  GAEventActionProduct,
  draftsPageId,
  draftsPagePath,
  loginLoginGA,
  loginSuccessGA,
  accountNewAccountGA,
  accountAdAccountGA,
  accountRemoveAccountGA,
  billingSaleGA,
  billingCompanyNameGA,
  billingCompanyCountryGA,
  billingLinkPayalyGA,
  productProductGA,
  getDrafts,
  DraftStatus,
  reportsPageId,
  reportsPagePath,
  getReports,
  getCampaignStatsUrl,
  mixPanelProjectToken,
  productTours,
  getAdAccountsUrlAdminOnboardingPage,
  makeAlyBusinessPartner,
  associateBusinessWithAdAccount,
  deAssociateBusinessWithAdAccount,
  payWallCreateAccount,
  payWallApplyCoupon,
  couponValidate,
  couponsPagePath,
  couponsPageId,
  ageGenderUrl,
  ageGenderKey, 


  salesReportPath, 
  salesReportPageId, 
  automaticReportPageId, 
  automaticReportPath,
  automaticReports,
  sendAutomaticReports,
  sellsCapture,
  getSales,
  getTickets,
  automaticReportsClients,
  automaticReportsCSA
};
