import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import GridContainer from '../../../components/Grid/GridContainer.js';
import GridItem from '../../../components/Grid/GridItem.js';
import Card from '../../../components/Card/Card.js';
import CardHeader from '../../../components/Card/CardHeader.js';
import CardIcon from '../../../components/Card/CardIcon.js';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CardFooter from '../../../components/Card/CardFooter.js';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import SyncIcon from '@material-ui/icons/Sync';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import { convertBigNumbers } from '../../../utils/functions';
import Widget from '../../../components/Widget/WidgetContainer';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import HelpIcon from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';
import { useIntercom } from 'react-use-intercom';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import trans from '../../../i18n/tran';
import {
  productTours
} from './../../../components/Constants';
const Dashboard35 = ({ classes, theme, ...props }) => {
  const [expanded1, setExpanded1] = React.useState(false);
  const { startTour } = useIntercom();

  const handleChange = panel => (event, isExpanded) => {
    setExpanded1(isExpanded ? true : false);
  };
  if (props.isLoading && !props.isPrintingPage) {
    return (
      <Widget>
        <Typography>{trans('LOADING', props.lang)}...</Typography>
      </Widget>
    );
  } else if (props.data && props.data.length === 0) {
    return (
      <Widget>
        <Typography>{trans('NO_INFO', props.lang)}</Typography>
      </Widget>
    );
  } else if (props.data && props.data.Counter === 0) {
    return (
      <Widget>
        <Typography>{trans('NO_INFO', props.lang)}</Typography>
      </Widget>
    );
  } else if (props.data && typeof props.data.Actions) {
    if (props.resizeRefresh) {
      return <div></div>;
    } else {
      return (
        <>
        
          <Accordion expanded={expanded1} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.accordionSummary}
              aria-controls="panel1b-content"
              id="panel1b-header"
            >
              <Typography className={classes.heading}>
                {trans('STANDARD_INDICATORS', props.lang)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GridContainer>
                {/* IMPRESSIONS */}
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="info" stats icon>
                      <CardIcon color="info">
                        <AccountCircleIcon />
                      </CardIcon>
                      <p className={classes.cardCategory}>{trans('IMPRESSIONS', props.lang)}</p>
                      <h3
                        className={classes.cardTitle}
                        style={{
                          fontSize:
                            document.documentElement.clientWidth > 1500
                              ? '1.2rem'
                              : document.documentElement.clientWidth > 1390
                              ? '1rem'
                              : document.documentElement.clientWidth < 1280
                              ? '1.5rem'
                              : '0.8rem',
                        }}
                      >
                        {convertBigNumbers(props.data.Impressions)}
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}></div>
                    </CardFooter>
                  </Card>
                </GridItem>
                {/* COST */}
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="info" stats icon>
                      <CardIcon color="info">
                        <AttachMoneyIcon />
                      </CardIcon>
                      <p className={classes.cardCategory}>{trans('COST', props.lang)}</p>
                      <h3
                        className={classes.cardTitle}
                        style={{
                          fontSize:
                            document.documentElement.clientWidth > 1500
                              ? '1.2rem'
                              : document.documentElement.clientWidth > 1390
                              ? '1rem'
                              : document.documentElement.clientWidth < 1280
                              ? '1.5rem'
                              : '0.8rem',
                        }}
                      >
                        {convertBigNumbers(props.data.Cost)}
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}></div>
                    </CardFooter>
                  </Card>
                </GridItem>
                {/* CLICKS */}
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="info" stats icon>
                      <CardIcon color="info">
                        <CheckCircleIcon />
                      </CardIcon>
                      <p className={classes.cardCategory}>Clicks</p>
                      <h3
                        className={classes.cardTitle}
                        style={{
                          fontSize:
                            document.documentElement.clientWidth > 1500
                              ? '1.2rem'
                              : document.documentElement.clientWidth > 1390
                              ? '1rem'
                              : document.documentElement.clientWidth < 1280
                              ? '1.5rem'
                              : '0.8rem',
                        }}
                      >
                        {convertBigNumbers(props.data.LinkClicks, 0)}
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}></div>
                    </CardFooter>
                  </Card>
                </GridItem>
                {/* ACTIONS */}
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="info" stats icon>
                      <CardIcon color="info">
                        <SpellcheckIcon />
                      </CardIcon>
                      <p className={classes.cardCategory}>{trans('ACTIONS', props.lang)}</p>
                      <h3
                        className={classes.cardTitle}
                        style={{
                          fontSize:
                            document.documentElement.clientWidth > 1500
                              ? '1.2rem'
                              : document.documentElement.clientWidth > 1390
                              ? '1rem'
                              : document.documentElement.clientWidth < 1280
                              ? '1.5rem'
                              : '0.8rem',
                        }}
                      >
                        {convertBigNumbers(props.data.Actions, 0)}
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}></div>
                    </CardFooter>
                  </Card>
                </GridItem>
                {/* CTR */}
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="info" stats icon>
                      <CardIcon color="info">
                        <ThreeSixtyIcon />
                      </CardIcon>
                      <p className={classes.cardCategory}>CTR</p>
                      <h3
                        className={classes.cardTitle}
                        style={{
                          fontSize:
                            document.documentElement.clientWidth > 1500
                              ? '1.2rem'
                              : document.documentElement.clientWidth > 1390
                              ? '1rem'
                              : document.documentElement.clientWidth < 1280
                              ? '1.5rem'
                              : '0.8rem',
                        }}
                      >
                        {convertBigNumbers(props.data.CTR)} %
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}></div>
                    </CardFooter>
                  </Card>
                </GridItem>
                {/* CONV RATE */}
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="info" stats icon>
                      <CardIcon color="info">
                        <SyncIcon />
                      </CardIcon>
                      <p className={classes.cardCategory}>Conv Rate</p>
                      <h3
                        className={classes.cardTitle}
                        style={{
                          fontSize:
                            document.documentElement.clientWidth > 1500
                              ? '1.2rem'
                              : document.documentElement.clientWidth > 1390
                              ? '1rem'
                              : document.documentElement.clientWidth < 1280
                              ? '1.5rem'
                              : '0.8rem',
                        }}
                      >
                        {props.data.CVR}%
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}></div>
                    </CardFooter>
                  </Card>
                </GridItem>
                {/* CPA */}
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="info" stats icon>
                      <CardIcon color="info">
                        <LocalAtmIcon />
                      </CardIcon>
                      <p className={classes.cardCategory}>CPA</p>
                      <h3
                        className={classes.cardTitle}
                        style={{
                          fontSize:
                            document.documentElement.clientWidth > 1500
                              ? '1.2rem'
                              : document.documentElement.clientWidth > 1390
                              ? '1rem'
                              : document.documentElement.clientWidth < 1280
                              ? '1.5rem'
                              : '0.8rem',
                        }}
                      >
                        {convertBigNumbers(props.data.CPA)}
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}></div>
                    </CardFooter>
                  </Card>
                </GridItem>
                {/* CPM */}
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="info" stats icon>
                      <CardIcon color="info">
                        <LocalAtmIcon />
                      </CardIcon>
                      <p className={classes.cardCategory}>CPM</p>
                      <h3
                        className={classes.cardTitle}
                        style={{
                          fontSize:
                            document.documentElement.clientWidth > 1500
                              ? '1.2rem'
                              : document.documentElement.clientWidth > 1390
                              ? '1rem'
                              : document.documentElement.clientWidth < 1280
                              ? '1.5rem'
                              : '0.8rem',
                        }}
                      >
                        {convertBigNumbers(props.data.CPM)}
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}></div>
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Button
                    variant="outlined"
                    size="medium"
                    className={classes.HelpButton}
                    onClick={e => {
                      if (startTour) {
                        startTour(productTours.es.standardKPI.id);
                      }
                    }}>
                      <HelpIcon fontSize="medium"  />
                      {props.buttonText ? props.buttonText : trans('HELP', props.lang)}
                  </Button>
                </GridItem>

              </GridContainer>
            </AccordionDetails>
          </Accordion>
        </>
      );
    }
  } else
    return (
      <Widget>
        <Typography>No hay datos para mostrar</Typography>
      </Widget>
    );
};

const styles = theme => ({
  accordionSummary: {
    flexDirection: 'row-reverse'
  },
  heading: {
    fontSize: '1.2rem',
  },
  tableWidget: {
    overflowX: 'auto',
    fontSize: '0.9rem',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  mainChartHeaderLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
  },
  divider: {
    minHeight: '3px',
    margin: '10px',
  },
  mainChartLegentElement: {
    fontSize: '18px !important',
    marginLeft: theme.spacing(1),
  },
  cardStyle: {
    flexDirection: 'column',
    minHeight: '400px',
    flexFlow: 'column wrap',
  },
  cardStyleLow: {
    flexDirection: 'column',
    minHeight: '400px',
    flexFlow: 'column wrap',
  },
  cardCategory: {
    color: theme.palette.text.primary,
    fontSize: '1.1rem',
    paddingTop: '10px',
    marginBottom: '0',
    marginTop: '0',
    margin: '0',
  },
  cardTitle: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    fontWeight: '300',
    marginTop: '30px',
    marginBottom: '25px',
    minHeight: '32px',
    '& small': {
      color: theme.primary,
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  metric: {
    textAlign: 'right',
  },
  metricText: {
    textAlign: 'left',
  },
  HelpButton: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '35%',
    alignText: 'center',
    fontSize: '0.75rem',
    marginTop: theme.spacing(2),
    color: theme.palette.text.hint
  },
});

export default withStyles(styles, { withTheme: true })(Dashboard35);
