import React, { Component } from 'react';
import {
  TextField,
  withStyles,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Widget from '../Widget/WidgetContainer';
import { recalculateMessages } from '../../utils/functions';
import { styles } from '../../utils/styles';
import trans from './../../i18n/tran';
class TextFieldList extends Component {
  constructor(props, classes) {
    let initValue = props.hasInitValue ? props.initListValue : [''];
    let messages = [];

    for (let i = 0; i < initValue.length; i++) {
      messages.push(
        props.getMessage ? props.getMessage(i, initValue, props) : '',
      );
    }
    super(props);
    this.state = {
      maxLengthExceeded: [false],
      list: initValue,
      message: messages,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.textFieldVariant === 'biddingAmount') {
      if (this.props.bidStrategy !== prevProps.bidStrategy) {
        recalculateMessages(this);
      }        
    }

    if (
      this.props.textFieldVariant === 'biddingAmount' &&
      prevProps.list[0] !== this.props.list[0]
    ) {
      this.setState({ list: this.props.list });
    }

    if (this.props.reset) {
      if (this.state.list.length > 1 || this.state.list[0].length > 0) {
        this.setState({ maxLengthExceeded: [false], list: [''] });
      }
    }

    if (prevProps.recalculateMessages !== this.props.recalculateMessages) {
      recalculateMessages(this);
    }
  }
  render() {
    return (
      <Widget
        titleFullWith={true}
        focusable={this.props.focusable}
        disableWidgetMenu={true}
        title={<div>{this.props.widgetTitle}</div>}
        title2={this.props.widgetTitle2}
        sidebutton={
          this.props.maxListSize > 1 &&
          this.state.list.length < this.props.maxListSize ? (
            <div style={{ float: 'right' }}>
              <AddIcon
                id={this.props.id + 'addButton'}
                className={this.props.classes.buttons}
                onClick={() => {
                  let newState = this.state;
                  newState.list.push('');
                  this.setState(newState);
                  recalculateMessages(this);
                  this.props.changeList(newState.list);
                }}
              />
            </div>
          ) : (
            ''
          )
        }
      >
        <React.Fragment>
          {this.state.list.map((title, index) => (
            <span key={index}>
              {this.props.showAdErrors ||
              (this.state &&
                this.state.message &&
                this.state.message[index] &&
                !this.state.message[index].isError)
                ? this.state.message[index]
                  ? this.state.message[index].message
                  : ''
                : ''}
              <TextField
                onBlur={e => {
                  if (this.props.onBlur) this.props.onBlur(e);
                }}
                id={this.props.id + 'textfield' + index}
                rows={this.props.rows}
                className={this.props.classes.textFieldList}
                variant={this.props.variant}
                multiline={this.props.multiline}
                margin={this.props.margin}
                type={this.props.fieldType ? this.props.fieldType : 'text'}
                onWheel={event => {
                  event.target.blur();
                }}
                disabled={this.props.disabled}
                fullWidth={true}
                onKeyUp={e => {
                  this.props.changeList(this.state.list);
                  let newState = this.state;
                  let message = this.props.getMessage
                    ? this.props.getMessage(index, this.state.list, this.props)
                    : '';
                  if (newState.message.length >= index)
                    newState.message[index] = message;
                  else newState.message.push(message);

                  this.setState(newState);
                }}
                onChange={e => {
                  let newState = this.state;
                  let newList = this.state.list.map(value => value);
                  newList[index] = e.target.value;
                  newState.list = newList;
                  this.setState(newState);
                }}
                placeholder={this.props.placeholder}
                value={title}
                style={{
                  width: '100%',
                  fontSize: '1rem',
                }}
                inputProps={{ style: { textAlign: this.props.textAlign } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {this.props.disabled ? (
                        ''
                      ) : (
                        <DeleteIcon
                          id={this.props.id + 'deleteButton' + index}
                          className={this.props.classes.buttons}
                          onClick={() => {
                            let newList = this.state.list.map(value => value);
                            let newState = this.state;
                            newList.splice(index, 1);
                            newState.maxLengthExceeded.splice(index, 1);
                            if (newList.length === 0) newList.push('');
                            newState.list = newList;
                            //validateError(newList, this);
                            this.setState(newState);
                            this.props.changeList(newState.list);
                            if (this.props.textFieldVariant === 'link')
                              this.props.changeAdLinkPreview('');
                            recalculateMessages(this);
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {this.props.textFieldVariant === 'link' ? (
                <div>
                  <h3>{trans('PREVIEW', this.props.lang)}</h3>
                  <Tooltip
                    title={
                      this.props.adLinkPreview.length > 0
                        ? this.props.adLinkPreview
                        : trans('PREVIEW', this.props.lang)
                    }
                  >
                    <TextField
                      disabled={true}
                      id={'Link_Preview'}
                      rows={this.props.rows}
                      className={this.props.classes.textFieldList}
                      variant={this.props.variant}
                      multiline={this.props.multiline}
                      margin={this.props.margin}
                      type="text"
                      fullWidth={true}
                      value={this.props.adLinkPreview}
                    ></TextField>
                  </Tooltip>
                </div>
              ) : (
                ''
              )}
            </span>
          ))}
        </React.Fragment>
      </Widget>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TextFieldList);
