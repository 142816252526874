import { compose, withState, withHandlers, lifecycle } from 'recompose';
import View from './View';
import { doFetch, createHeaders } from './../../utils/fetch';
import { getReports as getReportsUrl } from './../../components/Constants';
import { findNeedleInURL } from '../../utils/urlUtils';

let controller = new AbortController();
let signal =  controller.signal;
let isFetching = false;

async function getReportDef(props, reportid) {
  let url = `${getReportsUrl}/${reportid}`;
  controller = new AbortController();
  signal =  controller.signal;
  isFetching = true;

  await doFetch(
    url,
    {
      method: 'get',
      headers: createHeaders(),
      signal,
    },
    res => {
      isFetching = false;
      if ('success' in res && 'payload' in res && res.success) {
        if (res.payload && res.payload.length && res.payload.length > 1) {
          let raw = res.payload[0];
          if (raw && raw.length > 0) {
            props.updateReports([]);
            props.updateReportId(reportid);
            try {
              const params = JSON.parse(raw[0].Params);
              const cols = JSON.parse(raw[0].Columns);
              raw[0].Params = params;
              raw[0].Columns = cols;
              raw[0].Data = [];
              //console.log(raw[0].Params)
            } catch (e) {
              raw[0].Params = [];
              raw[0].Columns = [];
              raw[0].Data = [];
            }
            props.updateReport(raw[0]);
            props.changeIsLoadingLayout(false);
          }
        }
      }
    },
  );
}


function createSPParams(report) {
  let params = [];
  if (report && report.Params && report.Params.length > 0) {
    report.Params.forEach(element => {
      if (element.Actual) {
        params.push(element.Actual);
      } else {
        params.push(element.Default);
      }
    });
  }
  return params.join();
}

async function runReport(props) {
  controller = new AbortController();
  signal = controller.signal;
  props.changeIsLoadingLayout(true);

  let url = `${getReportsUrl}/${props.reportid}`;

  await doFetch(
    url,
    {
      method: 'post',
      headers: createHeaders(),
      body: JSON.stringify({
        spParams: createSPParams(props.report),
      }),
      signal,
    },
    res => {
      props.changeIsLoadingLayout(false);
      if ('success' in res && 'payload' in res && res.success) {
        if (res.payload && res.payload.length && res.payload.length > 1) {
          let raw = res.payload[0];
          let rep = props.report;
          rep.Data = raw;
          props.updateReport(rep);
          // console.log(rep.Data)
          props.changeIsLoadingLayout(false);
        }
      }
    },
  );

  props.changeIsLoadingLayout(false);
}

async function getReports(props) {
  controller = new AbortController();
  signal = controller.signal;


  let url = `${getReportsUrl}/`;

  await doFetch(
    url,
    {
      method: 'get',
      headers: createHeaders(),
      signal,
    },
    res => {
      if ('success' in res && 'payload' in res && res.success) {
        if (res.payload && res.payload.length && res.payload.length > 1) {
          let raw = res.payload[0];
          props.updateReports(raw);
          props.updateReportId(undefined);
          props.updateReport(undefined);
          props.changeIsLoadingLayout(false);
        }
      }
    },
  );

  props.changeIsLoadingLayout(false);
}

const mycompose = compose(
  withState('reports', 'updateReports', []),
  withState('reportid', 'updateReportId', undefined),
  withState('report', 'updateReport', undefined),
  lifecycle({
    componentDidMount() {
      let repId = findNeedleInURL(this.props.location.search, 'id');
      if (repId) {
        getReportDef(this.props, repId);
      } else {
        getReports(this.props);
      }
    },
    componentWillUnmount() {
     //  console.log("component will unmount")
      if (!(typeof controller === 'undefined')) {
        if (isFetching) {
          console.log("abort fetching")
          controller.abort();
        }        
      }
    },
  }),
  withHandlers({
    execReport: props => event => {
      runReport(props);
    },
    paramChanged: props => (event, param) => {
      // find param by name then update actual parameter value
      if (
        props.report &&
        props.report.Params &&
        props.report.Params.length > 0
      ) {
        props.report.Params.forEach(element => {
          if (element.Name === param) {
            // found
            element.Actual = event.target.value;
          }
        });
        props.updateReport(props.report);
      }
    },
  }),
);

export default mycompose(View);
