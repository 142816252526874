import { compose, withState, withHandlers, lifecycle } from 'recompose';
import View from './AssignProductView';
//import { getProductAdminApiFilters } from "../../../../utils/functions";
//import { saveAssignRequest } from "../../components/ApiClient/Assign";

const mycompose = compose(
  withState('filter', 'changeFilter', ''),
  lifecycle({
    componentDidMount() {
      //getCategoriesCreateAssign(this.props);
      //getCountriesCreateAssign(this.props);
    },

    componentWillUnmount() {
      //window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
    componentDidUpdate() {},
  }),
  withHandlers({}),
);

export default mycompose(View);
