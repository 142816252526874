import React from 'react';
import GoogleMapReact from 'google-map-react';
import Select from 'react-select';
import {
  Typography,
  Grid,
  TextField,
  Button,
  withStyles,
  Checkbox,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import EnhancedTable from '../EnhancedTable/EnhancedTable';
import ErrorIcon from '@material-ui/icons/Error';
import Widget from '../Widget';
import { styles } from '../../utils/styles';
import Focusable, { getFocusable } from '../../pages/createad/Focusable';
import googleIconInclude from '../../images/googleIcon.png';
import googleIconExclude from '../../images/googleIcon2.png';
import {
  getGeoLocation,
  filterGeolocations,
  isNumeric,
  alertConfirmPopUp,
  alertPopUp,
} from '../../utils/functions';
import { Environment } from '../Constants';
import  SavedAudienceMessage, {ComLocsFromSavedAudiences} from './../../pages/createad/Components/savedAudienceMessage';
import {
  getWidthCode,
} from '../../utils/generalFunctions';
import trans from './../../i18n/tran';

const View = ({ classes, theme, onChange, value, ...props }) => {
  function getAlerTableRow(newLocation, index) {
    return (
      <TableRow key={index}>
        <TableCell>
          <span style={{ fontWeight: 'bold' }}>{newLocation.tipo}</span>
        </TableCell>
        <TableCell>{newLocation.name}</TableCell>
      </TableRow>
    );
  }

  function getAlertText(newLocation, text) {
    return (
      <div>
        <span style={{ fontWeight: 'bold' }}>{text}</span>
        <Table>
          <TableBody>
            <TableRow></TableRow>
            {getAlerTableRow(newLocation)}
          </TableBody>
        </Table>
      </div>
    );
  }
  function getAlertTextFromArray(array, text) {
    return (
      <Grid container>
        <span style={{ fontWeight: 'bold' }}>{text}</span>
        <Table>
          <TableBody>
            <TableRow></TableRow>
            {array.map((element, index) => {
              return getAlerTableRow(element, index);
            })}
          </TableBody>
        </Table>
      </Grid>
    );
  }

  function validateLocation(
    locations,
    newLocations,
    removedLocations,
    newLocation,
  ) {
    let addNew = true;
    if (locations.length > 0) {
      locations.forEach(element => {
        let addElement = true;
        if (newLocation.fbType === 'country') {
          if (
            element.country_code === newLocation.key &&
            element.include === newLocation.include
          ) {
            addElement = false;
            removedLocations.push(element);
          }
        } else if (newLocation.fbType === 'region') {
          if (
            element.region_id === newLocation.key &&
            element.include === newLocation.include
          ) {
            removedLocations.push(element);
            addElement = false;
          } 
        } else if (newLocation.fbType === 'city') {
          if (
            element.primary_city_id === newLocation.key &&
            element.include === newLocation.include
          ) {
            removedLocations.push(element);
            addElement = false;
          } 
        }
        if (
          element.key === newLocation.country_code &&
          element.include === newLocation.include
        ) {
          alertPopUp(
            getAlertText(
              newLocation,
              trans('NEW_CAMPAIGN_MESSAGE27', props.lang),
            ),
            props,
            false,
            true,
          );
          addNew = false;
        } else if (
          element.key === newLocation.region_id &&
          element.include === newLocation.include
        ) {
          alertPopUp(
            getAlertText(
              newLocation,
              trans('NEW_CAMPAIGN_MESSAGE28', props.lang),
            ),
            props,
            false,
            true,
          );
          addNew = false;
        } else if (
          element.key === newLocation.primary_city_id &&
          element.include === newLocation.include
        ) {
          alertPopUp(
            getAlertText(
              newLocation,
              trans('NEW_CAMPAIGN_MESSAGE29', props.lang),
            ),
            props,
            false,
            true,
          );
          addNew = false;
        }

        if (addElement) newLocations.push(element);
      });

      if (removedLocations.length > 0) {
        alertPopUp(
          getAlertTextFromArray(
            removedLocations,
            'Al seleccionar ' +
              newLocation.tipo +
              ' se han removido las siguientes locaciones:',
          ),
          props,
          false,
          true,
        );
      }
    }
    if (addNew) {
      newLocations.push(newLocation);
    }
  }
  function addGeoLocation() {
    if (validateMaxLocations()) {
      let locations = props.locations;
      let locationId = props.locationId + 1;
      props.setLocationId(locationId);
      let newLocation = {
        region_id: props.geoTargetingSelected[0].region_id,
        selected: props.geoTargetingSelected[0],
        name: props.geoTargetingSelected[0].name,
        locationId: locationId,
        label: locationId,
        text: props.geoTargetingSelected[0].name,
        type: props.geoTargetingSelected[0].type,
        tipo: trans(props.geoTargetingSelected[0].type.toUpperCase(), props.lang),
        geoTargetingSearch: props.geoTargetingSearch,
        fbType: props.geoTargetingSelected[0].type,
        include: true,
        key: props.geoTargetingSelected[0].key,
        country_code: props.geoTargetingSelected[0].country_code,
        primary_city_id: props.geoTargetingSelected[0].primary_city_id,
      };

      let newLocations = [];
      let removedLocations = [];

      validateLocation(locations, newLocations, removedLocations, newLocation);

      filterGeolocations(
        props,
        props.geoTargetingSelector,
        newLocations,
        removedLocations,
      );
      props.setLocations(newLocations);
      props.changePagePagination(
        Math.ceil(newLocations.length / props.rowsPerPage) - 1,
      );
      props.changeGeoTargetingSelected([]);
    }
  }
  function changeRadiusFromValue(value) {
    props.radiusList.forEach(element => {
      if (element.value === value) props.changeRadius([element]);
    });
  }
  function changeCenter(row, locations, map, focusMap = true, click = false) {
    if (!map) map = props.map;
    if (map) {
      if (row.fbType && row.fbType === 'position') {
        refreshMarkers(locations ? locations : props.locations, map);
        setMap(
          props,
          map,
          row.radius,
          row.position,
          row.locationId,
          locations ? locations : props.locations,
          focusMap,
          click,
        );
      }
    }
  }
  function removeLocation(locationId) {
    let locations = [];
    let removedSelected = false;
    let removedLocation = undefined;
    if (props.locations) locations = props.locations;

    let newLocations = [];
    for (let i = 0; i < locations.length; i++) {
      if (locations[i].locationId !== locationId) {
        newLocations.push(locations[i]);
      } else {
        removedLocation = locations[i];
        if (locations[i].fbType === 'position') {
          if (locations[i].marker) locations[i].marker.setMap(null);
          if (locations[i].circle) locations[i].circle.setMap(null);
          if (locationId === props.locationSelected) {
            removedSelected = true;
          }
        }
      }
    }
    if (newLocations.lenght > 0) {
      if (removedSelected)
        changeCenter(newLocations[0], newLocations, props.map, false);
      else {
        let selected = getSelectedLocationObject();
        if (selected) changeCenter(selected, newLocations, props.map, false);
      }
    }
    props.setLocations(newLocations);
    if (newLocations.length > 0)
      props.changePagePagination(
        Math.ceil(newLocations.length / props.rowsPerPage) - 1,
      );
    if (props.isGeolocation) {
      if (removedLocation) {
        filterGeolocations(
          props,
          props.geoTargetingSelector,
          [],
          [removedLocation],
        );
      }
    }
  }
  function setMap(
    props,
    map,
    radius,
    position,
    locationId,
    locations,
    focusMap,
    click,
  ) {
    if (!click) {
      let zoom =
        radius === 1000
          ? 14
          : radius === 2000
          ? 13
          : radius === 5000
          ? 12
          : radius === 10000
          ? 11
          : radius === 20000
          ? 10
          : radius === 40000 || radius === 50000 || radius === 80000
          ? 9
          : 13;
      zoom = zoom - 1;

      props.setZoom(zoom);
      map.zoom = zoom;
    }
    changeRadiusFromValue(radius);
    map.setCenter(position);
    setSelectedLocation(props, locationId, locations, focusMap);
  }
  function setSelectedLocation(props, locationId, locations, focusMap) {
    if (!locations) locations = props.locations;
    if (locations) {
      let i = 0;
      locations.forEach(element => {
        i++;
        if (element.locationId === locationId) {
          props.changePagePagination(Math.ceil(i / props.rowsPerPage) - 1);
          element.label = <CheckIcon></CheckIcon>;
          if (element.marker) {
            if (focusMap) {
              let ref = getFocusable(props.refList, props.focusId + 'Map');
              if (ref) {
                if (ref.current)
                  if (ref.current.offsetTop !== undefined) {
                    window.scrollTo(0, ref.current.offsetTop - 100);
                  }
              }
            }
            element.marker.setAnimation(window.google.maps.Animation.BOUNCE);
          }
        } else {
          element.label = element.locationId;
        }
      });
      props.setLocationSelected(locationId);
    }
  }

  // since we are using a setTimeout inside of a istener for Markers and circles, theese create an object not a pointer of the props,
  //so any uptate that needs to be reflected on the "click Listener" should be refreshed in this function and also will use this function
  //to change colors of markers and circles
  function refreshMarkers(newLocations, map) {
    if (!map) map = props.map;
    newLocations.forEach(element => {
      if (element.marker || element.createMarker) {
        if (element.marker)
          //remove circle
          //remove marker
          element.marker.setMap(null);
        //create new marker
        let marker = new window.google.maps.Marker({
          position: element.position,
          title: String(element.locationId),
          label: String(element.locationId),
          map: map,
          icon: element.include ? googleIconInclude : googleIconExclude,
        });

        marker.addListener('click', () => {
          props.setMarkerWasSelected(true);
          setTimeout(function () {
            changeCenter(
              {
                position: element.position,
                locationId: element.locationId,
                fbType: 'position',
                radius: element.radius,
              },
              newLocations,
              map,
            );
          }, 50);
        });
        element.marker = marker;
      }
      if (element.circle || element.createCircle) {
        if (element.circle) {
          element.circle.setMap(null);
        }
        //create new marker
        let circle = new window.google.maps.Circle({
          strokeColor: element.include ? '#013F8A' : '#000000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: element.include ? '#013F8A' : '#000000',
          fillOpacity: 0.3,
          map: map,
          center: element.position,
          radius: element.radius,
        });
        circle.addListener('click', () => {
          props.setMarkerWasSelected(true);
          setTimeout(function () {
            changeCenter(
              {
                position: element.position,
                locationId: element.locationId,
                fbType: 'position',
                radius: element.radius,
              },
              newLocations,
              map,
            );
          }, 50);
        });
        element.circle = circle;
      }
    });
  }
  function getSelectedLocationObject(fbType) {
    let find = props.locations.findIndex(function (item, i) {
      return item.locationId === props.locationSelected;
    });
    if (find > -1) {
      if (!fbType || props.locations[find].fbType === fbType) {
        return props.locations[find];
      } else return undefined;
    } else {
      return undefined;
    }
  }
  function validateMaxLocations() {
    if (props.locations.length === 25) {
      alertPopUp('Hay un máximo de 25 locaciones', props, false, true);
      return false;
    }
    return true;
  }
  function renderMarker(
    latitud,
    longitud,
    radius,
    map,
    click,
    label,
    focusMap = true,
  ) {
    if (validateMaxLocations()) {
      if (props.markerWasSelected) {
        props.setMarkerWasSelected(false);
      } else {
        let locations = [];
        let locationId = 0;
        let selectedLocationIndex = undefined;

        if (props.locations) locations = props.locations;
        if (props.locationId !== undefined) {
          locationId = props.locationId + 1;
          if (click) props.setLocationId(locationId);
        }

        if (!click) {
          if (locations.length > 0) {
            if (props.locationSelected) {
              locationId = props.locationSelected;
            }
            let find = locations.findIndex(function (item, i) {
              return item.locationId === locationId;
            });
            if (find > -1) {
              selectedLocationIndex = find;
              if (locations[selectedLocationIndex].marker) {
                locations[selectedLocationIndex].marker.setMap(null);
              }
              if (locations[selectedLocationIndex].circle) {
                locations[selectedLocationIndex].circle.setMap(null);
              }
              latitud = locations[selectedLocationIndex].position.lat;
              longitud = locations[selectedLocationIndex].position.lng;
            }
          }
        }

        let newLocation = {
          locationId: locationId,
          label: locationId,
          text: 'lat: ' + latitud + ', lng: ' + longitud,
          type: label ? 'Radio: ' + label : 'Radio: ' + props.radius[0].label,
          fbType: 'position',
          position: { lat: latitud, lng: longitud },
          radius: radius,
          createCircle: true,
          createMarker: true,
          include: true,
        };
        if (selectedLocationIndex !== undefined)
          locations[selectedLocationIndex] = newLocation;
        else locations.push(newLocation);
        props.setLocations(locations);

        changeCenter(
          {
            position: { lat: latitud, lng: longitud },
            fbType: 'position',
            locationId,
            radius,
          },
          locations,
          map,
          focusMap,
          click,
        );
      }
    }
  }
  function resetLocations(isMap) {
    props.setLocationId(0);
    props.setLocations([]);
    props.changeIsGeolocation(!isMap);
    props.changeIsMap(isMap);
    props.changeGeoTargetingSearchText('');
    props.changeGeoTargetingSelected([]);
    props.changeGeoTargetingSelector([]);
  }
  function changeCheckBox(isMap) {
    if (props.locations && props.locations.length > 0) {
      alertConfirmPopUp(
        'Se reiniciarán las locaciones ingresadas, ¿Desea Continuar?',
        props,
        () => {
          resetLocations(isMap);
        },
      );
    } else {
      resetLocations(isMap);
    }
  }

  /* SO MANY THINGS WRONG WITH THIS APPROACH */
  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        {/* NEXT WIDGET CONTAINS THE "PICKER" */}
        <Widget
          focusable={props.focusable}
          title={
            props.isGeolocation ? trans('PLACES', props.lang) : props.isMap ? trans('MAP', props.lang) : trans('MAP', props.lang)
          }
          title2={
            props.savedAudienceSelected ? (
              ''
            ) : (
              <Grid container>
                {/* SHOWS ERROS */}
                {props.componentInfo.getError(props) && props.showAdErrors ? (
                  <Grid item xs={12} lg={12} md={12}>
                    <div style={{ color: 'red' }}>
                      <div className={classes.errorIcon}>
                        <ErrorIcon></ErrorIcon>
                      </div>
                      <div className={classes.warningMessage}>
                        {props.componentInfo.errorMessage}
                      </div>
                    </div>
                  </Grid>
                ) : (
                  ''
                )}

                <Grid item xs={12} lg={12} md={12}>
                  <div style={{ float: 'right', borderBottomStyle: 'groove' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.isGeolocation}
                          onChange={(event, checked) => {
                            changeCheckBox(!checked);
                          }}
                        ></Checkbox>
                      }
                      label={trans('PLACE_PICKER_LABEL1', props.lang)}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.isMap}
                          onChange={(event, checked) => {
                            changeCheckBox(checked);
                          }}
                        ></Checkbox>
                      }
                      label={trans('MAP', props.lang)}
                    />
                  </div>
                </Grid>
              </Grid>
            )
          }
          bodyClass={classes.tableWidget}
          disableWidgetMenu={true}
        >
        { /* HERE IS WHERE DISPLAYS THE GEO LOCATIONS OF THE SAVED AUDIENCES (INSIDE THE MAPPICKER!!!) */}
        {props.savedAudienceSelected ? (
          <>
            <SavedAudienceMessage
              lang={props.lang}
              audiences={props.selectedSavedAudiences}
            >
                <ComLocsFromSavedAudiences  lang={props.lang}
              audiences={props.selectedSavedAudiences} />
            </SavedAudienceMessage>         
          </>
        ) : props.isMap ? (
            <div>
              <Typography>Radio</Typography>
              <Select
                maxMenuHeight={200}
                id={'Radius'}
                isMulti={false}
                value={props.radius}
                placeholder={'Seleccione Radio'}
                options={props.radiusList}
                onChange={e => {
                  props.changeRadius([e]);
                  let selectedLocation = getSelectedLocationObject();
                  if (selectedLocation !== undefined)
                    renderMarker(
                      selectedLocation.position.lat,
                      selectedLocation.position.lng,
                      e.value,
                      props.map,
                      false,
                      e.label,
                    );
                }}
              ></Select>

              <div style={{ height: '10px' }}></div>
              <Grid>
                <div style={{ height: '50vh', width: '100%', readOnly: true }}>
                  <Focusable
                    id={props.focusId + 'Map'}
                    refList={props.refList}
                  ></Focusable>
                  <GoogleMapReact
                    onChange={e => {
                      if (props.map) {
                        if (!props.size) props.setSize(e.size);
                        else {
                          if (
                            e.size.width < window.screen.width ||
                            e.size.height < window.screen.height
                          ) {
                            if (
                              e.size.width !== props.size.width ||
                              e.size.height !== props.size.height
                            ) {
                              props.setSize(e.size);
                              props.setStyle();
                              //refreshCustomPage(props.setRefresh, 0, 0);
                            }
                          } else {
                            props.setSize(e.size);
                            props.setStyle({
                              position: 'fixed',
                              left: 0,
                              top: 0,
                            });
                          }
                        }
                      }
                    }}
                    onClick={e => {
                      renderMarker(
                        e.lat,
                        e.lng,
                        props.radius.length > 0 ? props.radius[0].value : 1000,
                        props.map,
                        true,
                      );
                    }}
                    bootstrapURLKeys={{
                      key:
                        Environment === 'DEV'
                          ? 'AIzaSyDl_L2Hy-HZbkMEafwF3wB-KaXJoM7qmJs'
                          : 'AIzaSyDC9hwIidJict6DNKvgPVf87kSwIdPgA_0',
                      //"AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg",
                      //key: "AIzaSyDC9hwIidJict6DNKvgPVf87kSwIdPgA_0",
                      //key: "AIzaSyDl_L2Hy-HZbkMEafwF3wB-KaXJoM7qmJsSJJA",
                    }}
                    //defaultCenter={{lat:14.60, lng:-90.54}}
                    resetBoundsOnResize={true}
                    center={
                      getSelectedLocationObject('position')
                        ? getSelectedLocationObject('position').position
                        : { lat: props.initLat, lng: props.initLng }
                    }
                    zoom={props.zoom}
                    onGoogleApiLoaded={({ map, maps }) => {
                      if (props.locations && props.locations.lenght === 0) {
                        props.setMap(map);
                        renderMarker(
                          props.initLat,
                          props.initLng,
                          props.radius.length > 0
                            ? props.radius[0].value
                            : 1000,
                          map,
                          true,
                          undefined,
                          false,
                        );
                      } else {
                        props.setMap(map);
                        refreshMarkers(props.locations, map);
                      }
                    }}
                    yesIWantToUseGoogleMapApiInternals={true}
                    fullscreenControl={true}
                    style={props.style}
                  ></GoogleMapReact>
                </div>
                <Grid container style={{ paddingTop: '10px' }} spacing={1}>
                  <Grid item xs={12} lg={5} md={5}>
                    <TextField
                      disabled={false}
                      style={{ width: '90%' }}
                      id={'Latitud_Text'}
                      value={props.manualLat}
                      onChange={e => {
                        if (
                          e.target.value.length &&
                          e.target.value.length > 0 &&
                          e.target.value.includes(',')
                        ) {
                          let locations = e.target.value.split(',');
                          if (locations.length > 0) {
                            props.changeManualLat(locations[0].trim());
                            props.changeManualLng(locations[1].trim());
                          } else props.changeManualLat(e.target.value);
                        } else props.changeManualLat(e.target.value.trim());
                      }}
                      variant="outlined"
                      label="Latitud"
                      onWheel={event => {
                        event.target.blur();
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={5} md={5}>
                    <TextField
                      disabled={false}
                      style={{ width: '90%' }}
                      id={'Longitud_Text'}
                      value={props.manualLng}
                      onChange={e => {
                        props.changeManualLng(e.target.value.trim());
                      }}
                      variant="outlined"
                      label="Longitud"
                      onWheel={event => {
                        event.target.blur();
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} lg={2} md={2}>
                    <div style={{ paddingTop: '10px', width: '50%' }}>
                      <Button
                        disabled={
                          props.manualLat.length === 0 ||
                          props.manualLng.length === 0
                        }
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={() => {
                          if (isNumeric(props.manualLat)) {
                            if (isNumeric(props.manualLng)) {
                              renderMarker(
                                parseFloat(props.manualLat),
                                parseFloat(props.manualLng),
                                props.radius.length > 0
                                  ? props.radius[0].value
                                  : 1000,
                                props.map,
                                true,
                              );
                              props.changeManualLat('');
                              props.changeManualLng('');
                            } else
                              alertPopUp(
                                'Longitud debe de ser númerico',
                                props,
                                true,
                              );
                          } else
                            alertPopUp(
                              'Latitud debe de ser númerico',
                              props,
                              true,
                            );
                        }}
                      >
                        Agregar
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ) : props.isGeolocation ? (
            <div>
              <div>
                <div
                  style={
                    getWidthCode() === 'xs'
                      ? { borderBottomStyle: 'groove' }
                      : {}
                  }
                >
                  <FormControl component="fieldset" style={{ width: '100%' }}>
                    <RadioGroup
                      row={true}
                      aria-label="geoLocation"
                      name="geoLocation"
                      value={props.geoTargetingSearch}
                      onChange={(e, value) => {
                        props.changeGeoTargetingSearchText('');
                        props.changeGeoTargetingSelected([]);
                        props.changeGeoTargetingSelector([]);
                        props.setGeoTargetingSearch(value);
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={4} md={2}>
                          <FormControlLabel
                            value={'1'}
                            control={<Radio />}
                            label={trans('COUNTRY', props.lang)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                          <FormControlLabel
                            value={'2'}
                            control={<Radio />}
                            label={trans('REGION', props.lang)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                          <FormControlLabel
                            value={'3'}
                            control={<Radio />}
                            label={trans('CITY', props.lang)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                          <FormControlLabel
                            value={'4'}
                            control={<Radio />}
                            label={trans('ZIP', props.lang)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                          <FormControlLabel
                            value={'5'}
                            control={<Radio />}
                            label={trans('ALL', props.lang)}
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </div>
                <Grid container spacing={2} style={{ paddingTop: '25px' }}>
                  <Grid item xs={12} sm={9} md={9} lg={10}>
                    <TextField
                      value={props.geoTargetingSearchText}
                      disabled={
                        (props.geoTargetingSearch &&
                          props.geoTargetingSearch.length === '') ||
                        !props.geoTargetingSearch
                      }
                      placeholder={
                        (props.geoTargetingSearch &&
                          props.geoTargetingSearch.length === '') ||
                        !props.geoTargetingSearch
                          ? 'Seleccione Tipo de Segmentación por Lugar'
                          : trans('SEARCH', props.lang) + "..."
                      }
                      style={{ width: '100%' }}
                      onChange={e => {
                        props.changeGeoTargetingSearchText(e.target.value);
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    lg={2}
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Button
                      disabled={
                        (props.geoTargetingSearch &&
                          props.geoTargetingSearch.length === '') ||
                        !props.geoTargetingSearch
                      }
                      fullWidth={true}
                      variant="contained"
                      size="medium"
                      color="primary"
                      onClick={() => {
                        getGeoLocation(props);
                      }}
                    >
                      {trans('SEARCH', props.lang)}
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <Typography>Geotargeting</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9} md={9} lg={10}>
                  <Select
                    maxMenuHeight={150}
                    id={'fbGeoTargeting'}
                    styles={theme.reactSelect}
                    isMulti={false}
                    value={props.geoTargetingSelected}
                    placeholder={
                      props.geoTargetingSelector.length === 0
                        ? ''
                        : trans('PLACE_PICKER_LABEL1', props.lang)
                    }
                    options={props.geoTargetingSelector}
                    disabled={props.geoTargetingSelector.length === 0}
                    onChange={e => {
                      props.changeGeoTargetingSelected([e]);
                    }}
                  ></Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={2}
                  style={{ float: 'right' }}
                >
                  <Button
                    fullWidth={true}
                    disabled={props.geoTargetingSelected.length === 0}
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() => {
                      addGeoLocation();
                    }}
                  >
                    {trans('ADD', props.lang)}
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            ''
          )}
          {props.savedAudienceSelected ? (
            ''
          ) : (
            <div style={{ overflow: 'hidden' }}>
              <EnhancedTable
                classes={classes}
                lang={props.lang}
                changeRowsPerPage={props.changeRowsPerPage}
                deleteFunction={removeLocation}
                updateTableProducts={props.updateTableProducts}
                mainId="locationId"
                changeRefresh={props.changeRefresh}
                refresh={props.refresh}
                selectAll={false}
                rows={props.locations}
                changeList={props.setLocations}
                ignoreTableClass={true}
                resizeWidthCodes={['xs', 'xs-sm', 'sm']}
                resizeCellOnclick={changeCenter}
                headCells={[
                  {
                    id: 'label',
                    label: trans('ID', props.lang),
                    onClick: changeCenter,
                    center: true,
                  },

                  {
                    id: 'text',
                    onClick: changeCenter,
                    label: trans('VALUE', props.lang),
                    center: true,
                  },
                  {
                    id: 'tipo',
                    onClick: changeCenter,
                    label: trans('TYPE', props.lang),
                    center: true,
                  },
                  {
                    id: 'eliminarLocation',
                    label: trans('DELETE', props.lang),
                    center: true,
                  },
                ]}
                page={props.pagePagination}
                setPage={props.changePagePagination}
              ></EnhancedTable>
            </div>
          )}
          <div style={{ height: '50px' }}></div>
        </Widget>
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(View);
