export function filteredProductsByCompany(Products, selectedCompanies) {
  let filtered = [];
  //if (props.changePagePagination) props.changePagePagination(0);
  Products.forEach(element => {
    if (element.Company === selectedCompanies[0].value) filtered.push(element);
  });
  return filtered;
}

export function filteredProductsByBrand(
  Products,
  selectedCompanies,
  selectedBrands,
) {
  let filtered = [];
  //if (props.changePagePagination) props.changePagePagination(0);
  Products.forEach(element => {
    if (
      element.Company === selectedCompanies[0].value &&
      element.Brand === selectedBrands[0].value
    )
      filtered.push(element);
  });
  return filtered;
}

export function getWidthCode(between = false) {
  let width = getWidth();
  if (!between) {
    if (width < 600) return 'xs';
    else if (width < 960) return 'sm';
    else if (width < 1280) return 'md';
    else return 'lg';
  } else {
    if (width < 600) return 'xs';
    else if (width < 780) return 'xs-sm';
    else if (width < 960) return 'sm';
    else if (width < 1120) return 'sm-md';
    else if (width < 1280) return 'md';
    else return 'lg';
  }
}
export function getWidth() {
  return document.documentElement.clientWidth;
}

export function getHeight() {
  return document.documentElement.clientHeight;
}

export function getScreenOrientation() {
  if (window.matchMedia('(orientation: portrait)').matches) {
    return 'portrait';
  }

  if (window.matchMedia('(orientation: landscape)').matches) {
    return 'landscape';
  }
}
export function showChangeToLandscape() {
  if (getWidth() >= 405) return false;
  else if (getWidth() > getHeight()) return false;
  else if (getHeight() >= 405 && getScreenOrientation() === 'portrait')
    return true;
  else return false;
}
