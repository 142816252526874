import React, { useEffect } from 'react';
import { Grid, CssBaseline, MuiThemeProvider, Box } from '@material-ui/core';
import { Form } from 'formik';
import TopBar from '../../components/TopBar';
import Welcome from '../../components/Welcome';
import { TopStepper } from '../../components/TopStepper';
import { theme } from './theme';
import { OnboardingWrapper, useOnboardingContext } from './context';
import { useIntercom } from 'react-use-intercom';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { PaymentSuccess } from '../../components/PaymentSuccess';

const hideHubspot = () => {
  // @ts-ignore
  setTimeout(() => window.HubSpotConversations.widget.remove(), 2000);
};

const View = () => {
  const classes = useStyles();
  const { steps, activeStep, isProcessComplete } = useOnboardingContext();

  const Component = steps[activeStep].component;

  const { shutdown } = useIntercom();

  useEffect(() => {
    shutdown();
    hideHubspot();
  }, [shutdown]);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopBar />
      <Grid container spacing={0} className={classes.content}>
        <div className={classes.fakeToolbar} />
        {!isProcessComplete && (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Welcome />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TopStepper activeStep={activeStep} steps={steps} />
            </Grid>
          </>
        )}
        <Box display="flex" justifyContent="center" alignItems="center">
          <Grid item xs={12} md={10} lg={10}>
            <Form autoComplete="off">
              {isProcessComplete ? (
                <PaymentSuccess />
              ) : (
                <Component key={activeStep} />
              )}
            </Form>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

const ViewComponent = (props: any) => {
  return (
    <OnboardingWrapper>
      <MuiThemeProvider theme={theme}>
        <View {...props} />
      </MuiThemeProvider>
    </OnboardingWrapper>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      maxWidth: '100vw',
      overflowX: 'hidden',
    },
    fakeToolbar: {
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      width: `calc(100vw)`,
      minHeight: '100vh',
      display: 'block',
      backgroundColor: '#fdf9f9',
    },
    errorMessage: {
      backgroundColor: theme.palette.error.dark,
      margin: theme.spacing(1),
    },
    infoMessage: {
      color: '#9F6000',
      backgroundColor: '#FEEFB3',
      margin: theme.spacing(1),
    },
    textField: {
      justifyContent: 'space-between',
      margin: theme.spacing(1),
      width: 500,
      borderColor: '#013f8a',
    },
    margin: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 10,
      margin: theme.spacing(3),
      alignText: 'center',
      width: 400,
    },
    fanButton: {
      float: 'right',
      display: 'flex',
      flexWrap: 'wrap',
      padding: 10,
      margin: theme.spacing(1),
      alignText: 'center',
    },
    fab: {
      position: 'absolute',
      right: theme.spacing(2),
      bottom: theme.spacing(0),
    },
    tableWidget: {
      overflowX: 'visible',
    },
    datePicker: {
      overflowX: 'visible',
      padding: theme.spacing(2),
      margin: 'auto',
    },
    errorIcon: {
      fontSize: '1.5rem',
      height: '1.5rem',
      width: '1.5rem',
      float: 'left',
    },
    warningMessage: {
      fontSize: '0.9rem',
      height: '1.5rem',
      paddingTop: '0.3rem',
      float: 'left',
    },
    button: { textTransform: 'none' },
  }),
);

export default ViewComponent;
