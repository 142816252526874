import React from 'react';
import classnames from 'classnames';
import { Grid, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/es/Typography/Typography';

const Widget = ({
  classes,
  children,
  title,
  noBodyPadding,
  bodyClass,
  className,
  sidebutton,
  title2,
  focusable,
  overflow,
  noBodyClass,
  ...props
}) => (
  <div className={classes.widgetWrapper}>
    <Grid
      style={
        overflow ? { overflow: 'visible', width: '100%' } : { width: '100%' }
      }
    >
      <div className={classes.widgetHeader}>
        {props.header ? (
          props.header
        ) : (
          <React.Fragment>
            <Typography variant="h5" color="primary">
              {title}
            </Typography>

            <div>
              {focusable}
              {sidebutton}
            </div>
          </React.Fragment>
        )}
      </div>

      <div
        className={
          !noBodyClass
            ? classnames(classes.widgetBody, {
                [classes.noPadding]: noBodyPadding,
                [bodyClass]: bodyClass,
              })
            : ''
        }
      >
        {title2}
        {children}
      </div>
    </Grid>
  </div>
);

const styles = theme => ({
  widgetWrapper: {
    display: 'flex',
    minHeight: '100%',
    width: '100%',
  },
  widgetHeader: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  widgetRoot: {
    boxShadow: theme.customShadows.widget,
  },
  widgetBody: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },

  paper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  },
  moreButton: {
    margin: -theme.spacing(1),
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.text.hint,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'rgba(255, 255, 255, 0.35)',
    },
  },
});

export default withStyles(styles, { withTheme: true })(Widget);
