import {
  serviceUnavailableHttpError,
  unauthorizedHttpError,
  noContentHttpCode,
  facebookGasHttpErrorCode,
} from '../components/Constants';

import Auth from '../authentication/Auth';

export function getLocalStorageNonce() {
  let nonce = undefined;
  if (sessionStorage.nonce !== undefined) {
    nonce = sessionStorage.nonce;
  }
  if (nonce === undefined && localStorage.nonce !== undefined) {
    nonce = localStorage.nonce;
  }

  return nonce;
}
export function createHeaders() {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('access_token')}`,
  );
  myHeaders.append('nonce', getLocalStorageNonce());
  return myHeaders;
}

export async function doFetch(
  url,
  requestInit,
  funtionForResult,
  paramsFunctionResult,
  props,
  updateSelected,
  // ,	layoutLoading
) {
  // loading has nothing to do with fetching
  /*
	if (layoutLoading) {
		if (props.changeIsLoadingSideBar) props.changeIsLoadingSideBar(false);
		if (props.changeIsLoadingLayout) props.changeIsLoadingLayout(true);
	} else {
		if (props.changeIsLoadingSideBar && !props.isFirstLoad)
			props.changeIsLoadingSideBar(true);
	}
    */
  let status = 400;

  try {
    await fetch(url, requestInit).then(result => {
      if (result) {
        status = result.status;
        if (result.status === unauthorizedHttpError) {
          let auth = new Auth();
          result.json().then(resultjson => {
            auth.sessionExpired();
          });
        } else if (result.status === serviceUnavailableHttpError) {
          funtionForResult([], paramsFunctionResult, props, updateSelected, -1);
        } else if (result.status === noContentHttpCode) {
          funtionForResult([], paramsFunctionResult, props, updateSelected, -1);
        } else if (result.status === facebookGasHttpErrorCode) {
          /*alertPopUp(
						"Se ha llegado al limite de las llamadas a Facebook para la Cuenta Publicitaria",
						props,
						true
					);*/
          props.changeFacebookNoGas(true);
          funtionForResult([], paramsFunctionResult, props, updateSelected, -1);
        } else
          result
            .json()
            .then(resultjson => {
              if (funtionForResult) {
                if (!resultjson.payload) resultjson.payload = [];
                funtionForResult(
                  resultjson,
                  paramsFunctionResult,
                  props,
                  updateSelected,
                  status,
                );
              }
              /* else if (layoutLoading) {
								if (props.changeIsLoadingLayout)
									props.changeIsLoadingLayout(true);
							} else if (props.changeIsLoadingSideBar)
								props.changeIsLoadingSideBar(true);
                            */
            })
            .catch(error => {
              console.log(error);
              if (!window.isReloading) {
                /*
								alertPopUp(
									"Hubo un error en la solicitud realizada, revisar conexión a Internet y pruebe nuevamente",
									props,
									true
								);
								if (props.changeIsLoadingLayout)
									props.changeIsLoadingLayout(false);

								if (props.changeLoadingMessage) {
									props.changeLoadingMessage("");
								}

								funtionForResult(
									[],
									paramsFunctionResult,
									props,
									updateSelected,
									-1
								);
                                */
              }
            });
      } else {
        funtionForResult(
          [],
          paramsFunctionResult,
          props,
          updateSelected,
          status,
        );
      }
    });
  } catch (err) {
    console.log(err);
    if (!window.isReloading) {
      /*alertPopUp(
				"Hubo un error en la solicitud realizada, revisar conexión a Internet y pruebe nuevamente",
				props,
				true
			);*/
      /*if (props.changeLoadingMessage) {
				props.changeLoadingMessage("");
			}
			if (props.changeIsLoadingLayout) props.changeIsLoadingLayout(false);
			funtionForResult([], paramsFunctionResult, props, updateSelected, -1);
            */
    }
  }
}
