import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import Widget from '../../../components/Widget';
import Select from 'react-select';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { appInstall } from '../../../components/Constants';
import trans from './../../../i18n/tran';

const Application = ({ classes, theme, onChange, value, ...props }) => {
  function getAppStores(stores) {
    let appObjectStore = [];
    // remove map and replace it with foreach
    appInstall.foreach(store => {
      if (stores[store.value]) {
        if (
          store.value !== 'instant_game' ||
          (store.value === 'instant_game' &&
            props.providers.includes('facebook') &&
            props.selectedFacebookPositions.includes('feed'))
        )
          appObjectStore.push({
            value: stores[store.value],
            label: store.label,
            store: store.value,
          });
      }
    });

    props.changeSelectorAppStore(appObjectStore);
  }
  return (
    <Widget
      overflow={true}
      focusable={
        <Focusable id={props.focusId} refList={props.refList}></Focusable>
      }
      title={
        <div>
          <div style={{ height: '1.5rem' }}>
            <div>{trans('APP', props.lang)}</div>
            {props.hasErrorApplication && props.showAdErrors ? (
              <div
                style={{
                  color: 'red',
                }}
              >
                <div className={classes.errorIcon}>
                  <ErrorIcon></ErrorIcon>
                </div>
                <div className={classes.warningMessage}>
                  {props.selectedApplications.length === 0
                    ? trans('NEW_CAMPAIGN_APP_SELECT', props.lang)
                    : trans('NEW_CAMPAIGN_APP_STORE_SELECT', props.lang)}
                </div>
              </div>
            ) : (
              ''
            )}
            <br />
          </div>
        </div>
      }
      bodyClass={classes.tableWidget}
      disableWidgetMenu={true}
    >
      <div style={{ padding: '50px 0px' }}>
        <div style={{ width: '100%' }}>
          <Typography>{trans('APP', props.lang)}</Typography>
          <Select
            id={'Aplicacion'}
            options={props.selectorApplication}
            value={props.selectedApplications}
            styles={theme.reactSelect}
            onChange={e => {
              props.changeHasErrorApplication(true);
              props.changeSelectedApplications([e]);
              //props.changeSelectorAppStore([e.store])
              props.changeSelectedAppStores([]);
              getAppStores(e.store);
            }}
            isDisabled={props.selectedAdminAdAccounts.length === 0}
            placeholder={
              props.selectedAdminAdAccounts.length === 0
                ? trans('SELECT_AD_ACCOUNT', props.lang)
                : trans('NEW_CAMPAIGN_APP_SELECT', props.lang)
            }
          ></Select>
        </div>
        <div style={{ width: '100%' }}>
          <Typography>{trans('APP_STORE', props.lang)}</Typography>
          <Select
            id={'UrlStore'}
            options={props.selectorAppStore}
            value={props.selectedAppStores}
            styles={theme.reactSelect}
            onChange={e => {
              props.changeHasErrorApplication(false);
              props.changeSelectedAppStores([e]);
              props.changeAdLinkPreview(e.value);
            }}
            isDisabled={props.selectedAdminAdAccounts.length === 0}
            placeholder={
              props.selectedAdminAdAccounts.length === 0
                ? trans('SELECT_AD_ACCOUNT', props.lang)
                : props.selectedApplications.length === 0
                ? trans('NEW_CAMPAIGN_APP_STORE_SELECT', props.lang)
                : trans('NEW_CAMPAIGN_APP_STORE_SELECT', props.lang)
            }
          ></Select>
        </div>
      </div>
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(Application);
