import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import Widget from '../../../components/Widget/WidgetContainer';
import Disclaimer from '../../../components/Disclaimer/Disclaimer';
import { DateDiffMinutes } from '../../../utils/dates';
import { showSessionWillExpireBeforeMinutes } from '../../../components/Constants';

const SessionWarning = ({ classes, theme, ...props }) => {
  let showWarning;
  let message;
  if (props.aly_expires_At) {
    // <- pure Date type
    let now = new Date(); // <- pure Date type
    let diff = DateDiffMinutes(props.aly_expires_At, now);
    if (diff && diff < showSessionWillExpireBeforeMinutes) {
      showWarning = true;
      message = `La sesión expira en ${diff} minutos`;
    }
  }
  return (
    <React.Fragment>
      {showWarning && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
            <Widget
              overflow={true}
              title={<div>Sesión</div>}
              bodyClass={classes.tableWidget}
              disableWidgetMenu={true}
            >
              <Disclaimer message={message} />
            </Widget>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const styles = theme => ({
  margin: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
    width: 400,
  },
  warningMessage: {
    fontSize: '0.9rem',
    height: '1.5rem',
    paddingTop: '0.3rem',
    float: 'left',
  },
});

export default withStyles(styles, { withTheme: true })(SessionWarning);
