import React, { useEffect } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { ReactComponent as Rocket } from '../../assets/svg/rocket.svg';
import { useOnboardingContext } from '../pages/onboarding/context';

import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from '../pages/onboarding/utils';
export const PaymentSuccess = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setNavBackButtonVisible } = useOnboardingContext();
  useEffect(
    () => {
      setNavBackButtonVisible(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { width } = useWindowDimensions();

  return (
    <Grid className={classes.root}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={3} md={3}>
          <Typography className={classes.title}>
            {t('Payment has been processed')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={3} md={3}>
          <Typography className={classes.description}>
            {t('We sent you and email with a confirmation')}
          </Typography>
        </Grid>

        <Grid container style={{ width, marginTop: '32px' }}>
          <Rocket style={{ width: 'inherit', height: 'inherit' }} />
        </Grid>
      </Grid>

      <Button
        component={Link}
        to="/"
        className={classes.button}
        fullWidth
        color="primary"
        type="submit"
        variant="contained">
        {t("Let's launch our first campaign")}
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 64,
      marginBottom: 20,
    },
    button: { textTransform: 'none', height: 38, marginTop: 56 },
    title: {
      fontFamily: 'Montserrat-Semibold',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'center',
    },
    description: {
      fontFamily: 'Montserrat-Regular',
      fontSize: '12px',
      lineHeight: '16px',
      textAlign: 'center',
    },
    rowWithButton: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    field: {
      marginBottom: '16px',
    },
    labelField: {
      fontFamily: 'Montserrat-Medium',
      fontSize: 13,
      marginBottom: 4,
    },
    labelFieldMandatory: {
      fontFamily: 'Montserrat-Medium',
      fontSize: 19,
      color: 'red',
    },
  }),
);
