import React from 'react';
import { Grid, withStyles, Fab, Typography } from '@material-ui/core';
import Widget from '../../components/Widget';
import PageTitle from '../../components/PageTitle';
import Button from '@material-ui/core/Button';
import TextFieldList from './component/TextFieldListView';
import Select from 'react-select';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import Focusable, { changeFocus } from '../createad/Focusable';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import { updateSelector, getBrandApiAdmin } from '../../utils/functions';
import {
  adAccountSelectorId,
  companySelectorId,
  brandSelectorId,
  GAEventCategoryProduct,
  GAEventActionProduct,
  sessionStorageUTMValue,
  productProductGA,
} from '../../components/Constants';
import { returnToModifyPage } from './Container';
import trans from '../../i18n/tran';
import {
  getGASessionItem,
  setGASessionItem,
  sendGAEvent,
} from '../../utils/ga';

const View = ({ classes, theme, onChange, value, ...props }) => {
  let fanPageFocusId = 'fanPageFocusId';
  let titleFocusId = 'titleFocusId';
  let descriptionFocusId = 'descriptionFocusId';
  let adAccountFocusId = 'adAccountFocusId';
  let blankSpaceV = false;

  function addValueToSelector(
    tempValue,
    selector,   // not selector but selector values
    changeselector,
    changeselected,
    changeHasError,
  ) {
    let newValue = {
      label: tempValue,
      value: tempValue,
    };

   const found = selector.find( x=> x.value === newValue.value);
    if (!found) {
        selector.push(newValue);
        changeselector(selector);
    }
  
    changeselected([newValue]);
    changeHasError(false);
  }

  return (
    <React.Fragment>
      {props.isDialog ? (
        <Button
          id="Create-Product"
          variant="contained"
          size="small"
          className={classes.Button}
          onClick={() => {
            returnToModifyPage(props);
            props.changeShowCreateProduct(false);
          }}
        >
          <ArrowBackIcon></ArrowBackIcon>
        </Button>
      ) : (
        ''
      )}
      {props.modifyProduct ? (
        <PageTitle title={trans('PRODUCT_MODIFY', props.lang)} />
      ) : (
        <PageTitle title={trans('PRODUCT_CREATE', props.lang)} />
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={6}>
          <TextFieldList
            showAdErrors={props.showErrors}
            ErrorIconClass={classes.errorIcon}
            ErrorMessageClass={classes.warningMessage}
            reset={props.reset}
            changeReset={props.changeReset}
            focusable={
              <Focusable id={titleFocusId} refList={props.refList}></Focusable>
            }
            hasInitValue={true}
            initListValue={props.initTitle}
            changeInitList={props.changeInitTitle}
            id="Title"
            widgetTitle={trans('TITLE', props.lang)}
            maxTextSize={64}
            maxTextSizeMessage={trans(
              'PRODUCT_FORM_TITLE_VALIDATION1',
              props.lang,
            )}
            maxListSize={1}
            ErrorIcon={<ErrorIcon />}
            WarningIcon={<WarningIcon />}
            minTextSize={1}
            minTextSizeMessage={trans(
              'PRODUCT_FORM_TITLE_VALIDATION2',
              props.lang,
            )}
            blankSpace = {true}
            placeholder={trans('PRODUCT_FORM_TITLE_PLACEHOLDER', props.lang)}
            multiline={false}
            variant="outlined"
            changeError={props.changeHasErrorTitle}
            Error={props.hasErrorTitle}
            list={props.adTitle}
            changeList={props.changeAdTitle}
            textFieldVariant="title"
            modifyProduct={props.modifyProduct}
          ></TextFieldList>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <TextFieldList
            reset={props.reset}
            changeReset={props.changeReset}
            focusable={
              <Focusable
                id={descriptionFocusId}
                refList={props.refList}
              ></Focusable>
            }
            hasInitValue={true}
            initListValue={props.initDescription}
            changeInitList={props.changeInitDescription}
            id="Description"
            widgetTitle={trans('DESCRIPTION', props.lang)}
            maxTextSize={250}
            maxTextSizeMessage={trans(
              'PRODUCT_FORM_DESC_VALIDATION1',
              props.lang,
            )}
            maxListSize={1}
            ErrorIcon={<ErrorIcon />}
            WarningIcon={<WarningIcon />}
            minTextSize={0}
            placeholder={trans('PRODUCT_FORM_DESC_PLACEHOLDER', props.lang)}
            multiline={true}
            variant="outlined"
            changeError={props.changeHasErrorDescription}
            Error={props.hasErrorDescription}
            list={props.adDescription}
            changeList={props.changeAdDescription}
            textFieldVariant="description"
          ></TextFieldList>
        </Grid>
        {/* General Information */}
        <Grid item xs={12} md={12} lg={12}>
          <Widget
            overflow={true}
            focusable={
              <Focusable
                id={adAccountFocusId}
                refList={props.refList}
              ></Focusable>
            }
            title={trans('PRODUCT_FORM_LABEL1', props.lang)}
            bodyClass={classes.tableWidget}
            disableWidgetMenu={true}
          >
            <div>
              {/* Ad Account */}
              {props.hasErrorAdAccount && props.showErrors ? (
                <div style={{ color: 'red' }}>
                  <div className={classes.errorIcon}>
                    <ErrorIcon></ErrorIcon>
                  </div>
                  {trans('PRODUCT_FORM_AD_ACCOUNT_PLACEHOLDER', props.lang)}
                </div>
              ) : props.hasErrorCompany && props.showErrors ? (
                <div style={{ color: 'red' }}>
                  <div className={classes.errorIcon}>
                    <ErrorIcon></ErrorIcon>
                  </div>
                  {trans('PRODUCT_FORM_COM_PLACEHOLDER', props.lang)}
                </div>
              ) : props.hasErrorBrand && props.showErrors ? (
                <div style={{ color: 'red' }}>
                  <div className={classes.errorIcon}>
                    <ErrorIcon></ErrorIcon>
                  </div>
                  {trans('PRODUCT_FORM_BRAND_PLACEHOLDER', props.lang)}
                </div>
              ) : (
                ''
              )}
              <Typography>
                {trans('PRODUCT_FORM_AD_ACCOUNT', props.lang)}
              </Typography>
              <Select
                styles={theme.reactSelect}
                id={adAccountSelectorId}
                isDisabled={
                  props.selectorAdminAdAccount.length === 0 ||
                  (props.isDialog && !props.initModifyAdAccount)
                }
                placeholder={
                  props.selectedTenants.length === 0
                    ? trans('PRODUCT_FORM_ACCOUNT_PLACEHOLDER', props.lang)
                    : props.selectorAdminAdAccount.length === 0
                    ? trans('PRODUCT_FORM_AD_ACCOUNT_NOTFOUND', props.lang)
                    : trans('PRODUCT_FORM_AD_ACCOUNT_PLACEHOLDER', props.lang)
                }
                isMulti={true}
                value={props.selectedAdminAdAccounts}
                options={props.selectorAdminAdAccount}
                onChange={e => {
                  updateSelector(
                    true,
                    props.changeSelectedAdminAdAccounts,
                    props.selectedAdminAdAccounts,
                    e,
                    props.selectorAdminAdAccount,
                    adAccountSelectorId,
                    props,
                    [],
                    'selectedAdminAdAccounts',
                    true,
                  );
                  props.changeHasErrorAdAccount(false);
                }}
              ></Select>
              {/* Company */}
              <Typography>{trans('COMPANY', props.lang)}</Typography>
              <div style={{ width: '98%', float: 'left' }}>
                <Select
                  styles={theme.reactSelect}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      // TODO: this functionality throws an error, meaning doesn't work as expected
                      let contains = false;
                      props.selectorCreateProductCompany.forEach(element => {
                        if (element.value.includes(props.tempCompany))
                          contains = true;
                      });
                      if (!contains)
                        addValueToSelector(
                          props.tempCompany,
                          props.selectorCreateProductCompany,
                          props.changeSelectorCreateProductCompany,
                          props.changeSelectedCreateProductCompanies,
                          props.changeHasErrorCompany,
                        );
                    }
                  }}
                  onInputChange={(e, e2) => {
                    if (e2.action && e2.action === 'input-change') {
                      props.changeTempCompany(e);
                    }                      
                  }}
                  id={companySelectorId}
                  placeholder={
                    props.selectedTenants.length === 0
                      ? trans('PRODUCT_FORM_ACCOUNT_PLACEHOLDER', props.lang)
                      : props.selectorCreateProductCompany.length === 0
                      ? trans('PRODUCT_FORM_BRAND_NOTFOUND', props.lang)
                      : trans('PRODUCT_FORM_COM_PLACEHOLDER', props.lang)
                  }
                  isMulti={false}
                  value={props.selectedCreateProductCompanies}
                  options={props.selectorCreateProductCompany}
                  onChange={e => {
                    getBrandApiAdmin(props, props.selectedTenants, [e]);
                    props.changeSelectedCreateProductCompanies([e]);
                    props.changeHasErrorCompany(false);
                  }}
                ></Select>
              </div>
              <div style={{ width: '2%', float: 'left' }}>
                <AddIcon
                  style={{ height: '2rem' }}
                  onClick={() => {
                    if(props.tempCompany !== ""){
                      addValueToSelector(
                        props.tempCompany,
                        props.selectorCreateProductCompany,
                        props.changeSelectorCreateProductCompany,
                        props.changeSelectedCreateProductCompanies,
                        props.changeHasErrorCompany,
                      );
                    }
                  }}
                ></AddIcon>
              </div>
              {/* BRAND */}
              <Typography>{trans('BRAND', props.lang)}</Typography>
              <div style={{ width: '98%', float: 'left' }}>
                <Select
                  styles={theme.reactSelect}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      let contains = false;
                      props.selectorCreateProductBrand.forEach(element => {
                        if (element.value.includes(props.tempBrand))
                          contains = true;
                      });
                      if (!contains)
                        addValueToSelector(
                          props.tempBrand,
                          props.selectorCreateProductBrand,
                          props.changeSelectorCreateProductBrand,
                          props.changeSelectedCreateProductBrands,
                          props.changeHasErrorBrand,
                        );
                    }
                  }}
                  onInputChange={(e, e2) => {
                    if (e2.action && e2.action === 'input-change')
                      props.changeTempBrand(e);
                  }}
                  id={brandSelectorId}
                  isDisabled={ !props.selectedCreateProductCompanies || props.selectedCreateProductCompanies.length === 0}
                  placeholder={
                    props.selectedTenants.length === 0
                      ? trans('PRODUCT_FORM_ACCOUNT_PLACEHOLDER', props.lang)
                      : !props.selectedCreateProductCompanies || props.selectedCreateProductCompanies.length === 0
                      ? trans('PRODUCT_FORM_BRAND_PLACEHOLDER', props.lang)
                      : props.selectorCreateProductBrand.length === 0
                      ? trans('PRODUCT_FORM_BRAND_NOTFOUND_2', props.lang)
                      : trans('PRODUCT_FORM_BRAND_PLACEHOLDER', props.lang)
                  }
                  isMulti={false}
                  value={props.selectedCreateProductBrands}
                  options={props.selectorCreateProductBrand}
                  onChange={e => {
                    props.changeSelectedCreateProductBrands([e]);
                    props.changeHasErrorBrand(false);
                  }}
                ></Select>
              </div>
              <div style={{ width: '2%', float: 'left' }}>
                <AddIcon
                  style={{ height: '2rem' }}
                  onClick={() => {
                    if(props.tempBrand !== ""){
                      addValueToSelector(
                        props.tempBrand,
                        props.selectorCreateProductBrand,
                        props.changeSelectorCreateProductBrand,
                        props.changeSelectedCreateProductBrands,
                        props.changeHasErrorBrand,
                      );
                    }
                    
                  }}
                ></AddIcon>
              </div>
            </div>
          </Widget>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Widget
            overflow={true}
            focusable={
              <Focusable
                id={fanPageFocusId}
                refList={props.refList}
              ></Focusable>
            }
            title={<div>{trans('PRODUCT_FORM_LABEL2', props.lang)}</div>}
            bodyClass={classes.tableWidget}
            disableWidgetMenu={true}
          >
            <div>
              {props.hasErrorCategory && props.showErrors ? (
                <div style={{ color: 'red' }}>
                  <div className={classes.errorIcon}>
                    <ErrorIcon></ErrorIcon>
                  </div>
                  {trans('PRODUCT_FORM_CAT_PLACEHOLDER', props.lang)}
                </div>
              ) : (
                ''
              )}

              <Typography>{trans('PRODUCT_FORM_CAT', props.lang)}</Typography>
              <Select
                id={adAccountSelectorId}
                isDisabled={props.selectorCategory.length === 0}
                placeholder={
                  props.selectorCategory.length === 0 ? (
                    <div>{trans('PRODUCT_FORM_CAT_NOT_FOUND', props.lang)}</div>
                  ) : (
                    trans('PRODUCT_FORM_CAT_PLACEHOLDER', props.lang)
                  )
                }
                isMulti={false}
                value={props.selectedCategories}
                options={props.selectorCategory}
                onChange={e => {
                  props.changeSelectedCategories([e]);
                  props.changeHasErrorCategory(false);
                }}
              ></Select>
            </div>
          </Widget>
        </Grid>
      </Grid>

      <Grid container alignContent="center" justifyContent="flex-end">
        <Fab
          id="create-Campaign"
          variant="extended"
          color="primary"
          aria-label="add"
          className={classes.margin}
          onClick={e => {
            props.changeShowErrors(true);
            if (props.hasError) {
              if (props.hasErrorCategory)
                changeFocus(fanPageFocusId, props.refList);
              else if (props.hasErrorAdAccount)
                changeFocus(adAccountFocusId, props.refList);
              else if (props.hasErrorCompany)
                changeFocus(adAccountFocusId, props.refList);
              else if (props.hasErrorBrand)
                changeFocus(adAccountFocusId, props.refList);
              else if (props.hasErrorTitle)
                changeFocus(titleFocusId, props.refList);
            } else {
              if(props.adTitle[0].trim().length !== 0){
                  props.setCreateClicked();
                  let setGAdataProduct = `${props.selectedAdminAdAccounts[0].label} - ${props.adTitle}`;
                  setGASessionItem(
                    productProductGA,
                    sessionStorageUTMValue,
                    setGAdataProduct,
                  );
                  
                  let sendGAdataProduct = getGASessionItem();

                  sendGAEvent({
                    category: GAEventCategoryProduct,
                    action: GAEventActionProduct,
                    label: sendGAdataProduct,
                  });
              }else{
                changeFocus(titleFocusId, props.refList);
                blankSpaceV = true;
              }

            }
          }}
        >
          {props.modifyProduct
            ? trans('PRODUCT_CREATE_ACTION', props.lang)
            : trans('PRODUCT_MODIFY_ACTION', props.lang)}
        </Fab>
      </Grid>
    </React.Fragment>
  );
};

const styles = theme => ({
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  infoMessage: {
    color: '#9F6000',
    backgroundColor: '#FEEFB3',
    margin: theme.spacing(1),
  },
  textField: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: 500,
    borderColor: '#013f8a',
  },
  margin: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
    width: 400,
  },
  fanButton: {
    float: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(1),
    alignText: 'center',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(0),
  },
  tableWidget: {
    overflowX: 'visible',
  },
  datePicker: {
    overflowX: 'visible',
    padding: theme.spacing(2),
    margin: 'auto',
  },
  errorIcon: {
    fontSize: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    float: 'left',
  },
  warningMessage: {
    fontSize: '0.9rem',
    height: '1.5rem',
    paddingTop: '0.3rem',
    float: 'left',
  },
});

export default withStyles(styles, { withTheme: true })(View);
