import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Dataset12Summed } from '../../rest';
import { Dataset12SummedParams } from '../../rest/Dataset12Summed';
import { useAccountIdContext } from '../../context/accountId';
import { useHomeFiltersContext } from '../../context/homeFilters';

interface HomeContextProps {
  summedStandardIndicator?: StandardIndicatorI;
}

interface StandardIndicatorI {
  Impressions: number;
  LinkClicks: number;
  CTR: number;
  Cost: number;
  Actions: number;
  CPA: number;
  CVR: number;
  CPM: number;
}

const HomeContext = createContext<HomeContextProps>({
  summedStandardIndicator: undefined,
});

export const HomeContextWrapper: FC = ({ children }) => {
  const { startDate, endDate } = useHomeFiltersContext();
  const { currentAccount } = useAccountIdContext();

  const [summedStandardIndicator, setSummedStandardIndicator] =
    useState<StandardIndicatorI>();

  const companyValue = 391;
  const brandValue = 392;
  const adAccountId = 186;
  const product = 230;
  const campaignId = 1712;
  const campaignTypeId = 1712;

  const genderId = [1, 2, 3];
  const ageId = [1, 2, 4, 5, 8];
  const accountChange = useCallback(async () => {
    const params: Dataset12SummedParams = {
      fDate: startDate,
      lDate: endDate,
      accountId: currentAccount!.Id ?? 0,
      companyValue,
      brandValue,
      adAccountId,
      product,
      campaignId,
      campaignTypeId,
      genderId,
      ageId,
    };
    const response = await Dataset12Summed(params);

    if (response.payload && response.payload[0]) {
      const x = response.payload[0] as StandardIndicatorI;
      setSummedStandardIndicator(x);
    }
  }, [currentAccount]);

  useEffect(
    () => {
      accountChange();
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [currentAccount, startDate, endDate],
  );

  return (
    <HomeContext.Provider
      value={{
        summedStandardIndicator,
      }}>
      {children}
    </HomeContext.Provider>
  );
};
export const useHomeContext = () => {
  const context = useContext(HomeContext);

  if (context === undefined) {
    throw new Error('cant be use out of provider');
  }
  return context;
};
