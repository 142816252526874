import React from 'react';
import { Grid, withStyles, Button, Typography } from '@material-ui/core';
import Select from 'react-select';
import Widget from '../../../../components/Widget/WidgetContainer';
import PersonIcon from '@material-ui/icons/Person';
import {
  getValueFromJSONArray,
  updatUserRole,
  deleteUserRole,
  getAccountUsers,
} from '../../../../utils/functions';
import {
  userRoles,
  userRolesWithStaff,
} from '../../../../components/Constants';
import { getItem12Button, getUserItemStyle } from '../../../../utils/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const UseresView = ({ classes, theme, onChange, value, ...props }) => {
  if (props.loadingLayout && !props.isPrintingPage) {
    return (
      <Widget>
        <Typography>Cargando...</Typography>
      </Widget>
    );
  } else {
    return (
      <React.Fragment>
        <Grid item xs={12} md={12} lg={12}>
          <Widget
            title={
              <div>
                <h2 style={{ textAlign: 'center' }}>Usuarios</h2>
              </div>
            }
            bodyClass={classes.tableWidget}
            overflow
            disableWidgetMenu={true}
          >
            <Grid container>
              {props.users.map(
                (
                  {
                    email,
                    active,
                    role,
                    name,
                    canModify,
                    userId,
                    showUser,
                    currentUserIsStaff,
                  },
                  index,
                ) => {
                  let selectRoles = userRoles;
                  if (currentUserIsStaff) {
                    selectRoles = userRolesWithStaff;
                  }
                  if (showUser)
                    return (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        container
                        key={index}
                        style={{
                          paddingTop: '10px',
                          paddingBottom: '10px',
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          style={getUserItemStyle({ margin: 'auto' })}
                        >
                          <Widget
                            title={<PersonIcon></PersonIcon>}
                            bodyClass={classes.tableWidget}
                            overflow
                            disableWidgetMenu={true}
                          >
                            <Grid item xs={12}>
                              <h3>{'Nombre: '}</h3>
                              {name}
                            </Grid>
                            <Grid item xs={12}>
                              <h3>{'Correo: '}</h3>
                              {email}
                            </Grid>

                            <Grid item xs={12}>
                              <h3>{'Rol: '}</h3>
                              <Select
                                options={selectRoles}
                                styles={theme.reactSelect}
                                value={getValueFromJSONArray(
                                  selectRoles,
                                  role,
                                  'value',
                                  role,
                                  true,
                                )}
                                onChange={e => {
                                  updatUserRole(userId, e.value, props, () => {
                                    getAccountUsers(props);
                                  });
                                }}
                                isDisabled={!canModify}
                              ></Select>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: '10px' }}>
                              <div style={{ width: '100%' }}>
                                <div style={{ width: '50%', margin: 'auto' }}>
                                  <Button
                                    disabled={!canModify}
                                    style={
                                      canModify
                                        ? getItem12Button({
                                            width: '100%',
                                            backgroundColor:
                                              theme.palette.text.danger,
                                            color: 'white',
                                          })
                                        : getItem12Button()
                                    }
                                    variant="contained"
                                    size="medium"
                                    color={active ? '' : 'primary'}
                                    onClick={() => {
                                      deleteUserRole(userId, props, () => {
                                        getAccountUsers(props);
                                      });
                                    }}
                                  >
                                    <Grid container>
                                      <Grid item xs={10}>
                                        Eliminar
                                      </Grid>
                                      <Grid item xs={2}>
                                        <DeleteIcon />
                                      </Grid>
                                    </Grid>
                                  </Button>
                                </div>
                              </div>
                            </Grid>
                          </Widget>
                        </Grid>
                      </Grid>
                    );
                  // return something to remove warnings
                  return null;
                },
              )}
            </Grid>
          </Widget>
        </Grid>
      </React.Fragment>
    );
  }
};

const styles = theme => ({
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  infoMessage: {
    color: '#9F6000',
    backgroundColor: '#FEEFB3',
    margin: theme.spacing(1),
  },
  textField: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: 500,
    borderColor: '#013f8a',
  },
  margin: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
    width: 400,
  },
  fanButton: {
    float: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(1),
    alignText: 'center',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(0),
  },
  tableWidget: {
    overflowX: 'visible',
  },
  datePicker: {
    overflowX: 'visible',
    padding: theme.spacing(2),
    margin: 'auto',
  },
  errorIcon: {
    fontSize: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    float: 'left',
  },
  warningMessage: {
    fontSize: '0.9rem',
    height: '1.5rem',
    paddingTop: '0.3rem',
    float: 'left',
  },
  Button: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignText: 'center',
    fontSize: '0.75rem',
    marginTop: theme.spacing(2),
  },
  modal: {
    width: '90%',
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflow: 'scroll',
    height: '100%',
    display: 'block',
  },
});

export default withStyles(styles, { withTheme: true })(UseresView);
