import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { IntercomProvider } from 'react-use-intercom';

import themes, { overrides } from '../themes';
import Layout from './Layout';
import ErrorPage from '../pages/error';
import SessionExpiredError from '../pages/error/SessionExpired';
import Mantainance from '../pages/error/Mantainance';

import Login from '../pages/login';
import Callback from '../pages/callbackAuth';
import { sessionValidationInterval, reactAppGATrackingCode } from './Constants';
import Auth from '../authentication/Auth';
import Home2 from '../refactor/pages/home';
import ApplyCoupon from '../refactor/pages/applyCoupon';
import Onboarding from '../refactor/pages/onboarding';
import Reports from 'src/pages/reports/Reports';


const theme = createTheme({ ...themes.default, ...overrides });



let timer;
function ValidateSessionTimer() {
  if (timer) clearTimeout(timer);
  if (sessionStorage.getItem('logOut') === null) {
    timer = setTimeout(() => {
      if (localStorage.getItem('logOut') !== null) {
        sessionStorage.setItem('logOut', 'true');
        let auth = new Auth();

        auth.sessionExpired();
      }
      ValidateSessionTimer();
    }, sessionValidationInterval);
  } else {
    timer = setTimeout(() => {
      if (
        sessionStorage.getItem('logOut') !== null &&
        localStorage.getItem('logOut') === null
      )
        ValidateSessionTimer();
    }, sessionValidationInterval);
  }
}
ValidateSessionTimer();

const PrivateRoute = ({
  component,
  computedMatch,
  isAuthenticated,
  dispatch,
  ...rest
}) => {
  return (
    <Route
      render={props =>
        localStorage.getItem('id_token') ? (
          <Layout {...rest} lang={rest.lang} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const PublicRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('id_token') ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
};

const DEFAULT_CONFIG = {
  trackingId: reactAppGATrackingCode,
  debug: false,
  gaOptions: {
    cookieDomain: 'none',
  },
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reactGaInitialised: true,
      configs: [DEFAULT_CONFIG],
    };
  }

  initReactGA = event => {
    if (event) {
      event.preventDefault();
    }

    const { configs } = this.state;
    ReactGA.initialize(configs);
  };

  componentDidMount() {
    this.initReactGA();
  }

  render() {
    return (
      <IntercomProvider appId="k47psb4k" autoBoot>
        <MuiThemeProvider theme={theme}>
          <Switch>            
            <Route exact path="/app/home2" component={Home2} />
            <Route exact path="/app/coupons" component={ApplyCoupon} />
            <Route exact path="/app/onboarding" render={() => <Onboarding />} />
            <Route exact path="/" render={() => <Redirect to="/app/home" />} />
            <Route
              exact
              path="/app"
              render={() => <Redirect to="/app/home" />}
            />
            <Route exact path="/error" component={ErrorPage} />
            <Route
              exact
              path="/error/sessionexpired"
              component={SessionExpiredError}
            />
            <Route exact path="/mantainance" component={Mantainance} />
            <PrivateRoute {...this.props} path="/app" />
            <PublicRoute
              path="/login"
              component={() => (
                <Login lang={this.props.lang} setLang={this.props.setLang} />
              )}
            />
            <PublicRoute path="/callback" component={Callback} />
            <PublicRoute exact path="/test" component={() => {
              <Reports lang={this.props.lang} setLang={this.props.setLang}  />
            }} />
            
            <Route component={ErrorPage} />            
          </Switch>
        </MuiThemeProvider>
      </IntercomProvider>
    );
  }
}

export default App;
