export const initialState = {
  isSidebarOpened: true,
  resizeRefresh: false,
};

export const TOGGLE_SIDEBAR = 'Layout/TOGGLE_SIDEBAR';
export const TOGGLE_RESIZEREFRESH = 'Layout/TOGGLE_RESIZEREFRESH';

export const toggleResizeRefresh = payload => dispatch => {
  dispatch({ type: TOGGLE_RESIZEREFRESH, payload: payload });
};

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
});

export default function LoginReducer(state = initialState, { type, payload }) {
  switch (type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        isSidebarOpened: !state.isSidebarOpened,
      };
    case TOGGLE_RESIZEREFRESH:
      return {
        ...state,
        resizeRefresh: payload,
      };
    default:
      return state;
  }
}
