import gif from '../images/loadingGif.gif';
import { getWidthCode } from './generalFunctions';
export function getItem12Button(aditionalStyle, padding = 10) {
  let style = {
    width: '100%',
    maxWidth: '500px',
    padding: padding,
    alignText: 'center',
    fontSize: getWidthCode() === 'xs' ? '2.7vmin' : '',
  };
  if (aditionalStyle) {
    Object.keys(aditionalStyle).forEach(function (key) {
      style[key] = aditionalStyle[key];
    });
  }
  return style;
}
export function getEnhancedTableButton(
  aditionalStyle,
  padding = 10,
  xsvmin = 2.2,
  smvmin = 2.2,
) {
  let style = {
    width: '100%',
    maxWidth: '500px',
    padding: padding,
    alignText: 'center',
    fontSize:
      getWidthCode() === 'xs'
        ? xsvmin + 'vmin'
        : getWidthCode() === 'sm'
        ? smvmin + 'vmin'
        : '',
  };
  if (aditionalStyle) {
    Object.keys(aditionalStyle).forEach(function (key) {
      style[key] = aditionalStyle[key];
    });
  }
  //console.log(style)
  return style;
}
export function getEnhancedTableText(aditionalStyle, padding = 10) {
  let style = {
    alignText: 'center',
    fontSize: getWidthCode() === 'xs' ? '2.2vmin' : '',
  };
  if (aditionalStyle) {
    Object.keys(aditionalStyle).forEach(function (key) {
      style[key] = aditionalStyle[key];
    });
  }
  return style;
}
export function getWidgetTitleStyle(aditionalStyle, padding = 10) {
  let style = {
    width: '100%',
    fontSize:
      getWidthCode() === 'xs' || getWidthCode() === 'sm'
        ? '6vmin'
        : getWidthCode() === 'md'
        ? '4vmin'
        : '4vmin',
  };
  if (aditionalStyle) {
    Object.keys(aditionalStyle).forEach(function (key) {
      style[key] = aditionalStyle[key];
    });
  }
  return style;
}
export function getUserItemStyle(aditionalStyle) {
  let style = {
    width: '100%',
    maxWidth: '500px',
    alignText: 'left',
    fontSize: getWidthCode() === 'xs' ? '3.5vmin' : '',
  };
  if (aditionalStyle) {
    Object.keys(aditionalStyle).forEach(function (key) {
      style[key] = aditionalStyle[key];
    });
  }
  return style;
}
export function getHeaderButtonStyle(isPicture = false) {
  let style = {
    height: getWidthCode() === 'xs' ? '50px' : isPicture ? '50px' : '',
    width: getWidthCode() === 'xs' ? '50px' : isPicture ? '50px' : '',
    padding: '1px',
    minWidth: '50px',
    minHeight: '50px',
  };

  return style;
}
export function getHeaderButtonImageStyle(color, isPicture = false) {
  let style = {
    height: getWidthCode() === 'xs' ? '35px' : isPicture ? '50px' : '40px',
    width: getWidthCode() === 'xs' ? '35px' : isPicture ? '50px' : '40px',
    padding: '1px',
    minWidth: '30px',
    minHeight: '30px',
    color: color,
  };

  return style;
}

export function resizeError() {
  let style = {
    fontSize:
      getWidthCode() === 'xs'
        ? '4vmin'
        : getWidthCode() === 'sm'
        ? '4vmin'
        : '2.5vmin',
  };
  return style;
}
export function resizeDiv(
  xsvmin = 2.5,
  smvmin = 2,
  mdvmin = 2.5,
  lgvmin = 2.5,
) {
  let style = {
    fontSize:
      getWidthCode() === 'xs'
        ? xsvmin + 'vmin'
        : getWidthCode() === 'sm'
        ? smvmin + 'vmin'
        : getWidthCode() === 'md'
        ? mdvmin + 'vmin'
        : getWidthCode() === 'lg'
        ? lgvmin + 'vmin'
        : '2.5vmin',
  };
  return style;
}
export function resizeCenterh2() {
  let style = {
    textAlign: 'center',
    fontSize: getWidthCode() === 'xs' ? '3.5vmin' : '',
  };
  return style;
}
export const styles = theme => ({
  mainButton: {
    width: '50%',
    float: 'right',
    fontSize: '2.5vmin',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
  },
  center: { margin: 'auto' },
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  infoMessage: {
    color: '#9F6000',
    backgroundColor: '#FEEFB3',
    margin: theme.spacing(1),
  },
  textFieldList: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: '100%',
    borderColor: '#013f8a',
    paddingTop: '0.3rem',
  },
  textField: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: 500,
    borderColor: '#013f8a',
  },
  margin: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
    width: 400,
  },
  fanButton: {
    float: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(1),
    alignText: 'center',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(0),
  },
  tableWidget: {
    overflowX: 'visible',
  },
  tabWidget: {
    overflowX: 'visible',
    paddingTop: '50px',
  },
  WidgetCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60%',
  },
  datePicker: {
    overflowX: 'visible',
    padding: theme.spacing(2),
    margin: 'auto',
  },
  errorIcon: {
    fontSize: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    float: 'left',
  },
  warningMessage: {
    fontSize: '0.9rem',
    height: '1.5rem',
    paddingTop: '0.3rem',
    float: 'left',
  },
  iframe: {
    width: '100%',
    maxWidth: '500px',
    height: '550px',
    margin: 'auto',
    border: 'none',
    background: 'url(' + gif + ')  no-repeat  center;',
  },
  iframeIG: {
    width: '318px',
    height: '575px',
    margin: 'auto',
    border: 'none',
    background: 'url(' + gif + ')  no-repeat  center;',
  },
  defaultPreviewImage: {
    width: '100%',
    margin: 'auto',
    border: 'none',
    maxWidth: '400px',
  },

  Button: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignText: 'center',
    fontSize: '0.6rem',
    marginTop: theme.spacing(2),
  },
  headerIcons: {
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '2rem',
    flexShrink: '0',
    userSelect: 'none',
  },
  modal: {
    width: '90%',
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflow: 'scroll',
    height: '100%',
    display: 'block',
  },
});
