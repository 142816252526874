import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import Widget from '../../../components/Widget';
import Select from 'react-select';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import { Grid } from '@material-ui/core';
import {
  conversationsDestinationType,
  conversationsWhatsAppDestinationType,
  conversationsIGStoriesDestinationType,
  facebookMessengerDestinationTypeId,
  instagramDirectDestinationTypeId,
  whatsAppDestinationTypeId,
  callToActionConversationsMessenger,
  callToActionConversationsDirect,
  callToActionConversationsWhatsapp,
} from '../../../components/Constants';

import trans from './../../../i18n/tran';

const Conversation = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <Widget
      overflow={true}
      focusable={
        <Focusable id={props.focusId} refList={props.refList}></Focusable>
      }
      title={
        <div>
          <div style={{ height: '1.5rem' }}>
            <div>{trans('NEW_CAMPAIGN_MESSAGES', props.lang)}</div>
            {props.hasErrorConversations && props.showAdErrors ? (
              <div
                style={{
                  color: 'red',
                }}
              >
                <div className={classes.errorIcon}>
                  <ErrorIcon></ErrorIcon>
                </div>
                <div className={classes.warningMessage}>
                  {props.selectedConversations.length === 0
                    ? trans('NEW_CAMPAIGN_MESSAGES_SELECT', props.lang)
                    : ''}
                </div>
              </div>
            ) : (
              <div style={{ width: '100%' }}>
                <Grid container spacing={0}>
                  <Grid
                    style={{
                      height: '1.5rem',
                      marginTop: 'auto',
                      fontSize: '0.9rem',
                    }}
                  >
                    <WarningIcon />
                    {
                      trans('NEW_CAMPAIGN_MESSAGE18', props.lang)
                    }
                  </Grid>
                </Grid>
              </div>
            )}
            <br />
          </div>
        </div>
      }
      bodyClass={classes.tableWidget}
      disableWidgetMenu={true}
    >
      <div style={{ padding: '50px 0px' }}>
        <div style={{ width: '100%' }}>
          <Typography>{trans('NEW_CAMPAIGN_MESSAGES_DEST', props.lang)}</Typography>
          <Select
            id={'Aplicacion'}
            styles={theme.reactSelect}
            isDisabled={props.selectedAdminFanPages.length === 0}
            options={
              props.providers.includes('instagram') &&
              props.selectedInstagramPositions.includes('story')
                ? conversationsIGStoriesDestinationType
                : props.selectedAdminFanPages &&
                  props.selectedAdminFanPages.hasWhatsAppNumber
                ? conversationsWhatsAppDestinationType
                : conversationsDestinationType
            }
            value={props.selectedConversations}
            onChange={e => {
              props.changeHasErrorConversations(false);
              props.changeSelectedConversations([e]);
              props.changeAdLinkPreview(e.link);
              props.changeSelectedCta([]);
              if (e.value === facebookMessengerDestinationTypeId)
                props.changeSelectorCta(callToActionConversationsMessenger);
              if (e.value === instagramDirectDestinationTypeId)
                props.changeSelectorCta(callToActionConversationsDirect);
              if (e.value === whatsAppDestinationTypeId)
                props.changeSelectorCta(callToActionConversationsWhatsapp);
            }}
            placeholder={
              props.selectedAdminAdAccounts.length === 0
                ? trans('SELECT_AD_ACCOUNT', props.lang)
                : props.selectedAdminFanPages.length === 0
                ? trans('NEW_CAMPAIGN_FANPAGE_SELECT', props.lang)
                : trans('NEW_CAMPAIGN_MESSAGES_DEST_SELECT', props.lang)
            }
          ></Select>
        </div>
      </div>
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(Conversation);
