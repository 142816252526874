import React, { useState, useEffect } from 'react'

import DateFnsUtils from '@date-io/date-fns';
import {
    Send, Delete, Edit
} from '@material-ui/icons';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker,
} from '@material-ui/pickers';
import { alpha } from '@material-ui/core/styles';

import './ReportsAuto.css';

import '../../assets/fonts/Inter/Inter-Regular.ttf'
import '../../assets/fonts/Inter/Inter-Light.ttf'
import '../../assets/fonts/Inter/Inter-SemiBold.ttf'



import { doFetch, createHeaders } from './../../utils/fetch';
import { automaticReports, sendAutomaticReports, automaticReportsClients, automaticReportsCSA } from '../../components/Constants'

import trans from './../../i18n/tran';

const horario = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00',
    '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']

const meses = [ 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEV' ];


let controller = new AbortController();
let signal = controller.signal;
let isFetching = false;


function ReportsAuto(props) {

    const [mainprops, setMainProps] = useState(props);

    const [listaReportes, setListaReportes] = useState([]);
    const [editando, setEditando] = useState(false);
    const [objEdicion, setObjEdicion] = useState({});

    const [selectedDate, handleDateChange] = useState(new Date());
    const [selectedReportType, setSelectedReportType] = useState('reporte');
    const [selectedRecurrence, setSelectedRecurrence] = useState('mensual');
    const [selectedSchedule, setSelectedSchedule] = useState('00:00');

    const [selectedCliente, setSelectedCliente] = useState('Todos');
    const [multiplesClientes, setMultiplesClientes] = useState(true);

    const [errorCreacion, seterrorCreacion] = useState(false);
    const [finalizado, setfinalizado] = useState(false);


    const [listaCSA, setListaCSA] = useState([]);
    const [listaCSAAccount, setListaCSAAccount] = useState([]);
    const [listaCSAAccountSingle, setListaCSAAccountSingle] = useState([]);

    // LANGUAGE
    let locale
    if (props.lang) {
        if (props.lang === "es") {
            locale = "es"
        }
    }

    // HANDLES
    const handleSelectReportChange = (event) => {
        setSelectedReportType(event.target.value);
        setfinalizado(false);
    };
    const handleSelectRecurrenceChange = (event) => {
        setSelectedRecurrence(event.target.value);
        setfinalizado(false);
    };
    const handleSelectScheduleChange = (event) => {
        setSelectedSchedule(event.target.value);
        setfinalizado(false);
    };
    const handleClienteChange = (event) => {

        if (event.target.value == "Todos") {
            // console.log('multiples clientes')
            setMultiplesClientes(true)
        } else {
            setMultiplesClientes(false)
        }
        setSelectedCliente(event.target.value);
        setfinalizado(false);
    };
    const handleCSAChange = (event) => {
        getCSAAccounts(props, event.target.value);
        setfinalizado(false);
    };

    // DATE AUXILIARY
    const getDateString = (date, time) => {
        // console.log('HACIENDO STRING DE DATE', date, time)

        const dateTimeString = date + 'T' + time + '-06:00';
        const dateObject = new Date(dateTimeString);

        const clientDateTime = new Date(dateObject.getTime() - 6 * 60 * 60 * 1000); // hours * minutes * seconds * miliseconds
        const dateAsString = clientDateTime.toLocaleDateString();


        var parts = dateAsString.split('/');
        const month = parseInt(parts[0]) < 10 ? '0'+ parts[0] : parts[0];
        const day = parseInt(parts[1]) < 10 ? '0'+ parts[1] : parts[1];
        var convertedDateStr = parts[2] + '-' + month+ '-' + day;



        // console.log('HACIENDO STRING DE DATE', dateObject)

        return convertedDateStr
    } 
    
    const geGMTSchedule = (timeStr) => {
        const timeParts = timeStr.split(':');
        var hours = parseInt(timeParts[0], 10);
        var minutes = parseInt(timeParts[1], 10);
        var seconds = parseInt(timeParts[2], 10);

        hours -= 6;
        if (hours < 0) {
            hours += 24;
        }

        return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');

    }

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it's zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }


    // API REQUESTS
    const getCSA = async (props) => {
        controller = new AbortController();
        signal = controller.signal;

        let url = `${automaticReportsCSA}/`;

        await doFetch(
            url,
            {
                method: 'get',
                headers: createHeaders(),
                signal,
            },
            res => {
                if ('success' in res && 'payload' in res && res.success) {

                    let raw = res.payload.report
                    setListaCSA(raw)

                }
            },
        );
    }
    const getCSAAccounts = async (props, id) => {
        controller = new AbortController();
        signal = controller.signal;

        let url = `${automaticReportsCSA}/`;

        await doFetch(
            url,
            {
                method: 'post',
                headers: createHeaders(),
                body: JSON.stringify({
                    Id: id == 'Todos' ? null : id
                }),
                signal,
            },
            res => {
                if ('success' in res && 'payload' in res && res.success) {

                    let raw = res.payload.report
                    setListaCSAAccount(raw)

                }
            },
        );
    }
    const getReports = async (props) => {

        controller = new AbortController();
        signal = controller.signal;

        let url = `${automaticReports}/`;

        await doFetch(
            url,
            {
                method: 'get',
                headers: createHeaders(),
                signal,
            },
            res => {
                if ('success' in res && 'payload' in res && res.success) {

                    let raw = res.payload.report
                    setListaReportes(raw)

                }
            },
        );
    }
    const enviar = async (props, reporte) => {
        // console.log('ENVIAR REPORTE', reporte)
        controller = new AbortController();
        signal = controller.signal;

        let url = `${sendAutomaticReports}/`;

        await doFetch(
            url,
            {
                method: 'post',
                headers: createHeaders(),
                body: JSON.stringify({
                    Id: reporte.Id
                }),
                signal,
            },
            res => {
                if ('success' in res && 'payload' in res && res.success) {

                    alert('Reporte enviado con éxito')

                }
            },
        );

        props.changeIsLoadingLayout(false);
    }
    const eliminar = async (props, reporte) => {

        let text = trans('RP_DELETE_CONFIRM', props.lang);
        if (window.confirm(text) != true) {
            console.log('nevermind')
            return;
        }

        console.log('ELIMINAR REPORTE', reporte)
        controller = new AbortController();
        signal = controller.signal;

        let url = `${automaticReports}/`;

        await doFetch(
            url,
            {
                method: 'delete',
                headers: createHeaders(),
                body: JSON.stringify({
                    Id: reporte.Id
                }),
                signal,
            },
            res => {
                if ('success' in res && 'payload' in res && res.success) {

                    alert('Reporte eliminado con éxito')
                    getReports();

                }
            },
        );

    }
    const enviarReporte = async (accountId, adAccountId) => {
        let url = `${automaticReports}/`;

        // fixing date and hour accorgind to server time
        const fixedDate = new Date(selectedDate.getTime()); //  - 24 * 60 * 60 * 1000

        // const clientDateStr = fixedDate.toISOString().slice(0, 19).replace('T', ' ').split(' ')[0];
        const clientHourStr = selectedSchedule;

        console.log('STARTING DATE', selectedDate, fixedDate)
        const tentativeDate = formatDate(fixedDate);

        // console.log('TENTATIVE DATE', tentativeDate)

        console.log('ORIGINAL DATE', tentativeDate, ' hour: ', clientHourStr );

        const clientDateTime = new Date(`${tentativeDate} ${clientHourStr}`);
        const serverDateTime = new Date(clientDateTime.getTime() + 6 * 60 * 60 * 1000); // hours * minutes * seconds * miliseconds

        const dateAsString = serverDateTime.toLocaleDateString();
        var timeAsString = serverDateTime.toLocaleTimeString(undefined, { hour12: false });

        var parts = dateAsString.split('/');
        const month = parseInt(parts[0]) < 10 ? '0'+ parts[0] : parts[0];
        const day = parseInt(parts[1]) < 10 ? '0'+ parts[1] : parts[1];
        var convertedDateStr = parts[2] + '-' + day + '-' + month;

        if (timeAsString == '24:00:00') {
            timeAsString = '00:00:00'
        }

        const dateTimeString = convertedDateStr + 'T' + timeAsString + 'Z';
        const dateObject = new Date(dateTimeString);
        console.log(dateObject.toUTCString()); 


        // console.log('FECHA Y HORA CLIENTE', clientDateTime, '\n', 'FECHA Y HORA SERVIDOR', serverDateTime,'\n',)
        console.log(convertedDateStr, ',',timeAsString );

        await doFetch(
            url,
            {
                method: 'post',
                headers: createHeaders(),
                body: JSON.stringify({
                    AccountId: accountId,
                    Type: selectedReportType,
                    ReportId: 10,
                    AdAccountId: adAccountId,
                    Frequency: selectedRecurrence,
                    ScheduleDate: convertedDateStr,
                    ShceduleTime: timeAsString,
                    Status: 10
                }),
                signal,
            },
            res => {
                if ('success' in res && 'payload' in res && res.success) {

                    if (res.payload.report.length > 0 && 'Error' in res.payload.report[0]) {
                        console.log('ERROR ADDACCOUNT', res)
                        seterrorCreacion(true)
                    } else {
                        // console.log('todo bien', accountId, adAccountId);
                        getReports();
                        setfinalizado(true);
                        setSelectedRecurrence('mensual')
                        setSelectedSchedule('00:00')
                        handleDateChange(new Date())
                    }

                }
            },
        );
    }    

    // REPORT AUXILIARY
    const nuevoReporteMultiple = (props, reporte) => {
        // console.log('multiple')
        if (editando) {

            nuevoReporte(false, 0);
            return;
        }
        listaCSAAccountSingle.forEach((cliente, x) => {
            nuevoReporte(true, x);
        });
    }    
    const nuevoReporte = async (multiple, index) => {

        controller = new AbortController();
        signal = controller.signal;

        let url = `${automaticReports}/`;

        if (editando) {
            await doFetch(
                url,
                {
                    method: 'put',
                    headers: createHeaders(),
                    body: JSON.stringify({
                        Id: objEdicion.Id,
                        AccountId: objEdicion.AccountId,
                        Type: selectedReportType,
                        ReportId: objEdicion.ReportId,
                        AdAccountId: objEdicion.AdAccountId,
                        Frequency: selectedRecurrence,
                        ScheduleDate: selectedDate.toISOString().slice(0, 19).replace('T', ' '),
                        ShceduleTime: selectedSchedule
                    }),
                    signal,
                },
                res => {
                    if ('success' in res && 'payload' in res && res.success) {

                        alert('Reporte editado con éxito');
                        getReports();

                    }
                },
            );

            setEditando(false)
        } else {

            let accountId = multiple ? listaCSAAccountSingle[index].AccountId : selectedCliente;

            listaCSAAccount.forEach(campania => {
                if (campania.AccountId == accountId) {
                    enviarReporte(accountId, campania.AdAccountId);
                }
            });
        }


    }
    const editar = async (props, reporte) => {
        // console.log('editar', reporte)

        const tdate = reporte.ScheduleDate.split('T')[0];
        const ttime = reporte.ShceduleTime;

        const dateTimeString = tdate + 'T' + ttime + '-06:00';
        const dateObject = new Date(dateTimeString);

        const clientDateTime = new Date(dateObject.getTime() - 6 * 60 * 60 * 1000); // hours * minutes * seconds * miliseconds

        const clientDate = new Date(clientDateTime.getDate());
        const clientTime = geGMTSchedule(reporte.ShceduleTime).slice(0,-3);

        // console.log('CLIENT DATE: ', clientDateTime);
        // console.log('CLIENT TIME', clientTime);


        setEditando(true);
        setObjEdicion(reporte);

        // const date = new Date(reporte.ScheduleDate);
        // console.log(reporte.ScheduleDate);
        handleDateChange(clientDateTime)

        setSelectedRecurrence(reporte.Frequency)

        // console.log(reporte.ShceduleTime.slice(0, -3))
        setSelectedSchedule(clientTime);

        setSelectedReportType(reporte.Type)


        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    // TRANSLATION AUXILIARY
    const getTypeTranslation = (type) => {
        return props.lang == 'es' ? type : 'Report'
    }
    const getFrequencyTranslation = (frequency) => {
        if (frequency == 'mensual') {
            return props.lang == 'es' ? frequency : 'monthly'
        } if (frequency == 'semanal') {
            return props.lang == 'es' ? frequency : 'weekly'
        } else {
            return props.lang == 'es' ? frequency : 'biweekly'
        }
    }
    

    // HOOKS 
    useEffect(() => {
        // en cada cambio de customer success
        let gurpoAccounts = listaCSAAccount.reduce((groups, item) => {
            const key = `${item.AccountId}-${item.Title}`;

            if (!groups[key]) {
                groups[key] = { AccountId: item.AccountId, Title: item.Title };
            }

            return groups;
        }, {});
        setListaCSAAccountSingle(Object.values(gurpoAccounts));

    }, [listaCSAAccount]);    
    useEffect(() => {
        // cada vez que se selecciona un cliente
    }, [selectedCliente]);
    useEffect(() => {
        setMultiplesClientes(true);
        getReports(props);
        getCSA(props);
        getCSAAccounts(props, 'Todos')
    }, []);    


    return (
        <div className="ReportsAuto-App">
            <div className="col-principal">
                <div className="col-titulo">
                    <p>
                        {trans('RP_AUTOMATIC_REPORT', props.lang)}
                        {
                            editando ?
                                <> - Editando Reporte </>
                                : <></>
                        }
                    </p>
                </div>
                {
                    errorCreacion &&
                    <div className="col-error">
                        <p>
                            Ha ocurrido un error en la creación de tu reporte, inténtalo más tarde.
                        </p>
                    </div>
                }
                {
                    finalizado && !errorCreacion &&
                    <div className="col-final">
                        <p>
                            {trans('RP_REPORT_OK', props.lang)}
                        </p>
                    </div>
                }
                {
                    editando ? <></> :
                        <div className="col-filtros">
                            <label htmlFor="customer-success" >Customer Success</label>
                            <select disabled={editando} name="customer-success" onChange={handleCSAChange}>
                                <option disabled>Customer Success</option>
                                <option>{trans('RP_ALL', props.lang)}</option>
                                {
                                    listaCSA.map(success =>
                                        <option key={success.UserId} value={success.UserId}>{success.FirstName} {success.LastName}</option>
                                    )
                                }
                            </select>
                            <select disabled={editando} value={selectedCliente} onChange={handleClienteChange}>
                                <option disabled>{trans('RP_SELECT_CLIENT', props.lang)}</option>
                                {
                                    listaCSAAccountSingle.length > 0 && <option value="Todos">{trans('RP_ALL', props.lang)}</option>
                                }
                                {
                                    listaCSAAccountSingle.map((account, x) =>
                                        <option key={x} value={account.AccountId} >{account.Title}</option>
                                    )
                                }
                            </select>
                        </div>
                }

                <div className='col-opciones' >
                    <p>
                        {trans('RP_REPORT_TYPE', props.lang)}
                    </p>

                    <select value={selectedReportType} onChange={handleSelectReportChange}>
                        <option value="reporte" >Reporte</option>
                    </select>

                    <p>
                        {trans('RP_RECURRENCE', props.lang)}
                    </p>
                    <select value={selectedRecurrence} onChange={handleSelectRecurrenceChange}>
                        <option value="mensual">{trans('RP_MONTHLY', props.lang)}</option>
                        <option value="bisemanal">{trans('RP_BIWEEKLY', props.lang)}</option>
                        <option value="semanal">{trans('RP_WEEKLY', props.lang)}</option>
                    </select>

                    <p>
                        {trans('RP_SHIPPING_DATE', props.lang)}
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker value={selectedDate} onChange={handleDateChange} />
                    </MuiPickersUtilsProvider>

                    <p>
                        {trans('RP_SCHEDULE', props.lang)}
                    </p>
                    <select value={selectedSchedule} onChange={handleSelectScheduleChange}>
                        {
                            horario.map((time) => {
                                return <option key={time} value={time}>{time}</option>
                            })
                        }
                    </select>

                </div>


                <div className='col-botones'>
                    <button onClick={() => multiplesClientes ? nuevoReporteMultiple() : nuevoReporte(false, 0)} >
                        {trans('RP_SAVE', props.lang)}
                    </button>
                </div>


                <div className="col-titulo">
                    <p>
                        {trans('RP_ALL_REPORTS', props.lang)}
                    </p>
                </div>

                <div className="col-tabla col-tabla-automatica">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Account
                                </th>
                                <th>
                                    Ad Account
                                </th>
                                <th>
                                    {trans('RP_REPORT_TYPE', props.lang)}
                                </th>
                                <th>
                                    {trans('RP_RECURRENCE', props.lang)}
                                </th>
                                <th>
                                    {trans('RP_SHIPPING_DATE', props.lang)}
                                </th>
                                <th>
                                    {trans('RP_SCHEDULE', props.lang)}
                                </th>
                                <th>
                                    {trans('RP_REPORT_OPTIONS', props.lang)}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listaReportes.map((reporte, i) =>
                                    <tr key={i}>
                                        <td>{reporte.AccountName}</td>
                                        <td>{reporte.AdAccountName}</td>
                                        <td>{getTypeTranslation(reporte.Type)}</td>
                                        <td>{getFrequencyTranslation(reporte.Frequency)}</td>
                                        <td>{getDateString(reporte.ScheduleDate.split('T')[0], reporte.ShceduleTime)}</td>
                                        <td>{geGMTSchedule(reporte.ShceduleTime)}</td>
                                        <td>
                                            <div className="button-row">
                                                <button className="miniReport" onClick={() => enviar(props, reporte)} >
                                                    <Send />
                                                </button>
                                                <button className="miniReport" onClick={() => editar(props, reporte)} >
                                                    <Edit />
                                                </button>
                                                <button className="miniReport" onClick={() => eliminar(props, reporte)} >
                                                    <Delete />
                                                </button>

                                            </div>
                                        </td>
                                    </tr>)
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={'100%'} className='pagination'>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

            </div>

        </div>
    )
}

export default ReportsAuto