import React from 'react';
import { Grid, Typography, withStyles, Button } from '@material-ui/core';
import Facebook from '../../images/facebook.svg';
import allylogo3 from '../../images/ALY.svg';
import { isLoadingLoginModal } from '../../utils/global';
import trans from './../../i18n/tran';
import { Spain, Usa } from './../../components/Flags/Flags';
import { Policy } from '@material-ui/icons';

const Login = ({ classes, ...props }) => {
  return (
    <Grid container className={classes.container}>
      {props.isLoadingLogin ? isLoadingLoginModal : <div></div>}
      <div className={classes.logotypeContainer}>
        <img
          src={allylogo3}
          alt="aly-ai logo"
          className={classes.userIcon}
        ></img>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Typography variant="h1" className={classes.greeting}>
              {trans('LOGIN', props.lang)}
            </Typography>
            <Button
              size="large"
              className={classes.googleButton}
              onClick={props.handleLoginButtonClick}
            >
              <img
                src={Facebook}
                alt="facebook"
                className={classes.googleIcon}
              />
              &nbsp;{trans('LOGIN_WITH_FACEBOOK', props.lang)}
            </Button>
            <Button className={classes.langButton} 
                onClick={()=> {
                props.setLang('es');
              }}>
                <Spain isCurrent={props.lang==="es"} />
            </Button>
            <Button className={classes.langButton}
                onClick={()=> {
                  props.setLang('en');
                  }}>
                <Usa isCurrent={props.lang==="en"} />
            </Button>
            <Button className={classes.privButton} href="https://aly-ai.com/politicas/">
              <Policy className={classes.profileMenuIcon} />
               {" "} {trans('NAV_PRIVACY_POLICY', props.lang)} 
            </Button>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
};

const styles = theme => ({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  //Main logo Login Container
  logotypeContainer: {
    backgroundColor: theme.palette.primary.main,
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', //horizontal
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logotypeImage: {
    height: '100%',
    width: '50%',
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: 'yellow',
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down('md')]: {
      fontSize: 48,
    },
  },
  //Sig In tab
  formContainer: {
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  form: {
    width: 320,
    alignItems: 'center',
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
    alignItems: 'center',
  },
  greeting: {
    fontWeight: 700,
    textAlign: 'center',
    marginTop: theme.spacing(4),
    color: theme.palette.text.secondary,
    fontSize: '2.5rem',
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: 'white',
    width: '100%',
    textTransform: 'none',
    '&:hover': {
      borderBottomColor: 'white',
      backgroundColor: 'white',
    },
  },
  langButton: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(),
    color:theme.palette.text.primary,
    backgroundColor: theme.palette.success.lighter,
    width: '45%',
    textTransform: 'none',
    '&:hover': {
      borderBottomColor: 'white',
      backgroundColor: 'white',
    },
  },
  privButton: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(5),
    color:theme.palette.text.primary,
    backgroundColor: theme.palette.success.lighter,
    width: '100%',
    textTransform: 'none',
    '&:hover': {
      borderBottomColor: 'white',
      backgroundColor: 'white',
    },
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  createAccountButton: {
    height: 46,
    textTransform: 'none',
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 6,
    // height: 1,
    backgroundColor: theme.palette.text.hint + '40',
    alignItems: 'center',
    maxWidth: '50%',
    marginBottom: theme.spacing(3),
  },
  errorMessage: {
    textAlign: 'center',
  },
  textFieldUnderline: {
    '&:before': {
      borderBottomColor: theme.palette.primary.light,
    },
    '&:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '&:hover:before': {
      borderBottomColor: `${theme.palette.primary.white} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: '40%',
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    '&:hover': {
      borderBottomColor: 'white',
      backgroundColor: 'white',
    },

  },
  forgetButton: {
    textTransform: 'none',
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: theme.spacing(2),
      textColor: 'white',
    },
  },
  userIcon: {
    width: '40%',
    marginRight: theme.spacing(1),
  },
  logo3: {
    width: '30%',
  },
});

export default withStyles(styles, { withTheme: true })(Login);
