import React from 'react';
import { Typography } from '../../../Wrappers';
import 'date-fns';
import Select from 'react-select';
import {
  optimizationSelectorId,
  accountSelectorId,
  adAccountSelectorId,
  campaignSelectorId,
  categorySelectorId,
  productSelectorId,
  ageSelectorId,
  genderSelectorId,
  brandSelectorId,
  companySelectorId,
  sideBarDatePicker,
  campaignNameSelectorId,
  productTours
} from '../../../Constants';
import ErrorIcon from '@material-ui/icons/Error';
import { IconButton, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import {
  getSelectedValues,
  componentBelongsInPage,
  getCustomPageInfo,
  getComponentMapping,
  getDisabledLoadButton,
  updateSelector,
} from '../../../../utils/functions';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { CustomDateSlider } from '../../CustomDateSlider';
import Button from '@material-ui/core/Button';
import trans from './../../../../i18n/tran';
import HelpIcon from '@material-ui/icons/Help';
import { useIntercom } from 'react-use-intercom';

const SideBarFilters = ({
  classes,
  theme,
  toggleSidebar,
  isSidebarOpened,
  isPermanent,
  location,
  ...props
}) => {
  let PageInfo = getCustomPageInfo(props.customPageId);
  const { startTour } = useIntercom();

  function openMenu() {
    if (props.isFullScreeen) {
      let element = document.getElementById('LayoutDiv');
      setTimeout(() => {
        element.scroll(100000, 100000);
      }, 50);
    }
  }
  return (
    <div
      id="Sidebar"
      style={{
        paddingTop: componentBelongsInPage(
          'padding-top',
          props.customPageId,
          PageInfo.mapping,
        )
          ? '30px'
          : '',
      }}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={toggleSidebar}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>

      {componentBelongsInPage(
        sideBarDatePicker,
        props.customPageId,
        PageInfo.mapping,
      ) ? (
        <div style={{ width: '90%', margin: '20px auto' }}>
          <CustomDateSlider
            fontSize={props.dateFontSize ? props.dateFontSize : undefined}
            changeError={props.changeDateHasErrors}
            changeIsLoading={
              props.layoutLoading
                ? props.changeIsLoadingLayout
                : props.changeIsLoadingSideBar
            }
            ErrorfontSize={'0.7rem'}
            ErrorIcon={<ErrorIcon />}
            switchDates={true}
            monthSelector={props.customPageId === 3}
            onChange={ getComponentMapping(props.customPageId, sideBarDatePicker).childFunction}
            {...props}
            id={sideBarDatePicker}
          ></CustomDateSlider>
        </div>
      ) : (
        ''
      )}

      {componentBelongsInPage(
        optimizationSelectorId,
        props.customPageId,
        PageInfo.mapping,
      ) ? (
        <div style={{ width: '90%', margin: '0px auto' }}>
          <Grid container justifyContent="center" style={{ width: '98%' }}>
            <Grid container>
              <Grid item xs={11} lg={11} md={11}>
                <Typography>Tipo de Optimización</Typography>
              </Grid>
              <Grid item xs={1} lg={1} md={1}>
                <div style={{ float: 'right' }}>
                  <Tooltip title="Multiple">
                    <Checkbox
                      disabled={
                        props.selectorOptimization.length === 0 ||
                        props.isFirstLoad
                      }
                      id="optimizationIsMultiple"
                      style={{ width: 5, height: 5 }}
                      checked={props.optimizationIsMultiple}
                      onChange={(event, checked) => {
                        let selected = props.selectedOptimizations;
                        if (!checked) {
                          selected = selected[0];
                        }
                        props.changeOptimizationIsMultiple(checked);
                        updateSelector(
                          checked,
                          props.changeSelectedOptimizations,
                          props.selectedOptimizations,
                          selected,
                          props.selectorOptimization,
                          optimizationSelectorId,
                          props,
                          [],
                          'selectedOptimizations',
                          props.layoutLoading ? props.layoutLoading : false,
                        );
                      }}
                      value="Multiple"
                    />
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Select
            onMenuOpen={() => {
              openMenu();
            }}
            styles={theme.reactSelect}
            id={optimizationSelectorId}
            isDisabled={
              props.selectorOptimization.length === 0 || props.isFirstLoad
            }
            placeholder={
              props.isLoadingSideBar && (props.isFirstLoad || !props.autoLoad)
                ? trans('LOADING', props.lang) + '...'
                : props.selectorOptimization.length === 0
                ? trans('NO_INFO_DROPDOWN', props.lang)
                : trans('SELECT_OPTIMIZATION_TYPE', props.lang)
            }
            isMulti={props.optimizationIsMultiple}
            value={
              !props.isLoadingSideBar || !props.isFirstLoad
                ? props.selectedOptimizations
                : []
            }
            options={props.selectorOptimization}
            onChange={e => {
              updateSelector(
                props.optimizationIsMultiple,
                props.changeSelectedOptimizations,
                props.selectedOptimizations,
                e,
                props.selectorOptimization,
                optimizationSelectorId,
                props,
                [],
                'selectedOptimizations',
                props.layoutLoading ? props.layoutLoading : false,
              );
            }}
          ></Select>
        </div>
      ) : (
        ''
      )}

      {componentBelongsInPage(
        accountSelectorId,
        props.customPageId,
        PageInfo.mapping,
      ) ? (
        <div style={{ width: '90%', margin: '0px auto' }}>
          <Grid container justifyContent="center" style={{ width: '98%' }}>
            <Grid container>
              <Grid item xs={11} lg={11} md={11}>
                <Typography className={classes.typo} weight="bold" variant="h1">
                  Negocio Administrador
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Select
            onMenuOpen={() => {
              openMenu();
            }}
            styles={theme.reactSelect}
            id={accountSelectorId}
            isMulti={props.accountIsMultiple}
            isDisabled={props.selectorAccount.length === 0 || props.isFirstLoad}
            placeholder={
              props.isLoadingSideBar && (props.isFirstLoad || !props.autoLoad)
                ? trans('LOADING', props.lang) + '...'
                : props.selectorAccount.length === 0
                ? trans('NO_INFO_DROPDOWN', props.lang)
                : 'Negocio Administrador'
            }
            value={
              !props.isLoadingSideBar || !props.isFirstLoad
                ? props.selectedAccounts
                : []
            }
            options={props.selectorAccount}
            onChange={e => {
              updateSelector(
                props.accountIsMultiple,
                props.changeSelectedAccounts,
                props.selectedAccounts,
                e,
                props.selectorAccount,
                accountSelectorId,
                props,
                [],
                'selectedAccounts',
                props.layoutLoading ? props.layoutLoading : false,
              );
            }}
          ></Select>
        </div>
      ) : (
        ''
      )}

      {componentBelongsInPage(
        companySelectorId,
        props.customPageId,
        PageInfo.mapping,
      ) ? (
        <div style={{ width: '90%', margin: '0px auto' }}>
          <Grid container justifyContent="center">
            <Grid container>
              <Grid item xs={11} lg={11} md={11}>
                <Typography>{trans('COMPANY', props.lang)}</Typography>
              </Grid>
              <Grid item xs={1} lg={1} md={1}>
                <div style={{ float: 'right' }}>
                  <Tooltip title="Multiple">
                    <Checkbox
                      disabled={
                        props.selectorCompany.length === 0 || props.isFirstLoad
                      }
                      id="companyIsMultiple"
                      style={{ width: 5, height: 5 }}
                      checked={props.companyIsMultiple}
                      onChange={(event, checked) => {
                        let selected = props.selectedCompanies;
                        if (!checked) {
                          selected = selected[0];
                        }
                        props.changeCompanyIsMultiple(checked);
                        let params = [];
                        //adding this here instead of adding it on UpdateSelector since this
                        //selector has two parents and updateSelector only gets one
                        params.selectedAccounts = getSelectedValues(
                          props.selectedAccounts,
                          props.selectorAccount,
                        );
                        updateSelector(
                          checked,
                          props.changeSelectedCompanies,
                          props.selectedCompanies,
                          selected,
                          props.selectorCompany,
                          companySelectorId,
                          props,
                          params,
                          'selectedCompanies',
                          props.layoutLoading ? props.layoutLoading : false,
                        );
                      }}
                      value="Multiple"
                    />
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Select
            styles={theme.reactSelect}
            onMenuOpen={() => {
              openMenu();
            }}
            id={companySelectorId}
            isMulti={props.companyIsMultiple}
            isDisabled={props.selectorCompany.length === 0 || props.isFirstLoad}
            placeholder={
              props.isLoadingSideBar && (props.isFirstLoad || !props.autoLoad)
                ? trans('LOADING', props.lang) + '...'
                : props.selectorCompany.length === 0
                ? trans('NO_INFO_DROPDOWN', props.lang)
                : trans('SELECT_COMPANY', props.lang)
            }
            value={
              !props.isLoadingSideBar || !props.isFirstLoad
                ? props.selectedCompanies
                : []
            }
            options={props.selectorCompany}
            onChange={e => {
              let params = [];
              //adding this here instead of adding it on UpdateSelector since this
              //selector has two parents and updateSelector only gets one
              params.selectedAccounts = getSelectedValues(
                props.selectedAccounts,
                props.selectorAccount,
              );
              updateSelector(
                props.companyIsMultiple,
                props.changeSelectedCompanies,
                props.selectedCompanies,
                e,
                props.selectorCompany,
                companySelectorId,
                props,
                params,
                'selectedCompanies',
                props.layoutLoading ? props.layoutLoading : false,
              );
            }}
          ></Select>
        </div>
      ) : (
        ''
      )}

      {componentBelongsInPage(
        brandSelectorId,
        props.customPageId,
        PageInfo.mapping,
      ) ? (
        <div style={{ width: '90%', margin: '0px auto' }}>
          <Grid container justifyContent="center">
            <Grid container>
              <Grid item xs={11} lg={11} md={11}>
                <Typography>{trans('BRAND', props.lang)}</Typography>
              </Grid>
              <Grid item xs={1} lg={1} md={1}>
                <div style={{ float: 'right' }}>
                  <Tooltip title="Multiple">
                    <Checkbox
                      disabled={
                        props.selectorBrand.length === 0 || props.isFirstLoad
                      }
                      id="brandIsMultiple"
                      style={{ width: 5, height: 5 }}
                      checked={props.brandIsMultiple}
                      onChange={(event, checked) => {
                        let selected = props.selectedBrands;
                        if (!checked) {
                          selected = selected[0];
                        }
                        props.changeBrandIsMultiple(checked);
                        let params = [];
                        //adding this here instead of adding it on UpdateSelector since this
                        //selector has two parents and updateSelector only gets one
                        params.selectedCompanies = getSelectedValues(
                          props.selectedCompanies,
                          props.selectorCompany,
                        );
                        params.selectedAccounts = getSelectedValues(
                          props.selectedAccounts,
                          props.selectorAccount,
                        );
                        updateSelector(
                          checked,
                          props.changeSelectedBrands,
                          props.selectedBrands,
                          selected,
                          props.selectorBrand,
                          brandSelectorId,
                          props,
                          params,
                          'selectedBrands',
                          props.layoutLoading ? props.layoutLoading : false,
                        );
                      }}
                      value="Multiple"
                    />
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Select
            onMenuOpen={() => {
              openMenu();
            }}
            styles={theme.reactSelect}
            id={brandSelectorId}
            isMulti={props.brandIsMultiple}
            isDisabled={props.selectorBrand.length === 0 || props.isFirstLoad}
            placeholder={
              props.isLoadingSideBar && (props.isFirstLoad || !props.autoLoad)
                ? trans('LOADING', props.lang) + '...'
                : props.selectorBrand.length === 0
                ? trans('NO_INFO_DROPDOWN', props.lang)
                : trans('SELECT_BRAND', props.lang)
            }
            value={
              !props.isLoadingSideBar || !props.isFirstLoad
                ? props.selectedBrands
                : []
            }
            options={props.selectorBrand}
            onChange={e => {
              let params = [];
              //adding this here instead of adding it on UpdateSelector since this
              //selector has two parents and updateSelector only gets one
              params.selectedCompanies = getSelectedValues(
                props.selectedCompanies,
                props.selectorCompany,
              );
              params.selectedAccounts = getSelectedValues(
                props.selectedAccounts,
                props.selectorAccount,
              );
              updateSelector(
                props.brandIsMultiple,
                props.changeSelectedBrands,
                props.selectedBrands,
                e,
                props.selectorBrand,
                brandSelectorId,
                props,
                params,
                'selectedBrands',
                props.layoutLoading ? props.layoutLoading : false,
              );
            }}
          ></Select>
        </div>
      ) : (
        ''
      )}

      {componentBelongsInPage(
        categorySelectorId,
        props.customPageId,
        PageInfo.mapping,
      ) ? (
        <div style={{ width: '90%', margin: '0px auto' }}>
          <Grid container justifyContent="center">
            <Grid container>
              <Grid item xs={11} lg={11} md={11}>
                <Typography>Categoría</Typography>
              </Grid>
              <Grid item xs={1} lg={1} md={1}>
                <div style={{ float: 'right' }}>
                  <Tooltip title="Multiple">
                    <Checkbox
                      disabled={
                        props.selectorCategory.length === 0 || props.isFirstLoad
                      }
                      id="categoryIsMultiple"
                      style={{ width: 5, height: 5 }}
                      checked={props.categoryIsMultiple}
                      onChange={(event, checked) => {
                        let selected = props.selectedCategories;
                        if (!checked) {
                          selected = selected[0];
                        }
                        props.changeCategoryIsMultiple(checked);
                        let params = [];
                        //adding this here instead of adding it on UpdateSelector since this
                        //selector has two parents and updateSelector only gets one
                        params.selectedCompanies = getSelectedValues(
                          props.selectedCompanies,
                          props.selectorCompany,
                        );
                        params.selectedAccounts = getSelectedValues(
                          props.selectedAccounts,
                          props.selectorAccount,
                        );
                        params.selectedBrands = getSelectedValues(
                          props.selectedBrands,
                          props.selectorBrand,
                        );
                        updateSelector(
                          checked,
                          props.changeSelectedCategories,
                          props.selectedCategories,
                          selected,
                          props.selectorCategory,
                          categorySelectorId,
                          props,
                          params,
                          'selectedCategories',
                          props.layoutLoading ? props.layoutLoading : false,
                        );
                      }}
                      value="Multiple"
                    />
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Select
            onMenuOpen={() => {
              openMenu();
            }}
            styles={theme.reactSelect}
            id={categorySelectorId}
            isDisabled={
              props.selectorCategory.length === 0 || props.isFirstLoad
            }
            placeholder={
              props.isLoadingSideBar && (props.isFirstLoad || !props.autoLoad)
                ? trans('LOADING', props.lang) + '...'
                : props.selectorCategory.length === 0
                ? trans('NO_INFO_DROPDOWN', props.lang)
                : 'Seleccione Categoría'
            }
            isMulti={props.categoryIsMultiple}
            value={
              !props.isLoadingSideBar || !props.isFirstLoad
                ? props.selectedCategories
                : []
            }
            options={props.selectorCategory}
            onChange={e => {
              let params = [];
              //adding this here instead of adding it on UpdateSelector since this
              //selector has two parents and updateSelector only gets one
              params.selectedCompanies = getSelectedValues(
                props.selectedCompanies,
                props.selectorCompany,
              );
              params.selectedAccounts = getSelectedValues(
                props.selectedAccounts,
                props.selectorAccount,
              );
              params.selectedBrands = getSelectedValues(
                props.selectedBrands,
                props.selectorBrand,
              );
              updateSelector(
                props.categoryIsMultiple,
                props.changeSelectedCategories,
                props.selectedCategories,
                e,
                props.selectorCategory,
                categorySelectorId,
                props,
                params,
                'selectedCategories',
                props.layoutLoading ? props.layoutLoading : false,
              );
            }}
          ></Select>
        </div>
      ) : (
        ''
      )}
      {componentBelongsInPage(
        productSelectorId,
        props.customPageId,
        PageInfo.mapping,
      ) ? (
        <div style={{ width: '90%', margin: '0px auto' }}>
          <Grid container justifyContent="center">
            <Grid container>
              <Grid item xs={11} lg={11} md={11}>
                <Typography>{trans('PRODUCT', props.lang)}</Typography>
              </Grid>
              <Grid item xs={1} lg={1} md={1}>
                <div style={{ float: 'right' }}>
                  {!props.singleProduct ? (
                    <Tooltip title="Multiple">
                      <Checkbox
                        disabled={
                          props.selectorProduct.length === 0 ||
                          props.isFirstLoad
                        }
                        id="productIsMultiple"
                        style={{ width: 5, height: 5 }}
                        checked={props.productIsMultiple}
                        onChange={(e, checked) => {
                          let selected = props.selectedProducts;
                          if (!checked) {
                            selected = selected[0];
                          }
                          props.changeProductIsMultiple(checked);
                          let params = [];
                          //adding this here instead of adding it on UpdateSelector since this
                          //selector has two parents and updateSelector only gets one
                          params.selectedAccounts = getSelectedValues(
                            props.selectedAccounts,
                            props.selectorAccount,
                          );
                          updateSelector(
                            checked,
                            props.changeSelectedProducts,
                            props.selectedProducts,
                            selected,
                            props.selectorProduct,
                            productSelectorId,
                            props,
                            params,
                            'selectedProducts',
                            props.layoutLoading ? props.layoutLoading : false,
                          );
                        }}
                        value="Multiple"
                      />
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>{' '}
            </Grid>
          </Grid>
          <Select
            onMenuOpen={() => {
              openMenu();
            }}
            styles={theme.reactSelect}
            id={productSelectorId}
            isDisabled={props.selectorProduct.length === 0 || props.isFirstLoad}
            placeholder={
              props.isLoadingSideBar && (props.isFirstLoad || !props.autoLoad)
                ? trans('LOADING', props.lang) + '...'
                : props.selectorProduct.length === 0
                ? trans('NO_INFO_DROPDOWN', props.lang)
                : 'Seleccione Producto'
            }
            isMulti={props.productIsMultiple}
            value={
              !props.isLoadingSideBar || !props.isFirstLoad
                ? props.selectedProducts
                : []
            }
            options={props.selectorProduct}
            onChange={e => {
              let params = [];
              //adding this here instead of adding it on UpdateSelector since this
              //selector has two parents and updateSelector only gets one
              params.selectedAccounts = getSelectedValues(
                props.selectedAccounts,
                props.selectorAccount,
              );
              updateSelector(
                props.productIsMultiple,
                props.changeSelectedProducts,
                props.selectedProducts,
                e,
                props.selectorProduct,
                productSelectorId,
                props,
                params,
                'selectedProducts',
                props.layoutLoading ? props.layoutLoading : false,
              );
            }}
          ></Select>
        </div>
      ) : (
        ''
      )}

      {componentBelongsInPage(
        campaignSelectorId,
        props.customPageId,
        PageInfo.mapping,
      ) ? (
        <div style={{ width: '90%', margin: '0px auto' }}>
          <Grid container justifyContent="center">
            <Grid container>
              <Grid item xs={11} lg={11} md={11}>
                <Typography>{trans('CAMPAIGN_TYPE', props.lang)}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Select
            onMenuOpen={() => {
              openMenu();
            }}
            styles={theme.reactSelect}
            id={campaignSelectorId}
            isDisabled={
              props.selectorCampaignType.length === 0 || props.isFirstLoad
            }
            placeholder={
              props.isLoadingSideBar && (props.isFirstLoad || !props.autoLoad)
                ? trans('LOADING', props.lang) + '...'
                : props.selectorCampaignType.length === 0
                ? trans('NO_INFO_DROPDOWN', props.lang)
                : trans('SELECT_CAMPAIGN_TYPE', props.lang)
            }
            isMulti={props.campaignIsMultiple}
            value={
              !props.isLoadingSideBar || !props.isFirstLoad
                ? props.selectedCampaignTypes
                : []
            }
            options={props.selectorCampaignType}
            onChange={e => {
              let params = [];
              //adding this here instead of adding it on UpdateSelector since this
              //selector has two parents and updateSelector only gets one
              params.selectedAccounts = getSelectedValues(
                props.selectedAccounts,
                props.selectorAccount,
              );
              params.selectedProducts = getSelectedValues(
                props.selectedProducts,
                props.selectorProduct,
              );
              updateSelector(
                props.campaignIsMultiple,
                props.changeSelectedCampaignTypes,
                props.selectedCampaignTypes,
                e,
                props.selectorCampaignType,
                campaignSelectorId,
                props,
                params,
                'selectedCampaignTypes',
                props.layoutLoading ? props.layoutLoading : false,
              );
            }}
          ></Select>
        </div>
      ) : (
        ''
      )}

      {componentBelongsInPage(
        adAccountSelectorId,
        props.customPageId,
        PageInfo.mapping,
      ) ? (
        <div style={{ width: '90%', margin: '0px auto' }}>
          <Grid container justifyContent="center">
            <Grid container>
              <Grid item xs={11} lg={11} md={11}>
                <Typography>{trans('AD_ACCOUNT', props.lang)}</Typography>
              </Grid>
              <Grid item xs={1} lg={1} md={1}>
                <div style={{ float: 'right' }}>
                  <Tooltip title="Multiple">
                    <Checkbox
                      disabled={
                        props.selectorAdAccount.length === 0 ||
                        props.isFirstLoad
                      }
                      id="adAccountIsMultiple"
                      style={{ width: 5, height: 5 }}
                      checked={props.adAccountIsMultiple}
                      onChange={(event, checked) => {
                        let selected = props.selectedAdAccounts;
                        if (!checked) {
                          selected = selected[0];
                        }
                        props.changeAdAccountIsMultiple(checked);
                        updateSelector(
                          checked,
                          props.changeSelectedAdAccounts,
                          props.selectedAdAccounts,
                          selected,
                          props.selectorAdAccount,
                          adAccountSelectorId,
                          props,
                          [],
                          'selectedAdAccounts',
                          props.layoutLoading ? props.layoutLoading : false,
                        );
                      }}
                      value="Multiple"
                    />
                  </Tooltip>
                </div>
              </Grid>{' '}
            </Grid>
          </Grid>

          <Select
            onMenuOpen={() => {
              openMenu();
            }}
            styles={theme.reactSelect}
            id={adAccountSelectorId}
            isDisabled={
              props.selectorAdAccount.length === 0 || props.isFirstLoad
            }
            placeholder={
              props.isLoadingSideBar && (props.isFirstLoad || !props.autoLoad)
                ? trans('LOADING', props.lang) + '...'
                : props.selectorAdAccount.length === 0
                ? trans('NO_INFO_DROPDOWN', props.lang)
                : trans('SELECT_AD_ACCOUNT', props.lang)
            }
            isMulti={props.adAccountIsMultiple}
            value={
              !props.isLoadingSideBar || !props.isFirstLoad
                ? props.selectedAdAccounts
                : []
            }
            options={props.selectorAdAccount}
            onChange={e => {
              updateSelector(
                props.adAccountIsMultiple,
                props.changeSelectedAdAccounts,
                props.selectedAdAccounts,
                e,
                props.selectorAdAccount,
                adAccountSelectorId,
                props,
                [],
                'selectedAdAccounts',
                props.layoutLoading ? props.layoutLoading : false,
              );
            }}
          ></Select>
        </div>
      ) : (
        ''
      )}
      {componentBelongsInPage(
        campaignNameSelectorId,
        props.customPageId,
        PageInfo.mapping,
      ) ? (
        <div style={{ width: '90%', margin: '0px auto' }}>
          <Grid container justifyContent="center">
            <Grid container>
              <Grid item xs={11} lg={11} md={11}>
                <Typography>{trans('CAMPAIGN_NAME', props.lang)}</Typography>
              </Grid>
              <Grid item xs={1} lg={1} md={1}>
                <div style={{ float: 'right' }}>
                  <Tooltip title="Multiple">
                    <Checkbox
                      disabled={
                        props.selectorCampaignName.length === 0 ||
                        props.isFirstLoad
                      }
                      id="campaignNameIsMultiple"
                      style={{ width: 5, height: 5 }}
                      checked={props.campaignNameIsMultiple}
                      onChange={(event, checked) => {
                        let selected = props.selectedCampaignNames;
                        if (!checked) {
                          selected = selected[0];
                        }
                        props.changeCampaignNameIsMultiple(checked);
                        let params = [];
                        //adding this here instead of adding it on UpdateSelector since this
                        //selector has two parents and updateSelector only gets one
                        params.selectedAccounts = getSelectedValues(
                          props.selectedAccounts,
                          props.selectorAccount,
                        );
                        params.selectedProducts = getSelectedValues(
                          props.selectedProducts,
                          props.selectorProduct,
                        );
                        updateSelector(
                          checked,
                          props.changeSelectedCampaignNames,
                          props.selectedCampaignNames,
                          selected,
                          props.selectorCampaignName,
                          campaignNameSelectorId,
                          props,
                          params,
                          'selectedCampaignNames',
                          props.layoutLoading ? props.layoutLoading : false,
                        );
                      }}
                      value="Multiple"
                    />
                  </Tooltip>{' '}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Select
            onMenuOpen={() => {
              openMenu();
            }}
            styles={theme.reactSelect}
            id={campaignSelectorId}
            isDisabled={
              props.selectorCampaignName.length === 0 || props.isFirstLoad
            }
            placeholder={
              props.isLoadingSideBar && (props.isFirstLoad || !props.autoLoad)
                ? trans('LOADING', props.lang) + '...'
                : props.selectorCampaignName.length === 0
                ? trans('NO_INFO_DROPDOWN', props.lang)
                : trans('SELECT_CAMPAIGN_TYPE', props.lang)
            }
            isMulti={props.campaignNameIsMultiple}
            value={
              !props.isLoadingSideBar || !props.isFirstLoad
                ? props.selectedCampaignNames
                : []
            }
            options={props.selectorCampaignName}
            onChange={e => {
              let params = [];
              //adding this here instead of adding it on UpdateSelector since this
              //selector has two parents and updateSelector only gets one
              params.selectedAccounts = getSelectedValues(
                props.selectedAccounts,
                props.selectorAccount,
              );
              params.selectedProducts = getSelectedValues(
                props.selectedProducts,
                props.selectorProduct,
              );
              updateSelector(
                props.campaignNameIsMultiple,
                props.changeSelectedCampaignNames,
                props.selectedCampaignNames,
                e,
                props.selectorCampaignName,
                campaignNameSelectorId,
                props,
                params,
                'selectedCampaignNames',
                props.layoutLoading ? props.layoutLoading : false,
              );
            }}
          ></Select>
        </div>
      ) : (
        ''
      )}

      {componentBelongsInPage(
        ageSelectorId,
        props.customPageId,
        PageInfo.mapping,
      ) ? (
        <div style={{ width: '90%', margin: '0px auto' }}>
          <Grid container justifyContent="center">
            <Grid container>
              <Grid item xs={11} lg={11} md={11}>
                <Typography>{trans('AGE_RANGE', props.lang)}</Typography>
              </Grid>
              <Grid item xs={1} lg={1} md={1}>
                <div style={{ float: 'right' }}>
                  <Tooltip title="Multiple">
                    <Checkbox
                      disabled={
                        props.selectorAge.length === 0 || props.isFirstLoad
                      }
                      id="ageIsMultiple"
                      style={{ width: 5, height: 5 }}
                      checked={props.ageIsMultiple}
                      onChange={(event, checked) => {
                        let selected = props.selectedAges;
                        if (!checked) {
                          selected = selected[0];
                        }
                        props.changeAgeIsMultiple(checked);

                        updateSelector(
                          checked,
                          props.changeSelectedAges,
                          props.selectedAges,
                          selected,
                          props.selectorAge,
                          ageSelectorId,
                          props,
                          [],
                          'selectedAges',
                          props.layoutLoading ? props.layoutLoading : false,
                        );
                      }}
                      value="Multiple"
                    />
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Select
            onMenuOpen={() => {
              openMenu();
            }}
            styles={theme.reactSelect}
            id={ageSelectorId}
            isDisabled={props.selectorAge.length === 0 || props.isFirstLoad}
            placeholder={
              props.isLoadingSideBar && (props.isFirstLoad || !props.autoLoad)
                ? trans('LOADING', props.lang) + '...'
                : props.selectorAge.length === 0
                ? trans('NO_INFO_DROPDOWN', props.lang)
                : 'Seleccione Producto'
            }
            isMulti={props.ageIsMultiple}
            value={
              !props.isLoadingSideBar || !props.isFirstLoad
                ? props.selectedAges
                : []
            }
            options={props.selectorAge}
            onChange={e => {
              updateSelector(
                props.ageIsMultiple,
                props.changeSelectedAges,
                props.selectedAges,
                e,
                props.selectorAge,
                ageSelectorId,
                props,
                [],
                'selectedAges',
                props.layoutLoading ? props.layoutLoading : false,
              );
            }}
          ></Select>
        </div>
      ) : (
        ''
      )}
      {componentBelongsInPage(
        genderSelectorId,
        props.customPageId,
        PageInfo.mapping,
      ) ? (
        <div style={{ width: '90%', margin: '0px auto' }}>
          <Grid container justifyContent="center">
            <Grid container>
              <Grid item xs={11} lg={11} md={11}>
                <Typography>{trans('GENDER', props.lang)}</Typography>
              </Grid>
              <Grid item xs={1} lg={1} md={1}>
                <div style={{ float: 'right' }}>
                  <Tooltip title="Multiple">
                    <Checkbox
                      disabled={
                        props.selectorGender.length === 0 || props.isFirstLoad
                      }
                      id="genderIsMultiple"
                      style={{ width: 5, height: 5 }}
                      checked={props.genderIsMultiple}
                      onChange={(event, checked) => {
                        let selected = props.selectedGenders;
                        if (!checked) {
                          selected = selected[0];
                        }
                        props.changeGenderIsMultiple(checked);

                        updateSelector(
                          checked,
                          props.changeSelectedGenders,
                          props.selectedGenders,
                          selected,
                          props.selectorGender,
                          genderSelectorId,
                          props,
                          [],
                          'selectedGenders',
                          props.layoutLoading ? props.layoutLoading : false,
                        );
                      }}
                      value="Multiple"
                    />
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Select
            onMenuOpen={() => {
              openMenu();
            }}
            styles={theme.reactSelect}
            id={genderSelectorId}
            isDisabled={props.selectorGender.length === 0 || props.isFirstLoad}
            placeholder={
              props.isLoadingSideBar && (props.isFirstLoad || !props.autoLoad)
                ? trans('LOADING', props.lang) + '...'
                : props.selectorGender.length === 0
                ? trans('NO_INFO_DROPDOWN', props.lang)
                : 'Seleccione Producto'
            }
            isMulti={props.genderIsMultiple}
            value={
              !props.isLoadingSideBar || !props.isFirstLoad
                ? props.selectedGenders
                : []
            }
            options={props.selectorGender}
            onChange={e => {
              updateSelector(
                props.genderIsMultiple,
                props.changeSelectedGenders,
                props.selectedGenders,
                e,
                props.selectorGender,
                genderSelectorId,
                props,
                [],
                'selectedGenders',
                props.layoutLoading ? props.layoutLoading : false,
              );
            }}
          ></Select>
        </div>
      ) : (
        ''
      )}
      {props.showAutoload ? (
        <div style={{ width: '90%', margin: '0px auto' }}>
          <Grid container justifyContent="center">
            <Grid container>
              <Grid item xs={11} lg={11} md={11}>
                <Typography>{trans('AUTO_LOAD', props.lang)}</Typography>
              </Grid>
              <Grid item xs={1} lg={1} md={1}>
                <div style={{ float: 'right' }}>
                  <Tooltip title="Auto Carga">
                    <Checkbox
                      id="autoLoadCheckBox"
                      style={{ width: 5, height: 5 }}
                      checked={props.autoLoad}
                      onChange={(event, checked) => {
                        props.changeAutoLoad(checked);
                      }}
                      value="Multiple"
                    />
                  </Tooltip>
                </div>
              </Grid>{' '}
            </Grid>
          </Grid>
        </div>
      ) : (
        ''
      )}
      <div>
        {!props.autoLoad ? (
          <div style={{ width: '50%', margin: '0px auto' }}>
            <Button
              disabled={props.dateHasErrors || getDisabledLoadButton(props)}
              variant="contained"
              size="small"
              color="primary"
              className={classes.Button}
              onClick={e => {
                PageInfo.finalFunction(
                  { ButtonRequest: true },
                  props,
                  false,
                  true,
                );
              }}
            >
              {props.buttonText ? props.buttonText : trans('LOAD', props.lang)}
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>
      { !props.hideHelp &&
        <div style={{ width: '50%', margin: '0px auto' }}>
          <Button
            variant="outlined"
            size="medium"
            className={classes.HelpButton}
            onClick={e => {
              if (startTour) {
                startTour(productTours.es.home.id);
              }
            }}>
              <HelpIcon fontSize="medium"  />
              {props.buttonText ? props.buttonText : trans('HELP', props.lang)}
          </Button>
        </div>
      }
    </div>
  );
};

const drawerWidth = 320; //acomode esto para las letras del ancho del menu cuando se abre

const styles = theme => ({
  menuButton: {
    marginLeft: 8,
    marginRight: 50,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  Button: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignText: 'center',
    fontSize: '0.75rem',
    marginTop: theme.spacing(2),
  },
  HelpButton: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignText: 'center',
    fontSize: '0.75rem',
    marginTop: theme.spacing(2),
    color: theme.palette.text.hint
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0, //theme.spacing(7) + 40,
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    fontSize: '8rem',
  },
  sidebarList: {
    marginTop: theme.spacing(3),
  },
  tableWidget: {
    overflowX: 'visible',
    width: '90%',
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

export default withStyles(styles, { withTheme: true })(SideBarFilters);
