import React from 'react';
import { Drawer, withStyles } from '@material-ui/core';

import classNames from 'classnames';
import SideBarFilters from './components/SideBarFilters/';
const SidebarView = ({
  classes,
  theme,
  toggleSidebar,
  isSidebarOpened,
  isPermanent,
  location,
  ...props
}) => {
  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
      link="/app/adaccounts"
    >
      <SideBarFilters
        toggleSidebar={toggleSidebar}
        isSidebarOpened={isSidebarOpened}
        isPermanent={isPermanent}
        location={location}
        showAutoload={true}
        {...props}
      ></SideBarFilters>
    </Drawer>
  );
};

const drawerWidth = 320; //acomode esto para las letras del ancho del menu cuando se abre

const styles = theme => ({
  menuButton: {
    marginLeft: 8,
    marginRight: 50,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  Button: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignText: 'center',
    fontSize: '0.75rem',
    marginTop: theme.spacing(2),
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0, //theme.spacing(7) + 40,
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    fontSize: '8rem',
  },
  sidebarList: {
    marginTop: theme.spacing(3),
  },
  tableWidget: {
    overflowX: 'visible',
    width: '90%',
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

export default withStyles(styles, { withTheme: true })(SidebarView);
