import { compose, withState, lifecycle } from 'recompose';
import View from './MapPickerView';

function setLocations(props) {
  props.setLocations([]);
  props.setInitLng(0);
  props.setInitLat(0);
  props.changeInitLocation([String(0), String(0)]);

  navigator.geolocation.getCurrentPosition(position => {
    props.setInitLng(position.coords.longitude);
    props.setInitLat(position.coords.latitude);
    props.changeInitLocation([
      String(position.coords.latitude),
      String(position.coords.longitude),
    ]);
  });

  if ((props.initLng === 0 && props.initLat === 0) || localStorage.QA) {
    props.setInitLng(-90.38858030018991);
    props.setInitLat(15.47477965113324);
    props.changeInitLocation([
      String(15.47477965113324),
      String(-90.38858030018991),
    ]);
  }
}
const mycompose = compose(
  withState('zoom', 'setZoom', 8),
  withState('refresh', 'setRefresh', false),
  withState('map', 'setMap'),
  withState('initLocation', 'changeInitLocation', ['0', '0']),
  withState('style', 'setStyle'),
  withState('size', 'setSize'),
  withState('minZoom', 'setMinZoom', 12),
  withState('markerWasSelected', 'setMarkerWasSelected', false),
  withState('locationSelected', 'setLocationSelected'),
  withState('pagePagination', 'changePagePagination', 0),
  withState('rowsPerPage', 'changeRowsPerPage', 5),
  withState('isGeolocation', 'changeIsGeolocation', true),
  withState('isMap', 'changeIsMap', false),
  withState('geoTargetingSearch', 'setGeoTargetingSearch', '5'),
  withState('geoTargetingSearchText', 'changeGeoTargetingSearchText', ''),
  withState('geoTargetingSelector', 'changeGeoTargetingSelector', []),
  withState('geoTargetingSelected', 'changeGeoTargetingSelected', []),
  withState('manualLat', 'changeManualLat', []),
  withState('manualLng', 'changeManualLng', []),
  lifecycle({
    componentDidMount() {
      setLocations(this.props);
    },
  }),
);

export default mycompose(View);
