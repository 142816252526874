import React from 'react';
import { AppBar, Toolbar, Box, Typography, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { useOnboardingContext } from '../pages/onboarding/context';
import { AlyIcon } from './Icon';
import { palette } from '../pages/onboarding/theme';

const TopBar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isNavBackButtonVisible, showPreviousStep } = useOnboardingContext();
  return (
    <AppBar position="fixed" className={classes.AppBar}>
      <Toolbar className={classes.toolbar}>
        <MenuItem
          className={`${isNavBackButtonVisible ? '' : classes.hideButton}`}
          onClick={showPreviousStep}>
          <ArrowBack className={classes.icon} />
        </MenuItem>
        <Box className={classes.Box}>
          <Typography className={classes.title}>{t('Get started')} </Typography>
        </Box>
        <MenuItem>
          <AlyIcon />
        </MenuItem>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    AppBar: {
      width: '100vw',
      zIndex: theme.zIndex.drawer + 101,
      transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: palette.deepPurple,
    },
    hideButton: { visibility: 'hidden' },
    toolbar: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    Box: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
    },
    title: {
      fontFamily: 'Montserrat-SemiBold',
      fontSize: '16px',
    },
    icon: {
      display: 'block',
      borderRadius: 30,
      marginRight: theme.spacing(2),
      color: palette.white,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

export default TopBar;
