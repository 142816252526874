import { compose, withState, lifecycle } from 'recompose';
import View from './exportReportsView';

const mycompose = compose(
  withState('adTitle', 'changeAdTitle', ['']),
  lifecycle({
    componentDidMount() {},

    componentWillUnmount() {},
    componentDidUpdate() {},
  }),
);

export default mycompose(View);
