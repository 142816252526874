import { axios, axiosHeaders, GenericGetAccountResponse } from './helper';
import { getCompaniesUrl } from '../../components/Constants';

//http://alyai-alb-382630953.us-east-2.elb.amazonaws.com/stat/api/v4.0/account/company?accountId=89&startDate=2021-02-02&endDate=2022-06-21

export interface Company {
  Id: number;
  title: string;
}
export const GetAccountCompanies = async (
  accountId: number,
  startDate: string,
  endDate: string,
) => {
  return await axios.get<GenericGetAccountResponse<Company>>(
    `${getCompaniesUrl}`,
    {
      ...axiosHeaders(),
      params: { accountId, startDate, endDate },
    },
  );
};
