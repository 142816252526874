import React, { useState } from 'react';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  LinearProgress,
  TableFooter,
} from '@material-ui/core';

import trans from './../../../i18n/tran';
// import Help from './../../../components/Help/help';
// import { productTours } from './../../../components/Constants';

function leadstotal(data) {
  return data.map(({ leads }) => leads).reduce((sum, i) => sum + i, 0);
}

const Dashboard12Component = ({ data, ...props}) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  let leadstotales;

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  if (data && data.length > 0) {
    leadstotales = leadstotal(data);
    return (
      <>
      <Table className="mb-0">
        <TableHead>
          <TableRow>
            <TableCell> {trans('IMAGE', props.lang)} </TableCell>
            <TableCell style={{ textAlign: 'center' }}> {trans('ACTIONS', props.lang)} </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(({ id, title, imageurl, gender, leads }) => (
              <TableRow key={id}>
                <TableCell>
                  <img
                    src={imageurl}
                    style={{ maxWidth: 100 }}
                    alt="facebook ad"
                  />
                </TableCell>
                <TableCell>
                  <LinearProgress
                    variant="determinate"
                    value={
                      (leads / (leadstotales > 0 ? leadstotales : 1)) * 100
                    }
                  />
                  <div style={{ textAlign: 'center' }}>
                    {(
                      (leads / (leadstotales > 0 ? leadstotales : 1)) *
                      100
                    ).toFixed(2) + '%'}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell align="right" colSpan={1}></TableCell>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              {trans('ADS_WIDGET_FOOT', props.lang)}:{' ' + leadstotales}
            </TableCell>
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              //component = "div"
              align="right"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={trans('ELEMENTS_PER_PAGE', props.lang)}
            />
          </TableRow>
        </TableFooter>
      </Table>
      </> 
    );
  } else {
    return (
      <div>
        <span>{trans('NO_INFO', props.lang)}</span>
      </div>
    );
  }
};

export default Dashboard12Component;
