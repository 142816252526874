import {
  associateBusinessWithAdAccount,
  deletePermissionsUrl,
  makeAlyBusinessPartner as makeAlyBusinessPartnerRest,
  payWallCreateAccount,
  successHttpCode,
  verifyAccountUrl,
} from '../../../components/Constants';
import { request } from '../../rest';
import { useState } from 'react';
import { AdAccountI } from './types';
import { requestHeaders } from '../../rest/helper';

export const MakeAlyBusinessPartner = async (adAccountId: string) => {
  try {
    const headers = requestHeaders();

    const result = await fetch(makeAlyBusinessPartnerRest, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ adAccountId }),
    });
    if (result.status !== successHttpCode) {
      return false;
    }
    return await result.json();
  } catch (e) {
    console.log('ValidateBusiness exception', e);
  }
};

export const AddAdAccountToBusiness = async (
  accountId: number,
  adAccountId: number,
  categoryId: number,
) => {
  try {
    const headers = requestHeaders();
    const result = await fetch(associateBusinessWithAdAccount, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ accountId, adAccountId, categoryId }),
    });
    if (result.status !== successHttpCode) {
      return false;
    }
    const x = await result.json();

    return x.success;
  } catch (e) {
    console.log('removeAdAccountToBusiness exception', e);
  }
};
export const RemoveAdAccountToBusiness = async (
  accountId: number,
  adAccountId: number,
) => {
  try {
    const headers = requestHeaders();
    const result = await fetch(associateBusinessWithAdAccount, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify({ accountId, adAccountId }),
    });
    if (result.status !== successHttpCode) {
      return false;
    }
    return await result.json();
  } catch (e) {
    console.log('removeAdAccountToBusiness exception', e);
  }
};

export const deletePermissions = async () => {
  try {
    const headers = requestHeaders();

    const result = await fetch(`${deletePermissionsUrl}`, {
      method: 'DELETE',
      headers,
    });

    if (result.status !== successHttpCode) {
      return false;
    }

    const { payload } = await result.json();
    return payload;
  } catch (e) {
    console.log('deletePermissions exception', e);
  }
};

export interface TierJson {
  created_at: string;
  fee: string;
  id: number;
  modified_at: string;
  name: string;
  p_ad_spend: number;
}
export interface GetAdAccountsResponse {
  payload: AdAccountI[];
  success: boolean;
}
export interface CreateNewAccountWithPaymentParams {
  accountId: number;
  email: string;
  accountName: string;
  taxId: string;
  country: string;
  address: string;
  firstName: string;
  lastName: string;
  phone: string;
  tierId: number;
  cardNumber: string;
  cardExpDate: string;
  cardCVV: string;
  cardHolderName: string;
  billingCoupon?: string;
}
//http://payaly-lb-327464353.us-east-2.elb.amazonaws.com/index.php/api/account

export interface CreateNewAccountWithPaymentResponse {
  code: number;
  desc: string;
  more_info: string;
}

export const useCreateBusiness = () => {
  const [isLoading, setIsLoading] = useState(false);
  const CreateBusiness = async (accountName: string | undefined) => {
    setIsLoading(true);

    try {
      const headers = requestHeaders();
      const result = await fetch(`${verifyAccountUrl}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ accountName }),
      });

      if (result.status !== successHttpCode) {
        return false;
      }
      const { payload } = await result.json();
      const value = payload[0];
      return value.iAccountId;
    } catch (e) {
      console.log('CreateBusiness exception', e);
    } finally {
      setIsLoading(false);
    }
  };
  return { isLoading, CreateBusiness };
};

export const useCreateNewAccountWithPayment = () => {
  const [isLoading, setIsLoading] = useState(false);

  const createNewAccountWithPayment = async (
    params: CreateNewAccountWithPaymentParams,
  ) => {
    setIsLoading(true);
    try {
      const headers = requestHeaders();
      return await request<CreateNewAccountWithPaymentResponse>(
        payWallCreateAccount,
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(params),
        },
      );
    } catch (e) {
      console.log('CreateNewAccountWithPayment exception', e);
      const error: CreateNewAccountWithPaymentResponse = {
        code: 500,
        desc: 'e.toString()',
        more_info: '',
      };
      return error;
    } finally {
      setIsLoading(false);
    }
  };
  return { isLoading, createNewAccountWithPayment };
};
