import React from 'react';
import { withStyles } from '@material-ui/core';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import MapPicker from '../../../components/MapPicker';

const Location = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <MapPicker
      id={'map_picker'}
      lang={props.lang}
      {...props}
      showLocationWiget={true}
      focusable={
        <Focusable id={props.focusId} refList={props.refList}></Focusable>
      }
    />
  );
};
export default withStyles(styles, { withTheme: true })(Location);
