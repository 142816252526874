import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, withStyles, Button } from '@material-ui/core';
import allylogo2 from '../../images/ALY.svg';
//import Login from '../login';

const SessionExpired = ({ classes }) => (
  <Grid container className={classes.container}>
    <img src={allylogo2} alt="aly-ai logo" width="25%"></img>
    <Typography
      color="textSecondary"
      component="h2"
      variant="h5"
      className={classes.safetyText}
    >
      TU SESIÓN EXPIRÓ
    </Typography>
    <Button
      variant="contained"
      color="primary"
      component={Link}
      to="/login"
      size="large"
      className={classes.button}
    >
      Volver al Inicio
    </Button>
  </Grid>
);

const styles = theme => ({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#100047',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  safetyText: {
    paddingBottom: theme.spacing(2),
  },
});

export default withStyles(styles, { withTheme: true })(SessionExpired);
