import React from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import Widget from '../../../../components/Widget';
import {
  objectives,
  productTours
} from '../../../../components/Constants';
import { clearAdPage } from '../../../../utils/functions';
import Select from 'react-select';
import Help from './../../../../components/Help/help';
import trans from './../../../../i18n/tran';
const SelectAdObjective = ({ classes, theme, onChange, value, ...props }) => {
  objectives.forEach(ele => {
    ele.label = trans(ele.facebook_name, props.lang)
  });

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={12}>
          <Widget
            overflow={true}
            title={<div>{trans('NEW_CAMPAIGN_SUB_TITLE', props.lang)}</div>}
            bodyClass={classes.tableWidget}
            disableWidgetMenu={true}
          >
            <Typography>{trans('NEW_CAMPAIGN_OBJECTIVE_SELECT', props.lang)}</Typography>
            <Select
              id={'Objective'}
              isMulti={false}
              value={props.selectedAdminCampaignTypes}
              styles={theme.reactSelect}
              placeholder={trans('NEW_CAMPAIGN_OBJECTIVE_PLACEHOLDER', props.lang)}
              options={objectives}
              onChange={e => {
                //console.log(e)
                props.changeSelectedAdminCampaignTypes([e]);
                props.changeSelectedCta([]);
                props.changeSelectorCta([]);
                props.changeSelectedAppStores([]);
                props.changeSelectedApplications([]);
                if (props.providers.length > 0) {
                  clearAdPage(props, e.value, props.providers[0], true);
                  props.changeFacebookPositions([]);
                  props.changeInstagramPositions([]);
                  props.changeProviders([]);
                }
              }}
            ></Select>
              <Help
                className={"HelpButton"} 
                lang={props.lang} 
                productTourId={productTours.es.newCampaignObjective.id} /> 
          </Widget>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const styles = theme => ({
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  infoMessage: {
    color: '#9F6000',
    backgroundColor: '#FEEFB3',
    margin: theme.spacing(1),
  },
  textField: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: 500,
    borderColor: '#013f8a',
  },
  margin: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
    width: 400,
  },
  fanButton: {
    float: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(1),
    alignText: 'center',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(0),
  },
  tableWidget: {
    overflowX: 'visible',
  },
  datePicker: {
    overflowX: 'visible',
    padding: theme.spacing(2),
    margin: 'auto',
  },
  errorIcon: {
    fontSize: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    float: 'left',
  },
  warningMessage: {
    fontSize: '0.9rem',
    height: '1.5rem',
    paddingTop: '0.3rem',
    float: 'left',
  },
});

export default withStyles(styles, { withTheme: true })(SelectAdObjective);
