import React from 'react';
import { withStyles, Typography, Grid } from '@material-ui/core';
import Widget from '../../../components/Widget';
import Select from 'react-select';
import Focusable from '../Focusable';
import {
  getFanPagesAdminApi,
  getLeadFormsAdminApi,
  getPixelAdminApi,
  getApplicationAdminApi,
  getInstagramAccountAdminApi,
  getAudience,
} from '../../../utils/functions';
import { styles } from '../../../utils/styles';
import LeadGenerationForms from './LeadGenerationForms';
import InstagramAccount from './InstagramAccount';
import { adAccountSelectorId } from '../../../components/Constants';
import ErrorIcon from '@material-ui/icons/Error';
import trans from './../../../i18n/tran';
const FanPage = ({ classes, theme, onChange, value, children, ...props }) => {
  return (
    <Widget
      overflow={true}
      focusable={
        <Focusable id={props.focusId} refList={props.refList}></Focusable>
      }
      title={
        <div>
          <div style={{ height: '1.5rem' }}>
            <div> {trans('NEW_CAMPAIGN_SUB_TITLE_2', props.lang)}</div>
            {props.hasErrorFanPage && props.showAdErrors ? (
              <div
                style={{
                  color: 'red',
                }}
              >
                <div className={classes.errorIcon}>
                  <ErrorIcon></ErrorIcon>
                </div>
                <div className={classes.warningMessage}>
                  {props.selectedAdminAdAccounts.length === 0
                    ? trans('PRODUCT_FORM_ACCOUNT_PLACEHOLDER', props.lang)
                    : props.leadGenerationForms &&
                      props.selectedLeadGenerationForms.length === 0
                    ? trans('NEW_CAMPAIGN_FORM_SELECT', props.lang)
                    : trans('NEW_CAMPAIGN_FANPAGE_SELECT', props.lang)}
                </div>
              </div>
            ) : (
              ''
            )}
            <br />
          </div>
        </div>
      }
      bodyClass={classes.tableWidget}
      disableWidgetMenu={true}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>{trans('AD_ACCOUNT', props.lang)}</Typography>
          <Select
            id={adAccountSelectorId}
            styles={theme.reactSelect}
            isDisabled={props.selectorAdminAdAccount.length === 0}
            placeholder={
              props.selectedTenants.length === 0
                ? trans('BUSINESS_SELECT', props.lang)
                : props.selectorAdminAdAccount.length === 0
                ? trans('NEW_CAMPAIGN_ERROR1', props.lang)
                : trans('AD_ACCOUNT_SELECT', props.lang)
            }
            isMulti={false}
            value={props.selectedAdminAdAccounts}
            options={props.selectorAdminAdAccount}
            onChange={async e => {
              if (props.pixels) getPixelAdminApi(props, [e]);
              if (props.application) getApplicationAdminApi(props, [e]);
              getAudience(props, {
                selectedAdAccount: e,
              });

              getFanPagesAdminApi(props, [], {
                validateEmpty: true,
                validateEmptyMessage: trans('NEW_CAMPAIGN_ERROR2', props.lang),
              });
              props.changeSelectedAdminAdAccounts([e]);
              props.changeHasErrorFanPage(true);
              props.changeHasErrorPixel(true);
              props.changeHasErrorApplication(true);
              props.changeSelectedAdminFanPages([]);
              props.changeSelectedLeadGenerationForms([]);
              props.changeSelectedAdminInstagramAccounts([]);
              props.changeSelectedPixels([]);
              props.changeSelectedPixelEvents([]);
              props.changeSelectedUsedPixelEvents([]);
              props.changeSelectedApplications([]);
              props.changeSelectedAppStores([]);
            }}
          ></Select>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography>{trans('NEW_CAMPAIGN_FANPAGE', props.lang)}</Typography>
              <Select
                id={'FanPage_Selector'}
                styles={theme.reactSelect}
                isDisabled={props.selectorAdminFanPage.length === 0}
                options={props.selectorAdminFanPage}
                value={props.selectedAdminFanPages}
                placeholder={
                  props.selectedAdminAdAccounts.length === 0
                    ? trans('NEW_CAMPAIGN_FANPAGE_SELECT', props.lang)
                    : props.selectorAdminFanPage.length === 0
                    ? trans('NEW_CAMPAIGN_ERROR3', props.lang)
                    : trans('NEW_CAMPAIGN_FANPAGE_SELECT', props.lang)
                }
                onChange={e => {
                  props.changeSelectedConversations([]);
                  props.changeSelectedAdminFanPages(e);
                  if (props.leadGenerationForms)
                    getLeadFormsAdminApi(props, [e]);
                  if (props.instagramAccount)
                    getInstagramAccountAdminApi(props, [e]);
                  if (props.leadGenerationForms)
                    props.changeHasErrorFanPage(true);
                  else props.changeHasErrorFanPage(false);
                  props.changeSelectedLeadGenerationForms([]);
                  props.changeSelectedAdminInstagramAccounts([]);
                }}
              ></Select>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {props.leadGenerationForms ? (
            <LeadGenerationForms focusId={''} {...props}></LeadGenerationForms>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12}>
          {' '}
          {props.instagramAccount ? (
            <InstagramAccount focusId={''} {...props}></InstagramAccount>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(FanPage);
