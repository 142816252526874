import React, { FC, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useNavBarContext, NavBarWrapper } from '../../components/NavBar';
import { StandardIndicator } from '../../components/StandardIndicator';
import { HomeContextWrapper, useHomeContext } from './context';
import {
  HomeFiltersContextWrapper,
  useHomeFiltersContext,
} from '../../context/homeFilters';
import IconButton from '@material-ui/core/IconButton';
import FilterList from '@material-ui/icons/FilterList';

const Home2: FC = () => {
  const { setDocumentTitle } = useNavBarContext();
  useEffect(() => {
    setDocumentTitle('Dashboard/Home');
  }, [setDocumentTitle]);

  const classes = useStyles();
  return (
    <NavBarWrapper>
      <HomeFiltersContextWrapper>
        <HomeContextWrapper>
          <div className={classes.root}>
            <FiterBar />
            <Area />
          </div>
        </HomeContextWrapper>
      </HomeFiltersContextWrapper>
    </NavBarWrapper>
  );
};

const FiterBar: FC = () => {
  const classes = useStyles();
  const { openFilterDrawer } = useHomeFiltersContext();
  return (
    <div>
      <IconButton
        onClick={openFilterDrawer}
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu">
        <FilterList />
      </IconButton>
    </div>
  );
};

const Area = () => {
  const classes = useStyles();

  const { summedStandardIndicator } = useHomeContext();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Standard Indicators</Typography>
      </AccordionSummary>
      {summedStandardIndicator ? (
        <div className="grid grid-cols-4 gap-4">
          <StandardIndicator
            indicatorType="impressions"
            value={summedStandardIndicator.Impressions}
          />

          <StandardIndicator
            indicatorType="cost"
            value={summedStandardIndicator.Cost}
          />

          <StandardIndicator
            indicatorType="clicks"
            value={summedStandardIndicator.LinkClicks}
          />

          <StandardIndicator
            indicatorType="actions"
            value={summedStandardIndicator.Actions}
          />

          <StandardIndicator
            indicatorType="ctr"
            value={summedStandardIndicator.CTR}
          />

          <StandardIndicator
            indicatorType="cvr"
            value={summedStandardIndicator.CVR}
          />

          <StandardIndicator
            indicatorType="cpa"
            value={summedStandardIndicator.CPA}
          />

          <StandardIndicator
            indicatorType="cpm"
            value={summedStandardIndicator.CPM}
          />
        </div>
      ) : (
        <CircularProgress />
      )}
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',

      height: '100%',
      overflow: 'hidden',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    fakeToolbar: {
      ...theme.mixins.toolbar,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    cardRoot: {
      marginBottom: 20,
    },
    cardContent: {},
  }),
);

export default Home2;
