import DownloadStats from './DownloadStats';
import { compose, withState, lifecycle } from 'recompose';

// import { getAccountsApiAdmin } from "../../utils/functions";

const mycompose = compose(
  withState('dataSet10Json', 'changedataSet10Json', []),
  withState('dataSet11Json', 'changedataSet11Json', []),

  lifecycle({
    componentDidMount() {
      //getAccountsApiAdmin(this.props);
      this.props.changeTenantChangeFunction({
        function: newTenenatValue => {
          this.props.changedataSet10Json([]);
          this.props.changedataSet11Json([]);
        },
      });
    },

    componentWillUnmount() {},
    componentDidUpdate() {},
  }),
);

export default mycompose(DownloadStats);
