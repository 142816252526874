import React, { useCallback, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { CountryField } from '../../../components/CountryField';
import { useOnboardingContext } from '../context';
import { StorePlusIcon, UserIcon } from '../../../components/Icon';
import { BusinessCategoriesField } from '../../../components/BusinessCategoriesField';
import { FormValues, OnBoardingStep } from './type';
import { useCreateBusiness } from '../rest';

import i18n from '../../../../i18n';
import { useTranslation } from 'react-i18next';
import { TextFieldConfig } from '../utils';
import { CheckBusinessExist } from '../../../rest';

const BusinessProfile = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isSubmitting, values, setFieldValue, setSubmitting, handleSubmit } =
    useFormikContext<FormValues>();

  useEffect(
    () => {
      const call = async () => {
        const response = await CheckBusinessExist(values.businessName);
        const result = response.success && !response.payload[0].iAccountId;
        setFieldValue('businessNameValid', result);
      };
      call().then();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.businessName],
  );

  const { CreateBusiness } = useCreateBusiness();
  const next = useCallback(async () => {
    if (!values.businessId) {
      setSubmitting(true);
      const businessId = await CreateBusiness(values.businessName);

      setSubmitting(false);
      if (businessId) {
        setFieldValue('businessId', businessId);
      }

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values]);

  const { setNavBackButtonVisible } = useOnboardingContext();
  useEffect(
    () => {
      setNavBackButtonVisible(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.title}>
            <StorePlusIcon /> {t('Your Aly business account')}
          </Typography>
          <Grid container spacing={2} className={classes.field}>
            <Grid item xs={12} sm={6} md={6}>
              <Field
                required
                label={t('Business account Name')}
                name="businessName"
                placeholder={t('Enter Business name')}
                component={TextField}
                variant="outlined"
                {...TextFieldConfig}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Field
                required
                label={t('Business category')}
                name="businessCategory"
                placeholder={t('Select Business category')}
                component={BusinessCategoriesField}
                variant="outlined"
                {...TextFieldConfig}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.cardRoot}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.title}>
            <UserIcon /> {t('Personal information')}
          </Typography>

          <Grid container spacing={2} className={classes.field}>
            <Grid item xs={12} sm={6} md={6}>
              <Field
                required
                name="firstName"
                label={t('Client first name')}
                placeholder={t('First Name')}
                variant="outlined"
                component={TextField}
                {...TextFieldConfig}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.field}>
            <Grid item xs={12} sm={6} md={6}>
              <Field
                required
                name="lastName"
                label={t('Client last name')}
                placeholder={t('Last Name')}
                variant="outlined"
                component={TextField}
                {...TextFieldConfig}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.field}>
            <Grid item xs={12} md={6} lg={6}>
              <Field
                required
                name="email"
                label={t('Email')}
                variant="outlined"
                placeholder={t('Enter email')}
                component={TextField}
                {...TextFieldConfig}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.field}>
            <Grid item xs={12} md={6} lg={6}>
              <Field
                required
                name="phoneNumber"
                label={t('Phone')}
                variant="outlined"
                placeholder={t('Enter Phone number')}
                component={TextField}
                {...TextFieldConfig}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Field
                required
                name="country"
                label={t('Country')}
                placeholder={t('Select Country')}
                component={CountryField}
                variant="outlined"
                {...TextFieldConfig}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Button
        onClick={async e => {
          await next();
          // @ts-ignore
          handleSubmit(e);
        }}
        fullWidth
        disabled={isSubmitting}
        style={{ textTransform: 'none' }}
        color="primary"
        variant="contained">
        {t('Choose an account')}
      </Button>
    </>
  );
};

export const BusinessProfileStep: OnBoardingStep = {
  component: BusinessProfile,
  label: i18n.t('Business Profile Setup'),
  initialValues: {
    businessName: '',
    businessNameValid: false,
    businessId: undefined,
    businessCategory: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    country: '',
  },
  welcomeTitle: '',
  welcomeDescription: '',
  validationSchema: Yup.object().shape({
    businessName: Yup.string()
      .required(i18n.t('Please enter your business name'))
      .test(
        'Unique business name',
        i18n.t('Business name taken'),
        (value, context) => {
          if (context.parent.businessId) {
            return true;
          }

          return !!context.parent.businessNameValid;
        },
      )
      .trim(i18n.t('Value cannot include leading and trailing spaces')),
    businessNameValid: Yup.boolean().required(
      i18n.t('Please enter your business name'),
    ),
    businessCategory: Yup.number()
      .integer()
      .required(i18n.t('Please select your business category')),
    firstName: Yup.string()
      .min(1, i18n.t('Please enter your first name'))
      .required(i18n.t('Please enter your first name'))
      .trim(i18n.t('Value cannot include leading and trailing spaces')),
    lastName: Yup.string()
      .required(i18n.t('Please enter your last name'))
      .trim(i18n.t('Value cannot include leading and trailing spaces')),
    email: Yup.string()
      .email()
      .required(i18n.t('Please enter a valid email address'))
      .trim(i18n.t('Value cannot include leading and trailing spaces')),
    phoneNumber: Yup.string()
      .required(i18n.t('Please enter a phone number'))
      .trim(i18n.t('Value cannot include leading and trailing spaces')),
    country: Yup.string()
      .min(1, i18n.t('Please select a country'))
      .required(i18n.t('Please select a country')),
  }),
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      marginBottom: 20,
    },
    cardContent: {},
    button: { textTransform: 'none', height: 38 },
    title: {
      fontFamily: 'Montserrat-Regular',
      fontSize: '11px',
      lineHeight: '16px',
      marginBottom: 20,
    },
    rowWithButton: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    field: {
      marginBottom: '24px',
    },
    labelField: {
      fontFamily: 'Montserrat-Medium',
      fontSize: 13,
      marginBottom: 4,
    },
    labelFieldMandatory: {
      fontFamily: 'Montserrat-Medium',
      fontSize: 19,
      color: 'red',
    },
  }),
);
