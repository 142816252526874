import React from 'react';
import { withStyles, Grid, ListItem } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WebIcon from '@material-ui/icons/Web';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Link from '@material-ui/core/Link';
import './../../../../i18n/tran';
import trans from './../../../../i18n/tran';
import Collapse from '@material-ui/core/Collapse';

const Help = ({ classes, theme, ...props }) => {
  const funcFBIcon = () => {
    return <FacebookIcon />;
  };
  const funcInstIcon = () => {
    return <InstagramIcon />;
  };
  const funcLinkIcon = () => {
    return <WebIcon />;
  };

  let cols = [];
  let linkGroups = [
    {
      id: 0,
      text: trans('NEW_ACCOUNT_HELP_LG_HEAD_1', props.lang),
      links: [
        {
          id: 1,
          text: trans('NEW_ACCOUNT_HELP_1', props.lang),
          href: 'https://www.facebook.com/pages/create/?ref_type=hc',
          icon: funcFBIcon,
        },
        {
          id: 2,
          text: trans('NEW_ACCOUNT_HELP_2', props.lang),
          href: 'https://www.facebook.com/help/187316341316631',
          icon: funcFBIcon,
        },
      ],
    },
    {
      id: 1,
      text: trans('NEW_ACCOUNT_HELP_LG_HEAD_2', props.lang),
      links: [
        {
          id: 3,
          text: trans('NEW_ACCOUNT_HELP_1', props.lang),
          href: 'https://www.facebook.com/help/instagram/155940534568753',
          icon: funcInstIcon,
        },
      ],
    },
  ];
  cols.push(linkGroups);
  linkGroups = [
    {
      id: 2,
      text: trans('NEW_ACCOUNT_HELP_LG_HEAD_3', props.lang),
      links: [
        {
          id: 5,
          text: trans('NEW_ACCOUNT_HELP_7', props.lang),
          href: 'https://www.facebook.com/business/help/1710077379203657?id=180505742745347',
          icon: funcFBIcon,
        },
        {
          id: 6,
          text: trans('NEW_ACCOUNT_HELP_4', props.lang),
          href: 'https://www.facebook.com/business/help/442345745885606?id=180505742745347',
          icon: funcFBIcon,
        },
      ],
    },
    {
      id: 3,
      text: trans('NEW_ACCOUNT_HELP_LG_HEAD_4', props.lang),
      links: [
        {
          id: 7,
          text: trans('NEW_ACCOUNT_HELP_5', props.lang),
          href: 'https://www.facebook.com/business/help/915454841921082?id=180505742745347',
          icon: funcFBIcon,
        },
      ],
    },
  ];
  cols.push(linkGroups);

  linkGroups = [
    {
      id: 4,
      text: trans('NEW_ACCOUNT_HELP_LG_HEAD_5', props.lang),
      links: [
        {
          id: 8,
          text: trans('NEW_ACCOUNT_HELP_8', props.lang),
          href: 'https://www.facebook.com/business/help/910137316041095?id=420299598837059',
          icon: funcFBIcon,
        },
        {
          id: 9,
          text: trans('NEW_ACCOUNT_HELP_6', props.lang),
          href: 'https://www.facebook.com/business/help/1693157020808397?recommended_by=910137316041095',
          icon: funcFBIcon,
        },
        {
          id: 10,
          text: trans('NEW_ACCOUNT_HELP_LG_HEAD_6', props.lang),
          links: [],
          icon: funcLinkIcon,
        },
      ],
    },
  ];
  cols.push(linkGroups);

  return (
    <>
      <Grid container spacing={4} direction="row" alignContent="space-between">
        {cols &&
          cols.length > 0 &&
          cols.map((linkGroups, indexc) => (
            <Grid item xs={4} md={4} lg={4} key={indexc}>
              <Box component="span" m={1}>
                <List component="nav">
                  {linkGroups &&
                    linkGroups.length > 0 &&
                    linkGroups.map((lg, index1) => (
                      <React.Fragment key={index1}>
                        <ListItem button key={index1}>
                          {lg.text}
                        </ListItem>
                        <Collapse
                          in={true}
                          timeout="auto"
                          unmountOnExit
                          key={'coll' + lg.id.toString()}
                        >
                          <List component="div" disablePadding>
                            {lg.links.map(link => (
                              <ListItem
                                button
                                className={classes.nested}
                                key={'list_item_item' + link.id.toString()}
                              >
                                <ListItemIcon key={link.id}>
                                  {link.icon()}
                                </ListItemIcon>
                                <Link
                                  rel="noreferrer"
                                  target="_blank"
                                  href={link.href}
                                  color="inherit"
                                >
                                  {link.text}
                                </Link>
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    ))}
                </List>
              </Box>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default withStyles({}, { withTheme: true })(Help);
