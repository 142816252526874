import React, { Component } from 'react';
import Dropzone from '../dropzone/Dropzone';
import './Upload.css';
import Progress from '../progress/Progress';
import CheckCircle from '../../../../../images/CheckCircle.svg';
import { saveAsset } from './../../../../../components/Constants';
import CustomizedSnackbars from './../../../../../components/SnackBar/CustomizedSnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
// import { validate } from "@material-ui/pickers";

class Upload extends Component {
  constructor(props) {
    super(props);
    // console.log(props.lang)
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      loading: true,
      invalidTypeFile: false,
      invalidDimension: false,
      invalidSize: false,
      invalidDuration: false,
      fileNameSlice: 0,
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("Upload:componentDidUpdate")
    //console.log("Upload:reset:"+ this.props.reset)
    if (this.props.reset) {
      //console.log("reset:"+this.props.reset)
      ////console.log("files.length:"+this.state.files.length)
      if (this.state.files.length > 0) {
        this.setState({ files: [] });
      }
      this.childDropzone.resetRef();
    }
    if (prevProps.uploadAllowedFiles !== this.props.uploadAllowedFiles) {
      this.validateFiles(this.state.files.length, this.state.files);
    }
    // console.log(this.props.lang)
  }

  fetchData = () => {
    //console.log(this.state.files.length);
    if (this.state.files.length === 0) {
      this.handleOpen(
        'error',
        'Debe de seleccionar al menos un archivo para cargar.',
      );
      return;
    }
    if (this.state.files.length > 3) {
      this.handleOpen(
        'warning',
        'Debe de seleccionar como máximo tres archivos en una carga.',
      );
      return;
    }

    this.setState({ loading: true });
    //Faking API call here
  };

  onFilesAdded(fillesToAdd) {
    //console.log(fillesToAdd)
    //console.log(this.state.files)
    let files = [];
    let newfiles = [];
    if (fillesToAdd.length > 0) {
      for (let index = 0; index < fillesToAdd.length; index++) {
        const file = fillesToAdd[index];
        let findindex = this.state.files.findIndex(function (item, i) {
          return item.name === file.name;
        });
        if (findindex === -1) files.push(file);
      }
    }
    // console.log("new files:")
    // console.log(files)
    newfiles = this.state.files.concat(files);
    this.setState(prevState => ({
      files: newfiles,
    }));

    //console.log("new state:")
    // console.log(this.state.files)
    // let length = files.length + this.state.files.length;
    let length = newfiles.length;
    this.props.changeList(newfiles);
    this.validateFiles(length, newfiles);
    if (length > this.props.maxListSize) this.props.changeInvalidMaxFiles(true);
    else this.props.changeInvalidMaxFiles(false);
    if (this.props.changeReset) this.props.changeReset(false);
    if (this.props.selectedUploadType !== '1' && files.length > 0)
      this.validateMetaData(files[0]);
  }

  validateMetaData(file) {
    this.setState(prevState => ({ invalidDimension: false }));
    this.setState(prevState => ({ invalidSize: false }));
    this.setState(prevState => ({ invalidDuration: false }));
    this.props.changeInvalidDimension(false);
    this.props.changeInvalidSize(false);
    this.props.changeInvalidDuration(false);
    console.log(file.size / 1048576);
    if (file.size / 1048576 < 1 || file.size / 1048576 > 100) {
      this.setState(prevState => ({ invalidSize: true }));
      this.props.changeInvalidSize(true);
      this.props.changeError(true);
    }
    let video = document.createElement('video');
    video.addEventListener('loadedmetadata', event => {
      if (video.videoHeight < 120 || video.videoWidth < 120) {
        this.setState(prevState => ({ invalidDimension: true }));
        this.props.changeInvalidDimension(true);
        this.props.changeError(true);
      }
      if (video.duration < 1 || video.duration > 14460) {
        this.setState(prevState => ({ invalidDuration: true }));
        this.props.changeInvalidDuration(true);
        this.props.changeError(true);
      }
      /*console.log(video.videoWidth)
			console.log(video.videoHeight)
			console.log(video.duration)*/
    });
    video.src = URL.createObjectURL(file);
  }

  onFilesRemoved = fileIndex => {
    this.childDropzone.removeFileByIndex(fileIndex);
    ////console.log("archivo a eliminar:"+fileIndex);
    let temporalArray = [...this.state.files];
    temporalArray.splice(fileIndex, 1);
    this.setState(prevState => ({
      files: temporalArray,
    }));
    let length = this.state.files.length - 1;
    this.props.changeList(temporalArray);
    this.validateFiles(length, temporalArray);
    if (length > this.props.maxListSize) this.props.changeInvalidMaxFiles(true);
    else this.props.changeInvalidMaxFiles(false);
    if (this.props.changeReset) this.props.changeReset(false);
    if (this.props.selectedUploadType !== '1') {
      this.setState(prevState => ({ invalidDimension: false }));
      this.setState(prevState => ({ invalidSize: false }));
      this.setState(prevState => ({ invalidDuration: false }));
      this.props.changeInvalidDimension(false);
      this.props.changeInvalidSize(false);
      this.props.changeInvalidDuration(false);
    }
  };

  validateFiles(length, files) {
    let validatedLength = this.validateLength(length);
    let validatedFilesExtensions = this.validateFilesExtensions(files);
    this.props.changeError(validatedLength || validatedFilesExtensions);
    //this.props.changeVisiblePreview(validatedLength || validatedFilesExtensions)
  }

  validateLength(length) {
    //console.log(length);
    //console.log(this.props.maxListSize);
    if (length > this.props.maxListSize || length < this.props.minListSize) {
      //this.props.changeError(true);
      return true;
    } else {
      //this.props.changeError(false);
      return false;
    }
  }

  validateFilesExtensions(files) {
    if (files.length === 0) return true;
    const validFileExtensions = this.props.uploadAllowedFiles;
    let count = 0;
    for (let i = 0; i < files.length; i++) {
      let extension = files[i].name.substring(
        files[i].name.lastIndexOf('.'),
        files[i].name.length,
      );
      for (let j = 0; j < validFileExtensions.length; j++) {
        if (extension.toLowerCase() === validFileExtensions[j].toLowerCase())
          count++;
      }
    }
    if (count === files.length) {
      ////console.log("archivos validos")
      //this.props.changeError(false);
      this.setState(prevState => ({ invalidTypeFile: false }));
      this.props.changeInvalidTypeFile(false);
      return false;
    } else {
      ////console.log("archivos invalidos")
      this.setState(prevState => ({ invalidTypeFile: true }));
      this.props.changeInvalidTypeFile(true);
      //this.props.changeError(true);
      return true;
    }
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach(file => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);

      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...u
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      let myHeaders = new Headers();
      myHeaders.append(
        'Authorization',
        `Bearer ${localStorage.getItem('access_token')}`,
      );
      myHeaders.append('nonce', localStorage.getItem('nonce'));

      const formData = new FormData();
      formData.append('file', file, file.name);

      fetch(`${saveAsset}/act_1295663983934978`, {
        method: 'POST',
        headers: myHeaders,
        body: formData,
      })
        .then(response => {
          return response.json();
        })
        .then(responseString => {
          ////console.log(responseString)
        })
        .catch(err => {
          ////////console.log(err)
        });
    });
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src={CheckCircle}
            style={{
              opacity:
                uploadProgress && uploadProgress.state === 'done' ? 0.5 : 0,
            }}
          />
        </div>
      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Volver Elegir Archivos
        </button>
      );
    } else {
      return <button disabled={true}>Cargar Archivos</button>;
    }
  }

  showSnackBar(open, variant, message) {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={this.handleClose}
      >
        <CustomizedSnackbars
          onClose={this.handleClose}
          variant={variant}
          message={message}
        />
      </Snackbar>
    );
  }

  handleOpen = (variantP, messageP) =>
    this.setState({ open: true, variant: variantP, message: messageP });

  handleClose = () => this.setState({ open: false });

  render() {
    const { open } = this.state;
    const { variant } = this.state;
    const { message } = this.state;
    // const { invalidTypeFile } = this.state;
    // const { invalidDimension } = this.state;
    // const { invalidSize } = this.state;
    // const { invalidDuration } = this.state;
    let lengthSlice = 0;
    lengthSlice = 80;
    return (
      <div className="Upload">
        <div className="Content">
          <Dropzone
            onFilesAdded={this.onFilesAdded}
            disabled={this.state.uploading || this.state.successfullUploaded}
            uploadAllowedFiles={this.state.uploadAllowedFiles}
            ref={instance => {
              this.childDropzone = instance;
            }}
            lang={this.props.lang}
          />
        </div>

        <div className="Files">
          {this.state.files.map((file, index) => {
            return (
              <div key={file.name} className="Row">
                <Tooltip title={file.name} placement="right">
                  <span className="Filename">
                    {file.name.length > lengthSlice
                      ? file.name.slice(0, lengthSlice - 3).concat('...')
                      : file.name}
                    <IconButton
                      key="close"
                      aria-label="close"
                      color="inherit"
                      onClick={() => {
                        this.onFilesRemoved(index);
                      }}
                    >
                      <CloseIcon style={{ fontSize: '0.7rem' }} />
                    </IconButton>
                  </span>
                </Tooltip>
                {this.renderProgress(file)}
              </div>
            );
          })}
        </div>
        {/*div className="Actions">{this.renderActions()}</div>*/}
        <div className="snackBar">
          {this.showSnackBar(open, variant, message)}
        </div>
      </div>
    );
  }
}

export default Upload;
