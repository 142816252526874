import Auth from '../../authentication/Auth';
import { saveDraft } from '../Constants';

//function saveDraftRequest (adAccountId, titles, bodies, files) {
export const saveDraftRequest = (copyDraft, callback) => {
  //let url = `${saveDraft}/${copyDraft.adaccountid}`
  let url = `${saveDraft}`;

  let myHeaders = new Headers();
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('access_token')}`,
  );
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('nonce', localStorage.getItem('nonce'));
  let bodyForm = JSON.stringify(copyDraft);
  //console.log(copyDraft)
  //
  fetch(url, {
    method: 'POST',
    headers: myHeaders,
    body: bodyForm,
  })
    .then(response => {
      if (response.status === 401) {
        let auth = new Auth();

        auth.sessionExpired();
      } else {
        return response.json();
      }
    })
    .then(resultString => {
      ////console.log(resultString)
      // ////console.log(`data set 0 ${resultString}`)
      if (resultString.success) {
        callback(undefined, resultString.payload);
        //return resultString.payload
      } else {
        if (resultString.message !== undefined)
          callback(resultString.message, undefined);
        else callback('Unknown error', undefined);
      }
    })
    .catch(err => {
      callback(err, undefined);
      //////console.log(err)
    });
};
