import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import Widget from '../../../../components/Widget/WidgetContainer';

import EnhancedTable from '../../../../components/EnhancedTable/Container';

const AccountsView = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <Widget
          title={
            <div>
              <h2 style={{ textAlign: 'center' }}>Negocios Administradores</h2>
            </div>
          }
          bodyClass={classes.tableWidget}
          disableWidgetMenu={true}
        >
          <div style={{ overflow: 'hidden' }}>
            <EnhancedTable
              updateTable={props.updateTable}
              mainId="value"
              changeRefresh={props.changeRefresh}
              refresh={props.refresh}
              ignoreTableClass={true}
              resizeWidthCodes={['xs', 'xs-sm', 'sm']}
              headCells={[
                {
                  id: 'label',
                  center: true,
                  disablePadding: true,
                  label: 'Negocio Administrador',
                },
                {
                  id: 'UniqueKey',
                  center: true,
                  disablePadding: false,
                  label: 'Llave Única',
                },
                {
                  id: 'status',
                  center: true,
                  disablePadding: true,
                  label: props.isApproval ? 'Activar' : 'Estatus',
                  isButton: props.isApproval,
                },
              ]}
              rows={props.selectorAdminAccount}
              page={props.pagePagination}
              setPage={props.changePagePagination}
            ></EnhancedTable>
          </div>
          <div style={{ height: '50px' }}></div>
        </Widget>
      </Grid>
    </React.Fragment>
  );
};

const styles = theme => ({
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  infoMessage: {
    color: '#9F6000',
    backgroundColor: '#FEEFB3',
    margin: theme.spacing(1),
  },
  textField: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: 500,
    borderColor: '#013f8a',
  },
  margin: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
    width: 400,
  },
  fanButton: {
    float: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(1),
    alignText: 'center',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(0),
  },
  tableWidget: {
    overflowX: 'visible',
  },
  datePicker: {
    overflowX: 'visible',
    padding: theme.spacing(2),
    margin: 'auto',
  },
  errorIcon: {
    fontSize: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    float: 'left',
  },
  warningMessage: {
    fontSize: '0.9rem',
    height: '1.5rem',
    paddingTop: '0.3rem',
    float: 'left',
  },
  Button: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignText: 'center',
    fontSize: '0.75rem',
    marginTop: theme.spacing(2),
  },
  modal: {
    width: '90%',
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflow: 'scroll',
    height: '100%',
    display: 'block',
  },
});

export default withStyles(styles, { withTheme: true })(AccountsView);
