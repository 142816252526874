import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { TextField, Button, Grid, FormControlLabel } from '@material-ui/core';
import Select from 'react-select';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { CustomDateSlider } from '../Sidebar/CustomDateSlider';
import { getWidthCode } from '../../utils/generalFunctions';
import { Treebeard, decorators } from 'react-treebeard';
import trans from './../../i18n/tran';

let counter = 0;
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  if (array.length && array.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
  } else return [];
}

function getSorting(order, orderBy) {
  if (orderBy === 'campaign') {
    return order === 'desc'
      ? (a, b) => desc(a.campaign.label, b.campaign, 'label')
      : (a, b) => -desc(a.campaign.label, b.campaign, 'label');
  } else {
    return order === 'desc'
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }
}
function validateBudget(value, days) {
  return !isNaN(parseFloat(value)) && isFinite(value) && days > 0;
}

function EnhacedTableBody(props) {
  const {
    headCells,
    order,
    orderBy,
    page,
    rowsPerPage,
    isSelected,
    handleClick,
    dense,
    mainId,
    rows,
    textFieldId,
    onRowClicked,
    deleteFunction,
    resizeWidthCodes,
    resizeCellOnclick,
    deleteIcon,
  } = props;
  const classes = useStyles();

  if (resizeWidthCodes && resizeWidthCodes.includes(getWidthCode(true))) {
    const mydecorator = {
      Header: props => {
        return (
          <div
            style={{
              ...props.style.base,
              color: '#013f8a',
              fontSize: '0.8rem',
              width: '100%',
            }}
          >
            {!props.node.isButton ? (
              <div
                id={props.node.id}
                style={
                  props.node.center ? { width: '50%', margin: 'auto' } : {}
                }
              >
                <span style={{ fontWeight: 'bold' }}>
                  {props.node.label ? props.node.label + ': ' : ''}
                </span>
                <span>{props.node.name}</span>
              </div>
            ) : !props.node.isDeleteButton && props.node.isButton ? (
              <div
                id={props.node.id}
                style={
                  props.node.center ? { width: '50%', margin: 'auto' } : {}
                }
              >
                <div>{props.node.name}</div>
              </div>
            ) : (
              ''
            )}
          </div>
        );
      },
    };
    let treeStyle = Treebeard.defaultProps.style;
    treeStyle.tree.base.backgroundColor = 'none';
    treeStyle.tree.base.color = 'black';
    return (
      <TableBody>
        {stableSort(rows, getSorting(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            let data = [];
            let isItemSelected = false;
            const labelId = `enhanced-table-checkbox-${index}`;
            if (row[mainId]) {
              isItemSelected = isSelected(row[mainId].id);

              headCells.forEach(element => {
                if (
                  element.id === mainId &&
                  row[element.id] &&
                  row[element.id].label &&
                  row[element.id].label.length &&
                  row[element.id].label.length > 0
                )
                  data.push({
                    name: row[element.id].label,
                    label: element.label,
                    toggled: true,
                  });
                if (
                  row[element.id] &&
                  row[element.id].length &&
                  row[element.id].length > 0
                )
                  data.push({
                    name: row[element.id],
                    label: element.label,
                    toggled: true,
                  });
                if (element.isButton) {
                  data.push({
                    name: row[element.id],
                    label: element.label,
                    toggled: true,
                    isButton: true,
                    isDeleteButton: element.isDeleteButton,
                    center: element.center,
                  });
                }
              });
            }
            let textGridSize = 12;
            if (props.selectAll) textGridSize = textGridSize - 2;
            if (deleteFunction) textGridSize = textGridSize - 2;
            return (
              <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={counter++}
                selected={isItemSelected}
                onClick={() => {
                  if (onRowClicked) onRowClicked(row);
                }}
              >
                <TableCell
                  padding="none"
                  key={counter++}
                  component="th"
                  scope="row"
                  align="left"
                >
                  <Grid container>
                    {props.selectAll ? (
                      <Grid item xs={2}>
                        <Checkbox
                          onClick={event =>
                            handleClick(event, row[mainId].id, row)
                          }
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid
                      item
                      xs={textGridSize}
                      onClick={() => {
                        if (resizeCellOnclick) resizeCellOnclick(row);
                      }}
                    >
                      <Treebeard
                        data={data}
                        style={treeStyle}
                        decorators={{
                          ...decorators,
                          Header: mydecorator.Header,
                        }}
                      ></Treebeard>
                    </Grid>

                    {deleteFunction ? (
                      <Grid item xs={2}>
                        <Button
                          style={deleteIcon ? { float: 'right' } : {}}
                          size="medium"
                          onClick={() => {
                            if (deleteFunction) deleteFunction(row[mainId]);
                          }}
                        >
                          {deleteIcon ? deleteIcon : <DeleteIcon></DeleteIcon>}
                        </Button>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    );
  }

  return (
    <TableBody>
      {stableSort(rows, getSorting(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          let isItemSelected = false;
          const labelId = `enhanced-table-checkbox-${index}`;
          if (row[mainId]) {
            isItemSelected = isSelected(row[mainId].id);

            return (
              <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={counter++}
                selected={isItemSelected}
                onClick={() => {
                  if (onRowClicked) onRowClicked(row);
                }}
              >
                {props.selectAll ? (
                  <TableCell key={counter++} align="left" padding="checkbox">
                    <Checkbox
                      onClick={event => handleClick(event, row[mainId].id, row)}
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                ) : (
                  <TableCell style={{ display: 'none' }}></TableCell>
                )}
                {headCells.map(({ id, center }, cellindex) =>
                  id === textFieldId ? (
                    <TableCell
                      padding="none"
                      key={counter++}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      <div>
                        <TextField
                          id={row[mainId].id.toString()}
                          type="number"
                          inputProps={{ style: { textAlign: 'center' } }}
                        ></TextField>
                      </div>
                    </TableCell>
                  ) : id === 'modifyBudget' ? (
                    <TableCell
                      padding="none"
                      key={counter++}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      <Button
                        style={{ height: '30px' }}
                        disabled={
                          !validateBudget(
                            document.getElementById(row[mainId].id.toString()),
                            row[mainId].remaining,
                          )
                        }
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={() => {
                          let element = document.getElementById(
                            row[mainId].id.toString(),
                          );

                          alert(element ? element.value : 'GFSAFSA');
                        }}
                      >
                        Modificar
                      </Button>
                    </TableCell>
                  ) : id === 'modifyCampaignDate' ? (
                    <TableCell
                      padding="none"
                      key={counter++}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      <Button
                        style={{ height: '30px' }}
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={() => {
                          let startdate = document.getElementById(
                            row[mainId].id.toString() + '-startDate',
                          );
                          let enddate = document.getElementById(
                            row[mainId].id.toString() + '-endDate',
                          );
                          if (startdate) startdate = startdate.value;
                          if (enddate) enddate = enddate.value;
                          alert(
                            startdate +
                              '-' +
                              enddate +
                              ' ' +
                              row[mainId].id.toString(),
                          );
                        }}
                      >
                        Modificar Fecha
                      </Button>
                    </TableCell>
                  ) : id === 'eliminarLocation' ? (
                    <TableCell
                      padding="none"
                      key={counter++}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <Button
                        size="medium"
                        onClick={() => {
                          if (deleteFunction) deleteFunction(row[mainId]);
                        }}
                      >
                        <DeleteIcon></DeleteIcon>
                      </Button>
                    </TableCell>
                  ) : id === 'campaignDate' ? (
                    <TableCell
                      padding="none"
                      key={counter++}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <CustomDateSlider
                        readOnly
                        showErrors={false}
                        fontSize={'0.8rem'}
                        reset={props.reset}
                        changeReset={props.changeReset}
                        ErrorIcon={<ErrorIcon />}
                        dateRange={[new Date(), new Date()]}
                        selectedDates={row[id]}
                        id={row[mainId].id}
                        validateDateEqualOrBiggerThanToday={false}
                        hideSlider={true}
                        changeError={() => {}}
                        Error={false}
                        switchDates={true}
                        changeDateRange={() => {}}
                        changeSelectedDates={() => {}}
                      >
                        {' '}
                      </CustomDateSlider>
                    </TableCell>
                  ) : id === mainId ? (
                    <TableCell
                      padding="none"
                      key={counter++}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {row[id].label}
                    </TableCell>
                  ) : id === 'products' ? (
                    isItemSelected ? (
                      <TableCell
                        style={{ width: '500px' }}
                        key={counter++}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {row['selectedProduct'].label}
                      </TableCell>
                    ) : props.updateTable ? (
                      <TableCell style={{ width: '500px' }} key={counter++}>
                        <Select value={row['selectedProduct']}></Select>
                      </TableCell>
                    ) : (
                      <TableCell
                        style={{ width: '500px' }}
                        key={counter++}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {row[id]}
                      </TableCell>
                    )
                  ) : id === 'modifyProduct' ? (
                    <TableCell
                      key={counter++}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <Button
                        id="Modify-Product"
                        size="small"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          props.changeShowCreateProduct(true);
                          props.changemodifyProductRow([row]);
                        }}
                      >
                        <BorderColorIcon className={classes.profileMenuIcon} />
                      </Button>
                    </TableCell>
                  ) : id === 'pauseCampaign' ? (
                    <TableCell
                      key={counter++}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <Button
                        color="secondary"
                        onClick={e => {
                          if (row['status'] === 'PAUSED')
                            alert('Activar' + row[mainId].id.toString());
                          else if (row['status'] === 'ACTIVE')
                            alert('Pausar' + row[mainId].id.toString());
                        }}
                        onClose={props.closeMailMenu}
                        className={classes.headerIconCollapse}
                      >
                        {row['status'] === 'PAUSED' ? (
                          <PlayCircleOutlineIcon
                            className={classes.headerIcons}
                          />
                        ) : row['status'] === 'ACTIVE' ? (
                          <PauseCircleOutlineIcon
                            className={classes.headerIcons}
                          />
                        ) : (
                          ''
                        )}
                      </Button>
                    </TableCell>
                  ) : id === 'addRemove' ? (
                    <TableCell
                      key={counter++}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row[id] ? (
                        <Button
                          color="secondary"
                          onClick={e => {
                            alert(row[mainId].id.toString());
                          }}
                          onClose={props.closeMailMenu}
                          className={classes.headerIconCollapse}
                        >
                          <AddIcon className={classes.headerIcons} />
                        </Button>
                      ) : (
                        <Button
                          color="secondary"
                          onClick={e => {
                            alert(row[mainId].id.toString());
                          }}
                          onClose={props.closeMailMenu}
                          className={classes.headerIconCollapse}
                        >
                          <CancelIcon className={classes.headerIcons} />
                        </Button>
                      )}
                    </TableCell>
                  ) : id === 'deleteProduct' ? (
                    <TableCell
                      key={counter++}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <Button
                        id={row[mainId].id + 'Delete-Product'}
                        size="small"
                        onClick={e => {
                          props.setDeleteClicked([row]);
                          //props.changeShowCreateProduct(true);
                        }}
                      >
                        <DeleteIcon className={classes.profileMenuIcon} />
                      </Button>
                    </TableCell>
                  ) : (
                    <TableCell
                      onClick={() => {
                        let find = headCells.findIndex(function (item, i) {
                          return item.id === id;
                        });
                        if (find > -1) {
                          if (headCells[find].onClick) {
                            headCells[find].onClick(row);
                          }
                        }
                      }}
                      key={counter++}
                      component="th"
                      scope="row"
                      align={center ? 'center' : 'left'}
                    >
                      {row[id]}
                    </TableCell>
                  ),
                )}
              </TableRow>
            );
          }
          // return null to remove warning
          return null;
        })}
      {props.hideButtom && (
        <TableRow style={{ height: (dense ? 33 : 53) * 1 }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
}

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    headCells,
    resizeWidthCodes,
  } = props;

  let _class = classes;
  if (_class) {
    _class = onRequestSort;
  }
  
  if (resizeWidthCodes && resizeWidthCodes.includes(getWidthCode())) {
    return <TableHead></TableHead>;
  } else
    return (
      <TableHead>
        <TableRow>
          {props.selectAll ? (
            <TableCell padding="checkbox"></TableCell>
          ) : (
            <TableCell style={{ display: 'none' }}></TableCell>
          )}
          {headCells.map(headCell => (
            <TableCell
              key={counter++}
              align={
                headCell.center || headCell.id === 'addRemove'
                  ? 'center'
                  : 'left'
              }
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
    fontSize: '1rem',
  },
}));
const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        numSelected > 1 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
          >
            {numSelected} Campañas Seleccionadas
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
          >
            {numSelected} Campaña Seleccionada
          </Typography>
        )
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
        ></Typography>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint,
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(props.mainId);
  const [selected, setSelected] = React.useState([]);
  const [dense] = React.useState(true);
  const [update, setUpdate] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = props.rows.map(n => n[props.mainId].id);
      setSelected(newSelected);
      props.onChecked(
        newSelected.map(n => ({
          value: n,
        })),
      );
      return;
    }
    setSelected([]);
    props.onChecked([]);
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    row.checked = false;
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
      row.checked = true;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    if (newSelected.length > 0)
      props.onChecked(
        newSelected.map(n => ({
          value: n,
        })),
      );
    else props.onChecked(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    if (props.changeRowsPerPage) {
      props.changeRowsPerPage(parseInt(event.target.value, 10));
    }      
    setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.rows.length - props.page * rowsPerPage);

  if (props.refresh && selected.length > 0) {
    if (props.changeRefresh) {
      setSelected([]);
      setTimeout(() => {
        props.changeRefresh(false);
      }, 100);
    }
  }

  if (props.updateTable !== update) {
    setUpdate(props.updateTable);

    if (props.refresh !== undefined) { 
      setSelected([]);
    }
  }
  function getPages(count) {
    let returnArray = [5];
    for (let i = 10; i < count; i = i + 5) {
      returnArray.push(i);
    }
    returnArray.push(count);
    return returnArray;
  }

  return (
    <Grid>
      <div className={classes.root}>
        <Paper
          className={classes.paper}
          style={props.noBoxShadow ? { boxShadow: 'none' } : {}}
        >
          <div id="test" className={classes.tableWrapper}>
            {props.selectAll ? (
              <Grid>
                <Grid key={counter++} align="left" padding="checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < props.rows.length
                        }
                        checked={selected.length === props.rows.length}
                        onChange={handleSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                      />
                    }
                    label="Seleccionar Todos"
                  />
                </Grid>
              </Grid>
            ) : (
              ''
            )}
            <Table
              fixedheader={'false'}
              style={{ tableLayout: 'auto' }}
              className={props.ignoreTableClass ? '' : classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={props.rows.length}
                headCells={props.headCells}
                selectAll={props.selectAll}
                resizeWidthCodes={props.resizeWidthCodes}
              />
              <EnhacedTableBody
                deleteFunction={props.deleteFunction}
                onRowClicked={props.onRowClicked}
                changeList={props.changeList}
                List={props.List}
                selected={selected}
                headCells={props.headCells}
                rowCount={props.rows.length}
                order={order}
                page={props.page}
                rowsPerPage={rowsPerPage}
                isSelected={isSelected}
                handleClick={handleClick}
                emptyRows={emptyRows}
                dense={dense}
                mainId={props.mainId}
                rows={props.rows}
                updateTable={props.updateTable}
                changeShowCreateProduct={props.changeShowCreateProduct}
                showCreateProduct={props.showCreateProduct}
                modifyProductRow={props.modifyProductRow}
                changemodifyProductRow={props.changemodifyProductRow}
                deleteClicked={props.deleteClicked}
                setDeleteClicked={props.setDeleteClicked}
                selectAll={props.selectAll}
                textFieldId={props.textFieldId}
                hideButtom={props.hideButtom}
                resizeWidthCodes={props.resizeWidthCodes}
                resizeCellOnclick={props.resizeCellOnclick}
                deleteIcon={props.deleteIcon}
              />
            </Table>
          </div>
          {!props.hideButtom ? (
            <TablePagination
              rowsPerPageOptions={getPages(props.rows.length)}
              component="div"
              align="right"
              rowsPerPage={rowsPerPage}
              page={props.page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={
                document.documentElement.clientWidth >= 500
                  ? trans('ELEMENTS_PER_PAGE', props.lang)
                  : ''
              }
              count={props.rows.length}
            />
          ) : (
            ''
          )}
        </Paper>
      </div>
    </Grid>
  );
}
