import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PageTitle from '../../components/PageTitle';
import SelectAdObjective from './Components/SelectAdObjective/SelectAdObjective';
import Product from './Components/Product';
import FanPage from './Components/FanPage';
import Pixels from './Components/Pixels';
import Application from './Components/Application';
import Conversation from './Components/Conversation';
import DatesSelection from './Components/DatesSelection';
import Budget from './Components/Budget';
import Link from './Components/Link';
import Titles from './Components/Titles';
import Bodies from './Components/Bodies';
import Name from './Components/Name';
import Location from './Components/Location';
import Interests from './Components/Interests';
import UploadFiles from './Components/UploadFiles';
import GenderAge from './Components/GenderAge';
import AdPreview from './Components/AdPreview';
import ErrorMessages from './Components/ErrorMessages';
import Cta from './Components/Cta';
import UTM from './Components/UTM';
import Description from './Components/Description';
import CustomAudiences from './Components/CustomAudiences';
import SavedAudiences from './Components/SavedAudiences';
import FacebookPlacement from './Components/FacebookPlacement';
import InstagramPlacement from './Components/InstagramPlacement';
import SessionWarning from './Components/SessionWarning';

import {
  createAdComponentBelongsInPage,
  createAdGetComponentInfo,
} from '../../utils/functions';
import {
  productComponentId,
  linkComponentId,
  titlesComponentId,
  descriptionComponentId,
  locationComponentId,
  ctaComponentId,
  bodiesComponentId,
  attachmentsComponentId,
  genderAgeComponentId,
  adPreviewComponentId,
  budgetComponentId,
  leadGenerationFormComponentId,
  pixelComponentId,
  applicationComponentId,
  conversationComponentId,
  instagramAccountComponentId,
  UTMComponentId,
  fanPageFocusId,
  pixelFocusId,
  applicationFocusId,
  conversationFocusId,
  dateComponentId,
  ubicacionFocusId,
  uploadFocusId,
  fanPageComponentId,
  genderFocusId,
  campaignNameComponentId,
  LookAlikeComponentId,
  LookAlikeFocusId,
  interestsComponentId,
  interestsFocusId,
} from '../../components/Constants';

import trans from './../../i18n/tran';

const View = ({ classes, theme, onChange, value, ...props }) => {
  if (props.selectedAdminCampaignTypes.length === 0) {
    return (
      <React.Fragment>
        <PageTitle title={trans('NEW_CAMPAIGN_TITLE', props.lang)}/>
        <SessionWarning {...props} />
        <SelectAdObjective {...props}></SelectAdObjective>{' '}
      </React.Fragment>
    );
  }
  if (
    props.selectedAdminCampaignTypes.length !== 0 &&
    props.providers.length === 0
  ) {
    return (
      <React.Fragment>
        <PageTitle title={trans('NEW_CAMPAIGN_TITLE', props.lang)} />
        <SessionWarning {...props} />
        <SelectAdObjective {...props}></SelectAdObjective>{' '}
        <FacebookPlacement {...props}></FacebookPlacement>
        <InstagramPlacement {...props}></InstagramPlacement>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <PageTitle title={trans('NEW_CAMPAIGN_TITLE', props.lang)} />
      <SessionWarning {...props} />
      <SelectAdObjective {...props}></SelectAdObjective>{' '}
      <FacebookPlacement {...props}></FacebookPlacement>{' '}
      <InstagramPlacement {...props}></InstagramPlacement>{' '}
      <Grid container spacing={4}>
        {createAdComponentBelongsInPage(productComponentId, props) ? (
          <Grid item xs={12} md={6} lg={6}>
            <Product
              isCreateAd={true}
              componentInfo={createAdGetComponentInfo(
                productComponentId,
                props,
              )}
              {...props}
            ></Product>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(fanPageComponentId, props) ? (
          <Grid item xs={12} md={6} lg={6}>
            <FanPage
              focusId={fanPageFocusId}
              leadGenerationForms={createAdComponentBelongsInPage(
                leadGenerationFormComponentId,
                props,
              )}
              pixels={createAdComponentBelongsInPage(pixelComponentId, props)}
              application={createAdComponentBelongsInPage(
                applicationComponentId,
                props,
              )}
              conversation={createAdComponentBelongsInPage(
                conversationComponentId,
                props,
              )}
              instagramAccount={createAdComponentBelongsInPage(
                instagramAccountComponentId,
                props,
              )}
              {...props}
            ></FanPage>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(pixelComponentId, props) ? (
          <Grid item xs={12} md={12} lg={12}>
            <Pixels focusId={pixelFocusId} {...props}></Pixels>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(applicationComponentId, props) ? (
          <Grid item xs={12} md={12} lg={12}>
            <Application focusId={applicationFocusId} {...props}></Application>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(conversationComponentId, props) ? (
          <Grid item xs={12} md={12} lg={12}>
            <Conversation
              focusId={conversationFocusId}
              {...props}
            ></Conversation>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(campaignNameComponentId, props) ? (
          <Grid item xs={12} lg={12} md={12}>
            <Name
              list={props.campaignName}
              changeList={props.changeCampaignName}
              componentInfo={createAdGetComponentInfo(
                campaignNameComponentId,
                props,
              )}
              {...props}
            ></Name>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(bodiesComponentId, props) ? (
          <Grid item xs={12} lg={12} md={12}>
            <Bodies
              componentInfo={createAdGetComponentInfo(bodiesComponentId, props)}
              {...props}
            ></Bodies>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(titlesComponentId, props) ? (
          <Grid item xs={12} lg={12} md={12}>
            <Titles
              componentInfo={createAdGetComponentInfo(titlesComponentId, props)}
              {...props}
            ></Titles>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(descriptionComponentId, props) ? (
          <Grid item xs={12} lg={12} md={12}>
            <Description focusId={''} {...props}></Description>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(ctaComponentId, props) ? (
          <Grid item xs={12} lg={6} md={6}>
            <Cta
              componentInfo={createAdGetComponentInfo(ctaComponentId, props)}
              {...props}
            ></Cta>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12} md={6} lg={6}>
          <DatesSelection
            componentInfo={createAdGetComponentInfo(dateComponentId, props)}
            {...props}
          ></DatesSelection>
        </Grid>
        {createAdComponentBelongsInPage(linkComponentId, props) ? (
          <Grid
            item
            xs={12}
            md={createAdComponentBelongsInPage(UTMComponentId, props) ? 6 : 12}
            lg={createAdComponentBelongsInPage(UTMComponentId, props) ? 6 : 12}
          >
            <Link
              componentInfo={createAdGetComponentInfo(linkComponentId, props)}
              {...props}
            ></Link>
          </Grid>
        ) : (
          ''
        )}

        {createAdComponentBelongsInPage(UTMComponentId, props) ? (
          <Grid item xs={12} md={6} lg={6}>
            <UTM
              componentInfo={createAdGetComponentInfo(UTMComponentId, props)}
              {...props}
            ></UTM>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(budgetComponentId, props) ? (
          <Grid item xs={12} md={6} lg={6}>
            <Budget
              componentInfo={createAdGetComponentInfo(budgetComponentId, props)}
              {...props}
            ></Budget>
          </Grid>
        ) : (
          ''
        )}
        {/* SaveAudiences, Recorded Audience  Drop Down */}
        {createAdComponentBelongsInPage(LookAlikeComponentId, props) ? (
          <Grid item xs={12} lg={6} md={6}>
            <SavedAudiences
              componentInfo={createAdGetComponentInfo(
                LookAlikeComponentId,
                props,
              )}
              focusId={LookAlikeFocusId}
              {...props}
            >
            </SavedAudiences>
          </Grid>
        ) : (
          ''
        )}
        {/** BUNCH OF STUFF INSIDE. PLACES PICKER (COUNTRY, CITY, ETC) AND LIST OF PLACES SAVED */}
        {createAdComponentBelongsInPage(locationComponentId, props) ? (
          <Grid item xs={12} lg={12} md={12}>
            <Location
              focusId={ubicacionFocusId}
              {...props}
              componentInfo={createAdGetComponentInfo(
                locationComponentId,
                props,
              )}
            ></Location>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(interestsComponentId, props) ? (
          <Grid item xs={12} lg={12} md={12}>
            <Interests
              focusId={interestsFocusId}
              {...props}
              componentInfo={createAdGetComponentInfo(
                interestsComponentId,
                props,
              )}
            ></Interests>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(genderAgeComponentId, props) ? (
          <Grid item xs={12} lg={6} md={12}>
            <GenderAge
              componentInfo={createAdGetComponentInfo(
                genderAgeComponentId,
                props,
              )}
              focusId={genderFocusId}
              {...props}
            ></GenderAge>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(LookAlikeComponentId, props) ? (
          <Grid item xs={12} lg={6} md={12}>
            <CustomAudiences
              componentInfo={createAdGetComponentInfo(
                LookAlikeComponentId,
                props,
              )}
              focusId={LookAlikeFocusId}
              {...props}
            ></CustomAudiences>
          </Grid>
        ) : (
          ''
        )}

        {createAdComponentBelongsInPage(attachmentsComponentId, props) ? (
          <Grid item xs={12} lg={12} md={12}>
            <UploadFiles focusId={uploadFocusId} {...props}></UploadFiles>
          </Grid>
        ) : (
          ''
        )}
        {createAdComponentBelongsInPage(adPreviewComponentId, props) ? (
          <Grid item xs={12} lg={12} md={12}>
            <AdPreview {...props}></AdPreview>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
      <ErrorMessages {...props}></ErrorMessages>
    </React.Fragment>
  );
};

const styles = theme => ({
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  infoMessage: {
    color: '#9F6000',
    backgroundColor: '#FEEFB3',
    margin: theme.spacing(1),
  },
  textField: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: 500,
    borderColor: '#013f8a',
  },
  margin: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
    width: 400,
  },
  fanButton: {
    float: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(1),
    alignText: 'center',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(0),
  },
  tableWidget: {
    overflowX: 'visible',
  },
  datePicker: {
    overflowX: 'visible',
    padding: theme.spacing(2),
    margin: 'auto',
  },
  errorIcon: {
    fontSize: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    float: 'left',
  },
  warningMessage: {
    fontSize: '0.9rem',
    height: '1.5rem',
    paddingTop: '0.3rem',
    float: 'left',
  },
});

export default withStyles(styles, { withTheme: true })(View);
