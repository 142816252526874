import { combineReducers } from 'redux';

import layout from '../components/Layout/LayoutState';
import login from '../pages/login/LoginState';
import campaign from '../components/Selectors/SelectorState';

export default combineReducers({
  layout,
  login,
  campaign,
});
