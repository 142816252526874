import { compose, withState, lifecycle } from 'recompose';
import View from './ClientApprovalView';
// import { getAccountsApiAdmin } from "../../utils/functions";

const mycompose = compose(
  withState('pagePagination', 'changePagePagination', 0),

  lifecycle({
    componentDidMount() {
      this.props.changeTenantChangeFunction({
        function: newTenenatValue => {},
      });
    },

    componentWillUnmount() {
      //window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
    componentDidUpdate() {},
  }),
);

export default mycompose(View);
