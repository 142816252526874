import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import AppView from './App';
import { defaultPermissions } from './Constants';
import { Init } from './../utils/mixpanel';

/* TODO: find a better way, maybe with redux with storage */
let defaultLang = 'es';
if (localStorage) {
  const savedLang = localStorage.getItem('lang');
  const validLangs = ['es', 'en'];
  if (savedLang && savedLang in validLangs) {
    defaultLang = savedLang;
  }
  localStorage.setItem('lang', defaultLang);
}

export default compose(
  withState('selectorTenant', 'changeSelectorTenant', []),
  withState('selectedTenants', 'changeSelectedTenants', []),
  withState('refreshLayout', 'changeRefreshLayout', false),
  withState('lang', 'setLang', defaultLang),
  lifecycle({
    async componentDidMount() {
      if (sessionStorage) {
        sessionStorage.removeItem('customPageValidation');
        sessionStorage.setItem('userPages', JSON.stringify(defaultPermissions));
      }
      /*if (window && window.location && window.location) {
        const urlParams = new URLSearchParams(window.location.search);
        let lang = urlParams.get("lang");
        if (lang) {
          this.props.setLang(lang)
        }
      }*/
      // init mix panel
      Init();
    },
  }),
  withHandlers({
    setLang: props => event => {
      props.setLang(event);
      if (localStorage) {
        localStorage.setItem('lang', event);
      }
    },
  }),
  connect(state => ({
    isAuthenticated: state.login.isAuthenticated,
  })),
)(AppView);
