import { request, requestHeaders } from './helper';
import { getNewNonceUrl } from '../../components/Constants';

interface CheckBusinessExistResponsePayload {
  ExpiresAt: string;
  JsonPages: string;
  Nonce: string;
}
interface CheckBusinessExistResponse {
  success: boolean;
  payload?: CheckBusinessExistResponsePayload;
}
export const GetAccountNonce = async (accountId: number) => {
  try {
    if (!accountId) {
      const error: CheckBusinessExistResponse = {
        success: false,
      };
      return error;
    }
    const headers = requestHeaders();
    const response = await request<CheckBusinessExistResponse>(
      `${getNewNonceUrl}?accountId=${accountId}`,
      {
        method: 'GET',
        headers: headers,
      },
    );
    return response;
  } catch (e) {
    console.log('GetAccountNonce exception', JSON.stringify(e));
    const error: CheckBusinessExistResponse = {
      success: false,
    };
    return error;
  }
};
