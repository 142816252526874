import { compose, withState, withHandlers, lifecycle } from 'recompose';

import View from './View';
import { doFetch, createHeaders } from './../../utils/fetch';
import { getDrafts } from './../../components/Constants';

function getDefaultRange() {
  let now = new Date();
  let sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(now.getDate() - 7);
  return [sevenDaysAgo, now];
}

let defaultRange = getDefaultRange();

function dateToYYMD(date) {
  var d = date.getDate();
  var m = date.getMonth() + 1; //Month from 0 to 11
  var y = date.getFullYear();
  return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

let signal;
let controller;

async function fetchDrafts(props, daterange) {
  controller = new AbortController();
  signal = controller.signal;

  const startDate = daterange[0];
  const endDate = daterange[1];
  const tenant = props.selectedTenants;
  if (tenant && tenant.length && tenant.length > 0) {
    let url = `${getDrafts}/${tenant[0].value}?startDate=${dateToYYMD(
      startDate,
    )}&endDate=${dateToYYMD(endDate)}`;

    await doFetch(
      url,
      {
        method: 'get',
        headers: createHeaders(),
        signal,
      },
      res => {
        if ('success' in res && 'payload' in res && res.success) {
          if (res.payload && res.payload.length && res.payload.length > 1) {
            let raw = res.payload[0];
            props.updateDrafts(raw);
            props.changeIsLoadingLayout(false);
          }
        }
      },
    );
  }
  props.changeIsLoadingLayout(false);
}

const mycompose = compose(
  withState('selectedDates', 'updateSelectedDates', defaultRange),
  withState('dateRange', 'updateDateRange', defaultRange),
  withState('drafts', 'updateDrafts', []),
  lifecycle({
    componentDidMount() {
      fetchDrafts(this.props, defaultRange);

      this.props.changeTenantChangeFunction({
        function: newTenenatValue => {
          fetchDrafts(this.props, this.props.selectedDates);
        },
      });
    },
    componentWillUnmount() {
      if (!(typeof controller === 'undefined')) {
        controller.abort();
      }
    },
  }),
  withHandlers({
    updateSelectedDates: props => event => {
      if (event && event.length > 1) {
        let parsedStartDate = Date.parse(event[0]);
        let parsedEndDate = Date.parse(event[1]);
        if (!Number.isNaN(parsedStartDate) && !Number.isNaN(parsedEndDate)) {
          props.changeIsLoadingLayout(true);
          props.updateSelectedDates(event);
          fetchDrafts(props, event);
        }
      }
    },
  }),
);

export default mycompose(View);
