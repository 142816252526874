import React from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import Widget from '../../components/Widget/WidgetContainer';
import PageTitle from '../../components/PageTitle/PageTitle';
import Select from 'react-select';
import { companySelectorId, brandSelectorId } from '../../components/Constants';
import SelectProduct from './components/selectproduct';
import { getBrandApiAdmin } from '../../utils/functions';
import {
  filteredProductsByCompany,
  filteredProductsByBrand,
} from '../../utils/generalFunctions';
import CreateProduct from '../product';
import trans from './../../i18n/tran'

const View = ({ classes, theme, onChange, value, ...props }) => {
  if (props.showCreateProduct) {
    return (
      <React.Fragment>
        <CreateProduct isDialog={true} modifyProduct={true} {...props} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <PageTitle title="Modificación de Productos" />
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={12}>
          <Widget
            overflow={true}
            title={<div></div>}
            bodyClass={classes.tableWidget}
            disableWidgetMenu={true}
          >
            <div>
              <Typography>Empresa</Typography>
              <Select
                id={companySelectorId}
                styles={theme.reactSelect}
                isDisabled={props.selectedTenants.length === 0}
                placeholder={
                  props.selectedTenants.length === 0
                    ? 'Seleccione Negocio Administrador'
                    : props.selectorAdminCompany.length === 0
                    ? 'No se Encontraron Empresas Para el Negocio Administrador Seleccionado'
                    : 'Seleccione Empresa'
                }
                isMulti={false}
                value={props.selectedAdminCompanies}
                options={props.selectorAdminCompany}
                onChange={e => {
                  getBrandApiAdmin(props, props.selectedTenants, [e]);
                  props.changeSelectedAdminCompanies([e]);
                  props.changeSelectedAdminBrands([]);
                  let filtered = filteredProductsByCompany(
                    props.selectorAdminProduct,
                    [e],
                  );
                  props.changeSelectedAdminProducts(filtered);
                }}
              ></Select>
            </div>
            <div>
              <Typography>Marca</Typography>
              <Select
                id={brandSelectorId}
                styles={theme.reactSelect}
                isDisabled={props.selectedAdminCompanies.length === 0}
                placeholder={
                  props.selectedTenants.length === 0
                    ? trans('BUSINESS_SELECT', props.lang)
                    : props.selectedAdminCompanies.length === 0
                    ? 'Seleccione Empresa'
                    : props.selectorAdminBrand.length === 0
                    ? 'No se Encontraron Marcas Para la Empresa Seleccionada'
                    : 'Seleccione Marca'
                }
                isMulti={false}
                value={props.selectedAdminBrands}
                options={props.selectorAdminBrand}
                onChange={e => {
                  props.changeSelectedAdminBrands([e]);
                  let filtered = filteredProductsByBrand(
                    props.selectorAdminProduct,
                    props.selectedAdminCompanies,
                    [e],
                  );
                  props.changeSelectedAdminProducts(filtered);
                  if (props.changePagePagination) props.changePagePagination(0);
                }}
              ></Select>
            </div>
          </Widget>
        </Grid>
        <SelectProduct {...props}></SelectProduct>
      </Grid>
    </React.Fragment>
  );
};

const styles = theme => ({
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  infoMessage: {
    color: '#9F6000',
    backgroundColor: '#FEEFB3',
    margin: theme.spacing(1),
  },
  textField: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: 500,
    borderColor: '#013f8a',
  },
  margin: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
    width: 400,
  },
  fanButton: {
    float: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(1),
    alignText: 'center',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(0),
  },
  tableWidget: {
    overflowX: 'visible',
  },
  datePicker: {
    overflowX: 'visible',
    padding: theme.spacing(2),
    margin: 'auto',
  },
  errorIcon: {
    fontSize: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    float: 'left',
  },
  warningMessage: {
    fontSize: '0.9rem',
    height: '1.5rem',
    paddingTop: '0.3rem',
    float: 'left',
  },
});

export default withStyles(styles, { withTheme: true })(View);
