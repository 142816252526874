import React, { FC } from 'react';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { Frame5, Label, CardTitle1 } from './StandardIndicator.styled';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

type IndicatorType =
  | 'impressions'
  | 'cost'
  | 'clicks'
  | 'actions'
  | 'ctr'
  | 'cvr'
  | 'cpa'
  | 'cpm';

type IndicatorTypeWithCurrency = Omit<IndicatorType, 'cost'>;

interface StandardIndicatorProps {
  indicatorType: IndicatorType;
  value: string | number;
}
export const StandardIndicator: FC<StandardIndicatorProps> = ({
  indicatorType,
  value,
}) => {
  const showCurrency = isIndicatorCurrency(indicatorType);
  const showPercent = isIndicatorPercent(indicatorType);

  return (
    <Frame5>
      <CardTitle indicatorType={indicatorType} />
      <Label>
        {showCurrency && '$'}
        <span className="montserrat-normal-tolopea-16px">{value}</span>
        {showPercent && '%'}
      </Label>
    </Frame5>
  );
};

const getIndicatorIcon = (indicatorType: IndicatorType) => {
  const icons: Record<IndicatorType, any> = {
    actions: EmojiPeopleIcon,
    clicks: EmojiPeopleIcon,
    cvr: EmojiPeopleIcon,
    cost: AttachMoneyIcon,
    cpa: EmojiPeopleIcon,
    cpm: EmojiPeopleIcon,
    ctr: EmojiPeopleIcon,
    impressions: EmojiPeopleIcon,
  };

  return icons[indicatorType] ?? icons['impressions'];
};

const getIndicatorText = (indicatorType: IndicatorType) => {
  const icons: Record<IndicatorType, string> = {
    actions: 'Actions',
    clicks: 'Clicks',
    cvr: 'Conv Rate',
    cost: 'Cost',
    cpa: 'CPA',
    cpm: 'CPM',
    ctr: 'CTR',
    impressions: 'Impressions',
  };

  return icons[indicatorType] ?? icons['impressions'];
};

const isIndicatorCurrency = (indicatorType: IndicatorType) => {
  const icons: Record<IndicatorType, boolean> = {
    actions: false,
    clicks: false,
    cvr: false,
    cost: true,
    cpa: false,
    cpm: false,
    ctr: false,
    impressions: false,
  };

  return icons[indicatorType];
};

const isIndicatorPercent = (indicatorType: IndicatorType) => {
  const icons: Record<IndicatorType, boolean> = {
    actions: false,
    clicks: false,
    cvr: true,
    cost: false,
    cpa: false,
    cpm: false,
    ctr: true,
    impressions: false,
  };

  return icons[indicatorType];
};

interface CardTitleProps {
  indicatorType: IndicatorType;
}
const CardTitle: FC<CardTitleProps> = ({ indicatorType }) => {
  const spanText = getIndicatorText(indicatorType);

  const classes = useStyles();

  const IconComponent = getIndicatorIcon(indicatorType);

  return (
    <CardTitle1>
      <IconComponent className={classes.imageIcon} />
      <Label>
        <span className="montserrat-medium-black-11px">{spanText}</span>
      </Label>
    </CardTitle1>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageIcon: {
      height: '1.5em',
      width: '1.5em',
      top: '.5em',
      position: 'relative',
    },
  }),
);
