import React from 'react';
import { Typography, MuiThemeProvider } from '@material-ui/core';
import { palette, theme } from '../pages/onboarding/theme';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { useOnboardingContext } from '../pages/onboarding/context';
const Welcome = () => {
  const classes = useStyles();
  const { userName } = useWelcome();

  const { activeStep } = useOnboardingContext();
  const { t } = useTranslation();

  let title =
    activeStep < 2
      ? t("Let's Get started {{userName}}", { userName })
      : t('We are almost there!');

  let subTitle =
    activeStep < 2
      ? t('Please complete the following steps.')
      : t(
          'We have several powerful plans to grow your business and provide calculated ads for your products',
        );

  if (activeStep === 3) {
    title = t('One final step!');
    subTitle = '';
  }
  return (
    <MuiThemeProvider theme={theme}>
      {userName && (
        <div className={classes.welcome}>
          <Typography className={classes.welcomeText}>{title}</Typography>
          <Typography className={classes.welcomeDescription}>
            {subTitle}
          </Typography>
        </div>
      )}
    </MuiThemeProvider>
  );
};

const useWelcome = () => {
  let usr, userName;
  const json = localStorage.getItem('userName');
  if (json) {
    usr = JSON.parse(json);
  }
  if (usr && usr.given_name) {
    userName = usr.given_name;
    if (usr.family_name) {
      userName = `${userName} ${usr.family_name}`;
    }
  }
  return { userName };
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    welcome: {
      display: 'block',
      paddingTop: '15px',
      paddingBottom: '15px',
    },
    welcomeText: {
      fontFamily: 'Montserrat-Semibold',
      fontSize: '16px',
      lineHeight: '24px',
      color: palette.deepPurple,
    },
    welcomeDescription: {
      fontFamily: 'Montserrat-Semibold',
      fontSize: '16px',
      lineHeight: '24px',
      color: palette.gray,
    },
  }),
);

export default Welcome;
