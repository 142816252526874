import React from 'react';
import { Grid, withStyles, Button, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Accounts from './components/accounts/AccountsContainer';
import allylogo2 from '../../images/Allylogo2.svg';
import { onBoardingOK } from '../../components/Constants';
import { resizeDiv } from '../../utils/styles';

const View = ({ classes, theme, onChange, value, ...props }) => {
  /*
	const customStyles = {
		container: (base, state) => ({
			...base,
		}),
		control: (base, state) => ({
			...base,
		}),
		valueContainer: (base, state) => ({
			...base,
		}),
		multiValue: (base, state) => ({
			...base,
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				cursor: isDisabled ? "not-allowed" : "default",
			};
		},
	};*/

  return (
    <Paper>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={12}>
          <div
            style={{
              width: '80%',
              left: '0',
              right: '0',
              top: '0',
              bottom: '0',
              margin: 'auto',
              maxWidth: '100%',
              maxHeight: '100%',
              overflow: 'auto',
            }}
          >
            <img
              src={allylogo2}
              alt="aly-ai logo"
              style={{ height: '200px' }}
            ></img>
          </div>
        </Grid>
        {sessionStorage.getItem('onBoardingStatus') !==
        onBoardingOK.toString() ? (
          <Grid item xs={12} md={12} lg={12}>
            <div
              style={{
                width: '80%',
                left: '0',
                right: '0',
                top: '0',
                bottom: '0',
                margin: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                overflow: 'auto',
              }}
            >
              {props.selectedTenants && props.selectedTenants.length > 0 ? (
                <div style={resizeDiv()}>
                  <div>
                    {
                      'Favor enviar un correo a Soporte ALY-AI <soporte@aly-ai.com> colocando en el correo la llave única para habilitar Negocio Administrador'
                    }
                  </div>
                  <div style={{ paddingTop: '25px' }}>
                    {'Llave única: '}
                    <span style={{ fontWeight: 'bold' }}>
                      {props.selectedTenants[0].UniqueKey}
                    </span>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </Grid>
        ) : (
          ''
        )}
        {props.isApproval ? (
          <Grid item xs={12} md={12} lg={12}>
            <Accounts {...props} />
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={11} md={11} lg={11}>
          <div style={{ float: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/app/home"
              size="large"
              className={classes.button}
            >
              Volver al Inicio
            </Button>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

const styles = theme => ({
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  infoMessage: {
    color: '#9F6000',
    backgroundColor: '#FEEFB3',
    margin: theme.spacing(1),
  },
  textField: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: 500,
    borderColor: '#013f8a',
  },
  margin: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
    width: 400,
  },
  fanButton: {
    float: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(1),
    alignText: 'center',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(0),
  },
  tableWidget: {
    overflowX: 'visible',
  },
  datePicker: {
    overflowX: 'visible',
    padding: theme.spacing(2),
    margin: 'auto',
  },
  errorIcon: {
    fontSize: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    float: 'left',
  },
  warningMessage: {
    fontSize: '0.9rem',
    height: '1.5rem',
    paddingTop: '0.3rem',
    float: 'left',
  },
});

export default withStyles(styles, { withTheme: true })(View);
