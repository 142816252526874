import React from 'react';
import Select from 'react-select';
import { withStyles } from '@material-ui/core';
import { Typography } from '../Wrappers';
import {
  facebookCampaignSelectorId,
  adAccountSelectorId,
} from '../Constants';

import trans from './../../i18n/tran';

import { getCustomPageInfo, updateSelector } from '../../utils/functions';
const SelectorView = ({ classes, theme, ...props }) => {
  return (
    <div style={{ margin: '0px auto' }}>
      <div>
        <Typography>{trans('AD_ACCOUNT', props.lang)}</Typography>
        <Select
          placeholder={trans('AD_ACCOUNT_SELECT', props.lang)}
          id={adAccountSelectorId}
          styles={theme.reactSelect}
          isMulti={true}
          value={props.selectedAdAccounts}
          options={props.selectorAdAccount}
          onChange={e => {
            updateSelector(
              true,
              props.changeSelectedAdAccounts,
              props.selectedAdAccounts,
              e,
              props.selectorAdAccount,
              adAccountSelectorId,
              props,
              [],
              'selectedAdAccounts',
              true,
            );
          }}
        ></Select>
      </div>
      <div>
        <Typography>{trans('CAMPAIGNS', props.lang)}</Typography>
        <Select
          placeholder={trans('CAMPAIGNS_SELECT', props.lang)}
          id={facebookCampaignSelectorId}
          styles={theme.reactSelect}
          isMulti={false}
          value={props.selectedFacebookCampaigns}
          options={props.selectorFacebookCampaign}
          onChange={e => {
            props.changeSelectedFacebookCampaigns([e]);
            {
              // it loads its child (this should be in the prop did update lol)
              let PageInfo = getCustomPageInfo(props.customPageId);
              let params = {};
              params.selectedFacebookCampaigns = [e];
              if (PageInfo.finalFunction) {
                PageInfo.finalFunction(params, props, true);
              }                
            }
            if (props.campaignChanged) { 
              props.campaignChanged(e.value);
            }
          }}
        ></Select>
      </div>
    </div>
  );
};

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(SelectorView);
