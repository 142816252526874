import React from 'react';
import {
  Grid,
  withStyles,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import Widget from '../../components/Widget/WidgetContainer';
import PageTitle from '../../components/PageTitle/PageTitle';
import Select from 'react-select';
import CreateProduct from '../product';
import {
  // accountSelectorId,
  adAccountSelectorId,
  // Environment,
} from '../../components/Constants';
import {
  getfacebookCampaigns,
  getProductAdminApiFiltersBrandandCompany,
  //refreshCustomPage,
  getAlyCampaigns,
} from '../../utils/functions';
import AssignOptimization from './components/assignoptimization';
import AssignProduct from './components/assignproduct';
import UpdateProduct from './components/updateproduct';
import ManageBudget from './components/budget';
import Checkbox from '@material-ui/core/Checkbox';

export function changeCampaign(selectedAdminCampaigns, props) {
  props.changeCampaignsManager([]);
  props.changeCampaignsManagerFiltered([]);
  props.changeSelectedAdminProducts([]);
  props.changeSelectorAdminProduct([]);

  getfacebookCampaigns(
    {
      selectedAdminCampaigns: selectedAdminCampaigns,
      api: 'Admin',
      isBudget: true,
    },
    props,
  );
}

const isProduct = 'Product';
export const isBudget = 'Budget';
const isOptimization = 'Optimization';
const isUpdate = 'Update';

export function changeAccount(
  checkbox,
  checked,
  selectedAdAccounts,
  props,
  refreshCampaigns,
) {
  if (checked) {
    if (!refreshCampaigns) {
      props.changeSelectedAdminCampaigns([]);
    }
    props.changeSelectorAdminCampaign([]);
    props.changeSelectedFacebookCampaigns([]);
    if (checkbox === isOptimization) {
      getfacebookCampaigns(
        {
          selectedAdAccounts: selectedAdAccounts,
          selectedAdminAccounts: props.selectedTenants,
          api: 'Admin',
          withoutFilter: true,
        },
        props,
      );
    } else if (checkbox === isProduct) {
      props.changeSelectedAdminProducts([]);
      props.changeSelectorAdminProduct([]);
      getProductAdminApiFiltersBrandandCompany(
        props,
        props.selectedTenants,
        undefined,
        false,
        true,
        selectedAdAccounts,
      );
      getfacebookCampaigns(
        {
          selectedAdAccounts: selectedAdAccounts,
          selectedAdminAccounts: props.selectedTenants,
          api: 'Admin',
          withoutProduct: true,
        },
        props,
      );
    } else if (checkbox === isUpdate) {
      props.changeSelectedAdminProducts([]);
      props.changeSelectorAdminProduct([]);
      getProductAdminApiFiltersBrandandCompany(
        props,
        props.selectedTenants,
        undefined,
        false,
        true,
        selectedAdAccounts,
      );
      getfacebookCampaigns(
        {
          selectedAdAccounts: selectedAdAccounts,
          selectedAdminAccounts: props.selectedTenants,
          api: 'Admin',
          isUpdate: true,
        },
        props,
      );
    } else if (checkbox === isBudget) {
      getAlyCampaigns(
        {
          selectedAdAccounts: selectedAdAccounts,
          selectedAdminAccounts: props.selectedTenants,
          api: 'Admin',
          isBudget: true,
          refreshCampaigns: refreshCampaigns,
        },
        props,
      );
    }
  }
}

const View = ({ classes, theme, onChange, value, ...props }) => {
  if (props.showCreateProduct) {
    return (
      <React.Fragment>
        <CreateProduct isDialog={true} {...props}></CreateProduct>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <PageTitle title="Manejo de Campañas" />
      <div>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.assignProduct}
                    onChange={(e, checked) => {
                      props.changeOptimizationType(false);
                      props.changeAssignProduct(checked);
                      props.changeUpdateProduct(false);
                      props.changeManageBudget(false);
                      changeAccount(
                        isProduct,
                        checked,
                        props.selectedAdminAdAccounts,
                        props,
                      );
                    }}
                  ></Checkbox>
                }
                label="Asignación Productos"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={12}>
          <Widget
            overflow={true}
            title={<div></div>}
            bodyClass={classes.tableWidget}
            disableWidgetMenu={true}
          >
            <div>
              <Typography>Cuenta Publicitaria</Typography>
              <Select
                id={adAccountSelectorId}
                isMulti={false}
                placeholder={'Seleccione Cuenta Publicitaria'}
                value={props.selectedAdminAdAccounts}
                styles={theme.reactSelect}
                options={props.selectorAdminAdAccount}
                onChange={e => {
                  props.changeSelectedAdminAdAccounts([e]);
                  props.changeSelectedCategoriesFiltered([]);
                  props.changeSelectedAdminProductsFiltered([]);
                  props.changeSelectedCountriesFiltered([]);
                  changeAccount(
                    props.assignOptimization
                      ? isOptimization
                      : props.assignProduct
                      ? isProduct
                      : props.updateProduct
                      ? isUpdate
                      : props.manageBudget
                      ? isBudget
                      : '',
                    props.assignOptimization |
                      props.assignProduct |
                      props.updateProduct |
                      props.manageBudget |
                      props.manager,
                    [e],
                    props,
                  );
                }}
              ></Select>
            </div>
            {props.manageBudget ? (
              <div>
                <Typography>Campaña</Typography>
                <Select
                  id={'Campañas'}
                  isMulti={false}
                  placeholder={'Campañas'}
                  value={props.selectedAdminCampaigns}
                  options={props.selectorAdminCampaign}
                  onChange={e => {
                    props.changeFacebookNoGas(false);
                    props.changeSelectedAdminCampaigns([e]);

                    changeCampaign([e], props);
                  }}
                ></Select>
              </div>
            ) : (
              ''
            )}
          </Widget>
        </Grid>

        {props.assignOptimization ? (
          <AssignOptimization {...props}></AssignOptimization>
        ) : props.assignProduct ? (
          <AssignProduct {...props}></AssignProduct>
        ) : props.updateProduct ? (
          <UpdateProduct {...props}></UpdateProduct>
        ) : props.manageBudget && !props.facebookNoGas ? (
          <ManageBudget {...props}></ManageBudget>
        ) : (
          <div></div>
        )}
      </Grid>
    </React.Fragment>
  );
};

const styles = theme => ({
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  infoMessage: {
    color: '#9F6000',
    backgroundColor: '#FEEFB3',
    margin: theme.spacing(1),
  },
  textField: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: 500,
    borderColor: '#013f8a',
  },
  margin: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
    width: 400,
  },
  fanButton: {
    float: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(1),
    alignText: 'center',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(0),
  },
  tableWidget: {
    overflowX: 'visible',
  },
  datePicker: {
    overflowX: 'visible',
    padding: theme.spacing(2),
    margin: 'auto',
  },
  errorIcon: {
    fontSize: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    float: 'left',
  },
  warningMessage: {
    fontSize: '0.9rem',
    height: '1.5rem',
    paddingTop: '0.3rem',
    float: 'left',
  },
});

export default withStyles(styles, { withTheme: true })(View);
