import React from 'react';
import {
  withStyles,
  Button,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from '@material-ui/core';
import TransparentWidget from '../../../components/TransparentWidget';
import Widget from '../../../components/Widget';
import Select from 'react-select';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import ErrorIcon from '@material-ui/icons/Error';
import SavedAudienceMessage, {ComAgeGenderFromSavedAudiences} from './savedAudienceMessage';
import trans from './../../../i18n/tran';

const Product = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <Widget
      overflow={true}
      bodyClass={classes.tableWidget}
      disableWidgetMenu={true}
      title={trans('AGE_GENDER_TITLE', props.lang)}
    >
      {props.savedAudienceSelected ? (
        <SavedAudienceMessage
        lang={props.lang}
        audiences={props.selectedSavedAudiences}
        >
          <ComAgeGenderFromSavedAudiences  lang={props.lang}
        audiences={props.selectedSavedAudiences} />

        </SavedAudienceMessage>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <TransparentWidget
              overflow={true}
              focusable={
                <Focusable
                  id={props.componentInfo.focusId}
                  refList={props.refList}
                ></Focusable>
              }
              title={
                <div>
                  {trans('AGES', props.lang)}
                  <div>
                    {props.componentInfo.getError &&
                    props.componentInfo.getError(props) &&
                    props.showAdErrors ? (
                      <div
                        style={{
                          color: 'red',
                        }}
                      >
                        <div className={classes.errorIcon}>
                          <ErrorIcon></ErrorIcon>
                        </div>
                        <div className={classes.warningMessage}>
                          {trans('AGE_GENDER_TITLE_RANGE', props.lang)}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              }
              bodyClass={classes.tableWidget}
              disableWidgetMenu={true}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <Typography>{trans('START', props.lang)}</Typography>
                  <Select
                    placeholder={'18'}
                    styles={theme.reactSelect}
                    onChange={e => {
                      props.changeSelectedAgesStart([e]);
                      let endList = [];
                      for (let i = e.value; i <= 65; i++) {
                        if (i === 65) endList.push({ label: '65+', value: i });
                        else endList.push({ value: i, label: i });
                      }
                      props.changeSelectorAgeEnd(endList);
                      if (props.selectedAgesEnd.length > 0) {
                        if (props.selectedAgesEnd[0].value < e.value)
                          props.changeSelectedAgesEnd([e]);
                      }
                    }}
                    value={props.selectedAgesStart}
                    options={props.selectorAgeStart}
                  ></Select>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Typography>{trans('END', props.lang)}</Typography>
                  <Select
                    styles={theme.reactSelect}
                    placeholder={'65+'}
                    onChange={e => {
                      props.changeSelectedAgesEnd([e]);
                    }}
                    value={props.selectedAgesEnd}
                    options={props.selectorAgeEnd}
                  ></Select>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div style={{ float: 'right' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      onClick={() => {
                        props.componentInfo.cleanFunction(props, true);
                      }}
                    >
                      {trans('CLEAN', props.lang)}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </TransparentWidget>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TransparentWidget
              overflow={true}
              title={<div>{trans('GENDER', props.lang)}</div>}
              bodyClass={classes.tableWidget}
              disableWidgetMenu={true}
            >
              <Grid container spacing={2}>
                <div style={{ margin: 'auto' }}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="gender"
                      value={props.selectedGender}
                      onChange={(e, value) => {
                        props.changeSelectedGender(value);
                      }}
                    >
                      <Grid item xs={4} md={4} lg={4}>
                        <FormControlLabel
                          value={'1'}
                          control={<Radio />}
                          label={trans('MEN', props.lang)}
                        />
                      </Grid>
                      <Grid item xs={4} md={4} lg={4}>
                        <FormControlLabel
                          value={'2'}
                          control={<Radio />}
                          label={trans('WOMEN', props.lang)}
                        />
                      </Grid>
                      <Grid item xs={4} md={4} lg={4}>
                        <FormControlLabel
                          value={'3'}
                          control={<Radio />}
                          label={trans('BOTH', props.lang)}
                        />
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>
            </TransparentWidget>
          </Grid>
        </Grid>
      )}
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(Product);
