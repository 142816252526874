import React, { FC, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
} from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormValues, OnBoardingStep } from '../type';
import { PaymentPlanItem } from './PaymentPlanItem';
import i18n from '../../../../../i18n';
import { useStyles } from './styles';
import { TierBasic, TiersI, useOnboardingContext } from '../../context';
import { GetTiers } from '../../../../rest';

export const PlanSelection: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tiers, changePlan, planType, disabled } = usePlanSelection();

  const isDesktop = useMediaQuery('(min-width:600px)');

  return (
    <>
      <Grid container justifyContent="center">
        <Typography className={classes.title}>
          {t('Choose the Right Plan for Your Business')}
        </Typography>
      </Grid>

      {isDesktop ? (
        <div className="overflow-auto grid lg:grid-cols-3 ">
          {tiers &&
            tiers.data.map((paymentPlan: TierBasic) => (
              <PaymentPlanItem
                data={paymentPlan}
                changePlan={changePlan}
                selected={planType === paymentPlan.id}
                technical={tiers.technical}
              />
            ))}
        </div>
      ) : (
        <ImageList className={classes.gridList} cols={1.2}>
          {tiers &&
            tiers.data.map((paymentPlan: TierBasic) => (
              <ImageListItem
                key={paymentPlan.id}
                className={classes.gridListTile}
                style={{ height: '100%' }}>
                <PaymentPlanItem
                  data={paymentPlan}
                  changePlan={changePlan}
                  selected={planType === paymentPlan.id}
                  technical={tiers.technical}
                />
              </ImageListItem>
            ))}
        </ImageList>
      )}

      <Button
        type={'submit'}
        fullWidth
        disabled={disabled}
        style={{ textTransform: 'none' }}
        color="primary"
        variant="contained">
        {t('Continue to checkout')}
      </Button>
    </>
  );
};

const usePlanSelection = () => {
  const { tiers, setTiers } = useOnboardingContext();
  const { t } = useTranslation();
  const tiersTranslations = t(`tierList`, { returnObjects: true }) as any;
  const { isSubmitting, isValid, setFieldValue, values } =
    useFormikContext<FormValues>();

  const changePlan = useCallback(
    (value: number | undefined) => {
      setFieldValue('planType', value);
    },
    [setFieldValue],
  );

  useEffect(
    () => {
      const call = async () => {
        const result = await GetTiers();

        const tempTiers = result.map((tier: any) => {
          return {
            // @ts-ignore
            ...tiersTranslations[tier.id],
            priceMonthly: tier.fee,
            percentage: tier.p_ad_spend,
            id: tier.id,
          };
        });
        const technicalTemp = Object.keys(tiersTranslations).reduce(
          (result, item) =>
            tiersTranslations[item].support.length > Object.keys(result).length
              ? tiersTranslations[item].support
              : result,
          [],
        );

        const tierStructure: TiersI = {
          data: tempTiers,
          technical: technicalTemp,
        };
        setTiers(tierStructure);
        // validateForm().then();
      };

      if (!tiers) {
        call();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    tiers,
    changePlan,
    planType: values.planType,
    disabled: !isValid || isSubmitting,
  };
};

export const PlanSelectionStep: OnBoardingStep = {
  component: PlanSelection,
  label: i18n.t('Purchase a plan'),
  initialValues: {
    planType: undefined,
  },
  welcomeTitle: '',
  welcomeDescription: '',
  validationSchema: Yup.object().shape({
    planType: Yup.number()
      .test('plan-selected', value => !!value && value > 0)
      .required(i18n.t('Please select a payment plan')),
  }),
};
