import { withTheme } from '@material-ui/core/styles';
import { compose, withState, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toggleSidebar } from '../../../Layout/LayoutState';

import SideBarFiltersView from './SideBarFiltersView';

export default compose(
  withRouter,
  withTheme,
  connect(
    state => ({
      isSidebarOpened: state.layout.isSidebarOpened,
    }),
    { toggleSidebar },
  ),

  withState('productIsMultiple', 'changeProductIsMultiple', false),
  withState('optimizationIsMultiple', 'changeOptimizationIsMultiple', false),
  withState('accountIsMultiple', 'changeAccountIsMultiple', false),
  withState('adAccountIsMultiple', 'changeAdAccountIsMultiple', false),
  withState('campaignIsMultiple', 'changeCampaignIsMultiple', false),
  withState('categoryIsMultiple', 'changeCategoryIsMultiple', false),
  withState('ageIsMultiple', 'changeAgeIsMultiple', false),
  withState('genderIsMultiple', 'changeGenderIsMultiple', false),
  withState('brandIsMultiple', 'changeBrandIsMultiple', false),
  withState('companyIsMultiple', 'changeCompanyIsMultiple', false),
  withState('campaignNameIsMultiple', 'changeCampaignNameIsMultiple', false),
  withState('autoLoad', 'changeAutoLoad', false),
  withState('dateHasErrors', 'changeDateHasErrors', false),

  lifecycle({
    componentWillUnmount() {},
  }),
)(SideBarFiltersView);
