import React from 'react';
import {
  withStyles,
  Tooltip,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import Widget from '../../../components/Widget';
import Select from 'react-select';
import Focusable from '../Focusable';
import { styles } from '../../../utils/styles';
import {
  productSelectorId,
  fanPageComponentId,
} from '../../../components/Constants';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import AddIcon from '@material-ui/icons/Add';
import {
  updateProductAdminApiFiltersCreateAd,
  getAdAccountsByProductApiAdmin,
  createAdGetComponentInfo,
} from '../../../utils/functions';
import trans from './../../../i18n/tran';

const Product = ({ classes, theme, onChange, value, ...props }) => {
  function clean(productSelect) {
    if (props.isCreateAd) {
      let component = createAdGetComponentInfo(fanPageComponentId, props);
      if (component.cleanFunction) component.cleanFunction(props);
      if (!productSelect) props.componentInfo.getchangeError(props)(true);
      props.changeSelectorCustomAudience([]);
      props.changeSelectorSavedAudience([]);
      props.changeSelectedCustomAudiences([]);
      props.changeSelectedSavedAudiences([]);
      props.changeSavedAudienceSelected(false);
    }
  }
  return (
    <Widget
      overflow={true}
      focusable={
        <Focusable
          id={props.isCreateAd ? props.componentInfo.focusId : ''}
          refList={props.refList}
        ></Focusable>
      }
      title2={
        <div style={{ float: 'right' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.isFilterProducts}
                onChange={(event, checked) => {
                  props.changeIsFilterProducts(checked);
                }}
              ></Checkbox>
            }
            label={trans('NEW_CAMPAIGN_PRODUCT_FILTER', props.lang)}
          />
        </div>
      }
      title={
        props.isCreateAd ? (
          <div>
            {trans('NEW_CAMPAIGN_PRODUCT', props.lang)}
            <div>
              {props.isCreateAd &&
              props.componentInfo.getError &&
              props.componentInfo.getError(props) &&
              props.showAdErrors ? (
                <div
                  style={{
                    color: 'red',
                  }}
                >
                  <div className={classes.errorIcon}>
                    <ErrorIcon></ErrorIcon>
                  </div>
                  <div className={classes.warningMessage}>
                    {trans('NEW_CAMPAIGN_PRODUCT_SELECT', props.lang)}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )
      }
      bodyClass={classes.tableWidget}
      disableWidgetMenu={true}
    >
      {props.isCampaign ? (
        <Grid container spacing={2}>
          <Grid item xs={9} sm={10} md={11} lg={11}>
            <h2>{trans('PRODUCT_SELECT', props.lang)}</h2>
          </Grid>
          <Grid item xs={3} sm={2} md={1} lg={1}>
            {
              <Tooltip title={trans('NEW_CAMPAIGN_PRODUCT_CREATE', props.lang)}>
                <div>
                  <Button
                    disabled={props.selectedAdminAdAccounts.length === 0}
                    id="Create-Product"
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.Button}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      props.changeShowCreateProduct(true);
                    }}
                  >
                    <AddIcon></AddIcon>
                  </Button>
                </div>
              </Tooltip>
            }
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={12}>
          {props.isFilterProducts ? (
            <div
              style={{
                width: '90%',
                margin: 'auto',
                border: 'thick solid #013f8a',
              }}
            >
              <div
                style={{
                  width: '90%',
                  margin: 'auto',
                }}
              >
                <Typography>Filtros: </Typography>
                <div style={{ height: '25px' }}></div>
                <Typography>{trans('COMPANY', props.lang)}</Typography>
                <Select
                  id={'CompanySelector'}
                  styles={theme.reactSelect}
                  placeholder={'Seleccione Empresa'}
                  isDisabled={props.selectorAdminCompany.length === 0}
                  isMulti={false}
                  options={props.selectorAdminCompany}
                  value={props.selectedAdminCompanies}
                  onChange={e => {
                    props.changeSelectedAdminCompanies([e]);
                    updateProductAdminApiFiltersCreateAd(props, [e], [], true);
                    clean();
                  }}
                ></Select>
                <Typography>Marca</Typography>
                <Select
                  id={'BrandSelector'}
                  styles={theme.reactSelect}
                  placeholder={'Seleccione Marca'}
                  isDisabled={props.selectorAdminBrand.length === 0}
                  isMulti={false}
                  options={props.selectorAdminBrand}
                  value={props.selectedAdminBrands}
                  onChange={e => {
                    props.changeSelectedAdminBrands([e]);
                    updateProductAdminApiFiltersCreateAd(
                      props,
                      props.selectedAdminCompanies,
                      [e],
                      false,
                    );
                    clean();
                  }}
                ></Select>
                <div style={{ height: '25px' }}></div>
              </div>
            </div>
          ) : (
            ''
          )}
          <div style={{ height: '25px' }}></div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div
            style={{
              width: '90%',
              margin: 'auto',
            }}
          >
            <Typography>{trans('NEW_CAMPAIGN_PRODUCT', props.lang)}</Typography>
            <Select
              id={productSelectorId}
              styles={theme.reactSelect}
              isDisabled={props.selectorAdminProductFiltered.length === 0}
              placeholder={
                props.selectedTenants.length === 0
                  ? trans('BUSINESS_SELECT', props.lang)
                  : props.selectorAdminProductFiltered.length === 0
                  ? trans('NEW_CAMPAIGN_PRODUCT_ERROR1', props.lang)
                  : trans('NEW_CAMPAIGN_PRODUCT_SELECT', props.lang)
              }
              isMulti={false}
              value={props.selectedAdminProducts}
              options={props.selectorAdminProductFiltered}
              onChange={e => {
                props.changeSelectedAdminProducts([e]);
                if (props.isCreateAd) {
                  getAdAccountsByProductApiAdmin(props, e.value, {
                    createAd: true,
                  });
                  props.componentInfo.getchangeError(props)(false);
                  clean(true);
                }
              }}
            ></Select>
          </div>{' '}
          <div style={{ height: '25px' }}></div>
        </Grid>
      </Grid>
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(Product);
