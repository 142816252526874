import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import Widget from '../../../../../components/Widget';
//import Focusable from "../Focusable";
import { styles } from '../../../../../utils/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { CustomDateSlider } from '../../../../../components/Sidebar/CustomDateSlider';
import Focusable from '../../../../createad/Focusable';
//import { CustomDateSlider } from "../../../components/Sidebar/CustomDateSlider";
const ModifyDatesSelection = ({
  classes,
  theme,
  onChange,
  value,
  ...props
}) => {
  return (
    <Widget
      overflow={true}
      focusable={
        <Focusable
          id={props.componentInfo.focusId}
          refList={props.refList}
        ></Focusable>
      }
      title={'Fecha de Campaña'}
      bodyClass={classes.tableWidget}
      disableWidgetMenu={true}
    >
      {props.componentInfo.getError(props) ? (
        <Grid item xs={12} lg={12} md={12}>
          {props.componentInfo.getMessage(props).message}
        </Grid>
      ) : (
        ''
      )}
      <div className={classes.datePicker}>
        <CustomDateSlider
          disableStartDateButton={true}
          inputPropsStartDate={{
            style: {
              fontSize: '0.9rem',
              textAlign: 'center',
            },
            readOnly: true,
          }}
          inputPropsEndDate={{
            style: {
              fontSize: '0.9rem',
              textAlign: 'center',
            },
            readOnly: true,
          }}
          showErrors={true}
          reset={props.reset}
          changeReset={props.changeReset}
          ErrorIcon={<ErrorIcon />}
          dateRange={props.modifySelectedDates.value}
          changeDateRange={props.changeSelectedCampaignDatesHandler}
          selectedDates={props.modifySelectedDates.value}
          changeSelectedDates={props.changeSelectedCampaignDatesHandler}
          id={'CreateAdDatePicker'}
          validateDateEqualOrBiggerThanToday={false}
          hideSlider={true}
          changeError={props.componentInfo.getchangeError(props)}
          Error={props.componentInfo.getError(props)}
        >
          {' '}
        </CustomDateSlider>
      </div>
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(ModifyDatesSelection);
