import {
  getAdAccountsUrlAdminAdAccountPage,
  assingAdAccountUrl,
  deletePermissionsUrl,
  successHttpCode,
} from './../../components/Constants';
import Auth from '../../authentication/Auth';
import { getNewNonceAndUserPages } from '../../utils/functions';
import trans from './../../i18n/tran';

async function getAdAccounts(callback1, props, refreshNonce) {
  if (refreshNonce) {
    await getNewNonceAndUserPages(props);
  }
  var url = `${getAdAccountsUrlAdminAdAccountPage}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('access_token')}`,
  );
  myHeaders.append('nonce', localStorage.getItem('nonce'));
  await fetch(url, { method: 'GET', headers: myHeaders })
    .then(response => {
      if (response.status === successHttpCode) {
        response.json().then(resultjson => {
          if (resultjson) callback1(undefined, resultjson.payload);
          else {
          }
        });
      }
    })
    .catch(err => {console.log(err)});
}

function inviteBlackbox(AdAccountId, callback, props) {
  if (props.changeIsLoadingLayout) props.changeIsLoadingLayout(true);
  var url = `${assingAdAccountUrl}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('access_token')}`,
  );
  myHeaders.append('nonce', localStorage.getItem('nonce'));
  var mybody = { adAccountId: AdAccountId };
  fetch(url, {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(mybody),
  })
    .then(response => {
      response.json().then(responseString => {
        if (response.status === successHttpCode) {
          if (responseString.message)
            callback(undefined, responseString.message);
          else
            callback(
              undefined,
              trans('AD_ACCOUNTS_PAGE_MESSAGE5', props.lang),
            );
        } else {
          if (responseString.message) callback(true, responseString.message);
          else
            callback(
              true,
              trans('AD_ACCOUNTS_PAGE_ERROR2', props.lang),
            );
        }
      });
    })

    .catch(err => {
      callback(
        true,
        trans('AD_ACCOUNTS_PAGE_ERROR2', props.lang),
      );
    });
}

function deletePermissions(callback) {
  var url = `${deletePermissionsUrl}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append(
    'Authorization',
    `Bearer ${localStorage.getItem('access_token')}`,
  );
  myHeaders.append('nonce', localStorage.getItem('nonce'));
  // var mybody = {};
  fetch(url, {
    method: 'DELETE',
    headers: myHeaders,
  })
    .then(response => {
      return response.json();
    })
    .then(responseString => {
      if (responseString.success) {
        let auth = new Auth();

        auth.sessionExpired();
      } else {
        if (responseString.token) {
          let auth = new Auth();

          auth.sessionExpired();
        } else {
          callback(true, `No se ha podido realizar la operación.`);
        }
      }
    })
    .catch(err => {
      console.log(err);
    });
}

export { getAdAccounts, deletePermissions, inviteBlackbox };
