import React from 'react';
import {
  Grid,
  withStyles,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import Select from 'react-select';
import Widget from '../../components/Widget/WidgetContainer';
import ComputerIcon from '@material-ui/icons/Computer';
import {
  savePayAlyCompanyInfo,
  alertPopUp,
  generatePayAlyLink,
} from '../../utils/functions';
import validator from 'validator';
import {
  GAEventCategoryCompany,
  GAEventActionCompanyName,
  GAEventActionCompanyCountry,
  sessionStorageUTMValue,
  billingSaleGA,
  billingCompanyNameGA,
  billingCompanyCountryGA,
} from '../../components/Constants';
import { getItem12Button, getUserItemStyle } from '../../utils/styles';
// import DeleteIcon from "@material-ui/icons/Delete";
import PageTitle from '../../components/PageTitle/PageTitle';
import ClientApproval from '../ClientApproval';
import './../../i18n/tran';
import trans from './../../i18n/tran';
import {
  setGASessionItem,
  getGASessionItem,
  sendGAEvent,
  sendGAEventBillingSale,
} from '../../utils/ga';


const BillingView = ({ classes, theme, onChange, value, ...props }) => {
  if (sessionStorage.getItem('clientApproval') !== null) {
    return <ClientApproval {...props}></ClientApproval>;
  } else
    return (
      <React.Fragment>
        <Grid item xs={12} md={12} lg={12}>
          <PageTitle title="Billing" />
          {props.selectedTenants && props.selectedTenants.length > 0 ? (
            <div style={{ padding: '25px' }}>
              {'Llave única: '}
              <span style={{ fontWeight: 'bold' }}>
                {props.selectedTenants[0].UniqueKey}
              </span>
            </div>
          ) : (
            ''
          )}
          <Widget
            title={
              <div>
                <h2 style={{ textAlign: 'center' }}>Productos</h2>
              </div>
            }
            bodyClass={classes.tableWidget}
            overflow
            disableWidgetMenu={true}
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {props.products.length > 0 ? (
                  <Grid container spacing={4}>
                    {props.products.map(
                      ({ Id, Name, MinMonths, Price, Description }, index) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            container
                            key={index}
                            style={{
                              paddingTop: '10px',
                              paddingBottom: '10px',
                            }}
                          >
                            <Grid
                              item
                              xs={11}
                              md={11}
                              lg={11}
                              style={getUserItemStyle({ margin: 'auto' })}
                            >
                              <Widget
                                title={<ComputerIcon></ComputerIcon>}
                                bodyClass={classes.tableWidget}
                                overflow
                                disableWidgetMenu={true}
                              >
                                <Grid item xs={12}>
                                  <h3>{'Nombre: '}</h3>

                                  {Name}
                                </Grid>
                                <Grid item xs={12}>
                                  <h3>{'Descripcion: '}</h3>

                                  {Description}
                                </Grid>

                                <Grid item xs={12}>
                                  <h3>{'Precio: '}</h3>${Price}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingTop: '10px' }}
                                >
                                  <div style={{ width: '100%' }}>
                                    <div
                                      style={{ width: '50%', margin: 'auto' }}
                                    >
                                      <Button
                                        disabled={props.selectedProduct === Id}
                                        style={getItem12Button({
                                          width: '100%',
                                          //backgroundColor: "#d9534f",
                                          color: 'white',
                                        })}
                                        variant="contained"
                                        size="medium"
                                        color={'primary'}
                                        onClick={() => {
                                          props.changeSelectedProduct(Id);
                                          props.changeSelectedProductName(
                                            Name + ' $' + Price + ' USD',
                                          );
                                          window.scrollTo(0, 10000);

                                          setGASessionItem(
                                            billingSaleGA,
                                            sessionStorageUTMValue,
                                            Name,
                                          );
                                          sendGAEventBillingSale(Price);
                                        }}
                                      >
                                        {props.selectedProduct === Id
                                          ? 'Seleccionado'
                                          : 'Seleccionar'}
                                      </Button>
                                    </div>
                                  </div>
                                </Grid>
                              </Widget>
                            </Grid>
                          </Grid>
                        );
                      },
                    )}
                  </Grid>
                ) : (
                  <h2>No se encontraron productos en este momento</h2>
                )}
              </Grid>
              <Grid item xs={12}>
                <Widget
                  title={
                    <div style={{ padding: '10px' }}>
                      {trans('BILLING_FORM_HEADER', props.lang)}
                    </div>
                  }
                  bodyClass={classes.tableWidget}
                  overflow
                  disableWidgetMenu={true}
                >
                  <Grid container spacing={4}>     
                    <Grid item xs={12} sm={6}>
                      <div style={{ paddingTop: '20px' }}>
                        <TextField
                          disabled={false}
                          id={'Nombre de Empresa_Text'}
                          value={props.companyName}
                          onChange={e => {
                            props.changeCompanyName(e.target.value);
                          }}
                          style={{ width: '100%' }}
                          variant="outlined"
                          label="Nombre de Empresa"
                          InputLabelProps={{
                            classes: {
                              root: classes.textFieldLabel,
                            },
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div style={{ paddingTop: '20px' }}>
                        <TextField
                          disabled={false}
                          style={{ width: '100%' }}
                          id={'NIT_Text'}
                          value={props.nit}
                          onChange={e => {
                            props.changeNit(e.target.value);
                          }}
                          variant="outlined"
                          label={trans('TAX_ID', props.lang)}
                          InputLabelProps={{
                            classes: {
                              root: classes.textFieldLabel,
                            },
                          }}
                        />{' '}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div style={{ paddingTop: '20px' }}>
                        <TextField
                          disabled={false}
                          style={{ width: '100%' }}
                          id={'companyAddress_Text'}
                          value={props.companyAddress}
                          onChange={e => {
                            props.changeCompanyAddress(e.target.value);
                          }}
                          variant="outlined"
                          label="Dirección"
                          InputLabelProps={{
                            classes: {
                              root: classes.textFieldLabel,
                            },
                          }}
                        />{' '}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div style={{ paddingTop: '20px' }}>
                        <TextField
                          disabled={false}
                          style={{ width: '100%' }}
                          id={'phone_Text'}
                          value={props.phone}
                          onChange={e => {
                            props.changePhone(e.target.value);
                          }}
                          variant="outlined"
                          label="Teléfono"
                          InputLabelProps={{
                            classes: {
                              root: classes.textFieldLabel,
                            },
                          }}
                        />{' '}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div style={{ paddingTop: '20px' }}>
                        <TextField
                          disabled={false}
                          style={{ width: '100%' }}
                          id={'email_Text'}
                          value={props.email}
                          onChange={e => {
                            props.changeEmail(e.target.value);
                          }}
                          variant="outlined"
                          label="Correo Electrónico"
                          InputLabelProps={{
                            classes: {
                              root: classes.textFieldLabel,
                            },
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>País</Typography>
                      <Select
                        styles={theme.reactSelect}
                        options={props.selectorCountryCode}
                        value={props.selectedCountryCode}
                        onChange={e => {
                          props.changeSelectedCountryCode([e]);
                        }}
                      ></Select>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        disabled={true}
                        style={{ width: '100%' }}
                        id={'plan_Text'}
                        value={props.selectedProductName}
                        variant="outlined"
                        label="Plan Seleccionado"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div style={getItem12Button({ float: 'right' })}>
                        <Button
                          fullWidth={true}
                          variant="contained"
                          size="medium"
                          color="primary"
                          onClick={async () => {
                            if (props.selectedProduct === -1) {
                              alertPopUp(
                                'Favor Seleccione Producto',
                                props,
                                true,
                              );
                            } else if (props.companyName.length === 0) {
                              alertPopUp(
                                'Favor Ingresar Nombre de Empresa',
                                props,
                                true,
                              );
                            } else if (props.nit.length === 0) {
                              alertPopUp('Favor Ingresar Nit', props, true);
                            } else if (props.companyAddress.length === 0) {
                              alertPopUp(
                                'Favor Ingresar Dirección de Empresa',
                                props,
                                true,
                              );
                            } else if (props.phone.length === 0) {
                              alertPopUp(
                                'Favor Ingresar teléfono de la Empresa',
                                props,
                                true,
                              );
                            } else if (props.email.length === 0) {
                              alertPopUp(
                                'Favor Ingresar correo electrónico',
                                props,
                                true,
                              );
                            } else if (!validator.isEmail(props.email.trim())) {
                              alertPopUp(
                                'Favor Ingresar un correo electrónico correcto',
                                props,
                                true,
                              );
                            } else if (props.selectedCountryCode.length === 0) {
                              alertPopUp('Favor Seleccione País', props, true);
                            } else {
                              let saved = await savePayAlyCompanyInfo(props);

                              if (saved) {
                                setGASessionItem(
                                  billingCompanyNameGA,
                                  sessionStorageUTMValue,
                                  props.companyName,
                                );
                                let sendGAdataBillingCompanyName =
                                  getGASessionItem();

                                sendGAEvent({
                                  category: GAEventCategoryCompany,
                                  action: GAEventActionCompanyName,
                                  label: sendGAdataBillingCompanyName,
                                });

                                setGASessionItem(
                                  billingCompanyCountryGA,
                                  sessionStorageUTMValue,
                                  props.selectedCountryCode[0].label,
                                );
                                let sendGAdataBillingCompanyCountry =
                                  getGASessionItem();

                                sendGAEvent({
                                  category: GAEventCategoryCompany,
                                  action: GAEventActionCompanyCountry,
                                  label: sendGAdataBillingCompanyCountry,
                                });

                                await generatePayAlyLink(props);
                              }
                            }
                          }}
                        >
                          {trans('BILLING_FORM_CALLTOACTION', props.lang)}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Widget>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </React.Fragment>
    );
};

function hexToRgbA(hex, alpha) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      alpha +
      ')'
    );
  }
  // throw new Error('Bad Hex');
}

const styles = theme => ({
  textFieldLabel: {
    color: hexToRgbA(theme.palette.text.primary, 0.5),
    with: '100%',
  },
});

export default withStyles(styles, { withTheme: true })(BillingView);
