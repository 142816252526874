import auth0 from 'auth0-js';
import {
  RedirectEndPoint,
  LogoutEndPoint,
  ExpiredEndPoint,
  //defaultPermissions,
} from './../components/Constants';

import { Reset, Track, NewEvent } from './../utils/mixpanel';
export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: 'alyblackbox.auth0.com',
    clientID: 'EXoHOFTnreZdpevqIENTVXWJ3qEACJfY',
    redirectUri: RedirectEndPoint,
    audience: 'https://blackbox-api/',
    responseType: 'token id_token',
    scope: 'openid profile email',
  });

  login() {
    this.auth0.authorize();
  }

  authorize() {
    this.auth0.authorize();
  }

  handleAuthentication(callback) {
    this.auth0.parseHash((errParseHash, authResults) => {
      if (!callback) throw new Error('Callback undefined');
      else if (errParseHash) callback(errParseHash);
      else {
        if (authResults && authResults.accessToken && authResults.idToken) {
          this.setSession(authResults, res => {
            callback(res);
          });
        } else {
          callback(new Error('No access token returned'));
        }
      }
    });
  }
  /* NOTE: is is a replacement for handleAuthentication
  After authentication occurs, you MUST parse data with parseHash function from URL from Auth0 
  fragment when the user is redirected back to your application in order to extract 
  the result of an Auth0 authentication response. 
  You may choose to handle this in a callback page that will 
  then redirect to your main application, or in-page, as the situation dictates.  
  https://auth0.com/docs/libraries/auth0js#extract-the-authresult-and-get-user-info
  */
  setupSession(callback) {
    if (!callback) return;    // callback is required, this function should be call without callback or strange this could happen
    this.auth0.parseHash((err, res) => {
      if (err) console.log(err);
      if (callback && err) {
        Track(NewEvent("login", "facebook_login", "fail"));
        callback(err);
        return
      }
      if (callback && !res) {
        callback("no authenticated");
        return
      }
      Track(NewEvent("login", "facebook_login", "success"));
      this.setSession(res, callback);
    })
  }
  // stores infromation from Auth0 in the localStorage:
  // access_token, id_token, expires_At, aly_expires_At, userInfo
  // removes selectedTenants
  setSession(authResults, callback) {
    //Assign local storage items
    let now = new Date().getTime();
    // from Auth0/Facebook token expires in about 2 H (120 minutes)
    // convert it from milisseconds and adds now
    let expiresAt = JSON.stringify(authResults.expiresIn * 1000 + now);
    let alyUserAuthNonceMax = 80; // <----- MUST be equal to the project param
    // convert it from minutes and adds now
    let alyExpiresAt = JSON.stringify(alyUserAuthNonceMax * 60 * 1000 + now);

    localStorage.setItem('access_token', authResults.accessToken);
    localStorage.setItem('id_token', authResults.idToken);
    localStorage.setItem('expires_At', expiresAt);
    localStorage.setItem('aly_expires_At', alyExpiresAt);
    localStorage.removeItem('selectedTenants');
    
    //Trying to get the user info
    this.auth0.client.userInfo(authResults.accessToken, (err, user) => {
      if (!callback) throw new Error('Callback undefined');
      else if (err) callback(err);
      else {
        localStorage.setItem('userInfo', JSON.stringify(user));    
        callback(undefined);
      }
    });
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResults) => {
      if (authResults && authResults.accessToken && authResults.idToken) {
        this.setSession(authResults);
      } else if (err) {
        this.logout();
      }
    });
  }

  isAuthenticated() {
    let expiresAt = JSON.parse(localStorage.getItem('expires_At'));
    return new Date().getTime() < expiresAt;
  }

  logout() {
    localStorage.setItem('logOut', 'true');
    sessionStorage.clear();
    sessionStorage.setItem('logOut', 'true');
    // Remove tokens and expiry time

    localStorage.removeItem('selectedTenants');
    localStorage.removeItem('userPages');
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_At');
    localStorage.removeItem('aly_expires_At');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    localStorage.removeItem('nonce');
    localStorage.removeItem('picture');
    localStorage.removeItem('dictionary');
    this.auth0.logout({
      returnTo: LogoutEndPoint,
    });
    Reset();
  }

  sessionExpired() {
    localStorage.setItem('logOut', 'true');
    sessionStorage.clear();
    sessionStorage.setItem('logOut', 'true');
    // Remove tokens and expiry time

    localStorage.removeItem('selectedTenants');
    localStorage.removeItem('userPages');
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_At');
    localStorage.removeItem('aly_expires_At');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    localStorage.removeItem('nonce');
    localStorage.removeItem('picture');
    localStorage.removeItem('dictionary');
    this.auth0.logout({
      returnTo: ExpiredEndPoint,
    });
    Reset();
  }
}
