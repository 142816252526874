import { compose, withState, lifecycle } from 'recompose';
import View from './InterestsView';

const mycompose = compose(
  withState('interestsSearchText', 'changeInterestsSearchText', ''),
  withState('interestsSelector', 'changeInterestsSelector', []),
  withState('interestsSelected', 'changeInterestsSelected', []),
  withState('pagePagination', 'changePagePagination', 0),
  withState('rowsPerPage', 'changeRowsPerPage', 5),
  withState('advanceTargetingSearch', 'setAdvanceTargetingSearch', 'interests'),
  lifecycle({
    componentDidMount() {},
  }),
);

export default mycompose(View);
