import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { palette } from '../../theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      marginBottom: 20,
      marginTop: 20,
    },
    cardPayment: {
      marginBottom: 20,
      marginTop: 20,
    },
    selectPlanButton: {
      textTransform: 'none',
      backgroundColor: palette.lightPurple,
      textColor: 'red',
      marginBottom: 20,
      marginTop: 20,
    },
    tableRowName: { fontFamily: 'Montserrat-Medium', fontSize: 12 },
    tableRowDetail: { fontFamily: 'Montserrat-SemiBold', fontSize: 13 },
    selectPlanButtonText: {
      color: palette.purple,
      fontFamily: 'Montserrat-Medium',
      fontSize: 15,
    },
    changePlanButton: {
      textTransform: 'none',
      alignItems: 'flex-end',
    },
    selectPlanText: {
      textTransform: 'none',
      color: palette.white,
      fontFamily: 'Montserrat-Medium',
      fontSize: 15,
    },
    changePlanButtonText: {
      textTransform: 'none',
      color: palette.purpleAly,
      fontFamily: 'Montserrat-Medium',
      fontSize: '11px',
      lineHeight: '16px',
    },
    cardContent: {},

    featureEnable: {
      fontFamily: 'Montserrat-Medium',
      fontSize: '12px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: 10,
    },
    featureDisable: {
      fontFamily: 'Montserrat-Medium',
      fontSize: 10,
      opacity: 0.5,
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: 10,
    },
    title: {
      fontFamily: 'Montserrat-Regular',
      fontSize: '11px',
      lineHeight: '16px',
      marginBottom: 20,
    },

    subTitle: { fontFamily: 'Montserrat-Medium', fontSize: 12 },
    paymentSummaryTitle: {
      paddingTop: 10,
      fontFamily: 'Montserrat-SemiBold',
      fontSize: 15,
    },
    field: {
      marginBottom: '16px',
    },
    cardTitle: {
      fontFamily: 'Montserrat-SemiBold',
      fontSize: '28px',
      lineHeight: '36px',
      color: palette.purpleRain,
      textAlign: 'left',
    },
    whiteText: {
      color: palette.white,
    },
    priceText: {
      fontFamily: 'Montserrat-SemiBold',
      fontSize: '28px',
      lineHeight: '36px',
      marginBottom: 10,
      textAlign: 'right',
      color: palette.purpleRain,
    },
    pricePerTimeText: {
      fontFamily: 'Montserrat-Medium',
      fontSize: '12px',
      lineHeight: '16px',
    },
    changePlanTitle: {
      fontFamily: 'Montserrat-SemiBold',
      fontSize: '11px',
      lineHeight: '16px',
      color: palette.emphasis,
    },
    planName: {
      fontFamily: 'Montserrat-SemiBold',
      fontSize: '28px',
      lineHeight: '36px',
      color: palette.purpleAly,
    },
    planPrice: {
      fontFamily: 'Montserrat-Medium',
      fontSize: '28px',
      lineHeight: '36px',
      color: '#000',
    },
    planPriceMonth: {
      fontFamily: 'Montserrat-Medium',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#000',
    },

    whatCVV: { fontFamily: 'Montserrat-Regular', fontSize: 11 },
    float: {
      float: 'right',
      color: palette.purple,
      fontFamily: 'Montserrat-Regular',
      fontSize: 14,
    },
    round: {
      height: '25px',
      width: '25px',
      border: '2px solid #3E4FD3',
      textAlign: 'center',
      borderRadius: '50%',
      display: 'inline-block',
    },
    discountText: {
      fontFamily: 'Montserrat-Regular',
      fontSize: 13,
      color: palette.purple,
    },
    table: {
      marginBottom: 20,
    },
    gridList: {
      flexWrap: 'nowrap',
    },
    gridListTile: { height: '100%' },
  }),
);
