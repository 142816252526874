import React from 'react';

import trans from '../../../i18n/tran';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

export default function Parameters(props, classes) {
  if (props.report && props.report.Params && props.report.Params.length > 0) {
    // console.log(props.report.Params)
    let rows = props.report.Params;
    return (
      <React.Fragment>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {trans('REPORT_VIEWER_PARAMETERS', props.lang)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    <TextField
                      id="standard-basic"
                      label={row.Name}
                      defaultValue={row.Default}
                      onChange={e => {
                        props.paramChanged(e, row.Name);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  } else {
    return <></>;
  }
}
