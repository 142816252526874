import React from 'react';
import { withStyles, Button } from '@material-ui/core';
import Widget from '../../../components/Widget';
import { styles } from '../../../utils/styles';
import DefaultPreview from '../../../images/DefaultPreview.png';
import { validateUrl } from '../../../utils/functions';
import { showChangeToLandscape } from '../../../utils/generalFunctions';
import rotateDeviceGif from '../../../images/rotateDeviceGif.gif';
import {
  saveAssetRequest,
  getPreviewApiAdmin,
} from '../../../components/ApiClient/Asset';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import trans from './../../../i18n/tran';
let iframeArray = [];
let processedFiles = 0;

const AdPreview = ({ classes, theme, onChange, value, ...props }) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  let adFormat = '';
  let placement = props.provider[0].value.placement;
  if (placement === 'facebook') {
    let facebookPosition = props.provider[0].value.position.facebook_positions;
    if (facebookPosition === 'feed') adFormat = 'MOBILE_FEED_STANDARD';
    else if (facebookPosition === 'story') adFormat = 'FACEBOOK_STORY_MOBILE';
  } else if (placement === 'instagram') {
    let instagramPosition = props.provider[0].value.position.instagram_positions;
    if (instagramPosition === 'stream') adFormat = 'INSTAGRAM_STANDARD';
    else if (instagramPosition === 'story') adFormat = 'INSTAGRAM_STORY';
  }
  let validation = false;
  if (
    props.adBodies[0] !== '' &&
    props.selectedAdminFanPages.value !== undefined &&
    props.selectedAdminAdAccounts.length > 0 &&
    validateUrl(props.adLinkPreview) &&
    props.adLinkPreview !== '' &&
    props.selectedCta.length > 0 &&
    !props.hasErrorUpload &&
    props.adFiles.length > 0
  ) {
    if (placement === 'facebook' && props.adTitles[0] === '') {
      validation = false;
    } else {
      validation = true;
    }
  } else {
    validation = false;
  }
  if (props.visiblePreview !== validation) {
    props.changeVisiblePreview(validation);
    if (!validation) props.changePreview([]);
  }

  function loadPreview(adFormat) {
    setActiveStep(0);
    processedFiles = 0;
    props.changeIsLoadingLayout(true);
    iframeArray = [];
    props.changePreviewValidation(true);
    for (let i = 0; i < props.adFiles.length; i++) {
      let file = props.adFiles[i];
      saveAssetRequest(
        props.selectedAdminAdAccounts[0].externalId,
        file,
        saveAssetRequestResultHandler,
      );
    }
  }

  function saveAssetRequestResultHandler(error, result) {
    processedFiles++;
    if (result !== undefined) {
      let resultFile =
        result[0].UrlPrefix +
        result[0].Directory +
        '/' +
        result[0].Key +
        '.' +
        result[0].Ext;
      let ctaType = props.selectedCta[0].value;
      let ctaLink = props.adLinkPreview;
      let description = props.description.length > 0 ? props.description[0] : '';
      let image = resultFile;
      let pageId = props.selectedAdminFanPages.value;
      let adAccountExtId = props.selectedAdminAdAccounts[0].externalId;
      for (let x = 0; x < props.adTitles.length; x++) {
        for (let y = 0; y < props.adBodies.length; y++) {
          let name = props.adTitles[x];
          let message = props.adBodies[y];
          getPreviewApiAdmin(
            props,
            {
              ctaType: ctaType,
              ctaLink: ctaLink,
              description: description,
              message: message,
              name: name,
              image: image,
              pageId: pageId,
              adFormat: adFormat,
              adAccountExtId: adAccountExtId,
            },
            getPreviewApiAdminResultHandler,
          );
        }
      }
    }
  }

  function getPreviewApiAdminResultHandler(error, result) {
    if (!error) {
      let iframe = result[0].body.substring(
        result[0].body.indexOf('src="') + 5,
        result[0].body.indexOf('" width'),
      );
      iframeArray.push(iframe.replace('amp;', ''));
      props.changePreview(iframeArray);
    }
    if (processedFiles === props.adFiles.length)
      props.changeIsLoadingLayout(false);
  }

  return (
    <Widget
      overflow={true}
      title={'Vista Previa'}
      bodyClass={classes.tableWidget}
    >
      <div align="center">
        <Button
          disabled={!props.visiblePreview}
          style={{ height: '2.5rem' }}
          variant="contained"
          size="small"
          color="primary"
          className={classes.Button}
          onClick={e => {
            loadPreview(adFormat);
          }}
        >
          {trans('PREVIEW', props.lang)}
        </Button>
        {props.preview.length > 0 &&
        props.visiblePreview &&
        showChangeToLandscape() ? (
          <div>
            <img
              alt=""
              style={{ width: '100%', padding: '10px' }}
              src={rotateDeviceGif}
            ></img>
          </div>
        ) : props.preview.length > 0 &&
          props.visiblePreview &&
          !showChangeToLandscape() ? (
          <div>
            <iframe
              title="AdPreview"
              src={props.preview[activeStep]}
              scrolling="no"
              className={
                placement === 'facebook' ? classes.iframe : classes.iframeIG
              }
            ></iframe>
            <MobileStepper
              steps={props.preview.length}
              position="static"
              variant="text"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === props.preview.length - 1}
                >
                  Siguiente
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Anterior
                </Button>
              }
            />
          </div>
        ) : (
          <div>
            <img
              src={DefaultPreview}
              alt={''}
              className={classes.defaultPreviewImage}
            />
          </div>
        )}
      </div>
    </Widget>
  );
};
export default withStyles(styles, { withTheme: true })(AdPreview);
