import React from 'react';
import {
  Grid,
  withStyles,
  Fab,
  // DialogActions,
  // Dialog,
  // DialogTitle,
  // DialogContentText,
  // DialogContent,
  // Button,
} from '@material-ui/core';
import ModifyDatesSelection from './ModifyDatesSelection';
import Name from '../../../../createad/Components/Name';
import Status from '../../../../createad/Components/Status';
//import BiddingAmount from "../../../../createad/Components/BiddingAmount";
import BiddingAmount from './ModifyBiddingAmount';
import {
  createAdComponentBelongsInPage,
  createAdGetComponentInfo,
  getObjectiveComponentsInfo,
} from '../../../../../utils/functions';

import {
  budgetComponentId,
  dateComponentId,
  campaignNameComponentId,
  campaignStatusComponentId,
  biddingAmountComponentId,
  Environment,
} from '../../../../../components/Constants';
import BudgetDetail from './BudgetDetail';
// import Widget from "../../../../../components/Widget/WidgetContainer";
import { changeFocus } from '../../../../createad/Focusable';
const View = ({ classes, theme, onChange, value, ...props }) => {
  return (
    <Grid container spacing={4}>
      {createAdComponentBelongsInPage(campaignNameComponentId, props) ? (
        <Grid item xs={12} lg={12} md={12}>
          <Name
            list={props.modifyCampaignName.value}
            changeList={e => {
              props.changeModifyCampaignName({ modified: true, value: e });
            }}
            componentInfo={createAdGetComponentInfo(
              campaignNameComponentId,
              props,
            )}
            {...props}
          ></Name>
        </Grid>
      ) : (
        ''
      )}
      {createAdComponentBelongsInPage(dateComponentId, props) ? (
        <Grid item xs={12} md={6} lg={6}>
          <ModifyDatesSelection
            date={props.modifyCampaignate}
            componentInfo={createAdGetComponentInfo(dateComponentId, props)}
            {...props}
          ></ModifyDatesSelection>
        </Grid>
      ) : (
        ''
      )}
      {createAdComponentBelongsInPage(campaignStatusComponentId, props) ? (
        <Grid item xs={12} md={6} lg={6}>
          <Status
            componentInfo={createAdGetComponentInfo(
              campaignStatusComponentId,
              props,
            )}
            {...props}
          ></Status>
        </Grid>
      ) : (
        ''
      )}
      {createAdComponentBelongsInPage(budgetComponentId, props) ? (
        <BudgetDetail
          componentInfo={createAdGetComponentInfo(budgetComponentId, props)}
          {...props}
        ></BudgetDetail>
      ) : (
        ''
      )}
      {createAdComponentBelongsInPage(biddingAmountComponentId, props) &&
      Environment === 'DEV' ? (
        <BiddingAmount
          componentInfo={createAdGetComponentInfo(
            biddingAmountComponentId,
            props,
          )}
          {...props}
        ></BiddingAmount>
      ) : (
        ''
      )}
      <Grid item xs={12} md={12} lg={12}>
        <div style={{ float: 'right' }}>
          <Fab
            id="modift-Campaign"
            variant="extended"
            color="primary"
            aria-label="add"
            className={classes.mainButton}
            onClick={e => {
              let error = false;

              let objectiveComponents =
                getObjectiveComponentsInfo(props).mapping;
              for (let i = 0; i < objectiveComponents.length; i++) {
                if (objectiveComponents[i].getError)
                  if (objectiveComponents[i].getError(props)) {
                    error = true;
                    changeFocus(objectiveComponents[i].focusId, props.refList);
                    break;
                  }
              }

              if (!error) props.setModifyClicked(props);
            }}
          >
            Modificar Campaña
          </Fab>
        </div>
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: theme.spacing(1),
  },
  infoMessage: {
    color: '#9F6000',
    backgroundColor: '#FEEFB3',
    margin: theme.spacing(1),
  },
  textField: {
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    width: 500,
    borderColor: '#013f8a',
  },
  margin: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(3),
    alignText: 'center',
    width: 400,
  },
  fanButton: {
    float: 'right',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    margin: theme.spacing(1),
    alignText: 'center',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(0),
  },
  tableWidget: {
    overflowX: 'visible',
  },
  datePicker: {
    overflowX: 'visible',
    padding: theme.spacing(2),
    margin: 'auto',
  },
  errorIcon: {
    fontSize: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    float: 'left',
  },
  warningMessage: {
    fontSize: '0.9rem',
    height: '1.5rem',
    paddingTop: '0.3rem',
    float: 'left',
  },
});

export default withStyles(styles, { withTheme: true })(View);
