import { compose, withState, withHandlers, lifecycle } from 'recompose';
import {
  leadGenerationObjective,
  conversionsObjective,
  appInstallObjective,
  conversationsObjective,
  googlePlayApplicationId,
  itunesApplicationId,
  whatsAppDestinationTypeId,
  instagramDirectDestinationTypeId,
  uploadImageExtensions,
  maxUploadImageFiles,
  maxUploadImageFilesMessage,
} from '../../components/Constants';
import View from './CreateAdView';
import {
  getAccountsApiAdmin,
  getPageHasError,
  clearAdPage,
  toFbDate,
  getCurrency,
  alertPopUp,
} from '../../utils/functions';
import { saveDraftRequest } from '../../components/ApiClient/Draft';
import { saveAssetRequest } from '../../components/ApiClient/Asset';
import trans from './../../i18n/tran';
import { Track, NewEvent, NewTrackData } from './../../utils/mixpanel';

let today = new Date();
today.setHours(0, 0, 0, 0);
let endDay = new Date(Date.now() + 2 * 24 * 60 * 60 * 1000);
endDay.setHours(0, 0, 0, 0);
let dateRange = [today, endDay];
let totalFiles = 0;
let processedFiles = 0;

let saveAssetRequestResultHandlerCallback;
let copyDraft;
let pProps;

function checkFilesProcessed(copyDraft, filePayload, props, callback) {
  copyDraft.assets.push(filePayload);
  if (totalFiles === processedFiles) {
    saveDraftRequest(copyDraft, (error, result) => {
      //
      if (error) {
        Track(NewEvent('new_campaign', 'create_botton','fail'), NewTrackData(props));
        props.changeIsLoadingLayout(false);
        alertPopUp(
          trans('ERROR_MESSAGE_1', props.lang),
          props,
          true,
          false,
          () => {
            callback(error, result);
          },
        );
      } else {
        props.changeIsLoadingLayout(false);
        Track(NewEvent('new_campaign', 'create_botton','success'), NewTrackData(props));

        alertPopUp(
          trans('NEW_CAMPAIGN_SUCESS', props.lang),
          props,
          false,
          false,
          () => {
            callback(error, result);
          },
        );
        callback(error, result);
      }
    });
  }
}

function saveAssetRequestResultHandler(error1, result1) {
  if (error1) {
    pProps.changeIsLoadingLayout(false);
    pProps.setResultCampaign(false);
    pProps.setVisibleSnackBar(true);
    saveAssetRequestResultHandlerCallback(error1, result1);
  } else {
    ////console.log(JSON.stringify(result1[0]))
    if (result1[0].Error !== undefined) {
      pProps.changeIsLoadingLayout(false);
      pProps.setResultCampaign(false);
      pProps.setVisibleSnackBar();
      saveAssetRequestResultHandlerCallback(result1[0].Error, undefined);
    } else {
      processedFiles++;
      checkFilesProcessed(
        copyDraft,
        result1[0],
        pProps,
        saveAssetRequestResultHandlerCallback,
      );
    }
  }
}

function uploadImages(pCopyDraft, files, externalId, props, callback) {
  props.changeIsLoadingLayout(true);
  copyDraft = pCopyDraft;
  pProps = props;
  totalFiles = files.length;
  processedFiles = 0;
  saveAssetRequestResultHandlerCallback = callback;
  for (let i = 0; i < files.length; i++) {
    let file = files[i]; // CLOSURES, scope, beutiful
    saveAssetRequest(externalId, file, saveAssetRequestResultHandler);
  }
}
function reloadProperties(props) {
  props.changeReset(true);
  props.changeShowAdErrors(false);
  clearAdPage(props, undefined, undefined, true);

  /*//Reset child properties
	props.changeReset(true);
	props.changeSelectedCampaignDates(dateRange);
	//Reset Selector Properties
	props.changeSelectorAdminAccount([]);
	props.changeSelectedAdminAccounts([]);
	getAccountsApiAdmin(props);
	props.changeSelectorAdminAdAccount([]);
	props.changeSelectedAdminAdAccounts([]);
	props.changeSelectorAdminProduct([]);
	props.changeSelectedAdminProducts([]);
	props.changeSelectorAdminFanPage([]);
	props.changeSelectedAdminFanPages([]);
	//Reset Selector of Location properties
	//props.changeLocation(["", ""]);
	props.changeSelectorLocation([]);
	props.changeSelectedLocation([]);
	getLocations(props);
	//Reset behavior properties

	props.setReloadClicked(false);
	props.changeRefList([]);
	//props.setVisibleSnackBar(false)
	//props.setResultCampaign(false)
	props.changeReset(false);
	//Reset Error Properties
	props.changeHasErrorDate(false);
	//props.changeHasErrorLocation(true);
	props.changeHasErrorTitles(true);
	props.changeHasErrorBodies(true);
	props.changeHasErrorLink(true);
	props.changeHasErrorBudget(true);
	props.changeHasErrorProduct(true);
	props.changeHasErrorFanPage(true);
	props.changeHasErrorUpload(true);*/
  props.changeHasError(true);
  props.changeReset(false);
  //if (!props.isModify) props.changeSelectedAdminAccounts([]);
}

function getAlyExpiresAt() {
  if (localStorage.getItem('aly_expires_At')) {
    let expires_At = parseInt(localStorage.getItem('aly_expires_At'));
    return new Date(expires_At);
  }
  return undefined;
}

const mycompose = compose(
  withState('campaignNameHasError', 'changeCampaignNameHasError', false),
  withState('adBiddingAmount', 'changeAdBiddingAmount', []),
  withState('biddingAmountHasError', 'changeBiddingAmountHasError', false),
  withState('showAdErrors', 'changeShowAdErrors', false),
  withState('selectedFacebookPositions', 'changeFacebookPositions', []),
  withState('selectedInstagramPositions', 'changeInstagramPositions', []),

  withState('budget', 'changeBudget', 0.0),
  withState('locations', 'setLocations', []),
  withState('locationId', 'setLocationId', 0),
  withState('radius', 'changeRadius', [{ value: 2000, label: '2KM' }]),
  withState('radiusList', 'changeRadiusList', [
    { value: 2000, label: '2KM' },
    { value: 5000, label: '5KM' },
    { value: 10000, label: '10KM' },
    { value: 20000, label: '20KM' },
    { value: 40000, label: '40KM' },
    { value: 50000, label: '50KM' },
    { value: 80000, label: '80KM' },
  ]),
  withState('interests', 'changeInterests', []),
  withState('distanceUnit', 'changeDistanceUnit', 'kilometer'),
  withState('selectorLocation', 'changeSelectorLocation', []),
  withState('selectedLocation', 'changeSelectedLocation', []),
  withState('adTitles', 'changeAdTitles', ['']),
  withState('adBodies', 'changeAdBodies', ['']),

  withState('adLinks', 'changeAdLinks', ['']),
  withState('adLinkPreview', 'changeAdLinkPreview', ''),
  withState('adLinkParameters', 'changeAdLinkParameters', []),
  withState('adFiles', 'changeAdFiles', []),
  withState('invalidTypeFile', 'changeInvalidTypeFile', false),
  withState('invalidDimension', 'changeInvalidDimension', false),
  withState('invalidSize', 'changeInvalidSize', false),
  withState('invalidDuration', 'changeInvalidDuration', false),
  withState('invalidMaxFiles', 'changeInvalidMaxFiles', false),
  withState('adFilesPreview', 'changeAdFilesPreview', []),
  withState('selectorAdminAdAccount', 'changeSelectorAdminAdAccount', []),
  withState('selectedAdminAdAccounts', 'changeSelectedAdminAdAccounts', []),
  withState('selectorAdminFanPage', 'changeSelectorAdminFanPage', []),
  withState('selectedAdminFanPages', 'changeSelectedAdminFanPages', []),
  withState('selectedGender', 'changeSelectedGender', '3'),
  withState('selectedUploadType', 'changeSelectedUploadType', '1'),
  withState(
    'uploadAllowedFiles',
    'changeUploadAllowedFiles',
    uploadImageExtensions,
  ),
  withState('maxUploadFiles', 'changeMaxUploadFiles', maxUploadImageFiles),
  withState(
    'maxUploadFilesMessage',
    'changeMaxUploadFilesMessage',
    maxUploadImageFilesMessage,
  ),
  withState('selectorAgeStart', 'changeSelectorAgeStart', []),
  withState('selectedAgesStart', 'changeSelectedAgesStart', []),
  withState('selectorAgeEnd', 'changeSelectorAgeEnd', []),
  withState('selectedAgesEnd', 'changeSelectedAgesEnd', []),

  withState('refreshCheckbox', 'changeRefreshCheckbox', false),
  withState(
    'selectorLeadGenerationForm',
    'changeSelectorLeadGenerationForm',
    [],
  ),
  withState(
    'selectedLeadGenerationForms',
    'changeSelectedLeadGenerationForms',
    [],
  ),

  withState('recalculateMessages', 'changeRecalculateMessages', false),
  withState('selectorPixel', 'changeSelectorPixel', []),
  withState('selectedPixels', 'changeSelectedPixels', []),
  withState('selectorPixelEvent', 'changeSelectorPixelEvent', []),
  withState('selectedPixelEvents', 'changeSelectedPixelEvents', []),
  withState('selectorUsedPixelEvent', 'changeSelectorUsedPixelEvent', []),
  withState('selectedUsedPixelEvents', 'changeSelectedUsedPixelEvents', []),
  withState(
    'selectedConversionLocations',
    'changeSelectedConversionLocations',
    [],
  ),
  withState('selectorApplication', 'changeSelectorApplication', []),
  withState('selectedApplications', 'changeSelectedApplications', []),
  withState('selectorAppStore', 'changeSelectorAppStore', []),
  withState('selectedAppStores', 'changeSelectedAppStores', []),
  withState('selectedConversations', 'changeSelectedConversations', []),
  withState(
    'selectorAdminInstagramAccount',
    'changeSelectorAdminInstagramAccount',
    [],
  ),
  withState(
    'selectedAdminInstagramAccounts',
    'changeSelectedAdminInstagramAccounts',
    [],
  ),

  withState('campaignName', 'changeCampaignName', ['']),
  withState('selectedCampaignDates', 'changeSelectedCampaignDates', dateRange),
  withState('adBudget', 'changeAdBudget', ['']),
  //selectedCustomAudiences  selectorCustomAudience changeSelectedCustomAudiences
  withState('selectedSavedAudiences', 'changeSelectedSavedAudiences', []),
  withState('selectorSavedAudience', 'changeSelectorSavedAudience', []),
  withState('selectedCustomAudiences', 'changeSelectedCustomAudiences', []),
  withState('selectorCustomAudience', 'changeSelectorCustomAudience', []),

  withState('hasErrorProduct', 'changeHasErrorProduct', true),
  withState('hasErrorDate', 'changeHasErrorDate', false),
  //withState("hasErrorLocation", "changeHasErrorLocation", true),
  withState('hasErrorTitles', 'changeHasErrorTitles', true),
  withState('hasErrorBodies', 'changeHasErrorBodies', true),
  withState('hasErrorLink', 'changeHasErrorLink', true),
  withState('hasErrorBudget', 'changeHasErrorBudget', true),
  withState('hasErrorFanPage', 'changeHasErrorFanPage', true),
  withState('hasErrorPixel', 'changeHasErrorPixel', true),
  withState('hasErrorApplication', 'changeHasErrorApplication', true),
  withState('hasErrorConversations', 'changeHasErrorConversations', true),
  withState('hasErrorUpload', 'changeHasErrorUpload', true),
  withState('invalidTypeFile', 'changeInvalidTypeFile', false),
  withState('hasError', 'changeHasError', true),
  withState('createClicked', 'setCreateClicked', false),
  withState('reloadClicked', 'setReloadClicked', false),
  withState('refList', 'changeRefList', []),
  withState('visibleSnackBar', 'setVisibleSnackBar', false),
  withState('resultCampaign', 'setResultCampaign', false),
  withState('reset', 'changeReset', false),
  withState('selectedCta', 'changeSelectedCta', []),
  withState('selectorCta', 'changeSelectorCta', []),
  withState('preview', 'changePreview', []),
  withState('visiblePreview', 'changeVisiblePreview', false),
  withState('previewValidation', 'changePreviewValidation', false),
  withState('initLng', 'setInitLng', 0),
  withState('initLat', 'setInitLat', 0),
  withState('providers', 'changeProviders', []),
  withState('savedAudienceSelected', 'changeSavedAudienceSelected', false),
  withState(
    'selectedAdminCampaignTypes',
    'changeSelectedAdminCampaignTypes',
    [],
  ),
  withState('description', 'changeDescription', []),
  withState('aly_expires_At', 'changeAlyExpiresAt', undefined),
  lifecycle({
    componentDidMount() {
      this.props.changeSelectorAdminProductFiltered([]);
      this.props.changeSelectedAdminProducts([]);
      this.props.changeAlyExpiresAt(getAlyExpiresAt());
      getAccountsApiAdmin(this.props);

      this.props.changeHasError(getPageHasError(this.props));
      this.props.changeTenantChangeFunction({
        function: newTenenatValue => {
          window.location.reload(); // AWFUL
        },
      });
      reloadProperties(this.props);
    },

    componentWillUnmount() {
      //window.removeEventListener('resize', this.props.handleWindowWidthChange);
    },
    componentDidUpdate() {
      let newHasError = getPageHasError(this.props);

      if (newHasError !== this.props.hasError)
        this.props.changeHasError(newHasError);
    },
  }),
  withHandlers({
    changeSelectedCampaignDatesHandler: props => dates => {
      props.changeRecalculateMessages(!props.recalculateMessages);
      props.changeSelectedCampaignDates(dates);
    },
    setCreateClicked: props => event => {
      let budget = Number(props.adBudget[0]);
      let link = props.adLinkPreview;
      if (
        props.selectedAdminCampaignTypes[0].value === conversationsObjective
      ) {
        link = encodeURI(props.selectedConversations[0].link);
      }
      if (props.selectedAdminCampaignTypes[0].value === appInstallObjective) {
        link = encodeURI(props.selectedAppStores[0].value);
      }
      let locations = [];
      let zips = [];
      let countries = [];
      let regions = [];
      let cities = [];
      let genders = [];
      let interests = undefined;
      let behaviors = undefined;
      let life_events = undefined;
      let industries = undefined;
      let family_statuses = undefined;
      let relationship_statuses = undefined;
      let education_statuses = undefined;

      let currency = getCurrency(props);

      if (props.interests.length > 0) {
        props.interests.forEach(element => {
          switch (element.fbType) {
            case 'interests':
              if (!interests) {
                interests = [];
              }
              interests.push(element.value);
              break;
            case 'behaviors':
              if (!behaviors) {
                behaviors = [];
              }
              behaviors.push(element.value);
              break;
            case 'life_events':
              if (!life_events) {
                life_events = [];
              }
              life_events.push(element.value);
              break;
            case 'industries':
              if (!industries) {
                industries = [];
              }
              industries.push(element.value);
              break;
            case 'family_statuses':
              if (!family_statuses) {
                family_statuses = [];
              }
              family_statuses.push(element.value);
              break;
            case 'relationship_statuses':
              if (!relationship_statuses) {
                relationship_statuses = [];
              }
              relationship_statuses.push(element.value);
              break;
            case 'education_statuses':
              if (!education_statuses) {
                education_statuses = [];
              }
              education_statuses.push(element.value);
              break;
            default:
              console.log('no interest match');
          }
        });
      }
      if (props.selectedGender === '3') {
        genders = [1, 2];
      } else genders = [parseInt(props.selectedGender)];
      let ages = undefined;
      if (props.selectedAgesStart.length > 0 && !props.savedAudienceSelected) {
        ages = {
          age_min: props.selectedAgesStart[0].value,
          age_max: props.selectedAgesEnd[0].value,
        };
      }

      if (!props.savedAudienceSelected) {
        props.locations.forEach(element => {
          switch (element.fbType) {
            case 'position':
              locations.push({
                latitude: element.position.lat,
                longitude: element.position.lng,
                radius: element.radius / 1000,
                distance_unit: props.distanceUnit,
              });
              break;
            case 'country':
              countries.push(element.key);
              break;
            case 'region':
              regions.push(element.key);
              break;
            case 'city':
              cities.push(element.key);
              break;
            case 'zip':
              zips.push(element.key);
              break;
            default:
              console.log('not location match');
          }
        });
      }

      let customAudiences = undefined;
      if (
        props.selectedCustomAudiences &&
        props.selectedCustomAudiences.length &&
        props.selectedCustomAudiences.length > 0
      ) {
        customAudiences = [];
      }

      props.selectedCustomAudiences.forEach(element => {
        customAudiences.push(element.value);
      });

      let savedAudiences = undefined;
      if (
        props.selectedSavedAudiences &&
        props.selectedSavedAudiences.length > 0
      ) {
        savedAudiences = [props.selectedSavedAudiences[0].value];
      }

      //let position = props.provider[0].value.position;
      let cta = props.selectedCta[0].value;
      let draftJSON = {
        description: props.description,
        titles: props.adTitles,
        bodies: props.adBodies,
        assets: [],
        adaccountid: props.selectedAdminAdAccounts[0].value,
        budget: budget,
        start_date: props.selectedCampaignDates[0]
          .toISOString()
          .substring(0, 10),
        unix_start: toFbDate(props.selectedCampaignDates[0]),
        end_date: props.selectedCampaignDates[1].toISOString().substring(0, 10),
        unix_end: toFbDate(props.selectedCampaignDates[1], true),
        fanpageid: props.selectedAdminFanPages.value,
        locations: locations,
        zips: zips,
        countries: countries,
        regions: regions,
        cities: cities,
        link: link,
        product_id: props.selectedAdminProducts[0].value,
        product_name: props.selectedAdminProducts[0].label,
        objective: props.selectedAdminCampaignTypes[0].facebook_name,
        //publisher_platforms: props.provider[0].value.placement,
        //provider: props.provider[0].value.provider,
        publisher_platforms: props.providers,
        facebook_positions: props.selectedFacebookPositions,
        instagram_positions: props.selectedInstagramPositions,
        call_to_action: cta,
        genders,
      };
      if (currency) {
        draftJSON['currency'] = currency;
      }
      if (customAudiences && !props.savedAudienceSelected) {
        draftJSON['customAudiences'] = customAudiences;
      }
      if (savedAudiences) {
        draftJSON['savedAudiences'] = savedAudiences;
        draftJSON['targeting'] = props.selectedSavedAudiences[0].targeting;
      }
      if (ages) {
        draftJSON['ages'] = ages;
      }
      if (
        props.selectedAdminCampaignTypes[0].value === leadGenerationObjective
      ) {
        draftJSON['lead_gen_form_id'] =
          props.selectedLeadGenerationForms[0].value;
      }

      if (props.selectedAdminCampaignTypes[0].value === conversionsObjective) {
        draftJSON['pixel_id'] = props.selectedPixels[0].value;
        if (props.selectedUsedPixelEvents.length > 0)
          draftJSON['pixel_event'] = props.selectedUsedPixelEvents[0].value;
        if (props.selectedPixelEvents.length > 0)
          draftJSON['pixel_event'] = props.selectedPixelEvents[0].value;
      }

      if (props.selectedAdminCampaignTypes[0].value === appInstallObjective) {
        draftJSON['application_id'] = props.selectedApplications[0].value;
        draftJSON['object_store_url'] = encodeURI(
          props.selectedAppStores[0].value,
        );
        if (props.selectedAppStores[0].store === googlePlayApplicationId)
          draftJSON['user_os'] = ['android'];
        if (props.selectedAppStores[0].store === itunesApplicationId)
          draftJSON['user_os'] = ['iOS'];
      }
      if (
        props.selectedAdminCampaignTypes[0].value === conversationsObjective
      ) {
        draftJSON['destination_type'] = props.selectedConversations[0].value;
        if (
          props.selectedConversations[0].value === whatsAppDestinationTypeId ||
          props.selectedConversations[0].value ===
            instagramDirectDestinationTypeId
        ) {
          draftJSON['device_platforms'] = ['mobile'];
        }
      }
      if (props.adBiddingAmount.length > 0) {
        draftJSON['bidding_amount'] = Number(props.adBiddingAmount[0]);
      }
      if (
        props.selectedInstagramPositions.length > 0 &&
        props.selectedAdminInstagramAccounts.length > 0
      )
        draftJSON['instagram_account'] =
          props.selectedAdminInstagramAccounts[0].value;

      if (interests) {
        draftJSON['interests'] = interests;
      }
      if (behaviors) {
        draftJSON['behaviors'] = behaviors;
      }
      if (life_events) {
        draftJSON['life_events'] = life_events;
      }
      if (industries) {
        draftJSON['industries'] = industries;
      }
      if (family_statuses) {
        draftJSON['family_statuses'] = family_statuses;
      }
      if (relationship_statuses) {
        draftJSON['relationship_statuses'] = relationship_statuses;
      }
      if (education_statuses) {
        draftJSON['education_statuses'] = education_statuses;
      }
      if (
        props.campaignName.length > 0 &&
        props.campaignName[0] &&
        props.campaignName[0].length > 0
      ) {
        draftJSON['campaign_name'] = props.campaignName[0];
      } else {
        draftJSON['campaign_name'] =
          props.selectedAdminProducts[0].label +
          '_' +
          props.selectedAdminCampaignTypes[0].label +
          '_' +
          props.selectedCampaignDates[0].toISOString().substring(0, 10);
      }

      draftJSON['upload_type'] =
        props.selectedUploadType === '1' ? 'image' : 'video';

      uploadImages(
        draftJSON,
        props.adFiles,
        props.selectedAdminAdAccounts[0].externalId,
        props,
        (error, result) => {
          if (result !== undefined) reloadProperties(props);
        },
      );
    },
    setVisibleSnackBar: props => visible => {
      props.setVisibleSnackBar(visible);
    },
    setResetProperties: props => event => {
    },
  }),
);

export default mycompose(View);
