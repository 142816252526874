import React, { FC } from 'react';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { CountryField } from '../../../../components/CountryField';
import i18n from '../../../../../i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { FormValues, OnBoardingStep } from '../type';
import * as Yup from 'yup';
import { useOnboardingContext } from '../../context';
import {
  CreditCardIcon,
  DoubleArrowsIcon,
  SpaIcon,
  UserIcon,
} from '../../../../components/Icon';
import valid from 'card-validator';
import InputMask from 'react-input-mask';
import { TextFieldConfig } from '../../utils';

export const Checkout: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showPreviousStep } = useOnboardingContext();
  const { isSubmitting, values, handleChange } = useFormikContext<FormValues>();

  const { tiers } = useOnboardingContext();

  const selectedPlan = tiers
    ? tiers.data.find(value => value.id === values.planType)
    : undefined;

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={9} md={9}>
          <Card className={classes.cardRoot}>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <Typography className={classes.changePlanTitle}>
                    <SpaIcon /> {t('Chosen plan')}
                  </Typography>
                </Grid>
                <Grid
                  container
                  justify="space-between"
                  spacing={2}
                  style={{ paddingLeft: '24px', paddingRight: '24px' }}>
                  <Typography align="left" className={classes.planName}>
                    {selectedPlan && selectedPlan.name}
                  </Typography>
                  <Typography align="right" className={classes.planPrice}>
                    ${selectedPlan && selectedPlan.priceMonthly}
                    <span className={classes.planPriceMonth}>
                      {t('/Monthly')}
                    </span>
                  </Typography>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <Button
                    onClick={showPreviousStep}
                    className={classes.changePlanButton}>
                    <Typography className={classes.changePlanButtonText}>
                      <DoubleArrowsIcon /> {t('Change this plan')}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={9} md={9}>
          <Card className={classes.cardRoot}>
            <CardContent className={classes.cardContent}>
              <Grid container>
                <Typography className={classes.title}>
                  <UserIcon /> {t('Billing information')}
                </Typography>

                <Grid container spacing={2} className={classes.field}>
                  <Grid item xs={12} md={8} lg={8}>
                    <Field
                      required
                      label={t('Billing Name')}
                      variant="outlined"
                      name="billingName"
                      placeholder={t('Enter your name')}
                      component={TextField}
                      {...TextFieldConfig}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.field}>
                  <Grid item xs={12} md={8} lg={8}>
                    <Field
                      required
                      label={t('Tax id')}
                      variant="outlined"
                      name="taxId"
                      placeholder={t('Enter your tax id')}
                      component={TextField}
                      {...TextFieldConfig}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.field}>
                  <Grid item xs={12} md={8} lg={8}>
                    <Field
                      required
                      label={t('Billing Address')}
                      name="billingAddress"
                      variant="outlined"
                      placeholder={t('Enter Address')}
                      component={TextField}
                      {...TextFieldConfig}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.field}>
                  <Grid item xs={12} md={8} lg={8}>
                    <Field
                      variant="outlined"
                      label={t('Country')}
                      name="billingCountry"
                      placeholder={t('Choose Country')}
                      component={CountryField}
                      {...TextFieldConfig}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.field}>
                  <Grid item xs={12} md={8} lg={8}>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.field}>
                  <Grid item xs={12} md={8} lg={8}>
                    <Typography className={classes.title}>
                      <CreditCardIcon /> {t('Payment information')}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.field}>
                  <Grid item xs={12} md={8} lg={8}>
                    <Field
                      required
                      label={t('Name on Card')}
                      variant="outlined"
                      name="billingCardName"
                      placeholder={t('Enter name on card')}
                      component={TextField}
                      {...TextFieldConfig}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.field}>
                  <Grid item xs={12} md={8} lg={8}>
                    <Field
                      required
                      label={t('Card Number')}
                      variant="outlined"
                      name="billingCardNumber"
                      placeholder={t('Enter card number')}
                      component={TextField}
                      {...TextFieldConfig}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.field}>
                  <Grid item xs={12} md={4} lg={4}>
                    <InputMask
                      mask="99/99"
                      maskPlaceholder="mm/yy"
                      value={values.billingCardExpiration}
                      onChange={handleChange}>
                      {() => (
                        <Field
                          required
                          label={t('Exp Date')}
                          variant="outlined"
                          name="billingCardExpiration"
                          placeholder={t('MM/YY')}
                          component={TextField}
                          {...TextFieldConfig}
                        />
                      )}
                    </InputMask>
                  </Grid>

                  <Grid item xs={12} md={4} lg={4}>
                    <InputMask
                      mask={[/^[0-9]{3,4}$/]}
                      maskPlaceholder=" "
                      value={values.billingCardCVV}
                      onChange={handleChange}>
                      {() => (
                        <Field
                          required
                          label={t('CVV')}
                          variant="outlined"
                          name="billingCardCVV"
                          placeholder={t('CVV Number')}
                          component={TextField}
                          {...TextFieldConfig}
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.field}>
                  <Grid item xs={12} md={8} lg={8}>
                    <Field
                      label={t('Coupon')}
                      variant="outlined"
                      name="billingCoupon"
                      placeholder={t('Enter coupon')}
                      component={TextField}
                      {...TextFieldConfig}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Button
        fullWidth
        disabled={isSubmitting}
        style={{ textTransform: 'none' }}
        color="primary"
        type="submit"
        variant="contained">
        {t('Pay now')}
      </Button>
    </>
  );
};

function validateMonth(value: any) {
  return (
    !!value &&
    valid.expirationMonth(value?.toString().split('/')[0]).isPotentiallyValid
  );
}

function validateYear(value: any) {
  return (
    !!value &&
    valid.expirationYear(value?.toString().split('/')[1]).isPotentiallyValid
  );
}
export const CheckoutStep: OnBoardingStep = {
  // @ts-ignore
  component: Checkout,
  label: i18n.t('Checkout'),
  initialValues: {
    billingName: undefined,
    taxId: undefined,
    billingAddress: undefined,
    billingCountry: '',
    billingCardName: undefined,
    billingCardNumber: undefined,
    billingCardExpiration: undefined,
    billingCardCVV: undefined,
    billingCoupon: undefined,
  },
  welcomeTitle: '',
  welcomeDescription: '',
  validationSchema: Yup.object().shape({
    billingName: Yup.string()
      .trim(i18n.t('Value cannot include leading and trailing spaces'))
      .required(i18n.t('Please enter billing name')),
    taxId: Yup.string()
      .trim(i18n.t('Value cannot include leading and trailing spaces'))
      .required(i18n.t('Please enter tax id')),
    billingAddress: Yup.string()
      .trim(i18n.t('Value cannot include leading and trailing spaces'))
      .required(i18n.t('Please enter billing address')),
    billingCountry: Yup.string()
      .trim(i18n.t('Value cannot include leading and trailing spaces'))
      .required(i18n.t('Please select a country')),
    billingCardName: Yup.string()
      .trim(i18n.t('Value cannot include leading and trailing spaces'))
      .required(i18n.t('Please enter name on card')),
    billingCardNumber: Yup.string()
      .test('test-number', i18n.t('Please enter number in card'), value => {
        return valid.number(value).isPotentiallyValid;
      })
      .trim(i18n.t('Value cannot include leading and trailing spaces'))
      .required(i18n.t('Please enter number in card')),
    billingCardExpiration: Yup.string()
      .test(
        'test-expiration-month',
        i18n.t('Please enter a valid expiration date, check month'),
        value => validateMonth(value),
      )
      .test(
        'test-expiration-year',
        i18n.t('Please enter a valid expiration date, check year'),
        value => validateYear(value),
      )
      .test(
        'test-expiration',
        i18n.t('Please enter a valid expiration date'),
        value => valid.expirationDate(value).isPotentiallyValid,
      )
      .trim(i18n.t('Value cannot include leading and trailing spaces'))
      .required(i18n.t('Please enter expiration date')),
    billingCardCVV: Yup.string()
      .trim(i18n.t('Value cannot include leading and trailing spaces'))
      .required(i18n.t('Please enter CVV from the back of your card'))
      .test(
        'cvv-length',
        i18n.t('Please enter CVV from the back of your card'),
        value => value?.length === 3 || value?.length === 4,
      ),
  }),
};
