import { request, requestHeaders } from './helper';
import { verifyAccountUrl } from '../../components/Constants';

interface CheckBusinessExistResponsePayload {
  iAccountId: string;
}
interface CheckBusinessExistResponse {
  success: boolean;
  payload: CheckBusinessExistResponsePayload[];
}
export const CheckBusinessExist = async (name: string | undefined) => {
  try {
    if (!name) {
      const error: CheckBusinessExistResponse = {
        success: false,
        payload: [],
      };
      return error;
    }
    const headers = requestHeaders();
    const response = await request<CheckBusinessExistResponse>(
      `${verifyAccountUrl}/${name.trim()}`,
      {
        method: 'GET',
        headers: headers,
      },
    );
    return response;
  } catch (e) {
    console.log('ValidateBusiness exception', JSON.stringify(e));
    const error: CheckBusinessExistResponse = {
      success: false,
      payload: [],
    };
    return error;
  }
};
